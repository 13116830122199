module.exports = function (code) {
    const currencyMap = {
        GBP: {
            name: 'Pound Sterling',
            ISO: 'GBP',
            fractionSize: 2,
            fractionCount: 2,
            symbol: '£',
            thousandsSeparator: ',',
            fractionSeparator: '.',
            symbolPosition: 'front',
            symbolSpacing: false
        },
        EUR: {
            name: 'Euro',
            ISO: 'EUR',
            fractionSize: 2,
            fractionCount: 2,
            symbol: '€',
            thousandsSeparator: '.',
            fractionSeparator: ',',
            symbolPosition: 'front',
            symbolSpacing: false
        },
        USD: {
            name: 'US Dollar',
            ISO: 'USD',
            fractionSize: 2,
            fractionCount: 2,
            symbol: '$',
            thousandsSeparator: ',',
            fractionSeparator: '.',
            symbolPosition: 'front',
            symbolSpacing: false
        },
        MAD: {
            name: 'Moroccan dirham',
            ISO: 'MAD',
            fractionSize: 2,
            fractionCount: 2,
            symbol: 'د.م',
            thousandsSeparator: ',',
            fractionSeparator: '.',
            symbolPosition: 'front',
            symbolSpacing: false
        },
        AED: {
            name: 'Arab Emirates Dirham',
            ISO: 'AED',
            fractionSize: 2,
            fractionCount: 2,
            symbol: 'د.إ',
            thousandsSeparator: ',',
            fractionSeparator: '.',
            symbolPosition: 'front',
            symbolSpacing: false
        },
        SEK: {
            name: 'Swedish Krona',
            ISO: 'SEK',
            fractionSize: 2,
            fractionCount: 2,
            symbol: 'kr',
            thousandsSeparator: ' ',
            fractionSeparator: ',',
            symbolPosition: 'back',
            symbolSpacing: false
        },
        NOK: {
            name: 'Norwegian krone',
            ISO: 'NOK',
            fractionSize: 2,
            fractionCount: 2,
            symbol: 'kr',
            thousandsSeparator: ' ',
            fractionSeparator: ',',
            symbolPosition: 'back',
            symbolSpacing: true
        },
        ZAR: {
            name: 'South African Rand',
            ISO: 'ZAR',
            fractionSize: 2,
            fractionCount: 2,
            symbol: 'R',
            thousandsSeparator: ' ',
            fractionSeparator: ',',
            symbolPosition: 'front',
            symbolSpacing: true
        },
        CHF: {
            name: 'Swiss franc',
            ISO: 'CHF',
            fractionSize: 2,
            fractionCount: 2,
            symbol: 'CHF',
            thousandsSeparator: "'",
            fractionSeparator: ',',
            symbolPosition: 'back',
            symbolSpacing: true
        },
        AUD: {
            name: 'Australian Dollar',
            ISO: 'AUD',
            fractionSize: 2,
            fractionCount: 2,
            symbol: '$',
            thousandsSeparator: ',',
            fractionSeparator: '.',
            symbolPosition: 'front',
            symbolSpacing: false
        },
        CAD: {
            name: 'Canadian Dollar',
            ISO: 'CAD',
            fractionSize: 2,
            fractionCount: 2,
            symbol: '$',
            thousandsSeparator: ',',
            fractionSeparator: '.',
            symbolPosition: 'front',
            symbolSpacing: false
        },
        DKK: {
            name: 'Danish Krone',
            ISO: 'DKK',
            fractionSize: 2,
            fractionCount: 2,
            symbol: 'kr',
            thousandsSeparator: '.',
            fractionSeparator: ',',
            symbolPosition: 'back',
            symbolSpacing: true
        }
    };

    return Object.keys(currencyMap).includes(code)
        ? currencyMap[code]
        : currencyMap['GBP'];
};
