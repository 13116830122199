<template>
    <div
        class="mx-auto max-w-2xl px-6 sm:px-12"
        :class="{ 'mt-10': $route.name !== 'manageAdyenAccountHolder' }"
    >
        <div class="md:flex md:items-center md:justify-between">
            <div class="min-w-0 flex-1">
                <router-link
                    :to="{
                        name:
                            $route.name === 'manageAdyenAccountHolder'
                                ? 'paymentSettings'
                                : 'dashboard'
                    }"
                    class="mb-2 flex items-center text-sm text-gray-500 inline-flex"
                >
                    <base-back-icon />
                    <span>
                        {{
                            $route.name === 'manageAdyenAccountHolder'
                                ? 'Payment settings'
                                : 'Dashboard'
                        }}
                    </span>
                </router-link>
            </div>

            <BaseSpinner :isLoading="isLoading" />
        </div>
        <div v-if="!isLoading && adyenAccountHolder">
            <div class="mt-5">
                <base-page-title :title="accountName" />
                <h3
                    class="mt-1 cursor-pointer font-mono text-sm text-gray-500"
                    @click="copyToClipboard"
                    >{{ $route.params.code }}</h3
                >
            </div>

            <div class="mt-5">
                <div class="shadow sm:overflow-hidden sm:rounded-md">
                    <div class="bg-white px-4 py-5 sm:p-6">
                        <div
                            v-if="adyenAccountHolder && requiresMoreInformation"
                            class="mb-5 rounded-md border border-blue-200 bg-blue-50 p-4"
                        >
                            <div class="flex">
                                <div class="flex-shrink-0">
                                    <svg
                                        class="h-5 w-5 text-blue-400"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                                            clip-rule="evenodd"
                                        />
                                    </svg>
                                </div>
                                <div class="ml-3">
                                    <h3
                                        class="text-sm font-medium text-blue-800"
                                    >
                                        Information required
                                    </h3>
                                    <div class="mt-2 text-sm text-blue-700">
                                        <p>
                                            We need to collect some more
                                            information about your business
                                            before you can start taking payments
                                        </p>
                                        <span
                                            class="mt-2 inline-flex rounded-md shadow-sm"
                                            @click="
                                                fetchAdyenAccountHolderOnboardingUrl
                                            "
                                        >
                                            <button
                                                type="button"
                                                class="focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                                                :class="{
                                                    spinner: onbardingUrlisLoading
                                                }"
                                                :disabled="
                                                    onbardingUrlisLoading
                                                "
                                            >
                                                Complete account setup
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div>
                                <dl class="sm:divide-y sm:divide-gray-200">
                                    <div
                                        class="pb-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:pb-5"
                                    >
                                        <dt
                                            class="text-sm font-medium text-gray-500"
                                        >
                                            Account type
                                        </dt>
                                        <dd
                                            class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0"
                                        >
                                            {{ adyenAccountHolder.legalEntity }}
                                        </dd>
                                    </div>
                                    <div
                                        class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5"
                                    >
                                        <dt
                                            class="text-sm font-medium text-gray-500"
                                        >
                                            Account Email
                                        </dt>
                                        <dd
                                            class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0"
                                        >
                                            {{
                                                adyenAccountHolder
                                                    .accountHolderDetails.email
                                            }}
                                        </dd>
                                    </div>
                                    <div
                                        class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5"
                                    >
                                        <dt
                                            class="text-sm font-medium text-gray-500"
                                        >
                                            Account Status
                                        </dt>
                                        <dd
                                            class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0"
                                        >
                                            <span
                                                :class="{
                                                    'bg-green-100 text-green-800': paymentsEnabled,
                                                    'bg-yellow-100 text-yellow-800': !paymentsEnabled
                                                }"
                                                class="inline-flex items-center rounded-sm px-2 py-0.5 text-xs font-medium leading-4"
                                            >
                                                <svg
                                                    :class="{
                                                        'text-green-400': paymentsEnabled,
                                                        'text-yellow-400': !paymentsEnabled
                                                    }"
                                                    class="-ml-0.5 mr-1.5 h-2 w-2"
                                                    fill="currentColor"
                                                    viewBox="0 0 8 8"
                                                >
                                                    <circle
                                                        cx="4"
                                                        cy="4"
                                                        r="3"
                                                    />
                                                </svg>
                                                Payments
                                            </span>
                                            <span
                                                :class="{
                                                    'bg-green-100 text-green-800': payoutsEnabled,
                                                    'bg-yellow-100 text-yellow-800': !payoutsEnabled
                                                }"
                                                class="ml-5 inline-flex items-center rounded-sm px-2 py-0.5 text-xs font-medium leading-4"
                                            >
                                                <svg
                                                    :class="{
                                                        'text-green-400': payoutsEnabled,
                                                        'text-yellow-400': !payoutsEnabled
                                                    }"
                                                    class="-ml-0.5 mr-1.5 h-2 w-2"
                                                    fill="currentColor"
                                                    viewBox="0 0 8 8"
                                                >
                                                    <circle
                                                        cx="4"
                                                        cy="4"
                                                        r="3"
                                                    />
                                                </svg>
                                                Payouts
                                            </span>

                                            <span
                                                :class="{
                                                    'bg-green-100 text-green-800': pciCompleted,
                                                    'bg-yellow-100 text-yellow-800': !pciCompleted
                                                }"
                                                class="ml-5 inline-flex items-center rounded-sm px-2 py-0.5 text-xs font-medium leading-4"
                                            >
                                                <svg
                                                    :class="{
                                                        'text-green-400': pciCompleted,
                                                        'text-yellow-400': !pciCompleted
                                                    }"
                                                    class="-ml-0.5 mr-1.5 h-2 w-2"
                                                    fill="currentColor"
                                                    viewBox="0 0 8 8"
                                                >
                                                    <circle
                                                        cx="4"
                                                        cy="4"
                                                        r="3"
                                                    />
                                                </svg>
                                                PCI
                                            </span>
                                        </dd></div
                                    >
                                    <div
                                        class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5"
                                    >
                                        <dt
                                            class="text-sm font-medium text-gray-500"
                                        >
                                            Bank accounts
                                        </dt>
                                        <dd
                                            class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0"
                                        >
                                            <AdyenBankAccounts
                                                :accounts="
                                                    adyenAccountHolder
                                                        .accountHolderDetails
                                                        .bankAccountDetails
                                                "
                                                :accountHolderCode="
                                                    adyenAccountHolder.accountHolderCode
                                                "
                                            />
                                        </dd>
                                    </div>
                                    <div
                                        class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5"
                                    >
                                      <dt
                                          class="text-sm font-medium text-gray-500"
                                      >
                                        Payouts
                                      </dt>
                                      <dd
                                          class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0"
                                      >
                                        <ul
                                            class="border border-gray-200 rounded-md divide-y divide-gray-200 overflow-hidden"
                                        >
                                          <li
                                          >
                                            <div
                                                class="pl-3 pr-4 py-3 flex items-center justify-between text-sm bg-gray-50"
                                            >
                                              <div class="w-0 flex-1 flex flex-col text-gray-500">
                                                <p class="ml-2 truncate flex items-center">
                                                  Daily
                                                  <base-question-icon
                                                      class="ml-1 text-gray-600 h-4 w-4 cursor-pointer"
                                                      v-tooltip="'Funds are transferred at the end of each day and they take 2 working days to arrive in your account. This means:<br/><br/>' +
                                                                  'Monday sales arrive on Wednesday<br/><br/>' +
                                                                  'Tuesday sales arrive on Thursday<br/><br/>' +
                                                                  'Wednesday sales arrive on Friday<br/><br/>' +
                                                                  'Thursday sales arrive on Saturday<br/><br/>' +
                                                                  'Friday, Saturday and Sunday sales arrive together on Tuesday'"
                                                  />
                                                </p>
                                              </div>
                                            </div>
                                          </li>
                                        </ul>
                                      </dd>
                                    </div>
                                    <div
                                        class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5"
                                    >
                                        <dt
                                            class="text-sm font-medium text-gray-500"
                                        >
                                            Stores
                                        </dt>
                                        <dd
                                            class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0"
                                        >
                                            <AdyenAccountHolderAccounts
                                                :accounts="
                                                    adyenAccountHolder.accounts
                                                "
                                            />
                                        </dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AdyenBankAccounts from './AdyenBankAccounts';
import AdyenAccountHolderAccounts from './AdyenAccountHolderAccounts';
import BaseQuestionIcon from "@/components/base/icons/BaseQuestionIcon.vue";
export default {
    name: 'ManageAdyenAccountHolder',
    metaInfo: {
        title: 'Storekit Payments Setup'
    },
    data() {
        return {
            isLoading: true,
            onbardingUrlisLoading: false,
            adyenAccountHolder: null
        };
    },
    components: {
      BaseQuestionIcon,
        AdyenBankAccounts,
        AdyenAccountHolderAccounts
    },
    mounted() {
        this.fetchAdyenAccountHolder();
    },
    methods: {
        async fetchAdyenAccountHolder() {
            try {
                this.isLoading = true;
                const { data } = await this.$axios.get(
                    `/adyen/account-holder/?accountHolderCode=${this.$route.params.code}`
                );

                this.adyenAccountHolder = data;
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        },
        async fetchAdyenAccountHolderOnboardingUrl() {
            try {
                this.onbardingUrlisLoading = true;

                const { data } = await this.$axios.get(
                    `/adyen/onboarding-url/?accountHolderCode=${this.$route.params.code}`
                );

                const popupWindowWidth = 500;
                const popupWindowHeight = 850;

                const left = screen.width / 2 - popupWindowWidth / 2;
                const top = screen.height / 2 - popupWindowHeight / 2;

                window.open(
                    data.redirectUrl,
                    'newwindow',
                    `width=${popupWindowWidth},height=${popupWindowHeight},top=${top},left=${left}`
                );
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.onbardingUrlisLoading = false;
            }
        },
        async fetchAdyenAccountPciUrl() {
            try {
                this.onbardingUrlisLoading = true;

                const { data } = await this.$axios.get(
                    `/adyen/pci-questionnaire-url/?accountCode=${this.$route.params.code}`
                );

                const popupWindowWidth = 500;
                const popupWindowHeight = 850;

                const left = screen.width / 2 - popupWindowWidth / 2;
                const top = screen.height / 2 - popupWindowHeight / 2;

                window.open(
                    data.redirectUrl,
                    'newwindow',
                    `width=${popupWindowWidth},height=${popupWindowHeight},top=${top},left=${left}`
                );
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.onbardingUrlisLoading = false;
            }
        },
        async copyToClipboard() {
            await navigator.clipboard.writeText(this.$route.params.code);
            this.$notify({
                group: 'settings',
                title: `Copied "${this.$route.params.code}" to clipboard`
            });
        }
    },
    computed: {
        accountName() {
            if (
                this.adyenAccountHolder &&
                this.adyenAccountHolder.accountHolderDetails &&
                this.adyenAccountHolder.accountHolderDetails.businessDetails
            ) {
                return this.adyenAccountHolder.accountHolderDetails
                    .businessDetails.legalBusinessName;
            } else if (
                this.adyenAccountHolder &&
                this.adyenAccountHolder.accountHolderDetails &&
                this.adyenAccountHolder.accountHolderDetails.individualDetails
            ) {
                return (
                    this.adyenAccountHolder.accountHolderDetails
                        .individualDetails.name.firstName +
                    ' ' +
                    this.adyenAccountHolder.accountHolderDetails
                        .individualDetails.name.lastName
                );
            } else return false;
        },
        requiresMoreInformation() {
            if (!this.paymentsEnabled || !this.payoutsEnabled) {
                return true;
            }
            return false;
        },
        payoutsEnabled() {
            return this.adyenAccountHolder.accountHolderStatus.payoutState
                .allowPayout;
        },
        paymentsEnabled() {
            return !this.adyenAccountHolder.accountHolderStatus.processingState
                .disabled;
        },
        pciCompleted() {
            return this.adyenAccountHolder.pciCompleted;
        }
    }
};
</script>
