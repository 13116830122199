<template>
    <div v-if="!isLoading">
        <div class="pb-4 pt-5 bg-white sm:py-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
                <div
                    class="flex flex-shrink-0 items-center justify-center mx-auto w-12 h-12 bg-red-100 rounded-full sm:mx-0 sm:w-10 sm:h-10"
                >
                    <svg
                        class="w-6 h-6 text-red-600"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                        />
                    </svg>
                </div>
                <div class="text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <p class="text-gray-500 text-sm leading-5">
                        Are you sure you want to delete the
                        <span class="text-indigo-600">
                            {{ category.name }}
                        </span>
                        category,
                        <strong>all products</strong> within this category will
                        also be deleted. This action can't be undone.
                    </p>
                </div>
            </div>
        </div>
        <div class="py-3 sm:flex justify-between">
            <span
                class="flex w-full rounded-md shadow-sm sm:w-auto"
                @click="deleteCategory"
            >
                <button
                    type="button"
                    class="inline-flex justify-center px-4 py-2 w-full text-white text-base font-medium leading-6 hover:bg-red-500 bg-red-600 border focus:border-red-700 border-transparent rounded-md focus:outline-none focus:shadow-outline-red shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                >
                    Delete category
                </button>
            </span>
            <span
                class="flex mt-3 w-full rounded-md shadow-sm sm:mt-0 sm:w-auto"
                @click="close"
            >
                <button
                    type="button"
                    class="inline-flex justify-center px-4 py-2 w-full hover:text-gray-500 text-gray-700 text-base font-medium leading-6 bg-white border focus:border-blue-300 border-gray-300 rounded-md focus:outline-none focus:shadow-outline-blue shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                >
                    Close
                </button>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CategoryDeletingSettings',
    props: {
        category: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isLoading: false
        };
    },
    methods: {
        async deleteCategory() {
            this.isLoading = true;

            try {
                await this.$axios.delete(`/categories/${this.category.id}`);

                this.update();
                this.close();
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        },
        close() {
            this.$emit('close');
        },
        update() {
            this.$emit('update');
        }
    }
};
</script>
