<template>
    <tr>
        <td
            class="border-b border-gray-100 px-3 py-2.5 sm:px-4"
        >
            <div class="flex items-center">
                <div
                    class="2xl:w-10 2xl:w-10 h-6 w-6 flex-shrink-0 self-start sm:h-8 sm:w-8"
                >
                    <img
                        v-if="item.image"
                        class="2xl:w-10 2xl:w-10 h-6 w-6 rounded-lg shadow-inner sm:h-8 sm:w-8 print:hidden"
                        :src="item.image + '-/resize/80x80/-/format/auto/'"
                        :alt="item.name"
                    />
                    <div
                        v-else
                        class="2xl:h-10 2xl:w-10 h-6 w-6 rounded-lg bg-gray-200 shadow-inner sm:h-8 sm:w-8 print:hidden"
                    />
                    <div class="hidden h-8 w-8 rounded-md border-gray-400 bg-gray-200 border print:block" />
                </div>
                <div class="ml-3">
                    <div class="text-sm font-medium leading-5 text-gray-900">
                        {{ item.name }}
                    </div>
                    <div v-if="item.modifiers && item.modifiers.length > 0">
                        <div
                            v-for="(modifier, key) in item.modifiers"
                            :key="`${item.id}-${modifier.id}-${key}`"
                            class="ml-1 flex flex-row items-center text-gray-700"
                        >
                            <base-curved-arrow-icon class="h-3 w-3 rotate-180 transform-gpu mr-1" />
                            <span class="pl-1 text-sm leading-5 text-green-600 w-full">
                                {{ modifier.name }}
                            </span>

                        </div>
                    </div>
                    <div v-if="item?.notes?.length > 0" class="ml-1 mt-1 border rounded-md px-1 py-0.5 bg-gray-50 border-dashed inline-flex items-center flex-row whitespace-normal">
                        <base-warning-icon
                            class="max-h-4 max-w-4 text-yellow-700 inline-block"
                        />
                        <span class="pl-1 text-sm leading-5 text-gray-500">
                              {{ item.notes }}
                          </span>
                      </div>
                </div>
            </div>
        </td>
        <td
            :class="{
                'align-top': item.modifiers && item.modifiers.length > 0
            }"
            class="whitespace-nowrap border-b border-gray-100 px-3 py-2.5 text-right sm:px-4"
        >
            <div
                class="inline rounded-md bg-sk-silver-grey px-2 py-1 font-mono text-sm font-semibold leading-5 text-gray-900"
            >
                x{{ item.quantity }}
            </div>
        </td>
        <td
            :class="{
                'align-top': item.modifiers && item.modifiers.length > 0
            }"
            class="whitespace-nowrap border-b border-gray-100 px-3 py-2.5 text-right font-mono sm:px-4"
        >
            <div class="text-sm leading-5 text-gray-900">
                {{ (countItemPrice(item) / 100) | currency }}
            </div>
        </td>
    </tr>
</template>

<script>
export default {
    name: 'OrderMenuItem',
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    methods: {
        countItemPrice(item) {
            let price = item.price * item.quantity;

            if (item.modifiers) {
                item.modifiers.map(modifier => {
                    price += modifier.price * item.quantity;
                });
            }
            return price;
        }
    }
};
</script>
