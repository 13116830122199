<template>
    <modal
        name="klarna-modal"
        :adaptive="true"
        height="auto"
        width="1000px"
        style="z-index: 9999"
    >
        <div
            class="w-full transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 shadow-xl transition-all sm:p-6"
        >
            <div class="relative overflow-hidden bg-white">
                <div class="relative">
                    <div
                        class="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8"
                    >
                        <div
                            class="mx-auto max-w-xl px-4 sm:px-6 lg:mx-0 lg:max-w-none lg:py-16 lg:px-0"
                        >
                            <div>
                                <div class="mt-6">
                                    <div class="mb-3"
                                        ><img
                                            src="https://ucarecdn.com/3e6f845d-0f9b-4e99-b1c7-74d8cd817723/-/format/auto/"
                                            alt="Enable Klarna payments"
                                            class="mb-2 w-14"
                                    /></div>
                                    <h2
                                        class="text-xl font-extrabold tracking-tight text-gray-900"
                                        >FAQs
                                    </h2>
                                    <p class="mt-4 text-sm text-gray-700"
                                        >Enable guests to buy now and pay later
                                        with Klarna
                                    </p>
                                    <ul class="mt-4">
                                        <li>
                                            <h5
                                                class="text-sm font-bold text-gray-700"
                                                >Will it cost me extra?
                                            </h5>
                                            <p
                                                class="mt-1 text-sm text-gray-600"
                                                >Klarna transactions are charged
                                                at 3.9% + 20p. That’s the only
                                                cost; you won’t incur any set up
                                                fee or credit risk and this will
                                                drive larger purchases.
                                            </p>
                                        </li>
                                        <li class="mt-4">
                                            <h5
                                                class="text-sm font-bold text-gray-700"
                                                >When do I get paid?
                                            </h5>
                                            <p
                                                class="mt-1 text-sm text-gray-600"
                                                >Klarna settlements follow the
                                                same rules as card payments –
                                                you won’t notice a difference in
                                                payout timing for Klarna orders.
                                            </p>
                                        </li>
                                        <li class="mt-4">
                                            <h5
                                                class="text-sm font-bold text-gray-700"
                                                >What if a customer never pays
                                                their balance?
                                            </h5>
                                            <p
                                                class="mt-1 text-sm text-gray-600"
                                                >Klarna takes on all the credit
                                                risk – you’ll get paid upfront
                                                and regardless of missed or late
                                                customer payments.
                                            </p>
                                        </li>
                                        <li class="mt-4">
                                            <p
                                                class="mt-1 text-sm text-gray-600"
                                                >Visit
                                                <a
                                                    href="https://www.klarna.com/uk/business/"
                                                    target="_blank"
                                                    class="underline"
                                                    rel="noopener"
                                                    >klarna.com</a
                                                >
                                                for more information
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="py-8 lg:py-16">
                            <div
                                class="-mr-48 pl-4 sm:pl-6 md:-mr-16 lg:relative lg:m-0 lg:mt-8 lg:h-full lg:px-0"
                            >
                                <video
                                    class="h-auto w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:left-0"
                                    src="https://ucarecdn.com/aa9dc234-4d5e-4230-8e63-26d7ef78c80f/"
                                    autoplay
                                    muted
                                ></video>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-5 sm:mt-4 sm:flex sm:justify-end sm:pl-4">
                <span
                    class="mt-3 flex w-full self-end rounded-md shadow-sm sm:mr-3 sm:mt-0 sm:w-auto"
                >
                    <button
                        @click="hideModal()"
                        type="button"
                        class="focus:outline-none inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium leading-6 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline sm:text-sm sm:leading-5"
                    >
                        Close
                    </button>
                </span>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    name: 'KlarnaModal',
    data() {
        return {};
    },
    methods: {
        hideModal() {
            this.$modal.hide('klarna-modal');
        }
    }
};
</script>
