<template>
    <modal
        name="add-table-area-modal"
        width="600px"
        height="auto"
        :clickToClose="true"
        @before-open="beforeOpen"
        :adaptive="true"
    >
        <div
            class="transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 shadow-xl transition-all sm:w-full sm:p-6"
        >
            <div>
                <div class="mt-3 text-left sm:mt-5">
                    <h3 class="text-lg font-medium leading-6 text-gray-900">
                        Add an area
                    </h3>
                    <div class="mt-2">
                        <!--<p class="text-sm leading-5 text-gray-500">-->
                        <!--Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur amet labore.-->
                        <!--</p>-->
                    </div>
                    <div class="mt-3">
                        <label
                            for="name"
                            class="block text-sm font-medium leading-5 text-gray-700"
                            >Name</label
                        >
                        <div class="relative mt-1 rounded-md shadow-sm">
                            <input
                                id="name"
                                v-model="area.name"
                                class="form-input block w-full sm:text-sm sm:leading-5"
                                :class="{
                                    'border-red-300': $v.area.name.$error
                                }"
                                autocomplete="off"
                                placeholder="eg Bar, Beer Garden"
                            />
                        </div>
                        <!--<p class="mt-2 text-sm text-gray-500">Enter the name/title of the product</p>-->
                    </div>
                    <!--                    <div class="mt-3">-->
                    <!--                        <label for="description" class="block text-sm font-medium leading-5 text-gray-700">Description</label>-->
                    <!--                        <div class="mt-1 relative rounded-md shadow-sm">-->
                    <!--                            <textarea id="description" v-model="category.description" class="form-input block w-full sm:text-sm sm:leading-5" />-->
                    <!--                        </div>-->
                    <!--                        &lt;!&ndash;<p class="mt-2 text-sm text-gray-500">Enter the description</p>&ndash;&gt;-->
                    <!--                    </div>-->
                </div>
            </div>
            <div
                class="mt-5 sm:mt-6 sm:flex sm:w-auto"
                :class="{ spinner: isLoading }"
            >
                <span class="flex w-full rounded-md shadow-sm sm:w-56">
                    <button
                        @click="createArea()"
                        :disabled="isLoading"
                        type="button"
                        class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo sm:text-sm sm:leading-5"
                    >
                        Create Area
                    </button>
                </span>
                <span
                    class="mt-3 flex w-full rounded-md shadow-sm sm:ml-3 sm:mt-0 sm:w-auto"
                >
                    <button
                        @click="$modal.hide('add-table-area-modal')"
                        type="button"
                        class="focus:outline-none inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium leading-6 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline sm:text-sm sm:leading-5"
                    >
                        Close
                    </button>
                </span>
            </div>
        </div>
    </modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
    name: 'AddTableAreaModal',
    components: {},
    validations: {
        area: {
            name: {
                required
            },
            description: {}
        }
    },
    data() {
        return {
            isLoading: false,
            venueId: null,
            area: {
                name: ''
            }
        };
    },
    methods: {
        beforeOpen(event) {
            this.venueId = event.params.venueId;
            this.area.name = '';
        },
        createArea() {
            this.$v.area.$touch();
            if (this.$v.area.$invalid) {
                this.errors = 'ERROR';
            } else {
                this.isLoading = true;
                let area = this.area;

                this.$axios
                    .post('/venues/' + this.venueId + '/areas', area)
                    .then(() => {
                        this.$v.$reset();
                        this.$modal.hide('add-table-area-modal');
                        // Reset to inital state https://stackoverflow.com/questions/35604987/is-there-a-proper-way-of-resetting-a-components-initial-data-in-vuejs
                        this.$emit('table-area-added');
                        // Object.assign(this.$data, this.$options.data())
                    })
                    .catch(e => {
                        throw new Error(`API ${e}`);
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            }
        }
    }
};
</script>

<style scoped>
.spinner:after {
    left: auto;
    right: 1em;
    border-right-color: indigo;
}
</style>
