<template>
  <div class="pb-4">
    <div class="overflow-hidden bg-white shadow sm:rounded-md border border-gray-100">
      <div class="flex flex-row p-2 px-2">
        <div class="flex-shrink-0">
          <img alt="" />
        </div>
        <div class="px-2 py-2">
          <div class="mb-2 flex items-center">
            <h4
                class="font-heading text-sm font-bold tracking-wide text-gray-600"
            >
              Upgrade to storekit plus</h4
            >
          </div>
          <p class="mb-4 text-sm text-gray-600"
          >Access exclusive tools that help you reach and retain more customers, convert more checkouts, and make more money. Plus, the lowest transaction rates of any plan.
          </p>
          <router-link :to="{ name: 'upgrade' }">
            <base-button
                button-text="Request upgrade"
                size="sm"
            />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UpgradeCard'
};
</script>
