<template>
    <div class="pl-1 pt-1 sm:pl-3 sm:pt-3 lg:hidden print:hidden">
        <button
            @click="$store.commit('ui/UPDATE_SIDEBAR', true)"
            class="focus:outline-none -ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 transition duration-150 ease-in-out hover:text-gray-900 focus:bg-gray-200"
        >
            <svg
                class="h-6 w-6"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
            >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 6h16M4 12h16M4 18h16"
                />
            </svg>
        </button>
    </div>
</template>

<script>
export default {
    name: 'ToggleSidebarButton'
};
</script>

<style scoped></style>
