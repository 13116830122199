<template>
    <div class="mx-auto mt-10 max-w-7xl p-4 px-4 sm:mt-0 sm:px-6">
        <div class="bg-white shadow sm:rounded-lg">
            <div class="px-4 py-5 sm:p-6">
                <h3
                    class="font-heading text-lg font-medium leading-6 text-gray-900"
                >
                    Create your first store
                </h3>
                <div class="mt-2 sm:flex sm:items-start sm:justify-between">
                    <div class="max-w-xl text-sm leading-5 text-gray-500">
                        <p>
                            Before you can start taking orders online, you'll
                            need to setup your store and create a menu. It'll
                            only take a few minutes!
                        </p>
                    </div>
                    <div
                        class="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center"
                    >
                        <base-button
                            @clicked="$router.push({ name: 'createVenue' })"
                            buttonText="Create your store"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CreateVenueCard'
};
</script>

<style scoped></style>
