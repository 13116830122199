<template>
    <div
        class="flex w-full cursor-pointer select-none items-center justify-between border-b px-3 py-1 hover:bg-gray-50 transition duration-300 ease-in-out"
        :class="isOpened ? 'border-gray-100' : 'border-gray-200'"
        @click="isOpened = !isOpened"
    >
        <span class="text-sm font-semibold leading-9 text-gray-700">
            {{ title }}
        </span>
        <span class="text-sm font-medium text-gray-400">
            <base-chevron-icon :class="{ 'rotate-180 transform': isOpened }" />
        </span>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        },
        value: {
            type: Boolean,
            required: true
        }
    },

    computed: {
        isOpened: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    }
};
</script>
