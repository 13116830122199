<template>
  <div>
    <div class="md:flex md:items-center md:justify-between">
      <div class="min-w-0 flex-1">
        <router-link
            :to="{ name: 'editVenue' }"
            class="mb-2 flex items-center text-sm text-gray-500"
        >
          <base-back-icon />
          <span>Settings</span>
        </router-link>
        <base-page-title title="Fresh KDS Integration" />
      </div>

      <BaseSpinner :isLoading="isLoading" />
    </div>
    <div
        v-if="!isLoading && !showSettings"
        class="py-8"
    >
      <FeaturePanelWithScreenshot
          title="Send orders to FreshKDS "
          description="Manage order prep paperlessly with Fresh KDS"
          :features="nashFeatures"
          image="https://ucarecdn.com/44ba65b5-1476-4e9a-bc26-f2daf69bba7b/-/quality/smart_retina/-/format/auto/"
      >
        <template #cta>
          <base-button
              v-if="isFeatureAvailable(FeaturesEnum.SAAS) || isFeatureAvailable(FeaturesEnum.INTERNAL)"
              class="mt-2 sm:ml-3 sm:mt-0"
              @clicked="showSettings = true"
              buttonText="Enable Fresh KDS"
          />
          <base-upsell-button
              v-else
              upsell-item="freshkds"
              buttonText="Upgrade to enable FreshKDS"
              :disabled="isLoading"
          ></base-upsell-button>
        </template>
      </FeaturePanelWithScreenshot>
    </div>
    <div v-if="!isLoading && showSettings">
      <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="sm:pl-0 sm:pr-5">
            <h3
                class="text-base font-semibold leading-6 text-gray-800"
            >Details</h3
            >
            <p class="mt-2 text-sm text-gray-500">
              Enter your Fresh KDS API Key and we'll send delivery
              and pickup orders to nash so you can manage your
              own delivery driver fleet.
              <br /><br />You'll need a FreshKDS account,
              <a
                  class="text-indigo-600"
                  href="https://www.fresh.technology/"
                  rel="noopener"
                  target="_blank"
              >create one here</a
              >.
            </p>
          </div>
        </div>
        <div class="mt-5 md:col-span-2 md:mt-0" v-if="!isLoading">
          <div class="shadow sm:overflow-hidden sm:rounded-md">
            <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
              <div class="grid grid-cols-3 gap-6">
                <div class="col-span-3">
                  <base-input-field
                      label="Fresh KDS API Token"
                      id="apiKey"
                      type="password"
                      autocomplete="none"
                      v-model.trim="freshkds.apiToken"
                      :errors="$v.freshkds.apiToken.$error"
                      placeholder="eg xxxxxxxxxx.XXXXXXXXXXXXXXXXXXXX"
                      errorMessage="That API key doesn't look correct, please double check."
                  />
                </div>
                <div class="col-span-6" v-if="locations">
                  <label for="locationId" class="block">Nash package requirements</label>
                  <select name="locationId" id="locationId" v-model="locations" class="rounded-md shadow-sm">
                    <option v-for="location in locations" :key="location.id" :value="location.id">{{ location.name }} ({{ location.address?.city }}</option>
                  </select>
                </div>

<!--                <div class="sm:col-span-6">-->
<!--                  <label-->
<!--                      for="orderStatus"-->
<!--                      class="block text-sm font-medium leading-5 text-gray-700"-->
<!--                  >Order status</label-->
<!--                  >-->
<!--                  <select-->
<!--                      id="model"-->
<!--                      name="model"-->
<!--                      v-model.trim="-->
<!--                                            nash.orderStatusTrigger-->
<!--                                        "-->
<!--                      class="focus:outline-none form-input mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"-->
<!--                  >-->
<!--                    <option-->
<!--                        v-for="orderStatus in orderStatuses"-->
<!--                        :key="orderStatus"-->
<!--                        :value="orderStatus"-->
<!--                    >-->
<!--                      {{ orderStatus }}-->
<!--                    </option>-->
<!--                  </select>-->
<!--                </div>-->
                <div class="sm:col-span-6">
                  <label
                      for="enabled"
                      class="block text-sm font-medium leading-5 text-gray-700"
                  >Integration Status</label
                  >

                  <div class="mt-2 flex items-start">
                    <toggle-switch
                        v-model="freshkds.enabled"
                    />

                    <p class="pl-4 text-sm text-gray-500"
                    >{{
                        freshkds.enabled
                            ? 'Enabled - sending orders to FreshKDS'
                            : 'Disabled - not sending orders to FreshKDS'
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BaseSpacerWithRuler />

      <div class="my-6 ml-4 flex flex-shrink-0 justify-end">
      <span class="inline-flex rounded-md shadow-sm" @click="saveVenue">
          <button
              type="button"
              class="focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
              :disabled="isLoading"
          >
              Save
          </button>
      </span>
      </div>
    </div>



  </div>
</template>

<script>
import { minLength, required } from 'vuelidate/lib/validators';
import ToggleSwitch from '@/components/formComponents/ToggleSwitch';
import {mapGetters} from "vuex";
import { FeaturesEnum } from '@/enums';
import FeaturePanelWithScreenshot from "@/components/widgets/FeaturePanelWithScreenshot.vue";


export default {
  name: 'FreshKdsSettings',
  metaInfo: {
    title: 'Fresh KDS Integration'
  },
  props: {
    venueId: {
      type: Number,
      required: true
    }
  },
  components: {
    FeaturePanelWithScreenshot,
    ToggleSwitch
  },
  data() {
    return {
      isLoading: false,
      showSettings: false,
      freshkds: {
        enabled: false,
        apiToken: null,
        deviceIds: null,
        locationId: null,
        orderTypes: null
      },
      locations: [],
      devices: [],
      FeaturesEnum,
      nashFeatures: [
        {
          "name": "Eliminate errors",
          "description": "Paper tickets are confusing, expensive, and inaccurate - resulting in unhappy customers, wasted food, and unnecessary costs."
        },
        {
          "name": "Take control of your workflow",
          "description": "Multiple view modes and themes (including take out order management screen), bump-bar compatible, and third-party integrations work in concert to best suit your business' needs."
        },
        {
          "name": "Take action based on data-backed insights",
          "description": "In-app and corresponding web-app metrics provide invaluable insights into your operation. These provide minute by minute bump times that lead to impactful changes."
        }
      ]

    };
  },
  validations: {
    freshkds: {
      apiToken: {
        required,
        minLength: minLength(10)
      },
      enabled: {
        required
      }
    }
  },
  computed: {
    ...mapGetters({
      isFeatureAvailable: 'user/isFeatureAvailable'
    })
  },
  async created() {
    await this.fetchFreshKdsSettings();
  },
  methods: {
    async fetchFreshKdsSettings() {
      try {
        this.isLoading = true;

        const { data } = await this.$axios.get(
            '/venues/' + this.venueId + '/settings/freshkds'
        );

        if (data[0].freshkds) {
          this.showSettings = true;
          this.freshkds = {
            ...data[0].freshkds
          };
        }
        if(this.freshkds.apiToken) {
          const { data } = await this.$axios.get(
              '/venues/' + this.venueId + '/freshkds/locations'
          );

          this.locations = data;
        }
        if(this.freshkds.locationId) {
          const { data } = await this.$axios.get(
              '/venues/' + this.venueId + '/freshkds/devices'
          );

          this.locations = data;
        }
      } catch (e) {
        throw new Error(`API ${e}`);
      } finally {
        this.isLoading = false;
      }
    },
    async saveVenue() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.errors = 'ERROR';
        return false;
      }

      try {
        this.isLoading = true;

        await this.$axios.put(
            '/venues/' + this.venueId + '/settings/freshkds',
            this.freshkds
        );

        this.$notify({
          group: 'settings',
          title: 'Settings saved'
        });
      } catch (error) {
        if (
            error.response &&
            error.response.data &&
            error.response.data.message &&
            error.response.data.message.includes('key_invalid')
        ) {
          this.$modal.show('custom-error-modal', {
            text:
                "Sorry, that FreshKDS API key doesn't seem to work. Please double check and try again."
          });
          this.nash.enabled = false;
        }

        throw new Error(`API ${error}`);
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>
