<template>
    <modal
        name="edit-pre-order-date-time-modal"
        :adaptive="true"
        height="auto"
        width="700px"
        @before-open="beforeOpen"
    >
        <div
            class="transform overflow-hidden rounded-lg bg-white shadow-xl transition-all sm:w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
        >
            <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div>
                    <div class="text-center sm:text-left">
                        <div class="flex items-center justify-between">
                            <h3
                                class="mb-3 font-heading text-lg leading-6 text-gray-900"
                            >
                                Change this order's date & time slot
                            </h3>
                            <div
                                class="h-4 w-4"
                                :class="{ 'text-gray-900 spinner': isLoading }"
                            >
                                <span></span>
                            </div>
                        </div>
                        <div>
                            <p class="text-sm leading-5 text-gray-500">
                                To change please select one of the available
                                days and then the time slot. This won't update
                                any external systems like your POS or delivery
                                system.
                            </p>
                        </div>
                        <div class="my-5 flex flex-col sm:flex-row">
                            <div
                                class="relative mb-2 w-full sm:mb-4 sm:w-5/12 md:mb-0 md:pr-2"
                            >
                                <label
                                    for="pre-order-day"
                                    class="block text-sm font-medium text-gray-700"
                                >
                                    Day
                                </label>
                                <div class="rounded-md shadow-sm">
                                    <select
                                        v-model="selectedDay"
                                        id="pre-order-day"
                                        class="focus:outline-none mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    >
                                        <option
                                            v-if="!days.length"
                                            disabled
                                            selected
                                            hidden
                                            :value="null"
                                        >
                                            Sorry, no days available
                                        </option>
                                        <option
                                            v-for="(day, index) in days"
                                            :key="index"
                                            :value="day"
                                        >
                                            {{
                                                $moment(day).format(
                                                    'ddd, D MMM'
                                                )
                                            }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div
                                class="relative mb-2 w-full sm:mb-4 md:mb-0 md:pl-2"
                            >
                                <label
                                    for="pre-order-time"
                                    class="block text-sm font-medium text-gray-700"
                                >
                                    Time slot
                                </label>
                                <!--                                <base-select-dropdown :options="slots"  />-->
                                <div class="rounded-md shadow-sm">
                                    <select
                                        v-model="selectedSlot"
                                        id="pre-order-time"
                                        class="focus:outline-none mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    >
                                        <option
                                            v-if="!slots.length"
                                            disabled
                                            selected
                                            hidden
                                            :value="null"
                                        >
                                            Sorry, no slots left today
                                        </option>

                                        <option
                                            v-for="(slot, index) in slots"
                                            :key="index"
                                            :value="slot.time"
                                        >
                                            {{ slot.time }}
                                            <span v-if="slot.isFull">
                                                (Full)</span
                                            >
                                            - {{ slot.orders }} orders -
                                            {{ slot.items }} items - £{{
                                                slot.value / 100
                                            }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <base-checkbox
                            v-if="order?.isPreOrder"
                            v-model="cancelPrintJobs"
                            id="cancelPrintJobs"
                            label="Cancel pre-order print jobs"
                            class="mt-4"
                        />
                        <base-checkbox
                            v-if="order?.isPreOrder"
                            v-model="requeuePrintJobs"
                            id="requeuePrintJobs"
                            label="Re-queue pre-order print jobs"
                            class="mt-4"
                        />
                        <base-checkbox
                            v-if="items?.orkestro"
                            v-model="rebookOrkestroJob"
                            id="requeueOrkestroJob"
                            label="Cancel & rebook orkestro delivery"
                            class="mt-4"
                        />
                        <base-checkbox
                            v-if="items?.deliverect"
                            v-model="requeueDeliverectOrder"
                            id="requeueDeliverectOrder"
                            label="Reschedule deliverect order"
                            class="mt-4"
                        />
                    </div>
                </div>
            </div>
            <div class="flex justify-between bg-gray-50 px-6 py-3">
                <button
                    @click="hideModal"
                    :disabled="isLoading"
                    type="button"
                    class="focus:outline-none inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium leading-6 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                >
                    Cancel
                </button>
                <button
                    @click="updatePreOrderDateTime"
                    :disabled="!isValidated()"
                    class="focus:outline-none inline-flex items-center rounded-md border border-indigo-300 bg-indigo-600 px-4 py-2 text-xs font-medium text-white shadow-sm focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                    :class="[
                        isValidated()
                            ? 'hover:bg-indigo-500'
                            : 'cursor-blocked cursor-not-allowed opacity-50'
                    ]"
                >
                    Save changes
                </button>
            </div>
        </div>
    </modal>
</template>

<script>
import { FulfillmentMethodsEnum } from '@/enums';

export default {
    name: 'EditPreOrderDateTimeModal',
    data() {
        return {
            order: null,
            isLoading: false,
            items: null,
            isSlotsLoading: false,
            selectedDay: null,
            selectedSlot: null,
            days: [],
            slots: [],
            cancelPrintJobs: false,
            requeuePrintJobs: false,
            rebookOrkestroJob: false,
            requeueDeliverectOrder: false
        };
    },
    watch: {
        async selectedDay() {
            this.slots = await this.checkTimeSlotAccessibility();
        }
    },
    methods: {
        hideModal() {
            this.$modal.hide('edit-pre-order-date-time-modal');
        },
        async beforeOpen({ params }) {
            this.order = params.order;
            await this.fetchDays();
            await this.fetchScheduledItems();
            this.slots = await this.checkTimeSlotAccessibility();
        },
        isValidated() {
            return this.selectedSlot && this.selectedDay;
        },
        async fetchScheduledItems() {
          this.isLoading = true;
          try {
            const { data } = await this.$axios.get(
                `/orders/${this.order.id}/scheduled-items`
            );

            this.items = data;
          } catch (e) {
            throw new Error(`API ${e}`);
          } finally {
            this.isLoading = false;
          }
        },
        async fetchDays() {
            this.isLoading = true;
            try {
                const { data } = await this.$axios.get(
                    `/venues/${this.order.venueId}/pre-order-days`
                );

                this.days = this.selectPreorderDaysByMethod(data);
                this.selectedDay = this.days[0] || null;
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        },
        selectPreorderDaysByMethod(data) {
            if (
                this.order.fulfillmentMethod === FulfillmentMethodsEnum.DELIVERY
            ) {
                return data.deliveryDates;
            }

            return data.pickupDates;
        },
        async checkTimeSlotAccessibility() {
            this.isSlotsLoading = true;
            const requestedDay = this.$moment(this.selectedDay).format(
                'YYYY-MM-DD'
            );

            try {
                const orderTotalItems = this.order.items.reduce(
                    (total, item) => {
                        return total + item.quantity;
                    },
                    0
                );

                const params = {
                    venueId: this.order.venueId,
                    requestedDay,
                    fulfillmentMethod: this.order.fulfillmentMethod,
                    orderTotal: this.order.total,
                    orderTotalItems
                };

                const { data } = await this.$axios.get(
                    `venues/${this.venueId}/pre-order-slots`,
                    {
                        params
                    }
                );

                if (data.length > 0) {
                    const preOrderDateTime = this.$moment(
                        this.order.preOrderDateTime
                    ).format('HH:mm a');

                    const matchingSlot = data.find(slot =>
                        slot.time.startsWith(preOrderDateTime)
                    );

                    this.selectedSlot = matchingSlot?.time
                        ? matchingSlot.time
                        : data[0].time;

                    return data;
                }

                this.selectedSlot = null;
            } catch (error) {
                throw new Error(
                    `Error while checking time slots accessibility: ${error}`
                );
            } finally {
                this.isSlotsLoading = false;
            }
        },
        setPreOrderDateTime() {
            const timeComponent = this.selectedSlot
                ? this.selectedSlot.substring(0, 8)
                : 0;
            const minutes = this.$moment(timeComponent, 'HH:mm a').minutes();
            const hours = this.$moment(timeComponent, 'HH:mm a').hours();
            const dateTime = this.$moment(this.selectedDay);
            let preOrderDate = dateTime
                .add(minutes, 'minutes')
                .add(hours, 'hours');
            const startDayOfOrderOffset = this.$moment(preOrderDate)
                .startOf('day')
                .utcOffset();
            const endDayOfOrderOffset = this.$moment(preOrderDate)
                .endOf('day')
                .utcOffset();
            const preOrderDateOffset = this.$moment(preOrderDate).utcOffset();

            if (
                startDayOfOrderOffset !== endDayOfOrderOffset &&
                preOrderDateOffset === endDayOfOrderOffset
            ) {
                const diff = endDayOfOrderOffset - startDayOfOrderOffset;

                preOrderDate =
                    diff > 0
                        ? preOrderDate.subtract(Math.abs(diff), 'minutes')
                        : preOrderDate.add(Math.abs(diff), 'minutes');
            }

            return preOrderDate.format();
        },
        async updatePreOrderDateTime() {
            try {
                const { data } = await this.$axios.post(
                    `/orders/${this.order.id}/pre-order-date-time`,
                    {
                        preOrderDateTime: this.setPreOrderDateTime(),
                        cancelPrintJobs: this.cancelPrintJobs,
                        requeuePrintJobs: this.requeuePrintJobs,
                        rebookOrkestroJob: this.rebookOrkestroJob,
                        requeueDeliverectOrder: this.requeueDeliverectOrder
                    }
                );

                this.$notify({
                    group: 'settings',
                    title: 'Date of pre-order changed successfully'
                });

                this.hideModal();

                return data;
            } catch (error) {
                if (error.response.data.message.includes('time_slot_expired')) {
                    this.$modal.show('custom-error-modal', {
                        title: 'Time slot expired',
                        text:
                            'Sorry, the time slot you selected is no longer available.'
                    });
                }

                throw new Error(
                    `Error while checking time slots accessibility: ${error}`
                );
            }
        }
    }
};
</script>
