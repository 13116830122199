var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.label)?_c('label',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.tooltip),expression:"tooltip"}],staticClass:"mb-2 flex items-center text-sm font-medium leading-5 text-gray-700",attrs:{"for":_vm.id}},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.labelPostfix)?_c('span',{staticClass:"font-normal text-gray-500"},[_vm._v(" "+_vm._s(_vm.labelPostfix)+" ")]):_vm._e(),(_vm.tooltip)?_c('base-question-icon',{staticClass:"ml-1"}):_vm._e()],1):_vm._e(),_c('div',{staticClass:"flex"},[_c('span',{staticClass:"focus:outline-none relative block h-6 w-11 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:shadow-outline",class:[
                _vm.value ? 'bg-indigo-600' : 'bg-gray-200',
                _vm.isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'
            ],attrs:{"role":"checkbox","tabindex":"0","aria-checked":_vm.value.toString()},on:{"click":_vm.onChange}},[_c('span',{staticClass:"relative inline-block h-5 w-5 transform rounded-full bg-white shadow transition duration-200 ease-in-out",class:[_vm.value ? 'translate-x-5' : 'translate-x-0'],attrs:{"aria-hidden":"true"}},[_c('span',{staticClass:"absolute inset-0 flex h-full w-full items-center justify-center transition-opacity",class:[
                        _vm.value
                            ? 'opacity-0 duration-100 ease-out'
                            : 'opacity-100 duration-200 ease-in'
                    ]},[_c('svg',{staticClass:"h-3 w-3 text-gray-400",attrs:{"fill":"none","viewBox":"0 0 12 12"}},[_c('path',{attrs:{"d":"M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2","stroke":"currentColor","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])]),_c('span',{staticClass:"absolute inset-0 flex h-full w-full items-center justify-center transition-opacity",class:[
                        _vm.value
                            ? 'opacity-100 duration-200 ease-in'
                            : 'opacity-0 duration-100 ease-out'
                    ]},[_c('svg',{staticClass:"h-3 w-3 text-indigo-600",attrs:{"fill":"currentColor","viewBox":"0 0 12 12"}},[_c('path',{attrs:{"d":"M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"}})])])]),(_vm.confirm)?_c('toggle-confirm-modal',{attrs:{"options":{
                    title: _vm.confirmTitle,
                    description: _vm.confirmDescription
                }},on:{"confirm":function($event){return _vm.emitChange()}}}):_vm._e()],1),(_vm.extraTextOn || _vm.extraTextOff)?_c('p',{staticClass:"flex items-center pl-4 text-sm text-gray-500"},[_vm._v(" "+_vm._s(_vm.value ? _vm.extraTextOn : _vm.extraTextOff)+" ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }