<template>
    <modal
        name="sort-categories-modal"
        width="600px"
        height="auto"
        :clickToClose="true"
        :adaptive="true"
        @before-open="beforeOpen"
    >
        <div
            class="flex h-12 items-center justify-between px-4 py-3 text-gray-600"
        >
            <h1 class="font-heading text-lg font-medium text-gray-900"
                >Sort categories
            </h1>
            <button @click="$modal.hide('sort-categories-modal')">
                <base-close-icon class="h-6 w-6" />
            </button>
        </div>
        <div class="h-5/6 overflow-scroll bg-gray-100 p-4">
            <div
                v-for="category in categories"
                :key="`${category.id}`"
                class="border-slate-300 flex h-10 items-center border-2 bg-white"
            >
                <div class="flex">
                    <div class="mr-2 flex items-center">
                        <base-ellipsis-vertical-icon />
                        <base-ellipsis-vertical-icon class="-ml-3" />
                    </div>
                    <span
                        class="sm:text-md text-sm font-medium leading-6 text-gray-700"
                    >
                        {{ category.name }}
                    </span>
                </div>
                <div class="mr-4 flex flex-1 justify-end">
                    <button
                        :disabled="
                            category.index === categories.length - 1
                                ? true
                                : false
                        "
                        @click="down(category.index)"
                    >
                        <base-arrow-down-icon
                            :class="
                                category.index === categories.length - 1
                                    ? 'cursor-not-allowed opacity-25'
                                    : ''
                            "
                        />
                    </button>
                    <button
                        :disabled="category.index === 0 ? true : false"
                        @click="up(category.index)"
                        class="mx-2"
                    >
                        <base-arrow-up-icon
                            :class="
                                category.index === 0
                                    ? 'cursor-not-allowed opacity-25'
                                    : ''
                            "
                        />
                    </button>
                </div>
            </div>
        </div>
        <div class="flex justify-between px-2 py-3">
            <button
                @click="$modal.hide('sort-categories-modal')"
                class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
            >
                Close
            </button>
            <button
                @click="save"
                class="focus:outline-none inline-flex items-center rounded-md border border-indigo-300 bg-indigo-600 px-4 py-2 text-xs font-medium text-white shadow-sm hover:bg-indigo-500 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
            >
                Save edits
            </button>
        </div>
    </modal>
</template>

<script>
export default {
    name: 'SortCategoriesModal',
    data() {
        return {
            categories: []
        };
    },
    methods: {
        beforeOpen({ params }) {
            this.categories = params.categoriesToSort.map(
                (category, index) => ({
                    ...category,
                    index
                })
            );
        },
        up(index) {
            this.categories[index].index = index - 1;
            this.categories[index - 1].index = index;

            const item = this.categories[index];
            this.categories.splice(index, 1);
            this.categories.splice(index - 1, 0, item);
        },
        down(index) {
            this.categories[index].index = index + 1;
            this.categories[index + 1].index = index;

            const item = this.categories[index];
            this.categories.splice(index, 1);
            this.categories.splice(index + 1, 0, item);
        },
        async save() {
            const index = this.useAdminIndex ? 'adminIndex' : 'index';

            let indexedCategories = [];

            this.categories.forEach((category, i) => {
                indexedCategories[i] = { id: category.id, index: i };
            });

            try {
                await this.$axios.put('/categories/sort', {
                    categories: indexedCategories,
                    index
                });
                this.$emit('sorted');
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.$modal.hide('sort-categories-modal');
            }
        }
    }
};
</script>
