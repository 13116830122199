<template>
    <modal
        name="resend-order-email-confirmation-modal"
        width="600px"
        height="auto"
        :clickToClose="true"
        @before-open="beforeOpen"
        :adaptive="true"
    >
        <div
            class="pb-4 pt-5 px-4 bg-white rounded-lg shadow-xl overflow-hidden transform transition-all sm:p-6 sm:w-full"
        >
            <div>
                <div class="text-left">
                    <h3 class="text-gray-900 text-lg font-heading font-medium leading-6">
                        Resend order receipt
                    </h3>
                    <div class="mt-2">
                        <p class="text-sm leading-5 text-gray-500">
                            Resend the order confirmation email to the customer.
                            You can optionally specify a different email
                            address.
                        </p>
                    </div>
                    <div class="mt-3">
                        <label
                            for="email"
                            class="block text-gray-700 text-sm font-medium leading-5"
                        >
                            Email Address
                        </label>
                        <div class="relative mt-1 rounded-md shadow-sm">
                            <input
                                id="email"
                                v-model.trim="email"
                                class="form-input block w-full sm:text-sm sm:leading-5"
                                :class="{
                                    'border-red-300': $v.email.$error
                                }"
                                placeholder="customer@example.com"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="mt-5 sm:flex justify-end sm:mt-6 sm:w-auto"
                :class="{ spinner: isLoading }"
            >
                <span
                    class="flex mt-3 w-full rounded-md shadow-sm sm:mt-0 sm:w-auto"
                >
                    <button
                        @click="
                            $modal.hide('resend-order-email-confirmation-modal')
                        "
                        type="button"
                        class="inline-flex justify-center px-4 py-2 w-full hover:text-gray-500 text-gray-700 text-base font-medium leading-6 bg-white border focus:border-blue-300 border-gray-300 rounded-md focus:outline-none focus:shadow-outline shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    >
                        Cancel
                    </button>
                </span>
                <span class="flex w-full rounded-md shadow-sm sm:w-56 sm:ml-3">
                    <button
                        @click="sendEmail()"
                        :disabled="isLoading"
                        type="button"
                        class="inline-flex justify-center px-4 py-2 w-full text-white text-base font-medium leading-6 hover:bg-indigo-500 bg-indigo-600 border focus:border-indigo-700 border-transparent rounded-md focus:outline-none focus:shadow-outline-indigo shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    >
                        Send Email
                    </button>
                </span>
            </div>
        </div>
    </modal>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';

export default {
    name: 'ResendOrderEmailConfirmationModal',
    components: {},
    validations: {
        email: {
            required,
            email
        }
    },
    data() {
        return {
            isLoading: false,
            email: null,
            orderId: null
        };
    },
    methods: {
        beforeOpen(event) {
            this.email = event.params.customerEmail;
            this.orderId = event.params.orderId;
        },
        hideModal() {
            this.$modal.hide('resend-order-email-confirmation-modal');
        },
        async sendEmail() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return false;
            } else {
                try {
                    await this.$axios.post(
                        `/orders/${this.orderId}/resend-email`,
                        { email: this.email }
                    );

                    this.hideModal();

                    const emailText = `Email was sent successfully`;
                    this.$notify({
                        group: 'settings',
                        duration: 5000,
                        text: emailText,
                        speed: 100
                    });

                    this.isLoading = false;
                } catch (error) {
                    const emailText = `Email failed to send`;
                    this.$notify({
                        group: 'settings',
                        duration: 5000,
                        text: emailText,
                        speed: 100
                    });
                    this.hideModal();
                    this.isLoading = false;
                    throw error;
                }
            }
        }
    }
};
</script>

<style scoped>
.spinner:after {
    left: auto;
    right: 1em;
    border-right-color: indigo;
}
</style>
