<template>
    <div
        class="focus:outline-none relative z-0 flex-1 overflow-y-auto pb-6 pt-2 md:py-6"
    >
        <base-toggle-sidebar-button />

        <div class="mx-auto max-w-7xl px-4 pt-8 sm:px-6 md:px-8 lg:px-8">
            <FeaturePanelWithScreenshot
                title="Improve your online reputation with smart reviews"
                subtitle="Unlock smart reviews with a subscription"
                description="The fastest way to increase your star ratings on google, tripadvisor, yelp and more."
                :features="smartReviewFeatures"
                image="https://ucarecdn.com/1fe90d5a-7e33-4b25-8234-77361aa75490/-/format/auto/-/resize/1500x/"
            >
                <template #cta>
                    <base-upsell-button
                        upsell-item="smart reviews"
                        buttonText="Upgrade to enable smart reviews"
                    ></base-upsell-button>
                    <base-button
                        class="mt-2 sm:ml-3 sm:mt-0"
                        href="https://storekit.com/our-products/features/smart-reviews"
                        buttonText="Learn more"
                    >
                        <template #left-icon>
                            <base-doc-icon class="mr-2 h-4 w-4" />
                        </template>
                    </base-button>
                </template>
            </FeaturePanelWithScreenshot>
        </div>
    </div>
</template>

<script>
import FeaturePanelWithScreenshot from '@/components/widgets/FeaturePanelWithScreenshot';

export default {
    name: 'SmartReviews',
    metaInfo: {
        title: 'Smart reviews'
    },
    components: {
        FeaturePanelWithScreenshot
    },
    data() {
        return {
            smartReviewFeatures: [
                {
                    name: 'Boost your online reputation.',
                    description:
                        'Automatically prompt only happy guests to leave reviews on Google, TripAdvisor, Yelp and more. Keep unhappy guests in-house to resolve issues before they leave a negative review.'
                },
                {
                    name: 'Easy guest feedback.',
                    description:
                        'Guests are prompted in realtime at the point of ordering or paying the bill to maximise feedback response rate.'
                }
            ]
        };
    }
};
</script>
