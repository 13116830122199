<template>
    <div>
        <div class="mt-4 md:grid md:gap-6 md:grid-cols-3">
            <div class="md:col-span-1">
                <div class="sm:pl-0 sm:pr-5">
                    <h3 class="text-gray-800 text-base font-semibold leading-6"
                        >Details</h3
                    >
                    <p class="mt-2 text-gray-500 text-sm">
                        Customize your Smart Payment Buttons
                    </p>
                </div>
            </div>
            <div class="mt-5 md:col-span-2 md:mt-0">
                <div class="shadow sm:rounded-md sm:overflow-hidden">
                    <div class="px-4 py-5 bg-white sm:p-6">
                        <div class="mb-4">
                            <img
                                src="@/assets/paypal-logo.svg"
                                alt="Customize Smart Payment Buttons"
                                class="mb-2 w-24"
                            />
                        </div>
                        <div>
                            <label
                                class="block text-gray-700 text-sm font-bold leading-5 sm:mt-px sm:pt-2"
                            >
                                Size:
                            </label>
                            <div class="p-2">
                                <label
                                    class="block text-gray-700 text-sm font-medium leading-5 sm:mt-px sm:pt-2"
                                    >Width (in px):</label
                                >
                                <input
                                    v-model="buttonStyles.size.width"
                                    class="form-input block w-full sm:text-sm sm:leading-5"
                                    :class="{
                                        'border-red-300':
                                            $v.buttonStyles.size.width.$error
                                    }"
                                    placeholder="eg 500"
                                    type="number"
                                    min="100"
                                    max="750"
                                />

                                <div
                                    v-if="$v.buttonStyles.size.width.$error"
                                    class="mt-2 text-red-700"
                                >
                                    Available Interval : (100px - 750px)
                                </div>
                            </div>
                            <div class="p-2">
                                <label
                                    class="block text-gray-700 text-sm font-medium leading-5 sm:mt-px sm:pt-2"
                                    >Height (in px):</label
                                >
                                <input
                                    v-model="buttonStyles.size.height"
                                    class="form-input block w-full sm:text-sm sm:leading-5"
                                    :class="{
                                        'border-red-300':
                                            $v.buttonStyles.size.height.$error
                                    }"
                                    placeholder="eg 50"
                                    type="number"
                                    min="25"
                                    max="55"
                                />

                                <div
                                    v-if="$v.buttonStyles.size.height.$error"
                                    class="mt-2 text-red-700"
                                >
                                    Available Interval : (25px - 55px)
                                </div>
                            </div>
                        </div>
                        <div class="p-2">
                            <label
                                class="block text-gray-700 text-sm font-bold leading-5 sm:mt-px sm:pt-2"
                                >Shape:</label
                            >

                            <div class="mt-1 rounded-md shadow-sm">
                                <select
                                    id="shape"
                                    v-model="buttonStyles.shape"
                                    class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                    :class="{
                                        'border-red-300':
                                            $v.buttonStyles.shape.$error
                                    }"
                                >
                                    <option
                                        v-for="avaiableShape in avaiableShapes"
                                        :value="avaiableShape"
                                        :key="avaiableShape"
                                        >{{ avaiableShape }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="p-2">
                            <label
                                class="block text-gray-700 text-sm font-bold leading-5 sm:mt-px sm:pt-2"
                                >Colour:</label
                            >

                            <div class="mt-1 rounded-md shadow-sm">
                                <select
                                    id="colour"
                                    v-model="buttonStyles.colour"
                                    class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                    :class="{
                                        'border-red-300':
                                            $v.buttonStyles.colour.$error
                                    }"
                                >
                                    <option
                                        v-for="avaiableColour in avaiableColours"
                                        :value="avaiableColour"
                                        :key="avaiableColour"
                                        >{{ avaiableColour }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="mr-4 my-6 text-right">
                        <span
                            class="inline-flex rounded-md shadow-sm"
                            @click="saveStyles"
                        >
                            <button
                                type="button"
                                class="relative inline-flex items-center px-4 py-2 text-white text-sm font-medium leading-5 hover:bg-indigo-500 bg-indigo-600 active:bg-indigo-700 border focus:border-indigo-700 border-transparent rounded-md focus:outline-none focus:shadow-outline-indigo"
                                :disabled="isLoading"
                            >
                                Save
                            </button>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { required, between } from 'vuelidate/lib/validators';

export default {
    props: {
        venueId: {
            type: Number,
            required: true,
            default: 0
        }
    },
    validations: {
        buttonStyles: {
            size: {
                width: { required, between: between(100, 750) },
                height: { required, between: between(25, 55) }
            },
            shape: {
                required
            },
            colour: {
                required
            }
        }
    },

    data() {
        return {
            isLoading: false,
            buttonStyles: {
                size: {
                    width: null,
                    height: null
                },
                shape: '',
                colour: ''
            },
            avaiableShapes: ['rect', 'pill'],
            avaiableColours: ['gold', 'blue', 'silver', 'black', 'white']
        };
    },
    mounted() {
        this.getButtonStyles();
    },
    methods: {
        saveStyles() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return false;
            }

            this.updateButtonStyles(this.buttonStyles);
        },
        async getButtonStyles() {
            try {
                this.isLoading = true;
                const { data } = await this.$axios.get(
                    '/paypal/button-styles',
                    {
                        params: { venueId: this.venueId }
                    }
                );

                this.buttonStyles = data;
            } catch (error) {
                throw new Error(`API Response ${error}`);
            } finally {
                this.isLoading = false;
            }
        },
        async updateButtonStyles() {
            try {
                await this.$axios.put('/paypal/button-styles', {
                    buttonStyles: this.buttonStyles,
                    venueId: this.venueId
                });

                this.$notify({
                    group: 'settings',
                    title: 'Settings saved'
                });
            } catch (error) {
                throw new Error(`API Response ${error}`);
            }
        }
    }
};
</script>
