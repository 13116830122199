<template>
  <div>
    <div class="md:flex md:items-center md:justify-between">
      <div class="min-w-0 flex-1">
        <router-link
            :to="{ name: 'editVenue' }"
            class="mb-2 flex items-center text-sm text-gray-500 inline-flex"
        >
          <base-back-icon />
          <span>Settings</span>
        </router-link>
        <base-page-title title="Pedal Me Integration" />
      </div>

      <BaseSpinner :isLoading="isLoading" />
    </div>
      <div v-if="!isLoading && !showSettings" class="py-8">
        <FeaturePanelWithScreenshot
            title="Pedal me london courier"
            description="Sell direct for delivery, without having your own drivers."
            :features="pedalMeFeatures"
            image="https://ucarecdn.com/44ba65b5-1476-4e9a-bc26-f2daf69bba7b/-/quality/smart_retina/-/format/auto/"
        >
          <template #cta>
            <base-button
                v-if="
                    isFeatureAvailable(FeaturesEnum.SAAS) ||
                    isFeatureAvailable(FeaturesEnum.INTERNAL)
                "
                class="mt-2 sm:ml-3 sm:mt-0"

                @clicked="showSettings = true"
                buttonText="Enable Pedal Me"
            />
            <base-upsell-button
                v-else
                upsell-item="pedalme"
                buttonText="Upgrade to enable Pedal Me"
                :disabled="isLoading"
            ></base-upsell-button>
          </template>
        </FeaturePanelWithScreenshot>
      </div>
    <div>
      <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="sm:pl-0 sm:pr-5">
            <h3
                class="text-base font-semibold leading-6 text-gray-800"
            >Details</h3
            >
            <p class="mt-2 text-sm text-gray-500">
              Enter your Klaviyo API key. We'll embed the klaviyo tracking script on all
              pages on your store, and send relevant conversion
              information.
            </p>
          </div>
        </div>
        <div class="mt-5 md:col-span-2 md:mt-0">
          <div class="shadow sm:overflow-hidden sm:rounded-md">
            <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
              <div class="grid grid-cols-3 gap-6">
                <div class="col-span-3 sm:col-span-2">
                  <base-input-field
                      label="Klaviyo API Key"
                      id="pixelId"
                      v-model="settings.klaviyoApiKey"
                      :errors="
                                            $v.settings.klaviyoApiKey.$error
                                        "
                      placeholder="e.g. 12345678901234"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <BaseSpacerWithRuler />

    <div class="my-6 ml-4 flex flex-shrink-0 justify-end">
            <span class="inline-flex rounded-md shadow-sm" @click="saveVenue">
                <button
                    type="button"
                    class="focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                    :disabled="isLoading"
                >
                    Save
                </button>
            </span>
    </div>

    <!--        <FooterHelp label="notices" link="https://storekit.com" />-->
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import FeaturePanelWithScreenshot from "@/components/widgets/FeaturePanelWithScreenshot.vue";
import {mapGetters} from "vuex";
import { FeaturesEnum } from '@/enums';


export default {
  name: 'PedalMeSettings',
  components: {FeaturePanelWithScreenshot},
  metaInfo: {
    title: 'Pedal Me Integration'
  },
  props: ['venueId', 'venue'],
  data() {
    return {
      FeaturesEnum,
      isLoading: false,
      showSettings: false,
      settings: { ...this.venue },
      pedalme: {},
      orderStatuses: ['Accepted', 'Preparing', 'Out for Delivery'],
      pedalMeFeatures: [
        {
          name: 'No commission fees',
          description:
              'Tap into the best fleets of delivery drivers, reducing costs of third-party delivery by up to 75%.'
        },
        {
          name: 'Pick your vehicles',
          description:
              'Create your own rules for how vehicle types are offered delivery jobs - like cars for orders over £100!'
        },
        {
          name: 'End-to-end tracking',
          description:
              'Keep your customers (and your teams) in the know with order tracking, from acceptance to delivery.'
        }
      ]
    };
  },
  validations: {
    settings: {
      klaviyoApiKey: {
        required
      }
    }
  },
  async created() {
    await this.fetchPedalMeSettings();
  },
  computed: {
    ...mapGetters({
      isFeatureAvailable: 'user/isFeatureAvailable',
      venue: 'venues/getVenue'
    }),
  },
  methods: {
    async fetchPedalMeSettings() {
      try {
        this.isLoading = true;
        const { data } = await this.$axios.get(`/venues/${this.venueId}/settings/pedalme`)
        this.pedalme = data;
      }
      catch (e) {
        throw new Error(e)
      }
      finally {
        this.isLoading = false;
      }
    },
    async saveVenue() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.errors = 'ERROR';
        return false;
      }

      try {
        this.isLoading = true;

        const payload = {
          klaviyoApiKey: this.settings.klaviyoApiKey
        };
        // eslint-disable-next-line
        const response = await this.$axios.put(
            '/venues/' + this.venueId,
            payload
        );

        this.$notify({
          group: 'settings',
          title: 'Settings saved'
        });
      } catch (e) {
        throw new Error(`API ${e}`);
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>
