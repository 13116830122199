import moment from 'moment';

export const checkSelectedStartDate = startDate => {
    const today = moment().startOf('day');

    let out = { startDate: {} };

    if (moment(startDate).isBefore(today)) {
        out.startDate = {
            message: 'Start date cannot be earlier than today',
            level: 'error'
        };
    }

    return out;
};

export const checkDateRange = (startDate, endDate) => {
    let out = {
        startDate: {},
        endDate: {}
    };

    if (moment(endDate).isBefore(moment(startDate))) {
        out.endDate = {
            message: 'End date cannot be before start date',
            level: 'error'
        };
    }

    if (moment(startDate).isAfter(moment(endDate))) {
        out.startDate = {
            message: 'Start date cannot be after end date',
            level: 'error'
        };
    }

    return out;
};

export const checkDateFormat = (startDate, endDate) => {
    const format = 'YYYY-MM-DD HH:mm';

    let out = {
        startDate: {},
        endDate: {}
    };

    const isValidStartDate = moment(startDate, format, true).isValid();
    const isValidEndDate = moment(endDate, format, true).isValid();

    if (!isValidStartDate) {
        out.startDate = {
            message: 'Invalid format date',
            level: 'error'
        };
    }

    if (!isValidEndDate) {
        out.endDate = {
            message: 'Invalid format date',
            level: 'error'
        };
    }

    return out;
};

export default {
    checkSelectedStartDate,
    checkDateRange,
    checkDateFormat
};
