<template>
    <div
        v-if="isFeatureAvailable(FeaturesEnum.INTERNAL) || isFeatureAvailable(FeaturesEnum.ITEM_IDS)"
        @click="copyToClipboard"
        class="inline-block cursor-pointer rounded-md bg-sk-black px-3 py-1 font-mono font-semibold leading-none text-sk-yellow shadow-md"
    >
        <span class="text-xs">
            {{ id ? id : 'No ID Yet' }}
        </span>
    </div>
</template>

<script>
import { FeaturesEnum } from '@/enums';
import { mapGetters } from 'vuex';
export default {
    name: 'AdminIdTag',
    props: {
        id: {
            type: [String, Number],
            required: true
        }
    },
    data() {
        return {
            FeaturesEnum
        };
    },
    computed: {
        ...mapGetters({
            isFeatureAvailable: 'user/isFeatureAvailable'
        })
    },
    methods: {
        async copyToClipboard() {
            await navigator.clipboard.writeText(this.id);
            this.$notify({
                group: 'settings',
                title: 'Copied to clipboard'
            });
        }
    }
};
</script>
