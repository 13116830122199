<template>
    <modal
        name="delay-modal"
        :adaptive="true"
        height="auto"
        width="600px"
        @before-open="beforeOpen"
    >
        <div
            class="bg-white rounded-lg shadow-xl overflow-hidden transform transition-all sm:w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
        >
            <div class="pb-4 pt-5 px-4 bg-white sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start">
                    <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <div class="flex items-center justify-between">
                            <h3
                                class="mb-3 text-gray-900 text-lg font-heading font-medium leading-6"
                            >
                                Delay this order
                            </h3>
                            <div
                                class="w-4 h-4"
                                :class="{ 'spinner text-gray-900': isLoading }"
                            >
                                <span></span>
                            </div>
                        </div>
                        <div>
                            <p class="text-gray-500 text-sm leading-5">
                                How much additional time do you want to add to
                                the estimated time? We'll notify your customer
                                about the delay by email and SMS.
                            </p>
                        </div>
                        <div
                            class="grid gap-4 grid-cols-2 mt-4"
                            :class="{
                                'opacity-50 pointer-events-none': isLoading
                            }"
                        >
                            <button
                                @click="delayOrder(1)"
                                type="button"
                                class="inline-flex justify-center px-4 py-2 w-full hover:text-gray-500 text-gray-700 text-base font-medium leading-6 bg-white border focus:border-blue-300 border-gray-300 rounded-md focus:outline-none focus:shadow-outline shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            >
                                1 Min
                            </button>
                            <button
                                @click="delayOrder(2)"
                                type="button"
                                class="inline-flex justify-center px-4 py-2 w-full hover:text-gray-500 text-gray-700 text-base font-medium leading-6 bg-white border focus:border-blue-300 border-gray-300 rounded-md focus:outline-none focus:shadow-outline shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            >
                                2 Min(s)
                            </button>
                            <button
                                @click="delayOrder(5)"
                                type="button"
                                class="inline-flex justify-center px-4 py-2 w-full hover:text-gray-500 text-gray-700 text-base font-medium leading-6 bg-white border focus:border-blue-300 border-gray-300 rounded-md focus:outline-none focus:shadow-outline shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            >
                                5 Min(s)
                            </button>

                            <button
                                @click="delayOrder(10)"
                                type="button"
                                class="inline-flex justify-center px-4 py-2 w-full hover:text-gray-500 text-gray-700 text-base font-medium leading-6 bg-white border focus:border-blue-300 border-gray-300 rounded-md focus:outline-none focus:shadow-outline shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            >
                                10 Min(s)
                            </button>

                            <button
                                @click="delayOrder(20)"
                                type="button"
                                class="inline-flex justify-center px-4 py-2 w-full hover:text-gray-500 text-gray-700 text-base font-medium leading-6 bg-white border focus:border-blue-300 border-gray-300 rounded-md focus:outline-none focus:shadow-outline shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            >
                                20 Min(s)
                            </button>

                            <button
                                @click="delayOrder(30)"
                                type="button"
                                class="inline-flex justify-center px-4 py-2 w-full hover:text-gray-500 text-gray-700 text-base font-medium leading-6 bg-white border focus:border-blue-300 border-gray-300 rounded-md focus:outline-none focus:shadow-outline shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            >
                                30 Min(s)
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="px-4 py-3 bg-gray-50 sm:flex sm:flex-row-reverse sm:px-6"
            >
              <base-button button-text="Cancel" size="sm" @clicked="hideModal" :disabled="isLoading" />
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    name: 'DelayModal',
    data() {
        return {
            order: null,
            isLoading: false
        };
    },
    methods: {
        hideModal() {
            this.$modal.hide('delay-modal');
        },
        beforeOpen({ params }) {
            this.order = params.order;
        },
        async delayOrder(delay) {
            this.isLoading = true;

            try {
                const { data } = await this.$axios.put(
                    `/orders/${this.order.id}/delay`,
                    {
                        delay
                    }
                );

                if (data.notifications) {
                    const emailText = data.notifications.email
                        ? `Email was sent successfully`
                        : `Email failed to send`;

                    this.$notify({
                        group: 'settings',
                        duration: 5000,
                        text: emailText,
                        speed: 100
                    });

                    const smsText = data.notifications.sms
                        ? `Text was sent successfully`
                        : `Text failed to send`;

                    this.$notify({
                        group: 'settings',
                        duration: 5000,
                        text: smsText,
                        speed: 200
                    });
                }

                this.$emit('delay-modal');
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
                this.hideModal();
            }
        }
    }
};
</script>
