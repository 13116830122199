<template>
    <modal
        name="toggle-confirm-modal"
        :adaptive="true"
        height="auto"
        width="500px"
        class="cursor-default"
        style="z-index: 9999"
        :clickToClose="false"
    >
        <div
            class="w-full transform overflow-hidden rounded-lg bg-white p-6 shadow-xl transition-all"
        >
            <div class="relative overflow-hidden bg-white">
                <div class="relative">
                    <span class="text-xl font-semibold text-gray-800">
                        {{ options.title }}
                    </span>
                    <div class="mt-4 text-sm text-gray-600">
                        {{ options.description }}
                    </div>
                </div>
            </div>
            <div class="mt-5 flex w-full">
                <span
                    class="mt-3 flex w-full justify-between rounded-md sm:mt-0"
                >
                    <button
                        @click="hideModal()"
                        type="button"
                        class="focus:outline-none inline-flex w-auto justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium leading-6 text-gray-700 shadow-sm shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline sm:text-sm sm:leading-5"
                    >
                        Close
                    </button>

                    <button
                        @click="confirm()"
                        type="button"
                        class="focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                    >
                        Confirm
                    </button>
                </span>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    name: 'ConfirmModal',
    data() {
        return {};
    },
    props: {
        options: {
            type: Object,
            required: true
        }
    },
    methods: {
        hideModal() {
            this.$modal.hide('toggle-confirm-modal');
        },
        confirm() {
            this.$emit('confirm');
        }
    }
};
</script>
