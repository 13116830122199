var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shadow sm:overflow-hidden sm:rounded-md"},[_c('div',{staticClass:"bg-white px-4 py-5 sm:p-6"},[_c('h4',{staticClass:"text-base font-medium text-gray-700"},[_vm._v("Business owners")]),_c('p',{staticClass:"mt-2 text-sm text-gray-500"},[_vm._v("Enter the details of one person who owns 25% or more of the company. If no person owns more than 25%, enter the details of a person authorised to sign up. ")]),_vm._l((_vm.shareholders),function(shareholder,index){return _c('div',{key:index,staticClass:"mt-4 grid grid-cols-6 gap-6"},[_c('div',{staticClass:"col-span-6 sm:col-span-6 lg:col-span-3"},[_c('label',{staticClass:"block text-sm font-medium leading-5 text-gray-700",attrs:{"for":'shareholderFirstName' + index}},[_vm._v("First Name")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(shareholder.name.firstName),expression:"shareholder.name.firstName",modifiers:{"trim":true}}],staticClass:"focus:outline-none form-input mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5",class:{
                        'border-red-300': _vm.$v[index].name.firstName.$error
                    },attrs:{"id":'shareholderFirstName' + index,"autocomplete":""},domProps:{"value":(shareholder.name.firstName)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(shareholder.name, "firstName", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.$v[index].name.firstName.$error)?_c('span',{staticClass:"text-xs text-red-600"},[_vm._v(" Please enter a valid name ")]):_vm._e()]),_c('div',{staticClass:"col-span-6 sm:col-span-6 lg:col-span-3"},[_c('label',{staticClass:"block text-sm font-medium leading-5 text-gray-700",attrs:{"for":'shareholderLastName' + index}},[_vm._v("Last Name")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(shareholder.name.lastName),expression:"shareholder.name.lastName",modifiers:{"trim":true}}],staticClass:"focus:outline-none form-input mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5",class:{
                        'border-red-300': _vm.$v[index].name.lastName.$error
                    },attrs:{"id":'shareholderLastName' + index},domProps:{"value":(shareholder.name.lastName)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(shareholder.name, "lastName", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.$v[index].name.lastName.$error)?_c('span',{staticClass:"text-xs text-red-600"},[_vm._v(" Please enter a valid name ")]):_vm._e()]),_c('div',{staticClass:"col-span-6"},[_c('label',{staticClass:"block text-sm font-medium leading-5 text-gray-700",attrs:{"for":'shareHolderEmail' + index}},[_vm._v("Email")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(shareholder.email),expression:"shareholder.email",modifiers:{"trim":true}}],staticClass:"focus:outline-none form-input mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5",class:{
                        'border-red-300': _vm.$v[index].email.$error
                    },attrs:{"id":'shareHolderEmail' + index,"required":""},domProps:{"value":(shareholder.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(shareholder, "email", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.$v[index].email.$error)?_c('span',{staticClass:"text-xs text-red-600"},[_vm._v(" Please enter a valid email address ")]):_vm._e()]),_c('div',{staticClass:"col-span-6"},[_c('label',{staticClass:"block text-sm font-medium leading-5 text-gray-700",attrs:{"for":'shareholderPhone' + index}},[_vm._v("Phone number ")]),_c('InternationalTelInput',{attrs:{"id":'shareholderPhone' + index,"error":_vm.$v[index].fullPhoneNumber.$error},model:{value:(shareholder.fullPhoneNumber),callback:function ($$v) {_vm.$set(shareholder, "fullPhoneNumber", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"shareholder.fullPhoneNumber"}}),(_vm.$v[index].fullPhoneNumber.$error)?_c('span',{staticClass:"text-xs text-red-600"},[_vm._v(" Please enter a valid phone number ")]):_vm._e()],1),_c('div',{staticClass:"col-span-6"},[_c('label',{staticClass:"block text-sm font-medium leading-5 text-gray-700",attrs:{"for":'shareholderDob' + index}},[_vm._v("Date of birth (Year - Month - Day) ")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(shareholder.personalData.dateOfBirth),expression:"shareholder.personalData.dateOfBirth",modifiers:{"trim":true}},{name:"mask",rawName:"v-mask",value:('XXXX-XX-XX'),expression:"'XXXX-XX-XX'"}],staticClass:"focus:outline-none form-input mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5",class:{
                        'border-red-300':
                            _vm.$v[index].personalData.dateOfBirth.$error
                    },attrs:{"id":'shareholderDob' + index,"placeholder":"yyyy/mm/dd","type":"tel","required":""},domProps:{"value":(shareholder.personalData.dateOfBirth)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(shareholder.personalData, "dateOfBirth", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.$v[index].personalData.dateOfBirth.$error)?_c('span',{staticClass:"text-xs text-red-600"},[_vm._v(" Please enter a valid date of birth ")]):_vm._e()]),_c('div',{staticClass:"col-span-6 w-full border-t border-gray-200 px-12"}),_c('adyen-address',{attrs:{"$v":_vm.$v,"address":shareholder.address,"index":index,"isIndividual":false}})],1)})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }