<template>
  <div v-auto-animate>
    <div
        @click="navigateToBill"
        class="px-2 py-2 cursor-pointer select-none sm:px-4 sm:py-3 text-gray-700 flex justify-between"
    >
      <div class="flex items-center">
        <base-stacked-cards-icon class="w-5 h-5 "/>
        <span class="text-sm ml-2">View bill</span>
      </div>
      <base-chevron-right-icon
          class="w-4 h-4"
      />
    </div>
  </div>
</template>

<script>

export default {
  name: 'OrderBillDetails',
  props: {
    venueId: {
      type: [String, Number],
      required: true
    },
    orderId: {
      type: String,
      required: true
    },
    billId: {
      type: String,
      required: true
    }
  },
  methods: {
    async navigateToBill() {
      this.$router.push({
        path: '/tables',
        query: { bill: this.billId, venueId: this.venueId }
      });
    }
  }
}
</script>