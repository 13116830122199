<template>
    <sliding-sidebar
        v-model="isOpened"
        title="Live orders filters"
        @on-escape-key-up="close"
    >
        <div>
            <div class="bg-white py-4">
                <div class="">
                    <div class="relative mt-3 text-center sm:mt-0 sm:text-left">
                        <div
                            class="relative mb-3 inline-block w-full text-left md:mb-0"
                        >
                            <div>
                                <span class="rounded-md shadow-sm">
                                    <venue-selector
                                        ref="venueSelector"
                                        class="w-full"
                                        v-if="venues.length"
                                        :venues="venues"
                                        :multiple-select="true"
                                        :allow-empty="true"
                                        :pre-selected="
                                            Array.isArray(this.venueFilter)
                                                ? this.venueFilter
                                                : parseInt(this.venueFilter)
                                        "
                                        @selected="emitVenue"
                                    />
                                </span>
                            </div>
                        </div>

                        <div class="mt-6 flex flex-row items-center">
                            <toggle-switch v-model="showAcceptedOrders" />
                            <span
                                class="ml-2 text-sm font-medium text-gray-500"
                            >
                                Show accepted orders
                            </span>
                        </div>

                        <div class="mt-6 flex flex-row items-center">
                            <toggle-switch v-model="showFailedOrders" />
                            <span
                                class="ml-2 text-sm font-medium text-gray-500"
                            >
                                Show failed orders only
                            </span>
                        </div>

                        <div class="mt-6">
                            <label
                                for="selectedFulfillmentMethods"
                                class="mb-2 block text-sm font-medium leading-5 text-gray-500"
                            >
                                Fulfillment methods
                            </label>
                            <multi-select
                                id="selectedFulfillmentMethods"
                                class="sk-multiselect"
                                :class="{
                                    active: selectedFulfillmentMethods
                                }"
                                multiple
                                v-model="selectedFulfillmentMethods"
                                :close-on-select="false"
                                deselect-label="Remove"
                                placeholder="Select"
                                label="label"
                                track-by="value"
                                :options="fulfillmentMethods"
                                :searchable="false"
                                @input="emitFulfillmentMethods"
                                :allow-empty="false"
                            >
                                <template slot="caret">
                                    <div
                                        class="absolute right-0 top-0 flex min-h-full items-center px-3"
                                    >
                                        <svg
                                            class="h-5 w-5"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                clip-rule="evenodd"
                                            />
                                        </svg>
                                    </div>
                                </template>
                            </multi-select>
                        </div>

                        <div class="mt-6">
                            <label
                                for="selectedTableArea"
                                class="mb-2 block text-sm font-medium leading-5 text-gray-700"
                            >
                                Table Area
                            </label>
                            <multi-select
                                id="selectedTableArea"
                                class="sk-multiselect"
                                :class="{
                                    active: selectedTableArea
                                }"
                                :disabled="!venueTables || !venueTables.length"
                                v-model="selectedTableArea"
                                :close-on-select="false"
                                deselect-label="Remove"
                                placeholder="Select one"
                                label="name"
                                track-by="id"
                                :options="venueTables"
                                :searchable="false"
                            >
                                <template slot="caret">
                                    <div
                                        class="absolute right-0 top-0 flex min-h-full items-center px-3"
                                    >
                                        <svg
                                            class="h-5 w-5"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                clip-rule="evenodd"
                                            />
                                        </svg>
                                    </div>
                                </template>
                            </multi-select>
                        </div>

                        <div class="mt-6">
                            <label
                                for="selectedTable"
                                class="mb-2 block text-sm font-medium leading-5 text-gray-700"
                            >
                                Table
                            </label>
                            <multi-select
                                id="selectedTable"
                                v-model="selectedTable"
                                class="sk-multiselect"
                                :close-on-select="false"
                                :class="{
                                    active: selectedTable
                                }"
                                :disabled="!selectedTableArea"
                                deselect-label="Remove"
                                placeholder="Select one"
                                label="name"
                                track-by="id"
                                :options="
                                    selectedTableArea &&
                                    selectedTableArea.tables
                                        ? selectedTableArea.tables
                                        : []
                                "
                                :searchable="false"
                                @input="emitTable"
                            >
                                <template slot="caret">
                                    <div
                                        class="absolute right-0 top-0 flex min-h-full items-center px-3"
                                    >
                                        <svg
                                            class="h-5 w-5"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                clip-rule="evenodd"
                                            />
                                        </svg>
                                    </div>
                                </template>
                            </multi-select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="justify-between py-3 sm:flex sm:flex-row">
                <span
                    class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto"
                    :class="{ 'opacity-50': isLoading }"
                >
                    <button
                        @click="close"
                        :disabled="isLoading"
                        type="button"
                        class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo sm:text-sm sm:leading-5"
                    >
                        Close
                    </button>
                </span>
                <span
                    class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto"
                    :class="{ 'opacity-50': isLoading }"
                >
                    <button
                        @click="clearFilters"
                        :disabled="isLoading"
                        type="button"
                        class="focus:outline-none inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium leading-6 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                    >
                        Clear filters
                    </button>
                </span>
            </div>
        </div>
    </sliding-sidebar>
</template>

<script>
import SlidingSidebar from '../SlidingSidebar';
import VenueSelector from '@/components/selectors/VenueSelector';
import ToggleSwitch from '@/components/formComponents/ToggleSwitch';
import { FeaturesEnum, FulfillmentMethodsEnum } from '@/enums';
import { mapGetters } from 'vuex';

export default {
    name: 'LiveOrdersFilterSidebar',
    components: {
        SlidingSidebar,
        VenueSelector,
        ToggleSwitch
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        showCompleted: {
            type: Boolean,
            default: false
        },
        showFailed: {
            type: Boolean,
            default: false
        },
        venues: {
            type: Array,
            required: true
        },
        venueTables: {
            type: Array,
            default: () => []
        },
        venueFilter: {
            type: [String, Array],
            default: () => []
        }
    },
    data() {
        const fulfillmentMethods = [
            { label: 'In Store', value: FulfillmentMethodsEnum.IN_STORE },
            { label: 'Delivery', value: FulfillmentMethodsEnum.DELIVERY },
            { label: 'Pickup', value: FulfillmentMethodsEnum.PICKUP }
        ];

        return {
            isLoading: false,
            selectedTableArea: null,
            selectedTable: null,
            FeaturesEnum,
            fulfillmentMethods,
            selectedFulfillmentMethods: fulfillmentMethods
        };
    },
    computed: {
        ...mapGetters({
            isFeatureAvailable: 'user/isFeatureAvailable'
        }),
        isOpened: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        showAcceptedOrders: {
            get() {
                return this.showCompleted;
            },
            set(value) {
                this.$emit('toggleCompleted', value);
            }
        },
        showFailedOrders: {
            get() {
                return this.showFailed;
            },
            set(value) {
                this.$emit('toggleFailed', value);
            }
        }
    },
    watch: {
        selectedTableArea() {
            this.clearSelectedTable();
        }
    },
    methods: {
        emitVenue(val) {
            this.selectedTableArea = null;
            this.$emit('setVenue', val);
        },
        emitFulfillmentMethods(val) {
            this.$emit(
                'setFulfillmentMethods',
                val.map(({ value }) => value)
            );
        },
        emitTable(val) {
            this.$emit('setTable', val ? val.id : null);
        },
        close() {
            this.isOpened = false;
        },
        clearSelectedTable() {
            this.selectedTable = null;
            this.$emit('setTable', null);
        },
        clearFilters() {
            this.$emit('clearFilters');
            this.$refs.venueSelector.updateSelection([]);
            this.selectedFulfillmentMethods = this.fulfillmentMethods;
        }
    }
};
</script>
<style lang="css">
.sk-multiselect.multiselect {
    min-height: 38px !important;
}

.sk-multiselect.multiselect.active .multiselect__tags {
    border-color: #4b5563;
}

.sk-multiselect.multiselect .multiselect__tags {
    min-height: 38px;
    border: 1px solid #d2d6dc;
    box-shadow: none;
}

.sk-multiselect.multiselect .multiselect__content-wrapper {
    min-width: 300px;
    margin-top: 8px;
    border-radius: 5px;
    border-top: 1px solid #e8e8e8;
    right: 0;
    @apply shadow-lg;
}

.sk-multiselect.multiselect .multiselect__content-wrapper * {
    font-weight: 400 !important;
}

.sk-multiselect.multiselect.multiselect--active .multiselect__tags {
    border-radius: 5px !important;
    border-color: #4b5563;
}

.sk-multiselect.multiselect .multiselect__tags .multiselect__tag,
.sk-multiselect.multiselect .multiselect__placeholder {
    margin-bottom: 0;
}

.sk-multiselect.multiselect .multiselect__placeholder {
    display: block !important;
}

.sk-multiselect.multiselect
    .multiselect__content-wrapper
    .multiselect__option--highlight,
.sk-multiselect.multiselect
    .multiselect__content-wrapper
    .multiselect__option--highlight::after {
    background: #f4f5f7 !important;
    color: #374151;
}

.sk-multiselect.multiselect
    .multiselect__content-wrapper
    .multiselect__option--highlight
    * {
    color: #374151;
}

.sk-multiselect.multiselect .multiselect__placeholder,
.sk-multiselect.multiselect .multiselect__single {
    font-weight: 500 !important;
}
</style>
