<template>
    <div class="shadow sm:overflow-hidden sm:rounded-md">
        <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
            <div>
                <label
                    for="code"
                    class="block text-sm font-medium text-gray-700"
                    >Confirmation code
                </label>
                <div class="mt-1">
                    <input
                        v-model="code"
                        v-mask="'####'"
                        id="code"
                        type="tel"
                        class="form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                </div>
            </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 text-right sm:px-6">
            <button
                @click="resendCode"
                type="submit"
                :disabled="isLoading"
                class="focus:outline-none mr-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
            >
                Send again
            </button>
            <button
                @click="confirmPrinter"
                type="submit"
                :disabled="isLoading"
                class="focus:outline-none inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
                Confirm printer
            </button>
        </div>
        <wrong-code-modal @sendAgain="resendCode" />
    </div>
</template>
<script>
import WrongCodeModal from '@/components/modals/WrongCodeModal';

export default {
    components: { WrongCodeModal },
    props: {
        selectedMac: {
            type: String,
            required: true,
            default: ''
        },
        venueId: {
            type: String,
            required: true,
            default: ''
        }
    },
    data() {
        return { code: '', isLoading: false };
    },
    methods: {
        async resendCode() {
            this.isLoading = true;

            const payload = {
                venueId: this.venueId,
                mac: this.selectedMac
            };

            try {
                await this.$axios.post('/printers/resend', payload);
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        },
        async confirmPrinter() {
            this.isLoading = true;

            const payload = {
                code: this.code,
                mac: this.selectedMac
            };

            try {
                const { data } = await this.$axios.post(
                    '/confirm-printer',
                    payload
                );

                if (data) {
                    this.$router.push({ name: 'printerIntegration' });
                    if (window.analytics) {
                        window.analytics.track('Printer Connected', {
                            venueId: this.venueId,
                            mac: this.mac,
                            accountId: data.accountId,
                            context: {
                                groupId: data.accountId
                            }
                        });
                    }
                }
            } catch (error) {
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.message &&
                    error.response.data.message.includes('wrong_code')
                ) {
                    this.$modal.show('wrong-code-modal');
                }
                this.$modal.show();

                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        }
    }
};
</script>
