<template>
    <div class="pb-4">
        <div
            class="mx-auto max-w-3xl overflow-hidden border border-gray-100 bg-white shadow sm:rounded-md"
            v-if="posts.length"
        >
            <carousel
                :hideArrowsOnBound="true"
                class="story-carousel story-carousel--colors"
            >
                <slide
                    v-for="post in posts"
                    :key="post.id"
                    class="story-carousel__slide flex flex-row p-2 px-2"
                >
                    <div class="px-2 py-2">
                        <div class="mb-2 flex items-center">
                            <h4
                                class="font-heading text-sm font-bold tracking-wide text-gray-600 line-clamp-1"
                            >
                                {{ post.title }}
                            </h4>
                            <span
                                class="ml-2 inline-flex items-center rounded-md bg-green-50 px-2 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-300"
                            >
                                New
                            </span>
                        </div>

                        <div
                            v-html="post.summary"
                            :id="`changelog-summary-wrapper-${post.id}`"
                            class="mb-3 text-sm text-gray-600 line-clamp-3"
                        ></div>
                        <base-button
                            v-if="post.url && !isAuthenticated"
                            button-text="Read more"
                            :href="post.url"
                            size="sm"
                        />
                        <router-link
                            v-if="post.url && isAuthenticated"
                            :to="{ path: post.url }"
                        >
                            <base-button button-text="Read more" size="sm" />
                        </router-link>
                    </div>
                </slide>
            </carousel>
        </div>
        <div
            class="flex flex-col overflow-hidden bg-white px-4 py-6 shadow sm:rounded-md"
            v-else
        >
            <div class="pb-3">
                <VclList :width="280" :height="120" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Carousel, Slide } from 'vue-snap';
import 'vue-snap/dist/vue-snap.css';
import { VclList } from 'vue-content-loading';

export default {
    name: 'WhatsNewSlider',
    components: {
        VclList,
        Carousel,
        Slide
    },
    data() {
        return {
            posts: []
        };
    },
    created() {
        this.getLastChangelogPosts();
    },
    computed: {
        ...mapGetters({
            isAuthenticated: 'user/isAuthenticated'
        })
    },
    methods: {
        async getLastChangelogPosts() {
            try {
                const { data } = await this.$axios.get('/changelog');

                this.posts = data.map(post => ({
                    ...post,
                    url: this.isAuthenticated
                        ? `release-notes/changelog/${post.url.split('/').pop()}`
                        : post.url
                }));
            } catch (error) {
                throw new Error(`API ${error}`);
            }
        }
    }
};
</script>

<style src="@/assets/css/components/whats-new-slider.css" />
