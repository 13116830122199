<template>
    <form
        action="#"
        method="POST"
        class="mt-4"
        :class="{
            'cursor-not-allowed opacity-50': !acceptsPreOrders
        }"
    >
        <div class="grid grid-cols-6 gap-6">
            <div
                class="col-span-6 sm:col-span-4"
                s
                v-for="(day, index) in 7"
                :key="`${day}+${index}+PickupTimes`"
            >
                <label
                    :for="'hours-' + index"
                    class="block text-sm font-medium leading-5 text-gray-700"
                >
                    {{ hoursDict[index] }}
                </label>
                <div
                    v-for="(
                        singleInterval, intervalIndex
                    ) in editablePickupSlots"
                    :key="`${singleInterval.day}++${intervalIndex}+PickupTimes`"
                >
                    <div
                        v-if="singleInterval.day === index"
                        class="inline-flex"
                    >
                        <vue-timepicker
                            manual-input
                            :minute-interval="15"
                            :id="'hours-' + intervalIndex"
                            :disabled="
                                !editablePickupSlots[intervalIndex].active
                            "
                            v-model="
                                editablePickupSlots[intervalIndex].openTime
                            "
                            :class="{
                                'opacity-25': !editablePickupSlots[
                                    intervalIndex
                                ].active
                            }"
                        >
                        </vue-timepicker>
                        <span class="px-4 py-2 text-gray-600"> to </span>
                        <vue-timepicker
                            manual-input
                            :minute-interval="15"
                            :disabled="
                                !editablePickupSlots[intervalIndex].active
                            "
                            :class="{
                                'opacity-25': !editablePickupSlots[
                                    intervalIndex
                                ].active
                            }"
                            v-model="
                                editablePickupSlots[intervalIndex].closeTime
                            "
                            :hour-range="[
                                [
                                    editablePickupSlots[
                                        intervalIndex
                                    ].openTime.substring(0, 2),
                                    24
                                ]
                            ]"
                            :input-class="
                                editablePickupSlots[intervalIndex].closeTime ===
                                    END_OF_THE_DAY || END_OF_THE_DAY + ':59'
                                    ? 'skip-error-style'
                                    : ''
                            "
                        >
                        </vue-timepicker>

                        <button
                            v-if="
                                oneDayIntervalsNumber(
                                    index,
                                    editablePickupSlots
                                ) > 1
                            "
                            type="button"
                            @click="
                                deleteTimeInterval(
                                    intervalIndex,
                                    singleInterval.day,
                                    editablePickupSlots
                                )
                            "
                            class="ml-3"
                            :class="{
                                'opacity-25': !editablePickupSlots[
                                    intervalIndex
                                ].active
                            }"
                        >
                            <span
                                class="transition-duration-100 cursor-pointer text-red-400 transition hover:text-red-700"
                            >
                                <svg
                                    class="h-5 w-5"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                    ></path>
                                </svg>
                            </span>
                        </button>
                    </div>
                </div>
                <div class="my-4">
                    <span class="inline-flex rounded-md shadow-sm">
                        <button
                            v-show="!closedArray[index]"
                            @click="
                                addTimeInterval(
                                    editablePickupSlots,
                                    index,
                                    type
                                )
                            "
                            type="button"
                            class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
                        >
                            Add another time interval
                        </button>
                    </span>
                </div>
                <div class="flex items-center space-x-3">
                    <span
                        @click="
                            toggleCloseDay(
                                editablePickupSlots,
                                index,
                                closedArray,
                                type
                            )
                        "
                        role="checkbox"
                        tabindex="0"
                        aria-checked="false"
                        class="focus:outline-none group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center"
                    >
                        <span
                            aria-hidden="true"
                            :class="{
                                'bg-gray-200': !closedArray[index],
                                'bg-indigo-600': closedArray[index]
                            }"
                            class="absolute mx-auto h-4 w-9 rounded-full bg-gray-200 transition-colors duration-200 ease-in-out"
                        ></span>

                        <span
                            aria-hidden="true"
                            :class="{
                                'translate-x-0': !closedArray[index],
                                'translate-x-5': closedArray[index]
                            }"
                            class="absolute left-0 inline-block h-5 w-5 translate-x-0 transform rounded-full border border-gray-200 bg-white shadow transition-transform duration-200 ease-in-out group-focus:border-blue-300 group-focus:shadow-outline"
                        ></span>
                    </span>
                    <span :id="'unavailable-' + day" style="margin-right: 20px">
                        <span
                            class="text-sm font-medium leading-5 text-gray-700"
                            >Unavailable
                        </span>
                    </span>

                    <span class="inline-flex rounded-md shadow-sm">
                        <span
                            @click="
                                toggleAroundTheClock(
                                    editablePickupSlots,
                                    index,
                                    type,
                                    closedArray
                                )
                            "
                            role="checkbox"
                            tabindex="0"
                            aria-checked="false"
                            class="focus:outline-none group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center"
                        >
                            <span
                                aria-hidden="true"
                                :class="{
                                    'bg-gray-200': !isAroundTheClock(
                                        editablePickupSlots,
                                        index
                                    ),
                                    'bg-indigo-600': isAroundTheClock(
                                        editablePickupSlots,
                                        index
                                    )
                                }"
                                class="absolute mx-auto h-4 w-9 rounded-full bg-gray-200 transition-colors duration-200 ease-in-out"
                            ></span>

                            <span
                                aria-hidden="true"
                                :class="{
                                    'translate-x-0': !isAroundTheClock(
                                        editablePickupSlots,
                                        index
                                    ),
                                    'translate-x-5': isAroundTheClock(
                                        editablePickupSlots,
                                        index
                                    )
                                }"
                                class="absolute left-0 inline-block h-5 w-5 translate-x-0 transform rounded-full border border-gray-200 bg-white shadow transition-transform duration-200 ease-in-out group-focus:border-blue-300 group-focus:shadow-outline"
                            ></span>
                        </span>
                    </span>

                    <span :id="'24hours-' + day">
                        <span
                            class="text-sm font-medium leading-5 text-gray-700"
                        >
                            Open 24 hours
                        </span>
                    </span>
                </div>
            </div>


        </div>
    </form>
</template>
<script>
import VueTimepicker from 'vue2-timepicker';
import EditVenueService from '@/services/editVenue.service';
const editVenueService = new EditVenueService();

export default {
    components: {
        VueTimepicker
    },
    props: {
        acceptsPreOrders: {
            type: Boolean,
            required: true
        },
        pickupSlots: {
            type: Array,
            default: () => [],
            required: true
        },
        hoursDict: {
            type: Array,
            default: () => [],
            required: true
        },
        END_OF_THE_DAY: {
            type: String,
            default: '',
            required: true
        },
        validationError: {
            type: Boolean,
            default: false,
            required: true
        }
    },
    watch: {
        editablePickupSlots: {
            deep: true,
            handler(slots) {
                this.$emit('editedPickupTimes', slots);
            }
        }
    },
    data() {
        return {
            closedArray: [false, false, false, false, false, false, false],
            editablePickupSlots: this.pickupSlots,
            type: 'slots'
        };
    },
    created() {
        this.checkForDaysClosed(this.editablePickupSlots, this.closedArray);
    },
    methods: {
        toggleCloseDay(array, day, closedArray, type) {
            const {
                resultArray,
                resultClosedArray
            } = editVenueService.toggleCloseDay(array, day, closedArray);

            this.closedArray = resultClosedArray;
            this.editablePickupSlots = [...resultArray];

            if (this.isAroundTheClock(this.editablePickupSlots, day)) {
                this.toggleAroundTheClock(
                    this.editablePickupSlots,
                    day,
                    type,
                    this.closedArray
                );
                this.toggleCloseDay(
                    this.editablePickupSlots,
                    day,
                    this.closedArray,
                    type
                );
            }
        },
        toggleAroundTheClock(array, day, type, closedArray) {
            const {
                resultArray,
                resultClosedArray
            } = editVenueService.toggleAroundTheClock(
                array,
                day,
                type,
                closedArray
            );

            this.editablePickupSlots = resultArray;
            this.closedArray = resultClosedArray;
        },
        addTimeInterval(array, day, type) {
            this.editablePickupSlots = editVenueService.addTimeInterval(
                array,
                day,
                type
            );
        },
        checkForDaysClosed(array, closedArray) {
            this.closedArray = editVenueService.checkForDaysClosed(
                array,
                closedArray
            );
        },
        deleteTimeInterval(index, day, array) {
            this.editablePickupSlots = editVenueService.deleteTimeInterval(
                index,
                day,
                array
            );
        },
        oneDayIntervalsNumber(day, array) {
            return editVenueService.oneDayIntervalsNumber(day, array);
        },
        isAroundTheClock(array, index) {
            return editVenueService.isAroundTheClock(array, index);
        }
    }
};
</script>
