<template>
    <div>
        <div class="md:flex md:items-center md:justify-between">
            <div class="min-w-0 flex-1">
                <router-link
                    :to="{ name: 'printerIntegration' }"
                    class="mb-2 flex items-center text-sm text-gray-500 inline-flex"
                >
                    <base-back-icon />
                    <span>Printers</span>
                </router-link>
                <base-page-title
                    :title="editMode ? 'Edit printer' : 'Add new printer'"
                />
            </div>

            <BaseSpinner :isLoading="isLoading" />
        </div>
        <div>
            <div class="mt-6 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="px-4 sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                            >Details</h3
                        >
                        <p class="mt-2 text-sm text-gray-500">
                            Setup compatible receipt and ticket printers to
                            print your orders on-demand or automatically. You
                            can check compatibility and buy printers here.
                        </p>
                    </div>
                </div>
                <div v-if="!isLoading" class="mt-5 md:col-span-2 md:mt-0">
                    <CreateEditPrinter
                        v-if="!comfirmMode"
                        :venueId="venueId"
                        :editMode="editMode"
                        :selectedMac="selectedMac"
                    />

                    <ConfirmPrinter
                        v-else
                        :selectedMac="selectedMac"
                        :venueId="venueId"
                    />
                </div>
            </div>
        </div>

        <BaseSpacerWithRuler />

    </div>
</template>

<script>
// import FooterHelp from '../../helpers/FooterHelp';
import CreateEditPrinter from '../../editVenue/CreateEditPrinter';
import ConfirmPrinter from '../../editVenue/ConfirmPrinter';

export default {
    name: 'printerSettings',
    metaInfo: {
        title: 'Cloud Printing'
    },
    props: {
        venueId: {
            type: Number,
            required: true,
            default: 0
        },
        selectedMac: {
            type: String,
            required: false,
            default: ''
        },
        operation: {
            type: String,
            required: false,
            default: ''
        }
    },
    data() {
        return {
            isLoading: false,
            mac: null,
            editMode: false,
            comfirmMode: false
        };
    },
    components: {
        // FooterHelp,
        CreateEditPrinter,
        ConfirmPrinter
    },
    created() {
        this.isLoading = true;

        this.mac = this.selectedMac;
        this.editMode = !!this.operation && this.operation === 'edit';
        this.comfirmMode = this.mac && !this.editMode;

        this.isLoading = false;
    }
};
</script>
