<template>
    <div>
        <div>
            <div class="md:flex md:items-center md:justify-between">
                <div class="min-w-0 flex-1">
                    <router-link
                        :to="{ name: 'paymentSettings' }"
                        class="mb-2 flex items-center text-sm text-gray-500 inline-flex"
                    >
                        <base-back-icon />
                        <span>Payment Settings</span>
                    </router-link>
                    <h2 class="text-2xl font-semibold text-gray-900">
                        PayPal Connection Status
                    </h2>
                </div>

                <div
                    class="h-4 w-4"
                    :class="{ 'text-gray-900 spinner': isLoading }"
                >
                    <span></span>
                </div>
            </div>

            <div class="mt-10">
                <h1 class="text-xl font-semibold text-gray-700"
                    >Successfully connected to PayPal!</h1
                >
            </div>
            <div
                v-for="paypalInfo in paypalInformations"
                :key="paypalInfo.name"
                class="mb-5"
            >
                <div class="rounded-md border border-blue-400 bg-blue-50 p-4">
                    <div class="flex">
                        <div class="flex-shrink-0">
                            <svg
                                class="h-5 w-5 text-blue-400"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                    clip-rule="evenodd"
                                />
                            </svg>
                        </div>
                        <div class="ml-3 flex-1 md:flex md:justify-between">
                            <p
                                v-linkified:options="{
                                    className: 'underline',
                                    attributes: {
                                        rel: 'noreferrer noopener'
                                    }
                                }"
                                class="text-sm text-gray-700"
                            >
                                {{ paypalInfo.content }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex justify-end">
                <router-link :to="{ name: 'paymentSettings' }">
                    <button
                        type="button"
                        class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                        Continue
                    </button>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    name: 'PayPalSettingsSuccess',
    metaInfo: {
        title: 'PayPal Integration Success'
    },
    props: {
        venueId: {
            type: Number,
            default: 0,
            required: true
        }
    },
    data() {
        return {
            paypalInformations: [],
            isLoading: false
        };
    },
    async mounted() {
        if (!Object.keys(this.$route.query).length) {
            this.$router.push({
                name: 'paymentSettings'
            });

            return;
        }

        const { merchantIdInPayPal } = this.$route.query;

        await this.connectPayPalWithDatabase({
            merchantId: merchantIdInPayPal,
            venueId: this.venueId
        });
        await this.getSellerOnboardingStatus(merchantIdInPayPal);
    },
    methods: {
        ...mapActions({
            connectPayPalWithDatabase: 'paypal/connectPayPalWithDatabase'
        }),

        async getSellerOnboardingStatus(merchantId) {
            this.isLoading = true;
            try {
                const { data } = await this.$axios.get(
                    '/paypal/onboarding-status',
                    {
                        params: { merchantId }
                    }
                );

                this.paypalInformations = data.paypalInformations;
            } catch (error) {
                throw new Error(`API Response ${error}`);
            } finally {
                this.isLoading = false;
            }
        }
    }
};
</script>
