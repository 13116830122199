<template>
    <v-dropdown
        distance="16"
        :delay="300"
        :triggers="['click']"
        popperClass="payment-details-popover"
    >
        <div
            class="group flex cursor-pointer select-none items-center rounded-md px-4 py-1 hover:bg-gray-100"
        >
          <span class="">
              <base-credit-card-icon
                  v-if="paymentType !== 'Cash'"
                  class="w-4 h-4 mr-1"
                  :class="{ 'text-red-800': refunds && refunds.length }"
              />
            </span>
            <div class="flex flex-col" :class="{ 'line-through text-red-800': refunds && refunds.length }"> {{ (totalPaid / 100) | currency }} </div>
          <svg
                class="m-auto block h-4 w-4 text-gray-600 opacity-0 group-hover:opacity-100"
                viewBox="0 0 20 20"
                fill="currentColor"
                focusable="false"
                aria-hidden="true"
            >
                <path d="M5 8l5 5 5-5H5z"></path>
            </svg>
        </div>

        <template #popper>
            <div class="p-3">
                <span class="mt-1.5 block text-sm text-gray-500">
                    Total paid: {{ (totalPaid / 100) | currency }}
                </span>
                <span class="mt-1.5 block text-sm text-gray-500">
                    Payment type: {{ paymentType === 'Adyen' ? 'Card' : paymentType }}
                </span>
                <span class="mt-1.5 block text-gray-500" v-if="refunds && refunds.length">
                    Refunds:
                    <ul v-for="(refund, index) of refunds" :key="refund.id">
                        <li class=""
                            >{{ index + 1 }}.
                            {{ (refund.amount / 100) | currency }}</li
                        >
                    </ul>
                </span>
            </div>
        </template>
    </v-dropdown>
</template>

<script>
export default {
    name: 'PaymentDetailsPopover',
    props: {
        paymentType: {
            type: String,
            required: false
        },
        totalPaid: {
            type: Number,
            required: false
        },
        refunds: {
            type: Array,
            required: false
        }
    }
};
</script>

<style>
.v-popper__popper.payment-details-popover .v-popper__wrapper .v-popper__inner {
    width: 250px;
    background: #fff;
    padding: 0;
    color: black;
    border-radius: 5px;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
}

.v-popper__popper.payment-details-popoverr .v-popper__arrow-inner {
    border-color: #f9f9f9;
}
</style>
