<template>
    <div v-if="!isLoading">
        <div>
            <div class="mt-3 text-left sm:mt-5">
                <div class="flex justify-between pb-12">
                    <div>Category always available</div>
                    <div>
                        <toggle-switch v-model="editableAlwaysOpen" />
                    </div>
                </div>
                <form v-show="!editableAlwaysOpen" action="#" method="POST">
                    <div class="grid grid-cols-5 gap-6">
                        <div
                            class="col-span-6 pb-8 sm:col-span-5"
                            :class="{ 'border-b border-gray-200': index < 6 }"
                            v-for="(day, index) in 7"
                            :key="`${day}+${index}+menuCategoryHours`"
                        >
                            <label
                                :for="`hours-${index}`"
                                class="block text-sm font-medium leading-5 text-gray-700"
                            >
                                {{ hoursDict[index] }}
                            </label>
                            <div
                                v-for="(
                                    singleInterval, intervalIndex
                                ) in editableMenuHours"
                                :key="`${singleInterval.day}++${intervalIndex}+VenueHours`"
                            >
                                <div
                                    v-if="singleInterval.day === index + 1"
                                    class="inline-flex"
                                >
                                    <vue-timepicker
                                        :minute-interval="1"
                                        manual-input
                                        :id="`hours-${intervalIndex}`"
                                        :disabled="
                                            openAllDayArray[index] ||
                                            closedArray[index]
                                        "
                                        v-model="
                                            editableMenuHours[intervalIndex]
                                                .openTime
                                        "
                                        :class="{
                                            'opacity-25':
                                                openAllDayArray[index] ||
                                                closedArray[index]
                                        }"
                                        @input="
                                            ensureCloseTimeAfterOpenTime(
                                                intervalIndex
                                            )
                                        "
                                    >
                                    </vue-timepicker>
                                    <span class="px-4 py-2 text-gray-600">
                                        to
                                    </span>
                                    <vue-timepicker
                                        manual-input
                                        :minute-interval="1"
                                        :disabled="
                                            openAllDayArray[index] ||
                                            closedArray[index]
                                        "
                                        :class="{
                                            'opacity-25':
                                                openAllDayArray[index] ||
                                                closedArray[index]
                                        }"
                                        v-model="
                                            editableMenuHours[intervalIndex]
                                                .closeTime
                                        "
                                        :hour-range="
                                            getHourRange(intervalIndex)
                                        "
                                        :minute-range="
                                            getMinuteRange(intervalIndex)
                                        "
                                    >
                                    </vue-timepicker>

                                    <button
                                        v-if="intervalsThisDay(index + 1) > 1"
                                        type="button"
                                        @click="
                                            deleteTimeInterval(
                                                intervalIndex,
                                                singleInterval.day,
                                                editableMenuHours
                                            )
                                        "
                                        class="ml-3"
                                        :class="{
                                            'opacity-25':
                                                openAllDayArray[index] ||
                                                closedArray[index]
                                        }"
                                    >
                                        <span
                                            class="transition-duration-100 cursor-pointer text-red-400 transition hover:text-red-700"
                                        >
                                            <svg
                                                class="h-5 w-5"
                                                fill="none"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                                ></path>
                                            </svg>
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div class="my-4">
                                <span class="inline-flex rounded-md shadow-sm">
                                    <button
                                        @click="
                                            addTimeInterval(
                                                editableMenuHours,
                                                index + 1,
                                                type
                                            )
                                        "
                                        :disabled="
                                            openAllDayArray[index] ||
                                            closedArray[index]
                                        "
                                        type="button"
                                        :class="{
                                            'opacity-25':
                                                openAllDayArray[index] ||
                                                closedArray[index]
                                        }"
                                        class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
                                    >
                                        Add another time interval
                                    </button>

                                    <button
                                        @click="applyToAll(index)"
                                        :disabled="
                                            openAllDayArray[index] ||
                                            closedArray[index]
                                        "
                                        type="button"
                                        :class="{
                                            'opacity-25':
                                                openAllDayArray[index] ||
                                                closedArray[index]
                                        }"
                                        class="focus:outline-none ml-2 inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
                                    >
                                        Apply to all
                                    </button>
                                </span>
                            </div>

                            <div class="flex items-center space-x-3">
                                <toggle-switch
                                    v-model="closedArray[index]"
                                    @input="closeDay(index)"
                                />

                                <span :id="`closed-${day}`">
                                    <span
                                        class="text-sm font-medium leading-5 text-gray-700"
                                        >Closed
                                    </span>
                                </span>

                                <span class="inline-flex rounded-md shadow-sm">
                                    <toggle-switch
                                        v-model="openAllDayArray[index]"
                                        @input="openAllDay(index)"
                                    />
                                </span>

                                <span :id="`24hours-${day}`">
                                    <span
                                        class="text-sm font-medium leading-5 text-gray-700"
                                    >
                                        Open 24 hours
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div
            class="mt-5 justify-between sm:mt-6 sm:flex sm:w-auto"
            :class="{ spinner: isLoading }"
        >
            <span class="flex rounded-md shadow-sm">
                <button
                    @click="saveHours"
                    :disabled="isLoading"
                    type="button"
                    class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo sm:text-sm sm:leading-5"
                >
                    Save changes
                </button>
            </span>
            <span
                class="mt-3 flex w-full rounded-md shadow-sm sm:ml-3 sm:mt-0 sm:w-auto"
            >
                <button
                    @click="close"
                    type="button"
                    class="focus:outline-none inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium leading-6 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline sm:text-sm sm:leading-5"
                >
                    Close
                </button>
            </span>
        </div>
    </div>
</template>

<script>
import VueTimepicker from 'vue2-timepicker';
import EditVenueService from '@/services/editVenue.service';
import ToggleSwitch from '../../formComponents/ToggleSwitch';

const editVenueService = new EditVenueService();

export default {
    name: 'CategoryAvailabilitySettings',
    components: {
        VueTimepicker,
        ToggleSwitch
    },
    props: {
        category: {
            type: Object,
            required: true
        }
    },
    watch: {
        editableAlwaysOpen(bool) {
            if (this.category.alwaysOpen !== bool) {
                this.updateMenuAlwaysOpen(bool);
            }

            if (!bool) {
                this.openAllDayArray = this.openAllDayArray.map(() => true);
                this.closedArray = this.closedArray.map(() => false);
            }
        }
    },
    data() {
        return {
            isLoading: false,
            hoursDict: [
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
                'Sunday'
            ],
            closedArray: [false, false, false, false, false, false, false],
            openAllDayArray: [false, false, false, false, false, false, false],
            editableMenuHours: false,
            editableAlwaysOpen: false,
            type: 'hours'
        };
    },
    mounted() {
        if (this.category) {
            this.editableMenuHours = this.category.hours || [];
            this.editableAlwaysOpen = this.category.alwaysOpen;

            Array.from({ length: 7 }, (x, i) => {
                const menuHours = this.editableMenuHours.filter(
                    hour => hour.day === i + 1
                );

                if (menuHours.some(menuHour => menuHour.openAllDay)) {
                    this.openAllDayArray[i] = true;
                } else if (!menuHours || !menuHours.length) {
                    this.closedArray[i] = true;
                }
            });
        }
    },
    methods: {
        close() {
            this.$emit('close');
        },
        async updateMenuAlwaysOpen(value) {
            try {
                await this.$axios.put(`/categories/${this.category.id}`, {
                    alwaysOpen: value
                });

                this.category.alwaysOpen = value;
            } catch (error) {
                throw new Error(`API ${error}`);
            }
        },
        async saveHours() {
            const updatedHours = [];

            Array.from({ length: 7 }, (x, i) => {
                if (this.openAllDayArray[i] && !this.closedArray[i]) {
                    updatedHours.push({
                        openTime: '00:00',
                        closeTime: '00:00',
                        openAllDay: true,
                        day: i + 1
                    });
                } else if (!this.openAllDayArray[i] && !this.closedArray[i]) {
                    const menuHours = this.editableMenuHours.filter(
                        hour => hour.day === i + 1
                    );

                    menuHours.forEach(hour => (hour.openAllDay = false));

                    updatedHours.push(...menuHours);
                }
            });

            this.isLoading = true;

            updatedHours.forEach(updatedHoursObject => {
                delete updatedHoursObject.updated_at;
                delete updatedHoursObject.created_at;
                if (
                    updatedHoursObject.openTime === '00:00' &&
                    updatedHoursObject.closeTime === '00:00' &&
                    !updatedHoursObject.openAllDay
                ) {
                    updatedHoursObject.openAllDay = true;
                }
            });

            try {
                await this.$axios.put(
                    `/categories/${this.category.id}/hours`,
                    updatedHours
                );

                this.category.hours = updatedHours;
                this.$emit('update');
                this.close();
            } catch (error) {
                this.$notify({
                    group: 'settings',
                    title: 'An error occurred'
                });

                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;

                if (!this.category.alwaysOpen && this.isOpenAllDayEveryDay()) {
                    await this.updateMenuAlwaysOpen(true);
                }
            }
        },
        isOpenAllDayEveryDay() {
            if (!this.openAllDayArray || !this.openAllDayArray.length) {
                return false;
            }

            return this.openAllDayArray.every(element => element === true);
        },
        intervalsThisDay(day) {
            return editVenueService.oneDayIntervalsNumber(
                day,
                this.editableMenuHours
            );
        },
        closeDay(day) {
            this.openAllDayArray[day] = false;
        },
        openAllDay(day) {
            this.closedArray[day] = false;
        },
        addTimeInterval(array, day) {
            this.editableMenuHours.push({
                day,
                openTime: '',
                closeTime: ''
            });
        },
        deleteTimeInterval(index, day, array) {
            this.editableMenuHours = editVenueService.deleteTimeInterval(
                index,
                day,
                array
            );
        },
        applyToAll(index) {
            const neededIntervals = this.editableMenuHours.filter(
                item => item.day === index + 1
            );

            const newIntervals = [];

            for (let i = 1; i <= 7; i++) {
                neededIntervals.forEach(item => {
                    delete item.id;
                    newIntervals.push({
                        ...item,
                        openAllDay: false,
                        day: i
                    });
                });
            }

            this.editableMenuHours = [...newIntervals];
            this.openAllDayArray = [];
            this.closedArray = [];
        },
        getHourRange(index) {
            return [
                [this.editableMenuHours[index].openTime.substring(0, 2), 23]
            ];
        },
        getMinuteRange(index) {
            const openingHour = this.editableMenuHours[
                index
            ].openTime.substring(0, 2);
            const closingHour = this.editableMenuHours[
                index
            ].closeTime.substring(0, 2);

            if (openingHour !== closingHour) {
                return [[0, 59]];
            }

            return [
                [this.editableMenuHours[index].openTime.substring(3, 5), 59]
            ];
        },
        isAfter(openTime, closeTime) {
            const open = this.$moment(openTime, 'HH:mm');
            const close = this.$moment(closeTime, 'HH:mm');

            return open.isAfter(close);
        },
        ensureCloseTimeAfterOpenTime(index) {
            const openTime = this.editableMenuHours[index].openTime;
            const closeTime = this.editableMenuHours[index].closeTime;

            if (this.isAfter(openTime, closeTime)) {
                this.editableMenuHours[
                    index
                ].closeTime = this.editableMenuHours[index].openTime;
            }
        }
    }
};
</script>
