<template>
    <div class="flex w-0 flex-1 flex-col overflow-hidden">
        <ToggleSidebar />

        <main
            class="focus:outline-none relative z-0 flex-1 overflow-y-auto"
            tabindex="0"
        >
            <div class="pb-6 pt-2 md:py-6">
                <div class="mx-auto px-4 sm:px-6 lg:px-8">
                    <div class="md:flex md:items-center md:justify-between">
                        <div class="min-w-0 flex-1">
                            <BasePageTitle title="Discounts" />
                        </div>
                        <div
                            class="mt-4 flex justify-end sm:mr-4 md:ml-4 md:mt-0"
                        >
                            <ExportButton @clicked="showExportCsvModal" />
                            <base-button
                                buttonText="Import"
                                size="sm"
                                @clicked="
                                    $router.push({ name: 'discountsImport' })
                                "
                            />

                          <base-button
                            size="sm"
                            button-text="Create Discount"
                            @clicked="$router.push({ name: 'newDiscount' })"
                            class="ml-3"
                            button-style="action"
                          />
                        </div>
                        <BaseSpinner :isLoading="isLoading" />
                    </div>
                </div>

                <div class="mx-auto px-0 py-4 sm:px-6 md:px-8">
                    <div class="rounded bg-white shadow-md sm:rounded-lg">
                        <div
                            class="flex flex-col justify-between px-2 py-2.5 sm:flex-row sm:px-6"
                        >
                            <ul class="mb-2 flex flex-row items-center sm:mb-0">
                                <li
                                    @click="
                                        updateParams({
                                            status: filter.value,
                                            page: 1
                                        })
                                    "
                                    class="mr-1 cursor-pointer rounded-md px-3 py-1 text-xs transition duration-150 ease-in-out hover:bg-gray-50"
                                    :class="{
                                        'bg-gray-100':
                                            filter.value === serverParams.status
                                    }"
                                    v-for="filter in discountFilters"
                                    :key="filter.value"
                                >
                                    {{ filter.label }}
                                </li>
                            </ul>
                            <div class="flex items-center">
                                <search-input
                                    class="mr-3"
                                    v-model="searchString"
                                    :min="3"
                                    :max="9"
                                    size="sm"
                                    placeholder="Search by code"
                                />
                                <venue-selector
                                    :venues="venues"
                                    :multiple-select="true"
                                    :allow-empty="true"
                                    :key="venues ? venues.length : 0"
                                    @selected="onVenueChange"
                                    size="sm"
                                />
                            </div>
                        </div>
                        <vue-good-table
                            mode="remote"
                            :columns="columns"
                            :rows="discountCodes"
                            styleClass="tailwind-table"
                            class="border-b border-gray-200"
                            row-style-class="mb-1 cursor-pointer"
                            @on-row-click="goToDiscountDetails"
                            @on-page-change="onPageChange"
                            @on-sort-change="onSortChange"
                            @on-per-page-change="onPerPageChange"
                            :totalRows="totalRecords"
                            :pagination-options="{
                                enabled: true,
                                perPage: serverParams.limit,
                                dropdownAllowAll: false,
                                rowsPerPageLabel: '',
                                perPageDropdown: [10, 20, 50, 100, 200],
                                setCurrentPage: serverParams.page
                            }"
                        >
                            <template slot="table-row" slot-scope="props">
                                <div
                                    v-if="props.column.field === 'code'"
                                    class="group flex items-center"
                                >
                                    <div>
                                        <div
                                            class="flex items-center font-mono text-sm font-bold leading-5 text-gray-600"
                                        >
                                          {{ props.row.code.toUpperCase() }}
                                          <div
                                              @click.stop="copyToClipboard(props.row.code.toUpperCase())"
                                              class="opacity-0 inline-flex group-hover:opacity-100 mx-1 transition duration-150 ease-in-out">
                                              <base-clipboard-icon v-tooltip="'Click to copy code'" class="w-4 h-4" />
                                            </div>
                                            <span
                                                v-if="props.row.autoApply"
                                                class="inline-flex items-center rounded-md bg-green-50 px-2 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-300"
                                                >AUTO</span
                                            >
                                        </div>
                                        <div
                                            v-if="
                                                props.row.type ===
                                                DiscountTypeEnum.FIXED
                                            "
                                            class="text-xs leading-5 text-gray-500"
                                        >
                                            {{
                                                (props.row.codeAmount / 100)
                                                    | currency
                                            }}
                                            off entire order<span
                                                v-if="props.row.excludeDelivery"
                                                >, excluding delivery</span
                                            >
                                        </div>
                                        <div
                                            v-if="
                                                props.row.type ===
                                                DiscountTypeEnum.PERCENT
                                            "
                                            class="text-xs leading-5 text-gray-500"
                                        >
                                            {{
                                                (
                                                    props.row.codeAmount / 10
                                                ).toFixed(1)
                                            }}% off entire order<span
                                                v-if="props.row.excludeDelivery"
                                                >, excluding delivery</span
                                            >
                                        </div>
                                        <div
                                            v-if="
                                                props.row.type ===
                                                DiscountTypeEnum.FREE_DELIVERY
                                            "
                                            class="text-xs leading-5 text-gray-500"
                                        >
                                            free delivery
                                        </div>
                                        <div
                                            v-if="
                                                props.row.type ===
                                                DiscountTypeEnum.BUY_ONE_GET_ONE_FREE
                                            "
                                            class="text-xs leading-5 text-gray-500"
                                        >
                                            buy one get
                                            {{ props.row.codeAmount / 10 }}% off
                                            the second
                                        </div>
                                    </div>
                                </div>
                                <status-badge
                                    v-else-if="
                                        props.column.field === 'archived'
                                    "
                                    :status="discountStatus(props.row)"
                                />
                                <div
                                    v-else-if="
                                        props.column.field === 'usageCount'
                                    "
                                    class="text-sm leading-5 text-gray-500"
                                >
                                    {{ props.row.usageCount }}/{{
                                        props.row.usageLimit === 0
                                            ? '&infin;'
                                            : props.row.usageLimit
                                    }}
                                </div>
                                <span v-else>
                                    {{ props.formattedRow[props.column.field] }}
                                </span>
                            </template>
                            <template slot="emptystate">
                                <div
                                    class="flex h-40 w-full items-center justify-center bg-white"
                                >
                                    <h3
                                        v-if="!isLoading && totalRecords === 0"
                                        class="text-sm font-medium text-gray-700"
                                    >
                                        No discounts found for the selected
                                        options
                                    </h3>
                                    <span v-else class="spinner"></span>
                                </div>
                            </template>
                            <template slot="loadingContent">
                                <div
                                    class="flex h-40 w-full items-center justify-center bg-white"
                                >
                                    <span class="spinner"></span>
                                </div>
                            </template>
                        </vue-good-table>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import ToggleSidebar from '../components/common/ToggleSidebar';
import ExportButton from '../components/helpers/ExportButton';
import VenueSelector from '../components/selectors/VenueSelector';
import { FeaturesEnum, DiscountTypeEnum } from '@/enums';
import { mapActions, mapGetters } from 'vuex';
import SearchInput from '@/components/widgets/SearchInput';
import StatusBadge from '@/components/StatusBadge';

export default {
    name: 'DiscountsList',
    metaInfo: {
        title: 'Discounts'
    },
    components: {
        StatusBadge,
        SearchInput,
        ToggleSidebar,
        ExportButton,
        VenueSelector
    },
    data() {
        return {
            FeaturesEnum,
            DiscountTypeEnum,
            discountCodes: [],
            isLoading: true,
            discountCodesReport: [],
            discountFilters: [
                {
                    label: 'All',
                    value: 'all'
                },
                {
                    label: 'Active',
                    value: 'active'
                },
                {
                    label: 'Scheduled',
                    value: 'scheduled'
                },
                {
                    label: 'Expired',
                    value: 'expired'
                },
                {
                    label: 'Archived',
                    value: 'archived'
                }
            ],
            discountFilter: 'All',
            csvTitle: 'Discounts',
            fields: [
                { label: 'Code', value: 'code' },
                { label: 'Type', value: 'type' },
                { label: 'Amount', value: 'amount' },
                { label: 'Description', value: 'desc' },
                { label: 'Times Used', value: 'usage' }
            ],
            columns: [
                {
                    label: 'Code',
                    field: 'code',
                    sortable: false,
                    tdClass:
                        'px-6 py-2 whitespace-nowrap text-sm leading-5 font-medium text-gray-900',
                    thClass:
                        'px-6 bg-gray-100 py-2 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600'
                },
                {
                    label: 'Status',
                    field: 'archived',
                    sortable: true,
                    firstSortType: 'desc',
                    type: 'boolean',
                    tdClass:
                        'px-6 py-2 whitespace-nowrap text-sm leading-5 font-medium text-gray-900',
                    thClass:
                        'px-6 bg-gray-100 py-2 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600'
                },
                {
                    label: 'Used',
                    field: 'usageCount',
                    sortable: true,
                    firstSortType: 'desc',
                    type: 'number',
                    tdClass:
                        'px-6 py-2 whitespace-nowrap text-sm leading-5 font-medium text-gray-900',
                    thClass:
                        'px-6 bg-gray-100 py-2 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600'
                }
            ],
            venueFilter: '',
            searchString: '',
            totalRecords: 0,
            serverParams: {
                page: 1,
                limit: 20,
                sortBy: '',
                sortOrder: '',
                venueIds: null,
                status: 'all',
                q: ''
            }
        };
    },
    computed: {
        ...mapGetters({
            isFeatureAvailable: 'user/isFeatureAvailable',
            venues: 'venues/getVenues'
        }),
        csvData() {
            return this.discountCodesReport.map(discount => ({
                ...discount
            }));
        }
    },
    async created() {
        if (!this.venues?.length > 0) {
            await this.fetchVenues();
        }
        await this.fetchDiscounts();
    },
    watch: {
        searchString(value) {
            const searchString = value.toLowerCase();

            const searchValueConditions =
                searchString.length >= 3 &&
                searchString.length <= 30 &&
                this.serverParams.q !== searchString;

            const clearSearchResults = !searchString && this.serverParams.q;

            if (searchValueConditions || clearSearchResults) {
                this.updateParams({ q: searchString });
            }
        }
    },
    methods: {
        ...mapActions({
            fetchVenues: 'venues/fetch'
        }),
        goToDiscountDetails(params) {
            this.$router.push({
                name: 'editDiscount',
                params: { id: params.row.id }
            });
        },
        async fetchDiscounts() {
            this.isLoading = true;
            try {
                const {
                    data: { results, total }
                } = await this.$axios.get('/discount-codes?', {
                    params: this.serverParams
                });

                for (const discount of results) {
                    const amount =
                        discount.type === DiscountTypeEnum.FIXED
                            ? this.$options.filters.currency(
                                  discount.codeAmount / 100
                              )
                            : discount.codeAmount / 10 + '%';
                    const code = discount.code.toUpperCase();
                    const type = discount.type;
                    const desc = discount.description;
                    const usage = discount.usageCount;

                    this.discountCodesReport.push({
                        code,
                        type,
                        amount,
                        desc,
                        usage
                    });
                }

                this.totalRecords = total;
                this.discountCodes = results;
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
            this.fetchDiscounts();
        },
        onPageChange(params) {
            this.updateParams({ page: params.currentPage });
        },
        onPerPageChange(params) {
            this.updateParams({ limit: params.currentPerPage });
        },
        onVenueChange(venueIds) {
            this.updateParams({ venueIds, page: 1 });
            this.venueFilter = venueIds;
        },
        onSortChange(params) {
            this.updateParams({
                page: 1,
                sortOrder: params[0].type,
                sortBy: params[0].field
            });
        },
        showExportCsvModal() {
            this.$modal.show('export-csv-modal', {
                header: this.csvTitle,
                csvData: this.csvData,
                fields: this.fields
            });
        },
        discountStatus(discount) {
            if (!discount.archived) {
                const date = new Date();
                if (new Date(discount.startDate) > date) {
                    return 'Scheduled';
                } else if (
                    (discount.endDate && new Date(discount.endDate) < date) ||
                    (discount.usageCount === discount.usageLimit &&
                        discount.usageLimit !== 0)
                ) {
                    return 'Expired';
                } else {
                    return 'Active';
                }
            }
            return 'Archived';
        },
        async copyToClipboard(code) {
          await navigator.clipboard.writeText(code);
          this.$notify({
            group: 'settings',
            title: `Copied code ${code} to clipboard`
          });
        }
    }
};
</script>
