const compareArray = (arrayA, arrayB) => {
    if (arrayA.length !== arrayB.length) {
        return false;
    }

    const uniqueValues = new Set([...arrayA, ...arrayB]);

    for (const value of uniqueValues) {
        const arrayACount = arrayA.filter(arrayValue => arrayValue === value)
            .length;

        const arrayBCount = arrayB.filter(arrayValue => arrayValue === value)
            .length;

        if (arrayACount !== arrayBCount) {
            return false;
        }
    }

    return true;
};

export default compareArray;
