<template>
    <div class="shadow sm:overflow-hidden sm:rounded-md">
        <div class="bg-white px-4 py-5 sm:p-6">
            <h4 class="text-base font-medium text-gray-700">Business owners</h4>
            <p class="mt-2 text-sm text-gray-500"
                >Enter the details of one person who owns 25% or more of the
                company. If no person owns more than 25%, enter the details of a
                person authorised to sign up.
            </p>
            <div
                class="mt-4 grid grid-cols-6 gap-6"
                v-for="(shareholder, index) in shareholders"
                :key="index"
            >
                <div class="col-span-6 sm:col-span-6 lg:col-span-3">
                    <label
                        :for="'shareholderFirstName' + index"
                        class="block text-sm font-medium leading-5 text-gray-700"
                        >First Name</label
                    >
                    <input
                        :id="'shareholderFirstName' + index"
                        v-model.trim="shareholder.name.firstName"
                        autocomplete=""
                        class="focus:outline-none form-input mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                        :class="{
                            'border-red-300': $v[index].name.firstName.$error
                        }"
                    />
                    <span
                        v-if="$v[index].name.firstName.$error"
                        class="text-xs text-red-600"
                    >
                        Please enter a valid name
                    </span>
                </div>

                <div class="col-span-6 sm:col-span-6 lg:col-span-3">
                    <label
                        :for="'shareholderLastName' + index"
                        class="block text-sm font-medium leading-5 text-gray-700"
                        >Last Name</label
                    >
                    <input
                        :id="'shareholderLastName' + index"
                        v-model.trim="shareholder.name.lastName"
                        class="focus:outline-none form-input mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                        :class="{
                            'border-red-300': $v[index].name.lastName.$error
                        }"
                    />
                    <span
                        v-if="$v[index].name.lastName.$error"
                        class="text-xs text-red-600"
                    >
                        Please enter a valid name
                    </span>
                </div>

                <div class="col-span-6">
                    <label
                        :for="'shareHolderEmail' + index"
                        class="block text-sm font-medium leading-5 text-gray-700"
                        >Email</label
                    >
                    <input
                        v-model.trim="shareholder.email"
                        :id="'shareHolderEmail' + index"
                        class="focus:outline-none form-input mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                        :class="{
                            'border-red-300': $v[index].email.$error
                        }"
                        required
                    />
                    <span
                        v-if="$v[index].email.$error"
                        class="text-xs text-red-600"
                    >
                        Please enter a valid email address
                    </span>
                </div>

                <div class="col-span-6">
                    <label
                        :for="'shareholderPhone' + index"
                        class="block text-sm font-medium leading-5 text-gray-700"
                        >Phone number
                    </label>
                    <InternationalTelInput
                        :id="'shareholderPhone' + index"
                        v-model.trim="shareholder.fullPhoneNumber"
                        :error="$v[index].fullPhoneNumber.$error"
                    />
                    <span
                        v-if="$v[index].fullPhoneNumber.$error"
                        class="text-xs text-red-600"
                    >
                        Please enter a valid phone number
                    </span>
                </div>

                <div class="col-span-6">
                    <label
                        :for="'shareholderDob' + index"
                        class="block text-sm font-medium leading-5 text-gray-700"
                        >Date of birth (Year - Month - Day)
                    </label>
                    <input
                        v-model.trim="shareholder.personalData.dateOfBirth"
                        :id="'shareholderDob' + index"
                        v-mask="'XXXX-XX-XX'"
                        placeholder="yyyy/mm/dd"
                        type="tel"
                        class="focus:outline-none form-input mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                        :class="{
                            'border-red-300':
                                $v[index].personalData.dateOfBirth.$error
                        }"
                        required
                    />
                    <span
                        v-if="$v[index].personalData.dateOfBirth.$error"
                        class="text-xs text-red-600"
                    >
                        Please enter a valid date of birth
                    </span>
                </div>

                <div class="col-span-6 w-full border-t border-gray-200 px-12">
                </div>

                <adyen-address
                    :$v="$v"
                    :address="shareholder.address"
                    :index="index"
                    :isIndividual="false"
                />
            </div>
        </div>
    </div>
</template>

<script>
import InternationalTelInput from '../../../formComponents/InternationalTelInput';
import AdyenAddress from './AdyenAddress';

export default {
    name: 'AdyenShareholders',
    props: {
        $v: {
            type: Object,
            required: false
        },
        shareholders: {
            type: Array,
            required: true
        }
    },
    components: {
        InternationalTelInput,
        AdyenAddress
    }
};
</script>
