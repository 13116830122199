<template>
    <tr class="mt-2 w-full bg-white">
        <td class="cursor-move whitespace-nowrap">
            <svg
                v-if="!disabled"
                class="m-2 inline-block h-4 w-4"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                    d="M7 16V4m0 0L3 8m4-4l4 4m6 0v12m0 0l4-4m-4 4l-4-4"
                ></path>
            </svg>
        </td>
        <td class="whitespace-nowrap">
            <div class="relative flex rounded-md shadow-sm">
                <input
                    v-show="!showPrintName"
                    id="name"
                    v-model="product.name.$model"
                    class="form-input block w-32 rounded-none rounded-l-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    :class="{
                        'border-red-300': product.name.$error
                    }"
                    placeholder="eg Four Seasons Pizza"
                />
                <input
                    v-show="showPrintName"
                    id="printName"
                    v-model="product.printName.$model"
                    class="form-input block w-32 rounded-none rounded-l-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    placeholder="Printer override name"
                />
                <button
                    @click="togglePrintName(true)"
                    type="button"
                    class="focus:outline-none relative -ml-px inline-flex items-center space-x-2 border border-gray-300 bg-gray-50 px-2 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                    :class="{ 'bg-gray-200': showPrintName }"
                >
                    <svg
                        class="h-4 w-4 text-gray-400"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
                        ></path>
                    </svg>
                </button>
                <button
                    @click="togglePrintName(false)"
                    type="button"
                    class="focus:outline-none relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-2 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                    :class="{ 'bg-gray-200': !showPrintName }"
                >
                    <svg
                        class="h-4 w-4 text-gray-400"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        ></path>
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                        ></path>
                    </svg>
                </button>
            </div>
        </td>
        <td
            v-if="
                isFeatureAvailable(FeaturesEnum.CENTEGRA) ||
                isFeatureAvailable(FeaturesEnum.COMTREX) ||
                isFeatureAvailable(FeaturesEnum.TISSL)
            "
            class="whitespace-nowrap"
        >
            <div class="relative pl-6">
                <span
                    @click="openEditModifierSidebar(product)"
                    class="inline-block cursor-pointer text-sm leading-5"
                >
                    {{ product.posId.$model }}
                </span>
            </div>
        </td>
        <td
            v-if="isFeatureAvailable(FeaturesEnum.CENTEGRA)"
            class="whitespace-nowrap"
        >
            <div class="relative pl-6">
                <span
                    @click="openEditModifierSidebar(product)"
                    class="inline-block cursor-pointer text-sm leading-5"
                >
                    {{
                        product.deliveryTax.$model
                            ? product.deliveryTax.$model / 100
                            : 0
                    }}%
                </span>
            </div>
        </td>
        <td class="whitespace-nowrap">
            <div class="relative flex flex-row items-center pl-6">
                <span
                    @click="openEditModifierSidebar(product)"
                    class="inline-block cursor-pointer text-sm leading-5"
                >
                    {{ (product.price.$model / 100) | currency }}
                </span>
            </div>
        </td>
        <td
            v-if="isFeatureAvailable(FeaturesEnum.SALE_PRICE)"
            class="whitespace-nowrap"
        >
            <div class="relative flex flex-row items-center pl-6">
                <span class="inline-block text-sm leading-5">
                    {{ (product.compareAtPrice.$model / 100) | currency }}
                </span>
            </div>
        </td>
        <td class="whitespace-nowrap py-2 pl-4">
            <base-small-toggle-switch
                v-if="!product.trackInventory.$model"
                v-model="product.isAvailable.$model"
            />
            <click-to-edit
                v-else
                class="cursor-pointer text-sm text-gray-600"
                style="width: 60px"
                v-model="product.inventory.$model"
                :label="'quantity'"
            />
        </td>
        <td class="flex whitespace-nowrap px-4 py-2">
            <div
                @click="openEditModifierSidebar(product)"
                class="mr-2 flex cursor-pointer items-center text-indigo-600"
            >
                <base-edit-icon class="h-5 w-5" />
            </div>
            <div
                @click="emitRemoveProduct(product)"
                class="group cursor-pointer text-gray-400"
            >
                <svg
                    class="h-5 w-5 group-hover:text-red-400"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                    ></path>
                </svg>
            </div>
        </td>
    </tr>
</template>
<script>
import { ElementMixin } from 'vue-slicksort';
import { mapGetters } from 'vuex';
import { FeaturesEnum } from '@/enums';
import ClickToEdit from '../helpers/ClickToEdit';
import EditModifierSidebar from '@/components/sidebars/EditModifierSidebar';
import Vue from 'vue';

export default {
    name: 'ModifierGroupListOption',
    mixins: [ElementMixin],
    data() {
        return {
            FeaturesEnum,
            showPrintName: false
        };
    },
    components: { ClickToEdit },
    props: {
        product: {
            type: Object,
            default: () => ({}),
            required: true
        },
        index: {
            type: String,
            default: '',
            required: true
        },
        disabled: {
            type: Boolean,
            default: false,
            required: true
        }
    },
    methods: {
        emitRemoveProduct(product) {
            this.$emit('remove-product', product.$model);
        },
        togglePrintName(showPrintName) {
            this.showPrintName = showPrintName;
        },
        openEditModifierSidebar(modifier) {
            const modifierCopy = Vue.observable(JSON.parse(JSON.stringify(modifier.$model)));
            const ModifierSidebar = Vue.extend(EditModifierSidebar);

            const sidebar = new ModifierSidebar({
                propsData: {
                    modifier: modifierCopy
                },
                router: this.$router,
                store: this.$store
            }).$mount();

            function destroy() {
                sidebar.$destroy();
                sidebar.$el.remove();
            }

            document.body.appendChild(sidebar.$el);

            sidebar.$on('close', () => {
                destroy();
            });

            sidebar.$on('update', updatedModifier => {
                Object.assign(modifier.$model, updatedModifier);
                this.$emit('update');
                destroy();
            });
        }
    },
    computed: {
        ...mapGetters({
            isFeatureAvailable: 'user/isFeatureAvailable',
            currency: 'user/currencySettings'
        })
    }
};
</script>
