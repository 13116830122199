<template>
    <div>
        <div class="md:flex md:items-center md:justify-between">
            <div class="min-w-0 flex-1">
                <router-link
                    :to="{ name: 'editVenue' }"
                    class="mb-2 flex items-center text-sm text-gray-500 inline-flex"
                >
                    <base-back-icon />
                    <span>Settings</span>
                </router-link>
                <h2 class="text-2xl font-semibold text-gray-900">
                    Centegra Integration
                </h2>
            </div>

            <BaseSpinner :isLoading="isLoading" />
        </div>
        <div>
            <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                        >
                            Settings
                        </h3>
                        <p class="mt-2 text-sm text-gray-500">
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit. Delectus eaque modi, quas quidem ratione
                            repellat totam? Alias eos fugiat natus.
                            <br /><br />Lorem ipsum dolor sit amet, consectetur
                            adipisicing elit. Nesciunt, reprehenderit?
                            <a
                                class="text-indigo-600"
                                href="https://centegra.co.uk/"
                                target="_blank"
                            >
                                Lorem ipsum dolor sit.
                            </a>
                        </p>
                    </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0" v-if="!isLoading">
                    <div class="shadow sm:overflow-hidden sm:rounded-md">
                        <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
                            <div>
                                <div>
                                    <label
                                        for="locationId"
                                        class="block text-sm font-medium leading-5 text-gray-700"
                                    >
                                        Location
                                    </label>

                                    <div class="mt-2 rounded-md shadow-sm">
                                        <select
                                            id="locationId"
                                            v-model="centegra.location"
                                            class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                            :disabled="
                                                !centegraSettings.locations ||
                                                !centegraSettings.locations
                                                    .length
                                            "
                                            :class="{
                                                'border-red-300':
                                                    $v.centegra.location.$error
                                            }"
                                        >
                                            <template
                                                v-if="
                                                    centegraSettings.locations
                                                "
                                            >
                                                <option
                                                    v-for="location in centegraSettings.locations"
                                                    :value="location"
                                                    :key="location.LocationID"
                                                >
                                                    {{ location.LocationName }}
                                                </option>
                                            </template>
                                        </select>
                                    </div>
                                    <span
                                        v-if="$v.centegra.location.$error"
                                        class="text-xs text-red-600"
                                    >
                                        Please select an option.
                                    </span>
                                </div>

                                <div class="mt-6">
                                    <label
                                        for="till"
                                        class="block text-sm font-medium leading-5 text-gray-700"
                                    >
                                        Till
                                    </label>
                                    <div class="mt-2 rounded-md shadow-sm">
                                        <select
                                            id="till"
                                            v-model="centegra.till"
                                            class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                            :disabled="
                                                !centegra.location ||
                                                !centegra.location.tills ||
                                                !centegra.location.tills.length
                                            "
                                            :class="{
                                                'border-red-300':
                                                    $v.centegra.till.$error
                                            }"
                                        >
                                            <template
                                                v-if="
                                                    centegra.location &&
                                                    centegra.location.tills
                                                "
                                            >
                                                <option
                                                    v-for="till in centegra
                                                        .location.tills"
                                                    :value="till"
                                                    :key="till.TillID"
                                                >
                                                    {{ till.TillName }}
                                                </option>
                                            </template>
                                        </select>
                                    </div>
                                    <span
                                        v-if="
                                            !centegra.location &&
                                            !$v.centegra.till.$error
                                        "
                                        class="text-xs text-gray-500"
                                    >
                                        Tip: select the location first to be
                                        able to select till option
                                    </span>
                                    <span
                                        v-if="$v.centegra.till.$error"
                                        class="text-xs text-red-600"
                                    >
                                        Please select an option.
                                    </span>
                                </div>

                                <div class="mt-6">
                                    <label
                                        for="clerkId"
                                        class="block text-sm font-medium leading-5 text-gray-700"
                                    >
                                        Clerk
                                    </label>
                                    <div class="mt-2 rounded-md shadow-sm">
                                        <select
                                            id="clerkId"
                                            v-model="centegra.clerkId"
                                            class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                            :disabled="
                                                !centegraSettings.clerks ||
                                                !centegraSettings.clerks.length
                                            "
                                            :class="{
                                                'border-red-300':
                                                    $v.centegra.clerkId.$error
                                            }"
                                        >
                                            <template
                                                v-if="centegraSettings.clerks"
                                            >
                                                <option
                                                    v-for="clerk in centegraSettings.clerks"
                                                    :value="clerk.ClerkID"
                                                    :key="clerk.ClerkID"
                                                >
                                                    {{ clerk.ClerkName }}
                                                </option>
                                            </template>
                                        </select>
                                    </div>
                                    <span
                                        v-if="$v.centegra.clerkId.$error"
                                        class="text-xs text-red-600"
                                    >
                                        Please select an option.
                                    </span>
                                </div>

                                <div class="mt-6">
                                    <label
                                        for="PriceLevelId"
                                        class="block text-sm font-medium leading-5 text-gray-700"
                                    >
                                        Price Level
                                    </label>
                                    <div class="mt-2 rounded-md shadow-sm">
                                        <select
                                            id="PriceLevelId"
                                            v-model="centegra.priceLevelId"
                                            class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                            :disabled="
                                                !centegraSettings.priceLevels ||
                                                !centegraSettings.priceLevels
                                                    .length
                                            "
                                            :class="{
                                                'border-red-300':
                                                    $v.centegra.priceLevelId
                                                        .$error
                                            }"
                                        >
                                            <template
                                                v-if="
                                                    centegraSettings.priceLevels
                                                "
                                            >
                                                <option
                                                    v-for="priceLevel in centegraSettings.priceLevels"
                                                    :value="
                                                        priceLevel.PriceLevelID
                                                    "
                                                    :key="
                                                        priceLevel.PriceLevelID
                                                    "
                                                >
                                                    {{
                                                        priceLevel.PriceLevelName
                                                    }}
                                                </option>
                                            </template>
                                        </select>
                                    </div>
                                    <span
                                        v-if="$v.centegra.priceLevelId.$error"
                                        class="text-xs text-red-600"
                                    >
                                        Please select an option.
                                    </span>
                                </div>

                                <div class="mt-6">
                                    <label
                                        for="mediaId"
                                        class="block text-sm font-medium leading-5 text-gray-700"
                                    >
                                        Media
                                    </label>
                                    <div class="mt-2 rounded-md shadow-sm">
                                        <select
                                            id="mediaId"
                                            v-model="centegra.mediaId"
                                            class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                            :disabled="
                                                !centegraSettings.media ||
                                                !centegraSettings.media.length
                                            "
                                            :class="{
                                                'border-red-300':
                                                    $v.centegra.mediaId.$error
                                            }"
                                        >
                                            <template
                                                v-if="centegraSettings.media"
                                            >
                                                <option
                                                    v-for="media in centegraSettings.media"
                                                    :value="media.MediaID"
                                                    :key="media.MediaID"
                                                >
                                                    {{ media.MediaName }}
                                                </option>
                                            </template>
                                        </select>
                                    </div>
                                    <span
                                        v-if="$v.centegra.mediaId.$error"
                                        class="text-xs text-red-600"
                                    >
                                        Please select an option.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <BaseSpacerWithRuler />

        <div class="my-6 ml-4 flex flex-shrink-0 justify-end">
            <span class="inline-flex rounded-md shadow-sm" @click="saveVenue">
                <button
                    type="button"
                    class="focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                    :disabled="isLoading"
                >
                    Save
                </button>
            </span>
        </div>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
    name: 'CentegraSettings',
    metaInfo: {
        title: 'Centegra Integration'
    },
    props: ['venueId', 'venue'],
    data() {
        return {
            isLoading: true,
            settings: { ...this.venue },
            centegraSettings: {},
            centegra: {
                location: {
                    LocationID: null
                },
                till: {
                    TillID: null
                },
                clerkId: null,
                priceLevelId: null,
                mediaId: null
            }
        };
    },
    validations: {
        centegra: {
            location: {
                LocationID: {
                    required
                }
            },
            till: {
                TillID: {
                    required
                }
            },
            clerkId: {
                required
            },
            priceLevelId: {
                required
            },
            mediaId: {
                required
            }
        }
    },
    watch: {
        'centegra.location'(value) {
            if (
                !value ||
                this.centegra.till.TillID ===
                    this.settings.centegraSettings.tillId
            ) {
                return;
            }

            this.clearTillSelection();
        }
    },
    async created() {
        this.fetchCentegraSettings();
    },
    methods: {
        async fetchCentegraSettings() {
            try {
                this.isLoading = true;

                const { data } = await this.$axios.get(`/centegra/settings`, {
                    params: { venueId: this.venueId }
                });

                this.centegraSettings = data;
                await this.setCentegraDefaults();
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        },
        async setCentegraDefaults() {
            this.centegra.location = this.centegraSettings?.locations?.find(
                location =>
                    location.LocationID ===
                    this.settings?.centegraSettings?.locationId
            ) || { LocationID: null };

            this.centegra.till = this.centegra?.location?.tills?.find(
                till => till.TillID === this.settings?.centegraSettings?.tillId
            ) || { TillID: null };

            this.centegra.clerkId =
                this.settings?.centegraSettings?.clerkId || null;
            this.centegra.mediaId =
                this.settings?.centegraSettings?.mediaId || null;
            this.centegra.priceLevelId =
                this.settings?.centegraSettings?.priceLevelId || null;
            this.centegra.sourceId =
                this.settings?.centegraSettings?.sourceId || null;
        },
        clearTillSelection() {
            this.centegra.till = { TillID: null };
        },
        async saveVenue() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.errors = 'ERROR';
                return false;
            }

            try {
                this.isLoading = true;

                const payload = {
                    venueId: this.venueId,
                    locationId: this.centegra.location.LocationID,
                    tillId: this.centegra.till.TillID,
                    tillSecureKey: this.centegra.till.SecureKey,
                    clerkId: this.centegra.clerkId,
                    mediaId: this.centegra.mediaId,
                    priceLevelId: this.centegra.priceLevelId,
                    sourceId: 114
                };

                await this.$axios.put(`/centegra/settings`, payload);

                this.$notify({
                    group: 'settings',
                    title: 'Settings saved'
                });
            } catch (error) {
                this.$modal.show('custom-error-modal', {
                    text:
                        "Sorry, Centegra API doesn't seem to work. Please double check and try again."
                });

                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        }
    }
};
</script>
