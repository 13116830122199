<template>
    <div class="flex flex-col p-1">
        <ul>
            <li v-for="item in items" :key="item.id" class="ml-2 list-disc">
                {{ item.name }}
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'ModifierGroupItemsTooltip',
    props: {
        items: {
            type: Array,
            default: () => []
        }
    }
};
</script>

<style>
.v-popper__popper.modifier-items-popover .v-popper__wrapper .v-popper__inner {
    width: 400px;
    background: #fff;
    padding: 0.5rem;
    color: black;
    border-radius: 5px;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
}

.v-popper__popper.modifier-items-popover .v-popper__arrow-inner {
    border-color: #f9f9f9;
}
</style>
