<template>
    <div class="flex items-center">
        <div class="relative mb-4 mt-2 flex items-center print:hidden">
            <!--            <label class="pr-3 text-gray-800 text-sm">Date range</label>-->
            <DateRangeSelector
                @date-updated="updateReport"
                :single-date="singleDate"
                :allow-future="true"
                :time-picker="timePicker"
            />
        </div>
        <div class="relative mb-4 ml-5 mt-2 flex items-center print:hidden">
            <label
                v-tooltip="
                    'Transactional reports consider the day to end at midnight. Operational reports end the day at 5am and use the pre order date if it exists.'
                "
                class="flex items-center pr-3 text-sm text-gray-800"
                >Reporting

                <svg
                    class="ml-1 h-4 w-4 cursor-pointer text-gray-500"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                        clip-rule="evenodd"
                    ></path></svg
            ></label>
            <div class="relative inline-block text-left">
                <div>
                    <button
                        @click="showDropdown = !showDropdown"
                        type="button"
                        class="focus:outline-none inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                        id="options-menu"
                        aria-haspopup="true"
                        aria-expanded="true"
                    >
                        {{
                            byPreOrderDate === true
                                ? 'Operational'
                                : 'Transactional'
                        }}
                        <svg
                            class="-mr-1 ml-2 h-5 w-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                clip-rule="evenodd"
                            />
                        </svg>
                    </button>
                </div>

                <transition
                    enter-active-class="transition ease-out duration-100"
                    enter-class="transform opacity-0 scale-95"
                    enter-to-class="transform opacity-100 scale-100"
                    leave-active-class="transition ease-in duration-75"
                    leave-class="transform opacity-100 scale-100"
                    leave-to-class="transform opacity-0 scale-95"
                >
                    <div
                        v-if="showDropdown"
                        v-click-outside="closeDropdown"
                        class="absolute right-0 z-50 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5"
                    >
                        <div
                            class="py-1 font-medium"
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="options-menu"
                        >
                            <span
                                @click="fetchReportByPreOrderDate(true)"
                                class="block cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                role="menuitem"
                                >Operational</span
                            >
                            <span
                                @click="fetchReportByPreOrderDate(false)"
                                class="block cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                role="menuitem"
                                >Transactional</span
                            >
                        </div>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>
<script>
import DateRangeSelector from '@/components/widgets/DateRangeSelector';
import vClickOutside from 'v-click-outside';

export default {
    components: {
        DateRangeSelector
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    data() {
        return {
            showDropdown: false
        };
    },
    props: {
        byPreOrderDate: {
            type: Boolean,
            required: true,
            default: false
        },
        singleDate: {
            type: Boolean,
            required: false,
            default: false
        },
        timePicker: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        updateReport(value) {
            this.$emit('update-report', value);
        },
        fetchReportByPreOrderDate(value) {
            this.$emit('fetch-report-by-pre-order-date', value);
        },
        closeDropdown() {
            this.showDropdown = false;
        }
    },
    watch: {
        byPreOrderDate() {
            this.showDropdown = false;
        }
    }
};
</script>
