<template>
    <div
        v-if="!isLoading"
        class="mb-4 overflow-hidden rounded-lg bg-white shadow"
    >
        <div class="grid grid-cols-6 gap-3 px-4 py-5 sm:p-6">
            <div class="group col-span-6 rounded-sm">
                <div class="">
                    <div class="mb-3">
                        <img
                            src="https://ucarecdn.com/27460630-e85a-489c-a724-872f203c0f13/-/format/auto/"
                            alt="Connect with StoreKit Payments"
                            class="-ml-1 mb-2 w-24"
                        />
                    </div>
                    <div>
                        <p class="mb-4 text-sm text-gray-800">
                            Connect your store to storekit payments to accept
                            credit/debit card and Apple/Google Pay payments.
                        </p>
                    </div>
                    <div class="flex flex-row">
                        <div class="w-1/2">
                            <p class="text-sm font-medium text-gray-700">
                                Credit / debit card rate
                            </p>
                            <span class="block py-2 text-sm text-gray-500">
                                <div v-if="paymentFee">
                                    <template v-if="Array.isArray(paymentFee)">
                                        <div
                                            v-for="fee in paymentFee"
                                            :key="fee.fulfillmentMethod"
                                        >
                                            {{ fee.percent + '%' }}
                                            <template v-if="fee.rate">
                                                +
                                                {{
                                                    fee.rate
                                                        | currency(
                                                            venue.currencyCode
                                                        )
                                                }}

                                                {{
                                                    '(' +
                                                    fee.fulfillmentMethod +
                                                    ')'
                                                }}
                                            </template>
                                        </div>
                                    </template>
                                    <template v-else>
                                        {{ paymentFee.percent + '%' }}
                                        <template v-if="paymentFee.rate">
                                            +
                                            {{
                                                paymentFee.rate
                                                    | currency(
                                                        venue.currencyCode
                                                    )
                                            }}
                                        </template>
                                    </template>
                                </div>
                                <div v-else>
                                    {{
                                        venue.acceptsInStore
                                            ? '1.9% + £0.15'
                                            : '3.9% + £0.20'
                                    }}
                                </div>
                            </span>
                        </div>
                    </div>

                    <div>
                        <img
                            class="-ml-2 h-12 w-auto"
                            src="https://ucarecdn.com/d00b3002-e1c8-4eb0-8de4-e7106d74a68e/-/format/auto/"
                            alt="Accepted payment methods"
                        />
                    </div>

                    <div class="mt-3 flex justify-end">
                        <div
                            v-if="account && account.id && account.adyenHolder"
                        >
                            <router-link
                                :to="{
                                    name: 'manageAdyenAccountHolder',
                                    params: {
                                        code:
                                            account.adyenHolder
                                                .accountHolderCode
                                    }
                                }"
                                class="ml-2"
                            >
                                <button
                                    type="button"
                                    class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                >
                                    Manage account
                                </button>
                            </router-link>
                            <div
                                class="mt-2"
                                v-if="isFeatureAvailable(FeaturesEnum.INTERNAL)"
                            >
                                <router-link
                                    :to="{
                                        name: 'createAdyenAccount',
                                        query: { newAccount: 'true' }
                                    }"
                                >
                                    <button
                                        v-tooltip="
                                            'Only create a new account if this store belongs to a different company / legal entity'
                                        "
                                        type="button"
                                        class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    >
                                        Create a new account
                                    </button>
                                </router-link>
                            </div>
                        </div>
                        <div
                            v-if="
                                account &&
                                account.id &&
                                account.adyenPlatformHolder
                            "
                        >
                            <router-link
                                :to="{
                                    name: 'manageAdyenPlatformAccountHolder',
                                    params: {
                                        accountHolderId:
                                            account.adyenPlatformHolder.id
                                    }
                                }"
                                class="ml-2"
                            >
                                <button
                                    type="button"
                                    class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                >
                                    Manage account
                                </button>
                            </router-link>
                            <div
                                class="mt-2"
                                v-if="isFeatureAvailable(FeaturesEnum.INTERNAL)"
                            >
                                <router-link
                                    :to="{
                                        name: 'createAdyenAccount',
                                        query: { newAccount: 'true' }
                                    }"
                                >
                                    <button
                                        v-tooltip="
                                            'Only create a new account if this store belongs to a different company / legal entity'
                                        "
                                        type="button"
                                        class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    >
                                        Create a new account
                                    </button>
                                </router-link>
                            </div>
                            <div
                                class="mt-2"
                                v-if="isFeatureAvailable(FeaturesEnum.INTERNAL)"
                            >
                                <router-link
                                    :to="{
                                        name: 'createAdyenPlatformAccount',
                                        query: { newAccount: 'true' }
                                    }"
                                >
                                    <button
                                        v-tooltip="
                                            'Only create a new account if this store belongs to a different company / legal entity'
                                        "
                                        type="button"
                                        class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    >
                                        Create a new platform account
                                    </button>
                                </router-link>
                            </div>
                        </div>
                        <div v-else-if="accountHolders.length">
                            <router-link
                                :to="{
                                    name: 'createAdyenAccount',
                                    query: { newAccount: 'true' }
                                }"
                            >
                                <button
                                    v-tooltip="
                                        'Only create a new account if this store belongs to a different company / legal entity'
                                    "
                                    type="button"
                                    class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                >
                                    Create a new account
                                </button>
                            </router-link>
                            <router-link
                                :to="{
                                    name: 'createAdyenPlatformAccount',
                                    query: { newAccount: 'true' }
                                }"
                            >
                                <button
                                    v-tooltip="
                                        'Only create a new account if this store belongs to a different company / legal entity'
                                    "
                                    type="button"
                                    class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                >
                                    Create a new platform account
                                </button>
                            </router-link>
                            <router-link
                                :to="{
                                    name: 'connectAdyenAccount'
                                }"
                                class="ml-2"
                            >
                                <button
                                    type="button"
                                    class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                >
                                    Connect an existing account
                                </button>
                            </router-link>
                            <router-link
                                :to="{
                                    name: 'connectAdyenPlatformAccount'
                                }"
                                class="ml-2"
                            >
                                <button
                                    type="button"
                                    class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                >
                                    Connect an existing platform account
                                </button>
                            </router-link>
                        </div>
                        <div v-else>
                            <router-link
                                :to="{
                                    name: 'createAdyenAccount'
                                }"
                            >
                                <button
                                    type="button"
                                    class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                >
                                    Activate Payments
                                </button>
                            </router-link>
                            <div
                                class="mt-2"
                                v-if="isFeatureAvailable(FeaturesEnum.INTERNAL)"
                            >
                              <router-link
                                  :to="{
                                      name: 'createAdyenPlatformAccount'
                                  }"
                              >
                                <button
                                    type="button"
                                    class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                >
                                  Activate Payments (Balance)
                                </button>
                              </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { FeaturesEnum } from '@/enums';
import { mapGetters } from 'vuex';

export default {
    name: 'AdyenPaymentOption',
    props: {
        venueId: {
            type: Number,
            required: true,
            default: 0
        },
        venue: {
            type: Object,
            required: false,
            default: () => {}
        }
    },
    data() {
        return {
            isLoading: 0,
            account: null,
            accountHolders: [],
            platformAccountHolders: [],
            FeaturesEnum
        };
    },
    created() {
        this.fetchAdyenAccount();
        this.fetchAdyenAccountHolders();
        this.fetchAdyenPlatformAccountHolders();
    },
    computed: {
        ...mapGetters({
            isFeatureAvailable: 'user/isFeatureAvailable'
        }),
        paymentFee() {
            let fees = {};

            if (!Array.isArray(this.account.paymentSplitSettings)) {
                return false;
            }

            for (const setting of this.account.paymentSplitSettings) {
                fees = {
                    ...fees,
                    [setting.fulfillmentMethod.toLowerCase()]: {
                        percent: (setting.percentageRate || 0) / 100,
                        rate: parseFloat((setting.constantRate || 0) / 100),
                        fulfillmentMethod: setting.fulfillmentMethod,
                        checkSum:
                            (setting.percentageRate || 0) +
                            (setting.constantRate || 0)
                    }
                };
            }

            if (this.venue.acceptsInStore) {
                if (fees.instore) {
                    return fees.instore;
                }
            } else {
                if (this.venue.acceptsDelivery && fees.delivery) {
                    if (
                        this.venue.acceptsPickup &&
                        fees.pickup &&
                        fees.delivery.checkSum !== fees.pickup.checkSum
                    ) {
                        return [fees.delivery, fees.pickup];
                    }

                    return fees.delivery;
                }

                if (this.venue.acceptsPickup && fees.pickup) {
                    return fees.pickup;
                }
            }

            return false;
        }
    },
    methods: {
        async fetchAdyenAccount() {
            try {
                this.isLoading++;
                const { data } = await this.$axios.get(`/adyen/account`, {
                    params: {
                        venueId: this.venueId
                    }
                });
                this.account = data;
            } catch (e) {
                throw new Error(e);
            } finally {
                this.isLoading--;
            }
        },
        async fetchAdyenPlatformAccountHolders() {
            try {
                this.isLoading++;
                const { data } = await this.$axios.get(
                    `/adyen/platform/account-holders`
                );
                this.accountHolders = data;
            } catch (e) {
                throw new Error(e);
            } finally {
                this.isLoading--;
            }
        },
        async fetchAdyenAccountHolders() {
          try {
            this.isLoading++;
            const { data } = await this.$axios.get(
                `/adyen/account-holders`
            );
            this.accountHolders = data;
          } catch (e) {
            throw new Error(e);
          } finally {
            this.isLoading--;
          }
        }
    }
};
</script>
