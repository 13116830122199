<template>
    <tbody class="divide-y divide-gray-200 bg-white bg-white">
        <slot />
    </tbody>
</template>

<script>
export default {
    name: 'OfferLinkGroupListOptionsContainer'
};
</script>
