<template>
    <modal
        name="archive-order-modal"
        :adaptive="true"
        height="auto"
        width="600px"
        @before-open="beforeOpen"
    >
        <div
            class="pb-4 pt-5 px-4 w-full bg-white rounded-lg shadow-xl overflow-hidden transform transition-all sm:p-6"
        >
            <div class="sm:flex sm:items-start">
                <div
                    class="flex flex-shrink-0 items-center justify-center mx-auto w-12 h-12 bg-red-100 rounded-full sm:mx-0 sm:w-10 sm:h-10"
                >
                    <svg
                        class="w-6 h-6 text-red-600"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                        ></path>
                    </svg>
                </div>
                <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <h3
                        class="font-heading text-lg font-medium leading-6 text-gray-900"
                    >
                        {{ isArchived ? 'Unarchive Order' : 'Archive Order' }}
                    </h3>
                    <div class="mt-2">
                        <p class="text-sm leading-5 text-gray-500">
                            {{ descriptionText }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="justify-end mt-5 sm:flex sm:mt-4 sm:pl-4">
                <span class="flex w-full rounded-md shadow-sm sm:w-auto">
                    <button
                        @click="hideModal()"
                        type="button"
                        class="inline-flex justify-center px-4 py-2 w-full hover:text-gray-500 text-gray-700 text-base font-medium leading-6 bg-white border focus:border-blue-300 border-gray-300 rounded-md focus:outline-none focus:shadow-outline shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    >
                        Cancel
                    </button>
                </span>
                <span
                    class="flex w-full rounded-md shadow-sm sm:ml-3 sm:mt-0 sm:w-auto"
                >
                    <button
                        @click="archiveOrder()"
                        type="button"
                        class="inline-flex justify-center px-4 py-2 w-full text-white text-base font-medium leading-6 hover:bg-indigo-500 bg-indigo-600 border focus:border-indigo-700 border-transparent rounded-md focus:outline-none focus:shadow-outline-indigo shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    >
                        {{ isArchived ? 'Unarchive Order' : 'Archive Order' }}
                    </button>
                </span>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    name: 'ArchiveOrderModal',
    data() {
        return {
            orderId: null,
            isLoading: false,
            isArchived: false
        };
    },
    computed: {
        descriptionText() {
            return this.isArchived
                ? `Unarchive this order to make it visible in your order list again, and include it in reporting. Once unarchived, the order will be accessible just like any other active order.`
                : `Archive this order to hide it from your orders list, and exclude it from reporting. You'll be able to access all archived orders by request.`;
        }
    },
    methods: {
        beforeOpen(event) {
            this.orderId = event.params.orderId;
            this.isArchived = event.params.isArchived;
        },
        hideModal() {
            this.$modal.hide('archive-order-modal');
        },
        async archiveOrder() {
            this.isLoading = true;
            try {
                if (this.isArchived) {
                    await this.$axios.patch(`/orders/${this.orderId}/restore`);
                } else {
                    await this.$axios.delete(`/orders/${this.orderId}`);
                }
                this.hideModal();
                this.$emit('order-archived');
            } catch (e) {
                this.hideModal();
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        }
    }
};
</script>
