<template>
    <modal
        name="add-table-modal"
        width="600px"
        height="auto"
        :clickToClose="true"
        @before-open="beforeOpen"
        :adaptive="true"
    >
        <div
            class="transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 shadow-xl transition-all sm:w-full sm:p-6"
        >
            <div>
                <div class="mt-3 text-left sm:mt-5">
                    <h3 class="text-lg font-medium leading-6 text-gray-900">
                        Add a {{ tableDescriptor }}
                    </h3>
                    <div class="mt-2">
                        <!--<p class="text-sm leading-5 text-gray-500">-->
                        <!--Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur amet labore.-->
                        <!--</p>-->
                    </div>
                    <div class="mt-3">
                        <base-input-field
                            label="Name"
                            id="name"
                            v-model="table.name"
                            :errors="$v.table.name.$error"
                            placeholder="e.g. table-2"
                        />
                        <!--<p class="mt-2 text-sm text-gray-500">Enter the name/title of the product</p>-->
                    </div>
                    <!--                    <div class="mt-3">-->
                    <!--                        <label for="description" class="block text-sm font-medium leading-5 text-gray-700">Description</label>-->
                    <!--                        <div class="mt-1 relative rounded-md shadow-sm">-->
                    <!--                            <textarea id="description" v-model="category.description" class="form-input block w-full sm:text-sm sm:leading-5" />-->
                    <!--                        </div>-->
                    <!--                        &lt;!&ndash;<p class="mt-2 text-sm text-gray-500">Enter the description</p>&ndash;&gt;-->
                    <!--                    </div>-->

                    <div class="mt-3">
                        <base-input-field
                            label="POS ID"
                            id="posId"
                            v-model="table.posId"
                            :errors="$v.table.posId.$error"
                            placeholder="e.g. 1234"
                        />
                    </div>
                    <div class="mt-3">
                        <base-input-field
                            label="Covers"
                            id="covers"
                            type="number"
                            v-model="table.covers"
                            placeholder="e.g. 2 or 5"
                        />
                    </div>
                </div>
            </div>
            <div
                class="mt-5 sm:mt-6 sm:flex sm:w-auto"
                :class="{ spinner: isLoading }"
            >
                <span class="flex w-full rounded-md shadow-sm sm:w-56">
                    <button
                        @click="createTable()"
                        :disabled="isLoading"
                        type="button"
                        class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo sm:text-sm sm:leading-5"
                    >
                        Create {{ tableDescriptor }}
                    </button>
                </span>
                <span
                    class="mt-3 flex w-full rounded-md shadow-sm sm:ml-3 sm:mt-0 sm:w-auto"
                >
                    <button
                        @click="$modal.hide('add-table-modal')"
                        type="button"
                        class="focus:outline-none inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium leading-6 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline sm:text-sm sm:leading-5"
                    >
                        Close
                    </button>
                </span>
            </div>
        </div>
    </modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
    name: 'AddTableModal',
    components: {},
    validations: {
        table: {
            name: {
                required
            },
            posId: {
                required(value) {
                    return [
                        'deliverect',
                        'ikentoo',
                        'centegra',
                        'comtrex'
                    ].includes(this.venuePosProvider)
                        ? !!(value && value.length)
                        : true;
                }
            }
        }
    },
    data() {
        return {
            isLoading: false,
            venueId: null,
            areaId: null,
            venuePosProvider: null,
            tableDescriptor: null,
            table: {
                name: '',
                posId: '',
                covers: null
            }
        };
    },
    methods: {
        beforeOpen(event) {
            this.venueId = event.params.venueId;
            this.tableDescriptor = event.params.tableDescriptor;
            this.areaId = event.params.areaId;
            this.venuePosProvider = event.params.venuePosProvider;
            this.table.name = '';
            this.table.posId = '';
            this.table.covers = null;
        },
        createTable() {
            this.$v.table.$touch();
            if (this.$v.table.$invalid) {
                this.errors = 'ERROR';
            } else {
                this.isLoading = true;
                let table = this.table;

                this.$axios
                    .post(
                        '/venues/' +
                            this.venueId +
                            '/areas/' +
                            this.areaId +
                            '/tables',
                        table
                    )
                    .then(() => {
                        this.$v.$reset();
                        this.$modal.hide('add-table-modal');
                        // Reset to inital state https://stackoverflow.com/questions/35604987/is-there-a-proper-way-of-resetting-a-components-initial-data-in-vuejs
                        this.$emit('table-area-added');
                        // Object.assign(this.$data, this.$options.data())
                    })
                    .catch(e => {
                        throw new Error(`API ${e}`);
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            }
        }
    }
};
</script>

<style scoped>
.spinner:after {
    left: auto;
    right: 1em;
    border-right-color: indigo;
}
</style>
