var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-span-6 grid gap-6"},[_c('div',[_c('label',{staticClass:"block text-gray-700 text-sm font-medium leading-5",attrs:{"for":"street"}},[_vm._v("Home address")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.address.street),expression:"address.street",modifiers:{"trim":true}}],staticClass:"form-input block mt-1 px-3 py-2 w-full border focus:border-blue-300 border-gray-300 rounded-md focus:outline-none focus:shadow-outline-blue shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5",class:{
                'border-red-300': _vm.isIndividual
                    ? _vm.$v.address.street.$error
                    : _vm.$v[_vm.index].address.street.$error
            },attrs:{"placeholder":"eg 101 High St","autocomplete":"shipping street-address","id":"street"},domProps:{"value":(_vm.address.street)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.address, "street", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(
                _vm.isIndividual
                    ? _vm.$v.address.street.$error
                    : _vm.$v[_vm.index].address.street.$error
            )?_c('span',{staticClass:"text-red-600 text-xs"},[_vm._v(" Please enter the street name ")]):_vm._e()]),_c('div',[_c('label',{staticClass:"block text-gray-700 text-sm font-medium leading-5",attrs:{"for":"building_number"}},[_vm._v("Building name / number")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.address.houseNumberOrName),expression:"address.houseNumberOrName",modifiers:{"trim":true}}],staticClass:"form-input block mt-1 px-3 py-2 w-full border focus:border-blue-300 border-gray-300 rounded-md focus:outline-none focus:shadow-outline-blue shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5",class:{
                'border-red-300': _vm.isIndividual
                    ? _vm.$v.address.houseNumberOrName.$error
                    : _vm.$v[_vm.index].address.houseNumberOrName.$error
            },attrs:{"placeholder":"eg 15-17","id":"building_number"},domProps:{"value":(_vm.address.houseNumberOrName)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.address, "houseNumberOrName", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',[_c('label',{staticClass:"block text-gray-700 text-sm font-medium leading-5",attrs:{"for":"city"}},[_vm._v("City")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.address.city),expression:"address.city",modifiers:{"trim":true}}],staticClass:"form-input block mt-1 px-3 py-2 w-full border focus:border-blue-300 border-gray-300 rounded-md focus:outline-none focus:shadow-outline-blue shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5",class:{
                'border-red-300': _vm.isIndividual
                    ? _vm.$v.address.city.$error
                    : _vm.$v[_vm.index].address.city.$error
            },attrs:{"id":"city","autocomplete":"shipping locality","placeholder":"eg London"},domProps:{"value":(_vm.address.city)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.address, "city", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(
                _vm.isIndividual
                    ? _vm.$v.address.city.$error
                    : _vm.$v[_vm.index].address.city.$error
            )?_c('span',{staticClass:"text-red-600 text-xs"},[_vm._v(" Please enter a valid city ")]):_vm._e()]),_c('div',[_c('label',{staticClass:"block text-gray-700 text-sm font-medium leading-5",attrs:{"for":"postal_code"}},[_vm._v("Post code")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.address.postalCode),expression:"address.postalCode",modifiers:{"trim":true}}],staticClass:"form-input block mt-1 px-3 py-2 w-full border focus:border-blue-300 border-gray-300 rounded-md focus:outline-none focus:shadow-outline-blue shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5",class:{
                'border-red-300': _vm.isIndividual
                    ? _vm.$v.address.postalCode.$error
                    : _vm.$v[_vm.index].address.postalCode.$error
            },attrs:{"id":"postal_code","placeholder":"eg SW1A 1AA","autocomplete":"shipping postal-code"},domProps:{"value":(_vm.address.postalCode)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.address, "postalCode", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(
                _vm.isIndividual
                    ? _vm.$v.address.postalCode.$error
                    : _vm.$v[_vm.index].address.postalCode.$error
            )?_c('span',{staticClass:"text-red-600 text-xs"},[_vm._v(" Please enter a valid postcode ")]):_vm._e()]),_c('div',[_c('div',[_c('label',{staticClass:"block text-gray-700 text-sm font-medium",attrs:{"for":"location"}},[_vm._v("Country")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.address.country),expression:"address.country"}],staticClass:"form-input focus:ring-indigo-500 block mt-1 pl-3 pr-10 py-2 w-full text-base border border-gray-300 focus:border-indigo-500 rounded-md focus:outline-none sm:text-sm",class:{
                    'border-red-300': _vm.isIndividual
                        ? _vm.$v.address.country.$error
                        : _vm.$v[_vm.index].address.country.$error
                },attrs:{"id":"location","name":"location"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.address, "country", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"","disabled":"","selected":""}},[_vm._v("Select a country")]),_vm._l((_vm.countries),function(country){return _c('option',{key:country.code,domProps:{"value":country.code}},[_vm._v(_vm._s(country.name))])})],2),(
                    _vm.isIndividual
                        ? _vm.$v.address.country.$error
                        : _vm.$v[_vm.index].address.country.$error
                )?_c('span',{staticClass:"text-red-600 text-xs"},[_vm._v(" Please select a country ")]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }