<template>
    <div>
        <div class="md:flex md:items-center md:justify-between">
            <div class="min-w-0 flex-1">
                <router-link
                    :to="{ name: 'editVenue' }"
                    class="mb-2 flex items-center text-sm text-gray-500 inline-flex"
                >
                    <base-back-icon />
                    <span>Settings</span>
                </router-link>
                <base-page-title title="TiPJAR Integration" />
            </div>

            <BaseSpinner :isLoading="isLoading" />
        </div>
        <div>
            <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                            >Details</h3
                        >
                        <p class="mt-2 text-sm text-gray-500">
                            Enter your TiPJAR shortcode and we'll embed a
                            tracked link on the order status page so your
                            customers can more easily tip your team members.
                        </p>
                    </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0">
                    <div class="shadow sm:overflow-hidden sm:rounded-md">
                        <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
                            <div class="grid grid-cols-3 gap-6">
                                <div class="col-span-3 sm:col-span-2">
                                    <base-input-field
                                        label="TiPJAR Shortcode"
                                        id="name"
                                        v-model.trim="settings.tipjarShortcode"
                                        placeholder="eg tip_jar"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <BaseSpacerWithRuler />

        <div class="my-6 ml-4 flex flex-shrink-0 justify-end">
            <span class="inline-flex rounded-md shadow-sm" @click="saveVenue">
                <button
                    type="button"
                    class="focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                    :disabled="isLoading"
                >
                    Save
                </button>
            </span>
        </div>

        <!--        <FooterHelp label="notices" link="https://storekit.com" />-->
    </div>
</template>

<script>
// import FooterHelp from '../../helpers/FooterHelp';

export default {
    name: 'TipjarSettings',
    props: ['venueId', 'venue'],
    metaInfo: {
        title: 'TipJar Settings'
    },
    data() {
        return {
            isLoading: false,
            settings: { ...this.venue }
        };
    },
    methods: {
        async saveVenue() {
            try {
                this.isLoading = true;

                const payload = {
                    tipjarShortcode: this.settings.tipjarShortcode
                };
                // eslint-disable-next-line
                const response = await this.$axios.put(
                    '/venues/' + this.venueId,
                    payload
                );

                this.$notify({
                    group: 'settings',
                    title: 'Settings saved'
                });
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        }
    }
};
</script>
