<template>
    <div>
        <div v-if="!isLoading">
            <div class="mt-3 flex flex-col sm:flex-row">
                <div class="w-full sm:w-2/3">
                    <label
                        for="name"
                        class="block text-sm font-medium leading-5 text-gray-700"
                        >Name</label
                    >
                    <div class="relative mt-1 rounded-md shadow-sm">
                        <input
                            id="name"
                            v-model="group.name"
                            class="form-input block w-full sm:text-sm sm:leading-5"
                            :class="{ 'border-red-300': $v.group.name.$error }"
                            placeholder="eg Choose a side"
                        />
                    </div>
                </div>
                <div class="mt-3 w-full pl-4 sm:mt-0 sm:w-1/3">
                    <label
                        for="sku"
                        class="block text-sm font-medium leading-5 text-gray-700"
                    >
                        SKU / PLU
                    </label>
                    <div class="relative mt-1 rounded-md shadow-sm">
                        <input
                            id="sku"
                            v-model="group.plu"
                            class="form-input block w-full sm:text-sm sm:leading-5"
                            placeholder="ABC01"
                        />
                    </div>
                </div>
            </div>
            <div
                v-if="
                          isFeatureAvailable(FeaturesEnum.TISSL)
                      "
                class="mt-2"
            >
              <base-input-field v-model="group.posId" label="POS ID" />
            </div>

            <div class="mt-3" v-if="showDescription">
                <label
                    for="description"
                    class="block text-sm font-medium leading-5 text-gray-700"
                >
                    Description
                </label>
                <div class="relative mt-1 rounded-md shadow-sm">
                    <textarea
                        id="description"
                        v-model="group.description"
                        class="form-input block w-full sm:text-sm sm:leading-5"
                    />
                </div>
            </div>
            <div
                @click="showDescription = true"
                class="my-4 flex cursor-pointer items-center text-sm font-semibold text-gray-500"
                v-else
            >
                <svg
                    class="h-3 w-3"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path d="M12 4v16m8-8H4"></path>
                </svg>
                <span
                    class="ml-1 cursor-pointer text-sm font-medium text-gray-500"
                >
                    Add description
                </span>
            </div>

            <div class="mt-3">
                <div
                    class="overflow-x-auto rounded-sm border border-gray-100 shadow-inner"
                >
                    <table
                        class="min-w-full table-auto divide-y divide-gray-200"
                    >
                        <thead>
                            <tr>
                                <th
                                    scope="col"
                                    colspan="2"
                                    class="py-3 pl-6 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                                >
                                    Option
                                </th>
                                <th
                                    v-if="
                                        isFeatureAvailable(
                                            FeaturesEnum.CENTEGRA
                                        ) ||
                                        isFeatureAvailable(FeaturesEnum.COMTREX) ||
                                        isFeatureAvailable(FeaturesEnum.TISSL)
                                    "
                                    scope="col"
                                    class="py-3 pl-6 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                                >
                                    POS ID
                                </th>
                                <th
                                    v-if="
                                        isFeatureAvailable(
                                            FeaturesEnum.CENTEGRA
                                        )
                                    "
                                    scope="col"
                                    class="py-3 pl-6 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                                >
                                    TAX
                                </th>
                                <th
                                    scope="col"
                                    class="py-3 pl-6 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                                >
                                    Price
                                </th>
                                <th
                                    v-if="
                                        isFeatureAvailable(
                                            FeaturesEnum.SALE_PRICE
                                        )
                                    "
                                    scope="col"
                                    class="py-3 pl-6 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                                >
                                    Original Price
                                </th>
                                <th
                                    scope="col"
                                    colspan="2"
                                    class="py-3 pl-6 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                                >
                                    In Stock
                                </th>
                            </tr>
                        </thead>

                        <ModifierGroupListOptionsContainer
                            v-if="group.products && group.products.length"
                            lockAxis="y"
                            v-model="group.products"
                            helperClass="z-1000"
                            :distance="15"
                            @input="getSortedProducts"
                        >
                            <ModifierGroupListOption
                                v-for="(product, index) in $v.group.products
                                    .$each.$iter"
                                :key="index"
                                :product="product"
                                :index="index"
                                :disabled="newOption"
                                @remove-product="removeProduct"
                                @add-modifier="addModifier"
                                @update="updateModifierGroup"
                            />
                        </ModifierGroupListOptionsContainer>
                    </table>

                    <div class="p-3">
                        <div
                            class="flex justify-center rounded-md bg-white px-6 pb-3 pt-2 text-center"
                        >
                            <p class="mt-1 text-sm text-gray-600">
                                <button
                                    @click.prevent="addModifier"
                                    type="button"
                                    class="focus:outline-none font-medium text-indigo-600 transition duration-150 ease-in-out hover:text-indigo-500 focus:underline"
                                >
                                    Add an option
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-6">
                <label
                    for="description"
                    class="block text-sm font-medium leading-5 text-gray-700"
                    >How many options can customers choose?</label
                >
                <div class="mt-3 flex flex-col sm:flex-row">
                    <div class="w-full sm:w-1/2">
                        <label
                            for="min"
                            class="block text-sm font-medium leading-5 text-gray-700"
                        >
                            <!--                        Minimum-->
                        </label>
                        <div class="relative mt-1 flex flex-row rounded-md">
                            <div
                                @click="
                                    group.min > 0
                                        ? group.min--
                                        : (group.min = 0)
                                "
                                class="relative inset-y-0 left-0 flex cursor-pointer items-center pl-3"
                            >
                                <span
                                    class="text-gray-500 sm:text-sm sm:leading-5"
                                >
                                    <svg
                                        class="h-5 w-5"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                        ></path>
                                    </svg>
                                </span>
                            </div>
                            <currency-input
                                id="min"
                                name="min"
                                v-model="group.min"
                                class="form-input mx-2 block w-full pl-7 pr-12 text-center shadow-sm sm:text-sm sm:leading-5"
                                :class="{
                                    'border-red-300': $v.group.min.$error
                                }"
                                placeholder="0"
                                :currency="null"
                                :precision="0"
                                locale="en-GB"
                                :value-as-integer="true"
                                :allow-negative="false"
                            ></currency-input>
                            <div
                                @click="group.min++"
                                class="relative inset-y-0 right-0 flex cursor-pointer items-center pr-3"
                            >
                                <span
                                    class="text-gray-500 sm:text-sm sm:leading-5"
                                >
                                    <svg
                                        class="h-5 w-5"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                        ></path>
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="mt-2 sm:mt-0">
                        <label
                            for="max"
                            class="block text-sm font-medium leading-5 text-gray-700"
                        >
                        </label>
                        <div
                            class="relative mt-1 flex select-none flex-row rounded-md"
                        >
                            <div
                                @click="
                                    group.max > 0
                                        ? group.max--
                                        : (group.max = 0)
                                "
                                class="relative inset-y-0 left-0 flex cursor-pointer items-center pl-3"
                            >
                                <span
                                    class="text-gray-500 sm:text-sm sm:leading-5"
                                >
                                    <svg
                                        class="h-5 w-5"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                        ></path>
                                    </svg>
                                </span>
                            </div>
                            <currency-input
                                id="max"
                                name="max"
                                v-model="group.max"
                                class="form-input mx-2 block w-full pl-7 pr-12 text-center shadow-sm sm:text-sm sm:leading-5"
                                placeholder="0"
                                :currency="null"
                                :precision="0"
                                locale="en-GB"
                                :value-as-integer="true"
                                :allow-negative="false"
                            ></currency-input>
                            <div
                                @click="group.max++"
                                class="relative inset-y-0 right-0 flex cursor-pointer items-center pr-3"
                            >
                                <span
                                    class="text-gray-500 sm:text-sm sm:leading-5"
                                >
                                    <svg
                                        class="h-5 w-5"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                                        ></path>
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <p v-if="$v.group.min.$error" class="mt-3 text-xs text-red-600">
                    The minimum value of the options to be chosen cannot exceed
                    the maximum value
                </p>
            </div>

            <div class="grid grid-cols-2 gap-6">
                <div class="mt-6">
                    <label
                        for="initialDisplaySetting"
                        class="block text-sm font-medium leading-5 text-gray-700"
                    >
                        Initial display setting
                    </label>

                    <select
                        v-model="group.initialDisplaySetting"
                        id="initialDisplaySetting"
                        name="initialDisplaySetting"
                        class="focus:outline-none mt-2 block w-full rounded-md border border-gray-300 px-3 py-2 pr-10 text-sm capitalize shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    >
                        <option
                            v-for="setting in Object.values(
                                initialDisplaySettingEnum
                            )"
                            :value="setting"
                            :key="setting"
                            :selected="setting === group.initialDisplaySetting"
                            :disabled="
                                setting === initialDisplaySettingEnum.HIDDEN
                            "
                        >
                            {{ setting }}
                        </option>
                    </select>
                </div>
                <div
                    v-if="
                        isFeatureAvailable(FeaturesEnum.TISSL) ||
                        isFeatureAvailable(FeaturesEnum.CENTEGRA) ||
                        isFeatureAvailable(FeaturesEnum.INTERNAL)
                    "
                    class="mt-6"
                >
                    <label
                        for="description"
                        class="block text-sm font-medium leading-5 text-gray-700"
                        >Print modifier options as products?</label
                    >

                    <toggle-switch
                        class="mt-4"
                        v-model="group.printModifiersAsItems"
                    />
                </div>
            </div>

            <div
                class="mt-5 select-none flex-row-reverse justify-between sm:mt-12 sm:flex sm:w-auto"
            >
                <div class="flex flex-col md:flex-row">
                    <span
                        class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto"
                    >
                        <button
                            @click="$emit('stop-editing')"
                            type="button"
                            class="focus:outline-none inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium leading-6 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline sm:text-sm sm:leading-5"
                        >
                            Go Back
                        </button>
                    </span>
                    <span
                        class="flex w-full rounded-md shadow-sm sm:ml-3 md:w-36"
                    >
                        <button
                            @click="
                                isEdit
                                    ? updateModifierGroup()
                                    : createModifierGroup()
                            "
                            :disabled="isLoading"
                            type="button"
                            class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo sm:text-sm sm:leading-5"
                        >
                            Save Modifiers
                        </button>
                    </span>
                </div>
                <div v-if="group.id">
                    <span class="mt-6 flex w-full rounded-md sm:w-auto md:mt-0">
                        <button
                            @click="deleteModifierGroup"
                            :disabled="isLoading"
                            type="button"
                            class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium leading-6 text-red-600 transition duration-150 ease-in-out hover:bg-red-500 hover:text-white hover:shadow-sm focus:border-red-700 focus:shadow-outline-red sm:text-sm sm:leading-5"
                        >
                            Remove Group
                        </button>
                    </span>
                </div>
            </div>
        </div>

        <div v-else class="flex h-56 w-full items-center justify-center">
            <div class="spinner"></div>
        </div>
    </div>
</template>

<script>
import { required, integer } from 'vuelidate/lib/validators';
import { FeaturesEnum } from '@/enums';
import ModifierGroupListOptionsContainer from './ModifierGroupListOptionsContainer';
import ModifierGroupListOption from './ModifierGroupListOption';
import ToggleSwitch from '@/components/formComponents/ToggleSwitch';
import { mapGetters } from 'vuex';
import BaseInputField from "@/components/base/form/BaseInputField.vue";

const initialDisplaySettingEnum = Object.freeze({
    EXPANDED: 'expanded',
    COLLAPSED: 'collapsed',
    HIDDEN: 'hidden'
});

export default {
    name: 'CreateEditModifierGroup',
    props: {
        product: {
            type: Object,
            required: true
        },
        venue: {
            type: Object,
            required: true
        },
        modifierGroup: {
            type: String,
            required: false
        }
    },
    components: {
      BaseInputField,
        ModifierGroupListOptionsContainer,
        ModifierGroupListOption,
        ToggleSwitch
    },
    validations: {
        group: {
            name: {
                required
            },
            min: {
                required,
                integer,
                shouldBeLess: (value, vm) => value <= vm.max
            },
            max: {
                required,
                integer
            },
            printModifiersAsItems: {
                required
            },
            products: {
                $each: {
                    name: {
                        required
                    },
                    printName: {},
                    price: {
                        required
                    },
                    isAvailable: {},
                    trackInventory: {},
                    inventory: {},
                    posId: {
                        required(value) {
                            return ['comtrex', 'centegra'].includes(
                                this.venue.posProvider
                            )
                                ? !!(value && value.length)
                                : true;
                        }
                    },
                    deliveryTax: {},
                    compareAtPrice: {}
                }
            }
        }
    },
    async created() {
        if (this.modifierGroup) {
            await this.fetchModifierGroup();
            this.isEdit = true;
        }
    },
    computed: {
        ...mapGetters({ isFeatureAvailable: 'user/isFeatureAvailable' }),
        newOption() {
            return this.group.products.some(product => product.addMode);
        }
    },
    data() {
        return {
            FeaturesEnum,
            isEdit: false,
            isLoading: false,
            showDescription: false,
            group: {
                name: '',
                description: '',
                required: null,
                image: '',
                plu: '',
                min: 1,
                max: 1,
                products: [],
                printModifiersAsItems: false,
                initialDisplaySetting: initialDisplaySettingEnum.EXPANDED
            },
            optionsRequired: [
                {
                    value: 'true',
                    label: 'Yes'
                },
                {
                    value: 'false',
                    label: 'No'
                }
            ],
            sortedProducts: [],
            initialDisplaySettingEnum
        };
    },
    methods: {
        async fetchModifierGroup() {
            this.isLoading = true;

            try {
                const { data } = await this.$axios.get(
                    `/groups/${this.modifierGroup}`
                );

                this.group = data;
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        },
        async createModifierGroup() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return false;
            }

            if (this.newOption) {
                this.removeAddMode();
            }

            const group = {
                name: this.group.name,
                description: this.group.description,
                plu: this.group.plu,
                image: this.group.image,
                min: this.group.min,
                max: this.group.max,
                products: this.group.products,
                printModifiersAsItems: this.group.printModifiersAsItems,
                initialDisplaySetting: this.group.initialDisplaySetting,
                posId: this.group.posId
            };

            this.isLoading = true;

            try {
                const { data } = await this.$axios.post(
                    `/products/${this.product.id}/groups`,
                    group
                );

                this.isEdit = true;
                this.group.id = data.id;

                this.$emit('product-updated');
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        },
        async updateModifierGroup() {
            this.$v.$touch();

            if (this.$v.$invalid || !this.group.id) {
                return false;
            }

            if (this.newOption) {
                this.removeAddMode();
            }

            const group = {
                id: this.group.id,
                name: this.group.name,
                description: this.group.description,
                plu: this.group.plu,
                image: this.group.image,
                min: this.group.min,
                max: this.group.max,
                products: this.group.products,
                printModifiersAsItems: this.group.printModifiersAsItems,
                initialDisplaySetting: this.group.initialDisplaySetting,
                posId: this.group.posId
            };

            this.isLoading = true;

            try {
                await this.$axios.patch(`/groups/${this.group.id}`, group);

                if (this.sortedProducts.length) {
                    const indexedProducts = this.sortedProducts.map(
                        (group, index) => {
                            return { id: group.id, index };
                        }
                    );

                    await this.$axios.put('/products/sort', {
                        items: indexedProducts,
                        index: 'index'
                    });
                }

                this.$emit('product-updated');
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        },
        async deleteModifierGroup() {
            this.isLoading = true;

            try {
                await this.$axios.delete(
                    `/products/${this.product.id}/groups/${this.group.id}`
                );

                this.$emit('group-removed');
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        },
        addModifier() {
            if (this.sortedProducts.length) {
                window.alert(
                    'First save the order of options then add a new option'
                );

                return;
            }
            const item = {
                name: '',
                image: '',
                price: 0,
                description: '',
                type: 'modifier',
                addMode: true,
                isAvailable: true,
                trackInventory: false,
                inventory: 0,
                index: this.group.products.length + 1,
                tags: [],
                posId: null,
                nutrition: {}
            };
            this.group.products.push(item);
        },
        removeProduct(product) {
            this.group.products = this.group.products.filter(
                x => x !== product
            );
        },
        getSortedProducts(sortedArray) {
            this.sortedProducts = sortedArray;
        },
        removeAddMode() {
            this.group.products.map(product => {
                if (product.addMode) {
                    delete product.addMode;
                }
            });
        }
    }
};
</script>
