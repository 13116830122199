<template>
    <sliding-sidebar
        v-model="isOpened"
        size="3xl"
        :title="
            !isLoading
                ? 'Table ' +
                  tableName +
                  ' / #' +
                  bill.providerId +
                  ' / ' +
                  'Covers: ' +
                  bill.covers
                : null
        "
        :id="bill.id"
        @on-escape-key-up="close"
        @sidebar-opened="onOpened"
    >
        <div v-if="!isLoading">
            <div class="flex items-start justify-between">
                <span class="text-xs text-gray-600">
                    Opened:
                    {{
                        bill.providerOpenDate ||
                        bill.created_at | moment('MMMM Do YYYY, h:mm:ss a')
                    }}</span
                >
                <div>
                    <button
                        @click="fetchBill"
                        :disabled="billLoading"
                        type="button"
                        :class="{ 'cusor-wait': billLoading }"
                        class="focus:outline-none inline-flex items-center rounded px-2.5 py-1.5 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                        <base-refresh-icon :loading="billLoading" />
                        Refresh
                    </button>
                </div>
            </div>
            <div>
                <div class="mt-4 sm:mt-12">
                    <div
                        class="-mx-4 mt-6 bg-white ring-1 ring-gray-300 sm:-mx-6 md:mx-0 md:rounded-lg"
                    >
                        <table class="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr>
                                    <th
                                        scope="col"
                                        class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                        >Time</th
                                    >
                                    <th
                                        scope="col"
                                        class="table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >Payment</th
                                    >
                                    <th
                                        scope="col"
                                        class="table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >Paid</th
                                    >
                                    <th
                                        scope="col"
                                        class="table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >POS Status</th
                                    >

                                    <th
                                        scope="col"
                                        class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >Total</th
                                    >
                                    <th
                                        scope="col"
                                        class="relative py-3.5 pl-3 pr-4 sm:pr-6"
                                    >
                                        <span class="sr-only">View</span>
                                    </th>
                                </tr>
                            </thead>

                            <tbody v-auto-animate>
                                <tr
                                    v-for="payment in bill.billPayments"
                                    :key="payment.id"
                                >
                                    <td
                                        class="relative py-4 pl-4 pr-3 text-xs sm:pl-6 sm:text-sm"
                                    >
                                        <div
                                            class="font-medium text-gray-900"
                                            >{{
                                                payment.created_at
                                                    | moment('h:mm a')
                                            }}</div
                                        >
                                    </td>
                                    <td
                                        class="flex flex-row items-center px-3 py-3.5 text-xs text-gray-500 sm:text-sm"
                                    >
                                        <BillPaymentTransactions
                                            v-if="saleTransaction(payment)"
                                            :transaction="
                                                saleTransaction(payment)
                                            "
                                        />
                                        <div
                                            v-else
                                            class="flex flex-row items-center rounded-md bg-gray-50 py-1.5 pl-2 pr-2 sm:pr-3"
                                        >
                                            <img
                                                v-if="
                                                    bill.provider === 'ikentoo'
                                                "
                                                class="sm:mr-2"
                                                src="https://ucarecdn.com/d9c88ec0-3051-4966-a467-ca26e94489b6/-/format/auto/"
                                                alt=""
                                                width="16"
                                                height="16"
                                            />
                                            <base-zonal-icon
                                                class="sm:mr-2"
                                                v-if="
                                                    bill.provider === 'comtrex'
                                                "
                                            />
                                            <span
                                                class="hidden text-xs font-semibold uppercase sm:inline-block"
                                                >External</span
                                            >
                                        </div>
                                    </td>
                                    <td
                                        class="table-cell px-3 py-3.5 text-xs text-gray-500 sm:text-sm"
                                    >
                                        <span
                                            v-if="
                                                saleTransaction(payment) &&
                                                saleTransaction(payment)
                                                    .paymentStatus ===
                                                    'Authorized'
                                            "
                                            class="inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800"
                                        >
                                            Paid
                                        </span>
                                        <span
                                            v-else-if="
                                                saleTransaction(payment) &&
                                                saleTransaction(payment)
                                                    .paymentStatus === 'Pending'
                                            "
                                            class="inline-flex items-center rounded-full bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800"
                                        >
                                            Pending
                                        </span>
                                        <div
                                            v-else-if="
                                                saleTransaction(payment) &&
                                                saleTransaction(payment)
                                                    .paymentStatus ===
                                                    'Unauthorized'
                                            "
                                            v-tooltip="
                                                saleTransaction(payment)
                                                    ? saleTransaction(payment)
                                                          .failureReason
                                                    : ''
                                            "
                                            class="flex cursor-pointer items-center rounded-full bg-red-100 px-2.5 py-0.5"
                                        >
                                            <base-warning-icon
                                                class="mr-1 flex-shrink-0 self-start text-red-800"
                                            />
                                            <span
                                                class="inline-flex items-center text-xs font-medium text-red-800"
                                            >
                                                Failed
                                            </span>
                                        </div>
                                    </td>
                                    <td
                                        class="table-cell px-3 py-3.5 text-xs text-gray-500 sm:text-sm"
                                    >
                                        <span
                                            v-if="payment.status === 'success'"
                                            class="inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800"
                                        >
                                            Success
                                        </span>
                                        <span
                                            v-else-if="
                                                payment.status === 'in_progress'
                                            "
                                            class="inline-flex items-center rounded-full bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800"
                                        >
                                            Pending
                                        </span>
                                        <div
                                            v-else
                                            v-tooltip="
                                                payment.posErrorMessage
                                                    ? `POS Error: ${payment.posErrorMessage}`
                                                    : ''
                                            "
                                            class="flex cursor-pointer items-center rounded-full bg-red-100 px-2.5 py-0.5"
                                        >
                                            <base-warning-icon
                                                class="mr-1 flex-shrink-0 self-start text-red-800"
                                            />
                                            <span
                                                class="inline-flex items-center text-xs font-medium text-red-800"
                                            >
                                                Failed
                                            </span>
                                        </div>
                                    </td>
                                    <td
                                        class="px-3 py-3.5 text-sm text-gray-500"
                                    >
                                        <div
                                            >{{
                                                (payment.total / 100) | currency
                                            }}
                                            <span
                                                v-if="payment.tip"
                                                class="text-xs"
                                            >
                                                (+{{
                                                    (payment.tip / 100)
                                                        | currency
                                                }})</span
                                            ></div
                                        >
                                    </td>
                                    <td
                                        v-if="
                                            payment.paymentType === 'Adyen' &&
                                            saleTransaction(payment) &&
                                            saleTransaction(payment)
                                                .paymentStatus === 'Authorized'
                                        "
                                        class="relative py-3.5 pl-0 pr-4 text-right text-sm font-medium sm:pr-0"
                                    >
                                        <button
                                            @click="downloadInvoice(payment.id)"
                                            type="button"
                                            class="focus:outline-none mr-1 inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
                                        >
                                            <base-receipt-icon
                                                class="inline-block h-4 w-4 sm:mr-1"
                                            />
                                            <span
                                                class="hidden sm:inline-block"
                                            >
                                                Invoice
                                            </span>
                                        </button>
                                        <button
                                            @click="showRefundModal(payment)"
                                            type="button"
                                            class="focus:outline-none mr-1 inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
                                        >
                                            <base-refund-icon
                                                class="inline-block sm:mr-1"
                                            />
                                            <span
                                                class="hidden sm:inline-block"
                                            >
                                                Refund
                                            </span>
                                        </button>
                                        <button
                                            v-if="
                                                isFeatureAvailable(
                                                    FeaturesEnum.INTERNAL
                                                ) && payment.status === 'failed'
                                            "
                                            @click="
                                                showResendForm = !showResendForm
                                            "
                                            type="button"
                                            class="focus:outline-none mr-1 inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
                                        >
                                            <svg
                                                class="inline-block md:mr-1"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="1.5"
                                                viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    d="M19.5 13.5L12 21m0 0l-7.5-7.5M12 21V3"
                                                ></path>
                                            </svg>
                                            <span
                                                class="hidden sm:inline-block"
                                            >
                                                Resend
                                            </span>
                                        </button>
                                        <div v-if="showResendForm">
                                            total:
                                            <currency-input
                                                id="resentTotal"
                                                v-model="paymentResend.total"
                                                class="form-input block w-full pl-7 pr-12 sm:text-sm sm:leading-5"
                                                placeholder="Total"
                                                :currency="null"
                                                locale="en-GB"
                                                :value-as-integer="true"
                                                :allow-negative="false"
                                            ></currency-input>
                                            tip:
                                            <currency-input
                                                id="resentTip"
                                                v-model="paymentResend.tip"
                                                class="form-input block w-full pl-7 pr-12 sm:text-sm sm:leading-5"
                                                placeholder="Tip"
                                                :currency="null"
                                                locale="en-GB"
                                                :value-as-integer="true"
                                                :allow-negative="false"
                                            ></currency-input>
                                            <button
                                                @click="resendToPOS(payment)"
                                                type="button"
                                                class="focus:outline-none mr-1 inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
                                            >
                                                <base-refresh-icon
                                                    class="inline-block sm:mr-1"
                                                />
                                                <span
                                                    class="hidden sm:inline-block"
                                                >
                                                    Resend Payment
                                                </span>
                                            </button>
                                        </div>
                                    </td>
                                </tr>

                                <tr
                                    v-if="
                                        !isLoading &&
                                        bill.billPayments &&
                                        bill.billPayments.length === 0
                                    "
                                >
                                    <td
                                        colspan="7"
                                        class="relative w-full rounded-lg p-12 text-center"
                                    >
                                        <span
                                            class="mt-2 block text-sm font-medium text-gray-900"
                                        >
                                            No payments yet
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="mt-4 sm:mt-8" v-if="bill.orders && bill.orders.length">
                <h2 class="font-heading text-lg font-medium text-gray-900"
                    >Related orders:</h2
                >
                <bill-payment-orders :orders="bill.orders" />
            </div>
            <div class="mt-5 justify-between py-3 sm:flex sm:flex-row">
                <span
                    v-if="bill.provider === 'ikentoo'"
                    class="inline-flex w-auto rounded-md shadow-sm"
                >
                    <a
                        :href="`https://manager.lsk.lightspeed.app/receipt/list/day/${day}/${day}?accountId=${bill.providerId}`"
                        target="_blank"
                        type="button"
                        class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
                    >
                        <img
                            class="mr-2"
                            src="https://ucarecdn.com/d9c88ec0-3051-4966-a467-ca26e94489b6/-/format/auto/"
                            alt=""
                            width="16"
                            height="16"
                        />
                        View in Lightspeed
                    </a>
                </span>
                <span
                    class="mt-3 flex w-full justify-end rounded-md shadow-sm sm:mt-0 sm:w-auto"
                    :class="{ 'opacity-50': isLoading }"
                >
                    <button
                        @click="close"
                        :disabled="isLoading"
                        type="button"
                        class="focus:outline-none inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium leading-6 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                    >
                        Close
                    </button>
                </span>
            </div>
            <RefundModal :is-bill-payment="true" />
        </div>
        <div v-else class="flex h-full items-center p-10">
            <div class="mx-auto spinner"></div>
        </div>
    </sliding-sidebar>
</template>

<script>
import SlidingSidebar from '../SlidingSidebar';
import RefundModal from '@/components/modals/RefundModal';
import BillPaymentTransactions from '@/components/widgets/BillPaymentTransactions';
import BillPaymentOrders from '@/components/widgets/BillPaymentOrders';
import BaseRefundIcon from '../base/icons/BaseRefundIcon';
import { FeaturesEnum } from '@/enums';
import { mapGetters } from 'vuex';

export default {
    name: 'TicketSidebar',
    components: {
        BaseRefundIcon,
        SlidingSidebar,
        RefundModal,
        BillPaymentTransactions,
        BillPaymentOrders
    },
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isLoading: true,
            billLoading: false,
            showResendForm: false,
            bill: {},
            FeaturesEnum,
            paymentResend: {
                total: 0,
                tip: 0
            }
        };
    },
    computed: {
        ...mapGetters({
            isFeatureAvailable: 'user/isFeatureAvailable'
        }),
        isOpened: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        tableName() {
            return this.bill?.table?.posId;
        },
        day() {
            return this.$moment(
                this.bill.providerOpenDate || this.bill.created_at
            ).format('YYYY-MM-DD');
        }
    },
    methods: {
        onOpened() {
            Object.assign(this.$data, this.$options.data.apply(this));
            this.fetchBill();
        },
        async fetchBill() {
            this.billLoading = true;
            try {
                const { data } = await this.$axios.get(
                    `/bills/fetch-expanded`,
                    {
                        params: {
                            billId: this.$route.query.bill,
                            venueId: this.$route.query.venueId
                        }
                    }
                );
                this.bill = data;
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.billLoading = false;
                this.isLoading = false;
            }
        },
        saleTransaction(billPayment) {
            const saleTransaction = billPayment.transactions.find(
                transaction => transaction.kind === 'Sale'
            );

            return saleTransaction;
        },
        close() {
            this.$router.push({
                path: this.$route.path,
                query: null
            });
            this.isOpened = false;
        },
        showRefundModal(billPayment) {
            billPayment.venue = this.bill.venue;
            billPayment.venueId = this.bill.venueId;

            this.$modal.show('refund-modal', {
                item: billPayment
            });
        },
        async resendToPOS(payment) {
            this.billLoading = true;
            try {
                await this.$axios.post(`/bills/retry-pos`, {
                    billPaymentId: payment.id,
                    total: this.paymentResend.total,
                    tip: this.paymentResend.tip
                });
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.fetchBill();

                this.billLoading = false;
                this.isLoading = false;
            }
        },
        downloadInvoice(billPaymentId) {
            window.open(
                `https://api.order.storekit.com/bills/get-invoice?billPaymentId=${billPaymentId}`,
                '_blank'
            );
        }
    }
};
</script>
