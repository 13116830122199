<template>
    <div
        class="mx-auto mt-6 grid max-w-3xl max-w-5xl gap-4 sm:mt-8 sm:grid-cols-1"
    >
        <div
            v-for="venueType in venueTypes"
            :key="venueType.type"
            class="group cursor-pointer rounded border-2 border-gray-200 px-4 py-2 shadow-sm transition transition-all duration-300 hover:border-indigo-600"
            :class="{
                'border-indigo-800 bg-indigo-50': venueMode === venueType.type
            }"
            @click="selectOption(venueType.type)"
            @mouseover="hoverOption(venueType.type)"
        >
            <h3 class="font-heading text-base font-bold text-gray-700">{{
                venueType.title
            }}</h3>
            <p class="mb-2 text-sm text-gray-700">{{ venueType.subTitle }}</p>
            <ul>
                <li
                    v-for="(attribute, i) in venueType.attributes"
                    :key="i"
                    class="flex text-xs font-medium text-gray-600"
                >
                    <base-green-tick-icon />
                    {{ attribute }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'VenueTypeSelection',
    props: {
        venueMode: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            venueModes: {
                TAKEAWAY: 'takeaway',
                ORDER_AND_PAY: 'orderAndPay',
                VISUAL_MENU: 'visualMenu'
            },
            venueTypes: [
                {
                    type: 'takeaway',
                    title: 'Takeaway',
                    subTitle: 'Delivery, Click & Collect, Pre-orders',
                    description: 'Take orders online for delivery and pickup',
                    attributes: [
                        'Delivery zones',
                        'Driver tracking',
                        'Pre-order slot capacity management'
                    ]
                },
                {
                    type: 'orderAndPay',
                    subTitle: 'QR code ordering',
                    title: 'Dine-In',
                    description: 'Take orders in venue',
                    attributes: [
                        'Order to the table',
                        'QR code / NFC tag ordering',
                        'Tips & service charge'
                    ]
                },
                {
                    type: 'visualMenu',
                    title: 'Digital Menu',
                    subTitle: 'No ordering',
                    description: 'Build a visual menu without ordering',
                    attributes: [
                        'Visual menu with photos and allergy info',
                        'Access via QR code / NFC tags',
                        'Upgrade to Order & Pay later'
                    ]
                }
            ]
        };
    },
    methods: {
        selectOption(val) {
            this.$emit('selected-option', val);
        },
        hoverOption(val) {
            this.$emit('hover-option', val);
        }
    }
};
</script>
