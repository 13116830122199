import Vue from 'vue';

Vue.filter('truncateString', (value, limit = 20) => {
    if (!value) {
        return '';
    }

    if (value.length > limit) {
        value = value.substring(0, limit) + '...';
    }

    return value;
});
