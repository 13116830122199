<template>
  <div>
    <button
        v-for="method in FulfillmentMethodsEnum"
        :key="method.name"
        @click="toggleFulfillment(method.name)"
        type="button"
        class="mr-2 mt-2 shadow-sm px-3 py-1.5 inline-flex items-center ease-in-out  font-medium text-sm transition duration-200 rounded-md border border-gray-200"
        :class="[
            selectedFulfillmentMethods.includes(method.name) ? 'border-black bg-black text-white' : 'text-gray-900 bg-gray-100',
            {'border-red-300 bg-red-50 text-gray-900': error},
        ]"
    >
      <component :is="method.icon" size="sm" />
      <span class="ml-2">
        {{ method.label }}
      </span>
    </button>
    <span
        v-if="error"
        class="block mt-2 text-sm text-red-600">
      Please select one or more order types
    </span>
  </div>
</template>

<script>
import DeliveryIcon from "@/components/common/DeliveryIcon.vue";
import InstoreIcon from "@/components/common/InstoreIcon.vue";
import PickupIcon from "@/components/common/PickupIcon.vue";
import { FulfillmentMethodsEnum } from '@/enums';



export default {
  name: 'FulfillmentMethodPicker',
  components: { DeliveryIcon, PickupIcon, InstoreIcon },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    error: {
      type: Boolean
    }
  },
  data() {
    return {
      FulfillmentMethodsEnum: FulfillmentMethodsEnum.nonCateringOptions()
    };
  },
  computed: {
    selectedFulfillmentMethods: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
  methods: {
    toggleFulfillment(method) {
      if (this.selectedFulfillmentMethods.length === 1 && this.selectedFulfillmentMethods.includes(method)) {
        return; // Prevent the last selected day from being deleted
      }

      this.selectedFulfillmentMethods = this.selectedFulfillmentMethods.includes(method)
          ? this.selectedFulfillmentMethods.filter((day) => day !== method)
          : [...this.selectedFulfillmentMethods, method];
    }
  }

}
</script>