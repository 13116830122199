<template>
    <div class="mb-4 rounded-md bg-white shadow" :class="{ 'opacity-50': menu.archived }">
        <div>
            <div
                class="flex flex-wrap items-center justify-between border-b border-gray-200 px-4 py-5 shadow-sm sm:flex-nowrap sm:px-6"
            >
                <div>
                    <h3
                        class="font-heading text-base font-medium flex items-center leading-6 text-gray-900"
                    >
                        <span
                            @click="
                                $router.push({
                                    name: 'editMenu',
                                    params: { id: menu.id }
                                })
                            "
                            class="text-indigo-600 cursor-pointer">
                            {{ menu.name }}
                        </span>
                      <venue-name-tag
                          class="hidden sm:inline-block ml-3 bg-gray-50"
                          v-if="menu.venue && menu.venue.name"
                          :name="menu.venue.adminName || menu.venue.name"
                          :truncate-length="25"
                      />
                    </h3>
                    <span v-if="menu.lastSyncedAt" class="text-xs"
                        >Synced:
                        {{ menu.lastSyncedAt | moment('from', 'now') }}
                    </span>

                    <p class="mt-1 max-w-2xl text-xs leading-5">
                        Created: {{ menu.created_at | moment('LL') }}
                    </p>
                </div>
                <div>
                    <div
                        v-if="isFeatureAvailable(FeaturesEnum.MULTI_MENU)"
                        class="inline-flex items-center"
                    >
                        <span class="mr-3 text-sm leading-5 text-gray-500">
                            Toggle active / inactive
                        </span>
                        <toggle-switch
                            v-model="menu.active"
                            @input="updateMenuStatus(menu.id, menu.active)"
                        />
                    </div>

                    <div
                        class="mt-2 flex flex-shrink-0 flex-col items-center sm:ml-4"
                    >
                        <base-button
                            buttonText="Edit menu"
                            size="sm"
                            @clicked="
                                $router.push({
                                    name: 'editMenu',
                                    params: { id: menu.id }
                                })
                            "
                        />
                    </div>
                </div>
            </div>
            <div
                class="grid grid-cols-1 gap-5 rounded-b-md bg-gray-50 px-4 py-3 text-xs text-gray-600 shadow-inner sm:grid-cols-5 sm:px-6"
                v-if="menu.statistics && menu.statistics.productsCount > 0"
            >
                <div class="flex flex-col">
                    <span class="">
                        {{ menu.statistics.productsCount }} menu items
                    </span>
                    <span class="">
                        {{ menu.statistics.categoriesCount }} categories
                    </span>
                </div>
                <div class="ml-0 flex flex-col sm:ml-4">
                    <span
                        v-tooltip="
                            menu.statistics.productsCount -
                            menu.statistics.missingImage +
                            '/' +
                            menu.statistics.productsCount +
                            ' items have images. Adding images to products increases revenue up to 30%.'
                        "
                    >
                        Item images
                    </span>
                    <div
                        class="mt-1 h-2 w-full rounded-full bg-gray-200 shadow-inner dark:bg-gray-700"
                    >
                        <div
                            class="h-2 rounded-full"
                            :class="
                                progressBarColour(
                                    menu.statistics.imageCompletePercentage
                                )
                            "
                            :style="
                                'width: ' +
                                menu.statistics.imageCompletePercentage +
                                '%'
                            "
                        ></div>
                    </div>
                </div>
                <div class="ml-0 flex flex-col sm:ml-4">
                    <span
                        v-tooltip="
                            menu.statistics.productsCount -
                            menu.statistics.missingDescription +
                            '/' +
                            menu.statistics.productsCount +
                            ' items have descriptions. Add descriptions to each menu item to help guests.'
                        "
                    >
                        Item descriptions
                    </span>
                    <div
                        class="mt-1 h-2 w-full rounded-full bg-gray-200 shadow-inner"
                    >
                        <div
                            class="h-2 rounded-full"
                            :class="
                                progressBarColour(
                                    menu.statistics
                                        .descriptionCompletePercentage
                                )
                            "
                            :style="
                                'width: ' +
                                menu.statistics.descriptionCompletePercentage +
                                '%'
                            "
                        ></div>
                    </div>
                </div>
                <div class="ml-0 flex flex-col sm:ml-4">
                    <span
                        v-tooltip="
                            menu.statistics.productsCount -
                            menu.statistics.missingTags +
                            '/' +
                            menu.statistics.productsCount +
                            ' items have dietary or allergen tags. Add allergen tags for compliance and dietary tags to help guests filter dishes.'
                        "
                    >
                        Diets & allergens
                    </span>
                    <div
                        class="mt-1 h-2 w-full rounded-full bg-gray-200 shadow-inner"
                    >
                        <div
                            class="h-2 rounded-full"
                            :class="
                                progressBarColour(
                                    menu.statistics.tagCompletePercentage
                                )
                            "
                            :style="
                                'width: ' +
                                menu.statistics.tagCompletePercentage +
                                '%'
                            "
                        ></div>
                    </div>
                </div>
            </div>
            <div
                class="px-4 py-5 sm:px-6"
                v-if="menu.syndication && menu.syndication.length"
            >
                <base-opentable-logo
                    class="h-auto w-20"
                    v-tooltip="'Published on Open Table'"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FeaturesEnum } from '@/enums';
import ToggleSwitch from '@/components/formComponents/ToggleSwitch';
import VenueNameTag from "@/components/common/VenueNameTag";

export default {
    name: 'MenuListItem',
    components: {
        VenueNameTag,
        ToggleSwitch
    },
    props: {
        menu: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        }
    },
    computed: {
        ...mapGetters({ isFeatureAvailable: 'user/isFeatureAvailable' }),
        isPosManaged() {
            return !!this.menu.deliverectId;
        }
    },
    data() {
        return {
            FeaturesEnum
        };
    },
    methods: {
        async updateMenuStatus(id, active) {
            try {
                await this.$axios.patch(`/menus/${this.menu.id}`, { active });
            } catch (error) {
                throw new Error(`API ${error}`);
            }
        },
        progressBarColour(percent) {
            if (percent < 50) {
                return 'bg-red-500';
            } else if (percent < 75) {
                return 'bg-yellow-500';
            } else {
                return 'bg-green-500';
            }
        }
    }
};
</script>
