<template>
    <tr class="mt-2 w-full bg-white">
        <td class="whitespace-nowrap">
            <div class="relative flex flex-row items-center pl-3">
                <span class="inline-block cursor-pointer text-sm leading-5">
                    <date-range-picker
                        :opens="'left'"
                        class="relative special-hours-datepicker"

                        v-model="dateRange"
                        :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY' }"
                        :single-date-picker="true"
                        :show-week-numbers="true"
                        :show-dropdowns="true"
                        :auto-apply="true"
                        :ranges="false"
                        :linked-calendars="false"
                        @update="update"
                    >
                        <template v-slot:input="picker">
                            <div
                                class="h-9 text-gray-700 hover:text-gray-500 focus:outline-none select-none inline-flex cursor-pointer items-center rounded-md shadow-sm border border-gray-300 bg-white px-3 py-2 text-sm leading-5 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
                                :class="{
                                    'border-red-300': specialHour.date.$error
                                }"
                            >
                                <svg
                                    class="-ml-1 mr-3 h-5 w-5"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                                    ></path>
                                </svg>
                                <span class="special-hours-label">
                                  {{!picker.startDate ? 'Select date' : '' }} {{ picker.startDate ? picker.startDate : null | date }}
                                </span>
                            </div>
                        </template>
                    </date-range-picker>
                </span>
            </div>
        </td>
        <td class="whitespace-nowrap">
            <div class="relative flex flex-row items-center pl-3">
                <vue-timepicker
                    manual-input
                    :minute-interval="15"
                    :id="'hours-' + index"
                    :disabled="false"
                    v-model="specialHour.openTime.$model"
                    :class="{
                        'opacity-25': false,
                        error: specialHour.openTime.$error
                    }"
                />
            </div>
        </td>
        <td class="whitespace-nowrap">
            <div class="relative flex flex-row items-center pl-3">
                <vue-timepicker
                    manual-input
                    :minute-interval="15"
                    :id="'hours-' + index"
                    :disabled="false"
                    v-model="specialHour.closeTime.$model"
                    :class="{
                        'opacity-25': false,
                        error: specialHour.closeTime.$error
                    }"
                />
            </div>
        </td>
        <td class="whitespace-nowrap">
            <div class="flex whitespace-nowrap px-2 py-2">
                <div
                    @click="remove(specialHour)"
                    class="group my-1 cursor-pointer text-gray-400"
                >
                    <svg
                        class="h-5 w-5 group-hover:text-red-400"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                        ></path>
                    </svg>
                </div>
            </div>
        </td>
    </tr>
</template>
<script>
import VueTimepicker from "vue2-timepicker";
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

export default {
    name: 'SpecialHourGroupListOption',
    components: {
        VueTimepicker,
        DateRangePicker
    },
    data() {
        return {
            dateRange: {
                startDate: '',
                endDate:''
            }
        };
    },
    props: {
        specialHour: {
            type: Object,
            default: () => ({}),
            required: true
        }
    },
    mounted() {
        if (this.specialHour.date) {
            this.dateRange = {
                startDate: this.specialHour.date.$model,
                endDate:this.specialHour.date.$model
            }
        }
    },
    filters: {
        date(value) {
            if (!value) return '';
            let options = { year: '2-digit', month: 'short', day: 'numeric' };
            return Intl.DateTimeFormat('en-GB', options).format(value);
        }
    },
    methods: {
        remove(specialHour) {
            this.$emit('remove', specialHour.$model);
        },
        update(value) {
            this.specialHour.date.$model = this.$moment(value.startDate).format('YYYY-MM-DD')
        }
    },
    computed: {
        index() {
            return this.$vnode.data.key
        }
    }
};
</script>

<style>
.special-hours-datepicker {
    @apply block !important;
}
.special-hours-datepicker .special-hours-label {
    min-width: 100px !important;
}
.vue__time-picker.time-picker input {
    @apply h-9 !important;
}
</style>
