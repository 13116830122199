<template>
    <div class="h-4 w-4" :class="{ 'text-gray-900 spinner': isLoading }">
        <span></span>
    </div>
</template>

<script>
export default {
    name: 'BaseSpinner',
    props: {
        isLoading: {
            type: Boolean,
            required: true
        }
    }
};
</script>
