import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/tailwind.css';
import VModal from 'vue-js-modal';
import Axios from 'axios';
import Vuex from 'vuex';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import { Offline as OfflineIntegration } from '@sentry/integrations';
import ApiService from './helpers/api';
import auth from './helpers/auth';
import Vuelidate from 'vuelidate';
import VueTheMask from 'vue-the-mask';
import SlugInput from 'vue-slug-input';
import VueCurrencyFilter from 'vue-currency-filter';
import VueCurrencyInput from 'vue-currency-input';
import VueApexCharts from 'vue-apexcharts';
import 'vue2-timepicker/dist/VueTimepicker.css';
import VueSegmentAnalytics from 'vue-segment-analytics';
import VueLodash from 'vue-lodash';
import orderBy from 'lodash/orderBy';
import groupBy from 'lodash/groupBy';
import VueMeta from 'vue-meta';
import Paginate from 'vuejs-paginate';
import MultiSelect from 'vue-multiselect';
import FloatingVue from 'floating-vue'
import VueGoodTablePlugin from 'vue-good-table';
import Notifications from 'vue-notification';
import linkify from 'vue-linkify';
import defaultCurrencyConfig from '@/helpers/currencies';
import './filters/truncateString';
import './filters/pluralize';
import OpenReplay from '@openreplay/tracker';
import autoAnimate from "@formkit/auto-animate";
import 'floating-vue/dist/style.css'

// Globally register all `Base`-prefixed components
import './components/_globals';

Vue.use(VueGoodTablePlugin);
Vue.use(Notifications);

Vue.use(FloatingVue)
Vue.directive('linkified', linkify);

Vue.directive("auto-animate", {
    inserted: function (el, binding) {
        autoAnimate(el, binding.value);
    }
});

Vue.component('multi-select', MultiSelect);

Vue.component('paginate-records', Paginate);

Vue.prototype.$tracker = new OpenReplay({
    projectKey: process.env.VUE_APP_OPENREPLAY_PROJECT_KEY
});

Vue.use(VueMeta, {
    // optional pluginOptions
    refreshOnceOnNavigation: true
});

Vue.use(VueLodash, { lodash: { orderBy, groupBy } });

process.env.VUE_APP_SEGMENT_KEY
    ? Vue.use(VueSegmentAnalytics, {
          id: process.env.VUE_APP_SEGMENT_KEY,
          router // Optional
      })
    : false;

Vue.use(VueApexCharts);
window.Apex.chart = {
    fontFamily:
        '\'Inter\', system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    xaxis: {
        labels: {
            style: { fontSize: '15px' }
        }
    }
};
Vue.component('apex-chart', VueApexCharts);

Vue.use(VueCurrencyInput);

Vue.use(SlugInput);

Vue.use(VueTheMask);

Vue.use(Vuelidate);

Vue.use(VModal, { dynamic: true, injectModalsContainer: true });

Vue.config.productionTip = false;

Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router)
        }),
        new OfflineIntegration({
            // limit how many events will be localled saved. Defaults to 30.
            maxStoredEvents: 30
        })
    ],
    tracesSampleRate: 0.5
});

Vue.prototype.$axios = Axios;
Vuex.Store.prototype.$axios = Axios;

const token = auth.getToken();

if (token) {
    Vue.prototype.$axios.defaults.headers.common[
        'Authorization'
    ] = `Token ${token}`;
}

Vue.prototype.$axios.defaults.headers.common[
    'Timezone'
] = Intl.DateTimeFormat().resolvedOptions().timeZone;

ApiService.init();

Vue.use(require('vue-moment'));

Vue.use(VueCurrencyFilter, {
    ...defaultCurrencyConfig(),
    name: 'currency'
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
