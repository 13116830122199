<template>
    <modal
        name="order-export-modal"
        :adaptive="true"
        height="auto"
        width="500px"
    >
        <div
            class="w-full transform overflow-hidden rounded-lg bg-white shadow-xl transition-all"
        >
            <div class="flex flex-col p-4 sm:flex-row sm:justify-between">
                <div
                    class="group mb-1 cursor-pointer rounded border-2 border-gray-200 px-4 py-2 shadow-sm transition transition-all duration-300 hover:border-indigo-600 active:border-indigo-800 active:bg-indigo-50 sm:mb-0 sm:mr-2 sm:w-1/2"
                    @click="$emit('exportOrders', true);$modal.hide('order-export-modal')"
                >
                    <h3
                        class="flex items-center font-heading text-base font-bold text-gray-700"
                        >Expanded
                    </h3>
                    <p class="mb-2 text-sm text-gray-500"
                        >Each product within an order will have its own separate
                        row in the CSV file. This was previously the default
                        export format.
                    </p>
                </div>
                <div
                    class="group mt-1 cursor-pointer rounded border-2 border-gray-200 px-4 py-2 shadow-sm transition transition-all duration-300 hover:border-indigo-600 active:border-indigo-800 active:bg-indigo-50 sm:ml-2 sm:mt-0 sm:w-1/2"
                    @click="$emit('exportOrders', false);$modal.hide('order-export-modal')"
                >
                    <h3 class="font-heading text-base font-bold text-gray-700"
                        >Compact</h3
                    >
                    <p class="mb-2 text-sm text-gray-500"
                        >Each order will be summarized in a single row of the
                        CSV file. Information about individual products within
                        the order will be omitted</p
                    >
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    name: 'OrderExportModal'
};
</script>
