<template>
    <div class="p-4">
        <div class="mb-4 flex justify-center text-lg font-bold">
            <span class="my-auto cursor-pointer" @click="back">
                <base-back-icon />
            </span>
            <span class="flex flex-1 justify-center font-heading">{{
                isEditMode ? 'Edit zone' : 'Add new zone'
            }}</span>
        </div>
        <div v-if="!isEditMode">
          <span class="text-base font-bold font-heading">Delivery Zone Type</span>
          <p class="text-sm text-gray-700"
              >Select a delivery zone type between Driving distance, Polygon,
              Circle</p>
        </div>
        <div v-else>
          <span class="text-base font-bold font-heading">Driving distance</span>
          <p class="text-sm text-gray-700">
            Calculated using real time traffic and road network data from Google. The zone shape is an estimate of the covered area, and can change with road closures.</p
          >
        </div>
        <div v-if="!isEditMode && (isFeatureAvailable(FeaturesEnum.INTERNAL) || isFeatureAvailable(FeaturesEnum.ZONES) || isFeatureAvailable(FeaturesEnum.SAAS))" class="flex w-full justify-between">
            <div
                v-for="tab in tabs"
                :key="tab.key"
                :class="tabItemClass(tab.key)"
                @click="$emit('select-tab', tab.key)"
                class="group inline-flex cursor-pointer items-center border-b-2 border-transparent px-1 py-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
            >
                {{ tab.name }}</div
            >
        </div>
        <div class="mt-4">
            <base-input-field
                label="Name"
                id="name"
                v-model="formData.name"
                placeholder="e.g. Zone 1"
                :errors="$v.formData.name.$error"
                errorMessage="Please give this zone a name."
                class="mt-3"
            />
            <base-input-field
                v-if="showRadiusInput"
                label="Driving distance"
                suffix="miles"
                id="radius"
                v-model="formData.radius"
                placeholder="e.g. 2.4"
                :errors="$v.formData.radius.$error"
                :errorMessage="
                    $v.formData.radius.$error && !$v.formData.radius.required
                        ? 'Driving distance is required.'
                        : 'Radius value must be positive.'
                "
                class="mt-3"
                type="number"
            />

            <div class="mt-3">
                <label
                    for="zone-fee"
                    class="flex items-center text-sm font-medium leading-5 text-gray-700"
                    v-tooltip="'Customers will be charged this fee for delivery within this zone.'"
                    >Delivery Fee <base-question-icon class="ml-1 text-gray-700" /></label
                >
                <div class="relative mt-1 w-full rounded-md shadow-sm">
                    <div
                        class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                    >
                        <span class="text-gray-500 sm:text-sm sm:leading-5">
                            {{ currency.symbol }}
                        </span>
                    </div>
                    <currency-input
                        id="zone-fee"
                        v-model="formData.deliveryFee"
                        class="form-input block w-full pl-7 pr-12 sm:text-sm sm:leading-5"
                        placeholder="4.50"
                        :currency="null"
                        locale="en-GB"
                        :value-as-integer="true"
                        :allow-negative="false"
                        :class="{
                            'border-red-300': $v.formData.deliveryFee.$error
                        }"
                    ></currency-input>
                </div>
                <p
                    class="mt-2 text-xs text-red-600"
                    v-if="$v.formData.deliveryFee.$error"
                >
                    Delivery fee is required, enter 0 to not charge a fee.
                </p>
            </div>
            <div class="py-3 flex flex-col">
              <label
                  class="flex items-center text-sm font-medium leading-5 text-gray-700 pb-2"
                  v-tooltip="'Enable minimum spend requirements for this zone. Customers will need to spend at least this amount to place an order if their address falls within this zone. This value will override the store minimum spend requirement.'"
              >
                Minimum spend required
                <base-question-icon class="ml-1 text-gray-700" />
              </label>
              <base-small-toggle-switch
                  v-model="requiresMinSpend"
              />
            </div>
            <div v-auto-animate>
                <div class="mt-1" v-show="requiresMinSpend">
                    <label
                        for="zone-minDeliveryAmount"
                        class="flex items-center text-sm font-medium leading-5 text-gray-700"
                        v-tooltip="
                            'The amount customers will need to spend to place an order if their address falls within this zone. Calculated as the sum of items, before gratuity and delivery fees.'
                        "
                        >Minimum spend amount
                        <base-question-icon class="ml-1 text-gray-700"
                    /></label>
                    <div class="relative mt-1 w-full rounded-md shadow-sm">
                        <div
                            class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                        >
                            <span class="text-gray-500 sm:text-sm sm:leading-5">
                                {{ currency.symbol }}
                            </span>
                        </div>
                        <currency-input
                            id="zone-minDeliveryAmount"
                            v-model="formData.minDeliveryAmount"
                            class="form-input block w-full pl-7 pr-12 sm:text-sm sm:leading-5"
                            placeholder="12.50"
                            :currency="null"
                            locale="en-GB"
                            :value-as-integer="true"
                            :allow-negative="false"
                            :class="{
                                'border-red-300':
                                    $v.formData.minDeliveryAmount.$error
                            }"
                        ></currency-input>
                    </div>
                    <p
                        class="mt-2 text-xs text-red-600"
                        v-if="$v.formData.minDeliveryAmount.$error"
                    >
                        Minimum delivery is required.
                    </p>
                </div>
                <div v-if="isCountryValid && selectedTab === 'postcodeZones'">
                    <label
                        for="postCodes"
                        class="block text-sm font-medium leading-5 text-gray-700"
                        >Select postcodes</label
                    >
                    <multi-select
                        v-if="postcodes"
                        id="postCodes"
                        v-model="formData.postCodes"
                        placeholder="Postcodes"
                        :allow-empty="false"
                        :searchable="true"
                        :options="postcodes"
                        :multiple="true"
                        :close-on-select="true"
                        @select="addEditLayer"
                        @remove="remove"
                        @close="showAllPostcodes = false"
                    >
                        <template
                            v-if="!showAllPostcodes"
                            slot="selection"
                            slot-scope="{ values, remove }"
                        >
                            <div class="multiselect__tags-wrap">
                                <span
                                    v-for="(option, index) in values.slice(
                                        0,
                                        3
                                    )"
                                    :key="index"
                                    class="multiselect__tag"
                                >
                                    {{ option }}
                                    <span
                                        class="multiselect__tag-icon"
                                        @click="remove(option)"
                                    ></span>
                                </span>
                                <span
                                    v-if="values.length > 3"
                                    class="multiselect__tag cursor-pointer"
                                    @click="showAllPostcodes = true"
                                >
                                    +{{ values.length - 3 }} more
                                </span>
                            </div>
                        </template>
                    </multi-select>
                    <p
                        class="mt-2 text-xs text-red-600"
                        v-if="$v.formData.postCodes.$error"
                    >
                        Please select at least one postcode.
                    </p>
                </div>
            </div>
            <p class="mt-2 text-xs text-red-600" v-if="$v.coordinates.$error">
                Please draw a delivery zone before saving.</p
            >
            <div class="mt-4 flex w-full items-center justify-between">
                <span
                    v-if="isEditMode"
                    @click="deleteZone"
                    class="cursor-pointer rounded-md px-3 py-1 text-xs font-medium text-gray-500 hover:bg-red-100 hover:text-red-500"
                    >Delete zone</span
                >
                <base-button
                    @clicked="
                        isEditMode ? editDeliveryZone() : addDeliveryZone()
                    "
                    :buttonText="isEditMode ? 'Save' : 'Create zone'"
                    size="sm"
                />
            </div>
        </div>
    </div>
</template>

<script>
import {
    CircleMode,
    DragCircleMode,
    DirectMode,
    SimpleSelectMode
} from 'mapbox-gl-draw-circle';
import { mapGetters } from 'vuex';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { required, requiredIf } from 'vuelidate/lib/validators';
import { FeaturesEnum } from '@/enums';

export default {
    name: 'CreateEditDeliveryZone',
    props: {
        selectedTab: {
            type: String,
            required: true
        },
        formData: {
            type: Object,
            required: true
        },
        isEditMode: {
            type: Boolean,
            required: true
        },
        map: {
            type: Object,
            required: true
        },
        zones: {
            type: Object,
            required: true
        }
    },
    watch: {
        selectedTab() {
            this.drawMode();
        }
    },
    data() {
        return {
            draw: null,
            polygons: [],
            coordinates: [],
            circle: null,
            tabs: [
                { name: 'Driving distance', key: 'drivingDistanceZones' },
                { name: 'Polygon', key: 'polygonZones' },
                { name: 'Circle', key: 'circleZones' }
            ],
            postcodes: null,
            requiresMinSpend: false,
            FeaturesEnum,
            features: null,
            tempZoneId: 'tempZone',
            showAllPostcodes: false,
            validCountries: ['GB']
        };
    },
    validations: {
        formData: {
            deliveryFee: {
                required
            },
            name: {
                required
            },
            radius: {
                required: requiredIf(function () {
                    return this.selectedTab === 'drivingDistanceZones';
                }),
                positive(value) {
                    if (this.selectedTab === 'drivingDistanceZones') {
                        return !isNaN(value) && parseFloat(value) > 0;
                    }

                    return true;
                }
            },
            postCodes: {
                required: requiredIf(function () {
                    return this.selectedTab === 'postcodeZones';
                })
            },
            minDeliveryAmount: requiredIf(function () {
                return this.requiresMinSpend;
            })
        },
        coordinates: {
            required: requiredIf(function () {
                if (
                    this.selectedTab !== 'drivingDistanceZones' &&
                    this.selectedTab !== 'postcodeZones' &&
                    this.coordinates.length === 0
                ) {
                    return true;
                }
                return false;
            })
        }
    },
    computed: {
        ...mapGetters({
            currency: 'user/currencySettings',
            venue: 'venues/getVenue',
            isFeatureAvailable: 'user/isFeatureAvailable'
        }),
        showRadiusInput() {
            return this.selectedTab === 'drivingDistanceZones';
        },
        isCountryValid() {
            return this.validCountries.includes(this.venue.address.country);
        }
    },
    mounted() {
        if (this.map) {
            this.drawMode();
        }

        if (this.isEditMode) {
            this.coordinates = this.formData.polygon.coordinates.flat();

            const source = this.map.getSource(`${this.formData.id}`);

            if (source?._data?.features) {
                this.features = JSON.parse(
                    JSON.stringify(source._data.features)
                );
            }

            if(this.formData.minDeliveryAmount) {
              this.requiresMinSpend = true;
            }
        }

        if (this.isCountryValid) {
            this.tabs.push({
                name: 'Postcode',
                key: 'postcodeZones'
            });

            this.loadPostcodes();
        }
    },
    methods: {
        tabItemClass(tabKey) {
            return [
                'border-transparent text-grey-500',
                this.isTabSelected(tabKey)
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
            ];
        },
        isTabSelected(tabName) {
            return this.selectedTab === tabName;
        },
        drawMode() {
            this.removeDraw();

            if (
                this.selectedTab === 'drivingDistanceZones' ||
                this.selectedTab === 'postcodeZones'
            ) {
                return;
            }

            const modeConfig = {
                polygonZones: {
                    type: 'polygonZones',
                    defaultMode: 'draw_polygon'
                },
                circleZones: {
                    type: 'circleZones',
                    defaultMode: 'draw_circle',
                    modes: {
                        ...MapboxDraw.modes,
                        draw_circle: CircleMode,
                        direct_select: DirectMode,
                        simple_select: SimpleSelectMode,
                        drag_circle: DragCircleMode
                    }
                }
            };

            const config = modeConfig[this.selectedTab];

            if (config) {
                this.setupDrawing(config);
            }

            if (this.isEditMode) {
                const layer = this.map.getSource(`${this.formData.id}`);
                this.$emit('remove', this.formData.id);
                const draw = {
                    id: `${this.formData.id}`,
                    type: 'Feature',
                    properties: {},
                    geometry: {
                        type: 'Polygon',
                        coordinates: layer._data.geometry.coordinates
                    }
                };

                if (config.type === 'circleZones') {
                    draw.properties = {
                        isCircle: true,
                        center: this.formData.center.coordinates,
                        radius: this.formData.radius
                    };
                }

                this.draw.add(draw);
            }
        },
        setupDrawing(config) {
            const drawConfig = {
                defaultMode: this.isEditMode
                    ? 'simple_select'
                    : config.defaultMode,
                displayControlsDefault: false,
                controls: {
                    trash: true
                }
            };

            if (config.type === 'polygonZones') {
                drawConfig.controls.polygon = true;
            }

            if (config.type === 'circleZones') {
                drawConfig.userProperties = true;
                drawConfig.modes = config.modes;
            }

            this.draw = new MapboxDraw(drawConfig);
            this.map.addControl(this.draw, 'bottom-left');

            if (config.type === 'circleZones') {
                this.draw.changeMode(drawConfig.defaultMode, {
                    initialRadiusInKm: 2
                });
            }

            this.map.on('draw.create', this.drawHandler);
            this.map.on('draw.update', this.drawHandler);
        },
        drawHandler(event) {
            if (this.selectedTab === 'polygonZones') {
                this.polygons = [];
                const drawnPolygon = event.features[0].geometry;
                this.coordinates = drawnPolygon.coordinates[0];
            }

            if (this.selectedTab === 'circleZones') {
                this.polygons = [];
                this.coordinates = [];
                const drawnCircle = event.features[0].properties;
                this.circle = {
                    radius: drawnCircle.radiusInKm / 1.6093445, // miles
                    center: drawnCircle.center // [lon, lat]
                };

                for (const polygon of event.features[0].geometry
                    .coordinates[0]) {
                    this.coordinates.push(polygon);
                }
            }
        },
        editDeliveryZone() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return false;
            }

            if (
                this.selectedTab === 'polygonZones' ||
                this.selectedTab === 'postcodeZones'
            ) {
                this.formData.polygon.coordinates = [this.coordinates];
            }

            if (this.selectedTab === 'circleZones') {
                this.formData.polygon.coordinates = [this.coordinates];

                this.formData.center.coordinates = this.circle?.center
                    ? this.circle?.center
                    : this.formData.center.coordinates;
                this.formData.radius = this.circle?.radius
                    ? this.circle?.radius
                    : this.formData.radius;
            }

            const index = this.zones[this.selectedTab].findIndex(
                zone => zone.id === this.formData.id
            );

            if (~index) {
                this.zones[this.selectedTab][index] = {
                    ...this.zones[this.selectedTab][index],
                    ...this.formData
                };

                if (
                    this.selectedTab === 'drivingDistanceZones' ||
                    this.selectedTab === 'postcodeZones'
                ) {
                    this.$emit('remove', this.formData.id);
                }

                this.update();
                return;
            }

            this.back();
        },
        addDeliveryZone() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return false;
            }

            if (this.selectedTab === 'polygonZones') {
                this.addPolygonZone(this.coordinates);
                return;
            }

            if (this.selectedTab === 'circleZones') {
                this.addCircleZone({
                    circle: this.circle,
                    polygons: this.coordinates
                });
                return;
            }

            if (this.selectedTab === 'postcodeZones') {
                this.zones.postcodeZones.push({
                    name: this.formData.name,
                    radius: this.formData.radius || 0,
                    deliveryFee: this.formData.deliveryFee || 0,
                    minDeliveryAmount: this.formData.minDeliveryAmount,
                    postCodes: this.formData.postCodes,
                    polygon: {
                        type: 'MultiPolygon',
                        coordinates: [this.coordinates]
                    }
                });

                this.$emit('remove', this.tempZoneId);
                this.update();

                return;
            }

            this.zones.drivingDistanceZones.push({
                name: this.formData.name,
                radius: this.formData.radius || 0,
                deliveryFee: this.formData.deliveryFee || 0,
                minDeliveryAmount: this.requiresMinSpend
                    ? this.formData.minDeliveryAmount
                    : null
            });

            this.update();
        },
        addPolygonZone(polygonZone) {
            this.zones.polygonZones.push({
                name: this.formData.name,
                radius: this.formData.radius || 0,
                deliveryFee: this.formData.deliveryFee || 0,
                polygon: {
                    type: 'Polygon',
                    coordinates: [polygonZone]
                },
                minDeliveryAmount: this.formData.minDeliveryAmount
            });

            this.update();
        },
        addCircleZone(circleZone) {
            this.zones.circleZones.push({
                name: this.formData.name,
                radius: circleZone?.circle?.radius,
                deliveryFee: this.formData.deliveryFee || 0,
                minDeliveryAmount: this.formData.minDeliveryAmount,
                center: {
                    type: 'Point',
                    coordinates: circleZone?.circle?.center || 0
                },
                polygon: {
                    type: 'Polygon',
                    coordinates: [circleZone?.polygons] || []
                }
            });

            this.update();
        },
        back() {
            this.removeDraw();
            this.revertLayer();
            this.$emit('back');
        },
        update() {
            this.removeDraw();
            this.$emit('update');
        },
        removeDraw() {
            if (this.draw) {
                this.map.removeControl(this.draw);
                this.draw = null;
            }
        },
        revertLayer() {
            if (this.selectedTab === 'postcodeZones') {
                if (!this.isEditMode) {
                    this.$emit('remove', this.tempZoneId);
                } else {
                    const source = this.map.getSource(`${this.formData.id}`);
                    if (source && this.features) {
                        source.setData({
                            type: 'FeatureCollection',
                            features: JSON.parse(JSON.stringify(this.features))
                        });
                    }
                }

                return;
            }

            if (
                !this.isEditMode ||
                this.selectedTab === 'drivingDistanceZones'
            ) {
                return;
            }

            this.map.addLayer({
                id: `${this.formData.id}`,
                type: 'fill',
                source: {
                    type: 'geojson',
                    data: {
                        type: 'Feature',
                        geometry: {
                            type: 'Polygon',
                            coordinates: this.formData.polygon.coordinates
                        }
                    }
                },
                paint: {
                    'fill-color': 'black',
                    'fill-opacity': 0.25
                }
            });
        },
        deleteZone() {
            this.removeDraw();
            this.$emit('delete-zone', this.formData, this.selectedTab);
        },
        remove(postcode) {
            const sourceId = this.isEditMode
                ? this.formData.id
                : this.tempZoneId;
            const layer = this.map.getSource(`${sourceId}`);
            const data = layer._data;
            const updatedFeatures = data.features.filter(
                feature => feature.properties.postcode !== postcode
            );

            layer.setData({
                type: 'FeatureCollection',
                features: updatedFeatures
            });

            this.coordinates = updatedFeatures.map(
                feature => feature.geometry.coordinates[0]
            );
        },
        async addEditLayer(postcode) {
            try {
                const { data } = await this.$axios.get(
                    `/venues/postcode-to-polygon`,
                    {
                        params: {
                            postcode: postcode
                        }
                    }
                );

                const sourceId = this.isEditMode
                    ? this.formData.id
                    : this.tempZoneId;

                let source = this.map.getSource(`${sourceId}`);
                const newFeature = {
                    type: 'Feature',
                    geometry: {
                        type: 'Polygon',
                        coordinates: data
                    },
                    properties: {
                        postcode: postcode
                    }
                };

                if (!source) {
                    this.map.addSource(sourceId, {
                        type: 'geojson',
                        data: {
                            type: 'FeatureCollection',
                            features: [newFeature]
                        }
                    });

                    this.map.addLayer({
                        id: `${sourceId}`,
                        type: 'fill',
                        source: `${sourceId}`,
                        paint: {
                            'fill-color': 'darkgreen',
                            'fill-opacity': 0.25
                        }
                    });

                    this.map.addLayer({
                        id: `${sourceId}-outline`,
                        type: 'line',
                        source: `${sourceId}`,
                        layout: {},
                        paint: {
                            'line-color': 'darkgreen',
                            'line-width': 2
                        }
                    });
                } else {
                    const existingData = source._data;

                    existingData.features.push(newFeature);
                    source.setData({
                        type: 'FeatureCollection',
                        features: existingData.features
                    });
                }

                this.coordinates.push(...data);
            } catch (error) {
                throw new Error(`API ${error}`);
            }
        },
        async loadPostcodes() {
            const fileName = this.venue.address.country.toLowerCase();
            const { default: postcodes } = await import(
                /* webpackChunkName: "postcodes-[request]" */
                `@/postcodes/${fileName}.json`
            );

            this.postcodes = postcodes;
        }
    }
};
</script>
