<template>
    <div
        @click="isInteractive = true"
        class="relative h-full w-full"
        :class="{ 'cursor-pointer': !isInteractive }"
    >
        <MglMap
            :key="`mapbox-key-${isInteractive}`"
            :accessToken="accessToken"
            :mapStyle="mapStyle"
            :center="mapCenter"
            :zoom="15"
            class="print:hidden"
            :interactive="isInteractive"
        >
            <template v-if="locationHistory?.length > 0">
                <MglMarker
                    v-for="(location, index) in locationHistory"
                    :coordinates="location"
                    :key="index"
                >
                    <template slot="marker">
                        <div class="bg-white border-2 border-blue-700 text-blue-700 rounded-full w-6 h-6 flex justify-center items-center font-semibold" >
                            {{ index }}
                        </div>
                    </template>
                </MglMarker>
            </template>

            <MglMarker
                v-if="deliveryCoordinates"
                :coordinates="deliveryCoordinates"
                color="blue"
            >
                <template slot="marker">
                    <div
                        class="marker"
                        style="background-image: url('https://ucarecdn.com/9bec0344-c447-486f-8562-f288fd19721a/-/format/auto/');"
                    ></div>
                </template>
            </MglMarker>

            <MglMarker v-if="venueCoordinates" :coordinates="venueCoordinates">
                <template slot="marker">
                    <div
                        class="marker"
                        style="background-image: url('https://ucarecdn.com/d4fe2209-baf6-424d-be88-c417c241d1e2/-/format/auto/');"
                    ></div>
                </template>
            </MglMarker>

            <MglMarker
                v-if="lastPositionCoordinates"
                :coordinates="lastPositionCoordinates"
            >
                <template slot="marker">
                    <div
                        class="marker"
                        style="background-image: url('https://ucarecdn.com/bf38497b-dcc2-4f55-a332-cd3440f210ae/-/format/auto/');"
                    ></div>
                </template>
            </MglMarker>
        </MglMap>
        <div
            @click.stop="copyToClipboard(`${address.buildingNumber} ${address.street}, ${address.city}, ${address.postCode}, ${address.country}`)"
            v-tooltip="'Click to copy address'"
            class="fade-background-top absolute top-0 left-0 w-full px-4 py-3 pointer-events-auto">
          <div class="inline-block rounded bg-white w-auto px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm" v-if="address">
            {{ address.buildingNumber }}
            <span v-if="address.buildingNumber">
                ,
            </span>
            {{ address.street }}
            <br />
            {{ address.city }}
            <br />
            {{ address.postCode }}
          </div>
        </div>
        <div class="fade-background absolute flex flex-col xl:flex-row justify-between bottom-0 left-0 w-full px-4 py-3">
            <a @click.stop :href="mapsLink" target="_blank">
                <button
                    type="button"
                    class="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >View in google maps</button
                >
            </a>
            <div v-if="address.deliveryDistance || address.deliveryTime" class="rounded mt-1 xl:mt-0 inline-flex items-center bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 flex-none">
              <span v-if="address.deliveryDistance">{{ (address.deliveryDistance * 0.000621371).toFixed(1) }} mi</span>
              <span v-if="address.deliveryTime" class="ml-2">{{ (address.deliveryTime / 60).toFixed(1) }} mins</span>
            </div>
        </div>
        <div class="hidden h-full w-full print:block">
            <img
                :src="`${uploadCareProxyUrl}/https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-s(${deliveryCoordinates[0]},${deliveryCoordinates[1]})/${deliveryCoordinates[0]},${deliveryCoordinates[1]},15,0.00,0.00/1000x600@2x?access_token=${accessToken}`"
            />
        </div>
    </div>
</template>

<script>
import Mapbox from 'mapbox-gl';
import { MglMap, MglMarker } from 'vue-mapbox';

export default {
    name: 'OrderDeliveryAddressMap',
    props: {
        deliveryCoordinates: {
            type: Array,
            required: false
        },
        venueCoordinates: {
            type: Array,
            required: false
        },
        address: {
          type: Object,
          required: false
        },
        delivery: {
          type: [Object, Boolean],
          required: false
        }
    },
    components: {
        MglMap,
        MglMarker
    },
    data() {
        return {
            accessToken:
                'pk.eyJ1IjoiYmVubGFyY2V5c2siLCJhIjoiY2pycXZnOHp5MDRuMDQ0cHJ4ZWttMGxsYSJ9.MQY0Fnhodssbf7FMG0EiHw', // your access token. Needed if you using Mapbox maps
            mapStyle: 'mapbox://styles/benlarceysk/ck8enq76w032w1itcbgkkkrwc', // your map style
            isInteractive: false
        };
    },
    created() {
        // We need to set mapbox-gl library here in order to use it in template
        this.mapbox = Mapbox;
    },
    computed: {
        locationHistory() {
            return this.delivery?.locationHistory?.map(({ lat, long }) => [long, lat]) || [];
        },
        lastPositionCoordinates() {
            return this.locationHistory[this.locationHistory.length - 1] || false;
        },
        mapsLink() {
            return `https://www.google.com/maps/dir/?api=1&destination=${this.deliveryCoordinates[1]},${this.deliveryCoordinates[0]}`;
        },
        uploadCareProxyUrl() {
          return process.env.VUE_APP_UPLOADCARE_PROXY_URL;
        },
        mapCenter() {
            return this.lastPositionCoordinates ? this.lastPositionCoordinates : this.deliveryCoordinates;
        }
    },
    methods: {
      async copyToClipboard(address) {
        await navigator.clipboard.writeText(address);
        this.$notify({
          group: 'settings',
          title: 'Copied address to clipboard'
        });
      }
    }
};
</script>

<style>
a.mapboxgl-ctrl-logo {
    display: none;
}

.fade-background {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), transparent);
}

.fade-background-top {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.05), transparent);
}
</style>

<style scoped>
.marker {
    background-size: cover;
    width: 30px;
    height: 46px;
    cursor: pointer;
    transform: translate(-50%, -100%) translate(352px, 708px) rotateX(0deg) rotateZ(0deg);
}
</style>
