<template>
    <div>
        <div class="mb-2 text-sm font-medium leading-5 text-gray-700"
            >Select dates</div
        >
        <multi-select
            id="datesSelector"
            v-model="selectedDates"
            placeholder="Select Dates"
            :allow-empty="true"
            :searchable="false"
            :options="availableDates"
            :multiple="true"
            :close-on-select="false"
            :limit-text="limitText"
            :limit="7"
            @input="updateValue"
        />
    </div>
</template>

<script>
export default {
    name: 'DatesSelector',
    props: {
        dates: {
            type: Array,
            default: () => []
        },
        maxDates: {
            type: Number,
            default: 30
        }
    },
    data() {
        return {
            availableDates: [],
            selectedDates: this.dates
        };
    },
    mounted() {
        this.generateDates();
    },
    watch: {
        dates() {
            this.selectedDates = this.dates;
        }
    },
    methods: {
        generateDates() {
            for (let i = 0; i < this.maxDates; i++) {
                this.availableDates.push(
                    this.$moment().add(i, 'days').format('YYYY-MM-DD')
                );
            }
        },
        limitText(count) {
            return `and ${count} other dates`;
        },
        updateValue(newValue) {
            this.$emit('selected', newValue);
        }
    }
};
</script>
