<template>
    <div>
        <div>
            <div>
                <p class="text-gray-500 text-sm leading-5">
                    Let customers make choices about their item - for example,
                    picking a size, adding a topping or upgrading with sides and
                    drinks.
                </p>
            </div>

            <div
                class="flex flex-col mt-4"
                v-if="
                    product.modifierGroups && product.modifierGroups.length > 0
                "
            >
                <div class="py-2">
                    <div
                        class="inline-block align-middle w-full border border-gray-200 rounded overflow-hidden"
                    >
                        <table class="table-fixed w-full">
                            <thead>
                                <tr>
                                    <th
                                        class="px-4 py-3 w-2/5 text-left text-gray-500 truncate text-xs font-medium tracking-wider leading-4 bg-gray-50 border-b border-gray-200 uppercase"
                                    >
                                        Group
                                    </th>
                                    <th
                                        class="px-4 py-3 w-2/5 text-left text-gray-500 truncate text-xs font-medium tracking-wider leading-4 bg-gray-50 border-b border-gray-200 uppercase"
                                    >
                                        Options
                                    </th>
                                    <th
                                        class="px-4 py-3 w-1/5 bg-gray-50 border-b border-gray-200"
                                    >
                                    </th>
                                </tr>
                            </thead>
                            <ModifierGroupListContainer
                                lockAxis="y"
                                v-model="product.modifierGroups"
                                helperClass="z-1000 flex flex-row"
                                @input="updateGroupOrder"
                                :distance="15"
                            >
                                <ModifierGroupListItem
                                    v-for="(
                                        group, index
                                    ) in product.modifierGroups"
                                    :key="group.id"
                                    :group="group"
                                    :index="index"
                                >
                                </ModifierGroupListItem>
                            </ModifierGroupListContainer>
                        </table>
                    </div>
                </div>
            </div>

            <div class="mt-5">
                <p class="block text-gray-700 text-sm font-medium leading-5">
                    Add a modifier group
                </p>

                <div class="mt-3 bg-white shadow overflow-hidden sm:rounded-md">
                    <ul>
                        <li @click="emitGroup(null)">
                            <div
                                class="group block hover:bg-gray-50 focus:bg-gray-50 focus:outline-none cursor-pointer transition duration-150 ease-in-out"
                            >
                                <div
                                    class="flex items-center px-4 py-4 sm:px-6"
                                >
                                    <div
                                        class="flex-1 min-w-0 sm:flex sm:items-center sm:justify-between"
                                    >
                                        <div>
                                            <div
                                                class="text-gray-600 group-hover:text-indigo-600 truncate text-sm font-medium leading-5"
                                            >
                                                Create a new group
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex-shrink-0 ml-5">
                                        <svg
                                            class="w-5 h-5 text-gray-400"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                                clip-rule="evenodd"
                                            />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li
                            @click="emitExistingGroup"
                            class="border-t border-gray-200"
                        >
                            <div
                                class="group block hover:bg-gray-50 focus:bg-gray-50 focus:outline-none cursor-pointer transition duration-150 ease-in-out"
                            >
                                <div
                                    class="flex items-center px-4 py-4 sm:px-6"
                                >
                                    <div
                                        class="flex-1 min-w-0 sm:flex sm:items-center sm:justify-between"
                                    >
                                        <div>
                                            <div
                                                class="text-gray-600 group-hover:text-indigo-600 truncate text-sm font-medium leading-5"
                                            >
                                                Use an existing group
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex-shrink-0 ml-5">
                                        <svg
                                            class="w-5 h-5 text-gray-400"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                                clip-rule="evenodd"
                                            />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div
                class="mt-5 sm:flex sm:mt-6 sm:w-auto justify-between"
                :class="{ spinner: isLoading }"
            >
                <span class="flex rounded-md shadow-sm">
                    <button
                        @click="update"
                        :disabled="isLoading"
                        type="button"
                        class="inline-flex justify-center px-4 py-2 w-full text-white text-base font-medium leading-6 hover:bg-indigo-500 bg-indigo-600 border focus:border-indigo-700 border-transparent rounded-md focus:outline-none focus:shadow-outline-indigo shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    >
                        Save changes
                    </button>
                </span>
                <span
                    class="flex mt-3 w-full rounded-md shadow-sm sm:ml-3 sm:mt-0 sm:w-auto"
                >
                    <button
                        @click="close"
                        type="button"
                        class="inline-flex justify-center px-4 py-2 w-full hover:text-gray-500 text-gray-700 text-base font-medium leading-6 bg-white border focus:border-blue-300 border-gray-300 rounded-md focus:outline-none focus:shadow-outline shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    >
                        Close
                    </button>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import ModifierGroupListItem from './ModifierGroupListItem';
import ModifierGroupListContainer from './ModifierGroupListContainer';

export default {
    name: 'ModifierGroupList',
    props: {
        product: {
            type: Object,
            required: true
        }
    },
    components: {
        ModifierGroupListItem,
        ModifierGroupListContainer
    },
    data() {
        return {
            isLoading: false
        };
    },
    methods: {
        close() {
            this.$emit('close');
        },
        emitGroup(id) {
            this.$emit('edit-group', id);
        },
        emitExistingGroup() {
            this.$emit('existing-group');
        },
        async updateGroupOrder(sortedArray) {
            const indexedGroups = sortedArray.map((group, index) => {
                return { id: group.id, index };
            });

            this.isLoading = true;

            try {
                await this.$axios.put('/groups/sort', indexedGroups);
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
                this.refreshModifiers();
            }
        },
        update() {
            this.$emit('update');
        },
        refreshModifiers() {
            this.$emit('refresh-modifiers');
        }
    }
};
</script>

<style>
.z-1000 {
    z-index: 1000;
}
</style>
