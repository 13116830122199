<template>
    <div class="hidden lg:flex lg:flex-shrink-0">
        <div class="flex w-56 flex-col bg-sk-black rounded-xl mx-4 my-4">
            <div class="flex h-0 flex-1 flex-col overflow-y-auto pb-4 pt-4">
                <div class="flex flex-shrink-0 items-center" id="logo">
                    <img
                        class="pl-4 w-auto"
                        style="height: 26px;"
                        :src="$store.state.ui.logoWhiteUrl"
                        alt="Logo"
                    />
                </div>
                <SidebarContent :isOgav="isOgav" />
                <SidebarInformations />
            </div>
            <SidebarAccount />
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import SidebarContent from './SidebarContent.vue';
import SidebarInformations from './SidebarInformations.vue';
import SidebarAccount from './SidebarAccount.vue';

export default {
    components: {
        SidebarContent,
        SidebarInformations,
        SidebarAccount
    },
    props: {
        isOgav: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    computed: {
        ...mapGetters({ currentUser: 'user/currentUser' })
    }
};
</script>
