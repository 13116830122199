var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"focus:outline-none select-none block transition duration-150 ease-in-out hover:bg-gray-50 focus:bg-gray-50"},[_c('div',{staticClass:"px-1 py-1 sm:px-3 sm:py-3"},[_c('div',{staticClass:"flex flex-col items-center justify-between sm:flex-row"},[_c('div',{staticClass:"mb-2 flex flex-row items-center truncate text-sm font-medium leading-5 text-indigo-600 sm:mb-0"},[(_vm.FulfillmentMethodsEnum.isDeliveryMethod(_vm.order.fulfillmentMethod))?_c('DeliveryIcon',{attrs:{"color":"text-gray-500","size":"sm"}}):(_vm.FulfillmentMethodsEnum.isPickupMethod(_vm.order.fulfillmentMethod))?_c('PickupIcon',{attrs:{"color":"text-gray-500","size":"sm"}}):(_vm.order.fulfillmentMethod === 'InStore')?_c('InstoreIcon',{attrs:{"color":"text-gray-500","size":"sm"}}):_vm._e(),(_vm.order.code)?_c('span',{staticClass:"ml-2 font-mono font-bold"},[_vm._v(" "+_vm._s(_vm.order.code)+" ")]):_c('span',{staticClass:"ml-2 font-mono font-bold"},[_vm._v(" #"+_vm._s(_vm.order.id)+" ")])],1),_c('div',{staticClass:"flex flex-shrink-0 items-center sm:ml-2"},[_c('status-badge',{attrs:{"status":_vm.order.orderStatus,"posRetryCount":_vm.order.posRetryCount}}),(_vm.order.orderStatus === 'Failed')?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                                'Something went wrong sending to the POS'
                            ),expression:"\n                                'Something went wrong sending to the POS'\n                            "}],staticClass:"ml-1 text-red-600"},[_c('base-warning-icon')],1):_vm._e(),(
                                _vm.order.orderStatus === 'Retrying' ||
                                _vm.order.orderStatus === 'Syncing'
                            )?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Sending order to POS'),expression:"'Sending order to POS'"}],staticClass:"ml-1"},[_c('base-refresh-icon',{attrs:{"loading":true}})],1):_vm._e()],1)]),(
                        _vm.selectedVenuesCount !== 1 &&
                        _vm.venuesCount > 1 &&
                        _vm.order.venue &&
                        _vm.order.venue.name
                    )?_c('venue-name-tag',{staticClass:"-mb-2 mt-1 hidden sm:inline-block bg-gray-50",attrs:{"name":_vm.order.venue.adminName || _vm.order.venue.name,"truncate-length":25}}):_vm._e(),_c('div',{staticClass:"mt-1 hidden items-end sm:flex sm:justify-between"},[_c('div',{staticClass:"sm:flex"},[_c('div',{staticClass:"mr-6 flex flex-col text-xs leading-5 text-gray-500"},[_c('div',[_vm._v(" "+_vm._s(_vm.order.customer.firstName || 'Guest')+" "+_vm._s(_vm.order.customer.lastName ? `${_vm.order.customer.lastName.slice(0, 1)}.` : '')+" ")]),(_vm.order.table)?_c('span',{staticClass:"text-xs",class:{ 'font-bold': _vm.isActiveTable }},[_vm._v(_vm._s(_vm.order.table.name))]):_vm._e()])]),_c('div',{staticClass:"mt-2 flex items-center rounded-md px-1 text-xs leading-5 text-gray-500 hover:bg-gray-200 sm:mt-0",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('toggle-absolute-time')}}},[_c('span',[(_vm.order.preOrderDateTime)?_c('time',{key:_vm.timeKey,attrs:{"datetime":_vm.order.preOrderDateTime}},[_vm._v(" "+_vm._s(_vm.preorderTime)+" ")]):_c('time',{key:_vm.timeKey,attrs:{"datetime":_vm.order.created_at}},[_vm._v(" "+_vm._s(_vm.orderTime)+" ")])])])])],1),(_vm.order.deliveries && _vm.order.deliveries.length)?_c('div',{staticClass:"relative text-gray-500 py-2 h-8 px-2 hidden sm:block pointer-events-none"},[(_vm.order.deliveries[0].deliveryProvider === 'orkestro')?_c('OrkestroChatWidget',{key:_vm.order.deliveries[0].deliveryProviderId,staticClass:"pointer-events-none absolute bottom-1 right-5 transform scale-75",attrs:{"delivery-provider-id":_vm.order.deliveries[0].deliveryProviderId,"location":"live-orders"}}):_vm._e()],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }