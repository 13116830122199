<template>
    <modal
        name="add-product-modal"
        width="600px"
        height="auto"
        :clickToClose="true"
        @before-open="beforeOpen"
        @before-close="beforeClose"
        :adaptive="true"
    >
        <div
            class="h-full transform overflow-auto rounded-lg bg-white shadow-xl transition-all sm:w-full"
        >
            <div>
                <div
                    v-if="step === 1"
                    class="flex flex-col-reverse justify-between p-6 sm:flex-row"
                >
                    <div
                        class="group w-1/2 mr-2 cursor-pointer active:border-indigo-800 active:bg-indigo-50 rounded border-2 border-gray-200 px-4 py-2 shadow-sm transition transition-all duration-300 hover:border-indigo-600"
                        @click="step = 2;"
                    >
                      <h3 class="font-heading text-base font-bold text-gray-700 flex items-center" v-tooltip="'Product details copied include: name, description, price, image, video, sale price, availability, allergens and dietary tags.'">Duplicate product <base-question-icon class="ml-1" /> </h3>
                      <p class="mb-2 text-sm text-gray-500">Copy values from an existing product to get started faster </p>
                    </div>
                    <div
                        class="group w-1/2 ml-2 cursor-pointer active:border-indigo-800 active:bg-indigo-50 rounded border-2 border-gray-200 px-4 py-2 shadow-sm transition transition-all duration-300 hover:border-indigo-600"
                        @click="step = 3;"
                    >
                      <h3 class="font-heading text-base font-bold text-gray-700">New product</h3>
                      <p class="mb-2 text-sm text-gray-500">Create a new product from scratch</p>
                    </div>
<!--                    <button-->
<!--                        @click="step = 2"-->
<!--                        type="button"-->
<!--                        class="focus:outline-none my-1 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium leading-6 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline sm:w-2/6 sm:leading-5"-->
<!--                    >-->
<!--                        Copy Existing Product-->
<!--                    </button>-->
<!--                    <button-->
<!--                        @click="step = 3"-->
<!--                        type="button"-->
<!--                        class="focus:outline-none my-1 inline-flex w-full justify-center rounded-md bg-indigo-600 px-4 py-2 text-sm font-medium leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo sm:w-2/6 sm:leading-5"-->
<!--                    >-->
<!--                        New Product-->
<!--                    </button>-->
                </div>
                <div v-if="step === 2" class="p-4">
                    <h1 class="text-sm sm:text-base font-heading"
                        >Select a product to copy values from:</h1
                    >
                    <div class="mt-2 flex w-full flex-col rounded-md">
                        <select
                            v-if="products"
                            id="product"
                            v-model="selectedProduct"
                            class="form-input block w-full transition duration-150 ease-in-out sm:w-4/6 sm:text-sm sm:leading-5"
                            :class="{
                                'border-red-300': $v.selectedProduct.$error
                            }"
                            @change="resetModifierGroupIds"
                        >
                            <option
                                v-for="product in products"
                                :value="product"
                                :key="product.id"
                                >{{ product.name }}
                            </option>
                        </select>

                        <div
                            v-if="selectedProduct.modifierGroups?.length > 0"
                            class="mt-2 text-sm sm:text-base"
                        >
                          <span class="font-heading text-sm mb-1">
                            Modifier groups:
                          </span>
                          <p class="text-gray-500 text-sm mb-2">
                            Select the modifier groups you'd like attached to the new product.
                          </p>
                            <div
                                v-for="modifierGroup in selectedProduct.modifierGroups"
                                :key="modifierGroup.id"
                                class="px-2 py-1"
                            >
                                <base-checkbox
                                    :id="modifierGroup.id"
                                    :label="modifierGroup.name"
                                    :value="modifierGroup.id"
                                    v-model="modifierGroupIds"
                                />
                            </div>
                        </div>
                    </div>
                    <span
                        class="mt-1 py-2 text-xs text-red-600"
                        v-if="$v.selectedProduct.$error"
                    >
                        Selecting a product to duplicate is required, or you can
                        skip this step.
                    </span>
                    <div
                        class="mt-8 flex w-full flex-col-reverse justify-between sm:flex-row"
                    >
                      <base-button size="sm" button-text="Back" @clicked="step = 1;" />

                      <base-button size="sm" button-text="Copy and continue" @clicked="handleNextStep(true)" />

                    </div>
                </div>

                <div v-if="step === 3" class="border-b border-gray-200">
                    <nav class="-mb-px flex px-4">
                        <span
                            @click="tab = 0"
                            :class="{
                                'border-indigo-500 text-indigo-600': tab === 0
                            }"
                            class="focus:outline-none cursor-pointer whitespace-nowrap border-b-2 border-transparent py-4 text-sm font-medium leading-5 text-gray-500 hover:border-gray-300 hover:text-gray-700 focus:border-gray-300 focus:text-gray-700"
                        >
                            Details
                        </span>

                        <span
                            @click="tab = 1"
                            :class="{
                                'border-indigo-500 text-indigo-600': tab === 1
                            }"
                            class="focus:outline-none ml-8 cursor-pointer whitespace-nowrap border-b-2 border-transparent py-4 text-sm font-medium leading-5 text-gray-500 hover:border-gray-300 hover:text-gray-700 focus:border-gray-300 focus:text-gray-700"
                        >
                            Tags & Allergens
                        </span>

                        <span
                            @click="tab = 2"
                            :class="{
                                'border-indigo-500 text-indigo-600': tab === 2
                            }"
                            class="focus:outline-none ml-8 cursor-pointer whitespace-nowrap border-b-2 border-transparent py-4 text-sm font-medium leading-5 text-gray-500 focus:border-gray-300 focus:border-indigo-700 focus:text-indigo-800"
                        >
                            Modifiers
                        </span>
                    </nav>
                    <div class="px-4 pb-4 pt-5 sm:p-6">
                        <div v-if="tab === 0" class="text-left sm:mt-2">
                            <div class="mt-3">
                                <base-input-field
                                    label="Name"
                                    id="name"
                                    v-model="product.name"
                                    :errors="$v.product.name.$error"
                                    placeholder="e.g. Croquettes"
                                />
                            </div>
                            <div class="mt-3">
                                <base-text-area
                                    label="Description"
                                    v-model="product.description"
                                    :rows="3"
                                    id="description"
                                />
                            </div>
                            <div class="mt-3">
                                <label
                                    for="price"
                                    class="block text-sm font-medium leading-5 text-gray-700"
                                    >Price</label
                                >
                                <div class="relative mt-1 rounded-md shadow-sm">
                                    <div
                                        class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                                    >
                                        <span
                                            class="text-gray-500 sm:text-sm sm:leading-5"
                                        >
                                            {{ currency.symbol }}
                                        </span>
                                    </div>
                                    <currency-input
                                        id="price"
                                        v-model="product.price"
                                        class="form-input block w-full pl-7 pr-12 sm:text-sm sm:leading-5"
                                        :class="{
                                            'border-red-300':
                                                $v.product.price.$error
                                        }"
                                        placeholder="0.00"
                                        :currency="null"
                                        locale="en-GB"
                                        :value-as-integer="true"
                                        :allow-negative="false"
                                    ></currency-input>
                                    <div
                                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                                    >
                                        <span
                                            class="text-gray-500 sm:text-sm sm:leading-5"
                                        >
                                            {{ currency.ISO }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                          <div
                              v-if="
                                  isFeatureAvailable(FeaturesEnum.CENTEGRA) ||
                                  isFeatureAvailable(FeaturesEnum.INTERNAL)
                              "
                              class="mt-3 w-full"
                          >
                            <label
                                for="tax"
                                class="block text-sm font-medium leading-5 text-gray-700"
                            >
                              Tax Rate
                            </label>
                            <div class="relative mt-1 rounded-md shadow-sm">
                              <currency-input
                                  id="tax"
                                  v-model="product.deliveryTax"
                                  class="form-input block w-full pl-7 pr-12 sm:text-sm sm:leading-5"
                                  placeholder="0.00%"
                                  :currency="null"
                                  locale="en-GB"
                                  :value-as-integer="true"
                                  :allow-negative="false"
                              />
                            </div>
                          </div>
                            <div
                                v-if="
                                    isFeatureAvailable(FeaturesEnum.INTERNAL) ||
                                    isFeatureAvailable(FeaturesEnum.COMTREX) ||
                                    isFeatureAvailable(FeaturesEnum.CENTEGRA) ||
                                    isFeatureAvailable(FeaturesEnum.TISSL)
                                "
                                class="mt-3 w-full"
                            >
                                <base-input-field
                                    label="POS ID"
                                    id="posId"
                                    tooltip="The unique identifier for this product in your POS system database"
                                    v-model="product.posId"
                                    placeholder="e.g. 1234"
                                    :errors="$v.product.posId.$error"
                                    error-message="This field is required"
                                />
                            </div>
                            <div class="mt-4">
                                <label
                                    for="image"
                                    class="block text-sm font-medium leading-5 text-gray-700"
                                >
                                    Image
                                    <span class="text-gray-400"
                                        >(optional)</span
                                    >
                                </label>
                                <div>
                                    <uploadcare-inline
                                        :ctxName="ctxName"
                                        inputAcceptTypes="image/*"
                                        tabs="local, instagram, dropbox, url, gdrive"
                                        @success="onImageSuccess"
                                    >
                                        <div
                                            v-if="product.image"
                                            class="mt-2 flex justify-center rounded-md border border-dashed border-gray-300 px-6 pb-6 pt-5"
                                        >
                                          <div
                                              class="relative h-24 w-full max-w-md rounded-sm bg-cover bg-center"
                                              :style="
                                                  'background-image: url(' +
                                                  product.image +
                                                  ');'
                                              "
                                          >
                                          </div>
                                        </div>
                                    </uploadcare-inline>
                                </div>
                            </div>
                        </div>
                        <!-- Tags -->
                        <div v-if="tab === 1" class="pb-4 text-left sm:mt-2">
                            <span
                                class="block text-sm font-medium leading-5 text-gray-700"
                            >
                                Contain
                            </span>
                            <div class="mt-3">
                                <div
                                    class="grid grid-cols-3"
                                    v-if="product.tags"
                                >
                                    <div
                                        v-for="tag in productTagsFiltered(
                                            100,
                                            1000
                                        )"
                                        :key="tag.value"
                                    >
                                        <div
                                            class="mb-3 flex flex-row items-center"
                                        >
                                            <base-small-toggle-switch
                                                :value="
                                                    product.tags.includes(
                                                        tag.value
                                                    )
                                                "
                                                @input="toggleProductTag(tag)"
                                            />

                                            <span
                                                class="ml-3 text-sm font-semibold text-gray-700"
                                                >{{ tag.label }}</span
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <span
                                class="block text-sm font-medium leading-5 text-gray-700"
                            >
                                May contain
                            </span>
                            <div class="mt-3">
                                <div
                                    class="grid grid-cols-3"
                                    v-if="product.tags"
                                >
                                    <div
                                        v-for="tag in productTagsFiltered(
                                            200100,
                                            2001000
                                        )"
                                        :key="tag.value"
                                    >
                                        <div
                                            class="mb-3 flex flex-row items-center"
                                        >
                                            <base-small-toggle-switch
                                                :value="
                                                    product.tags.includes(
                                                        tag.value
                                                    )
                                                "
                                                @input="toggleProductTag(tag)"
                                            />

                                            <span
                                                class="ml-3 text-sm font-semibold text-gray-700"
                                                >{{ tag.label }}</span
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <span
                                class="block text-sm font-medium leading-5 text-gray-700"
                                >Tags</span
                            >
                            <div class="mt-3">
                                <div
                                    class="grid grid-cols-3"
                                    v-if="product.tags"
                                >
                                    <div
                                        v-for="tag in productTagsFiltered(
                                            0,
                                            99
                                        )"
                                        :key="tag.value"
                                    >
                                        <div
                                            class="mb-3 flex flex-row items-center"
                                        >
                                            <base-small-toggle-switch
                                                :value="
                                                    product.tags.includes(
                                                        tag.value
                                                    )
                                                "
                                                @input="toggleProductTag(tag)"
                                            />
                                            <span
                                                class="ml-3 text-sm font-semibold text-gray-700"
                                                >{{ tag.label }}</span
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="tab === 2" class="pb-4 text-left sm:pt-2">
                            <div class="">
                                <p class="text-sm leading-5 text-gray-500">
                                    You will be able to create and edit
                                    modifiers after the product is created.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-if="step === 3"
                class="mb-4 mt-5 flex-row-reverse justify-between sm:mt-6 sm:flex sm:w-auto"
                :class="{ spinner: isLoading }"
            >
                <span class="mr-3 flex w-full rounded-md shadow-sm md:w-36">
                    <button
                        v-if="isImageReady"
                        @click="createProduct()"
                        :disabled="isLoading"
                        type="button"
                        class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo sm:text-sm sm:leading-5"
                    >
                        Create Product
                    </button>
                    <button
                        v-else
                        class="inline-flex w-full justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium leading-6 text-white shadow-sm sm:text-sm sm:leading-5"
                    >
                        File uploading
                    </button>
                </span>
                <span
                    class="mt-3 flex w-full rounded-md shadow-sm sm:ml-3 sm:mt-0 sm:w-auto"
                >
                    <button
                        @click="$modal.hide('add-product-modal')"
                        type="button"
                        class="focus:outline-none inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium leading-6 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline sm:text-sm sm:leading-5"
                    >
                        Close
                    </button>
                </span>
            </div>
        </div>
    </modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import UploadcareInline from '@/components/widgets/UploadcareInline';
import { tags } from '@/helpers/productTags';
import { FeaturesEnum } from '@/enums';

export default {
    name: 'AddProductModal',
    components: {
        UploadcareInline
    },
    props: {
        posProvider: {
            type: String,
            default: ''
        },
        categories: {
            type: Array
        }
    },
    validations: {
        product: {
            name: {
                required
            },
            price: {
                required
            },
            posId: {
                required(value) {
                    return ['comtrex', 'centegra'].includes(this.posProvider)
                        ? !!(value && value.length)
                        : true;
                }
            }
        },
        selectedProduct: {
            required
        }
    },
    data() {
        return {
            FeaturesEnum,
            showModifiersInfo: false,
            isLoading: false,
            tab: 0,
            product: {
                name: '',
                description: '',
                price: null,
                image: '',
                posId: null,
                onSale: false,
                compareAtPrice: null,
                isAvailable: true,
                categoryId: '',
                tags: [],
                video: null,
                index: null,
                deliveryTax: null
            },
            categoryId: '',
            isImageReady: true,
            ctxName: '',
            step: 1,
            selectedProduct: {},
            modifierGroupIds: []
        };
    },
    computed: {
        ...mapGetters({
            currency: 'user/currencySettings',
            isFeatureAvailable: 'user/isFeatureAvailable',
            user: 'user/currentUser'
        }),
        tags() {
            const mayContain = JSON.parse(JSON.stringify(tags)).filter(
                item => (item.value = 200000 + item.value)
            );

            return [...tags, ...mayContain];
        },
        products() {
            return this.categories.flatMap(category => category.products);
        }
    },
    methods: {
        onImageSuccess(event) {
            const regexp = /^(video)\//gi;

            if (regexp.test(event.mimeType)) {
                this.product.video = event.cdnUrl;
                this.requestVideoEncoding(event.uuid);
            } else {
                this.product.image = event.cdnUrl;
            }
        },
        onImageError(event) {
            throw new Error('Error: ', event);
        },
        imageProgress(uploadInfo) {
            if (uploadInfo.state === 'uploading') {
                this.isImageReady = false;
            }

            if (uploadInfo.state === 'ready') {
                this.isImageReady = true;
            }
        },
        requestVideoEncoding(uuid) {
            const params = {
                uuid
            };
            this.$axios
                .post('/video/encode', params)
                .then(() => {})
                .catch(e => {
                    this.$modal.show('api-error-modal');
                    throw new Error(`API ${e}`);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        beforeOpen(event) {
            this.tab = 0;
            this.product.name = '';
            this.product.description = '';
            this.product.price = null;
            this.product.image = '';
            this.product.posId = null;
            this.product.categoryId = event.params.categoryId;
            this.product.tags = [];
            this.product.deliveryTax = null;
            this.product.index = event.params.productsCount + 1;
            window.addEventListener('beforeunload', this.preventNav);
            this.ctxName = `product_image_${Date.now()}`;
        },
        beforeClose() {
            this.isImageReady = true;
            this.progress = 0;
            this.step = 1;
            this.selectedProduct = {};
            window.removeEventListener('beforeunload', this.preventNav);
        },
        toggleProductTag(tag) {
            if (this.product.tags.includes(tag.value)) {
                this.product.tags = this.product.tags.filter(
                    el => el !== tag.value
                );
            } else {
                this.product.tags.push(tag.value);
            }
        },
        productTagsFiltered(minVal, maxVal) {
            return this.tags.filter(tag => {
                return tag.value >= minVal && tag.value <= maxVal;
            });
        },
        async createProduct() {
            this.$v.product.$touch();

            if (this.$v.product.$invalid) {
                this.errors = 'ERROR';
                return;
            }

            this.isLoading = true;
            const product = this.product;
            product.menuId = this.$route.params.id;

            try {
                const { data } = await this.$axios.post('/products', product);

                await this.updateModifiers(data.id);
                this.$modal.hide('add-product-modal');
                this.$emit('product-added');
                if (window.analytics) {
                    window.analytics.track('Product Created', {
                        name: product.name,
                        price: product.price,
                        image: product.image,
                        tags: !!product.tags.length,
                        accountId: this.user.accounts[0].id,
                        context: {
                            groupId: this.user.accounts[0].id
                        }
                    });
                }

                this.$v.product.$reset();

                this.$notify({
                    group: 'settings',
                    duration: 2000,
                    text: `Product created`
                });
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        },
        handleNextStep(shouldCopy) {
            if (shouldCopy) {
                this.$v.selectedProduct.$touch();

                if (this.$v.selectedProduct.$invalid) {
                    return;
                }

                const {
                    name,
                    description,
                    price,
                    image,
                    posId,
                    onSale,
                    compareAtPrice,
                    isAvailable,
                    tags,
                    video,
                    deliveryTax
                } = this.selectedProduct;

                this.product = {
                    categoryId: this.product.categoryId,
                    name,
                    description,
                    price,
                    image,
                    posId,
                    onSale,
                    compareAtPrice,
                    isAvailable,
                    tags: tags || [],
                    video,
                    deliveryTax,
                    index: this.product.index
                };
            } else {
                this.selectedProduct = {};
                this.modifierGroupIds = [];
            }

            this.step = 3;
            this.$v.selectedProduct.$reset();
        },
        resetModifierGroupIds() {
            this.modifierGroupIds = [];
        },
        async updateModifiers(id) {
            if (this.modifierGroupIds?.length > 0) {
                try {
                    for (const modifierGroup of this.modifierGroupIds) {
                        await this.$axios.put(
                            `/products/${id}/groups/${modifierGroup}`
                        );
                    }
                } catch (error) {
                    throw new Error(`API ${error}`);
                }
            }
        }
    }
};
</script>

<style scoped>
progress {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    width: 100%;
    border-radius: 8px;
    margin-bottom: 0.75rem;
    color: #273238;
}
</style>
