<template>
    <div id="businessName" class="relative z-50 mb-4 w-full rounded-sm">
        <multi-select
            v-model="selectedCompany"
            deselect-label="Can't remove this value"
            :options="companies"
            track-by="title"
            label="name"
            id="businessNameInput"
            placeholder="Search for your company"
            :showLabels="false"
            :clear-on-select="false"
            :loading="isLoading"
            :allow-empty="true"
            class="animate-list software-select border-1 cursor-text rounded text-sm shadow-sm transition"
            :class="{ 'software-select-complete': selectedCompany }"
            :searchable="true"
            :showNoResults="!!showNoResults"
            :preserveSearch="true"
            :internal-search="false"
            @select="selectedPay"
            @search-change="searchInput"
        >
            <template slot="singleLabel" slot-scope="{ option }">
                <span class="text-sm text-gray-600">{{ option.title }}</span>
                <span class="text-sm text-gray-600">
                    ({{ option.company_number }})
                </span>
            </template>
            <template slot="option" slot-scope="props">
                <div class="group">
                    <span class="pl-6 text-sm text-gray-600">
                        {{ props.option.title }}
                    </span>
                    -
                    <span class="text-sm text-gray-700">
                        {{ props.option.company_number }}
                    </span>
                </div>
            </template>
            <template slot="placeholder">
                <div class="flex items-center">
                    <div class="inline-block px-3 text-gray-500">
                        <svg
                            viewBox="0 0 16 16"
                            role="presentation"
                            aria-hidden="true"
                            focusable="false"
                            class="h-3 w-3"
                            style="display: block; fill: currentColor"
                        >
                            <path
                                d="m2.5 7c0-2.5 2-4.5 4.5-4.5s4.5 2 4.5 4.5-2 4.5-4.5 4.5-4.5-2-4.5-4.5m13.1 6.9-2.8-2.9c.7-1.1 1.2-2.5 1.2-4 0-3.9-3.1-7-7-7s-7 3.1-7 7 3.1 7 7 7c1.5 0 2.9-.5 4-1.2l2.9 2.8c.2.3.5.4.9.4.3 0 .6-.1.8-.4.5-.5.5-1.2 0-1.7"
                            ></path>
                        </svg>
                    </div>
                    <span class="text-sm font-normal text-gray-600">
                        Search for your company
                    </span>
                </div>
            </template>
            <template slot="noOptions">
                <div class="flex w-full flex-wrap px-3 py-3">
                    <span class="text-sm font-normal text-gray-500">
                        Please enter 3 or more characters to search
                    </span>
                </div>
            </template>
            <template slot="noResult">
                <div class="flex w-full flex-wrap px-3 py-3">
                    <span class="text-sm text-gray-600">
                        Sorry, we can't find that company.<br />
                        Use the company name or number registered with companies
                        house
                    </span>
                </div>
            </template>
        </multi-select>
    </div>
</template>

<script>
import qs from 'qs';

export default {
    name: 'CompanyNumberInput',
    props: {
        value: {
            type: Object,
            required: true
        }
    },
    computed: {
        showNoResults() {
            if (this.searchQueryLength > 2) {
                return 2;
            } else {
                return false;
            }
        }
    },
    created() {
        if (this.value && this.value.title) {
            this.selectedCompany = this.value;
        }
    },
    data() {
        return {
            companies: [],
            selectedCompany: null,
            isLoading: false,
            searchQueryLength: null,
            timeout: null
        };
    },
    methods: {
        searchInput(searchQuery) {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.searchCompanies(searchQuery);
            }, 300);
        },
        async searchCompanies(searchQuery) {
            this.searchQueryLength = searchQuery.length;

            if (searchQuery.length > 2) {
                this.isLoading = true;

                try {
                    const params = qs.stringify({
                        q: searchQuery,
                        items_per_page: '10'
                    });
                    const url = `${process.env.VUE_APP_COMPANIES_HOUSE_URL}/search/companies?${params}`;
                    const response = await fetch(url, {
                        method: 'GET',
                        headers: new Headers({
                            Authorization: `Basic ${btoa(
                                `${process.env.VUE_APP_COMPANIES_HOUSE_USERNAME}:`
                            )}`
                        })
                    });

                    const data = await response.json();
                    this.companies = data.items;

                    return data;
                } catch (error) {
                    throw new Error(error);
                } finally {
                    this.isLoading = false;
                }
            }
        },
        clear() {
            this.selectedCompany = null;
            this.selectedPay();
        },
        selectedPay(value) {
            this.selectedCompany = value;
            this.$emit('input', this.selectedCompany);
        }
    }
};
</script>

<style>
#businessName .multiselect__tags {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
    border-color: rgb(209, 213, 219) !important;
}
#businessNameInput:focus {
    outline: none;
    box-shadow: none;
}

#businessName .multiselect__option--highlight,
#businessName .multiselect__option--selected.multiselect__option--highlight {
    background-color: #f2f2f2;
    color: inherit;
}
</style>

<style scoped>
.platforms-list {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    overflow-y: scroll;
    max-width: 100%;
}
.multiselect__tags {
    min-height: 50px;
    display: flex;
    align-items: center;
    padding: 0 10px;
}
.multiselect__single {
    margin-bottom: 0;
}
.multiselect__option {
    padding: 16px 12px;
}
.multiselect__select {
    display: none;
}
.multiselect__placeholder {
    margin-bottom: 0;
}
.multiselect__option--selected {
    background: #fff;
}
.multiselect__input {
    margin-bottom: 0;
}

.multiselect__spinner {
    height: 58px;
    width: 58px;
}
.multiselect__spinner:after,
.multiselect__spinner:before {
    color: #1c3d5a;
}
.multiselect__content-wrapper {
    border: 2px solid #172d48;
    border-top: none;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}
.platform-item {
    transition: all 0.5s ease;
}
.platform-selected {
    border-color: #172d48;
    border-width: 2px !important;
    outline: none;
}
</style>
