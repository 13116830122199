<template>
    <svg
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 50 50"
        class="flex-shrink-0"
        :class="[color, sizeClass]"
        stroke="currentColor"
    >
        <title>In-store Order</title>

        <path
            d="M 10.585938 11 L 0.38085938 21.205078 A 1.0001 1.0001 0 0 0 0.18945312 21.396484 L 0 21.585938 L 0 21.832031 A 1.0001 1.0001 0 0 0 0 22.158203 L 0 28 L 3 28 L 3 50 L 9 50 L 9 28 L 11 28 L 11 43 L 17 43 L 17 28 L 33 28 L 33 43 L 39 43 L 39 28 L 41 28 L 41 50 L 47 50 L 47 28 L 50 28 L 50 22.167969 A 1.0001 1.0001 0 0 0 50 21.841797 L 50 21.585938 L 49.806641 21.392578 A 1.0001 1.0001 0 0 0 49.623047 21.207031 A 1.0001 1.0001 0 0 0 49.617188 21.203125 L 39.414062 11 L 39 11 L 10.585938 11 z M 11.414062 13 L 38.585938 13 L 46.585938 21 L 3.4140625 21 L 11.414062 13 z M 2 23 L 48 23 L 48 26 L 46.167969 26 A 1.0001 1.0001 0 0 0 45.841797 26 L 42.154297 26 A 1.0001 1.0001 0 0 0 41.984375 25.986328 A 1.0001 1.0001 0 0 0 41.839844 26 L 38.167969 26 A 1.0001 1.0001 0 0 0 37.841797 26 L 34.154297 26 A 1.0001 1.0001 0 0 0 33.984375 25.986328 A 1.0001 1.0001 0 0 0 33.839844 26 L 16.167969 26 A 1.0001 1.0001 0 0 0 15.841797 26 L 12.154297 26 A 1.0001 1.0001 0 0 0 11.984375 25.986328 A 1.0001 1.0001 0 0 0 11.839844 26 L 8.1679688 26 A 1.0001 1.0001 0 0 0 7.8417969 26 L 4.1542969 26 A 1.0001 1.0001 0 0 0 3.984375 25.986328 A 1.0001 1.0001 0 0 0 3.8398438 26 L 2 26 L 2 23 z M 5 28 L 7 28 L 7 48 L 5 48 L 5 28 z M 13 28 L 15 28 L 15 41 L 13 41 L 13 28 z M 35 28 L 37 28 L 37 41 L 35 41 L 35 28 z M 43 28 L 45 28 L 45 48 L 43 48 L 43 28 z"
        />
    </svg>
</template>

<script>
export default {
    name: 'InstoreIcon',
    props: ['color', 'size'],
    computed: {
        sizeClass() {
            if (this.size === 'lg') {
                return 'w-7 h-7';
            } else if (this.size === 'sm') {
                return 'w-5 h-5';
            }
            return 'w-6 h-6';
        }
    }
};
</script>
