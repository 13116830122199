<template>
    <div> </div>
</template>

<script>
export default {
    name: 'ThemeableFrontendSkeleton',
    props: {
        name: {
            type: String,
            required: false
        },
        logo: {
            type: String,
            required: false
        }
    }
};
</script>
