<template>
    <span>
        <animated-number
            v-if="isFinite(value)"
            :value="value"
            :duration="duration"
            :formatValue="price ? formatToPrice : regular"
            :round="price ? null : 1"
        />
        <span v-else>{{ value }}</span>
    </span>
</template>
<script>
import AnimatedNumber from 'animated-number-vue';

export default {
    components: {
        AnimatedNumber
    },
    props: {
        value: {
            required: true,
            default: 0,
            type: [String, Number]
        },
        duration: {
            required: false,
            default: 300,
            type: Number
        },
        price: {
            required: false,
            default: false,
            type: Boolean
        },
        isAbbreviate: {
            required: false,
            default: false,
            type: Boolean
        }
    },
    data() {
        return {
            THOUSAND: 1000,
            MILLION: 1000000
        };
    },
    methods: {
        formatToPrice(value) {
            if (this.isAbbreviate) {
                if (value >= this.MILLION) {
                    return `${this.$options.filters.currency(
                        value / this.MILLION
                    )}M`;
                } else if (value >= this.THOUSAND) {
                    return `${this.$options.filters.currency(
                        value / this.THOUSAND
                    )}K`;
                }
            }

            return this.$options.filters.currency(value);
        },
        regular(value) {
            return value;
        }
    }
};
</script>
