<template>
    <div>
        <!--<span class="pl-2 block mb-3 text-gray-400 font-bold uppercase text-xs tracking-wide">Revenue</span>-->
        <div class="mb-4">
            <div>
                <BasePageTitle title="Delivery Revenue" />
            </div>
            <div class="mt-1 flex flex-col sm:mt-2 sm:flex-row sm:flex-wrap">
                <PrintButton />
                <ExportButton @clicked="showExportCsvModal" />
            </div>
        </div>

        <DateSettings
            :byPreOrderDate="byPreOrderDate"
            @update-report="updateReport"
            @fetch-report-by-pre-order-date="fetchReportByPreOrderDate"
        />

        <div class="grid grid-cols-6 gap-4">
            <div class="col-span-3 mb-4 rounded-sm bg-white shadow">
                <div class="px-4 py-2">
                    <!--                    <h3 class="text-sm leading-6 font-medium text-gray-600">-->
                    <!--                        Orders by delivery fee-->
                    <!--                    </h3>-->
                    <apex-chart
                        v-if="!isLoading"
                        width="100%"
                        height="337px"
                        type="bar"
                        :options="chartOptions"
                        :series="series"
                        class="font-sans"
                    ></apex-chart>
                    <div v-else style="height: 337px" class="spinner"> </div>
                </div>
            </div>
            <div
                class="col-span-3 mb-4 overflow-hidden rounded-sm bg-white shadow"
            >
                <!--                    <p class="mt-1 max-w-2xl text-sm leading-5 text-gray-500">-->
                <!--                        &lt;!&ndash;Personal details and application.&ndash;&gt;-->
                <!--                    </p>-->

                <div
                    class="w-full"
                    style="height: 400px"
                    v-if="!isLoading && venue && geoJson"
                >
                    <DeliveryHeatmap :venue="venue" :geoJson="geoJson" />
                </div>
                <div v-else class="w-full spinner" style="height: 400px"> </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PrintButton from '@/components/helpers/PrintButton';
import ExportButton from '@/components/helpers/ExportButton';
import DateSettings from '@/components/helpers/DateSettings';
import DeliveryHeatmap from '@/components/widgets/DeliveryHeatmap.vue';

export default {
    name: 'DeliveryReport',
    props: {
        selectedVenues: {
            type: Array,
            required: true
        }
    },
    components: {
        PrintButton,
        DateSettings,
        ExportButton,
        DeliveryHeatmap
    },
    data() {
        return {
            isLoading: false,
            dateRange: {
                startDate: null,
                endDate: null
            },
            geoJson: null,
            chartOptions: {
                noData: {
                    text: 'No data yet',
                    align: 'center',
                    verticalAlign: 'middle',
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: undefined,
                        fontSize: '15px',
                        fontWeight: 'bold'
                    }
                },
                title: {
                    text: 'Delivery Fees',
                    align: 'left',
                    margin: 10,
                    offsetX: 0,
                    offsetY: 0,
                    floating: false,
                    style: {
                        fontSize: '15px',
                        fontWeight: 'bold',
                        color: '#263238'
                    }
                },
                theme: {
                    palette: 'palette10'
                },
                // plotOptions: {
                //     bar: {
                //         columnWidth: '93%'
                //     }
                // },
                chart: {
                    id: 'vuechart-example-delivery',
                    toolbar: false
                },
                colors: ['rgba(78, 205, 196, 1)'],
                stroke: {
                    width: 4,
                    colors: ['rgba(78, 205, 196, 1)']
                },
                xaxis: {
                    type: 'numeric',
                    lines: {
                        show: false
                    },
                    labels: {
                        formatter: value =>
                            this.$options.filters.currency(
                                parseInt(value) / 100
                            )
                    }
                },
                yaxis: {
                    lines: {
                        show: false
                    },
                    labels: {
                        formatter: value =>
                            this.$options.filters.currency(
                                parseInt(value) / 100
                            )
                    }
                },
                dataLabels: {
                    enabled: false,
                    offsetY: -20
                },
                tooltip: {
                    enabled: true
                },
                dropShadow: {
                    enabled: true,
                    top: 0,
                    left: 0,
                    blur: 3,
                    opacity: 0.1
                }
            },
            series: [
                {
                    name: 'Orders',
                    data: []
                }
            ],
            reportData: [],
            csvTitle: 'Delivery Revenue',
            byPreOrderDate: false,
            TRANSACTIONAL_TYPE: 'transactional',
            OPERATIONAL_TYPE: 'operational',
            venue: {}
        };
    },
    methods: {
        updateReport(val) {
            this.dateRange = val;
            this.fetchReportByPreOrderDate(this.byPreOrderDate);
        },
        fetchReport(type = this.TRANSACTIONAL_TYPE) {
            this.isLoading = true;
            const params = {
                venueId: this.venue,
                from: this.dateRange.startDate,
                to: this.dateRange.endDate,
                type
            };
            this.$axios
                .get('/reports/delivery-fees', {
                    params
                })
                .then(res => {
                    this.series = [
                        {
                            data: res.data
                        }
                    ];

                    this.reportData = res.data;
                })
                .catch(e => {
                    throw new Error(`API ${e}`);
                })
                .finally(() => {
                    this.isLoading = false;
                });

            // this.$axios
            //     .get( process.env.VUE_APP_API_URL + '/reports/delivery-geojson', { params: params } )
            //     .then(res => {
            //         console.log(res)
            //     })
            //     .catch(e => {
            //         throw new Error(`API ${e}`)
            //     })
            //     .finally(() => {
            //         this.isLoading = false;
            //     });
        },
        fetchGeoJson(type = this.TRANSACTIONAL_TYPE) {
            this.isLoading = true;
            const params = {
                venueId: this.venue,
                from: this.dateRange.startDate,
                to: this.dateRange.endDate,
                type
            };
            this.$axios
                .get('/reports/delivery-geojson', { params })
                .then(res => {
                    // this.geoJson = res.data;
                    this.geoJson = {
                        type: 'geojson',
                        data: res.data
                    };
                })
                .catch(e => {
                    throw new Error(`API ${e}`);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        showExportModal() {
            this.$modal.show('export-modal', { resourceName: null });
        },
        formatCurrency(value) {
            return this.$options.filters.currency(parseInt(value) / 100);
        },
        fetchReportByPreOrderDate(value) {
            this.byPreOrderDate = value;

            const type = this.byPreOrderDate
                ? this.OPERATIONAL_TYPE
                : this.TRANSACTIONAL_TYPE;

            this.fetchReport(type);
            this.fetchGeoJson(type);
        },
        showExportCsvModal() {
            this.$modal.show('export-csv-modal', {
                header: this.csvTitle,
                csvData: this.csvData
            });
        }
    },
    created() {
        this.venue = this.selectedVenues[0];
        this.dateRange = this.getDateRange || this.dateRange;

        this.fetchReport(this.TRANSACTIONAL_TYPE);
        this.fetchGeoJson(this.TRANSACTIONAL_TYPE);
    },
    computed: {
        ...mapGetters({
            getDateRange: 'report/getDateRange'
        }),
        csvData() {
            const csvReportData = this.reportData.map(item => ({
                ...item
            }));

            csvReportData.forEach(item => {
                item.x = this.formatCurrency(item.x);
                item.y = this.formatCurrency(item.y);
            });

            return csvReportData;
        }
    },
    watch: {
        selectedVenues() {
            this.fetchReportByPreOrderDate(false);
        }
    }
};
</script>

<style>
.apexcharts-xaxistooltip,
.apexcharts-gridlines-horizontal {
    display: none;
}

a.mapboxgl-ctrl-logo {
    display: none;
}
</style>
