<template>
    <div
        v-click-outside="onClickOutside"
        class="relative inline-block mb-3 text-left md:mb-0"
    >
        <div class="relative">
            <span class="inline-block rounded-sm shadow-sm w-full sm:w-auto">
                <button
                    @click="dropdownOpen = !dropdownOpen"
                    type="button"
                    class="inline-flex justify-center px-4 py-2 w-full hover:text-gray-500 text-gray-700 active:text-gray-800 text-sm font-medium leading-5 active:bg-gray-50 bg-white border focus:border-blue-300 border-gray-300 rounded-md focus:outline-none focus:shadow-outline-blue transition duration-150 ease-in-out"
                    :class="{
                        'border-gray-600': this.ascending
                    }"
                >
                    Sort by
                    <svg
                        class="-mr-1 ml-2 w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                    >
                        <path
                            fill-rule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clip-rule="evenodd"
                        />
                    </svg>
                </button>
            </span>
        </div>
        <transition
            enter-active-class="transition ease-out duration-100"
            enter-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
        >
            <div
                v-if="dropdownOpen"
                x-transition:enter="transition ease-out duration-100"
                x-transition:enter-start="transform opacity-0 scale-95"
                x-transition:enter-end="transform opacity-100 scale-100"
                x-transition:leave="transition ease-in duration-75"
                x-transition:leave-start="transform opacity-100 scale-100"
                x-transition:leave-end="transform opacity-0 scale-95"
                class="absolute z-50 right-0 mt-2 w-40 rounded-md shadow-lg origin-top-right"
            >
                <div class="bg-white rounded-md shadow-xs cursor-pointer">
                    <div class="py-1">
                        <div
                            @click="toggleSort()"
                            class="group flex items-center justify-between px-4 py-2 text-gray-700 hover:text-gray-900 focus:text-gray-900 text-sm leading-5 hover:bg-gray-100 focus:bg-gray-100 focus:outline-none"
                        >
                            <span>Created at</span>
                            <base-arrow-up-icon class="inline-block w-4 h-4 transition-transform duration-300 ease-in-out transform" :class="{'rotate-180': !ascending}" />
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import vClickOutside from 'v-click-outside';
import BaseArrowUpIcon from "@/components/base/icons/BaseArrowUpIcon";

export default {
    name: 'SortBySelector',
    components: {
        BaseArrowUpIcon
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    data() {
        return {
            dropdownOpen: false,
            ascending: false,
        };
    },
    methods: {
        onClickOutside() {
            this.dropdownOpen = false;
        },
        toggleSort() {
            this.ascending = !this.ascending;
            this.$emit('toggleSort', this.ascending);
        }
    }
};
</script>
