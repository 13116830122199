<template>
    <div>
        <div class="md:flex md:items-center md:justify-between">
            <div class="min-w-0 flex-1">
                <router-link
                    :to="{ name: 'editVenue' }"
                    class="mb-2 flex items-center text-sm text-gray-500 inline-flex"
                >
                    <base-back-icon />
                    <span>Settings</span>
                </router-link>
                <base-page-title title="Loyalty Settings" />
            </div>

            <BaseSpinner :isLoading="isLoading" />
        </div>

        <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-1">
                <div class="sm:pl-0 sm:pr-5">
                    <h3 class="text-base font-semibold leading-6 text-gray-800">
                        Details
                    </h3>
                    <p class="mt-2 text-sm text-gray-500">
                        Connect to Piggy.
                        <br /><br />If you don't have a Piggy account yet, you
                        can learn how to
                        <a
                            class="text-indigo-600"
                            href="https://piggy.eu/"
                            target="_blank"
                        >
                            create one here
                        </a>
                    </p>
                </div>
            </div>
            <div class="mt-5 md:col-span-2 md:mt-0">
                <div
                    v-if="!isLoading"
                    class="w-full bg-white text-left shadow sm:rounded-md"
                >
                    <div class="p-4">
                        <span
                            v-if="isPiggyConnected"
                            class="font-medium text-green-600"
                        >
                            Connected to Piggy
                        </span>

                        <div class="space-y-6 bg-white">
                            <div class="flex justify-center">
                                <div
                                    v-if="!isPiggyConnected"
                                    class="col-span-3 sm:col-span-2"
                                >
                                    <button
                                        type="button"
                                        class="focus:outline-none inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                                        @click="openPiggyModal"
                                    >
                                        Connect to Piggy
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="grid grid-cols-1 gap-6 p-4 md:grid-cols-3"
                        v-if="isPiggyConnected"
                    >
                        <div class="sm:col-span-6">
                            <label
                                for="listShops"
                                class="block text-sm font-medium leading-5 text-gray-700"
                            >
                                Shops
                            </label>
                            <div>
                                <select
                                    id="shop"
                                    name="shop"
                                    class="focus:outline-none mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    @change="selectShop($event.target.value)"
                                >
                                    <option
                                        v-for="(shop, index) in shops"
                                        :value="shop.uuid"
                                        :key="shop.uuid"
                                        :selected="index === 0"
                                    >
                                        {{ shop.name }}
                                    </option>
                                </select>
                            </div>
                            <toggle-switch
                                label="Enable piggy"
                                class="my-4"
                                v-model="piggySettings.enabled"
                            />
                            <!--                            <toggle-switch-->
                            <!--                                label="Stage Transactions"-->
                            <!--                                class="my-4"-->
                            <!--                                v-model="piggySettings.stagedTransactions"-->
                            <!--                            />-->
                            <toggle-switch
                                label="Show rewards"
                                class="my-4"
                                v-model="piggySettings.enabledShowRewards"
                            />
                            <toggle-switch
                                label="Include delivery costs"
                                class="my-4"
                                v-model="piggySettings.includeDeliveryCosts"
                            />
                            <toggle-switch
                                label="Include service charges"
                                class="my-4"
                                v-model="piggySettings.includeServiceCharges"
                            />
                            <toggle-switch
                                label="Show inline rewards in basket"
                                class="my-4"
                                v-model="
                                    piggySettings.enabledShowInlineRewardsOnBasket
                                "
                            />
                            <base-input-field
                                id="credit-unit-name"
                                label="Credit unit name"
                                v-model="piggySettings.creditUnitName"
                                tooltip="If the unit is not set or does not exist, then the default unit is used."
                            />
                            <toggle-switch
                                label="Enable loyalty opt-in"
                                class="my-4"
                                v-model="
                                    piggySettings.loyaltyOptIn.enabledLoyalty
                                "
                            />
                            <toggle-switch
                                label="Loyalty pre-select"
                                class="my-4"
                                v-model="piggySettings.loyaltyOptIn.preSelected"
                                :class="{
                                    'select-none opacity-50': !piggySettings
                                        .loyaltyOptIn.enabledLoyalty
                                }"
                                :is-disabled="
                                    !piggySettings.loyaltyOptIn.enabledLoyalty
                                "
                            />
                            <base-input-field
                                id="loyalty-label"
                                v-model="piggySettings.loyaltyOptIn.label"
                                label="Loyalty label"
                                class="my-4"
                                :class="{
                                    'select-none opacity-50': !piggySettings
                                        .loyaltyOptIn.enabledLoyalty
                                }"
                                :disabled="
                                    !piggySettings.loyaltyOptIn.enabledLoyalty
                                "
                                :errors="
                                    $v.piggySettings.loyaltyOptIn.label.$error
                                "
                                error-message="This field is required"
                            />
                            <base-input-field
                                id="email-calculate"
                                label="Default credit calculation email"
                                v-model="piggySettings.defaultEmailForCalculate"
                                tooltip="This email address will be used to calculate points if the user doesn't have a Piggy account or has provided an incorrect email."
                                :errors="
                                    $v.piggySettings.defaultEmailForCalculate
                                        .$error
                                "
                                error-message="Please enter a valid email address"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <BaseSpacerWithRuler />

        <div
            v-if="isPiggyConnected"
            class="my-6 ml-4 flex flex-shrink-0 justify-end"
        >
            <span
                class="inline-flex rounded-md shadow-sm"
                @click="updateSettings"
            >
                <button
                    type="button"
                    class="focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                    :disabled="isLoading"
                >
                    Save
                </button>
            </span>
        </div>

        <div
            v-if="isPiggyConnected && isFeatureAvailable(FeaturesEnum.INTERNAL)"
            class="my-6 ml-4 flex flex-shrink-0 justify-end"
        >
            <span
                class="inline-flex rounded-md shadow-sm"
                @click="disconnectConnection"
            >
                <button
                    type="button"
                    class="focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-red-500 focus:border-red-700 focus:shadow-outline-indigo active:bg-red-700"
                >
                    Disconnect Auth
                </button>
            </span>
        </div>

        <div
            v-if="isPiggyConnected && isFeatureAvailable(FeaturesEnum.INTERNAL)"
            class="my-6 ml-4 flex flex-shrink-0 justify-end"
        >
              <span
                  class="inline-flex rounded-md shadow-sm"
                  @click="onboardAttributes"
              >
                  <button
                      type="button"
                      class="focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-red-500 focus:border-red-700 focus:shadow-outline-indigo active:bg-red-700"
                  >
                      Create reward attributes
                  </button>
              </span>
        </div>

        <piggy-connect-modal @close="checkIsPiggyConnected" />
    </div>
</template>

<script>
import { email, required, requiredIf } from 'vuelidate/lib/validators';
import ToggleSwitch from '@/components/formComponents/ToggleSwitch';
import PiggyConnectModal from '@/components/modals/PiggyConnectModal';
import { FeaturesEnum } from '@/enums';
import { mapGetters } from 'vuex';

export default {
    name: 'PiggySettings',
    metaInfo: {
        title: 'Piggy Settings'
    },
    props: {
        venueId: {
            type: Number,
            required: true
        },
        venue: {
            type: Object,
            required: true
        }
    },
    components: { PiggyConnectModal, ToggleSwitch },
    validations: {
        piggySettings: {
            loyaltyOptIn: {
                label: {
                    required: requiredIf(function () {
                        return this.piggySettings.loyaltyOptIn.enabledLoyalty;
                    })
                }
            },
            defaultEmailForCalculate: {
                required,
                email
            }
        }
    },
    data() {
        const defaultPiggySettings = {
            enabled: false,
            stagedTransactions: false,
            enabledShowRewards: false,
            includeDeliveryCosts: false,
            includeServiceCharges: false,
            enabledShowInlineRewardsOnBasket: false,
            loyaltyOptIn: {
                enabledLoyalty: true,
                preSelected: false,
                label: ''
            },
            piggyShopId: '',
            creditUnitName: '',
            defaultEmailForCalculate: ''
        };

        return {
            isLoading: false,
            isPiggyConnected: false,
            shops: [],
            piggySettings: { ...defaultPiggySettings },
            defaultPiggySettings,
            FeaturesEnum,
            selectedShop: null
        };
    },
    async mounted() {
        this.isLoading = true;

        await this.checkIsPiggyConnected();

        if (this.isPiggyConnected) {
            await this.getSettings();
            await this.getListShops();
        }

        this.isLoading = false;
    },
    watch: {
        'piggySettings.loyaltyOptIn.enabledLoyalty': {
            handler(value) {
                if (!value) {
                    this.piggySettings.loyaltyOptIn.preSelected = false;
                    this.piggySettings.loyaltyOptIn.label = '';
                    this.$v.piggySettings.loyaltyOptIn.label.$reset();
                }
            }
        }
    },
    computed: {
        ...mapGetters({
            isFeatureAvailable: 'user/isFeatureAvailable'
        })
    },
    methods: {
        openPiggyModal() {
            this.$modal.show('piggy-connect-modal', {
                callback: success => {
                    if (success) {
                        this.getListShops();
                    }
                }
            });
        },
        async getListShops() {
            try {
                const { data } = await this.$axios.get('/piggy/list-shops');

                this.shops = data.data;
                this.piggySettings.piggyShopId = this.shops[0].uuid;
            } catch (error) {
                throw new Error(`API ${error}`);
            }
        },
        async checkIsPiggyConnected() {
            try {
                const { data } = await this.$axios.get('/account/check', {
                    params: {
                        columnName: 'piggy'
                    }
                });

                this.isPiggyConnected = !data ? false : true;
            } catch (error) {
                throw new Error(`API ${error}`);
            }
        },
        async getSettings() {
            try {
                const { data } = await this.$axios.get('/piggy/settings', {
                    params: { venueId: this.venueId }
                });

                if (!Object.keys(data).length) {
                    this.piggySettings = { ...this.defaultPiggySettings };

                    return;
                }

                this.piggySettings = data;
            } catch (error) {
                throw new Error(`API ${error}`);
            }
        },
        async updateSettings() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return false;
            }

            const payload = {
                ...this.piggySettings,
                venueId: this.venueId
            };

            try {
                await this.$axios.put('/piggy/settings', payload);

                this.$notify({
                    group: 'settings',
                    title: 'Settings saved'
                });

                this.$v.$reset();
            } catch (error) {
                throw new Error(`API ${error}`);
            }
        },
        selectShop(value) {
            this.piggySettings.piggyShopId = value;
        },
        async disconnectConnection() {
            try {
                await this.$axios.post('/piggy/disconnect', {
                    venueId: this.venueId
                });
                this.$notify({
                    group: 'settings',
                    duration: 1000,
                    text: 'Loyalty disconnected'
                });
                window.location.reload();
            } catch (error) {
                throw new Error(`API ${error}`);
            }
        },
        async onboardAttributes() {
            try {
                await this.$axios.post('/piggy/onboard', {
                    venueId: this.venueId
                });
                this.$notify({
                    group: 'settings',
                    duration: 1000,
                    text: 'Reward attributes created'
                });
                window.location.reload();
            } catch (error) {
                throw new Error(`API ${error}`);
            }
        }
    }
};
</script>
