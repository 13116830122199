<template>
    <transition name="fade">
        <div
            v-if="isOpened"
            class="fixed inset-0 z-50 overflow-hidden"
            aria-labelledby="slide-over-title"
            role="dialog"
            aria-modal="true"
        >
            <div class="absolute inset-0 overflow-hidden">
                <div
                    class="absolute inset-0 bg-gray-500 bg-opacity-75 blur-sm transition-opacity"
                    aria-hidden="true"
                >
                </div>

                <div
                    class="sliding-sidebar fixed inset-y-0 right-0 flex max-w-full pl-10 transition-all duration-500"
                >
                    <div class="relative w-screen" :class="`max-w-${size}`">
                        <div
                            class="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:mt-4 sm:-ml-10 sm:pr-4"
                        >
                            <button
                                @click="close"
                                class="focus:outline-none rounded-md text-gray-300 hover:text-white focus:ring-2 focus:ring-white"
                            >
                                <span class="sr-only">Close panel</span>
                                <base-close-icon />
                            </button>
                        </div>

                        <div
                            v-click-outside="onClickOutside"
                            style="max-height: 97vh;"
                            class="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl sm:my-4 sm:mr-4 sm:rounded-lg"
                        >
                            <div
                                class="flex items-center justify-between px-4 sm:px-6"
                            >
                                <h2
                                    class="font-heading text-lg font-medium text-gray-900"
                                    id="slide-over-title"
                                >
                                    {{ title }}
                                </h2>
                                <admin-id-tag v-if="id" :id="id" />
                            </div>
                            <div
                                class="relative mb-24 mt-4 flex-1 px-4 sm:mt-6 sm:px-6"
                            >
                                <slot />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import vClickOutside from 'v-click-outside';
import AdminIdTag from '@/components/helpers/AdminIdTag';

export default {
    name: 'SlidingSidebar',
    components: { AdminIdTag },
    directives: {
        clickOutside: vClickOutside.directive
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            required: false
        },
        id: {
            type: [String, Number],
            required: false
        },
        size: {
            type: String,
            required: false,
            default: 'md'
        }
    },
    computed: {
        isOpened: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    watch: {
        isOpened(value) {
            if (!value) {
                window.removeEventListener('keyup', this.onEscapeKeyUp);

                return;
            }
            this.$emit('sidebar-opened');
            window.addEventListener('keyup', this.onEscapeKeyUp);
        }
    },
    methods: {
        onEscapeKeyUp(event) {
            if (event.which === 27) {
                this.$emit('on-escape-key-up');
            }
        },
        onClickOutside() {
            this.$emit('click-outside');
        },
        close() {
            this.isOpened = false;
        }
    }
};
</script>

<style src="@/assets/css/sliding-sidebar.css" scoped />
