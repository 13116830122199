const state = {
    venues: [],
    archivedVenues: [],
    venue: {}
};

const mutations = {
    SET_VENUES(state, payload) {
        state.venues = payload;
    },
    SET_ARCHIVED_VENUES(state, payload) {
        state.archivedVenues = payload;
    },
    SET_VENUE(state, payload) {
        state.venue = payload;
    },
    ARCHIVE_VENUE(state, venueId) {
        const archivedVenue = state.venues.find(venue => venue.id === venueId);

        if (!archivedVenue) {
            return;
        }

        archivedVenue.archived = true;
        state.archivedVenues.push(archivedVenue);

        state.venues = state.venues.filter(c => c.id !== venueId);
    },
    RESTORE_VENUE(state, venueId) {
        const restoredVenue = state.archivedVenues.find(
            venue => venue.id === venueId
        );

        if (!restoredVenue) {
            return;
        }

        restoredVenue.archived = false;
        state.venues.push(restoredVenue);

        state.archivedVenues = state.archivedVenues.filter(
            c => c.id !== venueId
        );
    },
    UPDATE_VENUE(state, venue) {
        const index = state.venues.findIndex(v => v.id === venue.id);

        if (index !== -1) {
            state.venues[index] = { ...state.venues[index], ...venue };
        }

        state.venue = { ...state.venue, ...venue };
    }
};

const getters = {
    getVenues: state => state.venues,
    getArchivedVenues: state => state.archivedVenues,
    getVenueCoordinates: state => [state.venue.address.lat, state.venue.address.lng],
    getVenue: state => state.venue
};

const actions = {
    async fetchSingle({ commit }, { slug, params }) {
        const { data } = await this.$axios.get(`/venues/${slug}/private`, {
            params
        });

        commit('SET_VENUE', data.venue);
    },
    async fetch({ commit }, params = {}) {
        const { data } = await this.$axios.get('/venues', { params });

        if (params.archived) {
            commit('SET_ARCHIVED_VENUES', data);
            return;
        }

        commit('SET_VENUES', data);
    },
    async archive({ state, commit }, venueId) {
        await this.$axios.delete(`/venues/${venueId}`);

        if (state.venues.length > 0) {
            commit('ARCHIVE_VENUE', venueId);
        }
    },
    async restore({ state, commit }, venueId) {
        await this.$axios.patch(`/venues/${venueId}/restore`);

        if (state.archivedVenues.length > 0) {
            commit('RESTORE_VENUE', venueId);
        }
    },
    async update({ commit }, venue) {
        const { data } = await this.$axios.put(`/venues/${venue.id}`, venue);

        commit('UPDATE_VENUE', data);
    }
};

export default {
    namespaced: true,
    getters,
    mutations,
    state,
    actions
};
