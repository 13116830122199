<template>
    <div
        class="mt-4"
        :class="{
            'cursor-not-allowed opacity-50': !acceptsPreOrders
        }"
    >
        <div :key="`slot-edit-${slotEditKey}`" class="grid grid-cols-6 gap-6">
            <div
                class="col-span-6 sm:col-span-4"
                v-for="(day, index) in 7"
                :key="`${day}+${index}+PickupTimes`"
            >
                <BaseSpacerWithRuler v-if="index !== 0" />

                <label
                    :for="'hours-' + index"
                    class="block text-sm font-medium leading-5 text-gray-700"
                >
                    {{ hoursDict[index] }}
                </label>
                <div
                    v-for="(
                        singleInterval, intervalIndex
                    ) in editablePickupSlots"
                    :key="`${singleInterval.day}++${intervalIndex}+PickupTimes`"
                >
                    <div
                        v-if="
                            singleInterval.day === index &&
                            !closedArray[singleInterval.day]
                        "
                    >
                        <div class="inline-flex">
                            <vue-timepicker
                                manual-input
                                :minute-interval="15"
                                :id="'hours-' + intervalIndex"
                                :disabled="!singleInterval.active"
                                v-model="singleInterval.openTime"
                                :class="{
                                    'opacity-25': !singleInterval.active
                                }"
                            >
                            </vue-timepicker>
                            <span class="px-4 py-2 text-gray-600"> to </span>
                            <vue-timepicker
                                manual-input
                                :minute-interval="15"
                                :disabled="!singleInterval.active"
                                :class="{
                                    'opacity-25': !singleInterval.active
                                }"
                                v-model="singleInterval.closeTime"
                                :hour-range="[
                                    [
                                        singleInterval.openTime.substring(0, 2),
                                        24
                                    ]
                                ]"
                                :input-class="
                                    singleInterval.closeTime ===
                                        END_OF_THE_DAY || END_OF_THE_DAY + ':59'
                                        ? 'skip-error-style'
                                        : ''
                                "
                            >
                            </vue-timepicker>

                            <button
                                v-if="
                                    oneDayIntervalsNumber(
                                        index,
                                        editablePickupSlots
                                    ) > 1
                                "
                                type="button"
                                @click="
                                    deleteTimeInterval(
                                        intervalIndex,
                                        singleInterval.day,
                                        editablePickupSlots
                                    )
                                "
                                class="ml-3"
                                :class="{
                                    'opacity-25': !singleInterval.active
                                }"
                            >
                                <span
                                    class="transition-duration-100 cursor-pointer text-red-400 transition hover:text-red-700"
                                >
                                    <svg
                                        class="h-5 w-5"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                        ></path>
                                    </svg>
                                </span>
                            </button>
                        </div>
                        <div class="mb-3">
                            <div
                                @click="toggleShowOptions(singleInterval)"
                                class="my-2 flex w-full cursor-pointer select-none items-center justify-between"
                            >
                                <span
                                    class="text-sm font-medium leading-5 text-gray-700"
                                >
                                    Additional options
                                </span>
                                <span class="text-sm font-medium text-gray-700">
                                    <svg
                                        class="h-6 w-6 transition duration-300"
                                        :class="{
                                            'rotate-180 transform':
                                                singleInterval.showOptions
                                        }"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                            clip-rule="evenodd"
                                        ></path>
                                    </svg>
                                </span>
                            </div>
                            <div
                                v-if="singleInterval.showOptions"
                                class="mt-3 rounded-md border-2 border-dashed border-gray-300 bg-gray-50 p-2 sm:p-4"
                            >
                                <div
                                    :class="{
                                        'pointer-events-none cursor-not-allowed opacity-50': !acceptsPreOrders
                                    }"
                                    class="sm:col-span-6 md:pl-2"
                                >
                                    <div
                                        class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                        Time slot length
                                    </div>
                                    <div class="mt-1 sm:col-span-2 sm:mt-2">
                                        <div
                                            class="max-w-xs rounded-md shadow-sm"
                                        >
                                            <select
                                                v-model="
                                                    singleInterval.slotInterval
                                                "
                                                class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                            >
                                                <option :value="null" default>
                                                    Default
                                                </option>
                                                <option
                                                    :value="duration"
                                                    v-for="duration in slotDurations"
                                                    :key="duration"
                                                >
                                                    {{ duration }}
                                                    Minutes
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    :class="{
                                        'pointer-events-none cursor-not-allowed opacity-50': !acceptsPreOrders
                                    }"
                                    class="sm:col-span-6 md:pl-2"
                                >
                                    <div
                                        class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                        Max orders per slot
                                    </div>

                                    <div class="mt-1 sm:col-span-2 sm:mt-2">
                                        <div
                                            class="max-w-xs rounded-md shadow-sm"
                                        >
                                            <input
                                                type="number"
                                                v-model="
                                                    singleInterval.quantity
                                                "
                                                placeholder="Default"
                                                class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div
                                    :class="{
                                        'pointer-events-none cursor-not-allowed opacity-50': !acceptsPreOrders
                                    }"
                                    class="sm:col-span-6 md:pl-2"
                                >
                                    <div
                                        class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                        Max items per slot
                                    </div>

                                    <div class="mt-1 sm:col-span-2 sm:mt-2">
                                        <div
                                            class="max-w-xs rounded-md shadow-sm"
                                        >
                                            <input
                                                type="number"
                                                v-model="
                                                    singleInterval.itemQuantity
                                                "
                                                placeholder="Default"
                                                class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div
                                    :class="{
                                        'pointer-events-none cursor-not-allowed opacity-50': !acceptsPreOrders
                                    }"
                                    class="sm:col-span-6 md:pl-2"
                                >
                                    <div
                                        class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                        Default monetary limit per slot
                                    </div>
                                    <div class="mt-1 sm:col-span-2 sm:mt-2">
                                        <div
                                            class="relative mt-1 max-w-xs rounded-md shadow-sm"
                                        >
                                            <div
                                                class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                                            >
                                                <span
                                                    class="text-gray-500 sm:text-sm sm:leading-5"
                                                >
                                                    {{ currency.symbol }}
                                                </span>
                                            </div>
                                            <currency-input
                                                v-model="
                                                    singleInterval.monetaryLimit
                                                "
                                                class="form-input block w-full pl-7 pr-12 sm:text-sm sm:leading-5"
                                                placeholder="Default"
                                                :currency="null"
                                                locale="en-GB"
                                                :value-as-integer="true"
                                                :allow-negative="false"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <p
                                    v-show="singleInterval.slotInterval"
                                    class="mt-1 text-sm leading-5 text-gray-500"
                                >
                                    Time length:
                                    {{ singleInterval.slotInterval }}
                                </p>
                                <p
                                    v-show="singleInterval.quantity"
                                    class="mt-1 text-sm leading-5 text-gray-500"
                                >
                                    Max orders:
                                    {{ singleInterval.quantity }}
                                </p>
                                <p
                                    v-show="singleInterval.itemQuantity"
                                    class="mt-1 text-sm leading-5 text-gray-500"
                                >
                                    Max items:
                                    {{ singleInterval.itemQuantity }}
                                </p>
                                <p
                                    v-show="singleInterval.monetaryLimit"
                                    class="mt-1 text-sm leading-5 text-gray-500"
                                >
                                    Monetary limit:
                                    {{
                                        (singleInterval.monetaryLimit / 100)
                                            | currency
                                    }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="my-4">
                    <span
                        v-show="!isAroundTheClock(editablePickupSlots, index)"
                        class="inline-flex rounded-md shadow-sm"
                    >
                        <button
                            v-show="!closedArray[index]"
                            @click="
                                addTimeInterval(
                                    editablePickupSlots,
                                    index,
                                    type
                                )
                            "
                            type="button"
                            class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
                        >
                            Add another time interval
                        </button>
                    </span>
                </div>
                <div class="flex items-center space-x-3">
                    <span
                        @click="
                            toggleCloseDay(
                                editablePickupSlots,
                                index,
                                closedArray,
                                type
                            )
                        "
                        role="checkbox"
                        tabindex="0"
                        aria-checked="false"
                        class="focus:outline-none group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center"
                    >
                        <span
                            aria-hidden="true"
                            :class="{
                                'bg-gray-200': !closedArray[index],
                                'bg-indigo-600': closedArray[index]
                            }"
                            class="absolute mx-auto h-4 w-9 rounded-full bg-gray-200 transition-colors duration-200 ease-in-out"
                        ></span>

                        <span
                            aria-hidden="true"
                            :class="{
                                'translate-x-0': !closedArray[index],
                                'translate-x-5': closedArray[index]
                            }"
                            class="absolute left-0 inline-block h-5 w-5 translate-x-0 transform rounded-full border border-gray-200 bg-white shadow transition-transform duration-200 ease-in-out group-focus:border-blue-300 group-focus:shadow-outline"
                        ></span>
                    </span>
                    <span :id="'unavailable-' + day" style="margin-right: 20px">
                        <span
                            class="text-sm font-medium leading-5 text-gray-700"
                            >Unavailable
                        </span>
                    </span>

                    <span class="inline-flex rounded-md shadow-sm">
                        <span
                            @click="
                                toggleAroundTheClock(
                                    editablePickupSlots,
                                    index,
                                    type,
                                    closedArray
                                )
                            "
                            role="checkbox"
                            tabindex="0"
                            aria-checked="false"
                            class="focus:outline-none group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center"
                        >
                            <span
                                aria-hidden="true"
                                :class="{
                                    'bg-gray-200': !isAroundTheClock(
                                        editablePickupSlots,
                                        index
                                    ),
                                    'bg-indigo-600': isAroundTheClock(
                                        editablePickupSlots,
                                        index
                                    )
                                }"
                                class="absolute mx-auto h-4 w-9 rounded-full bg-gray-200 transition-colors duration-200 ease-in-out"
                            ></span>

                            <span
                                aria-hidden="true"
                                :class="{
                                    'translate-x-0': !isAroundTheClock(
                                        editablePickupSlots,
                                        index
                                    ),
                                    'translate-x-5': isAroundTheClock(
                                        editablePickupSlots,
                                        index
                                    )
                                }"
                                class="absolute left-0 inline-block h-5 w-5 translate-x-0 transform rounded-full border border-gray-200 bg-white shadow transition-transform duration-200 ease-in-out group-focus:border-blue-300 group-focus:shadow-outline"
                            ></span>
                        </span>
                    </span>

                    <span :id="'24hours-' + day">
                        <span
                            class="text-sm font-medium leading-5 text-gray-700"
                        >
                            Open 24 hours
                        </span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import VueTimepicker from 'vue2-timepicker';
import EditVenueService from '@/services/editVenue.service';
const editVenueService = new EditVenueService();
import { mapGetters } from 'vuex';

export default {
    components: {
        VueTimepicker
    },
    props: {
        acceptsPreOrders: {
            type: Boolean,
            required: true
        },
        pickupSlots: {
            type: Array,
            default: () => [],
            required: true
        },
        hoursDict: {
            type: Array,
            default: () => [],
            required: true
        },
        END_OF_THE_DAY: {
            type: String,
            default: '23:59',
            required: false
        },
        validationError: {
            type: Boolean,
            default: false,
            required: true
        }
    },
    watch: {
        editablePickupSlots: {
            deep: true,
            handler(slots) {
                this.$emit('editedPickupTimes', slots);
            }
        }
    },

    data() {
        return {
            closedArray: [true, true, true, true, true, true, true],
            slotDurations: [5, 10, 15, 20, 30, 45, 60, 90, 120, 180, 240],
            showOptionsArray: [false, false, false, false, false, false, false],
            editablePickupSlots: this.pickupSlots.map(slot => {
                return { ...slot, showOptions: false };
            }),
            type: 'slots',
            slotEditKey: 1
        };
    },
    computed: {
        ...mapGetters({
            currency: 'user/currencySettings'
        })
    },
    created() {
        this.checkForDaysClosed(this.editablePickupSlots, this.closedArray);
    },
    methods: {
        toggleCloseDay(array, day, closedArray, type) {
            const {
                resultArray,
                resultClosedArray
            } = editVenueService.toggleCloseDay(array, day, closedArray);

            this.closedArray = resultClosedArray;
            this.editablePickupSlots = [...resultArray];

            if (
                !this.editablePickupSlots.find(slot => slot.day === day) &&
                !this.closedArray[day]
            ) {
                this.editablePickupSlots = editVenueService.addTimeInterval(
                    array,
                    day,
                    type
                );
            }

            if (this.isAroundTheClock(this.editablePickupSlots, day)) {
                this.toggleAroundTheClock(
                    this.editablePickupSlots,
                    day,
                    type,
                    this.closedArray
                );
                this.toggleCloseDay(
                    this.editablePickupSlots,
                    day,
                    this.closedArray,
                    type
                );
            }
        },
        toggleAroundTheClock(array, day, type, closedArray) {
            const {
                resultArray,
                resultClosedArray
            } = editVenueService.toggleAroundTheClock(
                array,
                day,
                type,
                closedArray
            );

            this.editablePickupSlots = resultArray;
            this.closedArray = resultClosedArray;
        },
        toggleShowOptions(singleInterval) {
            singleInterval.showOptions = !singleInterval.showOptions;
            this.slotEditKey++;
        },
        addTimeInterval(array, day, type) {
            this.editablePickupSlots = editVenueService.addTimeInterval(
                array,
                day,
                type
            );

            this.slotEditKey++;
        },
        checkForDaysClosed(array, closedArray) {
            this.closedArray = editVenueService.checkForDaysClosed(
                array,
                closedArray
            );
        },
        deleteTimeInterval(index, day, array) {
            this.editablePickupSlots = editVenueService.deleteTimeInterval(
                index,
                day,
                array
            );
        },
        oneDayIntervalsNumber(day, array) {
            return editVenueService.oneDayIntervalsNumber(day, array);
        },
        isAroundTheClock(array, index) {
            return editVenueService.isAroundTheClock(array, index);
        }
    }
};
</script>
