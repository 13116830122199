<template>
    <div class="mx-auto max-w-7xl px-4 pt-8 sm:px-6 md:px-8 lg:px-8">
        <FeaturePanelWithScreenshot
            title="Bespoke branding for your store"
            subtitle="Unlock branding with a subscription"
            :features="brandingFeatures"
            image="https://ucarecdn.com/d672b435-b0cf-4889-a82c-e1f79791cb22/-/format/auto/-/resize/1500x/"
        >
            <template #cta>
                <base-button
                    v-if="isFeatureAvailable(FeaturesEnum.SAAS)"
                    class="mt-2 sm:ml-3 sm:mt-0"
                    buttonText="Enable Branding"
                />
                <base-upsell-button
                    v-else
                    upsell-item="branding"
                    buttonText="Upgrade to enable custom branding"
                ></base-upsell-button>
            </template>
        </FeaturePanelWithScreenshot>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FeaturesEnum } from '@/enums';
import FeaturePanelWithScreenshot from '@/components/widgets/FeaturePanelWithScreenshot';

export default {
    name: 'BrandingUpsell',
    metaInfo: {
        title: 'Bespoke Branding'
    },
    components: { FeaturePanelWithScreenshot },
    data() {
        return {
            brandingFeatures: [
                {
                    name: 'Custom fonts',
                    description: 'Upload custom heading and body fonts'
                },
                {
                    name: 'Brand colours',
                    description:
                        'Customise almost every colour in the app to match your brand'
                },
                {
                    name: 'Alternative layouts',
                    description:
                        'Customise menu layout, product image sizes and more'
                }
            ],
            FeaturesEnum
        };
    },
    computed: {
        ...mapGetters({
            isFeatureAvailable: 'user/isFeatureAvailable'
        })
    }
};
</script>
