var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"md:flex md:items-center md:justify-between"},[_c('div',{staticClass:"min-w-0 flex-1"},[_c('router-link',{staticClass:"mb-2 flex items-center text-sm text-gray-500 inline-flex",attrs:{"to":{ name: 'editVenue' }}},[_c('base-back-icon'),_c('span',[_vm._v("Settings")])],1),_c('h2',{staticClass:"text-2xl font-semibold text-gray-900"},[_vm._v(" Centegra Integration ")])],1),_c('BaseSpinner',{attrs:{"isLoading":_vm.isLoading}})],1),_c('div',[_c('div',{staticClass:"mt-4 md:grid md:grid-cols-3 md:gap-6"},[_vm._m(0),(!_vm.isLoading)?_c('div',{staticClass:"mt-5 md:col-span-2 md:mt-0"},[_c('div',{staticClass:"shadow sm:overflow-hidden sm:rounded-md"},[_c('div',{staticClass:"space-y-6 bg-white px-4 py-5 sm:p-6"},[_c('div',[_c('div',[_c('label',{staticClass:"block text-sm font-medium leading-5 text-gray-700",attrs:{"for":"locationId"}},[_vm._v(" Location ")]),_c('div',{staticClass:"mt-2 rounded-md shadow-sm"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.centegra.location),expression:"centegra.location"}],staticClass:"form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5",class:{
                                            'border-red-300':
                                                _vm.$v.centegra.location.$error
                                        },attrs:{"id":"locationId","disabled":!_vm.centegraSettings.locations ||
                                            !_vm.centegraSettings.locations
                                                .length},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.centegra, "location", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[(
                                                _vm.centegraSettings.locations
                                            )?_vm._l((_vm.centegraSettings.locations),function(location){return _c('option',{key:location.LocationID,domProps:{"value":location}},[_vm._v(" "+_vm._s(location.LocationName)+" ")])}):_vm._e()],2)]),(_vm.$v.centegra.location.$error)?_c('span',{staticClass:"text-xs text-red-600"},[_vm._v(" Please select an option. ")]):_vm._e()]),_c('div',{staticClass:"mt-6"},[_c('label',{staticClass:"block text-sm font-medium leading-5 text-gray-700",attrs:{"for":"till"}},[_vm._v(" Till ")]),_c('div',{staticClass:"mt-2 rounded-md shadow-sm"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.centegra.till),expression:"centegra.till"}],staticClass:"form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5",class:{
                                            'border-red-300':
                                                _vm.$v.centegra.till.$error
                                        },attrs:{"id":"till","disabled":!_vm.centegra.location ||
                                            !_vm.centegra.location.tills ||
                                            !_vm.centegra.location.tills.length},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.centegra, "till", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[(
                                                _vm.centegra.location &&
                                                _vm.centegra.location.tills
                                            )?_vm._l((_vm.centegra
                                                    .location.tills),function(till){return _c('option',{key:till.TillID,domProps:{"value":till}},[_vm._v(" "+_vm._s(till.TillName)+" ")])}):_vm._e()],2)]),(
                                        !_vm.centegra.location &&
                                        !_vm.$v.centegra.till.$error
                                    )?_c('span',{staticClass:"text-xs text-gray-500"},[_vm._v(" Tip: select the location first to be able to select till option ")]):_vm._e(),(_vm.$v.centegra.till.$error)?_c('span',{staticClass:"text-xs text-red-600"},[_vm._v(" Please select an option. ")]):_vm._e()]),_c('div',{staticClass:"mt-6"},[_c('label',{staticClass:"block text-sm font-medium leading-5 text-gray-700",attrs:{"for":"clerkId"}},[_vm._v(" Clerk ")]),_c('div',{staticClass:"mt-2 rounded-md shadow-sm"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.centegra.clerkId),expression:"centegra.clerkId"}],staticClass:"form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5",class:{
                                            'border-red-300':
                                                _vm.$v.centegra.clerkId.$error
                                        },attrs:{"id":"clerkId","disabled":!_vm.centegraSettings.clerks ||
                                            !_vm.centegraSettings.clerks.length},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.centegra, "clerkId", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[(_vm.centegraSettings.clerks)?_vm._l((_vm.centegraSettings.clerks),function(clerk){return _c('option',{key:clerk.ClerkID,domProps:{"value":clerk.ClerkID}},[_vm._v(" "+_vm._s(clerk.ClerkName)+" ")])}):_vm._e()],2)]),(_vm.$v.centegra.clerkId.$error)?_c('span',{staticClass:"text-xs text-red-600"},[_vm._v(" Please select an option. ")]):_vm._e()]),_c('div',{staticClass:"mt-6"},[_c('label',{staticClass:"block text-sm font-medium leading-5 text-gray-700",attrs:{"for":"PriceLevelId"}},[_vm._v(" Price Level ")]),_c('div',{staticClass:"mt-2 rounded-md shadow-sm"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.centegra.priceLevelId),expression:"centegra.priceLevelId"}],staticClass:"form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5",class:{
                                            'border-red-300':
                                                _vm.$v.centegra.priceLevelId
                                                    .$error
                                        },attrs:{"id":"PriceLevelId","disabled":!_vm.centegraSettings.priceLevels ||
                                            !_vm.centegraSettings.priceLevels
                                                .length},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.centegra, "priceLevelId", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[(
                                                _vm.centegraSettings.priceLevels
                                            )?_vm._l((_vm.centegraSettings.priceLevels),function(priceLevel){return _c('option',{key:priceLevel.PriceLevelID,domProps:{"value":priceLevel.PriceLevelID}},[_vm._v(" "+_vm._s(priceLevel.PriceLevelName)+" ")])}):_vm._e()],2)]),(_vm.$v.centegra.priceLevelId.$error)?_c('span',{staticClass:"text-xs text-red-600"},[_vm._v(" Please select an option. ")]):_vm._e()]),_c('div',{staticClass:"mt-6"},[_c('label',{staticClass:"block text-sm font-medium leading-5 text-gray-700",attrs:{"for":"mediaId"}},[_vm._v(" Media ")]),_c('div',{staticClass:"mt-2 rounded-md shadow-sm"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.centegra.mediaId),expression:"centegra.mediaId"}],staticClass:"form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5",class:{
                                            'border-red-300':
                                                _vm.$v.centegra.mediaId.$error
                                        },attrs:{"id":"mediaId","disabled":!_vm.centegraSettings.media ||
                                            !_vm.centegraSettings.media.length},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.centegra, "mediaId", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[(_vm.centegraSettings.media)?_vm._l((_vm.centegraSettings.media),function(media){return _c('option',{key:media.MediaID,domProps:{"value":media.MediaID}},[_vm._v(" "+_vm._s(media.MediaName)+" ")])}):_vm._e()],2)]),(_vm.$v.centegra.mediaId.$error)?_c('span',{staticClass:"text-xs text-red-600"},[_vm._v(" Please select an option. ")]):_vm._e()])])])])]):_vm._e()])]),_c('BaseSpacerWithRuler'),_c('div',{staticClass:"my-6 ml-4 flex flex-shrink-0 justify-end"},[_c('span',{staticClass:"inline-flex rounded-md shadow-sm",on:{"click":_vm.saveVenue}},[_c('button',{staticClass:"focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700",attrs:{"type":"button","disabled":_vm.isLoading}},[_vm._v(" Save ")])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md:col-span-1"},[_c('div',{staticClass:"sm:pl-0 sm:pr-5"},[_c('h3',{staticClass:"text-base font-semibold leading-6 text-gray-800"},[_vm._v(" Settings ")]),_c('p',{staticClass:"mt-2 text-sm text-gray-500"},[_vm._v(" Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus eaque modi, quas quidem ratione repellat totam? Alias eos fugiat natus. "),_c('br'),_c('br'),_vm._v("Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nesciunt, reprehenderit? "),_c('a',{staticClass:"text-indigo-600",attrs:{"href":"https://centegra.co.uk/","target":"_blank"}},[_vm._v(" Lorem ipsum dolor sit. ")])])])])
}]

export { render, staticRenderFns }