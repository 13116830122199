<template>
    <div>
        <div class="md:flex md:items-center md:justify-between">
            <div class="min-w-0 flex-1">
                <router-link :to="{ name: 'editVenue' }" class="mb-2 flex items-center text-sm text-gray-500 inline-flex">
                    <base-back-icon />
                    <span>Settings</span>
                </router-link>
                <base-page-title title="Payment Settings" />
            </div>

            <BaseSpinner :isLoading="isLoading" />
        </div>
        <div>
            <div class="mt-6 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3 class="text-base font-semibold leading-6 text-gray-800">Payment provider</h3>
                        <p class="mt-2 text-sm text-gray-500">
                            Enable and manage your payment processing settings
                            to start accepting credit and debit cards.
                        </p>
                    </div>
                </div>
                <div class="md:col-span-2 md:mt-0">
                    <AdyenPaymentOption :venue="venue" :venue-id="venueId" />
                    <AdyenKlarnaPaymentOption v-if="venue.activePaymentSystem === 'Adyen'" :venue="venue"
                        :venue-id="venueId" />
                </div>
            </div>
        </div>

        <BaseSpacerWithRuler />
    </div>
</template>
<script>
import AdyenPaymentOption from './payments/AdyenPaymentOption';
import AdyenKlarnaPaymentOption from './payments/AdyenKlarnaPaymentOption';
import { mapGetters } from 'vuex';
import { FeaturesEnum } from '@/enums';

export default {
    name: 'PaymentSettings',
    components: {
        AdyenPaymentOption,
        AdyenKlarnaPaymentOption,
    },
    props: {
        venueId: {
            type: Number,
            required: true,
            default: 0
        },
        venue: {
            type: Object,
            required: false,
            default: () => { }
        }
    },
    metaInfo: {
        title: 'Payment Settings'
    },
    data() {
        return {
            isLoading: false,
            FeaturesEnum
        };
    },
    computed: {
        ...mapGetters({
            isFeatureAvailable: 'user/isFeatureAvailable'
        })
    }
};
</script>
