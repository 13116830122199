<template>
    <div class="relative">
      <editor-content :editor="editor" class="px-4 py-2" :class="{ 'pr-8': limit }" />
      <div class="bg-gray-50 px-4 py-2 w-full flex items-center justify-between">
        <div v-if="editor" class="flex items-center">
          <transition name="fade">
            <div v-if="limit"
                 class="text-xs text-gray-500 absolute right-2 top-3"
            >
              <svg
                  height="20"
                  width="20"
                  viewBox="0 0 20 20"
                  class="character-count__graph text-blue-400"
                  :class="{'character-count': true, 'character-count--warning': editor.storage.characterCount.characters() === limit, 'hidden' : editor.storage.characterCount.characters() === 0}"
              >
                <circle
                    r="10"
                    cx="10"
                    cy="10"
                    fill="#e9ecef"
                />
                <circle
                    r="5"
                    cx="10"
                    cy="10"
                    fill="transparent"
                    stroke="currentColor"
                    stroke-width="10"
                    :stroke-dasharray="`calc(${percentage} * 31.4 / 100) 31.4`"
                    transform="rotate(-90) translate(-20)"
                />
                <circle
                    r="6"
                    cx="10"
                    cy="10"
                    fill="white"
                />
              </svg>
              <span class="hidden">
{{ editor.storage.characterCount.characters() }}/{{ limit }}
            characters
            </span>

            </div>

          </transition>
          <button
              v-if="decorations"
              class="mr-1 rounded-lg bg-gray-100 px-2 py-0.5 text-sm font-bold"
              :class="{ 'bg-gray-200': editor.isActive('bold') }"
              @click="editor.chain().focus().toggleBold().run()"
          >
            B
          </button>
          <button
              v-if="decorations"
              class="mx-1 rounded-lg bg-gray-100 px-2 py-0.5 text-sm font-bold"
              :class="{ 'bg-gray-200': editor.isActive('italic') }"
              @click="editor.chain().focus().toggleItalic().run()"
          >
            I
          </button>
          <button
              v-if="decorations"
              class="mx-1 rounded-lg bg-gray-100 px-2 py-0.5 text-sm font-bold"
              :class="{ 'bg-gray-200': editor.isActive('underline') }"
              @click="editor.chain().focus().toggleUnderline().run()"
          >
            U
          </button>
          <button
              v-if="links"
              class="mx-1 rounded-lg bg-gray-100 px-2 py-0.5"
              @click="setLink"
          >
            <base-link-icon class="w-4 h-4 my-0.5" />
          </button>
          <button
              v-if="attachments"
              v-tooltip="'Attach a file'"
              class="mx-1 rounded-lg bg-gray-100 px-2 py-0.5"
              @click="$emit('toggle-attachments')"
          >
            <base-paper-clip-icon class="w-4 h-4 my-0.5" />
          </button>
          <button
              v-tooltip="'Mention user'"  
              class="mx-1 rounded-lg bg-gray-100 px-2 py-0.5"
              @click="insertMention"
          >
            <base-at-symbol-icon class="w-4 h-4 my-0.5" />
          </button>        
        </div>
        <div>
          <slot />
        </div>
      </div>

        <link-input-modal @create-link="createLink" />
    </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import CharacterCount from '@tiptap/extension-character-count';
import Underline from '@tiptap/extension-underline';
import Mention from '@tiptap/extension-mention';
import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import { mergeAttributes } from '@tiptap/core';
import { VueRenderer } from '@tiptap/vue-2';
import tippy from 'tippy.js';
import MentionList from './MentionList.vue';
import LinkInputModal from '@/components/modals/LinkInputModal';

export default {
    components: {
        EditorContent,
        LinkInputModal
    },

    props: {
        value: {
            type: String,
            default: ''
        },
        users: {
            type: Array,
            required: false,
            default: () => []
        },
        attachments: {
            type: Boolean,
            required: false,
            default: false
        },
        decorations: {
            type: Boolean,
            required: false,
            default: false
        },
        mentions: {
            type: Boolean,
            required: false,
            default: false
        },
        links: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data() {
        return {
            editor: null,
            limit: 240
        };
    },

    watch: {
        value(value) {
            const isSame = this.editor.getHTML() === value;

            if (isSame) {
                return;
            }

            this.editor.commands.setContent(value, false);
        }
    },

    mounted() {
        if (this.mentions) {
            this.$emit('get-users');
        }

        const CustomMention = Mention.extend({
            renderHTML({ node, HTMLAttributes }) {
                return [
                    'span',
                    mergeAttributes(
                        this.options.HTMLAttributes,
                        HTMLAttributes
                    ),
                    `@${node.attrs.name}`
                ];
            },
            addAttributes() {
                return {
                    name: {
                        default: null,
                        parseHTML: element => {
                            return {
                                name: element.getAttribute('data-mention-name')
                            };
                        },
                        renderHTML: attributes => {
                            if (!attributes.name) {
                                return {};
                            }
                            return {
                                'data-mention-name': attributes.name
                            };
                        }
                    },
                    id: {
                        default: null,
                        parseHTML: element => {
                            return {
                                id: element.getAttribute('data-mention-id')
                            };
                        },
                        renderHTML: attributes => {
                            if (!attributes.id) {
                                return {};
                            }

                            return {
                                'data-mention-id': attributes.id
                            };
                        }
                    }
                };
            }
        });

        const extensions = [
            StarterKit,
            Underline,
            Placeholder.configure({
              placeholder: 'Leave a comment…',
            }),
            CharacterCount.configure({
                limit: this.limit
            })
        ];

        if (this.links) {
            extensions.push(
                Link.extend({ inclusive: false }).configure({
                    HTMLAttributes: {
                        class: 'comment-link'
                    },
                    openOnClick: false
                })
            );
        }

        if (this.mentions) {
            extensions.push(
                CustomMention.configure({
                    HTMLAttributes: {
                        class: 'mention'
                    },
                    suggestion: {
                        items: ({ query }) => {
                            return this.users
                                .filter(item =>
                                    item.name
                                        .toLowerCase()
                                        .startsWith(query.toLowerCase())
                                )
                                .slice(0, 5);
                        },
                        render: () => {
                            let component;
                            let popup;

                            return {
                                onStart: props => {
                                    component = new VueRenderer(MentionList, {
                                        parent: this,
                                        propsData: props
                                    });

                                    if (!props.clientRect) {
                                        return;
                                    }

                                    popup = tippy('body', {
                                        getReferenceClientRect:
                                            props.clientRect,
                                        appendTo: () => document.body,
                                        content: component.element,
                                        showOnCreate: true,
                                        interactive: true,
                                        trigger: 'manual',
                                        placement: 'bottom-start'
                                    });
                                },
                                onUpdate(props) {
                                    component.updateProps(props);

                                    if (!props.clientRect) {
                                        return;
                                    }

                                    popup[0].setProps({
                                        getReferenceClientRect: props.clientRect
                                    });
                                },
                                onKeyDown(props) {
                                    if (props.event.key === 'Escape') {
                                        popup[0].hide();

                                        return true;
                                    }

                                    return component.ref?.onKeyDown(props);
                                },
                                onExit() {
                                    popup[0].destroy();
                                    component.destroy();
                                }
                            };
                        }
                    }
                })
            );
        }

        this.editor = new Editor({
            content: this.value,
            extensions,
            editorProps: {
                attributes: {
                    class: 'prose prose-sm sm:prose-base m-1 focus:outline-none'
                }
            },

            onUpdate: () => {
                if (this.editor.storage.characterCount.characters() === 0) {
                    this.$emit('input', '');
                } else {
                    this.$emit('input', this.editor.getHTML());
                }
            }
        });
    },

    beforeDestroy() {
        this.editor.destroy();
    },

    computed: {
      percentage() {
        return Math.round((100 / this.limit) * this.editor.storage.characterCount.characters())
      },
    },

    methods: {
        setLink() {
            const previousUrl = this.editor.getAttributes('link').href;

            this.$modal.show('link-input-modal', {
                previousUrl
            });
        },

        async createLink({ text, link }) {
            if (link === '') {
                this.editor
                    .chain()
                    .focus()
                    .extendMarkRange('link')
                    .unsetLink()
                    .run();

                return;
            }

            if (link) {
                this.editor
                    .chain()
                    .focus()
                    .extendMarkRange('link')
                    .deleteSelection()
                    .run(); //workaround for editing links with custom text

                this.editor
                    .chain()
                    .focus()
                    .extendMarkRange('link')
                    .setLink({ href: link, target: '_blank' })
                    .command(({ tr }) => {
                        tr.insertText(text);
                        return true;
                    })
                    .run();
            }
        },
        insertMention() {
            this.editor
                .chain()
                .focus()
                .command(({ tr }) => {
                    tr.insertText(`@`);
                    return true;
                })
                .run();
        }
    }
};
</script>

<style src="@/assets/css/animations.css" />

<style>
.tiptap p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.character-count {
  display: flex;
  align-items: center;
  color: #68cef8;
}
.character-count--warning {
  color: #fb5151;
}
.character-count__graph {
  margin-right: 0.5rem;
}
.character-count__text {
  color: #868e96;
}


.tippy-box {
  background-color: transparent!important;
}
.tippy-content {
  padding: 0!important;
  background: transparent;
}

.tippy-content:after {
  display:none;
}

.tippy-arrow {
  color: gray!important;
}

</style>
