<template>
    <div>
        <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-1">
                <div class="sm:pl-0 sm:pr-5">
                    <h3 class="text-base font-semibold leading-6 text-gray-800"
                        >Smart review links</h3
                    >
                    <p class="mt-2 text-sm text-gray-500"> </p>
                </div>
            </div>
            <div class="mt-5 md:col-span-2 md:mt-0">
                <div class="shadow sm:overflow-hidden sm:rounded-md">
                    <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
                        <div class="grid grid-cols-3 gap-6">
                            <div class="col-span-6 sm:col-span-6 lg:col-span-3">
                                <ul
                                    role="list"
                                    class="divide-y divide-gray-200"
                                >
                                    <li
                                        v-for="link in reviewLinks"
                                        :key="link.id"
                                        class="relative py-4"
                                        :class="{
                                            'opacity-25': link.archived
                                        }"
                                    >
                                        <div
                                            class="flex items-end justify-between"
                                        >
                                            <span
                                                class="py-0.5 text-xs font-medium text-green-800"
                                            >
                                                {{
                                                    link.id
                                                        ? link.clicks +
                                                          ' clicks'
                                                        : 'new'
                                                }}
                                            </span>
                                            <div class="flex flex-row">
                                                <base-button
                                                    class="ml-3 w-auto"
                                                    @clicked="
                                                        link.archived = !link.archived
                                                    "
                                                    :buttonText="
                                                        link.archived
                                                            ? 'Undelete'
                                                            : 'Delete'
                                                    "
                                                    size="sm"
                                                >
                                                </base-button>
                                                <base-button
                                                    class="ml-3 w-auto"
                                                    :href="link.url"
                                                    buttonText="View link"
                                                    size="sm"
                                                >
                                                    <template #left-icon>
                                                        <base-eye-icon
                                                            class="mr-2 h-4 w-4"
                                                        />
                                                    </template>
                                                </base-button>
                                            </div>
                                        </div>
                                    </li>
                                </ul>

                                <div class="mt-3">
                                    <div>
                                        <div
                                            class="relative rounded-md shadow-sm"
                                        >
                                            <div
                                                class="absolute inset-y-0 left-0 flex items-center"
                                            >
                                                <label
                                                    for="type"
                                                    class="sr-only"
                                                    >Country</label
                                                >
                                                <select
                                                    id="type"
                                                    name="type"
                                                    v-model="newLink.type"
                                                    class="focus:outline-none h-full rounded-md border-0 bg-transparent py-0 pl-3 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                                                >
                                                    <option
                                                        value="google_places"
                                                        >Google</option
                                                    >
                                                    <option value="trip_advisor"
                                                        >Tripadvisor</option
                                                    >
                                                    <option value="yelp"
                                                        >Yelp</option
                                                    >
                                                </select>
                                            </div>
                                            <input
                                                type="url"
                                                name="reviewLinkUrl"
                                                id="reviewLinkUrl"
                                                v-model="newLink.url"
                                                class="block w-full rounded-md border-0 py-1.5 pl-32 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                placeholder=""
                                            />
                                        </div>
                                    </div>
                                    <base-button
                                        class="mt-3"
                                        @clicked="addSmartReviewLink"
                                        buttonText="Add a review link"
                                    />
                                </div>

                                <base-button
                                    class="mt-3"
                                    @clicked="saveReviewLinks"
                                    buttonText="Save review links"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
    name: 'SmartReviewSettings',
    components: {},
    props: {
        venue: {
            type: Object,
            required: true
        }
    },
    validations: {
        reviewLinks: {
            $each: {
                url: {
                    required
                }
            }
        }
    },
    data() {
        return {
            reviewLinks: [],
            newLink: {
                url: 'https://search.google.com/local/writereview?placeid=',
                type: 'google_places',
                archived: false
            },
            isLoading: false
        };
    },
    created() {
        this.fetchReviewLinks();
    },
    methods: {
        async fetchReviewLinks() {
            try {
                this.isLoading = true;
                const { data } = await this.$axios.get(
                    `/venues/${this.venue.id}/review-links`
                );
                this.reviewLinks = data;
            } catch (error) {
                throw new Error(error);
            } finally {
                this.isLoading = false;
            }
        },
        addSmartReviewLink() {
            this.reviewLinks.push(this.newLink);
        },
        archiveReviewLink(index) {
            this.reviewLinks.splice(index, 1);
        },
        async saveReviewLinks() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return false;
            }

            try {
                this.isLoading = true;

                const reviewLinks = this.reviewLinks.map(
                    // eslint-disable-next-line no-unused-vars
                    ({ url, type, archived, id }) => ({
                        url,
                        type,
                        archived,
                        id
                    })
                );

                await this.$axios.put(
                    `/venues/${this.venue.id}/review-links`,
                    reviewLinks
                );
                await this.fetchReviewLinks();
                this.$notify({
                    group: 'settings',
                    text: 'Review links saved'
                });
            } catch (error) {
                this.$notify({
                    group: 'settings',
                    text: 'Failed to save review links'
                });
                throw new Error(error);
            } finally {
                this.isLoading = false;
            }
        }
    }
};
</script>
