<template>
    <div class="my-4 md:mt-80">
        <div class="flex flex-row items-center">
            <admin-id-tag :id="order.id" />
            <div
                @click="showAdminJson = !showAdminJson"
                class="ml-auto mr-3 flex h-7 w-7 cursor-pointer select-none items-center justify-center rounded-sm bg-sk-black px-2 py-1 shadow-md transition-all duration-300"
            >
                <span
                    class="text-sm font-bold uppercase leading-none text-sk-yellow"
                    >{{ showAdminJson ? '-' : '+' }}</span
                >
            </div>
        </div>
        <div v-if="showAdminJson">
            <div
                class="mt-3 rounded-md border border-dashed bg-white p-2 text-xs"
            >
                <div class="flex justify-between">
                    <h2 class="mb-2 text-sm font-bold text-gray-600"
                        >External service status:</h2
                    >
                    <a
                        class="underline"
                        :href="`https://app.datadoghq.eu/logs?from_ts=${orderCreatedAtUnix.start}&to_ts=${orderCreatedAtUnix.end}&query=${order.id}`"
                        target="_blank"
                    >
                        Vew in logs ->
                    </a>
                </div>
                <json-viewer :jsonData="order.externalServiceStatuses" />
            </div>

            <div
                class="mt-2 rounded-md border border-dashed bg-white p-2 text-xs"
            >
                <h2 class="mb-2 text-sm font-bold text-gray-600"
                    >Order JSON:</h2
                >
                <json-viewer :jsonData="order" />
            </div>
        </div>
    </div>
</template>

<script>
import AdminIdTag from '@/components/helpers/AdminIdTag';

export default {
    name: 'AdminOrderJson',
    components: {
        JsonViewer: () => import('@/components/helpers/JsonViewer'),
        AdminIdTag
    },
    props: {
        order: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            showAdminJson: false
        };
    },
    computed: {
        orderCreatedAtUnix() {
            return {
                start: this.$moment(this.order.created_at).valueOf(),
                end: this.$moment(this.order.created_at)
                    .add(1, 'hour')
                    .valueOf()
            };
        }
    }
};
</script>
