<template>
    <div>
        <div class="py-4">
            <div>
                <div class="p-4" v-if="isLoading">
                    <vue-content-loading
                        :width="400"
                        :height="150"
                        style="height: 150px; width: 400px"
                    >
                        <circle cx="10" cy="20" r="8" />
                        <rect
                            x="25"
                            y="15"
                            rx="5"
                            ry="5"
                            width="220"
                            height="10"
                        />
                        <circle cx="10" cy="50" r="8" />
                        <rect
                            x="25"
                            y="45"
                            rx="5"
                            ry="5"
                            width="220"
                            height="10"
                        />
                        <circle cx="10" cy="80" r="8" />
                        <rect
                            x="25"
                            y="75"
                            rx="5"
                            ry="5"
                            width="220"
                            height="10"
                        />
                        <circle cx="10" cy="110" r="8" />
                        <rect
                            x="25"
                            y="105"
                            rx="5"
                            ry="5"
                            width="220"
                            height="10"
                        />
                    </vue-content-loading>
                </div>

              <ul role="list" class="space-y-6">
                <li v-for="(order, index) in orders" :key="order.id" class="relative flex gap-x-4">
                  <div :class="[index === orders.length - 1 ? 'h-6' : '-bottom-6', 'absolute left-0 top-0 flex w-6 justify-center']">
                    <div class="w-px bg-gray-200" />
                  </div>
                  <template>
                    <div class="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                      <div class="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                    </div>
                    <p class="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                      <router-link class="font-medium text-gray-900 cursor-pointer" :to="{ name: 'orderDetail', params: { id: order.id } }">#{{ order.code }}</router-link> ordered.
                    </p>
                    <time :datetime="order.created_at" class="flex-none py-0.5 text-xs leading-5 text-gray-500">{{
                        order.created_at | moment('calendar', null, { sameDay: '[Today] [at] HH:mm',
                          nextDay: '[Tomorrow] [at] HH:mm',
                          nextWeek: 'dddd [at] HH:mm',
                          lastDay: '[Yesterday]  [at] HH:mm',
                          lastWeek: '[Last] dddd [at] HH:mm',
                          sameElse: 'Do MMM [at] HH:mm' })
                      }}</time>
                  </template>
                </li>
              </ul>

                <div v-if="!isLoading && !orders.length">
                    <div class="bg-white shadow sm:rounded-lg">
                        <div class="px-4 py-5 sm:p-6">
                            <div
                                class="mt-2 sm:flex sm:items-start sm:justify-between"
                            >
                                <div
                                    class="mx-auto max-w-xl text-center text-gray-500 text-sm leading-5"
                                >
                                    <p class="text-gray-600 text-base">
                                        No orders yet.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueContentLoading from 'vue-content-loading';

export default {
    name: 'CustomerOrders',
    components: {
        VueContentLoading
    },
    props: {
        orders: {
            type: Array,
            default: null
        },
        isLoading: {
            type: Boolean,
            default: true
        }
    }
};
</script>
