<template>
    <div class="w-full h-full">
        <MglMap
            :accessToken="accessToken"
            :mapStyle="mapStyle"
            :center="[1.1743, 52.3555]"
            :zoom="5"
        >
            <!--            <MglMarker :coordinates="coordinates" color="blue" />-->
            <MglNavigationControl position="top-right" />
            <MglGeojsonLayer
                type="fill"
                :sourceId="'deliveries'"
                :source="geoJson"
                layerId="geoJsonLayer"
                :layer="geoJsonlayer"
            />
        </MglMap>
    </div>
</template>

<script>
import Mapbox from 'mapbox-gl';
import { MglMap, MglGeojsonLayer, MglNavigationControl } from 'vue-mapbox';

export default {
    name: 'DeliveryHeatmap',
    props: ['venue', 'geoJson'],
    components: {
        MglMap,
        MglGeojsonLayer,
        MglNavigationControl
    },
    async created() {
        this.mapbox = Mapbox;
    },
    data() {
        return {
            accessToken:
                'pk.eyJ1IjoiYmVubGFyY2V5c2siLCJhIjoiY2pycXZnOHp5MDRuMDQ0cHJ4ZWttMGxsYSJ9.MQY0Fnhodssbf7FMG0EiHw', // your access token. Needed if you using Mapbox maps
            mapStyle: 'mapbox://styles/benlarceysk/ck8enq76w032w1itcbgkkkrwc', // your map style
            geoJsonlayer: {
                id: 'geoJsonLayer',
                type: 'heatmap',
                maxzoom: 20,
                paint: {
                    // Increase the heatmap weight based on frequency and property magnitude
                    'heatmap-weight': [
                        'interpolate',
                        ['linear'],
                        ['get', 'mag'],
                        0,
                        0,
                        6,
                        1
                    ],
                    // Increase the heatmap color weight weight by zoom level
                    // heatmap-intensity is a multiplier on top of heatmap-weight
                    'heatmap-intensity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        0,
                        1,
                        9,
                        3
                    ],
                    // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
                    // Begin color ramp at 0-stop with a 0-transparancy color
                    // to create a blur-like effect.
                    'heatmap-color': [
                        'interpolate',
                        ['linear'],
                        ['heatmap-density'],
                        0,
                        'rgba(33,102,172,0)',
                        0.2,
                        'rgb(103,169,207)',
                        0.4,
                        'rgb(209,229,240)',
                        0.6,
                        'rgb(253,219,199)',
                        0.8,
                        'rgb(239,138,98)',
                        1,
                        'rgb(178,24,43)'
                    ],
                    // Adjust the heatmap radius by zoom level
                    'heatmap-radius': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        0,
                        2,
                        9,
                        20
                    ]
                    // Transition from heatmap to circle layer by zoom level
                    // 'heatmap-opacity': [
                    //     'interpolate',
                    //     ['linear'],
                    //     ['zoom'],
                    //     7,
                    //     1,
                    //     9,
                    //     0
                    // ]
                }
            }
        };
    }
};
</script>

<style>
/*a.mapboxgl-ctrl-logo {*/
/*    display: none;*/
/*}*/

.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
    display: none;
}
</style>
