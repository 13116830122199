<template>
    <div
        class="flex min-h-screen w-full flex-col justify-center bg-sk-silver-grey py-12 sm:px-6 lg:px-8"
    >
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <img
                class="ml-0 mr-auto h-10 w-auto"
                :src="whitelabelLogoUrl"
                alt="Logo"
                v-if="whitelabelLogoUrl"
            />
        </div>

        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-lg">
            <div
                class="bg-white px-4 py-8 shadow-xl sm:rounded-md sm:px-10 sm:px-5 sm:py-10 md:px-14 md:py-14"
            >
                <h2
                    class="mb-6 text-left font-heading text-2xl font-bold leading-9 text-gray-700"
                >
                    Reset your password
                </h2>
                <form @submit.prevent="reset()">
                    <div class="mt-6">
                        <label
                            for="email"
                            class="block text-sm font-medium leading-5 text-gray-700"
                        >
                            Email address
                        </label>
                        <div class="mt-1 rounded-md shadow-sm">
                            <input
                                v-model="user.email"
                                @change="emailUnique = ''"
                                id="email"
                                type="email"
                                autocomplete="username"
                                required
                                class="focus:outline-none block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                            />
                        </div>
                        <div class="mt-1" v-if="emailUnique">
                            <span class="py-2 text-xs text-red-600">{{
                                emailUnique
                            }}</span>
                        </div>
                    </div>

                    <div class="mt-6">
                        <span
                            class="block w-full rounded-md shadow-sm"
                            :class="{ spinner: isLoading }"
                        >
                            <button
                                :disabled="isLoading"
                                class="focus:outline-none flex w-full justify-center rounded-md border border-transparent bg-indigo-800 px-4 py-3 text-sm font-semibold text-white transition duration-150 ease-in-out hover:bg-indigo-900 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                            >
                                Send reset email
                            </button>
                        </span>
                        <div v-if="showSuccessMessage" class="mt-2">
                            <span class="text-sm text-gray-600"
                                >If there is an account associated with
                                {{ user.email }} you will receive an email with
                                a link to reset your password
                            </span>
                        </div>
                    </div>
                </form>
            </div>
            <div>
                <p
                    class="max-w mt-8 pl-3 text-left text-sm leading-5 text-gray-600"
                >
                    Or
                    <router-link
                        :to="{ name: 'login' }"
                        class="focus:outline-none font-medium text-indigo-600 transition duration-150 ease-in-out hover:text-indigo-500 focus:underline"
                    >
                        login to your account
                    </router-link>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PasswordReset',
    metaInfo: {
        title: 'Reset Password'
    },
    props: {
        email: {
            type: String,
            required: false,
            default: ''
        }
    },
    computed: {
        whitelabelLogoUrl() {
            return process.env.VUE_APP_WIHTELABEL_LOGO || null;
        }
    },
    data() {
        return {
            isLoading: false,
            showSuccessMessage: false,
            passwordCheck: true,
            emailUnique: null,
            user: {
                email: ''
            }
        };
    },
    mounted() {
        if (this.email) {
            this.user.email = this.email;
        }
    },
    methods: {
        reset() {
            this.isLoading = true;
            this.$store
                .dispatch('user/RESET', {
                    email: this.user.email
                })
                .then(() => {
                    this.showSuccessMessage = true;
                })
                .catch(e => {
                    if (e.data) {
                        this.emailUnique = e.data.message;
                    }
                    throw new Error(`API ${e}`);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }
    }
};
</script>

<style scoped>
.spinner:after {
    left: auto;
    right: 1em;
    border-right-color: white;
}
</style>
