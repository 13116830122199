<template>
    <div
        class="flex min-h-screen w-full flex-row justify-start bg-sk-silver-grey sm:justify-center"
    >
        <div
            class="flex w-full flex-col justify-center px-4 py-12 sm:px-6 lg:w-1/2 lg:px-8"
        >
            <div class="sm:mx-auto sm:w-full sm:max-w-md">
                <img
                    class="ml-0 mr-auto h-10 w-auto"
                    :src="whitelabelLogoUrl"
                    alt="Logo"
                    v-if="whitelabelLogoUrl"
                />
            </div>

            <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-lg">
                <div
                    class="bg-white px-4 py-8 shadow-xl sm:rounded-md sm:px-10 sm:px-5 sm:py-10 md:px-14 md:py-14"
                >
                    <h2
                        class="mb-6 text-left font-heading text-2xl font-bold leading-9 text-gray-700"
                    >
                        Log in to your account
                    </h2>
                    <form @submit.prevent="login()">
                        <div>
                            <label
                                for="email"
                                class="block text-sm font-medium leading-5 text-gray-700"
                            >
                                Email address
                            </label>
                            <div class="mt-1 rounded-md shadow-sm">
                                <input
                                    v-model="user.email"
                                    id="email"
                                    type="email"
                                    autocomplete="username"
                                    required
                                    class="focus:outline-none block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                                />
                            </div>
                        </div>

                        <div class="mt-6">
                            <BasePasswordInput
                                v-model="user.password"
                                autocomplete="current-password"
                            />
                        </div>

                        <div class="mt-6 flex items-center justify-between">
                            <div class="flex items-center">
                                <input
                                    id="remember_me"
                                    type="checkbox"
                                    class="form-checkbox h-4 w-4 rounded border-gray-300 text-indigo-600 transition duration-150 ease-in-out focus:ring-indigo-500"
                                />
                                <label
                                    for="remember_me"
                                    class="ml-2 block text-sm leading-5 text-gray-900"
                                >
                                    Remember me
                                </label>
                            </div>

                            <div class="text-sm leading-5">
                                <router-link
                                    :to="{
                                        name: 'reset',
                                        params: {
                                            email: validEmail
                                        }
                                    }"
                                    class="focus:outline-none font-medium text-indigo-600 transition duration-150 ease-in-out hover:text-indigo-500 focus:underline"
                                >
                                    Forgot your password?
                                </router-link>
                            </div>
                        </div>

                        <div class="mt-6">
                            <span
                                class="block w-full rounded-md shadow-sm sm:shadow-md"
                                :class="{ spinner: isLoading }"
                            >
                                <button
                                    type="submit"
                                    :disabled="isLoading"
                                    class="focus:outline-none flex w-full justify-center rounded-md border border-transparent bg-indigo-800 px-4 py-3 text-sm font-semibold text-white transition duration-150 ease-in-out hover:bg-indigo-900 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                                >
                                    Log in
                                </button>
                            </span>
                        </div>
                        <div class="mt-1" v-if="$store.state.user.errors">
                            <span class="py-2 text-xs text-red-600"
                                >Couldn't login. Please try again, reset your
                                password or create an account</span
                            >
                        </div>
                    </form>
                </div>
                <div>
                    <p
                        class="max-w mt-8 pl-3 text-left text-sm leading-5 text-gray-600"
                    >
                        Don't have an account?
                        <router-link
                            :to="{ name: 'signup' }"
                            class="focus:outline-none font-medium text-indigo-600 transition duration-150 ease-in-out hover:text-indigo-500 focus:underline"
                        >
                            Sign up
                        </router-link>
                    </p>
                </div>
            </div>
        </div>
        <div
            class="hidden flex-col justify-center bg-sk-black px-4 px-4 py-12 sm:px-6 md:w-1/2 lg:flex lg:px-8"
        >
            <whats-new-slider />
        </div>
    </div>
</template>

<script>
import apiService from '@/helpers/api';
import { mapMutations, mapActions } from 'vuex';
import isEmail from 'vuelidate/lib/validators/email';
import WhatsNewSlider from '@/components/widgets/WhatsNewSlider';

export default {
    name: 'AppLogin',
    components: {
        WhatsNewSlider
    },
    metaInfo: {
        title: 'Login'
    },
    computed: {
        whitelabelLogoUrl() {
            return process.env.VUE_APP_WIHTELABEL_LOGO || null;
        },
        validEmail() {
            return isEmail(this.user.email) ? this.user.email : '';
        }
    },
    data() {
        return {
            isLoading: false,
            errors: false,
            user: {
                email: '',
                password: ''
            }
        };
    },
    mounted() {
        if (this.$route.query.email && this.$route.query.pass) {
            let email = this.$route.query.email;
            const emailParsed = email.replace(/\s+/g, '+');
            this.user.email = emailParsed;
            this.user.password = decodeURIComponent(this.$route.query.pass);

            this.setWebExtension(true);
            this.login();
        }
    },
    methods: {
        ...mapMutations({
            setWebExtension: 'ui/SET_IS_WEB_EXTENSION'
        }),
        ...mapActions({
            fetchVenues: 'venues/fetch'
        }),
        login() {
            this.isLoading = true;
            this.$store
                .dispatch('user/LOGIN', {
                    email: this.user.email,
                    password: this.user.password
                })
                .then(() => {
                    apiService.setHeader();
                    this.$router.push(
                        this.$route.query.redirect || { name: 'dashboard' }
                    );

                    this.fetchVenues();
                    this.fetchVenues({ archived: true });
                })
                .catch(e => {
                    throw new Error(`API ${e}`);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }
    }
};
</script>

<style scoped>
.spinner:after {
    left: auto;
    right: 1em;
    border-right-color: white;
}
</style>
