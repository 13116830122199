<template>
    <div class="">
        <div class="overflow-hidden bg-white shadow sm:rounded-md border border-gray-100">
            <div class="flex flex-row p-2 px-2">
                <div class="flex-shrink-0">
                    <img alt="" />
                </div>
                <div class="px-2 py-2">
                    <div class="mb-2 flex items-center">
                        <h4
                            class="font-heading text-sm font-bold tracking-wide text-gray-600"
                        >
                            Request a feature</h4
                        >
                    </div>

                    <p class="mb-4 text-sm text-gray-600"
                        >Influence our product roadmap with your suggestions.
                        Visit our community feedback board to suggest or vote on
                        a feature that would make storekit better for you.
                    </p>
                    <router-link :to="{ name: 'feedback' }">
                      <base-button
                          button-text="Request a feature"
                          size="sm"
                      />
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FeatureRequestPrompt'
};
</script>
