<template>
    <div
        class="focus:outline-none relative z-0 flex-1 overflow-y-auto bg-sk-silver-grey pb-6"
    >
        <ToggleSidebarButton />

        <div
            class="glass relative mx-4 mt-4 bg-white px-4 shadow-sm sm:px-6 lg:px-8 print:hidden"
        >
            <div>
                <div class="sm:hidden">
                    <label for="tabs" class="sr-only">Select a report</label>
                    <select
                        @change="navigateToReport($event)"
                        id="tabs"
                        name="tabs"
                        class="focus:outline-none block w-full rounded-md border border-gray-300 px-3 py-2 pr-10 text-sm shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    >
                        <option value="feedbackOverview" selected
                            >Overview</option
                        >
                        <option value="feedbackAverage"
                            >Average over time</option
                        >
                    </select>
                </div>
                <div class="hidden sm:block">
                    <div>
                        <nav
                            class="-mb-px flex space-x-8 overflow-x-scroll"
                            aria-label="Tabs"
                        >
                            <router-link
                                :to="{ name: 'feedbackOverview' }"
                                active-class="border-black text-black font-medium"
                                class="flex-shrink-0 whitespace-nowrap border-b-2 border-transparent px-1 py-3 text-xs font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                            >
                                Overview
                            </router-link>
                            <router-link
                                :to="{ name: 'feedbackAverage' }"
                                active-class="border-black text-black font-medium"
                                class="flex-shrink-0 whitespace-nowrap border-b-2 border-transparent px-1 py-3 text-xs font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                            >
                                Average over time
                            </router-link>
                            <router-link
                                :to="{ name: 'smartReviews' }"
                                active-class="border-black text-black font-medium"
                                class="flex-shrink-0 whitespace-nowrap border-b-2 border-transparent px-1 py-3 text-xs font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                            >
                                Smart reviews
                            </router-link>
                        </nav>
                    </div>
                </div>
                <div class="absolute right-0 top-0 px-4 pt-6 sm:px-6 lg:px-8">
                    <div class="md:flex md:items-center md:justify-between">
                        <div
                            class="h-4 w-4"
                            :class="{ 'text-gray-900 spinner': isLoading }"
                        >
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="mx-auto py-4 sm:mt-0 sm:px-4 sm:px-6 md:mt-2"
            v-if="!isLoading && venues"
        >
            <div
                class="relative z-50 inline-block pb-8 text-left sm:float-right sm:pb-0"
            >
                <div>
                    <span class="rounded-sm shadow-sm">
                        <venue-selector
                            :venues="venues"
                            :multiple-select="true"
                            :allow-empty="true"
                            :pre-selected="
                                Array.isArray(venueFilter)
                                    ? venueFilter
                                    : parseInt(venueFilter)
                            "
                            @selected="setVenue"
                        />
                    </span>
                </div>
            </div>
            <router-view
                :selectedVenues="venueFilter"
                :venues="venues"
            ></router-view>
        </div>

        <div
            v-else
            class="mx-auto mt-10 p-4 px-4 sm:mt-0 sm:mt-12 sm:px-6 md:px-8"
        >
            <div
                class="mb-4 flex h-60 w-full items-center justify-center bg-white p-8 shadow sm:rounded-md"
            >
                <div class="w-48">
                    <ReportLoading />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ReportLoading from '../components/helpers/ReportLoading';
import ToggleSidebarButton from '../components/helpers/ToggleSidebarButton';
import VenueSelector from '../components/selectors/VenueSelector';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'FeedbackReports',
    metaInfo: {
        title: 'Feedback Reports'
    },
    components: {
        ReportLoading,
        ToggleSidebarButton,
        VenueSelector
    },
    data() {
        return {
            venueFilter: [],
            isLoading: true
        };
    },
    async created() {
        this.isLoading = true;

        if (!this.venues?.length > 0) {
            await this.fetchVenues();
        }

        if (this.venues?.length > 0) {
            this.venueFilter = [this.venues[0].id];
        }

        this.isLoading = false;
    },
    computed: {
        ...mapGetters({
            venues: 'venues/getVenues'
        })
    },
    methods: {
        ...mapActions({
            fetchVenues: 'venues/fetch'
        }),
        navigateToReport(event) {
            this.$router.push({ name: event.target.value });
        },
        venueById(id) {
            return this.venues.find(venue => venue.id === id);
        },
        setVenue(venueId) {
            this.venueFilter = venueId;
        }
    }
};
</script>
