<template>
    <modal
        name="created-venue-modal"
        @before-open="beforeOpen"
        width="500px"
        height="auto"
        :clickToClose="false"
        :adaptive="true"
    >
        <div
            class="fixed inset-x-0 bottom-0 px-4 pb-6 sm:inset-0 sm:flex sm:items-center sm:justify-center sm:p-0"
        >
            <div
                x-transition:enter="ease-out duration-300"
                x-transition:enter-start="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                x-transition:enter-end="opacity-100 translate-y-0 sm:scale-100"
                x-transition:leave="ease-in duration-200"
                x-transition:leave-start="opacity-100 translate-y-0 sm:scale-100"
                x-transition:leave-end="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                class="transform rounded-lg bg-white shadow-xl transition-all sm:w-full sm:max-w-md"
            >
                <div>
                    <div class="bg-white" v-if="newStore">
                        <div
                            class="flex items-center justify-between border-b border-gray-200 px-4 py-5 sm:px-6"
                        >
                            <div
                                class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100"
                            >
                                <svg
                                    class="h-6 w-6 text-green-600"
                                    stroke="currentColor"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M5 13l4 4L19 7"
                                    />
                                </svg>
                            </div>
                            <div class="ml-4">
                                <h3
                                    class="text-lg font-medium font-medium leading-6 text-gray-900"
                                >
                                    Success!
                                </h3>
                                <div>
                                    <p class="text-sm leading-4 text-gray-500">
                                        Your first store was created
                                        successfully, now let's create your
                                        product menu.
                                    </p>
                                </div>
                            </div>
                            <div
                                class="-mt-8 h-4 w-4"
                                :class="{ spinner: isLoading }"
                            >
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <div class="px-4 py-5 text-center sm:p-6">
                        <div
                            class="cursor-pointer rounded-sm bg-gray-50 p-4 shadow-inner"
                            @click="goToStore"
                        >
                            <div class="mx-auto p-4">
                                <qr-code
                                    class="mx-auto rounded-lg"
                                    :url="qrLink"
                                    :fileName="`${this.venueSlug}-qr-code`"
                                    ref="qr"
                                />
                            </div>
                            <span class="text-sm text-gray-600"
                                >Click, or scan the QR code on your mobile, to
                                visit your
                                <span v-if="newStore">new</span> store</span
                            >
                        </div>
                        <div class="mt-5 sm:mt-6" v-if="newStore">
                            <span class="inline-flex rounded-md shadow-sm">
                                <button
                                    type="button"
                                    :disabled="isLoading"
                                    @click="
                                        $router.push({
                                            name: 'createMenu',
                                            params: { venueId: venueId }
                                        })
                                    "
                                    class="focus:outline-none inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                                >
                                    Create your menu
                                </button>
                            </span>
                            <!--                            <span class="inline-flex rounded-md pl-4 shadow-sm">-->
                            <!--                                <button-->
                            <!--                                    type="button"-->
                            <!--                                    :disabled="isLoading"-->
                            <!--                                    @click="createDefaultMenu"-->
                            <!--                                    class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"-->
                            <!--                                >-->
                            <!--                                    Start with a default menu-->
                            <!--                                </button>-->
                            <!--                            </span>-->
                        </div>
                        <div class="mt-5 select-none sm:mt-6" v-else>
                            <span
                                @click="closeModal"
                                class="cursor-pointer px-4 py-2 text-sm font-medium leading-5 text-gray-700"
                            >
                                Close
                            </span>
                            <span class="inline-flex pl-4">
                                <div
                                    v-click-outside="onClickOutside"
                                    class="relative mr-4 select-none text-left md:mr-6 print:hidden"
                                >
                                    <button
                                        type="button"
                                        @click="
                                            showMoreActions = !showMoreActions
                                        "
                                        class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium leading-5 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
                                    >
                                        Download QR Code
                                    </button>
                                    <transition
                                        enter-active-class="transition ease-out duration-100"
                                        enter-class="transform opacity-0 scale-95"
                                        enter-to-class="transform opacity-100 scale-100"
                                        leave-active-class="transition ease-in duration-75"
                                        leave-class="transform opacity-100 scale-100"
                                        leave-to-class="transform opacity-0 scale-95"
                                    >
                                        <div
                                            v-if="showMoreActions"
                                            class="absolute bottom-12 left-0 z-50 mt-2 w-48 origin-top-right rounded-md shadow-lg"
                                        >
                                            <div
                                                class="overflow-hidden rounded-md bg-white shadow-sm"
                                                role="menu"
                                                aria-orientation="vertical"
                                                aria-labelledby="options-menu"
                                            >
                                                <span
                                                    class="focus:outline-none group flex w-full cursor-pointer items-center px-4 py-1 py-2 text-center text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900"
                                                    role="menuitem"
                                                    @click="saveQR('svg')"
                                                >
                                                    <download-icon
                                                        class="mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500"
                                                    />

                                                    Download as SVG
                                                </span>
                                                <div
                                                    class="border-t border-gray-100"
                                                ></div>
                                                <span
                                                    class="focus:outline-none group flex w-full cursor-pointer items-center px-4 py-1 py-2 text-center text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900"
                                                    role="menuitem"
                                                    @click="saveQR('png')"
                                                >
                                                    <download-icon
                                                        class="mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500"
                                                    />
                                                    Download as PNG
                                                </span>
                                            </div>
                                        </div>
                                    </transition>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import vClickOutside from 'v-click-outside';
import DownloadIcon from '@/components/icons/DownloadIcon';
import QrCode from '@/components/QrCode';

export default {
    name: 'CreatedVenueModal',
    data() {
        return {
            isLoading: false,
            venueId: null,
            venueSlug: null,
            newStore: null,
            showMoreActions: false
        };
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    components: {
        QrCode,
        DownloadIcon
    },
    methods: {
        async createDefaultMenu() {
            if (this.isLoading) {
                return;
            }

            this.isLoading = true;

            try {
                const { data } = await this.$axios.post('/menus/default', {
                    venueId: this.venueId
                });

                this.$router.push({
                    name: 'editMenu',
                    params: { id: data.id }
                });
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        },
        onClickOutside() {
            this.showMoreActions = false;
        },
        beforeOpen(event) {
            this.venueId = event.params.venueId;
            this.venueSlug = event.params.venueSlug;
            this.newStore = event.params.newStore;
        },
        goToStore() {
            window.open(this.qrLink, '_blank');
        },
        saveQR(type) {
            this.$refs.qr.download(type);
        },
        closeModal() {
            this.$modal.hide('created-venue-modal');
        }
    },
    computed: {
        publicHostname() {
            return process.env.VUE_APP_WHITELABEL_PUBLIC_HOSTNAME;
        },
        qrLink() {
            return `https://${this.publicHostname}/${this.venueSlug}/menu`;
        }
    }
};
</script>

<style>
.v--modal-overlay {
    background: rgba(0, 0, 0, 0.1);
}
</style>
