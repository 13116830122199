<template>
    <modal
        name="sync-products-with-centegra-modal"
        width="600px"
        height="auto"
        :adaptive="true"
        :clickToClose="true"
        @before-open="beforeOpen"
    >
        <div
            class="h-full bg-white rounded-lg shadow-xl overflow-scroll transform transition-all sm:w-full"
        >
            <div class="p-10 m-auto w-1/2">
                <button
                    class="m-auto w-full px-4 py-2 hover:text-indigo-500 text-indigo-700 text-base font-medium leading-6 bg-white border focus:border-indigo-300 border-indigo-300 rounded-md focus:outline-none focus:shadow-outline shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    @click="fetchProducts"
                >
                    Fetch your products from centegra
                </button>
            </div>

            <div v-if="diffProducts.length" class="m-auto w-1/2 text-center">
                <div class="mb-5">
                    Select Products that you want to synchronize with your menu
                </div>
                <div
                    v-for="diffProduct in diffProducts"
                    :key="diffProduct.ProductID"
                    class="border-4 border-blue-600 border-opacity-25 mt-2 p-2"
                    :class="
                        diffProduct.state === 'added'
                            ? 'bg-green-100'
                            : diffProduct.state === 'edited'
                            ? 'bg-yellow-100'
                            : 'bg-red-100'
                    "
                >
                    <div>
                        <div>
                            <span class="text-gray-700 font-medium">
                                Name:
                            </span>
                            {{ diffProduct.NameLong }}</div
                        >
                        <div>
                            <span class="text-gray-700 font-medium">
                                Description:
                            </span>
                            {{ diffProduct.SalesDescription }}
                        </div>
                        <div>
                            <span class="text-gray-700 font-medium">PLU:</span>
                            {{ diffProduct.PLU }}
                        </div>

                        <div>
                            <label
                                :for="`synchronize-${diffProduct.ProductID}`"
                                class="text-gray-700 font-medium"
                            >
                                Synchronize:
                            </label>
                            <input
                                v-model="diffProduct.synchronize"
                                :id="`synchronize-${diffProduct.ProductID}`"
                                type="checkbox"
                                class="form-checkbox w-4 h-4 text-indigo-600 transition duration-150 ease-in-out ml-1"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-else-if="productsFetched"
                class="m-auto w-1/2 text-center text-green-600"
            >
                <div class="mb-5"> Everything synchronized </div>
            </div>

            <div
                class="flex-row-reverse justify-between mb-4 mt-8 sm:flex sm:mt-6 sm:w-auto"
                :class="{ spinner: isLoading }"
            >
                <span
                    v-if="diffProducts.length"
                    class="mx-3 w-full rounded-md shadow-sm sm:ml-3 sm:mt-0 sm:w-auto"
                >
                    <button
                        type="button"
                        class="inline-flex justify-end px-4 py-2 w-full hover:text-gray-500 text-gray-700 text-base font-medium leading-6 bg-white border focus:border-blue-300 border-gray-300 rounded-md focus:outline-none focus:shadow-outline shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                        @click="saveProducts"
                    >
                        Save
                    </button>
                </span>

                <span
                    class="mx-3 w-full rounded-md shadow-sm sm:ml-3 sm:mt-0 sm:w-auto"
                >
                    <button
                        type="button"
                        class="inline-flex justify-center px-4 py-2 w-full hover:text-gray-500 text-gray-700 text-base font-medium leading-6 bg-white border focus:border-blue-300 border-gray-300 rounded-md focus:outline-none focus:shadow-outline shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                        @click="closeModal"
                    >
                        Cancel
                    </button>
                </span>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    name: 'SyncProductsWithCentegraModal',
    data() {
        return {
            isLoading: false,
            menuId: this.$route.params.id,
            categories: [],
            menuProducts: [],
            centegraProducts: [],
            diffProducts: [],
            productsFetched: false
        };
    },
    methods: {
        beforeOpen({ params }) {
            this.categories = params.categories;

            this.menuProducts = this.categories.flatMap(
                ({ products }) => products
            );

            this.menuProducts = [
                ...new Map(
                    this.menuProducts.map(menuProduct => [
                        menuProduct.id,
                        menuProduct
                    ])
                ).values()
            ];
        },
        closeModal() {
            this.$modal.hide('sync-products-with-centegra-modal');
        },
        async fetchProducts() {
            this.isLoading = true;

            try {
                const { data } = await this.$axios.get(`/centegra/products`, {
                    params: {
                        menuId: this.menuId
                    }
                });

                this.centegraProducts = data;

                this.centegraProducts = [
                    ...new Map(
                        this.centegraProducts.map(centegraProduct => [
                            centegraProduct.ProductID,
                            centegraProduct
                        ])
                    ).values()
                ];

                await this.showProductsDiff();

                this.productsFetched = true;
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        },
        showProductsDiff() {
            if (!this.centegraProducts.length) {
                return [];
            }

            this.diffProducts = this.menuProducts
                .map(menuProduct => {
                    menuProduct.synchronize = false;

                    const foundProduct = this.centegraProducts.find(
                        ({ ProductID }) =>
                            parseInt(menuProduct.posId) === ProductID
                    );

                    if (!foundProduct) {
                        menuProduct.NameLong = menuProduct.name;
                        menuProduct.SalesDescription = menuProduct.description;
                        menuProduct.PLU = menuProduct.plu;
                        menuProduct.ProductID = menuProduct.posId;
                        menuProduct.state = 'deleted';

                        return menuProduct;
                    }

                    const index = this.centegraProducts.findIndex(
                        ({ ProductID }) =>
                            parseInt(menuProduct.posId) === ProductID
                    );

                    this.centegraProducts.splice(index, 1);

                    const isDiff =
                        menuProduct.name !== foundProduct.NameLong ||
                        (foundProduct.SalesDescription &&
                            menuProduct.description !==
                                foundProduct.SalesDescription) ||
                        menuProduct.plu !== foundProduct.PLU;

                    if (isDiff) {
                        menuProduct.NameLong = foundProduct.NameLong;
                        menuProduct.SalesDescription =
                            menuProduct.SalesDescription || '';
                        menuProduct.PLU = foundProduct.PLU;
                        menuProduct.ProductID = foundProduct.ProductID;
                        menuProduct.state = 'edited';
                    }

                    return isDiff ? menuProduct : null;
                })
                .filter(diffProduct => diffProduct);

            this.centegraProducts.forEach(centegraProduct => {
                centegraProduct.state = 'added';
            });

            this.diffProducts = [
                ...this.diffProducts,
                ...this.centegraProducts
            ];
        },
        async saveProducts() {
            this.isLoading = true;

            const products = this.diffProducts
                .map(diffProduct => {
                    return diffProduct.synchronize ? diffProduct : null;
                })
                .filter(diffProduct => diffProduct);

            try {
                await this.$axios.post(`/centegra/synchronize-products`, {
                    products,
                    menuId: this.menuId
                });

                this.$emit('update');

                this.closeModal();
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        }
    }
};
</script>
