import Knock from '@knocklabs/client';

const knockClient = new Knock(process.env.VUE_APP_KNOCK_PUBLIC_KEY);

export const knockFeed = (userId, token) => {
    knockAuth(userId, token);

    const knockFeed = knockClient.feeds.initialize(
        process.env.VUE_APP_KNOCK_CHANNEL_ID
    );

    knockFeed.listenForUpdates();
    knockFeed.fetch();

    return knockFeed;
};

const knockAuth = (userId, token) => {
    knockClient.authenticate(userId, token);
};

export default { knockFeed };
