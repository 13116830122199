<template>
  <div v-auto-animate>
    <div
        @click="showDetails = !showDetails;fetchBatchDetails()"
        class="px-2 py-2 cursor-pointer select-none sm:px-4 sm:py-3 text-gray-700 flex justify-between"
    >
      <div class="flex items-center">
        <base-stacked-cards-icon class="w-5 h-5 "/>
        <span class="text-sm ml-2">Order batch</span>
      </div>
      <base-chevron-icon
          class="-ml-1 -mr-1 sm:ml-2 w-4 h-4"
          :class="{ 'rotate-180': showDetails }"
      />
    </div>
    <div class="px-2 pb-2 sm:px-4 sm:pb-3 text-gray-700 text-sm pl-10" v-if="showDetails" :class="{ 'spinner': isLoading }">
      <span>This order was combined with {{batchDetails.length -1 }} other orders</span>
      <ul>
        <li v-for="(order) in filteredBatchDetails" :key="order.id">
          <span class="">#{{order.code}}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
name: 'OrderBatchDetails',
  props: {
    orderId: {
      type: String,
      required: true
    },
    batchId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showDetails: false,
      batchDetails: [],
      isLoading: false
    }
  },
  methods: {
    async fetchBatchDetails() {
      try {
        this.isLoading = true;
        const { data } = await this.$axios.get(`/orders/batches/${this.batchId}/private`);
        this.batchDetails = data;
      } catch (error) {
        throw Error(error)
      } finally {
        this.isLoading = false;
      }
    },
  },
  computed: {
      filteredBatchDetails() {
        return this.batchDetails.filter(order => order.id !== this.orderId)
      }
  }
}
</script>