var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"-mx-4 mt-2 bg-white ring-1 ring-gray-300 sm:-mx-6 md:mx-0 md:rounded-lg"},[_c('table',{staticClass:"min-w-full divide-y divide-gray-300"},[_vm._m(0),_c('tbody',[_vm._l((_vm.orders),function(order){return _c('tr',{key:order.id},[_c('td',{staticClass:"relative py-4 pl-4 pr-3 text-xs sm:pl-6 sm:text-sm"},[_c('div',{staticClass:"font-medium text-gray-900"},[_vm._v(_vm._s(_vm._f("moment")(order.created_at,'h:mm a')))])]),_c('td',{staticClass:"px-3 py-3.5 text-sm text-gray-500"},[(order.code)?_c('span',{staticClass:"font-mono font-bold"},[_vm._v(" "+_vm._s(order.code)+" ")]):_vm._e()]),_c('td',{staticClass:"px-3 py-3.5 text-sm text-gray-500"},[_c('div',[_vm._v(_vm._s(_vm._f("currency")((order.total / 100))))])]),_c('td',{staticClass:"px-3 py-3.5 text-sm text-gray-500"},[_c('router-link',{attrs:{"to":{
                                name: 'orderDetail',
                                params: {
                                    id: order.id
                                }
                            }}},[_c('button',{staticClass:"focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",attrs:{"type":"button"}},[_vm._v(" View order ")])])],1)])}),(!_vm.orders.length)?_c('tr',[_vm._m(1)]):_vm._e()],2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticClass:"py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6",attrs:{"scope":"col"}},[_vm._v("Time")]),_c('th',{staticClass:"table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900",attrs:{"scope":"col"}},[_vm._v("Code")]),_c('th',{staticClass:"px-3 py-3.5 text-left text-sm font-semibold text-gray-900",attrs:{"scope":"col"}},[_vm._v("Total")]),_c('th',{staticClass:"relative py-3.5 pl-3 pr-4 sm:pr-6",attrs:{"scope":"col"}},[_c('span',{staticClass:"sr-only"},[_vm._v("View")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"relative w-full rounded-lg p-12 text-center",attrs:{"colspan":"7"}},[_c('span',{staticClass:"mt-2 block text-sm font-medium text-gray-900"},[_vm._v(" No orders yet ")])])
}]

export { render, staticRenderFns }