<template>
    <div
        class="flex w-full items-center justify-between text-xs font-medium leading-5 tracking-wide text-gray-700"
    >
        <div class="flex h-10 flex-col justify-center overflow-hidden pl-1">
            <label
                :for="keyName"
                class="text-xs font-medium leading-5 tracking-wide text-gray-700"
                :class="value ? 'line-clamp-1' : 'line-clamp-2'"
            >
                {{ title }}
            </label>
            <div v-if="value" class="flex items-center gap-1">
                <span class="uppercase">{{ value }}</span>
                <div
                    class="cursor-pointer rounded hover:bg-gray-200"
                    v-tooltip="'Reset to default'"
                    @click="reset"
                >
                    <base-small-close-icon />
                </div>
            </div>
        </div>
        <div class="bg-gray-300 p-px" style="border-radius: 10px">
            <v-swatches
                v-model="computedValue"
                swatch-size="30"
                :trigger-style="{
                    width: '26px',
                    height: '26px'
                }"
                show-fallback
                class="flex items-center justify-center"
                :swatches="uniqueColours"
                fallback-input-type="color"
                fallback-ok-text="Select"
                popover-x="left"
                show-border
                @input="onChange"
            />
        </div>
    </div>
</template>

<script>
import VSwatches from 'vue-swatches';

export default {
    props: {
        value: {
            type: [String, null],
            required: false,
            default: null
        },
        title: {
            type: String,
            required: true
        },
        keyName: {
            type: String,
            required: true
        },
        defaultColour: {
            type: String,
            required: false,
            default: '#ffffff'
        }
    },
    components: {
        VSwatches
    },
    data() {
        return {
            colorSwatches: [
                this.defaultColour,
                '#000000',
                '#222831',
                '#ffffff',
                '#393e46',
                '#583d72',
                '#9f5f80',
                '#99c1de',
                '#84a59d',
                '#ffd369',
                '#f5cac3',
                '#ff8e71'
            ]
        };
    },
    computed: {
        computedValue: {
            get() {
                return this.value || this.defaultColour;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        uniqueColours() {
            return [...new Set(this.colorSwatches)];
        }
    },
    methods: {
        onChange(colour) {
            this.$emit('change', this.keyName, colour);
        },
        reset() {
            this.computedValue = null;

            this.onChange(null);
        }
    }
};
</script>
