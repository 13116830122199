<template>
    <div v-if="!isLoading && category">
        <div class="mt-3 text-left sm:mt-5">
            <div class="mt-3">
                <base-input-field
                    type="text"
                    label="Name"
                    tooltip="The category name is visible on your menu"
                    id="name"
                    placeholder="e.g. Pizza"
                    v-model.trim="category.name"
                    :errors="$v.category.name.$error"
                />
            </div>
            <div class="mt-3">
                <label
                    for="description"
                    class="block text-sm font-medium leading-5 text-gray-700"
                >
                    Description
                </label>
                <div class="relative mt-1 rounded-md shadow-sm">
                    <textarea
                        id="description"
                        v-model="category.description"
                        rows="3"
                        class="form-input block w-full sm:text-sm sm:leading-5"
                    />
                </div>
            </div>
            <div
              class="flex items-center mt-5"
              >
              <base-small-toggle-switch
                  v-model="category.hiddenInMenu"
              />
              <label
                  v-tooltip="'Hides this category from the top navigation bar, it will still be visible on the menu'"
                  class="ml-2 flex items-center text-sm font-medium text-gray-500"
              >
                Hide from navigation
                <base-question-icon class="ml-1 text-gray-700" />
              </label>
            </div>
            <div
                class="mt-5"
                v-if="
                    isFeatureAvailable(FeaturesEnum.INTERNAL) ||
                    isFeatureAvailable(FeaturesEnum.SAAS) ||
                    isFeatureAvailable(FeaturesEnum.CATEGORY_IMAGE)
                "
            >
                <div class="flex flex-row items-center justify-between">
                    <label
                        for="image"
                        class="block text-sm font-medium leading-5 text-gray-700"
                    >
                        Image
                        <span class="text-gray-400"> (optional) </span>
                    </label>
                    <span
                        @click="category.image = ''"
                        class="transition-duration-100 cursor-pointer pl-14 text-red-400 transition hover:text-red-700"
                        v-if="category.image"
                    >
                        <svg
                            class="h-5 w-5"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                            ></path>
                        </svg>
                    </span>
                </div>
                <div class="w-full">
                    <div>
                        <uploadcare-inline
                            :ctxName="`${ctxName}category_settings_image`"
                            inputAcceptTypes="image/*"
                            tabs="local, instagram, dropbox, url, gdrive"
                            @success="onImageSuccess"
                        >
                            <div
                                v-if="category.image"
                                class="mt-2 p-3 flex justify-center rounded-md border-2 border-dashed border-gray-300"
                            >
                                <div class="w-auto">
                                    <div
                                        class="relative h-36 w-36 max-w-md rounded-sm bg-cover bg-center"
                                        :style="`background-image: url(${category.image}-/resize/288x/-/format/auto/)`"
                                    >
                                    </div>
                                </div>
                            </div>
                        </uploadcare-inline>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="mt-5 justify-between sm:mt-6 sm:flex sm:w-auto"
            :class="{ spinner: isLoading }"
        >
            <span class="flex rounded-md shadow-sm">
                <button
                    v-if="isImageReady"
                    @click="saveCategory"
                    :disabled="isLoading"
                    type="button"
                    class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo sm:text-sm sm:leading-5"
                >
                    Save changes
                </button>
                <button
                    v-else
                    class="inline-flex w-full justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium leading-6 text-white shadow-sm sm:text-sm sm:leading-5"
                >
                    File uploading
                </button>
            </span>
            <span
                class="mt-3 flex w-full rounded-md shadow-sm sm:ml-3 sm:mt-0 sm:w-auto"
            >
                <button
                    @click="close"
                    type="button"
                    class="focus:outline-none inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium leading-6 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline sm:text-sm sm:leading-5"
                >
                    Close
                </button>
            </span>
        </div>
    </div>
</template>

<script>
import UploadcareInline from '@/components/widgets/UploadcareInline';
import { required } from 'vuelidate/lib/validators';
import { FeaturesEnum } from '@/enums';
import { mapGetters } from 'vuex';

export default {
    name: 'CategoryDetailSettings',
    validations: {
        category: {
            name: {
                required
            }
        }
    },
    props: {
        category: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isLoading: false,
            FeaturesEnum,
            isImageReady: true,
            ctxName: this.category.id
        };
    },
    components: { UploadcareInline },
    computed: {
        ...mapGetters({ isFeatureAvailable: 'user/isFeatureAvailable' })
    },
    methods: {
        async saveCategory() {
            this.$v.category.$touch();

            if (this.$v.category.$invalid) {
                this.errors = 'ERROR';

                return;
            }

            this.isLoading = true;
            const { name, description, id, image, hiddenInMenu } = this.category;

            try {
                await this.$axios.put(`/categories/${id}`, {
                    name,
                    description,
                    image,
                    hiddenInMenu
                });

                this.update();
                this.close();
                this.$notify({
                    group: 'settings',
                    title: 'Settings saved'
                });
            } catch (error) {
                this.$notify({
                    group: 'settings',
                    title: 'An error occurred'
                });
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        },
        close() {
            this.$emit('close');
        },
        update() {
            this.$emit('update');
        },
        onImageSuccess(event) {
            this.category.image = event.cdnUrl;
        },
        onImageError(event) {
            throw new Error('Error: ', event);
        },
        imageProgress(uploadInfo) {
            if (uploadInfo.state === 'uploading') {
                this.isImageReady = false;
            }

            if (uploadInfo.state === 'ready') {
                this.isImageReady = true;
            }
        }
    }
};
</script>
