var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.isLoading)?_c('div',{staticClass:"mb-4 overflow-hidden rounded-lg bg-white shadow"},[_c('div',{staticClass:"grid grid-cols-6 gap-3 px-4 py-5 sm:p-6"},[_c('div',{staticClass:"group col-span-6 rounded-sm"},[_c('div',{},[_vm._m(0),_vm._m(1),_vm._m(2),_c('div',{staticClass:"mt-3 flex justify-end"},[_c('div',{staticClass:"flex items-center justify-between"},[_vm._m(3),_c('toggle-switch',{attrs:{"confirm":"","confirm-title":_vm.active
                                        ? 'Confirm disabling Klarna payments'
                                        : 'Confirm enabling Klarna payments',"confirm-description":_vm.active
                                        ? 'To confirm disabling Klarna payments, please click the confirm button shown below. You\'ll still be able to process refunds for previous klarna transactions.'
                                        : 'To confirm enabling Klarna payments, please click the confirm button shown below. Klarna will be immediately available as a payment method at checkout.'},on:{"input":function($event){return _vm.toggleKlarna()}},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}})],1),_c('button',{staticClass:"focus:outline-none ml-4 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",attrs:{"type":"button"},on:{"click":function($event){return _vm.openKlarnaModal()}}},[_vm._v(" Learn more ")])])])])])]):_c('div',{staticClass:"mb-4 overflow-hidden rounded-lg bg-white p-5 shadow"},[_c('VclList',{attrs:{"width":250,"height":200}})],1),_c('klarna-modal')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3"},[_c('img',{staticClass:"mb-2 w-14",attrs:{"src":"https://ucarecdn.com/3e6f845d-0f9b-4e99-b1c7-74d8cd817723/-/format/auto/","alt":"Enable Klarna payments"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"mb-4 text-sm text-gray-800"},[_vm._v(" Give your customers the ability to checkout with Klarna. You always get paid in full and upfront, your customers pay at a later date. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-row"},[_c('div',{staticClass:"w-1/2"},[_c('p',{staticClass:"text-sm font-medium text-gray-700"},[_vm._v(" Klarna rate ")]),_c('span',{staticClass:"block py-2 text-sm text-gray-500"},[_c('div',[_vm._v(" 3.9% + 20p ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"flex flex-col"},[_c('span',{staticClass:"mr-3 text-sm font-medium text-gray-700",attrs:{"id":"availability-label"}},[_vm._v("Accept Klarna")])])
}]

export { render, staticRenderFns }