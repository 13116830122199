<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 80 80"
        width="24px"
        height="24px"
    >
        <path
            fill="#fff"
            d="M6.5 75.5L6.5 4.5 43.793 4.5 61.5 22.207 61.5 75.5z"
        />
        <path
            fill="#788b9c"
            d="M43.586,5L61,22.414V75H7V5H43.586 M44,4H6v72h56V22L44,4L44,4z"
        />
        <path
            fill="#fff"
            d="M42.5 23.5L42.5 4.5 43.793 4.5 61.5 22.207 61.5 23.5z"
        />
        <path
            fill="#788b9c"
            d="M43.586,5L61,22.414V23H43V5H43.586 M44,4h-2v20h20v-2L44,4L44,4z"
        />
        <path
            fill="#bae0bd"
            d="M14 18h8v-2h-8V18zM28 16v2h12v-2H28zM14 22h8v-2h-8V22zM28 20v2h12v-2H28zM14 26h8v-2h-8V26zM46 24v2h8v-2H46zM28 26h12v-2H28V26zM14 60h8v-2h-8V60zM46 58v2h8v-2H46zM28 60h12v-2H28V60zM14 64h8v-2h-8V64zM46 62v2h8v-2H46zM28 64h12v-2H28V64zM14 68h8v-2h-8V68zM46 66v2h8v-2H46zM28 68h12v-2H28V68z"
        />
        <path fill="#5e9c76" d="M6 28H62V56H6z" />
        <path fill="#bae0bd" d="M57 43H62V56H57z" />
        <path
            fill="#fff"
            d="M23.523,36.803v1.081h-3.598v-0.919c0-1.66-1.541-2.003-2.224-2.003	c-0.894,0-2.222,0.216-2.222,2.247l0,9.853c0,0.561,0.019,1.977,2.188,1.977c2.045,0,2.259-1.569,2.259-1.948v-0.949h3.598v1.193	c0,1.585-1.594,4.465-5.715,4.465c-0.683,0-5.928-0.073-5.928-4.547l0-10.614c0-1.972,1.677-4.439,5.857-4.439	C22.816,32.2,23.523,35.731,23.523,36.803z M34.679,32.2c-5.991,0-6.175,4.556-6.175,5.279c0,3.743,2.364,4.468,3.916,4.956	l2.576,0.811c1.606,0.569,2.081,0.673,2.081,2.923c0,2.332-0.679,2.87-2.611,2.87c-2.06,0-2.54-1.14-2.54-1.977v-0.864l-3.598,0	v1.028c0,3.343,3.806,4.574,6.138,4.574c4.832,0,6.209-2.645,6.209-5.658c0-3.873-1.685-4.579-3.775-5.279l-2.752-0.84	c-2.083-0.633-1.958-1.348-1.993-1.61c-0.034-0.261-0.054-0.582-0.054-0.961c0-2.514,1.941-2.491,2.435-2.491	c2.236,0,2.54,1.698,2.54,2.33v0.62l3.598,0v-1.351C40.676,35.096,39.093,32.2,34.679,32.2z M54.007,32.362l-2.894,13.457h-0.071	l-2.858-13.457l-3.809,0l5.114,19.277h3.175l5.151-19.277L54.007,32.362z"
        />
        <path
            fill="#d6e3ed"
            d="M61 46L61 75 58.007 75 58.797 76 62 76 62 46z"
        />
        <g>
            <path
                fill="#9cacbd"
                d="M49.041 60.5L58.5 60.5 58.5 44.5 69.5 44.5 69.5 60.5 78.96 60.5 64 79.199z"
            />
            <path
                fill="#36404d"
                d="M69,45v15v1h1h7.919L64,78.399L50.081,61H58h1v-1V45H69 M70,44H58v16H48l16,20l16-20H70V44L70,44z"
            />
        </g>
    </svg>
</template>

<script>
export default {
    name: 'BaseCsvFileIcon'
};
</script>

<style scoped></style>
