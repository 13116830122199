<template>
  <div class="pb-4 pt-6">
    <div class="overflow-hidden grid grid-cols-4 p-4 gap-4 bg-white shadow sm:rounded-md border border-gray-100">
      <div class="col-span-4 bg-sk-silver-grey rounded-md overflow-hidden flex">
        <div class="p-4">
          <h6 class="text-gray-700 font-heading font-semibold text-sm">Get more from storekit</h6>
          <p class="text-xs text-gray-600">Browse our support docs to learn about all our features and expand your hospitality offering</p>
          <base-button class="mt-2" button-text="Browse docs" size="sm" href="https://intercom.help/storekit--support/en/" />
        </div>
        <div class="h-32">
          <img src="https://ucarecdn.com/543db6b5-032b-4928-b18b-1fa46f836524/-/format/auto/-/resize/400x/" alt="">
        </div>
      </div>
      <div class=" col-span-4 sm:col-span-2 bg-sk-silver-grey p-4 rounded-md">
        <p class="text-xs text-gray-600">Talk to our support team through live chat</p>
        <base-button button-text="Live chat" size="sm" class="mt-2" @clicked="openIntercom" />
      </div>
<!--      <div class="hidden col-span-3 sm:col-span-1 bg-sk-silver-grey p-4 rounded-md">-->
<!--        <p class="text-xs text-gray-600">Phone</p>-->
<!--        <base-button button-text="Phone call" size="sm" class="mt-2" />-->
<!--      </div>-->
      <div class=" col-span-4 sm:col-span-2 bg-sk-silver-grey p-4 rounded-md"
           :class="{ 'opacity-50 cursor-not-allowed': !isFeatureAvailable(FeaturesEnum.SAAS) }"
            v-tooltip="!isFeatureAvailable(FeaturesEnum.SAAS) ? 'Whatsapp support requires a storekit+ plan' : '' ">
        <p class="text-xs text-gray-600">Talk to our support team through whatsapp</p>
        <base-button button-text="Whatsapp chat" size="sm" class="mt-2" :class="{ 'pointer-events-none': !isFeatureAvailable(FeaturesEnum.SAAS) }" href="https://wa.me/442033189890">
          <template #left-icon>
            <base-whatsapp-icon src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" class="-ml-1 mr-2 h-5 w-5" />
          </template>
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import {FeaturesEnum} from '@/enums';
import BaseButton from "@/components/base/BaseButton.vue";
import {mapGetters} from "vuex";

export default {
  name: 'supportRequestCard',
  components: { BaseButton },
  data() {
    return {
      FeaturesEnum
    };
  },
  computed: {
    ...mapGetters({
      isFeatureAvailable: 'user/isFeatureAvailable'
    })
  },
  methods: {
    openIntercom() {
      if (window.Intercom) {
        window.Intercom(
            'showNewMessage'
        );
      } else {
        window.alert(
            'Please disable your AdBlocker to use live chat support.'
        );
      }
    },
  }
};
</script>
