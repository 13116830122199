export default class DiscountTypeEnum {
    static get FIXED() {
        return 'fixed';
    }

    static get PERCENT() {
        return 'percent';
    }

    static get FREE_DELIVERY() {
        return 'freedelivery';
    }

    static get BUY_ONE_GET_ONE_FREE() {
        return 'buyonegetonefree';
    }

    static get options() {
        return {
            FIXED: this.FIXED,
            PERCENT: this.PERCENT,
            FREE_DELIVERY: this.FREE_DELIVERY,
            BUY_ONE_GET_ONE_FREE: this.BUY_ONE_GET_ONE_FREE
        };
    }
}
