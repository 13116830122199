<template>
    <div class="flex w-0 flex-1 flex-col overflow-hidden">
        <ToggleSidebar />

        <main
            class="focus:outline-none relative z-0 flex-1 overflow-y-auto"
            tabindex="0"
        >
            <div class="pb-6 pt-2 md:py-6">
                <div class="mx-auto px-4 sm:px-6 lg:px-8">
                    <div class="md:flex md:items-center md:justify-between">
                        <div class="min-w-0 flex-1 flex items-center">
                            <BasePageTitle title="Banners" />
                        </div>
                        <div
                            class="flex justify-end sm:mr-4 md:ml-4"
                        >
                          <base-button
                              size="sm"
                              button-text="Create Banner"
                              @clicked="$router.push({ name: 'newOffer' })"
                              class="ml-3"
                              button-style="action"
                          />
                        </div>
                        <BaseSpinner :isLoading="isLoading" />
                    </div>
                </div>

                <div class="mx-auto px-0 py-4 sm:px-6 md:px-8">
                    <div class="rounded bg-white shadow-md sm:rounded-lg">
                        <div
                            class="flex flex-col justify-between px-2 py-2.5 sm:flex-row sm:px-6"
                        >
                            <ul class="mb-2 flex flex-row items-center sm:mb-0 overflow-scroll">
                                <li
                                    @click="
                                        updateParams({
                                            status: filter.value,
                                            page: 1
                                        })
                                    "
                                    class="mr-1 cursor-pointer rounded-md px-3 py-1 text-xs transition duration-150 ease-in-out hover:bg-gray-50"
                                    :class="{
                                        'bg-gray-100':
                                            filter.value === serverParams.status
                                    }"
                                    v-for="filter in offersFilters"
                                    :key="filter.value"
                                >
                                    {{ filter.label }}
                                </li>
                            </ul>
                            <div class="flex">
                                <search-input
                                    class="text-gray-700 sm:mr-3"
                                    v-model="searchString"
                                    :min="3"
                                    :max="9"
                                    size="sm"
                                    placeholder="Search by name"
                                />

                                <venue-selector
                                    :venues="venues"
                                    :multiple-select="true"
                                    :allow-empty="true"
                                    :key="venues ? venues.length : 0"
                                    size="sm"
                                    @selected="onVenueChange"
                                />
                            </div>
                        </div>
                        <vue-good-table
                            mode="remote"
                            :columns="columns"
                            :rows="offers"
                            styleClass="tailwind-table"
                            class="border-b border-gray-200"
                            row-style-class="mb-1 cursor-pointer"
                            @on-row-click="goToOfferDetails"
                            @on-page-change="onPageChange"
                            @on-sort-change="onSortChange"
                            @on-per-page-change="onPerPageChange"
                            :totalRows="totalRecords"
                            :pagination-options="{
                                enabled: true,
                                perPage: serverParams.limit,
                                dropdownAllowAll: false,
                                rowsPerPageLabel: '',
                                perPageDropdown: [10, 20, 50, 100, 200],
                                setCurrentPage: serverParams.page
                            }"
                        >
                            <template slot="table-row" slot-scope="props">
                                <status-badge
                                    v-if="props.column.field === 'archived'"
                                    :status="offerStatus(props.row)"
                                />
                                <span
                                    v-else-if="
                                        props.column.field === 'mobileImage'
                                    "
                                    class=""
                                >
                                    <img
                                        :src="
                                            props.formattedRow[
                                                props.column.field
                                            ]
                                        "
                                        alt=""
                                        class="h-14 w-auto rounded-md shadow-sm"
                                    />
                                </span>
                                <span v-else>
                                    {{ props.formattedRow[props.column.field] }}
                                </span>
                            </template>
                            <template slot="emptystate">
                                <div
                                    class="flex h-40 w-full items-center justify-center bg-white"
                                >
                                    <h3
                                        v-if="!isLoading && totalRecords === 0"
                                        class="text-sm font-medium text-gray-700"
                                    >
                                        No banners found for the selected options
                                    </h3>
                                    <span v-else class="spinner"></span>
                                </div>
                            </template>
                            <template slot="loadingContent">
                                <div
                                    class="flex h-40 w-full items-center justify-center bg-white"
                                >
                                    <span class="spinner"></span>
                                </div>
                            </template>
                        </vue-good-table>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import ToggleSidebar from '../components/common/ToggleSidebar';
import VenueSelector from '../components/selectors/VenueSelector';
import { mapActions, mapGetters } from 'vuex';
import SearchInput from '@/components/widgets/SearchInput';
import StatusBadge from '@/components/StatusBadge';

export default {
    name: 'OffersList',
    metaInfo: {
        title: 'Offers'
    },
    components: {
        StatusBadge,
        SearchInput,
        ToggleSidebar,
        VenueSelector
    },
    data() {
        return {
            offers: [],
            isLoading: true,
            offersFilters: [
                {
                    label: 'All',
                    value: 'all'
                },
                {
                    label: 'Active',
                    value: 'active'
                },
                {
                    label: 'Scheduled',
                    value: 'scheduled'
                },
                {
                    label: 'Expired',
                    value: 'expired'
                },
                {
                    label: 'Archived',
                    value: 'archived'
                }
            ],
            filter: 'All',
            columns: [
                {
                    label: 'Name',
                    field: 'name',
                    sortable: false,
                    tdClass:
                        'px-6 py-2 whitespace-nowrap text-sm leading-5 font-medium text-gray-900',
                    thClass:
                        'px-6 bg-gray-100 py-2 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600'
                },
                {
                    label: 'Image',
                    field: 'mobileImage',
                    sortable: false,
                    tdClass:
                        'px-6 py-2 whitespace-nowrap text-sm leading-5 font-medium text-gray-900',
                    thClass:
                        'px-6 bg-gray-100 py-2 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600'
                },
                {
                    label: 'Status',
                    field: 'archived',
                    sortable: true,
                    firstSortType: 'desc',
                    type: 'boolean',
                    tdClass:
                        'px-6 py-2 whitespace-nowrap text-sm leading-5 font-medium text-gray-900',
                    thClass:
                        'px-6 bg-gray-100 py-2 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600'
                },
                {
                    label: 'Created',
                    field: 'created_at',
                    sortable: true,
                    firstSortType: 'desc',
                    type: 'date',
                    dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
                    dateOutputFormat: 'MMM do yy',
                    tdClass:
                        'px-6 py-2 whitespace-nowrap text-sm leading-5 font-medium text-gray-900',
                    thClass:
                        'px-6 bg-gray-100 py-2 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600'
                },
                {
                    label: 'Updated',
                    field: 'updated_at',
                    sortable: true,
                    firstSortType: 'desc',
                    type: 'date',
                    dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
                    dateOutputFormat: 'MMM do yy',
                    tdClass:
                        'px-6 py-2 whitespace-nowrap text-sm leading-5 font-medium text-gray-900',
                    thClass:
                        'px-6 bg-gray-100 py-2 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600'
                }
            ],
            venueFilter: '',
            searchString: '',
            totalRecords: 0,
            serverParams: {
                page: 1,
                limit: 20,
                sortBy: '',
                sortOrder: '',
                venueIds: null,
                status: 'all',
                q: ''
            }
        };
    },
    computed: {
        ...mapGetters({
            isFeatureAvailable: 'user/isFeatureAvailable',
            venues: 'venues/getVenues'
        })
    },
    async created() {
        if (!this.venues?.length > 0) {
            await this.fetchVenues();
        }
        await this.fetchOffers();
    },
    watch: {
        searchString(value) {
            const searchString = value.toLowerCase();

            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }

            this.timer = setTimeout(() => {
                const searchValueConditions =
                    searchString.length >= 3 &&
                    searchString.length <= 30 &&
                    this.serverParams.q !== searchString;
                const clearSearchResults = !searchString && this.serverParams.q;

                if (searchValueConditions || clearSearchResults) {
                    this.updateParams({ q: searchString });
                }
            }, 600);
        }
    },
    methods: {
        ...mapActions({
            fetchVenues: 'venues/fetch'
        }),
        goToOfferDetails(params) {
            this.$router.push({
                name: 'editOffer',
                params: { id: params.row.id }
            });
        },
        async fetchOffers() {
            this.isLoading = true;
            try {
                const {
                    data: { results, total }
                } = await this.$axios.get('/offers', {
                    params: this.serverParams
                });

                this.totalRecords = total;
                this.offers = results;
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
            this.fetchOffers();
        },
        onPageChange(params) {
            this.updateParams({ page: params.currentPage });
        },
        onPerPageChange(params) {
            this.updateParams({ limit: params.currentPerPage });
        },
        onVenueChange(venueIds) {
            this.updateParams({ venueIds, page: 1 });
            this.venueFilter = venueIds;
        },
        onSortChange(params) {
            this.updateParams({
                page: 1,
                sortOrder: params[0].type,
                sortBy: params[0].field
            });
        },
        offerStatus(offer) {
            if (!offer.archived) {
                const date = new Date();
                if (new Date(offer.startDate) > date) {
                    return 'Scheduled';
                } else if (offer.endDate && new Date(offer.endDate) < date) {
                    return 'Expired';
                } else {
                    return 'Active';
                }
            }
            return 'Archived';
        }
    }
};
</script>
