<template>
    <div class="p-2">
        <span class="text-gray-600 whitespace-pre-line">
            {{ comments }}
        </span>
    </div>
</template>

<script>
export default {
    name: 'ReviewCommentsTooltip',
    props: {
        comments: String
    }
};
</script>

<style>
.v-popper__popper.review-comments-popover .v-popper__wrapper .v-popper__inner {
    width: 400px;
    max-height: 300px;
    background: #fff;
    padding: 0.5rem;
    color: black;
    border-radius: 5px;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
}

.v-popper__popper.review-comments-popover .v-popper__arrow-inner {
    border-color: #f9f9f9;
}
</style>
