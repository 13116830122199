<template>
    <div>
        <label v-if="labelText" class="block text-sm font-medium text-gray-700">
            {{ labelText }}
        </label>
        <span @click="upgradeRequest" class="inline-flex rounded-md shadow-sm">
            <button
                type="button"
                :class="sizeClass"
                :disabled="disabled"
                class="focus:outline-none inline-flex items-center rounded-md border border-gray-800 bg-sk-black font-medium leading-5 text-white transition duration-150 ease-in-out hover:opacity-90 focus:border-indigo-600 focus:shadow-outline-indigo active:bg-gray-50"
            >
                <svg
                    v-show="!isLoading"
                    class="mr-2 h-5 w-5"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                >
                    <path
                        clip-rule="evenodd"
                        fill-rule="evenodd"
                        d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm.53 5.47a.75.75 0 00-1.06 0l-3 3a.75.75 0 101.06 1.06l1.72-1.72v5.69a.75.75 0 001.5 0v-5.69l1.72 1.72a.75.75 0 101.06-1.06l-3-3z"
                    ></path>
                </svg>
                {{ isLoading ? 'Loading...' : buttonText }}
            </button>
        </span>
    </div>
</template>

<script>
export default {
    name: 'BaseUpsellButton',
    props: {
        buttonText: {
            type: String,
            required: true
        },
        labelText: {
            type: String,
            default: null
        },
        size: {
            type: String,
            default: 'md'
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        upsellItem: {
            type: String,
            required: true
        },
        venueId: {
          type: Number,
          required: false
        }
    },
   data() {
      return {
        isLoading: false
        }
     },
    computed: {
        sizeClass() {
            if (this.size === 'lg') {
                return 'w-7 h-7';
            } else if (this.size === 'sm') {
                return 'px-2 py-1 text-xs';
            }

            return 'px-4 py-2 text-sm';
        }
    },
    methods: {
        async upgradeRequest() {
          try {
            this.isLoading = true;
            await this.$axios.post(`/account/sales-request`, {
              venueId:  this.venueId,
              product: this.upsellItem
            })

            this.$notify({
              group: 'settings',
              title: 'Thanks, we have your request and will be in touch soon.',
              duration: 10000
            });
          }
          catch (e) {
            this.$notify({
              group: 'settings',
              title: 'Sorry, something went wrong, please try again.'
            });
            throw new Error(e)
          }
          finally {
            this.isLoading = false;
          }
        }
    }
};
</script>
