<template>
    <div>
        <div class="md:flex md:items-center md:justify-between">
            <div class="min-w-0 flex-1">
                <div>
                    <router-link
                        :to="{ name: 'editVenue' }"
                        class="mb-2 flex items-center text-sm text-gray-500 inline-flex"
                    >
                        <base-back-icon />
                        <span>Settings</span>
                    </router-link>
                </div>

                <base-page-title title="Gratuity Settings" />
            </div>

            <BaseSpinner :isLoading="isLoading" />
        </div>
        <div>
            <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                        >
                            Enable tips
                        </h3>
                        <p class="mt-2 text-sm text-gray-500">
                            Enabling will give your customers a option to leave
                            a tip when they're on the checkout screen. Tips are
                            calculated as a percentage of the sub total (total
                            product costs before service charge or delivery
                            fees)
                        </p>
                    </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0">
                    <div class="shadow sm:overflow-hidden sm:rounded-md">
                        <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
                            <div class="col-span-3 sm:col-span-2">
                                <div class="mt-3 text-center sm:text-left">

                                    <LargeRadioButton
                                        :options="tipDisplaySettings"
                                        v-model="settings.acceptTips"
                                    />
                                </div>
                            </div>
                            <div class="mt-2 flex">
                                <div>
                                    <span
                                        class="mb-3 inline-block text-sm font-medium text-gray-700"
                                    >
                                        Tip label
                                    </span>
                                    <input
                                        v-model.trim="settings.tipLabel"
                                        placeholder="Tip"
                                        id="tipLabel"
                                        maxlength="80"
                                        class="focus:outline-none form-input block w-full rounded-md border border-gray-300 px-3 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                                    />
                                </div>
                            </div>
                            <div class="mt-2 flex items-center" v-if="settings.acceptTips === 'enabled'">
                                <div>
                                    <span
                                        class="mb-3 inline-block text-sm font-medium text-gray-700"
                                    >
                                        Suggested tip amounts
                                    </span>
                                    <div
                                        class="m-auto grid sm:grid-cols-2 md:grid-cols-4"
                                    >
                                        <div
                                            class="relative m-2 mr-2 flex cursor-pointer items-center justify-center rounded border border px-5 py-4 text-center transition duration-300 ease-in-out"
                                            v-for="(
                                                tip, index
                                            ) in tipPercentages"
                                            :key="index"
                                        >
                                            <currency-input
                                                v-model="tipOptions[index]"
                                                :id="`${tip}-${index}`"
                                                name="codeAmount"
                                                class="text-indigo-600 form-input sm:text-sm border-none"
                                                placeholder="0"
                                                :currency="null"
                                                :precision="1"
                                                style="width: 50px"
                                                locale="en-GB"
                                                :value-as-integer="true"
                                                :allow-negative="false"
                                            ></currency-input>

                                            <div
                                                v-if="index === 0"
                                                class="absolute inset-y-9 text-gray-500"
                                                style="font-size: 0.6rem"
                                            >
                                                (default)
                                            </div>

                                            <div
                                                class="absolute right-0 top-0 cursor-pointer text-sm text-red-500"
                                                style="
                                                    padding: 0.01rem 0.2rem
                                                        0.1rem 0.2rem;
                                                "
                                                @click="removeTipOption(index)"
                                            >
                                                x
                                            </div>

                                            <div
                                                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                                            >
                                                <span
                                                    class="text-gray-500 sm:text-sm"
                                                >
                                                    %
                                                </span>
                                            </div>
                                        </div>

                                        <div
                                            v-if="tipPercentages.length < 6"
                                            class="relative flex cursor-pointer flex-col items-center justify-center text-4xl text-green-700"
                                            @click="addTipOption"
                                        >
                                            +
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="settings.acceptsPat">
            <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                        >
                            Pay at table
                        </h3>
                        <div class="text-sm text-gray-500">
                            <p class="mt-2">
                                Customise the message shown to customers when
                                your store is set to not accepting orders.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0">
                    <div class="shadow sm:rounded-md">
                        <div
                            class="space-y-6 bg-white px-4 py-5 sm:rounded-md sm:p-6"
                        >
                            <div class="mt-1 sm:col-span-2 sm:mt-0">
                                <div class="flex flex-col rounded-md shadow-sm">
                                    <label
                                        for=""
                                        class="block text-sm font-medium leading-5 text-gray-700"
                                        >Display a message when service charge
                                        is tapped</label
                                    >

                                    <toggle-switch
                                        class="mt-2"
                                        v-model="
                                            settings.patServiceChargeMessage
                                        "
                                    />
                                    <textarea
                                        name="patServiceChargeMessageText"
                                        v-model.trim="
                                            settings.patServiceChargeMessageText
                                        "
                                        rows="4"
                                        :class="{
                                            'opacity-50': !settings.patServiceChargeMessage
                                        }"
                                        class="form-input mt-4 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                    ></textarea>

                                    <label
                                        class="mt-4 mb-2 block text-sm font-medium leading-5 text-gray-700"
                                    >
                                        Enable pay at table tipping (on top of
                                        service charge)
                                    </label>
                                    <div class="mt-3 text-center sm:text-left">
                                        <div
                                            class="flex items-center justify-between"
                                        >
                                        </div>
                                        <LargeRadioButton
                                            :options="PatDisplaySettings"
                                            v-model="settings.acceptPatTips"
                                        />
                                    </div>

                                    <label
                                        for="patTipSubLabel"
                                        class="mt-4 block text-sm font-medium leading-5 text-gray-700"
                                        >Display a sub label explaining tip
                                        policy</label
                                    >

                                    <textarea
                                        name="patTipSubLabel"
                                        v-model.trim="settings.tipSubLabel"
                                        rows="4"
                                        class="form-input mt-4 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="my-6 ml-4 flex flex-shrink-0 justify-end">
            <span class="inline-flex rounded-md shadow-sm" @click="saveVenue">
                <button
                    type="button"
                    class="focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                    :disabled="isLoading"
                >
                    Save
                </button>
            </span>
        </div>
    </div>
</template>

<script>
import LargeRadioButton from '@/components/formComponents/LargeRadioButton';
import ToggleSwitch from '@/components/formComponents/ToggleSwitch';
import { mapActions } from 'vuex';
export default {
    name: 'TipSettings',
    props: ['venueId', 'venue'],
    metaInfo: {
        title: 'Gratuity Settings'
    },
    components: {
        LargeRadioButton,
        ToggleSwitch
    },
    data() {
        return {
            isLoading: false,
            settings: { ...this.venue },
            tipDisplaySettings: [
                {
                    label: 'Disable tips',
                    labelDescription: "Customers won't be able to leave a tip",
                    value: 'disabled'
                },
                {
                    label: 'Enable percentage tips',
                    labelDescription:
                        'Customers will be able to leave an optional percentage based tip',
                    value: 'enabled'
                },
                {
                  label: 'Enable smart tips',
                  labelDescription:
                      'Customers will be prompted to leave a fixed amount tip based on the order total',
                  value: 'smart'
                }
            ],
            PatDisplaySettings: [
                {
                    label: 'Disable tipping',
                    labelDescription: "Customers won't be able to leave a tip",
                    value: false
                },
              {
                label: 'Enable percentage tips',
                labelDescription:
                    'Customers will be able to leave an optional percentage based tip',
                value: true
              },
              {
                label: 'Enable smart tips',
                labelDescription:
                    'Customers will be prompted to leave a fixed amount tip based on the order total',
                value: 'smart'
              }
            ],
            tipPercentages: this.venue.tipPercentages,
            tipSuggestion: 0
        };
    },
    methods: {
        ...mapActions({
            updateVenueSettings: 'venues/update'
        }),
        addTipOption() {
            this.tipPercentages.push(0);
        },
        removeTipOption(index) {
            if (this.tipPercentages.length === 2) {
                this.$notify({
                    group: 'settings',
                    title: 'At least 2 tip options required'
                });

                return;
            }

            this.tipPercentages.splice(index, 1);
        },
        async saveVenue() {
            this.isLoading = true;

            try {
                const tipPercentages = this.tipOptions.map(
                    tipOption => tipOption * 10
                );
                const payload = {
                    id: this.venueId,
                    tipPercentages,
                    acceptTips: this.settings.acceptTips,
                    tipLabel: this.settings.tipLabel,
                    patServiceChargeMessage: this.settings
                        .patServiceChargeMessage,
                    patServiceChargeMessageText: this.settings
                        .patServiceChargeMessageText,
                    tipSubLabel: this.settings.tipSubLabel,
                    acceptPatTips: this.settings.acceptPatTips
                };

                await this.updateVenueSettings(payload);

                this.$notify({
                    group: 'settings',
                    title: 'Settings saved'
                });
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        }
    },
    computed: {
        tipOptions() {
            return this.tipPercentages.map(tip => tip / 10);
        }
    }
};
</script>
