<template>
    <sliding-sidebar
        v-model="isOpened"
        title="Edit product"
        :id="product.id"
        size="2xl"
        @on-escape-key-up="closeSidebar"
    >
        <div>
            <div class="sm:hidden">
                <label for="tabs" class="sr-only">Select a tab</label>
                <select
                    id="tabs"
                    name="tabs"
                    v-model="currentTab"
                    class="focus:outline-none block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-sm focus:border-indigo-500 focus:ring-indigo-500"
                >
                    <option
                        v-for="tab in tabs"
                        :key="tab.key"
                        :value="tab"
                        :selected="tab"
                    >
                        {{ tab.name }}

                        <span
                            v-if="
                                tab.key === TabsEnum.TAGS_N_ALLERGENS.key &&
                                !isSomeAllergenSelected
                            "
                        >
                            (*no allergens selected)
                        </span>
                    </option>
                </select>
            </div>
            <div class="hidden sm:block">
                <div class="border-b border-gray-200">
                    <nav class="-mb-px flex space-x-10" aria-label="Tabs">
                        <span
                            v-for="tab in tabs"
                            :key="tab.name"
                            @click="changeTab(tab)"
                            class="relative cursor-pointer"
                            :class="[
                                tab.key === currentTab.key
                                    ? 'border-indigo-500 text-indigo-600'
                                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                tab.key === TabsEnum.DELETE.key
                                    ? 'text-red-600'
                                    : '',
                                'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium'
                            ]"
                            :aria-current="
                                tab.key === currentTab.key ? 'page' : undefined
                            "
                        >
                            <div
                                v-if="
                                    tab.key === TabsEnum.TAGS_N_ALLERGENS.key &&
                                    !isSomeAllergenSelected
                                "
                                class="absolute top-0 -right-2 mb-1 inline-flex items-center rounded-full bg-red-100 px-0.5 py-0.5 text-xs font-medium text-red-800"
                            >
                                <svg
                                    class="h-3 w-3"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                    ></path>
                                </svg>
                            </div>

                            {{ tab.name }}
                        </span>
                    </nav>
                </div>
            </div>
        </div>
        <transition name="fade" mode="out-in" appear>
            <product-detail-settings
                v-if="currentTab.key === TabsEnum.DETAILS.key"
                :product="product"
                :venue="venue"
                @close="closeSidebar"
                @update="saveProduct"
                @update-categories="updateCategories"
            />

            <product-tags-settings
                v-else-if="currentTab.key === TabsEnum.TAGS_N_ALLERGENS.key"
                :product="product"
                @close="closeSidebar"
                @update="saveProduct"
            />

            <product-modifiers-settings
                v-else-if="currentTab.key === TabsEnum.MODIFIERS.key"
                :product="product"
                :venue="venue"
                @close="closeSidebar"
                @update="saveProduct"
                @update-categories="updateCategories"
            />

            <product-video-settings
                v-else-if="currentTab.key === TabsEnum.VIDEO.key"
                :product="product"
                @close="closeSidebar"
                @update="saveProduct"
                @update-categories="updateCategories"
            />

            <product-deleting-settings
                v-else-if="currentTab.key === TabsEnum.DELETE.key"
                :product="product"
                @close="closeSidebar"
                @update="updateCategories"
            />
        </transition>
    </sliding-sidebar>
</template>

<script>
import SlidingSidebar from '../SlidingSidebar';
import ProductDetailSettings from './modules/ProductDetailSettings';
import ProductTagsSettings from './modules/ProductTagsSettings';
import ProductModifiersSettings from './modules/ProductModifiersSettings';
import ProductVideoSettings from './modules/ProductVideoSettings';
import ProductDeletingSettings from './modules/ProductDeletingSettings';

const TabsEnum = Object.freeze({
    DETAILS: { name: 'Details', key: 'details' },
    TAGS_N_ALLERGENS: {
        name: 'Tags & Allergens',
        key: 'tags-n-allergens'
    },
    MODIFIERS: {
        name: 'Modifiers',
        key: 'modifiers'
    },
    VIDEO: { name: 'Video', key: 'video' },
    DELETE: { name: 'Delete', key: 'delete' }
});

export default {
    name: 'EditProductSidebar',
    components: {
        ProductDeletingSettings,
        ProductModifiersSettings,
        ProductTagsSettings,
        ProductDetailSettings,
        ProductVideoSettings,
        SlidingSidebar
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        product: {
            type: Object,
            required: true
        },
        venue: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            TabsEnum,
            currentTab: TabsEnum.DETAILS,
            tabs: Object.values(TabsEnum),
            isLoading: false
        };
    },
    computed: {
        isOpened: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        isSomeAllergenSelected() {
            return this.product.tags && this.product.tags.some(tag => tag > 10);
        }
    },
    methods: {
        resetCurrentTab() {
            this.currentTab = TabsEnum.DETAILS;
        },
        changeTab(tab) {
            this.currentTab = tab;
        },
        updateCategories() {
            this.$emit('update');
        },
        closeSidebar() {
            this.resetCurrentTab();
            this.isOpened = false;
        },
        async saveProduct() {
            const product = {
                compareAtPrice: this.product.compareAtPrice,
                description: this.product.description,
                image: this.product.image,
                inventory: this.product.inventory,
                isAvailable: this.product.isAvailable,
                muxAssetId: this.product.muxAssetId,
                name: this.product.name,
                printName: this.product.printName,
                onSale: this.product.onSale,
                popular: this.product.popular,
                price: this.product.price,
                sku: this.product.sku,
                plu: this.product.plu,
                snooze: this.product.snooze,
                tags: this.product.tags,
                trackInventory: this.product.trackInventory,
                video: this.product.video,
                posId: this.product.posId,
                deliveryTax: this.product.deliveryTax,
                nutrition: this.product.nutrition,
                ingredients: this.product.ingredients
            };

            try {
                await this.$axios.put(`/products/${this.product.id}`, product);

                this.updateCategories();
                this.closeSidebar();
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        }
    }
};
</script>

<style src="@/assets/css/animations.css" />
