<template>
    <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
            <div class="sm:pl-0 sm:pr-5">
                <h3 class="text-base font-semibold leading-6 text-gray-800">
                    Order batching
                </h3>
                <p class="mt-2 text-sm text-gray-500">
                    Enable order batching to combine orders from the same table
                    into one ticket for your printers or EPOS system. A batch is
                    started when a customer places the first order for a table,
                    any order placed within your batch timeout setting will be
                    combined into a single ticket.
                </p>
            </div>
        </div>
        <div class="mt-5 md:col-span-2 md:mt-0">
            <div class="shadow sm:overflow-hidden sm:rounded-md">
                <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
                    <div class="grid grid-cols-6 gap-6">
                        <div class="col-span-6 sm:col-span-6 lg:col-span-6">
                            <label
                                for="orderBatching"
                                class="block text-sm font-medium leading-5 text-gray-700"
                            >
                                Enabled
                            </label>
                            <div class="relative mt-2 rounded-md">
                                <toggle-switch
                                    v-model="isActive"
                                />
                            </div>
                        </div>
                        <div class="col-span-6 sm:col-span-6 lg:col-span-6">
                            <label
                                for="orderBatchNotes"
                                class="block text-sm font-medium leading-5 text-gray-700"
                            >
                                Show notes
                            </label>
                            <div class="relative mt-2 rounded-md">
                                <toggle-switch v-model="showNotes" />
                            </div>
                        </div>
                        <div class="col-span-6 sm:col-span-6 lg:col-span-6">
                            <label
                                for="orderBatchCustomerNames"
                                class="block text-sm font-medium leading-5 text-gray-700"
                            >
                                Show customer names
                            </label>
                            <div class="relative mt-2 rounded-md">
                                <toggle-switch v-model="showCustomerNames" />
                            </div>
                        </div>
                        <div class="col-span-6 sm:col-span-6 lg:col-span-6">
                            <label
                                for="batchTimeOut"
                                class="block text-sm font-medium leading-5 text-gray-700"
                            >
                                Timeout
                            </label>
                            <span class="text-sm leading-5 text-gray-500">
                                set the order batching timeout
                            </span>
                            <div class="relative mt-1 rounded-md shadow-sm">
                                <select
                                    v-model="selectedTimeout"
                                    id="batchTimeOut"
                                    class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                    :disabled="!isActive"
                                >
                                    <option
                                        v-for="timeoutOption in timeoutOptions"
                                        :key="timeoutOption"
                                        :value="timeoutOption"
                                    >
                                        {{ timeoutOption }} Minutes
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ToggleSwitch from '@/components/formComponents/ToggleSwitch';
import { mapGetters } from 'vuex';
import { FeaturesEnum } from '@/enums';

export default {
    components: { ToggleSwitch },
    name: 'BatchSettings',
    props: {
        batchSettings: {
            type: Object,
            required: true,
            default: () => ({})
        },
        isBatchSettingActive: {
            type: Boolean,
            required: true,
            default: false
        },
        areBatchNotesActive: {
            type: Boolean,
            required: true,
            default: false
        },
        areBatchCustomerNamesActive: {
            type: Boolean,
            required: true,
            default: false
        },
        batchTimeout: {
            type: Number,
            required: true,
            default: 0
        }
    },
    watch: {
        isActive(value) {
            this.$emit('change-is-batch-setting-active', value);
        },
        showNotes(value) {
            this.$emit('change-are-batch-notes-active', value);
        },
        showCustomerNames(value) {
            this.$emit('change-are-batch-names-active', value);
        },
        selectedTimeout(value) {
            this.$emit('change-batch-timeout', value);
        }
    },
    data() {
        return {
            isActive: this.isBatchSettingActive,
            showNotes: this.areBatchNotesActive,
            showCustomerNames: this.areBatchCustomerNamesActive,
            selectedTimeout: this.batchTimeout,
            timeoutOptions: [2, 3, 4, 5],
            FeaturesEnum
        };
    },
    methods: {
        toggleIsActive() {
            this.isActive = !this.isActive;
        }
    },
    computed: {
        ...mapGetters({
            isFeatureAvailable: 'user/isFeatureAvailable'
        })
    }
};
</script>
