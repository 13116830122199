<template>
    <div
        class="flex min-h-screen w-full flex-row justify-start bg-sk-silver-grey sm:justify-center"
    >
        <div
            class="flex w-full flex-col justify-center px-4 py-12 sm:px-6 lg:px-8"
        >
            <div class="sm:mx-auto sm:w-full sm:max-w-md">
                <img
                    class="ml-0 mr-auto h-10 w-auto"
                    :src="whitelabelLogoUrl"
                    alt="Logo"
                    v-if="whitelabelLogoUrl"
                />
            </div>

            <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-lg">
                <div
                    class="bg-white px-4 py-8 shadow-xl sm:rounded-md sm:px-10 sm:px-5 sm:py-10 md:px-12 md:py-12"
                >
                    <h2
                        class="mb-6 text-left font-heading text-xl font-bold leading-7 text-gray-700"
                    >
                        OpenTable wants access to your storekit account.
                    </h2>
                    <div class="mt-6" v-if="venues.length">
                        <div class="mt-1 rounded-md shadow-sm">
                            <multi-select
                                v-model="selectedVenue"
                                @select="errors = false"
                                track-by="name"
                                label="name"
                                placeholder="Select one store"
                                :options="venues"
                                :searchable="true"
                                :allow-empty="false"
                            />
                        </div>
                    </div>
                    <div class="mt-6 flex justify-between">
                        <span
                            class="block w-full rounded-md shadow-sm"
                            :class="{ spinner: isLoading }"
                        >
                            <button
                                @click="consent(false)"
                                type="submit"
                                :disabled="isLoading"
                                class="focus:outline-none flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-3 text-sm font-semibold text-gray-700 text-white transition duration-150 ease-in-out hover:bg-gray-50 focus:border-indigo-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                Cancel
                            </button>
                        </span>
                        <span
                            class="ml-4 block w-full rounded-md shadow-sm"
                            :class="{ spinner: isLoading }"
                        >
                            <button
                                @click="consent(true)"
                                type="submit"
                                :disabled="isLoading"
                                class="focus:outline-none flex w-full justify-center rounded-md border border-transparent bg-indigo-800 px-4 py-3 text-sm font-semibold text-white transition duration-150 ease-in-out hover:bg-indigo-900 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                            >
                                Allow
                            </button>
                        </span>
                    </div>
                    <div class="mt-6"> </div>
                    <div class="mt-1" v-if="$store.state.user.errors">
                        <span class="py-2 text-xs text-red-600"
                            >Couldn't login. Please try again, reset your
                            password or create an account</span
                        >
                    </div>
                    <div class="mt-1" v-if="errors">
                        <span class="py-2 text-xs text-red-600"
                            >Please select a store</span
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import isEmail from 'vuelidate/lib/validators/email';

export default {
    name: 'OAuthConsent',
    metaInfo: {
        title: 'Login'
    },
    computed: {
        whitelabelLogoUrl() {
            return process.env.VUE_APP_WIHTELABEL_LOGO || null;
        },
        validEmail() {
            return isEmail(this.user.email) ? this.user.email : '';
        }
    },
    data() {
        return {
            isLoading: false,
            venues: [],
            selectedVenue: {},
            errors: false
        };
    },
    async mounted() {
        await this.getInfo();
    },
    methods: {
        async getInfo() {
            try {
                const { data } = await this.$axios.get(`/oauth/get-info`, {
                    params: {
                        challenge: this.$route.query.consent_challenge
                    }
                });

                this.venues = data.venues || [];

                if (this.venues.length === 1) {
                    this.selectedVenue = this.venues[0];
                }
            } catch (error) {
                throw new Error(`API ${error}`);
            }
        },

        async consent(accepted) {
            if (accepted && !this.selectedVenue.id) {
                this.errors = true;
                return;
            }
            this.isLoading = true;

            if (this.$route.query.consent_challenge) {
                try {
                    const { data } = await this.$axios.post(`/oauth/consent`, {
                        challenge: this.$route.query.consent_challenge,
                        accepted,
                        venueId: this.selectedVenue.id
                    });

                    window.location.href = data.redirect;
                } catch (error) {
                    throw new Error(`API ${error}`);
                } finally {
                    this.isLoading = false;
                }

                return;
            }
        }
    }
};
</script>

<style scoped>
.spinner:after {
    left: auto;
    right: 1em;
    border-right-color: white;
}
</style>
