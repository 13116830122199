<template>
    <div class="hidden sm:block" aria-hidden="true">
        <div :class="classObject">
            <div class="border-t border-gray-200"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BaseSpacerWithRuler',
    props: {
        height: {
            type: Number,
            default: 5,
            required: false
        }
    },
    computed: {
        classObject() {
            return `py-${this.height}`;
        }
    }
};
</script>
