<template>
    <div
        class="focus:outline-none relative z-0 flex-1 overflow-y-auto pb-6 pt-2 md:py-6"
    >
        <base-toggle-sidebar-button />

        <div
            class="mx-auto flex max-w-7xl flex-row items-center justify-between px-4 sm:px-6 lg:px-8"
        >
            <base-page-title title="Settings" />
            <div>
                <div class="">
                    <span class="flex- inline-flex">
                        <base-button @clicked="logout()" buttonText="Logout">
                            <template #right-icon>
                                <base-exit-icon class="-mr-1 ml-2 h-5 w-5" />
                            </template>
                        </base-button>
                    </span>
                </div>
                <BaseSpinner :isLoading="isLoading" />
            </div>
        </div>
        <div class="mx-auto max-w-7xl px-4 pb-24 pt-8 sm:px-6 md:px-8 lg:px-8">
            <div>
                <div class="md:grid md:grid-cols-3 md:gap-6">
                    <div class="md:col-span-1">
                        <div class="px-4 sm:px-0">
                            <h3
                                class="text-lg font-medium leading-6 text-gray-900"
                                >Your Profile</h3
                            >
                        </div>
                    </div>
                    <div class="mt-5 md:col-span-2 md:mt-0">
                        <form
                            @submit.prevent="updateUser()"
                            @change="autoSaveUserDebounced"
                            v-if="account && user"
                        >
                            <div class="shadow sm:rounded-md">
                                <div
                                    class="bg-white px-4 py-5 sm:p-6 md:rounded-md"
                                >
                                    <div class="grid grid-cols-6 gap-6">
                                        <div class="col-span-6 select-none">
                                            <div
                                                class="flex w-full items-center rounded-md border-none border-gray-300 bg-gray-100 px-3 py-2 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                                            >
                                                <base-home-icon
                                                    class="h-4 w-4"
                                                />
                                                <span class="ml-2">{{
                                                    account.name
                                                }}</span>
                                            </div>
                                        </div>
                                        <div class="col-span-6">
                                            <base-input-field
                                                id="name"
                                                v-model="user.name"
                                                label="Name"
                                                :errors="$v.user.name.$error"
                                                errorMessage="Please enter a valid name"
                                            />
                                        </div>

                                        <div class="col-span-6">
                                            <base-input-field
                                                id="email"
                                                v-model="user.email"
                                                label="Email"
                                                :errors="
                                                    $v.user.email.$error ||
                                                    error.emailInUse
                                                "
                                                :errorMessage="
                                                    error && error.emailInUse
                                                        ? 'Already in use. Please use another email address.'
                                                        : 'Please enter a valid email'
                                                "
                                            />
                                        </div>

                                        <div class="col-span-6">
                                            <label
                                                for="phone"
                                                class="block text-sm font-medium leading-5 text-gray-700"
                                                >Phone
                                                <span class="text-gray-500"
                                                    >(optional)</span
                                                ></label
                                            >
                                            <InternationalTelInput
                                                id="phone"
                                                v-model.trim="user.phone"
                                                :error="$v.user.phone.$error"
                                                @input="autoSaveUserDebounced"
                                            />
                                            <span
                                                v-if="$v.user.phone.$error"
                                                class="text-xs text-red-600"
                                            >
                                                Please enter a valid phone
                                                number
                                            </span>
                                        </div>

                                        <timezone-selector
                                            class="col-span-6"
                                            v-model="user.timezone"
                                            @input="autoSaveUserDebounced"
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="hidden sm:block">
                <div class="py-5">
                    <div class="border-t border-gray-200"></div>
                </div>
            </div>

            <div class="mt-10 sm:mt-0">
                <div class="md:grid md:grid-cols-3 md:gap-6">
                    <div class="md:col-span-1">
                        <div class="px-4 sm:px-0">
                            <h3
                                class="text-lg font-medium leading-6 text-gray-900"
                                >Users</h3
                            >
                            <p class="mt-1 text-sm leading-5 text-gray-600">
                                Manage user access to your account
                            </p>
                        </div>
                    </div>
                    <div class="mt-5 md:col-span-2 md:mt-0">
                        <div class="overflow-hidden shadow sm:rounded-md">
                            <UserList
                                :users="account.user"
                                v-if="account && account.user"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="hidden sm:block">
                <div class="py-5">
                    <div class="border-t border-gray-200"></div>
                </div>
            </div>

            <div class="mt-10 sm:mt-0">
                <div class="md:grid md:grid-cols-3 md:gap-6">
                    <div class="md:col-span-1">
                        <div class="px-4 sm:px-0">
                            <h3
                                class="text-lg font-medium leading-6 text-gray-900"
                                >Developers</h3
                            >
                            <p class="mt-1 text-sm leading-5 text-gray-600">
                            </p>
                        </div>
                    </div>
                    <div class="mt-5 md:col-span-2 md:mt-0">
                        <div class="overflow-hidden shadow sm:rounded-md">
                            <div
                                class="border-b border-gray-200 bg-white px-4 py-5 sm:px-6"
                            >
                                <div
                                    class="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap"
                                >
                                    <div class="ml-4 mt-4">
                                        <h3
                                            class="text-base font-semibold leading-6 text-gray-900"
                                            >Webhooks</h3
                                        >
                                        <p class="mt-1 text-sm text-gray-500"
                                            >Send realtime storekit data to
                                            external services and custom
                                            integrations.
                                        </p>
                                    </div>
                                    <div class="ml-4 mt-4 flex-shrink-0">
                                        <base-button
                                            @clicked="
                                                $router.push({
                                                    name: 'webhooks'
                                                })
                                            "
                                            :buttonText="
                                                account?.webhooksEnabled
                                                    ? 'Manage Webhooks'
                                                    : 'Enable Webhooks'
                                            "
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <AddUserModal v-on:user-added="fetchAccount()" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { email, minLength, required } from 'vuelidate/lib/validators';
import UserList from '../components/widgets/UserList';
import AddUserModal from '../components/modals/AddUserModal';
import TimezoneSelector from '@/components/formComponents/TimezoneSelector';
import InternationalTelInput from '@/components/formComponents/InternationalTelInput';
import { debounce } from '@/helpers/debounce';

export default {
    name: 'AccountSettings',
    metaInfo: {
        title: 'Account Settings'
    },
    components: {
        UserList,
        AddUserModal,
        TimezoneSelector,
        InternationalTelInput
    },
    data() {
        return {
            isLoading: false,
            accountName: '',
            account: null,
            error: {
                emailInUse: false
            }
        };
    },
    validations: {
        user: {
            name: {
                required,
                minLength: minLength(3)
            },
            email: {
                required,
                minLength: minLength(3),
                email
            },
            phone: {
                minLength: minLength(5)
            }
        }
    },
    async created() {
        this.fetchAccount();
        this.autoSaveUserDebounced = debounce(() => {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return false;
            }

            this.error.emailInUse = false;
            this.isLoading = true;

            const { email, name, phone, timezone } = this.user;
            const user = {
                email,
                phone,
                name,
                timezone
            };

            try {
                this.$axios.put('/user', { user });

                this.$notify({
                    group: 'settings',
                    title: 'Settings saved'
                });
            } catch (error) {
                if (error.response?.data?.message.includes('email_exists')) {
                    this.$modal.show('custom-error-modal', {
                        title: "Couldn't update email",
                        text:
                            'The requested email address is already in use. Please use another email address.'
                    });
                    this.error.emailInUse = true;
                } else {
                    this.$notify({
                        group: 'settings',
                        title: 'An error occurred'
                    });
                }

                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        }, 1000);
    },
    methods: {
        async autoSaveUser() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return false;
            }

            this.error.emailInUse = false;
            this.isLoading = true;

            const { email, name, password, phone, timezone } = this.user;
            const user = {
                email,
                phone,
                name,
                timezone
            };
            if (password) {
                user.password = password;
            }

            try {
                await this.$axios.put('/user', { user });

                this.$notify({
                    group: 'settings',
                    title: 'Settings saved'
                });
            } catch (error) {
                if (error.response?.data?.message.includes('email_exists')) {
                    this.$modal.show('custom-error-modal', {
                        title: "Couldn't update email",
                        text:
                            'The requested email address is already in use. Please use another email address.'
                    });
                    this.error.emailInUse = true;
                } else {
                    this.$notify({
                        group: 'settings',
                        title: 'An error occurred'
                    });
                }

                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        },
        async fetchAccount() {
            this.isLoading = true;

            try {
                const { data } = await this.$axios.get('/account');

                this.account = data;
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        },
        logout() {
            this.$store.dispatch('allOrders/clearUserSettings');
            this.$store.dispatch('user/LOGOUT');
            this.$router.push({ name: 'login' });
            this.$knockFeed.teardown();
        }
    },
    computed: {
        ...mapGetters('user', { user: 'currentUser' })
    }
};
</script>
