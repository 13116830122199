<template>
    <div>
        <div class="mb-4 print:hidden" v-if="!embedded">
            <div>
                <BasePageTitle title="Sales over time" />
            </div>
            <div class="mt-1 flex flex-col sm:mt-2 sm:flex-row sm:flex-wrap">
                <PrintButton />
                <ExportButton @clicked="showExportCsvModal" />
            </div>
        </div>

        <DateSettings
            v-if="!embedded"
            :byPreOrderDate="byPreOrderDate"
            @update-report="updateReport"
            @fetch-report-by-pre-order-date="fetchReportByPreOrderDate"
        />

        <div
            class="mb-4 overflow-hidden rounded-sm bg-white shadow"
            :class="{ 'overflow-visible rounded-md': embedded }"
        >
            <div :class="{ 'py-2 pl-1 pr-2' : !embedded }">
                <apex-chart
                    v-if="!isLoading"
                    width="100%"
                    :height="embedded ? '260px' : '350px'"
                    type="bar"
                    :options="chartOptions"
                    :series="series"
                    class="font-sans"
                    :class="{ embedded: embedded }"
                >
                </apex-chart>
                <div v-else style="height: 275px" class="spinner"> </div>
            </div>
        </div>

        <div class="mt-8 bg-white shadow" v-if="!embedded">
            <vue-good-table
                v-if="!isLoading && reportData && reportData.length"
                :isLoading.sync="isLoading"
                class="border-b border-gray-200"
                styleClass="tailwind-table gray-subheaders"
                :pagination-options="{
                    enabled: true,
                    perPage: 100
                }"
                :group-options="{
                    enabled: true,
                    headerPosition: 'bottom'
                }"
                :columns="columns"
                :rows="[
                    {
                        day: null,
                        totalOrders: null,
                        grossRevenue: null,
                        grossDiscount: null,
                        children: reportData || []
                    }
                ]"
            >
                <template slot="table-header-row" slot-scope="props">
                    <span v-if="props.column.field === 'day'" class="text-sm">
                        Summary
                    </span>
                    <span v-else class="text-sm">
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
            </vue-good-table>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PrintButton from '@/components/helpers/PrintButton';
import DateSettings from '@/components/helpers/DateSettings';
import ExportButton from '@/components/helpers/ExportButton';

export default {
    name: 'OrdersReport',
    props: {
        selectedVenues: {
            type: Array,
            required: true
        },
        embedded: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: {
        DateSettings,
        PrintButton,
        ExportButton
    },
    data() {
        return {
            isLoading: true,
            dateRange: {
                startDate: null,
                endDate: null
            },
            columns: [
                {
                    label: 'Date',
                    field: 'day',
                    sortable: true,
                    firstSortType: 'desc',
                    type: 'date',
                    dateInputFormat: 'yyyy-MM-dd',
                    dateOutputFormat: 'MMM do yyyy',
                    tdClass:
                        'px-6 py-3 whitespace-nowrap text-sm leading-5 font-medium text-gray-900',
                    thClass:
                        'px-6 bg-gray-100 py-2 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600'
                },
                {
                    label: 'Orders',
                    field: 'totalOrders',
                    headerField: row =>
                        this.calculateSummary('totalOrders', row),
                    type: 'number',
                    sortable: true,
                    firstSortType: 'desc',
                    tdClass:
                        'px-6 py-3 whitespace-nowrap text-sm leading-5 text-gray-500',
                    thClass:
                        'px-6 bg-gray-100 py-2 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600'
                },
                {
                    label: 'Gross Sales',
                    field: 'grossRevenue',
                    type: 'number',
                    sortable: true,
                    headerField: row =>
                        this.calculateSummary('grossRevenue', row),
                    firstSortType: 'desc',
                    formatFn: this.formatCurrency,
                    tdClass:
                        'px-6 py-3 whitespace-nowrap text-sm leading-5 text-gray-500',
                    thClass:
                        'px-6 bg-gray-100 py-2 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600'
                },
                {
                    label: 'Discounts',
                    field: 'grossDiscount',
                    type: 'number',
                    sortable: true,
                    headerField: row =>
                        this.calculateSummary('grossDiscount', row),
                    firstSortType: 'desc',
                    formatFn: this.formatCurrency,
                    tdClass:
                        'px-6 py-3 whitespace-nowrap text-sm leading-5 text-gray-500',
                    thClass:
                        'px-6 bg-gray-100 py-2 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600'
                }
            ],
            report: {},
            reportData: null,
            chartOptions: {
                theme: {
                    palette: 'palette4'
                },
                title: {
                    text: 'Total sales',
                    align: 'left',
                    margin: 10,
                    offsetX: 0,
                    offsetY: 0,
                    floating: false,
                    style: {
                        fontSize: '15px',
                        fontWeight: 'bold',
                        color: '#263238'
                    }
                },
                colors: ['rgba(78, 205, 196, 1)'],
                chart: {
                    id: 'vuechart-example2',
                    toolbar: false
                },
                stroke: {
                    width: 2,
                    colors: ['rgba(78, 205, 196, 1)']
                },
                labels: [],
                plotOptions: {
                    bar: {
                        columnWidth: '93%',
                        borderRadius: 2,
                        borderRadiusApplication: 'end'
                    }
                },
                xaxis: {
                    type: 'datetime',
                    lines: {
                        show: true
                    },
                    labels: {
                        datetimeUTC: true,
                        show: true,
                        rotate: -45,
                        rotateAlways: false,
                    }
                },
                yaxis: {
                    labels: {
                        formatter: value =>
                            this.$options.filters.currency(parseInt(value))
                    },
                    forceNiceScale: true,
                    min: 0,
                    decimalsInFloat: 0
                },
                dataLabels: {
                    enabled: false
                },
                tooltip: {
                    enabled: true
                },
                dropShadow: {
                    enabled: true,
                    top: 0,
                    left: 0,
                    blur: 3,
                    opacity: 0.1
                }
            },
            series: [
                {
                    name: 'Gross Revenue',
                    data: []
                }
            ],
            csvTitle: 'Sales over time',
            keysForCsv: ['Date', 'Orders', 'Gross Sales', 'Discounts'],
            byPreOrderDate: false,
            TRANSACTIONAL_TYPE: 'transactional',
            OPERATIONAL_TYPE: 'operational',
            fields: [
                {
                    label: 'Date',
                    value: 'day'
                },
                {
                    label: 'Orders',
                    value: 'totalOrders'
                },
                {
                    label: 'Gross Sales',
                    value: 'grossRevenue'
                },
                {
                    label: 'Discounts',
                    value: 'grossDiscount'
                }
            ]
        };
    },
    created() {
        this.dateRange = this.getDateRange || this.dateRange;

        this.fetchReport(this.TRANSACTIONAL_TYPE);
    },
    methods: {
        calculateSummary(type, rowObj) {
            let sum = 0;
            for (let i = 0; i < rowObj.children.length; i++) {
                sum += rowObj.children[i][type];
            }

            return sum;
        },
        updateReport(val) {
            this.dateRange = val;
            this.fetchReportByPreOrderDate(this.byPreOrderDate);
        },
        async fetchReport(type = this.TRANSACTIONAL_TYPE) {
            this.isLoading = true;

            if (!this.selectedVenues.length) {
                this.series[0].data = [];

                this.isLoading = false;

                return;
            }

            const params = {
                venueIds: this.selectedVenues,
                from: this.dateRange.startDate,
                to: this.dateRange.endDate,
                type
            };

            try {
                const response = await this.$axios.get('/reports/orders', {
                    params
                });

                this.reportData = response.data;

                const data = [];

                for (const val of response.data) {
                    const revenue = parseInt(val.grossRevenue / 100) || 0;
                    const date = new Date(val.day);
                    const dateTime = date.getTime();

                    data.push([dateTime, revenue]);
                }

                this.series[0].data = data;
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        },

        print() {
            window.print();
        },
        formatCurrency: function (value) {
            return this.$options.filters.currency(parseInt(value) / 100);
        },
        showExportModal() {
            this.$modal.show('export-modal', { resourceName: null });
        },
        fetchReportByPreOrderDate(value) {
            this.byPreOrderDate = value;

            const type = this.byPreOrderDate
                ? this.OPERATIONAL_TYPE
                : this.TRANSACTIONAL_TYPE;

            this.fetchReport(type);
        },
        showExportCsvModal() {
            this.$modal.show('export-csv-modal', {
                header: this.csvTitle,
                csvData: this.csvData,
                fields: this.fields
            });
        }
    },
    computed: {
        ...mapGetters({
            getDateRange: 'report/getDateRange'
        }),
        csvData() {
            const csvReportData = this.reportData.map(item => ({
                ...item
            }));

            csvReportData.forEach(item => {
                item.grossRevenue = this.formatCurrency(item.grossRevenue);
                item.grossDiscount = this.formatCurrency(item.grossDiscount);
            });

            return csvReportData;
        }
    },
    watch: {
        selectedVenues() {
            this.fetchReportByPreOrderDate(false);
        }
    }
};
</script>

<style>
.apexcharts-xaxistooltip,
.apexcharts-gridlines-horizontal {
    display: none;
}
.embedded .apexcharts-title-text {
    display: none;
}
</style>
