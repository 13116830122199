<template>
    <modal
        name="ready-for-pickup-modal"
        :adaptive="true"
        height="auto"
        width="600px"
        @before-open="beforeOpen"
    >
        <div
            class="transform overflow-hidden rounded-lg bg-white shadow-xl transition-all sm:w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
        >
            <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start">
                    <div class="mt-3 sm:ml-4 sm:mt-0 text-left">
                        <div class="flex items-center justify-between">
                            <h3
                                class="mb-2 text-lg font-heading leading-6 text-gray-900"
                            >
                                Ready for pickup
                            </h3>
                            <div
                                class="h-4 w-4"
                                :class="{ 'text-gray-900 spinner': isLoading }"
                            >
                                <span></span>
                            </div>
                        </div>
                        <div>
                            <p class="text-sm leading-5 text-gray-500">
                                We'll notify your customer by email and SMS to let them know their order is ready now or in the number of minutes you select.
                            </p>
                        </div>
                        <div
                            class="mt-4 grid grid-cols-1  sm:grid-cols-2 gap-3 sm:gap-4"
                            :class="{
                                'pointer-events-none opacity-50': isLoading
                            }"
                        >
                            <button
                                @click="updateOrder(0)"
                                type="button"
                                class="focus:outline-none mb-4 sm:mb-0 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium leading-6 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline sm:text-sm sm:leading-5"
                            >
                                Now
                            </button>
                            <button
                                @click="updateOrder(2)"
                                type="button"
                                class="focus:outline-none inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium leading-6 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline sm:text-sm sm:leading-5"
                            >
                                in 2 Mins
                            </button>
                            <button
                                @click="updateOrder(5)"
                                type="button"
                                class="focus:outline-none inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium leading-6 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline sm:text-sm sm:leading-5"
                            >
                                in 5 Mins
                            </button>

                            <button
                                @click="updateOrder(10)"
                                type="button"
                                class="focus:outline-none inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium leading-6 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline sm:text-sm sm:leading-5"
                            >
                                in 10 Mins
                            </button>

                            <button
                                @click="updateOrder(15)"
                                type="button"
                                class="focus:outline-none inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium leading-6 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline sm:text-sm sm:leading-5"
                            >
                                in 15 Mins
                            </button>
                          <button
                              @click="updateOrder(20)"
                              type="button"
                              class="focus:outline-none inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium leading-6 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline sm:text-sm sm:leading-5"
                          >
                            in 20 Mins
                          </button>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6"
            >
                <span
                    class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto"
                    :class="{ 'opacity-50': isLoading }"
                >
                    <button
                        @click="hideModal"
                        :disabled="isLoading"
                        type="button"
                        class="focus:outline-none inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium leading-6 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                    >
                        Cancel
                    </button>
                </span>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    name: 'ReadyForPickupModal',
    data() {
        return {
            order: null,
            isLoading: false
        };
    },
    methods: {
        hideModal() {
            this.$modal.hide('ready-for-pickup-modal');
        },
        beforeOpen({ params }) {
            this.order = params.order;
        },
        async updateOrder(delay) {
            this.$emit('update', 'Ready For Pickup', delay);

            this.hideModal();
        }
    }
};
</script>
