<template>
    <li>
        <span
            @click="selectTableArea(area, index)"
            :class="{
                'bg-gray-50': selectedTableAreaIndex === index
            }"
            class="focus:outline-none block cursor-pointer border-b border-r border-gray-100 transition duration-150 ease-in-out hover:bg-gray-50 focus:bg-gray-50"
        >
            <div class="flex items-center px-2 py-4 xl:px-3">
                <div class="flex min-w-0 flex-1 items-center">
                    <div
                        class="min-w-0 flex-1 cursor-move gap-1 px-1 md:grid md:grid-cols-2 xl:gap-2 xl:px-2"
                    >
                        <div>
                            <div
                                class="truncate text-sm font-medium leading-5 text-indigo-600"
                            >
                                <ClickToEdit
                                    class="cursor-pointer"
                                    :value="area.name"
                                    :label="'name'"
                                    v-on:input="updateAreaName"
                                />
                            </div>
                        </div>
                        <div class="hidden md:block">
                            <div class="ml-1 flex flex-shrink-0 justify-end">
                                <span
                                    class="ml-1 inline-flex rounded-full bg-blue-200 px-2 text-xs font-semibold leading-5 text-blue-400"
                                    v-if="isSnoozed(area)"
                                >
                                    Snoozed for

                                    {{
                                        area.disabledUntil
                                            | moment('from', 'now', true)
                                    }}
                                </span>
                                <span
                                    class="ml-1 inline-flex rounded-full bg-gray-200 px-2 text-xs font-semibold leading-5 text-gray-400"
                                    v-else-if="!area.active"
                                >
                                    Inactive
                                </span>
                                <span
                                    v-else
                                    class="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800"
                                >
                                    {{ area.tables.length }} Tables
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <svg
                        class="h-5 w-5 text-gray-400"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                    >
                        <path
                            fill-rule="evenodd"
                            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                            clip-rule="evenodd"
                        />
                    </svg>
                </div>
            </div>
        </span>
    </li>
</template>
<script>
import { ElementMixin } from 'vue-slicksort';
import ClickToEdit from '../helpers/ClickToEdit';

export default {
    name: 'AreaListOption',
    mixins: [ElementMixin],
    components: {
        ClickToEdit
    },
    props: {
        area: {
            type: Object,
            required: true,
            default: () => ({})
        },
        index: {
            type: Number,
            required: true,
            default: 0
        },
        selectedTableAreaIndex: {
            type: Number,
            required: true,
            default: 0
        }
    },
    methods: {
        isSnoozed(area) {
            const until = this.$moment(area.disabledUntil);

            return until.isAfter();
        },
        selectTableArea(area, index) {
            this.$emit('selectTableArea', area, index);
        },

        async updateAreaName(name) {
            if (name) {
                try {
                    await this.$axios.patch('/venues/areas/' + this.area.id, {
                        name
                    });

                    this.area.name = name;
                } catch (error) {
                    throw new Error(`API ${error}`);
                } finally {
                    this.isLoading = false;
                }
            }
        }
    }
};
</script>
