var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-4 rounded-md bg-white shadow",class:{ 'opacity-50': _vm.menu.archived }},[_c('div',[_c('div',{staticClass:"flex flex-wrap items-center justify-between border-b border-gray-200 px-4 py-5 shadow-sm sm:flex-nowrap sm:px-6"},[_c('div',[_c('h3',{staticClass:"font-heading text-base font-medium flex items-center leading-6 text-gray-900"},[_c('span',{staticClass:"text-indigo-600 cursor-pointer",on:{"click":function($event){return _vm.$router.push({
                                name: 'editMenu',
                                params: { id: _vm.menu.id }
                            })}}},[_vm._v(" "+_vm._s(_vm.menu.name)+" ")]),(_vm.menu.venue && _vm.menu.venue.name)?_c('venue-name-tag',{staticClass:"hidden sm:inline-block ml-3 bg-gray-50",attrs:{"name":_vm.menu.venue.adminName || _vm.menu.venue.name,"truncate-length":25}}):_vm._e()],1),(_vm.menu.lastSyncedAt)?_c('span',{staticClass:"text-xs"},[_vm._v("Synced: "+_vm._s(_vm._f("moment")(_vm.menu.lastSyncedAt,'from', 'now'))+" ")]):_vm._e(),_c('p',{staticClass:"mt-1 max-w-2xl text-xs leading-5"},[_vm._v(" Created: "+_vm._s(_vm._f("moment")(_vm.menu.created_at,'LL'))+" ")])]),_c('div',[(_vm.isFeatureAvailable(_vm.FeaturesEnum.MULTI_MENU))?_c('div',{staticClass:"inline-flex items-center"},[_c('span',{staticClass:"mr-3 text-sm leading-5 text-gray-500"},[_vm._v(" Toggle active / inactive ")]),_c('toggle-switch',{on:{"input":function($event){return _vm.updateMenuStatus(_vm.menu.id, _vm.menu.active)}},model:{value:(_vm.menu.active),callback:function ($$v) {_vm.$set(_vm.menu, "active", $$v)},expression:"menu.active"}})],1):_vm._e(),_c('div',{staticClass:"mt-2 flex flex-shrink-0 flex-col items-center sm:ml-4"},[_c('base-button',{attrs:{"buttonText":"Edit menu","size":"sm"},on:{"clicked":function($event){return _vm.$router.push({
                                name: 'editMenu',
                                params: { id: _vm.menu.id }
                            })}}})],1)])]),(_vm.menu.statistics && _vm.menu.statistics.productsCount > 0)?_c('div',{staticClass:"grid grid-cols-1 gap-5 rounded-b-md bg-gray-50 px-4 py-3 text-xs text-gray-600 shadow-inner sm:grid-cols-5 sm:px-6"},[_c('div',{staticClass:"flex flex-col"},[_c('span',{},[_vm._v(" "+_vm._s(_vm.menu.statistics.productsCount)+" menu items ")]),_c('span',{},[_vm._v(" "+_vm._s(_vm.menu.statistics.categoriesCount)+" categories ")])]),_c('div',{staticClass:"ml-0 flex flex-col sm:ml-4"},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                        _vm.menu.statistics.productsCount -
                        _vm.menu.statistics.missingImage +
                        '/' +
                        _vm.menu.statistics.productsCount +
                        ' items have images. Adding images to products increases revenue up to 30%.'
                    ),expression:"\n                        menu.statistics.productsCount -\n                        menu.statistics.missingImage +\n                        '/' +\n                        menu.statistics.productsCount +\n                        ' items have images. Adding images to products increases revenue up to 30%.'\n                    "}]},[_vm._v(" Item images ")]),_c('div',{staticClass:"mt-1 h-2 w-full rounded-full bg-gray-200 shadow-inner dark:bg-gray-700"},[_c('div',{staticClass:"h-2 rounded-full",class:_vm.progressBarColour(
                                _vm.menu.statistics.imageCompletePercentage
                            ),style:('width: ' +
                            _vm.menu.statistics.imageCompletePercentage +
                            '%')})])]),_c('div',{staticClass:"ml-0 flex flex-col sm:ml-4"},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                        _vm.menu.statistics.productsCount -
                        _vm.menu.statistics.missingDescription +
                        '/' +
                        _vm.menu.statistics.productsCount +
                        ' items have descriptions. Add descriptions to each menu item to help guests.'
                    ),expression:"\n                        menu.statistics.productsCount -\n                        menu.statistics.missingDescription +\n                        '/' +\n                        menu.statistics.productsCount +\n                        ' items have descriptions. Add descriptions to each menu item to help guests.'\n                    "}]},[_vm._v(" Item descriptions ")]),_c('div',{staticClass:"mt-1 h-2 w-full rounded-full bg-gray-200 shadow-inner"},[_c('div',{staticClass:"h-2 rounded-full",class:_vm.progressBarColour(
                                _vm.menu.statistics
                                    .descriptionCompletePercentage
                            ),style:('width: ' +
                            _vm.menu.statistics.descriptionCompletePercentage +
                            '%')})])]),_c('div',{staticClass:"ml-0 flex flex-col sm:ml-4"},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                        _vm.menu.statistics.productsCount -
                        _vm.menu.statistics.missingTags +
                        '/' +
                        _vm.menu.statistics.productsCount +
                        ' items have dietary or allergen tags. Add allergen tags for compliance and dietary tags to help guests filter dishes.'
                    ),expression:"\n                        menu.statistics.productsCount -\n                        menu.statistics.missingTags +\n                        '/' +\n                        menu.statistics.productsCount +\n                        ' items have dietary or allergen tags. Add allergen tags for compliance and dietary tags to help guests filter dishes.'\n                    "}]},[_vm._v(" Diets & allergens ")]),_c('div',{staticClass:"mt-1 h-2 w-full rounded-full bg-gray-200 shadow-inner"},[_c('div',{staticClass:"h-2 rounded-full",class:_vm.progressBarColour(
                                _vm.menu.statistics.tagCompletePercentage
                            ),style:('width: ' +
                            _vm.menu.statistics.tagCompletePercentage +
                            '%')})])])]):_vm._e(),(_vm.menu.syndication && _vm.menu.syndication.length)?_c('div',{staticClass:"px-4 py-5 sm:px-6"},[_c('base-opentable-logo',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Published on Open Table'),expression:"'Published on Open Table'"}],staticClass:"h-auto w-20"})],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }