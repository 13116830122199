var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_vm._m(0),(_vm.modifierGroups && _vm.modifierGroups.length > 0)?_c('div',{staticClass:"flex flex-col mt-4"},[_c('div',{staticClass:"py-2"},[_c('div',{staticClass:"inline-block align-middle w-full border border-gray-200 rounded overflow-hidden"},[_c('table',{staticClass:"table-fixed w-full"},[_vm._m(1),_c('tbody',{staticClass:"bg-white"},_vm._l((_vm.modifierGroups),function(group){return _c('tr',{key:group.id,staticClass:"w-full bg-white"},[_c('td',{staticClass:"whitespace-nowrap pl-4 pr-4 py-4 w-8/12 text-gray-400 truncate text-sm font-medium leading-4 border-b border-gray-200"},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                                            group.name.length > 50
                                                ? group.name
                                                : false
                                        ),expression:"\n                                            group.name.length > 50\n                                                ? group.name\n                                                : false\n                                        "}],staticClass:"inline-block text-gray-500",attrs:{"title":"group.name"}},[_vm._v(" "+_vm._s(group.name)+" ")]),(group.plu)?_c('span',{staticClass:"inline-block pl-3 text-gray-400 text-xs"},[_vm._v("SKU: "+_vm._s(group.plu))]):_vm._e(),_c('span',{staticClass:"ml-2 inline-block cursor-pointer text-xs"},[_c('v-dropdown',{attrs:{"distance":"5","triggers":['click'],"popperClass":"modifier-items-popover"},scopedSlots:_vm._u([{key:"popper",fn:function(){return [_c('modifier-group-items-tooltip',{attrs:{"items":group.products}})]},proxy:true}],null,true)},[(group.products.length)?_c('div',{staticClass:"group flex select-none items-center rounded-md px-4 py-2 hover:bg-gray-100"},[_c('span',[_vm._v("items")]),_c('span',{staticClass:"m-auto block h-4 w-4 text-gray-600 opacity-0 group-hover:opacity-100"},[_c('svg',{attrs:{"viewBox":"0 0 20 20","fill":"currentColor","focusable":"false","aria-hidden":"true"}},[_c('path',{attrs:{"d":"M5 8l5 5 5-5H5z"}})])])]):_vm._e()])],1)]),_c('td',{staticClass:"whitespace-nowrap px-4 py-4 w-4/12 text-right text-sm font-medium leading-4 border-b border-gray-200"},[(_vm.isLoading && group.isLoading)?_c('span',{staticClass:"spinner h-40"}):_c('div',[(
                                                !_vm.productHasModifier(
                                                    group.id
                                                )
                                            )?_c('span',{staticClass:"text-indigo-600 hover:text-indigo-900 cursor-pointer",on:{"click":function($event){return _vm.addGroupToProduct(group)}}},[_vm._v(" Add group to product ")]):_c('span',{staticClass:"text-gray-600"},[_vm._v(" Already added ")])])])])}),0)])])])]):(_vm.isLoading)?_c('div',{staticClass:"spinner h-40"}):_c('div',{staticClass:"flex items-center justify-center h-40"},[_c('span',{staticClass:"text-gray-600 font-medium"},[_vm._v(" No modifier groups available ")])]),_c('div',{staticClass:"flex-row-reverse justify-between mt-5 select-none sm:flex sm:mt-12 sm:w-auto"},[_c('div',{staticClass:"flex flex-col md:flex-row"},[_c('span',{staticClass:"flex mt-3 w-full rounded-md shadow-sm sm:mt-0 sm:w-auto"},[_c('button',{staticClass:"inline-flex justify-center px-4 py-2 w-full hover:text-gray-500 text-gray-700 text-base font-medium leading-6 bg-white border focus:border-blue-300 border-gray-300 rounded-md focus:outline-none focus:shadow-outline shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('stop-editing')}}},[_vm._v(" Go Back ")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"text-gray-500 text-sm leading-5"},[_vm._v(" Use an existing modifier group. Note that any changes made to a shared modifier group will be applied against all products ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticClass:"px-4 py-3 w-8/12 text-left text-gray-500 truncate text-xs font-medium tracking-wider leading-4 bg-gray-50 border-b border-gray-200 uppercase hover:"},[_vm._v(" Group ")]),_c('th',{staticClass:"px-4 py-3 w-4/12 bg-gray-50 border-b border-gray-200"})])])
}]

export { render, staticRenderFns }