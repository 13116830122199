<template>
    <div class="flex w-0 flex-1 flex-col overflow-scroll sm:overflow-hidden">
        <main class="focus:outline-none relative z-0 h-full flex-1">
            <div
                class="mx-auto flex max-w-7xl flex-row items-center justify-between px-4 sm:px-6 lg:px-8"
            >
                <div
                    class="h-4 w-4"
                    :class="{ 'text-gray-900 spinner': isLoading }"
                >
                    <span></span>
                </div>
            </div>
            <div
                class="mx-auto my-6 flex h-full max-w-7xl flex-col justify-center px-4 sm:my-0 sm:px-6 md:px-8"
            >
                <base-page-title title="Now, let's setup your menu" />

                <div
                    class="cards-container relative flex flex-col gap-x-3 py-2 pb-4 md:flex-row md:overflow-x-scroll"
                >
                    <div
                        v-for="option in importOptions"
                        :key="option.title"
                        class="card mt-6 flex w-full flex-row bg-white shadow sm:rounded-lg md:w-1/3 md:flex-shrink-0"
                    >
                        <div class="flex w-full flex-col px-4 py-5 sm:p-6">
                            <h3
                                class="font-heading text-lg font-semibold leading-6 text-gray-900"
                            >
                                {{ option.title }}
                            </h3>
                            <div class="mt-2 text-sm leading-5 text-gray-500">
                                <p>
                                    {{ option.description }}
                                </p>
                            </div>
                            <div class="mt-auto justify-end">
                                <div class="mt-8 flex items-center">
                                    <div
                                        class="flex items-center text-gray-500"
                                    >
                                        <base-clock-icon class="h-4 w-4" />
                                        <span class="ml-1 text-sm">{{
                                            option.speed
                                        }}</span>
                                    </div>
                                    <div
                                        class="ml-3 flex items-center text-gray-500"
                                    >
                                        <base-credit-card-icon
                                            class="h-4 w-4"
                                        />
                                        <span class="ml-1 text-sm">{{
                                            option.cost
                                        }}</span>
                                    </div>
                                </div>
                                <div>
                                    <span
                                        class="mt-3 inline-flex rounded-md shadow-sm sm:w-auto"
                                        :class="{
                                            'button-spinner spinner': isLoading
                                        }"
                                    >
                                        <button
                                            @click="option.action"
                                            :disabled="isLoading"
                                            type="button"
                                            class="focus:outline-none inline-flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 font-medium text-white transition duration-150 ease-in-out hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 sm:w-auto sm:text-sm sm:leading-5"
                                        >
                                            {{ option.actionText }}
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div
                        class="mt-6 flex flex-col pl-2 sm:flex-row sm:flex-wrap"
                    >
                        <button
                            @click="skip"
                            type="button"
                            class="focus:outline-none group -ml-2 mt-2 flex cursor-pointer items-center rounded-md rounded-sm p-1.5 text-sm leading-5 text-gray-600 hover:bg-gray-200 hover:text-gray-700 sm:mr-6"
                        >
                            I'll do it later
                            <svg
                                class="ml-1.5 h-5 w-5 flex-shrink-0 text-gray-500 group-hover:text-gray-600"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                                ></path>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'createMenu',
    data() {
        return {
            isLoading: false,
            categories: [],
            menuId: null,
            newCategory: {},
            importOptions: [
                {
                    title: 'Enter manually',
                    subtitle: 'Import your menu from a file',
                    description:
                        'Enter your menu items in our easy to use menu builder.',
                    speed: '10-30 mins',
                    cost: 'Free',
                    action: () => {
                        this.createMenu();
                    },
                    actionText: 'Create a menu'
                },
                {
                    title: 'Import from spreadsheet',
                    subtitle: 'Import your menu from a file',
                    description:
                        'Import all your menu items from a CSV or Excel file you prepare.',
                    speed: '5 -10 mins',
                    cost: 'Free',
                    action: () => {
                        this.$router.push({
                            name: 'menuImportSpreadsheet'
                        });
                    },
                    actionText: 'Import a menu'
                },
                {
                    title: 'Import from a PDF or website',
                    subtitle: 'Import your menu from a file',
                    description:
                        "Give us a PDF or website link for your menu and we'll do the hard work of getting it into storekit.",
                    speed: '2-3 days',
                    cost: '3 free imports per year',
                    action: () => {
                        this.$router.push({
                            name: 'createMenuImport',
                            params: { id: this.menuId }
                        });
                    },
                    actionText: 'Import a menu'
                }
                // {
                //     title: 'Import from a POS system',
                //     subtitle: 'Import your menu from a file',
                //     description:
                //         'Connect to your POS system to automatically sync your menu into storekit',
                //     speed: '3-5 days',
                //     cost: 'Subscription required',
                //     action: () => {
                //         this.$router.push({
                //             name: 'createMenuImport',
                //             params: { id: this.menuId }
                //         });
                //     },
                //     actionText: 'Connect a POS'
                // }
            ]
        };
    },
    computed: {
        ...mapGetters({ user: 'user/currentUser' })
    },
    methods: {
        ...mapActions({
            fetchVenues: 'venues/fetch'
        }),
        async createCategory() {
            const savedCategory = this.newCategory;
            this.isLoading = true;
            this.newCategory = {};

            try {
                await this.$axios.post(
                    `/categories/${this.menuId}`,
                    savedCategory
                );

                this.$router.push({
                    name: 'editMenu',
                    params: { id: this.menuId }
                });
            } catch (e) {
                this.newCategory = savedCategory;
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        },
        async createMenu() {
            const { venueId } = this.$route.params;
            this.isLoading = true;

            try {
                const { data } = await this.$axios.post('/menus', {
                    venueId,
                    name: 'Default Menu',
                    version: 0
                });

                if (!data || !data.id) {
                    return;
                }

                this.menuId = data.id;

                // await this.createCategory();

                if (window.analytics) {
                    window.analytics.track('Menu Created', {
                        menuId: data.id,
                        venueId,
                        accountId: this.user.accounts[0].id,
                        context: {
                            groupId: this.user.accounts[0].id
                        }
                    });
                }

                this.fetchVenues();

                await this.$router.push({
                    name: 'editMenu',
                    params: { id: this.menuId }
                });
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        },
        skip() {
            this.$router.push({ name: 'dashboard' });
        }
    }
};
</script>

<style scoped>
.spinner.button-spinner:after {
    left: auto;
    right: 1em;
    border-right-color: white;
}

.spinner.slug-spinner {
    color: black;
    pointer-events: all;
}

.spinner.slug-spinner:after {
    left: auto;
    right: 1em;
}

@media only screen and (max-width: 768px) {
    .card {
        width: 100%;
    }
}

/*.cards-container::after {*/
/*    content: '';*/
/*    position: absolute;*/
/*    top: 0;*/
/*    right: 0;*/
/*    width: 25px;*/
/*    height: 100%;*/
/*    background-image: linear-gradient(*/
/*        to right,*/
/*        rgba(255, 255, 255, 0) 0%,*/
/*        white 80%,*/
/*        white 100%*/
/*    );*/
/*}*/
</style>
