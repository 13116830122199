<template>
    <div class="custom-selector">
        <div class="relative shadow-sm">
            <multi-select
                ref="multiselect"
                v-model="selectedVenues"
                :options="venuesOptions"
                :multiple="multipleSelect"
                :close-on-select="!multipleSelect"
                :searchable="false"
                :preselect-first="!allowEmpty"
                :deselectLabel="venues.length > 2 ? 'Remove' : ' '"
                :selectLabel="venues.length > 2 ? 'Select' : ' '"
                :allow-empty="allowEmpty"
                :maxHeight="400"
                label="name"
                track-by="id"
                class="cursor-pointer"
                :class="{
                    active: selectedVenues,
                    'sm-selector': size === 'sm'
                }"
                @input="!onClose ? updateSelection(selectedVenues) : null"
                @close="onClose ? updateSelection(selectedVenues) : null"
            >
                <template
                    v-if="multipleSelect"
                    slot="selection"
                    slot-scope="{ values }"
                >
                    <span
                        v-if="values.length"
                        class="ml-1 font-medium text-gray-700"
                        :class="{ 'text-xs': size === 'sm' }"
                    >
                        {{ values.length }}
                        {{ values.length > 1 ? 'stores' : 'store' }} selected
                    </span>
                </template>

                <template slot="singleLabel" slot-scope="{ option }">
                    <span
                        class="truncate font-medium text-gray-700"
                        :class="{ 'text-xs': size === 'sm' }"
                    >
                        {{
                            option.adminName ||
                            option.name | truncateString(truncateLength)
                        }}
                    </span>
                </template>

                <template slot="placeholder">
                    <span
                        class="ml-1 select-none font-medium font-medium text-gray-700"
                        :class="{ 'text-xs': size === 'sm' }"
                    >
                        {{ placeholder }}
                    </span>
                </template>

                <template slot="caret">
                    <div
                        class="absolute right-0 top-0 flex min-h-full items-center px-3"
                    >
                        <svg
                            class="h-5 w-5"
                            :class="{ 'h-4 w-4': size === 'sm' }"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                clip-rule="evenodd"
                            />
                        </svg>
                    </div>
                </template>

                <template slot="option" slot-scope="props">
                    <div
                        v-if="!props.option.selectAllOption"
                        class="flex items-center justify-start"
                    >
                        <svg
                            v-if="!props.option.id && allowEmpty"
                            fill="none"
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            viewBox="0 0 24 24"
                            class="mr-2 inline-block h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500"
                        >
                            <path d="M4 6h16M4 10h16M4 14h16M4 18h16"></path>
                        </svg>
                        <span
                            v-else
                            :aria-label="
                                props.option.enabled ? 'Online' : 'Offline'
                            "
                            :class="{
                                'bg-green-400': props.option.enabled,
                                'bg-gray-200': !props.option.enabled
                            }"
                            class="mr-2 inline-block h-2 w-2 flex-shrink-0 rounded-full"
                        >
                        </span>
                        <div
                            class="leading-2 relative inline-block h-4 w-48 overflow-hidden whitespace-nowrap"
                            :class="{
                                'scroll-truncated':
                                    props.option.name.length > 30
                            }"
                        >
                            <span
                                :class="{
                                    'line-through': props.option.archived
                                }"
                                class="absolute translate-y-0 transform whitespace-nowrap pr-3"
                            >
                                {{
                                    props.option.adminName || props.option.name
                                }}
                            </span>
                        </div>
                    </div>
                    <div v-else class="flex items-center justify-center">
                        <span style="font-weight: 500 !important">
                            {{ props.option.adminName || props.option.name }}
                        </span>
                    </div>
                </template>
            </multi-select>
        </div>
    </div>
</template>

<script>
export default {
    name: 'VenueSelector',
    data() {
        return {
            showVenueFilter: null,
            venuesOptions: [],
            selectedVenues: [],
            selectAllStoresName: 'Select all Stores',
            deselectAllStoresName: 'Deselect all Stores'
        };
    },
    props: {
        venues: {
            type: [Array, Number],
            required: true
        },
        multipleSelect: {
            type: Boolean,
            required: false,
            default: false
        },
        preSelected: {
            type: [Array, Number],
            required: false
        },
        allowEmpty: {
            type: Boolean,
            required: false,
            default: false
        },
        truncateLength: {
            type: Number,
            required: false,
            default: 20
        },
        onClose: {
            type: Boolean,
            required: false,
            default: false
        },
        defaultVenueSelect: {
            type: Number,
            required: false,
            default: 0
        },
        isReport: {
            type: Boolean,
            required: false,
            default: false
        },
        size: {
            type: String,
            default: 'md',
            required: false
        },
        hideAllStoresOption: {
            type: Boolean,
            required: false,
            default: false
        },
        placeholder: {
            type: String,
            required: false,
            default: 'Select store'
        }
    },
    mounted() {
        this.selectedVenues = [];

        this.venuesOptions = [...this.venues];

        if (
            this.venuesOptions.length > 1 &&
            this.multipleSelect &&
            this.isReport
        ) {
            this.venuesOptions.unshift({
                name: this.selectAllStoresName,
                selectAllOption: true,
                selectedAll: false,
                isReset: false
            });
        }

        if (
            this.venuesOptions.length > 1 &&
            this.allowEmpty &&
            !this.hideAllStoresOption
        ) {
            this.venuesOptions.unshift({ name: 'All stores', isReset: true });
        }

        if (this.defaultVenueSelect) {
            const venue = this.venues.find(
                venue => venue.id === this.defaultVenueSelect
            );

            this.selectedVenues = [venue];

            return;
        }

        if (!this.preSelected) {
            this.selectedVenues = null;

            return;
        }

        if (!this.preSelected.length) {
            this.selectedVenues = this.venueById(this.preSelected);

            return;
        }

        for (const item of this.preSelected) {
            const venue = this.venueById(parseInt(item));

            if (venue) {
                this.selectedVenues.push(venue);
            }
        }
    },
    methods: {
        venueById(id) {
            return this.venues.find(venue => venue.id === id);
        },
        updateSelection(selectedData) {
            return this.multipleSelect
                ? this.updateMultipleSelection(selectedData)
                : this.updateSingleSelection(selectedData);
        },
        updateMultipleSelection(selectedOptions) {
            const isResetOption = selectedOptions
                ? selectedOptions.some(selectedOption => selectedOption.isReset)
                : null;

            const selectAllOption = this.venuesOptions.find(
                venueOption => venueOption.selectAllOption
            );

            if (isResetOption && selectAllOption) {
                selectAllOption.selectedAll = false;
                selectAllOption.isReset = false;
                selectAllOption.name = this.selectAllStoresName;
            }

            if (!selectedOptions || !selectedOptions.length || isResetOption) {
                this.selectedVenues = null;

                return this.$emit('selected', this.selectedVenues);
            }

            const selectAll = selectedOptions.find(
                selectedOption => selectedOption.selectAllOption
            );

            const index = this.venuesOptions.findIndex(
                venueOption => venueOption.selectAllOption
            );

            if (selectAll) {
                return this.selectAllVenues(selectAllOption, index);
            }

            const selectedVenuesToUpdate = selectedOptions.filter(
                selectedOption => selectedOption.id
            );

            if (selectAllOption) {
                const isAllSelected =
                    this.venues.length === selectedVenuesToUpdate.length;

                selectAllOption.selectedAll = isAllSelected;
                selectAllOption.isReset = isAllSelected;
                selectAllOption.name = isAllSelected
                    ? this.deselectAllStoresName
                    : this.selectAllStoresName;

                this.venuesOptions.splice(index, 1, selectAllOption);
            }

            const venuesIds = selectedVenuesToUpdate.map(
                selectedVenueToUpdate => selectedVenueToUpdate.id
            );

            this.$emit('selected', venuesIds);
        },
        updateSingleSelection(selectedOption) {
            if (!selectedOption) {
                this.selectedVenues = null;

                return this.$emit('selected', this.selectedVenues);
            }

            return this.$emit('selected', selectedOption.id);
        },
        selectAllVenues(selectAllOption, index) {
            selectAllOption.selectedAll = true;
            selectAllOption.isReset = true;
            selectAllOption.name = this.deselectAllStoresName;

            this.venuesOptions.splice(index, 1, selectAllOption);

            this.selectedVenues = this.venues;

            const venuesIds = this.venues.map(venue => venue.id);

            this.$emit('selected', venuesIds);
        }
    }
};
</script>

<style src="@/assets/css/custom-selector.css" scoped></style>
