<template>
    <modal
        name="snooze-table-modal"
        width="600px"
        height="auto"
        class="snooze-item-availability-modal overflow-visible"
        :clickToClose="true"
        @before-open="beforeOpen"
        :adaptive="true"
    >
        <div
            class="transform rounded-lg bg-white p-6 shadow-xl transition-all sm:w-full"
        >
            <div class="text-left" v-if="!isSnoozed">
                <h3
                    class="font-heading text-lg font-medium leading-6 text-gray-900"
                >
                    Disable
                    {{ type === 'category' ? 'category' : 'orders' }} for:
                </h3>
                <div class="mt-4 flex items-center justify-between">
                    <div class="flex w-full items-center">
                        <label
                            v-for="(option, key) in options"
                            @click="selectOption(option.value)"
                            :key="key"
                            class="focus:outline-none mr-2 flex cursor-pointer items-center justify-center rounded-md border py-2 px-3 text-sm font-medium transition-colors hover:border-indigo-600 sm:flex-1"
                            :class="{
                                'cursor-not-allowed opacity-25': !option.active,
                                'border-transparent bg-indigo-600 text-white hover:bg-indigo-700 hover:text-white':
                                    optionSelected === option.value,
                                'hover:text-indigo-600':
                                    optionSelected !== option.value
                            }"
                        >
                            <span id="size-choice-0-label">
                                {{ option.label }}
                            </span>
                        </label>
                    </div>
                </div>
                <div class="mt-4 flex items-center sm:flex-1">
                    <span class="mr-2 min-w-max text-gray-600"> or until </span>
                    <DateRangeSelector
                        @date-updated="selectPicker"
                        :styles="pickerStyles"
                        aligned="left"
                        :singleDate="true"
                        :allowFuture="true"
                        :disallowPast="true"
                        :minDate="minSnoozeDate"
                        :maxDate="maxSnoozeDate"
                    />
                </div>
            </div>
            <div v-else>
                <div class="text-left">
                    <h3
                        class="font-heading text-lg font-medium leading-6 text-gray-900"
                    >
                        Remove snooze
                    </h3>
                    <p class="mt-2">
                        Currently snoozed for
                        {{ disabledUntil | moment('from', 'now', true) }}</p
                    >
                </div>
            </div>
            <div
                class="mt-5 flex-row-reverse justify-between sm:mt-8 sm:flex sm:w-auto"
                :class="{ spinner: isLoading }"
            >
                <span
                    class="flex w-full rounded-md shadow-sm md:w-36 lg:w-auto"
                >
                    <button
                        v-if="!isSnoozed"
                        @click="snooze"
                        type="button"
                        :disabled="!optionSelected && !dateSelected"
                        class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo sm:text-sm sm:leading-5"
                        :class="{
                            'cursor-not-allowed opacity-50':
                                !optionSelected && !dateSelected
                        }"
                    >
                        Disable
                        {{ type === 'category' ? 'category' : 'orders' }}
                    </button>
                    <button
                        v-else
                        @click="unSnooze"
                        type="button"
                        class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo sm:text-sm sm:leading-5"
                    >
                        Enable {{ type === 'category' ? 'category' : 'orders' }}
                    </button>
                </span>
                <span
                    class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto"
                >
                    <button
                        @click="$modal.hide('snooze-table-modal')"
                        type="button"
                        class="focus:outline-none inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium leading-6 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline sm:text-sm sm:leading-5"
                    >
                        Close
                    </button>
                </span>
            </div>
        </div>
    </modal>
</template>

<script>
import DateRangeSelector from '@/components/widgets/DateRangeSelector';
export default {
    name: 'SnoozeTableModal',
    components: { DateRangeSelector },
    data() {
        return {
            isLoading: false,
            options: [
                { label: '5 mins', value: 5, active: true },
                { label: '10 mins', value: 10, active: true },
                { label: '30 mins', value: 30, active: true },
                { label: '1 H', value: 60, active: true },
                { label: '2 H', value: 120, active: true },
                { label: '12 H', value: 720, active: true }
            ],
            pickerStyles: 'min-w-max rounded-md transition-color font-medium',
            dateSelected: null,
            optionSelected: null,
            disabledUntil: null,
            item: null,
            type: null
        };
    },
    watch: {
        dateSelected(value) {
            this.pickerStyles =
                'min-w-max rounded-md transition-color font-medium ' +
                (value
                    ? 'bg-indigo-600 text-white hover:text-white border-indigo-600'
                    : 'hover:border-indigo-600 hover:text-indigo-600');
        }
    },
    methods: {
        beforeOpen({ params }) {
            Object.assign(this.$data, this.$options.data.apply(this));
            const { id, disabledUntil } = params.item;

            this.item = { id };
            this.disabledUntil = disabledUntil;
            this.type = params.type;
        },
        selectPicker({ endDate }) {
            this.dateSelected = endDate;
            this.optionSelected = null;
        },
        selectOption(option) {
            this.optionSelected = option;
            this.dateSelected = null;
        },
        async snooze() {
            let disabledForInMinutes = 0;

            if (this.dateSelected) {
                const rightNow = this.$moment();
                const endDateMoment = this.$moment(this.dateSelected);
                endDateMoment.set({ hour: 5, minute: 0, second: 0 });
                disabledForInMinutes = endDateMoment.diff(rightNow, 'minutes');
            } else {
                disabledForInMinutes = this.optionSelected;
            }

            try {
                this.isLoading = true;
                let result;

                if (this.type === 'area') {
                    result = await this.$axios.patch(
                        `/venues/areas/${this.item.id}`,
                        {
                            disabledForInMinutes
                        }
                    );

                    this.$emit('table-area-snoozed', result.data.disabledUntil);
                } else if (this.type === 'table') {
                    result = await this.$axios.put(`/tables/${this.item.id}`, {
                        disabledForInMinutes
                    });

                    this.$emit('table-snoozed', result.data.disabledUntil);
                } else if (this.type === 'category') {
                    result = await this.$axios.put(
                        `/categories/${this.item.id}`,
                        {
                            disabledForInMinutes
                        }
                    );

                    this.$emit('category-snoozed', {
                        id: this.item.id,
                        disabledUntil: result.data.disabledUntil
                    });

                    this.$notify({
                      group: 'settings',
                      duration: 2000,
                      text: `Category snoozed`,
                    });
                }

                this.$modal.hide('snooze-table-modal');
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        },
        async unSnooze() {
            try {
                this.isLoading = true;
                let result;

                if (this.type === 'area') {
                    result = await this.$axios.patch(
                        `/venues/areas/${this.item.id}`,
                        {
                            disabledForInMinutes: 0,
                            active: true
                        }
                    );

                    this.$emit('table-area-snoozed', result.data.disabledUntil);
                } else if (this.type === 'table') {
                    result = await this.$axios.put(`/tables/${this.item.id}`, {
                        disabledForInMinutes: 0,
                        active: true
                    });

                    this.$emit('table-snoozed', result.data.disabledUntil);
                } else if (this.type === 'category') {
                    result = await this.$axios.put(
                        `/categories/${this.item.id}`,
                        {
                            disabledForInMinutes: 0
                        }
                    );

                    this.$emit('category-snoozed', {
                        id: this.item.id,
                        disabledUntil: result.data.disabledUntil
                    });

                    this.$notify({
                      group: 'settings',
                      duration: 2000,
                      text: `Category unsnoozed`,
                    });
                }

                this.$modal.hide('snooze-table-modal');
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        }
    },
    computed: {
        isSnoozed() {
            const until = this.$moment(this.disabledUntil);

            return until.isAfter();
        },
        minSnoozeDate() {
            const today = new Date();
            const tomorrow = new Date(today);
            tomorrow.setDate(tomorrow.getDate() + 1);

            return tomorrow.toString();
        },
        maxSnoozeDate() {
            const maxDate = new Date();
            maxDate.setDate(maxDate.getDate() + 7);

            return maxDate.toString();
        }
    }
};
</script>

<style>
.snooze-venue-modal .vm--modal {
    overflow: visible;
}
</style>
