<template>
    <div class="pb-4">
        <div
            class="overflow-hidden bg-white shadow sm:rounded-md border border-gray-100"
            v-if="!isLoading"
        >
            <div
                class="hidden border-b border-gray-200  md:block bg-gray-100"
            >
                <div
                    class="ml-10 min-w-0 flex-1 px-4 text-xs md:grid md:grid-cols-3 md:gap-2 "
                >
                    <div class="py-2 leading-5 text-gray-600"> Product </div>
                    <div class="py-2 leading-5 text-gray-600">
                        Units sold
                    </div>
                    <div class="py-2 leading-5 text-gray-600"> Revenue </div>
                </div>
            </div>

            <ul class="py-2">
                <li v-for="product in products" :key="product.id">
                    <div href="#" class="block">
                        <div class="flex items-center px-2 pl-4 py-2">
                            <div class="flex min-w-0 flex-1 items-center">
                                <div class="flex-shrink-0">
                                    <img
                                        class="w-8 rounded-md"
                                        :src="
                                            product.image +
                                            '-/scale_crop/100x100/smart/-/format/auto/'
                                        "
                                        :alt="product.name"
                                        v-if="product.image"
                                    />
                                    <div
                                        class="h-8 w-8 rounded-md bg-gray-100 shadow-inner"
                                        v-else
                                    ></div>
                                </div>
                                <div
                                    class="min-w-0 flex-1 px-2 md:grid md:grid-cols-3 md:gap-4"
                                >
                                    <div>
                                        <div
                                            class="truncate text-sm font-medium leading-5 text-gray-600"
                                            >{{ product.name }}</div
                                        >
                                    </div>
                                    <div>
                                        <div
                                            class="truncate font-medium leading-5 text-gray-600"
                                            ><span
                                                class="text-sm text-gray-400 md:hidden"
                                                >Units sold: </span
                                            ><span class="font-mono text-sm">{{ product.sales }}</span></div
                                        >
                                    </div>
                                    <div>
                                        <div
                                            class="flex items-center font-medium leading-5 text-gray-600"
                                        >
                                            <span
                                                class="pr-1 text-sm text-gray-400 md:hidden"
                                                >Revenue:
                                            </span>
                                            <span v-if="product.price === 0"
                                                >–</span
                                            >
                                            <span v-else class="font-mono text-sm">
                                                {{
                                                    ((product.sales *
                                                        product.price) /
                                                        100)
                                                        | currency
                                                }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div
            class="flex flex-col overflow-hidden bg-white p-4 shadow sm:rounded-md"
            v-else
        >
            <div class="pb-3">
                <VclList :width="180" :height="220" />
            </div>
        </div>
    </div>
</template>

<script>
import { VclList } from 'vue-content-loading';

export default {
    name: 'HomepageTopItems',
    props: ['hasOnboarded'],
    components: {
        VclList
    },
    data() {
        return {
            isLoading: true
        };
    },
    async created() {
        this.fetchOrderCount();
    },
    methods: {
        fetchOrderCount() {
            this.isLoading = true;
            this.$axios
                .get('/reports/top-products')
                .then(res => {
                    this.products = res.data;
                })
                .catch(e => {
                    // this.$modal.show("api-error-modal");
                    throw new Error(`API ${e}`);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }
    }
};
</script>
