<template>
    <div
        class="flex w-0 flex-1 flex-col overflow-hidden print:overflow-visible"
        :class="{ 'opacity-50': isLoading }"
    >
        <ToggleSidebarButton />
        <main class="focus:outline-none relative z-0 flex-1 overflow-y-auto">
            <div
                class="mx-6 mx-auto max-w-7xl pb-6 pt-4 sm:px-6 md:py-6 lg:px-8"
            >
                <div
                    v-if="!isLoading"
                    class="flex flex-col items-center pb-2 sm:pb-2 md:grid md:grid-cols-3 md:flex-row md:pb-1 lg:pb-0"
                >
                    <div class="min-w-0 flex-1">
                        <router-link
                            :to="{ name: 'customers' }"
                            class="mb-2 flex items-center text-sm text-gray-500 inline-flex"
                        >
                            <base-back-icon />
                            <span>Customers</span>
                        </router-link>

                        <base-page-title
                            :title="`${customer.firstname || ''} ${customer.lastname || ''}`"
                    /></div>
                </div>

                <div class="sm:grid md:grid md:grid-cols-3 md:gap-4">
                    <div class="md:col-span-2 lg:col-span-2">
                        <div
                            class="mx-auto grid-rows-2 self-stretch rounded-md bg-white pb-0 shadow sm:mt-2 lg:mt-3"
                            v-if="!isLoading"
                        >
                            <div class="row-1">
                                <div
                                    class="row-1 flex items-center px-4 py-4 sm:px-6"
                                >
                                    <div class="h-10 w-10 flex-shrink-0">
                                        <avatar
                                            v-if="
                                                customer && customer.firstname
                                            "
                                            :username="
                                                customer.firstname +
                                                ' ' +
                                                customer.lastname
                                            "
                                            :size="36"
                                        />
                                    </div>
                                    <div class="ml-4">
                                        <div
                                            class="text-base font-semibold leading-5 text-gray-800"
                                        >
                                            {{ customer.firstname }}
                                            {{ customer.lastname }}
                                        </div>
                                        <div
                                            class="text-sm leading-5 text-gray-500"
                                        >
                                            Customer for about
                                            {{ timeOfCostumerExistance }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="row-2 border-t border-gray-200 px-4 py-5 sm:px-6"
                            >
                                <div class="flex flex-wrap items-stretch">
                                    <div class="flex-1">
                                        <div
                                            class="text-center"
                                            v-if="orders.length"
                                        >
                                            <router-link
                                                :to="{
                                                    name: 'orderDetail',
                                                    params: {
                                                        id: orders[0].id
                                                    }
                                                }"
                                                class="mt-3 text-sm text-gray-500"
                                                >Last Order</router-link
                                            >
                                            <h3
                                                class="mt-1.5 text-base font-medium text-gray-700"
                                                >{{
                                                    orders[0].created_at
                                                        | moment('from', 'now')
                                                }}</h3
                                            >
                                        </div>
                                    </div>
                                    <div class="flex-1">
                                        <div class="text-center">
                                            <span
                                                class="mt-3 text-sm text-gray-500"
                                                >Total spent</span
                                            >
                                            <h3
                                                class="mt-1.5 text-base font-medium text-gray-700"
                                                >{{
                                                    (customer.totalOrderValue /
                                                        100)
                                                        | currency
                                                }}</h3
                                            >
                                            <span
                                                class="mt-1.5 text-sm text-gray-500"
                                            >
                                                {{ customer.orderCount }} orders
                                            </span>
                                        </div>
                                    </div>
                                    <div class="flex-1">
                                        <div class="text-center">
                                            <span
                                                class="mt-3 text-sm text-gray-500"
                                                >Average order value</span
                                            >
                                            <h3
                                                class="mt-1.5 text-base font-medium text-gray-700"
                                                >{{
                                                    (customer.totalOrderValue /
                                                        customer.orderCount /
                                                        100)
                                                        | currency
                                                }}</h3
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            class="mx-auto grid-rows-2 self-stretch rounded-md bg-white p-5 pb-0 shadow sm:mt-2 lg:mt-3"
                            v-else
                        >
                            <VclList :width="400" :height="200" />
                        </div>

                        <div
                            v-if="!isLoading"
                            class="z-5 mx-auto self-stretch rounded-md bg-white p-3 px-4 pb-3 shadow sm:mt-2 sm:px-6 sm:pb-5 md:col-span-2 lg:mt-3 lg:pb-7"
                        >
                            <div class="flex border-gray-200">
                                <div class="w-full">
                                    <apex-chart
                                        type="pie"
                                        width="250"
                                        :options="chartOptions"
                                        :series="customerInsightData"
                                    ></apex-chart>
                                </div>
                                <div class="w-full">
                                    <CustomerTopItems
                                        :items="customer.topItems"
                                    ></CustomerTopItems>
                                </div>
                            </div>
                        </div>

                        <div
                            class="z-5 mx-auto self-stretch rounded-md bg-white p-3 px-4 pb-3 pt-6 shadow sm:mt-2 sm:px-6 sm:pb-5 md:col-span-2 lg:mt-3 lg:pb-7"
                        >
                            <span
                                class="pt-2 text-base font-heading leading-5 text-gray-800"
                                >Activity</span
                            >
                            <customer-orders
                                :orders="orders"
                                :is-loading="!!isLoading"
                            />
                        </div>
                    </div>
                    <div
                        class="z-5 md:grid-gap-3 mt-2 grid grid-rows-3 gap-y-4 self-stretch overflow-hidden rounded-md bg-white shadow sm:mt-2 sm:flex-nowrap md:ml-1 md:flex-nowrap lg:mt-3"
                        style="height: max-content"
                    >
                        <div
                            class="border-b border-gray-200 px-4 py-5 sm:px-6"
                            v-if="!isLoading"
                        >
                            <span
                                class="mb-3 block text-base font-semibold leading-5 text-gray-800"
                                >Customer details</span
                            >
                            <div
                                class="mt-2 flex items-center text-sm leading-5 text-gray-400"
                                v-if="customer.phone"
                            >
                                <base-phone-icon class="mr-2 flex-shrink-0 h-4 w-4" />
                                <a
                                    :href="'tel:' + customer.phone"
                                    class="text-gray-900"
                                    target="_blank"
                                    >{{ customer.phone }}</a
                                >
                            </div>
                            <div
                                class="mt-2 flex items-center text-sm leading-5 text-gray-400"
                                v-if="customer.email"
                            >
                                <base-email-icon class="mr-2 flex-shrink-0" />

                                <a
                                    :href="'mailto:' + customer.email"
                                    target="_blank"
                                    class="text-indigo-600"
                                    >{{ customer.email }}</a
                                >
                            </div>
                        </div>
                        <div
                            v-if="isLoading && !customer"
                            class="border-b border-gray-200 px-4 py-5 sm:px-6"
                        >
                            <vue-content-loading
                                :width="400"
                                :height="150"
                                style="height: 150px; width: 400px"
                            >
                                <rect
                                    x="48"
                                    y="8"
                                    rx="3"
                                    ry="3"
                                    width="88"
                                    height="6"
                                />
                                <rect
                                    x="48"
                                    y="26"
                                    rx="3"
                                    ry="3"
                                    width="52"
                                    height="6"
                                />
                                <rect
                                    x="0"
                                    y="56"
                                    rx="3"
                                    ry="3"
                                    width="250"
                                    height="6"
                                />
                                <rect
                                    x="0"
                                    y="72"
                                    rx="3"
                                    ry="3"
                                    width="300"
                                    height="6"
                                />
                                <rect
                                    x="0"
                                    y="88"
                                    rx="3"
                                    ry="3"
                                    width="178"
                                    height="6"
                                />
                                <circle cx="20" cy="20" r="20" />
                            </vue-content-loading>
                        </div>
                        <div
                            v-else-if="
                                !isLoading &&
                                customer &&
                                customer.averageReviewsRating &&
                                customer.reviewsCount > 0
                            "
                            class="border-gray-100 px-2 py-2 sm:px-5 sm:py-2.5"
                        >
                            <div class="flex flex-row items-center">
                                <h3 class="text-lg font-bold text-gray-700">
                                    {{ customer.averageReviewsRating }}
                                </h3>
                                <div class="mb-1.5 block pl-2">
                                    <star-rating
                                        :show-rating="false"
                                        :star-size="16"
                                        :padding="2"
                                        active-color="#fabe00"
                                        :increment="0.01"
                                        v-model="customer.averageReviewsRating"
                                        :read-only="true"
                                    />
                                </div>
                            </div>
                            <span
                                v-if="customer.reviewsCount"
                                class="mb-1 block text-xs tracking-wide text-gray-500"
                            >
                                Average rating from
                                {{ customer.reviewsCount }} reviews
                            </span>
                        </div>
                        <div
                            v-else-if="
                                !isLoading &&
                                customer &&
                                customer.reviewsCount === 0
                            "
                            class="text-center text-sm"
                        >
                            No Reviews yet
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import Avatar from 'vue-avatar';
import vClickOutside from 'v-click-outside';
import dayjs from 'dayjs';
import StarRating from 'vue-star-rating';
import ToggleSidebarButton from '../components/helpers/ToggleSidebarButton';
import { VclList, VueContentLoading } from 'vue-content-loading';
import CustomerOrders from '../components/customer/CustomerOrders';
import CustomerTopItems from '../components/widgets/CustomerTopItems';

export default {
    name: 'customerProfile',
    metaInfo() {
        return {
            title: `Customer: ${this.customer.firstname} ${this.customer.lastname}`
        };
    },
    components: {
        VclList,
        VueContentLoading,
        Avatar,
        CustomerOrders,
        ToggleSidebarButton,
        CustomerTopItems,
        StarRating
    },
    data() {
        return {
            serverParams: {
                page: 1,
                limit: 15,
                sortBy: '',
                sortOrder: '',
                venuesIds: null
            },
            customers: [],
            customerNote: '',
            order: {},
            orders: [],
            customer: {},
            isLoading: 0,
            showMoreActions: false,
            chartOptions: {
                chart: {
                    width: 450,
                    type: 'pie'
                },
                colors: ['rgba(78, 205, 196, 1)', '#048243', '#32BF84'],
                labels: ['In Store', 'Delivery', 'Pickup'],
                plotOptions: {
                    pie: {
                        startAngle: 0,
                        expandOnClick: true
                    }
                },
                legend: {
                    show: true,
                    position: 'bottom',
                    fontFamily: 'inherit'
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (val) {
                        return val.toFixed(0) + '%';
                    }
                }
            }
        };
    },
    async created() {
        await this.fetchCustomer(this.$route.params.email);
        await this.fetchOrders();
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    methods: {
        async fetchCustomer(email) {
            if (!email) {
                return;
            }

            try {
                this.isLoading++;
                const params = {
                    withLastOrderDate: true,
                    withReporting: true
                };
                const { data } = await this.$axios.get('/customers/' + email, {
                    params
                });

                this.customer = data;
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading--;
            }
        },

        async fetchOrders() {
            try {
                this.isLoading++;
                const params = {
                    showRejected: false,
                    page: 1,
                    limit: 50,
                    customerEmail: this.$route.params.email
                };
                const { data } = await this.$axios.get('/orders', {
                    params: params
                });

                this.orders = data.results;
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading--;
            }
        }
    },
    computed: {
        timeOfCostumerExistance() {
            const customerFrom = dayjs(this.customer.createdAt);
            const currentDate = dayjs();
            const differenceInDays = currentDate.diff(customerFrom, 'day');
            const differenceInMonths = currentDate.diff(customerFrom, 'month');
            const differenceInYears = currentDate.diff(customerFrom, 'year');

            if (differenceInYears) {
                return (
                    differenceInYears +
                    ' ' +
                    (differenceInYears > parseInt(1) ? 'years' : 'year')
                );
            } else if (differenceInMonths) {
                return (
                    differenceInMonths +
                    ' ' +
                    (differenceInMonths > parseInt(1) ? 'months' : 'month')
                );
            } else if (differenceInDays === 0) {
                return '1 day';
            } else {
                return (
                    differenceInDays +
                    ' ' +
                    (differenceInDays > parseInt(1) ? 'days' : 'day')
                );
            }
        },
        publicHostname() {
            return process.env.VUE_APP_WHITELABEL_PUBLIC_HOSTNAME;
        },
        customerInsightData() {
            let total = this.customer.orderCount;
            return [
                Math.round((this.customer.totalInstoreOrders / total) * 100),
                Math.round((this.customer.totalDeliveryOrders / total) * 100),
                Math.round((this.customer.totalPickupOrders / total) * 100)
            ];
        }
    }
};
</script>

<style scoped>
.box-divider {
    box-shadow: inset -1px 0 #e3e8ee;
}

.customer-card {
    height: max-content;
}

@media print {
    table {
        page-break-inside: auto;
    }
    tr {
        page-break-inside: avoid;
        page-break-after: auto;
    }
    thead {
        display: table-header-group;
    }
    tfoot {
        display: table-footer-group;
    }

    .map-container {
        height: 500px;
        width: 1000px;
    }
}
</style>
