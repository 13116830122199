<template>
    <div>
        <div class="mt-4 md:grid md:gap-6 md:grid-cols-3">
            <div class="md:col-span-1">
                <div class="sm:pl-0 sm:pr-5">
                    <h3 class="text-gray-800 text-base font-semibold leading-6"
                        >Details</h3
                    >
                    <p class="mt-2 text-gray-500 text-sm">
                        Custom Card Fields
                    </p>
                </div>
            </div>

            <div class="mt-5 md:col-span-2 md:mt-0">
                <div class="shadow sm:rounded-md sm:overflow-hidden">
                    <div class="flex items-center mt-4 p-20">
                        <span
                            @click="toggleCustomCardsFields"
                            role="checkbox"
                            :disabled="isLoading"
                            tabindex="0"
                            aria-checked="false"
                            class="group relative inline-flex flex-shrink-0 items-center justify-center w-10 h-5 focus:outline-none cursor-pointer"
                        >
                            <span
                                aria-hidden="true"
                                :class="{
                                    'bg-gray-200': !areCustomCardFieldsActive,
                                    'bg-indigo-600': areCustomCardFieldsActive
                                }"
                                class="absolute mx-auto w-9 h-4 bg-gray-200 rounded-full transition-colors duration-200 ease-in-out"
                            ></span>
                            <span
                                aria-hidden="true"
                                :class="{
                                    'translate-x-0': !areCustomCardFieldsActive,
                                    'translate-x-5': areCustomCardFieldsActive
                                }"
                                class="absolute left-0 inline-block w-5 h-5 bg-white border group-focus:border-blue-300 border-gray-200 rounded-full shadow group-focus:shadow-outline transform translate-x-0 transition-transform duration-200 ease-in-out"
                            ></span>
                        </span>
                        <span
                            class="pl-3 text-gray-700 text-sm font-medium leading-5"
                        >
                            Custom Card Fields
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        venueId: {
            type: Number,
            required: true,
            default: 0
        },
        areCustomCardFieldsAvailable: {
            type: Boolean,
            default: false,
            required: true
        },
        areCustomCardFields: {
            type: Boolean,
            default: false,
            required: true
        }
    },

    data() {
        return {
            isLoading: false,
            areCustomCardFieldsActive: this.areCustomCardFields
        };
    },

    methods: {
        async toggleCustomCardsFields() {
            if (this.areCustomCardFieldsAvailable) {
                this.isLoading = true;

                this.areCustomCardFieldsActive = !this
                    .areCustomCardFieldsActive;

                try {
                    await this.$axios.put('/paypal/toggle-custom-card-fields', {
                        venueId: this.venueId,
                        areCustomCardFieldsEnabled: this
                            .areCustomCardFieldsActive
                    });

                    this.$emit(
                        'toggleCustomCardFieldsActive',
                        this.areCustomCardFieldsActive
                    );
                } catch (error) {
                    throw new Error(`API Response ${error}`);
                } finally {
                    this.isLoading = false;
                }
            } else {
                this.$modal.show('custom-error-modal', {
                    title: 'Error',
                    text: 'This option is not available for you'
                });
            }
        }
    }
};
</script>
