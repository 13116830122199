<template>
    <span
        class="inline-flex items-center rounded-md px-2 py-0.5 text-xs font-medium ring-1 ring-inset"
        :class="computedClass"
    >
        {{ status }}

        <span v-if="status === 'Retrying' && posRetryCount > 0" class="ml-1">
            {{ ' x' + posRetryCount }}
        </span>
    </span>
</template>

<script>
import { StatusEnum } from '@/enums';

export default {
    name: 'StatusBadge',
    props: {
        status: {
            type: String,
            required: true
        },
        posRetryCount: {
            type: Number,
            required: false
        }
    },
    computed: {
        computedClass() {
            switch (this.status) {
                case StatusEnum.SCHEDULED:
                case StatusEnum.PENDING:
                case StatusEnum.SYNCING:
                case StatusEnum.REJECTED:
                case StatusEnum.RETRYING:
                    return 'bg-yellow-50 text-yellow-700 ring-yellow-300';
                case StatusEnum.ACTIVE:
                case StatusEnum.ACCEPTED:
                case StatusEnum.COMPLETE:
                case StatusEnum.READY_FOR_PICKUP:
                case StatusEnum.OUT_FOR_DELIVERY:
                case StatusEnum.PREPARING:
                case StatusEnum.DELIVERED:
                case StatusEnum.SUCCESS:
                    return 'bg-green-50 text-green-700 ring-green-300';
                case StatusEnum.EXPIRED:
                case StatusEnum.FAILED:
                    return 'bg-red-50 text-red-700 ring-red-300';
                default:
                    return 'bg-gray-50 text-gray-700 ring-gray-300';
            }
        }
    }
};
</script>
