<template>
    <modal
        name="menu-hours-modal"
        width="600px"
        height="auto"
        :clickToClose="true"
        :adaptive="true"
        scrollable
    >
        <div
            class="transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 shadow-xl transition-all sm:w-full sm:p-6"
        >
            <div>
                <div class="mt-3 text-left sm:mt-5">
                    <h3
                        class="mb-6 text-lg font-medium leading-6 text-gray-900"
                    >
                        Change menu availability
                    </h3>
                    <div class="flex justify-between px-6 pb-12">
                        <div>Menu always available</div>
                        <div>
                            <toggle-switch v-model="editableAlwaysOpen" />
                        </div>
                    </div>
                    <form v-show="!editableAlwaysOpen" action="#" method="POST">
                        <div class="grid grid-cols-5 gap-6">
                            <div
                                class="col-span-6 rounded-sm bg-gray-100 p-3 sm:col-span-5"
                                v-for="(day, index) in 7"
                                :key="`${day}+${index}+menuHours`"
                            >
                                <label
                                    :for="'hours-' + index"
                                    class="block text-sm font-medium leading-5 text-gray-700"
                                >
                                    {{ hoursDict[index] }}
                                </label>
                                <div
                                    v-for="(
                                        singleInterval, intervalIndex
                                    ) in editableMenuHours"
                                    :key="`${singleInterval.day}++${intervalIndex}+VenueHours`"
                                >
                                    <div
                                        v-if="singleInterval.day === index + 1"
                                        class="inline-flex"
                                    >
                                        <vue-timepicker
                                            manual-input
                                            :minute-interval="15"
                                            :id="'hours-' + intervalIndex"
                                            :disabled="
                                                openAllDayArray[index] ||
                                                closedArray[index]
                                            "
                                            v-model="
                                                editableMenuHours[intervalIndex]
                                                    .openTime
                                            "
                                            :class="{
                                                'opacity-25':
                                                    openAllDayArray[index] ||
                                                    closedArray[index]
                                            }"
                                        >
                                        </vue-timepicker>
                                        <span class="px-4 py-2 text-gray-600">
                                            to
                                        </span>
                                        <vue-timepicker
                                            manual-input
                                            :minute-interval="15"
                                            :disabled="
                                                openAllDayArray[index] ||
                                                closedArray[index]
                                            "
                                            :class="{
                                                'opacity-25':
                                                    openAllDayArray[index] ||
                                                    closedArray[index]
                                            }"
                                            v-model="
                                                editableMenuHours[intervalIndex]
                                                    .closeTime
                                            "
                                            :hour-range="[
                                                [
                                                    editableMenuHours[
                                                        intervalIndex
                                                    ].openTime.substring(0, 2),
                                                    24
                                                ]
                                            ]"
                                        >
                                        </vue-timepicker>

                                        <button
                                            v-if="
                                                intervalsThisDay(index + 1) > 1
                                            "
                                            type="button"
                                            @click="
                                                deleteTimeInterval(
                                                    intervalIndex,
                                                    singleInterval.day,
                                                    editableMenuHours
                                                )
                                            "
                                            class="ml-3"
                                            :class="{
                                                'opacity-25':
                                                    openAllDayArray[index] ||
                                                    closedArray[index]
                                            }"
                                        >
                                            <span
                                                class="transition-duration-100 cursor-pointer text-red-400 transition hover:text-red-700"
                                            >
                                                <svg
                                                    class="h-5 w-5"
                                                    fill="none"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="2"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                                    ></path>
                                                </svg>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                <div class="my-4">
                                    <span
                                        class="inline-flex rounded-md shadow-sm"
                                    >
                                        <button
                                            @click="
                                                addTimeInterval(
                                                    editableMenuHours,
                                                    index + 1,
                                                    type
                                                )
                                            "
                                            :disabled="
                                                openAllDayArray[index] ||
                                                closedArray[index]
                                            "
                                            type="button"
                                            :class="{
                                                'opacity-25':
                                                    openAllDayArray[index] ||
                                                    closedArray[index]
                                            }"
                                            class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
                                        >
                                            Add another time interval
                                        </button>
                                    </span>
                                </div>

                                <div class="flex items-center space-x-3">
                                    <toggle-switch
                                        v-model="closedArray[index]"
                                        @input="closeDay(index)"
                                    />

                                    <span :id="'closed-' + day">
                                        <span
                                            class="text-sm font-medium leading-5 text-gray-700"
                                            >Closed
                                        </span>
                                    </span>

                                    <span
                                        class="inline-flex rounded-md shadow-sm"
                                    >
                                        <toggle-switch
                                            v-model="openAllDayArray[index]"
                                            @input="openAllDay(index)"
                                        />
                                    </span>

                                    <span :id="'24hours-' + day">
                                        <span
                                            class="text-sm font-medium leading-5 text-gray-700"
                                        >
                                            Open 24 hours
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div
                class="mt-5 sm:mt-6 sm:flex sm:w-auto"
                :class="{ spinner: isLoading }"
            >
                <span class="flex w-full rounded-md shadow-sm sm:w-56">
                    <button
                        @click="saveHours()"
                        :disabled="isLoading"
                        type="button"
                        class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo sm:text-sm sm:leading-5"
                    >
                        Save changes
                    </button>
                </span>
                <span
                    class="mt-3 flex w-full rounded-md shadow-sm sm:ml-3 sm:mt-0 sm:w-auto"
                >
                    <button
                        @click="closeModal"
                        type="button"
                        class="focus:outline-none inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium leading-6 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline sm:text-sm sm:leading-5"
                    >
                        Close
                    </button>
                </span>
            </div>
        </div>
    </modal>
</template>

<script>
import VueTimepicker from 'vue2-timepicker';
import EditVenueService from '@/services/editVenue.service';
const editVenueService = new EditVenueService();
import ToggleSwitch from '../formComponents/ToggleSwitch';

export default {
    name: 'MenuHoursModal',
    components: {
        VueTimepicker,
        ToggleSwitch
    },
    props: {
        menuHours: {
            type: Array,
            default: () => [],
            required: true
        },
        menuId: {
            type: String,
            default: '',
            required: true
        },
        alwaysOpen: {
            type: Boolean,
            default: true,
            required: true
        }
    },
    watch: {
        editableMenuHours: {
            deep: true,
            handler(hours) {
                this.$emit('editedVenueHours', hours);
            }
        }
    },

    data() {
        return {
            isLoading: false,
            hoursDict: [
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
                'Sunday'
            ],
            closedArray: [false, false, false, false, false, false, false],
            openAllDayArray: [false, false, false, false, false, false, false],
            editableMenuHours: this.menuHours,
            editableAlwaysOpen: this.alwaysOpen,
            type: 'hours'
        };
    },
    created() {
        Array.from({ length: 7 }, (x, i) => {
            const menuHours = this.menuHours.filter(hour => hour.day === i + 1);

            if (menuHours.find(y => y.openAllDay === true)) {
                this.openAllDayArray[i] = true;
            } else if (!menuHours || !menuHours.length) {
                this.closedArray[i] = true;
            }
        });
    },

    methods: {
        closeModal() {
            this.$modal.hide('menu-hours-modal');
        },

        async updateMenuAlwaysOpen(value) {
            try {
                await this.$axios.patch(`/menus/${this.menuId}`, {
                    alwaysOpen: value
                });

                this.$emit('alwaysOpenUpdate', value);
            } catch (error) {
                throw new Error(`API ${error}`);
            }
        },

        async saveHours() {
            if (this.editableAlwaysOpen && !this.alwaysOpen) {
                await this.updateMenuAlwaysOpen(true);

                this.$modal.hide('menu-hours-modal');
                this.isLoading = false;

                return;
            } else if (this.editableAlwaysOpen && this.alwaysOpen) {
                this.$modal.hide('menu-hours-modal');
                this.isLoading = false;

                return;
            }

            const updatedHours = [];

            Array.from({ length: 7 }, (x, i) => {
                if (this.openAllDayArray[i] && !this.closedArray[i]) {
                    updatedHours.push({
                        openTime: '00:00',
                        closeTime: '00:00',
                        openAllDay: true,
                        day: i + 1
                    });
                } else if (!this.openAllDayArray[i] && !this.closedArray[i]) {
                    const menuHours = this.editableMenuHours.filter(
                        hour => hour.day === i + 1
                    );

                    menuHours.forEach(hour => (hour.openAllDay = false));

                    updatedHours.push(...menuHours);
                }
            });

            this.isLoading = true;

            try {
                await this.$axios.put(
                    `/menus/${this.menuId}/hours`,
                    updatedHours
                );

                this.$modal.hide('menu-hours-modal');
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;

                if (this.alwaysOpen) {
                    await this.updateMenuAlwaysOpen(false);
                }
            }
        },

        intervalsThisDay(day) {
            return editVenueService.oneDayIntervalsNumber(
                day,
                this.editableMenuHours
            );
        },

        closeDay(day) {
            this.openAllDayArray[day] = false;
        },

        openAllDay(day) {
            this.closedArray[day] = false;
        },

        addTimeInterval(array, day) {
            this.editableMenuHours.push({
                day,
                openTime: '',
                closeTime: ''
            });
        },

        deleteTimeInterval(index, day, array) {
            this.editableMenuHours = editVenueService.deleteTimeInterval(
                index,
                day,
                array
            );
        }
    }
};
</script>

<style scoped>
.spinner:after {
    left: auto;
    right: 1em;
    border-right-color: indigo;
}
</style>
