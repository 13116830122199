<template>
    <div class="mb-6 sm:mb-8 mt-4 flex flex-col bg-white shadow sm:rounded-lg">
        <div
            :class="{ 'draggable-category': !isMobile, 'rounded-b-lg': !show }"
            class="rounded-t-lg border-b border-gray-200 bg-white px-2 py-3 sm:px-4 sm:px-6"
        >
            <div
                class="-ml-4 -mt-4 flex cursor-move flex-wrap items-center justify-between sm:flex-nowrap"
            >
                <div class="ml-4 mt-4 flex cursor-pointer">
                    <div class="w-full overflow-hidden">
                        <div class="flex">
                            <div
                                class="flex"
                                @click="$emit('editCategory', category)"
                            >
                                <h3
                                    class="sm:text-md text-sm font-medium leading-6 text-gray-700"
                                >
                                    {{ category.name }}
                                </h3>
                                <div
                                    class="sm:text-md ml-2 flex items-center text-sm text-indigo-600"
                                >
                                    edit
                                    <base-edit-icon class="ml-1" />
                                </div>
                            </div>
                        </div>
                        <p
                            v-show="category.description"
                            class="mt-1 max-w-md truncate pr-10 text-xs leading-5 text-gray-500 sm:text-sm"
                        >
                            {{ category.description }}
                        </p>
                    </div>
                </div>
                <div class="ml-4 mt-4 flex flex-shrink-0 cursor-default">
                    <div
                        @click.prevent="showSnoozeModal"
                        class="mr-2 flex cursor-pointer items-center text-gray-700 rounded-md hover:bg-gray-50 px-2"
                    >
                        <span
                            v-if="isSnoozed"
                            class="mr-1 text-xs font-semibold text-gray-600"
                        >
                            Unsnoozing
                            {{ category.disabledUntil | moment('from', 'now') }}
                        </span>
                        <base-snooze-icon
                            class="w-5 h-5"
                            :class="{ 'text-indigo-600': isSnoozed }"
                        />
                    </div>
                    <base-button :button-text="show ? 'Collapse' : 'Expand'" size="sm" @clicked="show = !show" >
                      <template #right-icon>
                        <base-chevron-icon :class="{ 'rotate-180': show }" />
                      </template>
                    </base-button>
                </div>
            </div>
        </div>
        <div v-auto-animate>
            <div class="flex flex-col" v-if="show">
                <div
                    class="-my-2 overflow-x-auto py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
                >
                    <div
                        class="inline-block min-w-full overflow-hidden border-b border-gray-200 align-middle sm:rounded-b-lg"
                    >
                        <table class="min-w-full table-auto">
                            <thead>
                                <tr>
                                    <th
                                        class="border-b border-gray-200 bg-gray-100 py-3 pl-2 pr-3 text-left text-xs font-medium  leading-4 text-gray-500 sm:pl-6 sm:pr-6"
                                    >
                                        Name
                                    </th>
                                    <th
                                        class="hidden border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium  leading-4 text-gray-500 sm:table-cell"
                                    >
                                        Modifiers
                                    </th>
                                    <th
                                        class="hidden border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium  leading-4 text-gray-500 sm:table-cell"
                                    >
                                        Popular
                                    </th>
                                    <th
                                        class="border-b border-gray-200 bg-gray-100 px-3 py-3 text-left text-xs font-medium  leading-4 text-gray-500 sm:px-6"
                                    >
                                        In Stock
                                    </th>
                                    <th
                                        class="hidden border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium  leading-4 text-gray-500 sm:table-cell"
                                    >
                                        Price
                                    </th>
                                    <th
                                        class="border-b border-gray-200 bg-gray-100 px-6 py-3"
                                    ></th>
                                </tr>
                            </thead>
                            <draggable
                                v-model="sortedProducts"
                                @end="updateItemOrder"
                                @add="changeItemCategory(category.id)"
                                handle=".draggable-product"
                                group="product"
                                tag="tbody"
                                class="bg-white"
                                v-if="
                                    category &&
                                    category.products &&
                                    category.products.length > 0
                                "
                            >
                                <menu-category-product
                                    :isDeliverectManaged="false"
                                    :product="product"
                                    v-for="product in sortedProducts"
                                    :key="product.id"
                                    @showEditProductSidebar="
                                        $emit('showEditProductSidebar', product)
                                    "
                                />
                            </draggable>
                            <tbody>
                                <tr
                                    @click="showAddProductModal()"
                                    v-if="
                                        index === 0 &&
                                        this.category.products.length === 0
                                    "
                                    class="cursor-pointer rounded-md text-center"
                                >
                                    <td
                                        class="whitespace-nowrap bg-gray-100 px-6 py-10 transition-colors duration-500 ease-in-out hover:bg-gray-200"
                                        colspan="6"
                                    >
                                        <base-drink-icon
                                            class="mx-auto h-12 w-12 text-gray-400" />
                                        <h3
                                            class="mt-2 text-sm font-semibold text-gray-900"
                                            >Create your first product</h3
                                        >
                                        <p class="mt-1 text-sm text-gray-500"
                                            >Menu items are organised into
                                            categories. Get started by creating
                                            your first product.</p
                                        >
                                        <div class="mt-6">
                                            <base-button
                                                buttonText="Create a new product"
                                                size="sm"
                                            /> </div
                                    ></td>
                                </tr>
                                <tr
                                    v-else
                                    class="cursor-pointer bg-white"
                                    @click="showAddProductModal()"
                                >
                                    <td
                                        class="whitespace-nowrap bg-gray-100 px-6 py-2"
                                        colspan="6"
                                    >
                                        <div
                                            class="flex w-full items-center justify-center"
                                        >
                                            <div class="mt-2 w-full sm:mt-0">
                                                <div
                                                    class="flex justify-center rounded-md border-2 border-dashed border-gray-300 bg-white px-6 pb-2 pt-1 opacity-50 hover:opacity-100"
                                                >
                                                    <div
                                                        class="mt-1 flex items-center text-center text-sm text-indigo-600"
                                                    >
                                                        <svg
                                                            class="h-4 w-4"
                                                            fill="none"
                                                            stroke="currentColor"
                                                            viewBox="0 0 24 24"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                stroke-width="2"
                                                                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                                                            ></path>
                                                        </svg>
                                                        <button
                                                            type="button"
                                                            class="focus:outline-none ml-1 font-medium text-indigo-600 transition duration-150 ease-in-out hover:text-indigo-500 focus:underline"
                                                        >
                                                            Add a product
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable';
import { isMobile } from 'mobile-device-detect';
import { mapGetters } from 'vuex';
import MenuCategoryProduct from './MenuCategoryProduct';

export default {
    name: 'MenuCategory',
    props: {
        category: {
            type: Object,
            required: true,
            default: () => ({})
        },
        showCategory: {
            type: Boolean,
            required: true,
            default: false
        },
        isPosManaged: {
            type: Boolean,
            required: true,
            default: false
        },
        isDeliverectManaged: {
            type: Boolean,
            required: true,
            default: false
        },
        index: {
            type: Number,
            required: true,
            default: 0
        }
    },
    components: {
        MenuCategoryProduct,
        draggable
    },
    data() {
        return {
            isLoading: false,
            isLoadingOrder: false,
            show: true,
            adminSortedProducts: [],
            updatedDisabledUntil: null
        };
    },
    async created() {
        this.show = this.showCategory;

        this.adminSortedProducts = this._.orderBy(
            this.category.products,
            'adminIndex'
        );
    },
    methods: {
        showSnoozeModal() {
            this.$modal.show('snooze-table-modal', {
                item: this.category,
                type: 'category'
            });
        },
        showAddProductModal() {
            if (!this.isPosManaged) {
                this.$modal.show('add-product-modal', {
                    categoryId: this.category.id,
                    productsCount: this.category.products.length
                });
            } else {
                this.showPosOnlyManagedNotify('product');
            }
        },
        async updateItemOrder() {
            const index = this.useAdminIndex ? 'adminIndex' : 'index';
            let indexedProducts = [];
            this.sortedProducts.forEach((item, i) => {
                indexedProducts[i] = { id: item.id, index: i };
            });

            this.isLoadingOrder = true;
            try {
              await this.$axios.put('/products/sort', {
                items: indexedProducts,
                index
              });

              this.$notify({
                group: 'settings',
                duration: 2000,
                text: `Item order updated`,
              });
            } catch (e) {
              throw new Error(`API ${e}`);
            } finally {
              this.isLoadingOrder = false;
            }

        },
        async changeItemCategory(newCategoryId) {
            const index = this.useAdminIndex ? 'adminIndex' : 'index';
            let indexedProducts = [];
            this.sortedProducts.forEach((item, i) => {

                if (item.oldCategoryId !== newCategoryId) {
                    indexedProducts[i] = { id: item.id, index: i, oldCategoryId: item.oldCategoryId, newCategoryId };
                } else {
                    indexedProducts[i] = { id: item.id, index: i };
                }
            });

            this.isLoadingOrder = true;

            try {
                await this.$axios.put('/products/sort', {
                    items: indexedProducts,
                    index
                });

                this.sortedProducts.forEach((item) => {
                    item.oldCategoryId = newCategoryId
                });

                this.$notify({
                    group: 'settings',
                    duration: 2000,
                    text: `Item category updated`,
                });
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoadingOrder = false;
            }
        },
        showPosOnlyManagedNotify(item) {
            this.$emit('show-pos-only-managed-notify', item);
        }
    },
    computed: {
        ...mapGetters({
            useAdminIndex: 'user/useAdminIndex'
        }),
        isMobile() {
            return isMobile;
        },
        sortedProducts: {
            get() {
                if (this.useAdminIndex) {
                    return this.adminSortedProducts;
                } else {
                    return this.category.products;
                }
            },
            set(value) {
                if (this.useAdminIndex) {
                    this.adminSortedProducts = value;
                } else {
                    this.category.products = value;
                }
            }
        },
        isSnoozed() {
            if (!this.category.disabledUntil) {
                return false;
            }

            const until = this.$moment(this.category.disabledUntil);

            return until.isAfter();
        }
    },
    watch: {
        showCategory(newVal) {
            this.show = newVal;
        }
    }
};
</script>
