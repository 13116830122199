<template>
    <date-range-picker
        opens="right"
        class="relative w-full shadow-sm"
        :class="{ 'opacity-50': disabled }"
        :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY HH:mm' }"
        :timePicker="true"
        :maxDate="null"
        :min-date="yesterday"
        :singleDatePicker="true"
        :disabled="disabled"
        :showWeekNumbers="true"
        :showDropdowns="true"
        :autoApply="true"
        :ranges="false"
        v-model="input"
        :linkedCalendars="false"
    >
        <template v-slot:input="picker">
            <div
                id="valid-from"
                class="focus:outline-none mt-2 inline-flex w-full cursor-pointer items-center rounded-md rounded-sm border border-gray-300 bg-white px-3 py-2 text-sm leading-5 transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
                :class="{ 'cursor-not-allowed': disabled }"
            >
                <svg
                    class="-ml-1 mr-3 h-5 w-5"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                    ></path>
                </svg>
                <span>
                    {{ picker.startDate | date }}
                </span>
            </div>
        </template>
    </date-range-picker>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

export default {
    name: 'DiscountValiditySelector',
    props: {
        value: {
            type: Object
        },
        disabled: { type: Boolean, default: false }
    },
    components: {
        DateRangePicker
    },
    data() {
        return {
            isLoading: true
        };
    },

    filters: {
        date(value) {
            if (!value) return '';

            return Intl.DateTimeFormat('en-GB', {
                year: '2-digit',
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric'
            }).format(value);
        }
    },
    computed: {
        input: {
            get: function () {
                return this.value;
            },
            set: function (value) {
                this.$emit('input', value);
            }
        },
        yesterday() {
            const yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);
            yesterday.setHours(0, 0, 0, 0);

            return yesterday;
        }
    }
};
</script>

<style>
.reportrange-text {
    background: transparent !important;
    cursor: pointer !important;
    padding: 0 !important;
    border: none !important;
}
.calendars.row.no-gutters {
    display: flex;
}

.daterangepicker.dropdown-menu {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px -2px rgba(0, 0, 0, 0.05);
    top: 50px;
}
</style>
