var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.venue.acceptsInStore)?_c('div',{staticClass:"mt-6"},[_c('div',[_c('div',{staticClass:"block"},[_c('div',{staticClass:"overflow-x-scroll border-b border-gray-200"},[_c('nav',{staticClass:"-mb-px flex"},[_c('span',{staticClass:"focus:outline-none cursor-pointer whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-sm font-medium leading-5 text-gray-500 hover:border-gray-300 hover:text-gray-700 focus:border-gray-300 focus:text-gray-700",class:{
                                'border-indigo-500 text-indigo-600 focus:border-indigo-700 focus:text-indigo-800':
                                    _vm.tab === 0
                            },on:{"click":function($event){_vm.tab = 0}}},[_vm._v(" "+_vm._s(_vm.venue.tableDescriptor)+" Plan ")]),_c('span',{staticClass:"focus:outline-none ml-8 cursor-pointer whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-sm font-medium leading-5 text-gray-500 hover:border-gray-300 hover:text-gray-700 focus:border-gray-300 focus:text-gray-700",class:{
                                'border-indigo-500 text-indigo-600 focus:border-indigo-700 focus:text-indigo-800':
                                    _vm.tab === 1
                            },on:{"click":function($event){_vm.tab = 1}}},[_vm._v(" Settings ")])])])])]),(_vm.tab === 0)?_c('div',{},[_c('div',[_c('Tables',{attrs:{"tables":_vm.tables,"venueId":_vm.venue.id,"venue-slug":_vm.venue.slug,"tableDescriptor":_vm.venue.tableDescriptor,"order-type":_vm.venue.acceptsPat ? 'pat' : 'opat',"pos-provider":_vm.venue.posProvider},on:{"":function($event){},"sortedTables":_vm.sortedTables,"table-area-added":_vm.fetchTables}})],1)]):_vm._e(),(_vm.tab === 1)?_c('div',{staticClass:"px-4 py-5 sm:p-6"},[_c('div',{staticClass:"mt-3 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6"},[_c('div',{staticClass:"sm:col-span-4"},[_c('label',{staticClass:"block text-sm font-medium leading-5 text-gray-700",attrs:{"for":"tableDescriptor"}},[_vm._v(" Location names ")]),_c('p',{staticClass:"mt-2 text-sm text-gray-500"},[_vm._v(" By default we use \"Table\" to refer to where your customers are within your store, but you can change this to anything you like. For example, Room, Hole or Booth ")]),_c('div',{staticClass:"mt-1 flex rounded-md shadow-sm"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.venue.tableDescriptor),expression:"venue.tableDescriptor"}],staticClass:"form-input block w-full min-w-0 flex-1 rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5",attrs:{"id":"tableDescriptor"},domProps:{"value":(_vm.venue.tableDescriptor)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.venue, "tableDescriptor", $event.target.value)},function($event){return _vm.$emit('venue-updated', {
                                    tableDescriptor: $event.target.value
                                })}]}})])]),_c('div',{staticClass:"sm:col-span-4"},[_c('label',{staticClass:"block text-sm font-medium leading-5 text-gray-700",attrs:{"for":"about"}},[_vm._v(" Order instructions ")]),_c('p',{staticClass:"mt-2 text-sm text-gray-500"},[_vm._v("We'll show this message to your customers when they first visit your ordering site. Leave it as the default or write your own. ")]),_c('div',{staticClass:"mt-1 rounded-md shadow-sm"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.venue.orderInstructions),expression:"venue.orderInstructions"}],staticClass:"form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5",attrs:{"id":"orderInstructions","rows":"3"},domProps:{"value":(_vm.venue.orderInstructions)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.venue, "orderInstructions", $event.target.value)},function($event){return _vm.$emit('venue-updated', {
                                    orderInstructions: $event.target.value
                                })}]}})])])])]):_vm._e()]):_vm._e(),_c('AddTableAreaModal',{on:{"table-area-added":_vm.fetchTables}}),_c('AddTableModal',{on:{"table-area-added":_vm.fetchTables}}),_c('DeleteTableModal',{on:{"table-area-added":_vm.fetchTables}}),_c('DeleteTableAreaModal',{on:{"table-area-added":_vm.fetchTables}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }