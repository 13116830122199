<template>
    <ul
        class="divide-y divide-gray-200 overflow-hidden rounded-md border border-gray-200"
    >
        <li
            v-for="account in accounts"
            :key="account.bankAccountUUID"
            class="flex items-center justify-between bg-gray-50 py-3 pl-3 pr-4 text-sm"
        >
            <div class="relative flex w-0 flex-1 flex-col text-gray-500">
                <template
                    v-if="
                        account.iban &&
                        (!account.accountNumber || !account.branchCode)
                    "
                >
                    <p class="ml-2 truncate"> IBAN: {{ account.iban }} </p>
                </template>
                <template v-else>
                    <p class="ml-2 truncate">
                        Account number: {{ account.accountNumber }}
                    </p>
                    <p class="ml-2 truncate">
                        Branch code: {{ account.branchCode }}
                    </p>
                </template>
                <p class="ml-2 truncate">
                    Currency: {{ account.currencyCode }}
                </p>
                <div
                    @click="showChangeBankAccountMessage"
                    class="absolute top-0 right-0 flex cursor-pointer flex-row items-center hover:text-gray-400 hover:underline"
                >
                    <span class="mr-1 text-sm"> Change </span>
                    <base-edit-icon />
                </div>
            </div>
        </li>
        <li v-if="accounts && !accounts.length">
            <div class="flex flex-col p-4 text-center text-gray-500">
                <p class=""> No bank accounts setup </p>
            </div>
        </li>
    </ul>
</template>

<script>
export default {
    name: 'AdyenBankAccounts',
    props: {
        accounts: {
            type: Array,
            required: true,
            default: () => []
        },
        accountHolderCode: {
            type: String,
            required: false
        }
    },
    methods: {
        showChangeBankAccountMessage() {
            if (window.Intercom) {
                window.Intercom(
                    'showNewMessage',
                    `Hi! I'd like to change the bank account for my storekit payments account ${this.accountHolderCode}`
                );
            } else {
                window.alert(
                    'Please disable your AdBlocker to use live chat support.'
                );
            }
        }
    }
};
</script>
