<template>
    <div>
        <button
            v-for="(day, index) in days"
            :key="index"
            @click="toggleDay(index)"
            type="button"
            class="mr-2 shadow-sm mt-2 px-3 py-1.5 ease-in-out font-medium text-sm transition duration-200 rounded-md border border-gray-200"
            :class="[
                selectedDays.includes(index) ? 'border-black bg-black text-white' : 'text-gray-900 bg-gray-100',
                {'border-red-300 bg-red-50 text-gray-900': error},
            ]"
        >
            {{ day }}
        </button>
    </div>
</template>

<script>
export default {
    Name: 'WeekDayPicker',
    props: {
        value: {
            type: Array,
            default: () => [],
        },
        error: {
            type: Boolean
        }
    },
    data() {
        return {
            days: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
        };
    },
    computed: {
        selectedDays: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit('input', newValue);
            },
        },
    },
    methods: {
        toggleDay(index) {
            if (this.selectedDays.length === 1 && this.selectedDays.includes(index)) {
                return; // Prevent the last selected day from being deleted
            }

            this.selectedDays = this.selectedDays.includes(index)
                ? this.selectedDays.filter((day) => day !== index)
                : [...this.selectedDays, index];
        },
    },
};
</script>
