<template>
    <div class="w-full" v-if="key" :key="key">
        <div
            class="draggable-category rounded-t-lg border-b border-gray-200 bg-gray-100 px-4 py-3 sm:px-6"
            :class="{
                'bg-red-300': element.deleted,
                'bg-green-300': !element.deleted && !element.existedBefore
            }"
        >
            <div
                :key="key"
                class="-ml-4 -mt-4 flex cursor-move flex-wrap items-center justify-between sm:flex-nowrap"
            >
                <div class="ml-4 mt-4 flex cursor-pointer">
                    <div class="w-full overflow-hidden">
                        <div class="flex">
                            <toggle-switch
                                v-model="element.update"
                                @input="setUpdate(element)"
                            />
                            <div
                                class="flex"
                                @click="$emit('editElement', element)"
                            >
                                <h3
                                    class="text-md ml-3 font-medium leading-6 text-gray-700"
                                >
                                    <span
                                        v-show="
                                            element.oldName &&
                                            element.name !== element.oldName
                                        "
                                        class="line-through"
                                    >
                                        {{ element.oldName }}
                                    </span>
                                    {{ element.name }}
                                    <span
                                        v-show="
                                            element.oldPrice &&
                                            element.price !== element.oldPrice
                                        "
                                        class="line-through"
                                        >{{
                                            (element.oldPrice / 100) | currency
                                        }}</span
                                    >
                                    <span v-show="element.price">{{
                                        (element.price / 100) | currency
                                    }}</span>
                                </h3>
                            </div>
                        </div>
                        <p
                            class="mt-1 max-w-md truncate pr-10 text-sm leading-5 text-gray-500"
                        >
                            {{ element.description }}
                        </p>
                        <p
                            v-show="
                                !element.deleted &&
                                !element.existedBefore &&
                                !element.update
                            "
                            class="mt-1 max-w-md truncate pr-10 text-sm leading-5 text-gray-800"
                        >
                            Element won't be inserted
                        </p>
                        <p
                            v-show="element.deleted && !element.update"
                            class="mt-1 max-w-md truncate pr-10 text-sm leading-5 text-gray-800"
                        >
                            Element won't be deleted
                        </p>
                        <p
                            v-show="element.existedBefore && !element.update"
                            class="mt-1 max-w-md truncate pr-10 text-sm leading-5 text-gray-800"
                        >
                            Element will be deleted
                        </p>
                    </div>
                </div>
                <div class="ml-4 mt-4 flex-shrink-0 cursor-default">
                    <span
                        v-show="showExpandButton"
                        class="rounded-md shadow-sm"
                    >
                        <button
                            @click="show = !show"
                            type="button"
                            :disabled="
                                (!element.update && !element.deleted) ||
                                (element.deleted && element.update)
                            "
                            class="focus:outline-none inline-flex w-full justify-center rounded-md bg-white px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
                            :class="{
                                'cursor-default opacity-25':
                                    (!element.update && !element.deleted) ||
                                    (element.deleted && element.update)
                            }"
                        >
                            <span v-if="show">Collapse</span>
                            <span v-else>Expand</span>
                            <svg
                                class="h-6 w-6 transition duration-300"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                    clip-rule="evenodd"
                                ></path>
                            </svg>
                        </button>
                    </span>
                </div>
            </div>
        </div>

        <div v-if="show">
            <div class="flex flex-col">
                <div
                    class="-my-2 overflow-x-auto py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
                >
                    <div
                        class="inline-block min-w-full overflow-hidden border-b border-gray-200 pl-10 align-middle sm:rounded-lg"
                    >
                        <slot />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ToggleSwitch from '@/components/formComponents/ToggleSwitch';
export default {
    name: 'MenuSyncElement',
    components: {
        ToggleSwitch
    },
    props: {
        element: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    data() {
        return {
            title: 'Menu sync',
            oldCategories: null,
            newCategories: null,
            show: false,
            isLoading: true,
            key: 0
        };
    },
    computed: {
        showExpandButton() {
            return (
                this.element?.products?.length ||
                this.element?.modifierGroups?.length
            );
        },
        isExpandButtonDisabled() {
            return !this.element.update;
        }
    },
    mounted() {
        this.key++;
    },
    methods: {
        setUpdate(element) {
            this.key++;

            if (!element.update) {
                this.show = false;
            }
        }
    }
};
</script>
