<template>
    <modal
        name="add-user-modal"
        :adaptive="true"
        height="auto"
        width="600px"
        @before-open="beforeOpen"
        class="add-user-modal"
    >
        <div
            class="transform rounded-lg bg-white shadow-xl transition-all sm:w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
        >
            <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start">
                    <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <div class="flex items-center justify-between">
                            <h3
                                class="mb-3 text-lg font-medium leading-6 text-gray-900"
                            >
                                Add a new user
                            </h3>
                            <div
                                class="h-4 w-4"
                                :class="{ 'text-gray-900 spinner': isLoading }"
                            >
                                <span></span>
                            </div>
                        </div>
                        <div>
                            <p class="text-sm leading-5 text-gray-500">
                                Give staff access to your store by sending them
                                an invitation.
                            </p>
                        </div>
                        <div class="my-5 md:my-3">
                            <form @submit.prevent="inviteUser">
                                <div class="grid grid-cols-6 gap-6">
                                    <div class="col-span-6">
                                        <base-input-field
                                            type="text"
                                            label="Name"
                                            id="name"
                                            v-model.trim="user.name"
                                            :errors="$v.user.name.$error"
                                        />
                                    </div>

                                    <div class="col-span-6">
                                        <base-input-field
                                            type="email"
                                            label="Email address"
                                            id="name"
                                            v-model.trim="user.email"
                                            :errors="$v.user.email.$error"
                                        />
                                        <span
                                            v-if="error.userAlreadyExist"
                                            class="text-xs text-red-600"
                                        >
                                            The requested email address is
                                            already in use. Please use another
                                            email address.
                                        </span>
                                    </div>

                                    <div class="col-span-6">
                                        <base-input-field
                                            id="phone"
                                            v-model="user.phone"
                                            label="Phone"
                                            label-postfix="(optional)"
                                            :errors="$v.user.phone.$error"
                                            errorMessage="Please enter a valid phone number"
                                        />
                                    </div>
                                    <div class="col-span-6">
                                        <label
                                            class="mb-2 block text-sm font-medium leading-5 text-gray-700"
                                            >Grant access to all venues
                                        </label>
                                        <toggle-switch
                                            v-model="user.allVenueAccess"
                                        />
                                    </div>

                                    <div
                                        v-if="
                                            !isLoading && !user.allVenueAccess
                                        "
                                        class="z-100 relative col-span-6"
                                    >
                                        <span class="rounded-sm shadow-sm">
                                            <venue-selector
                                                :venues="venues"
                                                :multiple-select="true"
                                                :on-close="false"
                                                :is-report="true"
                                                @selected="onVenueChange"
                                            />
                                        </span>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6"
            >
                <span
                    class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto"
                    :class="{ 'opacity-50': isLoading }"
                >
                    <button
                        @click="inviteUser"
                        :disabled="isLoading"
                        type="button"
                        class="focus:outline-none inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium leading-6 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                    >
                        Invite user
                    </button>
                </span>
            </div>
        </div>
    </modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { email, minLength, required, numeric } from 'vuelidate/lib/validators';
import ToggleSwitch from '@/components/formComponents/ToggleSwitch';
import VenueSelector from '@/components/selectors/VenueSelector';

export default {
    name: 'AddUserModal',
    components: {
        ToggleSwitch,
        VenueSelector
    },
    data() {
        return {
            user: {
                name: '',
                email: '',
                phone: '',
                allVenueAccess: true
            },
            error: {
                userAlreadyExist: false
            },
            isLoading: false,
            venues: []
        };
    },
    validations: {
        user: {
            name: {
                required,
                minLength: minLength(3)
            },
            email: {
                required,
                minLength: minLength(3),
                email
            },
            phone: {
                minLength: minLength(5),
                numeric
            }
        }
    },
    computed: {
        ...mapGetters({
            currentUser: 'user/currentUser'
        })
    },
    methods: {
        async beforeOpen() {
            await this.fetchVenues();
        },
        hideModal() {
            this.$modal.hide('add-user-modal');
        },
        clearForm() {
            this.user = {
                name: '',
                email: '',
                phone: '',
                allVenueAccess: true,
                venues: []
            };
            this.error.userAlreadyExist = false;
        },
        async fetchVenues() {
            try {
                this.isLoading = true;
                const { data } = await this.$axios.get('/venues');

                this.venues = data;
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        },
        onVenueChange(venueIds) {
            this.user.venues = venueIds || [];
        },
        async inviteUser() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return false;
            }

            this.isLoading = true;
            const user = this.user;

            if (user.allVenueAccess) {
                user.venues = [];
            }

            try {
                await this.$axios.post('/users/invite', { user });

                if (window.analytics) {
                    window.analytics.track('Invite Sent', {
                        inviteeEmail: user.email,
                        inviteeName: user.name,
                        inviteePhone: user.phone,
                        inviteeRole: 'Admin',
                        accountId: this.currentUser.accounts[0].id,
                        context: {
                            groupId: this.currentUser.accounts[0].id
                        }
                    });
                }

                this.$emit('user-added');
                this.clearForm();
                this.hideModal();

                this.$notify({
                    group: 'settings',
                    title: 'User added'
                });
            } catch (error) {
                if (error.response?.data.includes('user_exists')) {
                    this.error.userAlreadyExist = true;
                }

                this.$notify({
                    group: 'settings',
                    title: 'An error occurred'
                });

                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        }
    }
};
</script>

<style>
.add-user-modal .vm--modal {
    overflow: visible;
}
</style>
