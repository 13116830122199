<template>
    <modal
        name="export-modal"
        :adaptive="true"
        :reset="true"
        height="auto"
        width="600px"
        @before-open="beforeOpen"
    >
        <div
            class="bg-white rounded-lg shadow-xl overflow-hidden transform transition-all sm:w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
        >
            <div class="pb-4 pt-5 px-4 bg-white sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start">
                    <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <div class="flex items-center justify-between">
                            <h3
                                class="mb-3 text-gray-900 font-heading text-lg font-medium"
                            >
                                Export
                                <span v-if="resourceName">
                                    {{ resourceName }}
                                </span>
                                <span v-else>your report</span>
                            </h3>
                        </div>
                        <div>
                            <p class="text-gray-500 text-sm leading-5">
                                Report will be exported as a CSV (comma
                                separated values) table.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="px-4 py-3 bg-gray-50 sm:flex sm:flex-row-reverse sm:px-6"
            >
                <span class="inline-flex ml-4 rounded-md shadow-sm">
                    <button
                        @click="downloadReport"
                        :disabled="isLoading"
                        type="button"
                        :class="{ 'spinner button-spinner': isLoading }"
                        class="inline-flex items-center px-4 py-2 text-white text-sm font-medium leading-5 hover:bg-indigo-500 bg-indigo-600 active:bg-indigo-700 border focus:border-indigo-700 border-transparent rounded-md focus:outline-none focus:shadow-outline-indigo transition duration-150 ease-in-out"
                    >
                        Export {{ resourceName }}
                    </button>
                </span>
                <span
                    class="flex mt-3 w-full rounded-md shadow-sm sm:mt-0 sm:w-auto"
                >
                    <button
                        @click="hideModal()"
                        :disabled="isLoading"
                        type="button"
                        class="inline-flex justify-center px-4 py-2 w-full hover:text-gray-500 text-gray-700 text-base font-medium leading-6 bg-white border focus:border-blue-300 border-gray-300 rounded-md focus:outline-none focus:shadow-outline-blue shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    >
                        Cancel
                    </button>
                </span>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    name: 'ExportModal',
    data() {
        return {
            resourceName: null,
            resourceUri: null,
            params: null,
            order: null,
            isLoading: false
        };
    },
    methods: {
        hideModal() {
            this.$modal.hide('export-modal');
        },
        beforeOpen(event) {
            this.resourceName = event.params.resourceName;
            this.resourceUri = event.params.resourceUri;
            this.params = event.params.params;
        },
        downloadReport() {
            this.isLoading = true;
            this.$axios
                .get(this.resourceUri, {
                    params: this.params
                })
                .then(res => {
                    const downloadUrl = window.URL.createObjectURL(
                        new Blob([res.data])
                    );
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', 'export.csv'); //any other extension
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    this.hideModal();
                })
                .catch(e => {
                    throw new Error(`API ${e}`);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }
    }
};
</script>

<style scoped>
.spinner.button-spinner:after {
    border-right-color: white;
}
</style>
