<template>
    <modal
        name="pos-error-modal"
        :adaptive="true"
        height="auto"
        width="1000px"
        @before-open="beforeOpen"
        style="z-index: 9999"
    >
        <div
            class="w-full transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 shadow-xl transition-all sm:p-6"
        >
            <div class="relative overflow-hidden bg-white">
                <div class="relative">
                    <div
                        class="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8"
                    >
                        <div
                            class="mx-auto max-w-xl px-4 sm:px-6 lg:mx-0 lg:max-w-none lg:py-16 lg:px-0"
                        >
                            <div>
                                <div class="mt-6">
                                    <h2
                                        class="text-xl font-extrabold tracking-tight text-gray-900"
                                        >How to fix Lightspeed timeout errors
                                    </h2>
                                    <p class="mt-4 text-sm text-gray-700"
                                        >Orders aren't being accepted by your
                                        POS.
                                    </p>
                                    <ul class="mt-4">
                                        <li
                                            @mouseover="setVideo()"
                                            class="rounded-md p-2 transition transition-all hover:bg-gray-100"
                                        >
                                            <h5
                                                class="text-sm font-bold text-gray-700"
                                                >Lightspeed open & logged in
                                            </h5>
                                            <p
                                                class="mt-1 text-sm text-gray-600"
                                                >Make sure the Lightspeed
                                                application is open and logged
                                                in on the master iPad
                                            </p>
                                        </li>
                                        <li
                                            @mouseover="setVideo('internet')"
                                            class="mt-4 rounded-md p-2 transition transition-all hover:bg-gray-100"
                                        >
                                            <h5
                                                class="text-sm font-bold text-gray-700"
                                                >Internet connectivity
                                            </h5>
                                            <p
                                                class="mt-1 text-sm text-gray-600"
                                                >Check the master iPad has a
                                                working internet connection to
                                                Lightspeed Cloud. Click the 4
                                                dots at the bottom right
                                            </p>
                                        </li>
                                        <li
                                            class="mt-4 rounded-md p-2 transition transition-all hover:bg-gray-100"
                                            @mouseover="setVideo('sync')"
                                        >
                                            <h5
                                                class="text-sm font-bold text-gray-700"
                                                >Latest Lighspeed configuration
                                            </h5>
                                            <p
                                                class="mt-1 text-sm text-gray-600"
                                                >Check the master iPad doesn't
                                                require a config update, click
                                                the 4 dots at the bottom right
                                                and click the refresh icon.
                                            </p>
                                        </li>
                                        <li
                                            class="mt-4 rounded-md p-2 transition transition-all hover:bg-gray-100"
                                        >
                                            <h5
                                                class="text-sm font-bold text-gray-700"
                                                >Get help
                                            </h5>
                                            <p
                                                class="mt-1 text-sm text-gray-600"
                                                >Click the button below to chat
                                                with support about this failed
                                                order
                                            </p>
                                            <button
                                                @click="openIntercom"
                                                type="button"
                                                class="focus:outline-none mt-2 inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            >
                                                Live chat
                                                <base-help-icon
                                                    class="ml-2 -mr-1 h-5 w-5"
                                                />
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="py-8 lg:py-16">
                            <div
                                class="-mr-48 pl-4 sm:pl-6 md:-mr-16 lg:relative lg:m-0 lg:mt-8 lg:h-full lg:px-0"
                            >
                                <video
                                    class="h-auto w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:left-0"
                                    :src="video"
                                    autoplay
                                    loop
                                    muted
                                ></video>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-5 sm:mt-4 sm:flex sm:justify-end sm:pl-4">
                <span
                    class="mt-3 flex w-full self-end rounded-md shadow-sm sm:mr-3 sm:mt-0 sm:w-auto"
                >
                    <button
                        @click="hideModal()"
                        type="button"
                        class="focus:outline-none inline-flex w-full items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium leading-6 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline sm:text-sm sm:leading-5"
                    >
                        Close
                    </button>
                </span>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    name: 'PosErrorModal',
    data() {
        return {
            orderId: null,
            venueSlug: null,
            video: null
        };
    },
    methods: {
        beforeOpen({ params }) {
            this.orderId = params.orderId;
            this.venueSlug = params.venueSlug;
        },
        hideModal() {
            this.$modal.hide('pos-error-modal');
        },
        openIntercom() {
            if (window.Intercom) {
                window.Intercom(
                    'showNewMessage',
                    `Hi! I need help fixing a timeout errors for our store (https://order.storekit.com/${this.venueSlug}), here's an example order number: ${this.orderId}`
                );
            } else {
                window.alert(
                    'Please disable your AdBlocker to use live chat support.'
                );
            }
        },
        setVideo(tab) {
            switch (tab) {
                case 'internet':
                    this.video =
                        'https://ucarecdn.com/52d52f26-5b64-4ba6-87e9-cfd1a884ec89/internet.mp4';
                    break;
                case 'sync':
                    this.video =
                        'https://ucarecdn.com/53004015-17bf-4974-be17-a28c2561add7/lsinternet.mp4';
                    break;
                default:
                    this.video = null;
                    break;
            }
        }
    }
};
</script>
