<template>
    <div
        class="focus:outline-none relative z-0 flex-1 overflow-y-auto bg-sk-silver-grey pb-6"
    >
        <ToggleSidebarButton />

        <div
            class="relative mx-4 mt-4 glass sm:bg-white px-2 sm:shadow-sm sm:px-6 lg:px-8 print:hidden"
        >
            <div>
                <div class="sm:hidden">
                    <label for="tabs" class="sr-only">Select a report</label>
                    <select
                        @change="navigateToReport($event)"
                        id="tabs"
                        name="tabs"
                        class="focus:outline-none block w-full rounded-md border border-gray-300 px-3 py-2 pr-10 text-sm shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    >
                      <option
                          v-for="item in navItems"
                          :key="item.name"
                          :value="item.name"
                      >
                        {{ item.label }}
                      </option>
                    </select>
                </div>
                <div class="hidden sm:block">
                    <div>
                        <nav
                            class="-mb-px flex space-x-8 overflow-x-scroll"
                            aria-label="Tabs"
                        >
                          <router-link
                              v-for="item in navItems"
                              :key="item.name"
                              :to="{ name: item.name }"
                              active-class="border-black text-black font-medium"
                              class="flex-shrink-0 whitespace-nowrap border-b-2 border-transparent px-1 py-3 text-xs font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                              :aria-current="item.name === $route.name ? 'page' : null"
                          >
                            {{ item.label }}
                          </router-link>
                        </nav>
                    </div>
                </div>
                <div class="absolute right-0 top-0 px-4 pt-6 sm:px-6 lg:px-8">
                    <div class="md:flex md:items-center md:justify-between">
                        <div
                            class="h-4 w-4"
                            :class="{ 'text-gray-900 spinner': isLoading }"
                        >
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="mx-auto py-4 sm:mt-0 sm:px-4 sm:px-6 md:mt-2"
            v-if="!isLoading && venues"
        >
            <div
                class="relative z-50 inline-block px-2 pb-4 text-left sm:float-right sm:px-0 sm:pb-0"
            >
                <div>
                    <span class="rounded-sm shadow-sm">
                        <venue-selector
                            v-if="renderVenueSelector"
                            :venues="venues"
                            :multiple-select="!isDeliveryReport"
                            :allow-empty="false"
                            :default-venue-select="defaultVenueSelect"
                            :pre-selected="selectedVenues"
                            :is-report="true"
                            @selected="setVenues"
                        />
                    </span>
                </div>
            </div>
            <router-view :selected-venues="selectedVenues"></router-view>
        </div>

        <div
            v-else
            class="mx-auto mt-10 p-4 px-4 sm:mt-0 sm:mt-12 sm:px-6 md:px-8"
        >
            <div
                class="mb-4 flex h-60 w-full items-center justify-center bg-white p-8 shadow sm:rounded-md"
            >
                <div class="w-48">
                    <ReportLoading />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ReportLoading from '@/components/helpers/ReportLoading';
import ToggleSidebarButton from '@/components/helpers/ToggleSidebarButton';
import VenueSelector from '@/components/selectors/VenueSelector';
import compareArray from '@/helpers/compareArray';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'SalesReports',
    metaInfo: {
        title: 'Sales Reports'
    },
    components: {
        ReportLoading,
        ToggleSidebarButton,
        VenueSelector
    },
    computed: {
        ...mapGetters({
            availableVenues: 'venues/getVenues',
            archivedVenues: 'venues/getArchivedVenues'
        }),
        venues() {
            return [...this.availableVenues, ...this.archivedVenues];
        },
        isDeliveryReport() {
            return this.$route.path === '/reports/sales/delivery';
        }
    },
    watch: {
        isDeliveryReport() {
            this.renderVenueSelector = false;

            this.$nextTick(() => {
                this.renderVenueSelector = true;
            });
        }
    },
    data() {
        return {
            isLoading: true,
            selectedVenues: [],
            renderVenueSelector: true,
            defaultVenueSelect: 0,
            navItems: [
              { name: 'ordersReport', label: 'Revenue' },
              { name: 'revenueByTableArea', label: 'Revenue by area' },
              { name: 'zReport', label: 'Z-Report' },
              { name: 'averageOrderValueReport', label: 'Average Order Value' },
              { name: 'dowReport', label: 'Day of Week' },
              { name: 'todReport', label: 'Time of Day' },
              { name: 'productsReport', label: 'Products' },
              { name: 'categoriesReport', label: 'Categories' },
              { name: 'deliveryReport', label: 'Deliveries' },
              { name: 'tipsReports', label: 'Tips' },
              { name: 'discountsReports', label: 'Discounts' },
              { name: 'discountedOrdersReport', label: 'Discounted Orders' },
              { name: 'refundedOrdersReport', label: 'Refunded orders' },
              { name: 'failedOrdersReport', label: 'Failed Orders' }
            ]
        };
    },
    async created() {
        this.isLoading = true;

        await Promise.all([
            !this.availableVenues?.length > 0 ? this.fetchVenues() : null,
            !this.archivedVenues?.length > 0
                ? this.fetchVenues({ archived: true })
                : null
        ]);

        if (this.venues?.length > 0) {
            this.setFirstVenue();
        }

        this.isLoading = false;
    },
    methods: {
        ...mapActions({
            fetchVenues: 'venues/fetch'
        }),
        navigateToReport(event) {
            this.$router.push({ name: event.target.value });
        },
        venueById(id) {
            return this.venues.find(venue => venue.id === id);
        },
        setFirstVenue() {
            const firstVenue = this.venues[0].id;

            this.defaultVenueSelect = firstVenue;
            this.setVenues([firstVenue]);
        },
        setVenues(ids) {
            if (!ids) {
                return;
            }

            const idsArray = Array.isArray(ids) ? ids : [ids];

            if (this.isDeliveryReport) {
                this.selectedVenues = idsArray;

                return;
            }

            if (compareArray(idsArray, this.selectedVenues)) {
                return;
            }

            this.selectedVenues = idsArray;
        }
    }
};
</script>
