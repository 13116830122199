const settings =
    JSON.parse(localStorage.getItem('allOrdersUserSettings')) || [];

const state = {
    userSettings: {
        showRejected: settings.showRejected || false,
        archived: settings.archived || false,
        byDate: settings.byDate || false,
        venueFilter: settings.venueFilter || null,
        orderFilter: settings.orderFilter || null,
        search: settings.search || null,
        tableId: settings.tableId || null,
        limit: settings.limit ? parseInt(settings.limit) : 20,
        from: settings.from || null,
        to: settings.to || null
    }
};

const mutations = {
    UPDATE_USER_SETTINGS(state, settings) {
        state.userSettings = settings;
    }
};

const getters = {
    userSettings(state) {
        return state.userSettings;
    }
};

const actions = {
    editUserSettings({ commit }, settings) {
        localStorage.setItem('allOrdersUserSettings', JSON.stringify(settings));
        commit('UPDATE_USER_SETTINGS', settings);
    },
    clearUserSettings({ commit }) {
        const defaultSettings = {
            showRejected: false,
            archived: false,
            byDate: false,
            venueFilter: null,
            orderFilter: null,
            limit: null,
            tableId: null,
            search: null,
            from: null,
            to: null
        };

        localStorage.setItem(
            'allOrdersUserSettings',
            JSON.stringify(defaultSettings)
        );
        commit('UPDATE_USER_SETTINGS', defaultSettings);
    }
};

export default {
    namespaced: true,
    getters,
    mutations,
    state,
    actions
};
