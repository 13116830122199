<template>
    <div class="">
        <div class="relative rounded-md shadow-sm">
            <div
                class="pointer-events-none absolute inset-y-0 left-0 flex items-center"
                :class="[size === 'sm' ? 'pl-2' : 'pl-3']"
            >
                <base-small-search-icon class="h-5 w-5 text-gray-400" />
            </div>
            <input
                :value="value"
                @input="emitValue($event.target.value)"
                type="text"
                :placeholder="placeholder"
                class="focus:outline-none form-input block w-full rounded-md border border-gray-300 text-xs focus:border-blue-300 focus:shadow-outline-blue sm:leading-5"
                :class="[size === 'sm' ? 'py-1 pl-8' : 'py-2 pl-10']"
            />
        </div>
    </div>
</template>

<script>
import BaseSmallSearchIcon from '@/components/base/icons/BaseSmallSearchIcon';
export default {
    name: 'SearchInput',
    components: { BaseSmallSearchIcon },
    data() {
        return {
            timeout: null
        };
    },
    beforeDestroy() {
        clearInterval(this.timeout);
    },
    props: {
        value: {
            type: String,
            required: false,
            default: ''
        },
        min: {
            type: Number,
            required: false,
            default: null
        },
        max: {
            type: Number,
            required: false,
            default: null
        },
        placeholder: {
            type: String,
            required: false,
            default: 'Search'
        },
        size: {
            type: String,
            default: 'md',
            required: false
        }
    },
    methods: {
        emitValue(value) {
            if (
                value.length === 0 ||
                (this.min <= value.length && value.length <= this.max)
            ) {
                clearTimeout(this.timeout);
                this.timeout = setTimeout(
                    () => {
                        this.$emit('input', value);
                    },
                    value.length === 0 ? 0 : 600
                );
            }

            return false;
        }
    }
};
</script>
