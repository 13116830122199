<template>
    <div
        class="flex w-full flex-wrap gap-2 rounded-md border px-3 py-1.5 text-sm transition duration-150 ease-in-out"
        :class="
            error
                ? 'border-red-300'
                : 'border-gray-300 focus-within:border-blue-300 focus-within:shadow-outline-blue'
        "
    >
        <div
            v-for="(item, index) in computedValue"
            :key="`tag_${index}`"
            class="flex items-center justify-center gap-2 break-all rounded bg-gray-50 px-2 py-0.5 text-sm text-gray-700 ring-1 ring-inset ring-gray-300"
        >
            {{ item }}

            <button
                class="flex h-4 w-4 items-center justify-center rounded-sm hover:bg-gray-200"
                @click="removeItem(index)"
            >
                <base-close-icon class="max-w-4 max-h-4" />
            </button>
        </div>

        <input
            :id="id"
            v-model.trim="pendingValue"
            inputmode="text"
            :placeholder="placeholder"
            class="outline-none placeholder:text-neutral-500 h-6 flex-1"
            @keydown="handleKeyDown"
            @blur="addPendingValue"
        />
    </div>
</template>

<script>
export default {
    name: 'BaseTagsInput',

    props: {
        id: {
            type: String,
            default: Math.random().toString(36).substring(2)
        },

        value: {
            type: Array,
            default: () => []
        },

        placeholder: {
            type: String,
            default: ''
        },

        error: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            pendingValue: ''
        };
    },

    computed: {
        computedValue: {
            get() {
                return this.value;
            },

            set(value) {
                this.$emit('input', value);
            }
        }
    },

    methods: {
        handleKeyDown(event) {
            if (event.key === 'Enter' || event.key === ',') {
                event.preventDefault();

                this.addPendingValue();
            }
        },

        removeItem(index) {
            this.computedValue.splice(index, 1);
        },

        addPendingValue() {
            if (this.pendingValue) {
                const pendingValue = this.pendingValue.includes(',')
                    ? this.pendingValue
                          .split(',')
                          .map(value => value.trim())
                          .filter(value => !!value)
                    : [this.pendingValue];

                const newData = [
                    ...new Set([...this.computedValue, ...pendingValue])
                ];

                this.computedValue = newData;
                this.pendingValue = '';
            }
        }
    }
};
</script>
