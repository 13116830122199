<template>
    <modal
        name="manager-pin-change-modal"
        :adaptive="true"
        height="auto"
        width="500px"
        class="cursor-default"
        style="z-index: 9999"
        :clickToClose="true"
        @before-open="beforeOpen"
    >
        <div
            class="w-full transform overflow-hidden rounded-lg bg-white shadow-xl transition-all"
        >
            <div
                class="relative overflow-hidden bg-white p-6 pb-4 pt-4 shadow-sm"
            >
                <div class="relative">
                    <h2
                        class="flex flex-row items-center text-lg font-heading font-semibold text-gray-800"
                    >
                        <base-lock-icon />
                        {{
                            {
                                [StepsEnum.CURRENT_PIN]: 'Current PIN',
                                [StepsEnum.NEW_PIN]: 'New PIN',
                                [StepsEnum.CONFIRM_NEW_PIN]: 'Confirm PIN'
                            }[step]
                        }}
                    </h2>
                </div>
            </div>
            <div class="px-6">
                <div
                    class="mt-8 mb-8 flex flex-col items-center justify-center"
                    :class="{ 'opacity-50 spinner': isLoading }"
                >
                    <PincodeInput
                        v-if="step === StepsEnum.CURRENT_PIN"
                        v-model="currentPinCode"
                        :characterPreview="true"
                        :secure="true"
                    />
                    <PincodeInput
                        v-else-if="step === StepsEnum.NEW_PIN"
                        v-model="newPinCode"
                        :characterPreview="true"
                        :secure="true"
                    />
                    <PincodeInput
                        v-else
                        v-model="newPinCodeConfirm"
                        :characterPreview="true"
                        :secure="true"
                    />
                    <div class="mt-1 text-xs text-red-500">
                        <span>{{ errorText }}</span>
                    </div>
                </div>
            </div>
            <div class="mt-5 flex w-full p-6 pt-0">
                <span
                    class="mt-3 flex w-full justify-between rounded-md sm:mt-0"
                >
                    <button
                        @click="hideModal()"
                        type="button"
                        class="focus:outline-none inline-flex w-auto justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium leading-6 text-gray-700 shadow-sm shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline sm:text-sm sm:leading-5"
                    >
                        Cancel
                    </button>

                    <button
                        v-if="step === StepsEnum.CURRENT_PIN"
                        @click="confirmCurrent()"
                        type="button"
                        class="focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                    >
                        Next
                    </button>
                    <button
                        v-else-if="step === StepsEnum.NEW_PIN"
                        @click="confirmNew()"
                        type="button"
                        class="focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                    >
                        Confirm
                    </button>
                    <button
                        v-else
                        @click="changeManagerPin()"
                        type="button"
                        class="focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                    >
                        Set PIN
                    </button>
                </span>
            </div>
        </div>
    </modal>
</template>

<script>
import PincodeInput from 'vue-pincode-input';

const StepsEnum = Object.freeze({
    CURRENT_PIN: 'current',
    NEW_PIN: 'new',
    CONFIRM_NEW_PIN: 'confirm'
});

export default {
    name: 'ManagerPinChangeModal',
    components: {
        PincodeInput
    },
    data() {
        return {
            isLoading: false,
            currentPinCode: '',
            newPinCode: '',
            newPinCodeConfirm: '',
            venueId: null,
            errorText: null,
            step: StepsEnum.CURRENT_PIN,
            StepsEnum
        };
    },
    methods: {
        beforeOpen({ params }) {
            Object.assign(this.$data, this.$options.data.apply(this));
            this.venueId = params.venueId;
        },
        hideModal(eventType = 'cancel') {
            this.$emit(eventType);
            this.$modal.hide('manager-pin-change-modal');
        },
        async confirmCurrent() {
            this.errorText = null;

            if (!this.currentPinCode || this.currentPinCode.length !== 4) {
                this.errorText = 'Please enter a valid 4-digit PIN';

                return;
            }

            try {
                this.isLoading = true;
                const { data } = await this.$axios.post(
                    `/venues/${this.venueId}/manager-pin-check`,
                    {
                        pinCode: this.currentPinCode
                    }
                );

                if (!data) {
                    this.errorText = 'Please enter a valid 4-digit PIN';

                    return;
                }

                this.step = StepsEnum.NEW_PIN;
            } catch (error) {
                throw new Error(error);
            } finally {
                this.isLoading = false;
            }
        },
        confirmNew() {
            this.errorText = null;

            if (!this.newPinCode || this.newPinCode.length !== 4) {
                this.errorText = 'Please enter a valid 4-digit PIN';

                return;
            }

            if (this.newPinCode === this.currentPinCode) {
                this.errorText =
                    'New 4-digit PIN should be different from current one';

                return;
            }

            this.step = StepsEnum.CONFIRM_NEW_PIN;
        },
        async changeManagerPin() {
            if (this.newPinCode === this.newPinCodeConfirm) {
                try {
                    this.isLoading = true;
                    await this.$axios.patch(
                        `/venues/${this.venueId}/manager-pin`,
                        {
                            currentPinCode: this.currentPinCode,
                            newPinCode: this.newPinCode
                        }
                    );

                    this.hideModal('pin-changed');
                } catch (error) {
                    this.errorText = 'Sorry, something went wrong.';
                    throw new Error(error);
                } finally {
                    this.isLoading = false;
                }
            } else {
                this.errorText = "Provided 4-digit PIN code doesn't match";
            }
        }
    }
};
</script>

<style>
input.vue-pincode-input {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 5px;
    box-shadow: none;
    border: 3px solid rgb(53 53 53 / 14%);
    background-color: rgba(243, 243, 240);
    max-width: 60px;
    font-size: 1.8rem;
    border-radius: 5px;
}
input.vue-pincode-input:focus {
    box-shadow: none;
    border-color: rgba(17, 24, 39);
}
</style>
