<template>
    <div class="relative shadow-sm">
        <multi-select
            ref="multiselect"
            v-model="selectedOptions"
            :options="options"
            :multiple="true"
            :close-on-select="false"
            :searchable="false"
            :preselect-first="true"
            :deselectLabel="availableOptions.length > 2 ? 'Remove' : ' '"
            :selectLabel="availableOptions.length > 2 ? 'Select' : ' '"
            :allow-empty="false"
            label="name"
            track-by="id"
            class="cursor-pointer"
            :class="{ active: selectedOptions }"
            @input="updateMultipleSelection(selectedOptions)"
            @close="updateMultipleSelection(selectedOptions)"
        >
            <template slot="placeholder">
                <span class="ml-1 select-none font-medium text-gray-700">
                    {{ placeholder }}
                </span>
            </template>

            <template slot="option" slot-scope="props">
                <div
                    v-if="!props.option.selectAllOption"
                    class="flex items-center justify-start"
                >
                    <div
                        class="leading-2 relative inline-block h-4 w-48 overflow-hidden whitespace-nowrap"
                        :class="{
                            'scroll-truncated': props.option.name.length > 30
                        }"
                    >
                        <span
                            :class="{
                                'text-gray-200': props.option.archived
                            }"
                            class="absolute translate-y-0 transform whitespace-nowrap pr-3"
                        >
                            {{ props.option.adminName || props.option.name }}
                        </span>
                    </div>
                </div>
                <div v-else class="flex items-center justify-center">
                    <span style="font-weight: 500 !important">
                        {{ props.option.adminName || props.option.name }}
                    </span>
                </div>
            </template>
            <template #selection="{ values, remove }" v-if="!showAllStores">
                <div class="multiselect__tags-wrap">
                    <span
                        v-for="(option, index) in values.slice(0, 3)"
                        :key="index"
                        class="multiselect__tag"
                        @click="remove(option)"
                    >
                        {{ option.adminName || option.name }}
                        <span class="multiselect__tag-icon"></span>
                    </span>
                    <span
                        v-if="values.length > 3"
                        class="multiselect__tag"
                        @click="showAllStores = true"
                    >
                        +{{ values.length - 3 }} more
                    </span>
                </div>
            </template>
        </multi-select>
    </div>
</template>

<script>
export default {
    name: 'MultiselectWithSelectAll',
    data() {
        return {
            options: [],
            selectedOptions: [],
            selectLabel: 'Select all',
            deselectLabel: 'Deselect all',
            showAllStores: false
        };
    },
    props: {
        availableOptions: {
            type: [Array, Number],
            required: true
        },
        preSelected: {
            type: [Array, Number],
            required: false
        },
        placeholder: {
            type: String,
            required: false,
            default: 'Select option'
        }
    },
    watch: {
        preSelected(value) {
            if (!this.selectedOptions || !this.selectedOptions.length) {
                this.refreshPreSelected(value);
            }
        }
    },
    mounted() {
        this.selectedOptions = [];

        this.options = [...this.availableOptions];

        if (this.options.length > 1) {
            this.options.unshift({
                name: this.selectLabel,
                selectAllOption: true,
                selectedAll: false,
                isReset: false
            });
        }

        this.refreshPreSelected(this.preSelected);
    },
    methods: {
        refreshPreSelected(data) {
            this.selectedOptions = [];

            if (!data) {
                return;
            }

            if (data.length) {
                for (const item of data) {
                    this.selectedOptions.push(item);
                }
            } else {
                this.selectedOptions = data;
            }

            this.updateMultipleSelection(this.selectedOptions);
        },
        updateMultipleSelection(selectedOptions) {
            if (this.$refs.multiselect && !this.$refs.multiselect.isOpen) {
                this.showAllStores = false;
            }

            const isResetOption = selectedOptions
                ? selectedOptions.some(selectedOption => selectedOption.isReset)
                : null;

            const selectAllObject = this.options.find(
                venueOption => venueOption.selectAllOption
            );

            if (isResetOption && selectAllObject) {
                selectAllObject.selectedAll = false;
                selectAllObject.isReset = false;
                selectAllObject.name = this.selectLabel;
            }

            if (!selectedOptions || !selectedOptions.length || isResetOption) {
                this.selectedOptions = null;

                return this.$emit('selected', this.selectedOptions);
            }

            const selectAll = selectedOptions.find(
                selectedOption => selectedOption.selectAllOption
            );

            const index = this.options.findIndex(
                venueOption => venueOption.selectAllOption
            );

            if (selectAll) {
                return this.selectAllOptions(selectAllObject, index);
            }

            const selectedOptionsToUpdate = selectedOptions.filter(
                selectedOption => selectedOption.id
            );

            if (selectAllObject) {
                const isAllSelected =
                    this.availableOptions.length ===
                    selectedOptionsToUpdate.length;

                selectAllObject.selectedAll = isAllSelected;
                selectAllObject.isReset = isAllSelected;
                selectAllObject.name = isAllSelected
                    ? this.deselectLabel
                    : this.selectLabel;

                this.options.splice(index, 1, selectAllObject);
            }

            this.$emit('selected', this.selectedOptions);
        },

        selectAllOptions(selectAllObject, index) {
            selectAllObject.selectedAll = true;
            selectAllObject.isReset = true;
            selectAllObject.name = this.deselectLabel;

            this.options.splice(index, 1, selectAllObject);

            this.selectedOptions = this.availableOptions;

            this.$emit('selected', this.selectedOptions);
        }
    }
};
</script>
