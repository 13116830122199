<template>
    <article class="z-50 rounded-md bg-white w-full flex items-row py-2 px-2 mb-5 text-base border border-gray-200 shadow-sm print:shadow-none print:border-none">
        <div>
          <avatar
              v-if="comment?.user?.name"
              :username="comment.user.name"
              :size="24"
              class="mr-2 mt-1"
          />
        </div>
      <div class="w-full">
        <footer class="mb-2 flex items-center w-full justify-between">
          <div class="flex items-center">
            <p
                class="inline-flex items-center text-sm font-semibold text-gray-800"
            >
              {{ comment?.user?.name }}</p
            >
            <p class="ml-1 text-sm text-gray-500"
            > commented at <time pubdate>
              {{
                comment.created_at | moment('k:mm, Do MMMM')
              }}</time
            ></p
            >
          </div>
          <div
              v-if="currentUser.id === comment.userId"
              v-click-outside="onClickOutside"
              class="relative inline-block select-none text-left print:hidden"
          >
                <span class="rounded-md">
                    <button
                        @click="showMoreActions = !showMoreActions"
                        id="dropdownCommentButton"
                        data-dropdown-toggle="dropdownComment"
                        class="focus:outline-none inline-flex items-center rounded-lg bg-white p-2 text-center text-sm font-medium text-gray-500 hover:bg-gray-100 focus:ring-4 focus:ring-gray-50 dark:bg-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                        type="button"
                    >
                        <svg
                            class="h-4 w-4"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 16 3"
                        >
                            <path
                                d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z"
                            />
                        </svg>
                        <span class="sr-only">Comment settings</span>
                    </button>
                </span>
            <transition
                enter-active-class="transition ease-out duration-100"
                enter-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
            >
              <div
                  v-if="showMoreActions"
                  class="absolute right-0 z-50 mt-2 w-36 origin-top-right rounded-md shadow-lg"
              >
                <div
                    class="shadow-xs rounded-md bg-white"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                >
                  <div
                      v-show="currentUser.id === comment.userId"
                      class="py-1"
                  >
                                <span
                                    @click="deleteComment(comment.id)"
                                    class="focus:outline-none group flex cursor-pointer items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900"
                                    role="menuitem"
                                >
                                    Delete
                                </span>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </footer>
        <span
            v-html="comment.text"
            class="text-sm text-gray-600"
        ></span>
        <div class="mt-2 flex items-center space-x-4"> </div>

        <a
            :href="attachment.url"
            target="_blank"
            class="group m-1 flex items-center rounded-lg bg-gray-50 p-2"
            v-for="(attachment, index) of comment.attachments"
            :key="attachment.url + index"
        >
          <div class="mr-3">
            <div v-if="!isPdf(attachment.name)">
              <img
                  class="h-8 w-8 rounded-lg shadow-inner sm:h-10 sm:w-10"
                  :src="
                            attachment.url +
                            '-/scale_crop/80x80/smart/-/format/auto/'
                        "
                  :alt="attachment.name"
              /></div>
            <div v-else :href="attachment.url" target="_blank">
              <base-doc-icon class="h-10 w-10" />
            </div>
          </div>
          <div class="text-sm text-gray-500 dark:text-gray-400">
            {{ attachment.name }}
          </div>
        </a>
      </div>

    </article>
</template>

<script>
import vClickOutside from 'v-click-outside';
import Avatar from 'vue-avatar';
import { mapGetters } from 'vuex';

export default {
    name: 'CommentComponent',
    props: {
        comment: {
            type: Object,
            required: true
        }
    },
    components: {
        Avatar
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    data() {
        return {
            showMoreActions: false
        };
    },

    computed: {
        ...mapGetters({ currentUser: 'user/currentUser' })
    },

    methods: {
        onClickOutside() {
            this.showMoreActions = false;
        },

        async deleteComment(id) {
            this.showMoreActions = false;

            try {
                await this.$axios.delete(`/comments/${id}`);

                this.$emit('fetch-comments');
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        },
        isPdf(fileName) {
            return fileName.split('.')?.pop() === 'pdf';
        }
    }
};
</script>

<style>
.mention {
    @apply rounded-md bg-gray-100 font-medium;
    padding: 2px 6px;
}
</style>
