<template>
    <div>
        <label
            v-if="label"
            :for="id"
            v-tooltip="tooltip"
            class="mb-2 flex items-center text-sm font-medium leading-5 text-gray-700"
        >
            {{ label }}
            <span v-if="labelPostfix" class="font-normal text-gray-500">
                {{ labelPostfix }}
            </span>
            <base-question-icon v-if="tooltip" class="ml-1" />
        </label>

        <div class="flex">
            <span
                role="checkbox"
                tabindex="0"
                :class="[
                    value ? 'bg-indigo-600' : 'bg-gray-200',
                    isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'
                ]"
                @click="onChange"
                :aria-checked="value.toString()"
                class="focus:outline-none relative block h-6 w-11 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:shadow-outline"
            >
                <span
                    aria-hidden="true"
                    :class="[value ? 'translate-x-5' : 'translate-x-0']"
                    class="relative inline-block h-5 w-5 transform rounded-full bg-white shadow transition duration-200 ease-in-out"
                >
                    <span
                        :class="[
                            value
                                ? 'opacity-0 duration-100 ease-out'
                                : 'opacity-100 duration-200 ease-in'
                        ]"
                        class="absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
                    >
                        <svg
                            class="h-3 w-3 text-gray-400"
                            fill="none"
                            viewBox="0 0 12 12"
                        >
                            <path
                                d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </span>
                    <span
                        :class="[
                            value
                                ? 'opacity-100 duration-200 ease-in'
                                : 'opacity-0 duration-100 ease-out'
                        ]"
                        class="absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
                    >
                        <svg
                            class="h-3 w-3 text-indigo-600"
                            fill="currentColor"
                            viewBox="0 0 12 12"
                        >
                            <path
                                d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"
                            />
                        </svg>
                    </span>
                </span>

                <toggle-confirm-modal
                    v-if="confirm"
                    @confirm="emitChange()"
                    :options="{
                        title: confirmTitle,
                        description: confirmDescription
                    }"
                />
            </span>
            <p
                v-if="extraTextOn || extraTextOff"
                class="flex items-center pl-4 text-sm text-gray-500"
            >
                {{ value ? extraTextOn : extraTextOff }}
            </p>
        </div>
    </div>
</template>

<script>
import ToggleConfirmModal from '@/components/modals/ToggleConfirmModal';

export default {
    name: 'ToggleSwitch',
    components: {
        ToggleConfirmModal
    },
    props: {
        value: {
            type: Boolean,
            default: false,
            required: true
        },
        confirm: {
            type: Boolean,
            default: false
        },
        confirmTitle: {
            type: String,
            default: 'Confirm changes'
        },
        confirmDescription: {
            type: String,
            default:
                'To confirm changes, please click the confirm button shown below.'
        },
        modalName: {
            type: String,
            default: 'toggle-confirm-modal'
        },
        label: {
            type: String,
            required: false,
            default: ''
        },
        labelPostfix: {
            type: String,
            required: false,
            default: ''
        },
        tooltip: {
            type: String,
            required: false,
            default: ''
        },
        id: {
            type: String,
            required: false
        },
        isDisabled: {
            type: Boolean,
            required: false,
            default: false
        },
        extraTextOff: {
            type: String,
            required: false,
            default: ''
        },
        extraTextOn: {
            type: String,
            required: false,
            default: ''
        }
    },
    methods: {
        onChange() {
            if (this.isDisabled) {
                return;
            }

            this.$emit('toggle-enable');
            this.confirm ? this.displayConfirmModal() : this.emitChange();
        },
        displayConfirmModal() {
            this.$modal.show('toggle-confirm-modal');
        },
        emitChange() {
            this.$modal.hide('toggle-confirm-modal');
            this.$emit('input', !this.value);
        }
    }
};
</script>
