<template>
    <div
        class="fixed inset-0 flex flex-col items-center justify-center gap-4 p-4 text-center"
    >
        <span class="text-lg">
            Sorry, the store designer is only available on large screens
        </span>

        <router-link
            :to="{ name: 'editVenue' }"
            class="inline-flex items-center text-sm text-gray-600"
        >
            <base-back-icon />
            Go back to the settings
        </router-link>
    </div>
</template>

<script>
export default {
    name: 'MobileDeviceWarning'
};
</script>
