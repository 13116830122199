<template>
  <div>
    <div class="md:flex md:items-center md:justify-between">
      <div class="min-w-0 flex-1">
        <router-link
            :to="{ name: 'editVenue' }"
            class="mb-2 flex items-center text-sm text-gray-500 inline-flex"
        >
          <base-back-icon />
          <span>Settings</span>
        </router-link>
        <base-page-title title="Acteol Integration" />
      </div>

      <BaseSpinner :isLoading="isLoading" />
    </div>
    <div v-if="!isLoading && !showSettings" class="py-8">
      <FeaturePanelWithScreenshot
          title="Acteol CRM"
          description="Connect guest and transaction data with Acteol CRM."
          :features="acteolFeatures"
          image="https://ucarecdn.com/a7b3b6d6-2727-4c35-ab43-f470c9dc216a/-/quality/smart_retina/-/format/auto/"
      >
        <template #cta>
          <base-button
              v-if="
                  isFeatureAvailable(FeaturesEnum.SAAS) ||
                  isFeatureAvailable(FeaturesEnum.INTERNAL)
              "
              class="mt-2 sm:ml-3 sm:mt-0"
              @clicked="showSettings = true"
              buttonText="Enable Acteol CRM"
          />
          <base-upsell-button
              v-else
              upsell-item="acteol"
              buttonText="Upgrade to enable Acteol"
              :disabled="isLoading"
          ></base-upsell-button>
        </template>
      </FeaturePanelWithScreenshot>
    </div>
    <div v-if="!isLoading && showSettings">
      <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="sm:pl-0 sm:pr-5">
            <h3
                class="text-base font-semibold leading-6 text-gray-800"
            >Details</h3
            >
            <p class="mt-2 text-sm text-gray-500">
              Connect to Acteol CRM
            </p>
          </div>
        </div>
        <div class="mt-5 md:col-span-2 md:mt-0">
          <div class="shadow sm:overflow-hidden sm:rounded-md">
            <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
              <div class="grid grid-cols-3 gap-6">
                <div class="col-span-3">
                  <base-input-field
                      disabled
                      label="Acteol Host"
                      prefix="https://"
                      :value="$store.state.user.profile?.accounts[0]?.atreemo?.host"
                      type="text"
                      id="host"
                  />
                </div>
                <div class="col-span-3">
                  <base-input-field
                      label="Site ID"
                      type="number"
                      id="site-id"
                      :errors="$v.atreemo.siteId.$error"
                      v-model.trim="atreemo.siteId"
                  />
                </div>
                <div class="col-span-3">
                  <base-input-field
                      label="Brand ID"
                      label-postfix="(optional)"
                      id="brand-id"
                      v-model.trim="atreemo.brandId"
                  />
                </div>
                <div class="col-span-3 flex-col flex">
                  <label
                      class="mb-2 text-sm flex items-center font-medium text-gray-700"
                      v-tooltip="'Only send data to acteol for guests who have opted in to receive marketing communications.'"
                  >
                    Opt-in only
                    <base-question-icon class="ml-1 text-gray-700" />
                  </label>
                  <base-small-toggle-switch
                      v-model="atreemo.optInOnly"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="my-6 ml-4 flex flex-shrink-0 justify-end">
        <base-button @clicked="saveVenue" button-text="Save" size="sm" :disabled="isLoading" />
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import FeaturePanelWithScreenshot from "@/components/widgets/FeaturePanelWithScreenshot.vue";
import { FeaturesEnum } from "@/enums";
import { mapGetters } from "vuex";

export default {
  name: 'AtreemoSettings',
  components: { FeaturePanelWithScreenshot },
  props: ['venueId', 'venue'],
  metaInfo: {
    title: 'Acteol Settings'
  },
  data() {
    return {
      isLoading: false,
      showSettings: false,
      settings: { ...this.venue },
      atreemo: {
        siteId: null,
        optInOnly: false,
        brandId: null
      },
      FeaturesEnum,
      acteolFeatures: [
        {
          name: 'Contact sync',
          description:
              'Create and update guest profiles in Acteol CRM.'
        },
        {
          name: 'Visits',
          description:
              'Trigger visit events in Acteol CRM for each guest visit.'
        },
        {
          name: 'ROI tracking',
          description:
              'Transactional and line item data sent to Acteol for accurate ROI tracking.'
        }
      ]
    };
  },
  validations: {
    atreemo: {
      siteId: {
        required
      }
    }
  },
  async mounted() {
    await this.checkAtreemoAccount();
    if (this.settings?.integrations?.atreemo) {
      this.atreemo = this.settings.integrations.atreemo;
    }
  },
  computed: {
    ...mapGetters({
      isFeatureAvailable: 'user/isFeatureAvailable'
    })
  },
  methods: {
    async checkAtreemoAccount() {
      try {
          this.isLoading = true;
          const { data } = await this.$axios.get(`/account/check`,{
            params: {
              columnName: 'atreemo'
            }
          })

          this.showSettings = !data ? false : true;
      }
      catch (e) {
        throw new Error(e)
      }
      finally {
        this.isLoading = false;
      }
    },
    async saveVenue() {
      try {
        this.isLoading = true;

        this.$v.atreemo.$touch();

        if (this.$v.atreemo.$invalid) {
          return false;
        }

        await this.$axios.post(`/atreemo/update/`,
            {...this.atreemo, venueId: this.venueId}
        );

        this.$notify({
          group: 'settings',
          title: 'Settings saved'
        });
      } catch (e) {
        throw new Error(`API ${e}`);
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>
