<template>
    <div>
        <div class="md:flex md:items-center md:justify-between">
            <div class="min-w-0 flex-1">
                <router-link
                    :to="{ name: 'editVenue' }"
                    class="mb-2 flex items-center text-sm text-gray-500 inline-flex"
                >
                    <base-back-icon />
                    <span>Settings</span>
                </router-link>
            </div>

            <BaseSpinner :isLoading="isLoading" />
        </div>
        <div>
            <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1"> </div>
                <div class="mt-5 md:col-span-2 md:mt-0">
                    <div
                        v-if="!isLoading"
                        class="shadow sm:overflow-hidden sm:rounded-md"
                    >
                        <div class="my-6 flex flex-shrink-0 justify-center">
                            <span v-if="isSuccess" class="text-green-800">
                                Instagram Successfuly Authorized
                            </span>
                            <span v-else class="text-red-800">
                                Something went wrong, try again later
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <BaseSpacerWithRuler />
    </div>
</template>

<script>
export default {
    name: 'InstagramAuthResult',
    metaInfo: {
        title: 'Instagram Auth Result'
    },
    props: {
        venueId: {
            type: Number,
            required: true
        },
        venue: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isLoading: false,
            isSuccess: false
        };
    },
    mounted() {
        const { code } = this.$route.query;

        if (code) {
            this.getAccessToken(code);
        } else {
            this.$router.push({ name: 'editVenue' });
        }
    },
    methods: {
        async getAccessToken(code) {
            this.isLoading = true;

            const params = {
                code,
                venueId: this.venueId
            };

            try {
                const { data } = await this.$axios.get(
                    `/instagram/access-token`,
                    {
                        params
                    }
                );

                this.isSuccess = data;
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;

                setTimeout(() => {
                    this.$router.push({ name: 'stores' });
                }, 8000);
            }
        }
    }
};
</script>
