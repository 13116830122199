<template>
    <modal
        name="snooze-item-availability-modal"
        width="800px"
        height="auto"
        class="snooze-item-availability-modal overflow-visible"
        :clickToClose="true"
        @before-close="beforeClose"
        :adaptive="true"
    >
        <div
            class="transform rounded-lg bg-white p-6 shadow-xl transition-all sm:w-full"
        >
            <div>
                <div class="text-left">
                    <h3 class="text-lg font-medium leading-6 text-gray-900">
                        Disable for:
                    </h3>
                    <div class="mt-4 flex items-center justify-between">
                        <div class="flex w-full items-center">
                            <label
                                v-for="(option, key) in options"
                                @click="selectOption(option.value)"
                                :key="key"
                                class="focus:outline-none mr-2 flex cursor-pointer items-center justify-center rounded-md border py-2 px-3 text-sm font-medium transition-colors hover:border-indigo-600 sm:flex-1"
                                :class="{
                                    'cursor-not-allowed opacity-25': !option.active,
                                    'border-transparent bg-indigo-600 text-white hover:bg-indigo-700 hover:text-white':
                                        optionSelected === option.value,
                                    'hover:text-indigo-600':
                                        optionSelected !== option.value
                                }"
                            >
                                <span id="size-choice-0-label">
                                    {{ option.label }}
                                </span>
                            </label>
                        </div>
                        <div class="flex items-center sm:flex-1">
                            <span class="mr-2 min-w-max text-gray-600">
                                or until
                            </span>
                            <DateRangeSelector
                                @date-updated="selectPicker"
                                :styles="pickerStyles"
                                aligned="left"
                                :singleDate="true"
                                :allowFuture="true"
                                :disallowPast="true"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="mt-5 flex-row-reverse justify-between sm:mt-8 sm:flex sm:w-auto"
                :class="{ spinner: isLoading }"
            >
                <span class="flex w-full rounded-md shadow-sm md:w-36">
                    <button
                        @click="snooze"
                        type="button"
                        :disabled="!optionSelected && !dateSelected"
                        class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo sm:text-sm sm:leading-5"
                        :class="{
                            'cursor-not-allowed opacity-50':
                                !optionSelected && !dateSelected
                        }"
                    >
                        Snooze
                    </button>
                </span>
                <span
                    class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto"
                >
                    <button
                        @click="$modal.hide('snooze-item-availability-modal')"
                        type="button"
                        class="focus:outline-none inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium leading-6 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline sm:text-sm sm:leading-5"
                    >
                        Close
                    </button>
                </span>
            </div>
        </div>
    </modal>
</template>

<script>
import DateRangeSelector from '@/components/widgets/DateRangeSelector';

export default {
    name: 'SnoozeItemAvailabilityModal',
    components: { DateRangeSelector },
    data() {
        return {
            isLoading: false,
            options: [
                { label: '1 H', value: 1, active: true },
                { label: '2 H', value: 2, active: true },
                { label: '4 H', value: 4, active: true },
                { label: '6 H', value: 6, active: true },
                { label: '12 H', value: 12, active: true },
                {
                    label: 'Indefinitely',
                    value: 'indefinitely',
                    active: true
                }
            ],
            pickerStyles: 'min-w-max rounded-md transition-color font-medium',
            dateSelected: null,
            optionSelected: null
        };
    },
    watch: {
        dateSelected(value) {
            this.pickerStyles =
                'min-w-max rounded-md transition-color font-medium ' +
                (value
                    ? 'bg-indigo-600 text-white hover:text-white border-indigo-600'
                    : 'hover:border-indigo-600 hover:text-indigo-600');
        }
    },
    methods: {
        // beforeOpen({ params }) {
        //     // console.log(params);
        // },
        selectPicker({ endDate }) {
            this.dateSelected = endDate;
            this.optionSelected = null;
        },
        selectOption(option) {
            this.optionSelected = option;
            this.dateSelected = null;
        },
        snooze() {
            // console.log({
            //     ...(this.dateSelected
            //         ? { until: this.dateSelected }
            //         : { for: this.optionSelected })
            // });
        },
        beforeClose() {
            this.optionSelected = null;
            this.dateSelected = null;
        }
    }
};
</script>

<style>
.snooze-item-availability-modal .vm--modal {
    overflow: visible;
}
</style>
