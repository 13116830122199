export default class RefundReasonsEnum {
    static get ITEM_OUT_OF_STOCK() {
        return 'ITEM_OUT_OF_STOCK';
    }

    static get CUSTOMER_ERROR() {
        return 'CUSTOMER_ERROR';
    }

    static get STAFF_ERROR() {
        return 'STAFF_ERROR';
    }

    static get TECH_ISSUE() {
        return 'TECH_ISSUE';
    }

    static get WAIT_TIME() {
        return 'WAIT_TIME';
    }

    static get OTHER() {
        return 'OTHER';
    }

    static get options() {
        return {
            ITEM_OUT_OF_STOCK: this.ITEM_OUT_OF_STOCK,
            CUSTOMER_ERROR: this.CUSTOMER_ERROR,
            STAFF_ERROR: this.STAFF_ERROR,
            TECH_ISSUE: this.TECH_ISSUE,
            WAIT_TIME: this.WAIT_TIME,
            OTHER: this.OTHER
        };
    }

    static get dictionary() {
        return {
            ITEM_OUT_OF_STOCK: 'Item out of stock',
            CUSTOMER_ERROR: 'Customer error',
            STAFF_ERROR: 'Staff error',
            TECH_ISSUE: 'Tech issue',
            WAIT_TIME: 'Wait time',
            OTHER: 'Other'
        };
    }
}
