<template>
    <h1 class="font-heading text-2xl font-semibold text-gray-900"
        >{{ title }}
    </h1>
</template>

<script>
export default {
    name: 'BasePageTitle.vue',
    props: {
        title: {
            type: String,
            required: true
        }
    }
};
</script>

<style scoped></style>
