<template>
    <div
        class="mx-auto max-w-2xl px-6 sm:px-12"
        :class="{ 'mt-10': $route.name !== 'manageAdyenAccountHolder' }"
    >
        <div class="md:flex md:items-center md:justify-between">
            <div class="min-w-0 flex-1">
                <router-link
                    :to="{
                        name:
                            $route.name === 'manageAdyenAccountHolder'
                                ? 'paymentSettings'
                                : 'dashboard'
                    }"
                    class="mb-2 flex items-center text-sm text-gray-500"
                >
                    <base-back-icon />
                    <span>
                        {{
                            $route.name === 'manageAdyenAccountHolder'
                                ? 'Payment settings'
                                : 'Dashboard'
                        }}
                    </span>
                </router-link>
            </div>

            <BaseSpinner :isLoading="isLoading" />
        </div>

        <div class="mb-5 rounded-md border border-blue-200 bg-blue-50 p-4">
            <div class="flex">
                <div class="flex-shrink-0">
                    <svg
                        class="h-5 w-5 text-blue-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                    >
                        <path
                            fill-rule="evenodd"
                            d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                            clip-rule="evenodd"
                        />
                    </svg>
                </div>
                <div v-if="!adyenAccountHolder.active" class="ml-3">
                    <h3 class="text-sm font-medium text-blue-800">
                        Information required
                    </h3>
                    <div class="mt-2 text-sm text-blue-700">
                        <p>
                            We need to collect some more information about your
                            business before you can start taking payments
                        </p>
                        <span
                            class="mt-2 inline-flex rounded-md shadow-sm"
                            @click="fetchAdyenAccountHolderOnboardingUrl"
                        >
                            <button
                                type="button"
                                class="focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                                :class="{
                                    spinner: onbardingUrlisLoading
                                }"
                                :disabled="onbardingUrlisLoading"
                            >
                                Complete account setup
                            </button>
                        </span>
                    </div>
                </div>
                <div v-else class="ml-3">
                    <h3 class="text-sm font-medium text-blue-800">
                        Manage your account
                    </h3>
                    <div class="mt-2 text-sm text-blue-700">
                        <p>
                            <!-- We need to collect some more information about your
                            business before you can start taking payments -->
                        </p>
                        <span
                            class="mt-2 inline-flex rounded-md shadow-sm"
                            @click="fetchAdyenAccountHolderOnboardingUrl"
                        >
                            <button
                                type="button"
                                class="focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                                :class="{
                                    spinner: onbardingUrlisLoading
                                }"
                                :disabled="onbardingUrlisLoading"
                            >
                                Edit account setup
                            </button>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ManageAdyenPlatformAccountHolder',
    metaInfo: {
        title: 'Storekit Payments Setup'
    },
    data() {
        return {
            isLoading: true,
            onbardingUrlisLoading: false,
            adyenAccountHolder: null
        };
    },
    mounted() {
        this.fetchAdyenAccountHolder();
    },
    methods: {
        async fetchAdyenAccountHolder() {
            try {
                this.isLoading = true;
                const { data } = await this.$axios.get(
                    `/adyen/platform/account-holder/${this.$route.params.accountHolderId}`
                );

                this.adyenAccountHolder = data;
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        },
        async fetchAdyenAccountHolderOnboardingUrl() {
            try {
                this.onbardingUrlisLoading = true;
                const { data } = await this.$axios.get(
                    `/adyen/platform/onboarding-link/?accountHolderId=${this.$route.params.accountHolderId}&redirectUrl=${window.location.href}`
                );

                window.open(data.url);
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.onbardingUrlisLoading = false;
            }
        },

        async copyToClipboard() {
            await navigator.clipboard.writeText(this.$route.params.code);
            this.$notify({
                group: 'settings',
                title: `Copied "${this.$route.params.code}" to clipboard`
            });
        }
    },
    computed: {
        accountName() {
            if (
                this.adyenAccountHolder &&
                this.adyenAccountHolder.accountHolderDetails &&
                this.adyenAccountHolder.accountHolderDetails.businessDetails
            ) {
                return this.adyenAccountHolder.accountHolderDetails
                    .businessDetails.legalBusinessName;
            } else if (
                this.adyenAccountHolder &&
                this.adyenAccountHolder.accountHolderDetails &&
                this.adyenAccountHolder.accountHolderDetails.individualDetails
            ) {
                return (
                    this.adyenAccountHolder.accountHolderDetails
                        .individualDetails.name.firstName +
                    ' ' +
                    this.adyenAccountHolder.accountHolderDetails
                        .individualDetails.name.lastName
                );
            } else return false;
        }
    }
};
</script>
