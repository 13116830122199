<template>
    <div>
        <div
            v-if="!isLoading"
            class="mb-4 overflow-hidden rounded-lg bg-white shadow"
        >
            <div class="grid grid-cols-6 gap-3 px-4 py-5 sm:p-6">
                <div class="group col-span-6 rounded-sm">
                    <div class="">
                        <div class="mb-3">
                            <img
                                src="https://ucarecdn.com/3e6f845d-0f9b-4e99-b1c7-74d8cd817723/-/format/auto/"
                                alt="Enable Klarna payments"
                                class="mb-2 w-14"
                            />
                        </div>
                        <div>
                            <p class="mb-4 text-sm text-gray-800">
                                Give your customers the ability to checkout with
                                Klarna. You always get paid in full and upfront,
                                your customers pay at a later date.
                            </p>
                        </div>

                        <div class="flex flex-row">
                            <div class="w-1/2">
                                <p class="text-sm font-medium text-gray-700">
                                    Klarna rate
                                </p>
                                <span class="block py-2 text-sm text-gray-500">
                                    <div> 3.9% + 20p </div>
                                </span>
                            </div>
                        </div>

                        <div class="mt-3 flex justify-end">
                            <div class="flex items-center justify-between">
                                <span class="flex flex-col">
                                    <span
                                        class="mr-3 text-sm font-medium text-gray-700"
                                        id="availability-label"
                                        >Accept Klarna</span
                                    >
                                </span>
                                <toggle-switch
                                    v-model="active"
                                    @input="toggleKlarna()"
                                    confirm
                                    :confirm-title="
                                        active
                                            ? 'Confirm disabling Klarna payments'
                                            : 'Confirm enabling Klarna payments'
                                    "
                                    :confirm-description="
                                        active
                                            ? 'To confirm disabling Klarna payments, please click the confirm button shown below. You\'ll still be able to process refunds for previous klarna transactions.'
                                            : 'To confirm enabling Klarna payments, please click the confirm button shown below. Klarna will be immediately available as a payment method at checkout.'
                                    "
                                />
                            </div>
                            <button
                                @click="openKlarnaModal()"
                                type="button"
                                class="focus:outline-none ml-4 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                Learn more
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="mb-4 overflow-hidden rounded-lg bg-white p-5 shadow">
            <VclList :width="250" :height="200" />
        </div>
        <klarna-modal />
    </div>
</template>

<script>
import { VclList } from 'vue-content-loading';
import KlarnaModal from '@/components/modals/KlarnaModal';

import ToggleSwitch from '@/components/formComponents/ToggleSwitch';
export default {
    name: 'AdyenPaymentOption',
    components: {
        VclList,
        ToggleSwitch,
        KlarnaModal
    },
    props: {
        venueId: {
            type: Number,
            required: true,
            default: 0
        },
        venue: {
            type: Object,
            required: false,
            default: () => {}
        }
    },
    data() {
        return {
            isLoading: 0,
            account: null,
            accountHolders: [],
            active: null
        };
    },
    created() {
        this.fetchAdyenAccount();
    },
    methods: {
        async fetchAdyenAccount() {
            try {
                this.isLoading++;
                const { data } = await this.$axios.get(`/adyen/account`, {
                    params: {
                        venueId: this.venueId
                    }
                });
                this.account = data;
                this.active = data.klarna;
            } catch (e) {
                throw new Error(e);
            } finally {
                this.isLoading--;
            }
        },
        async toggleKlarna() {
            try {
                const value = this.active;
                this.isLoading++;
                await this.$axios.put(`/adyen/klarna-setting`, {
                    venueId: this.venueId,
                    active: value
                });
                this.active = value;
                this.$notify({
                    group: 'settings',
                    title: `Klarna payments ${value ? 'enabled' : 'disabled'}`
                });
            } catch (e) {
                this.active = !this.active;
                throw new Error(e);
            } finally {
                this.isLoading--;
            }
        },
        openKlarnaModal() {
            this.$modal.show('klarna-modal');
        }
    }
};
</script>
