<template>
    <div>
        <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-1">
                <div class="sm:pl-0 sm:pr-5">
                    <h3 class="text-base font-semibold leading-6 text-gray-800">
                        Order Notifications
                    </h3>
                    <p class="mt-2 text-sm text-gray-500">
                        Delivered when a new order is placed on your website.
                        When the order page is open, a sound will play as orders
                        arrive.
                    </p>
                </div>
            </div>
            <div class="mt-5 md:col-span-2 md:mt-0">
                <div class="shadow sm:overflow-hidden sm:rounded-md">
                    <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
                        <legend class="text-base font-medium text-gray-900"
                            >Sent to you</legend
                        >
                        <div class="flex items-center">
                            <input
                                v-model="editableEmailNotifications"
                                id="acceptsEmailNotifications"
                                :value="true"
                                name="form-input push_notifications"
                                type="radio"
                                class="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                            />
                            <label for="acceptsEmailNotifications" class="ml-3">
                                <span
                                    class="block text-sm font-medium leading-5 text-gray-700"
                                >
                                    Email &amp; browser notifications
                                </span>
                            </label>
                        </div>
                        <div class="mt-4 flex items-center">
                            <input
                                v-model="editableEmailNotifications"
                                id="NoEmailNotifications"
                                :value="false"
                                name="form-input push_notifications"
                                type="radio"
                                class="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                            />
                            <label for="NoEmailNotifications" class="ml-3">
                                <span
                                    class="block text-sm font-medium leading-5 text-gray-700"
                                >
                                    Browser only
                                </span>
                            </label>
                        </div>
                    </div>
                    <div
                        v-if="!isOrderAndPay"
                        class="space-y-6 bg-white px-4 py-5 sm:p-6"
                    >
                        <legend class="text-base font-medium text-gray-900"
                            >Sent to customers</legend
                        >
                        <fieldset>
                            <base-checkbox
                                v-model="editableCustomerSmsNotification"
                                :disabled="!hasPayments"
                                id="customerSmsNotification"
                                label="Text (SMS) notifications"
                                :tooltip="
                                    !hasPayments
                                        ? 'You\'ll need to activate payment processing before you can send text messages to customers.'
                                        : ''
                                "
                            />
                        </fieldset>
                      <div>
                        <base-input-field
                            id="name"
                            v-model="editableSmsName"
                            :disabled="!hasPayments"
                            label="SMS Sender Name"
                            :maxLength="11"
                            errorMessage="Please enter a valid name" />
                      </div>
                    </div>
                    <div
                        v-if="isOrderAndPay"
                        class="space-y-6 bg-white px-4 py-5 sm:p-6"
                    >
                        <legend class="text-base font-medium text-gray-900"
                            >Sent to the POS / Printers</legend
                        >
                        <fieldset>
                            <base-checkbox
                                v-model="editablePaymentFailedNotification"
                                id="paymentFailedNotification"
                                label="Print a message when a customer's payment fails"
                            />
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BaseInputField from "@/components/base/form/BaseInputField.vue";

export default {
  components: {BaseInputField},
    props: {
        emailNotifications: {
            type: Boolean,
            required: true
        },
        customerSmsNotification: {
            type: Boolean,
            required: true
        },
        isOrderAndPay: {
            type: Boolean,
            required: true
        },
        hasPayments: {
            type: Boolean,
            required: true
        },
        paymentFailedNotification: {
            type: Boolean,
            required: true
        },
        smsName: {
            type: String,
            required: false
        }
    },
    computed: {
        editableEmailNotifications: {
            get() {
                return this.emailNotifications;
            },
            set(value) {
                this.$emit(
                    'change-operation-property',
                    'emailNotifications',
                    value
                );
            }
        },
        editableCustomerSmsNotification: {
            get() {
                return this.customerSmsNotification;
            },
            set(value) {
                this.$emit(
                    'change-operation-property',
                    'customerSmsNotification',
                    value
                );
            }
        },
        editablePaymentFailedNotification: {
            get() {
                return this.paymentFailedNotification;
            },
            set(value) {
                this.$emit(
                    'change-operation-property',
                    'paymentFailedNotification',
                    value
                );
            }
        },
        editableSmsName: {
            get() {
                return this.smsName;
            },
            set(value) {
                this.$emit(
                    'change-operation-property',
                    'smsName',
                    value
                );
            }
        }
    }
};
</script>
