<template>
    <ul
        class="border border-gray-200 rounded-md divide-y divide-gray-200 overflow-hidden"
    >
        <li
            v-for="account in filteredAccounts"
            :key="account.accountCode"
            class=""
        >
            <div
                class="pl-3 pr-4 py-3 flex items-center justify-between text-sm bg-gray-50"
            >
                <div class="w-0 flex-1 flex flex-col text-gray-500">
                    <p class="ml-2 truncate">
                        Store: {{ venueById(account.description) }}
                    </p>
                    <p class="ml-2 truncate">
                        Account code: {{ account.accountCode }}
                    </p>
                </div>
            </div>
        </li>
        <li v-if="accounts && !filteredAccounts.length">
            <div class="flex flex-col text-gray-500 p-4 text-center">
                <p class=""> No stores connected </p>
            </div>
        </li>
    </ul>
</template>

<script>
export default {
    name: 'AdyenAccountHolderStores',
    props: {
        accounts: {
            type: Array,
            required: false
        }
    },
    data() {
        return {
            venues: []
        };
    },
    mounted() {
        this.fetchVenues();
    },
    methods: {
        async fetchVenues() {
            this.isLoading = true;
            try {
                const { data } = await this.$axios.get('/venues');

                this.venues = data;
                this.isLoading = false;
            } catch (e) {
                this.isLoading = false;
                throw new Error(e);
            }
        },
        venueById(id) {
            const venue = this.venues.find(venue => venue.id === parseInt(id));
            if (venue) {
                return venue.name;
            } else return '';
        }
    },
    computed: {
        filteredAccounts() {
            return this.accounts.filter(
                account => account.description !== 'TransactionAccount'
            );
        }
    }
};
</script>
