<template>
    <div>
        <div class="md:flex md:items-center md:justify-between">
            <div class="min-w-0 flex-1">
                <router-link
                    :to="{ name: 'editVenue' }"
                    class="mb-2 flex items-center text-sm text-gray-500 inline-flex"
                >
                    <base-back-icon />
                    <span>Settings</span>
                </router-link>
                <base-page-title title="Store Notices" />
            </div>
            <div
                class="mr-4 mt-4 flex md:ml-4 md:mt-0"
                v-if="!isLoading && notices && notices.length"
            >
                <div class="md:ml-3">
                    <base-button @clicked="addVenueNotice"
                                 buttonText="Add store notice"
                                 :disabled="isLoading"
                                 button-style="action"
                                 size="sm"
                    />
                </div>
            </div>
            <BaseSpinner :isLoading="isLoading" />
        </div>
        <div>
            <div class="mt-6 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                            >Store Notices</h3
                        >
                        <p class="mt-2 text-sm text-gray-500">
                            Create messages visible to customers on your store.
                            For example, add a notice on your menu with a link
                            to your allergen web page, or a helpful notice on
                            the basket page about delivery.
                        </p>
                        <p class="mt-3 text-sm text-gray-500">
                            Tip: We'll automatically make links clickable in
                            store notices.
                        </p>
                    </div>
                </div>
                <div
                    class="mt-5 md:col-span-2 md:mt-0"
                    :class="{ 'h-full': !notices.length }"
                >
                    <draggable v-model="notices" handle=".handle">
                        <div
                            class="handle col-span-6 mb-8 overflow-hidden rounded-lg bg-white shadow sm:overflow-hidden sm:rounded-md"
                            v-for="(notice, index) in notices"
                            :key="notice.id"
                        >
                            <div class="px-4 py-5 sm:p-6">
                                <div
                                    class="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4"
                                >
                                    <label
                                        :for="'title-' + notice.id"
                                        class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                        Title
                                        <br />
                                        <span class="text-sm text-gray-400"
                                            >(Optional)</span
                                        >
                                    </label>
                                    <div class="mt-1 sm:col-span-2 sm:mt-0">
                                        <div
                                            class="flex max-w-lg rounded-md shadow-sm"
                                        >
                                            <input
                                                :id="'title-' + notice.id"
                                                v-model.trim="notice.title"
                                                :class="{
                                                    'border-red-300':
                                                        $v.notices.$each[index]
                                                            .title.$error
                                                }"
                                                class="form-input block w-full flex-1 rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                            />
                                        </div>
                                        <span
                                            class="text-xs text-red-500"
                                            v-if="
                                                $v.notices.$each[index].body
                                                    .$error &&
                                                !$v.notices.$each[index].title
                                                    .requiredIf
                                            "
                                        >
                                            Notice title or description is
                                            required.
                                        </span>
                                    </div>
                                </div>

                                <div
                                    class="mt-3 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4"
                                >
                                    <label
                                        :for="'body-' + notice.id"
                                        class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                        Text
                                        <br />
                                        <span class="text-sm text-gray-400"
                                            >(Optional)</span
                                        >
                                    </label>
                                    <div class="mt-1 sm:col-span-2 sm:mt-0">
                                        <base-text-area
                                            id="'body-' + notice.id"
                                            v-model.trim="notice.body"
                                            :maxLength="300"
                                            :errors="$v.notices.$each[index]
                                                        .body.$error"
                                            :rows="3"
                                        />
                                        <div class="flex justify-end">
                                            <span
                                                class="my-2 flex flex-auto justify-start text-xs text-red-500"
                                                v-show="
                                                    $v.notices.$each[index].body
                                                        .$error &&
                                                    !$v.notices.$each[index]
                                                        .body.requiredIf
                                                "
                                            >
                                                Notice title or description is
                                                required.
                                            </span>
                                            <span
                                                class="my-2 flex flex-auto justify-start text-xs text-red-500"
                                                v-show="
                                                    $v.notices.$each[index].body
                                                        .$error &&
                                                    !$v.notices.$each[index]
                                                        .body.maxLength
                                                "
                                            >
                                                Description is too long.
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    class="mt-3 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-2"
                                >
                                    <label
                                        :for="'type-' + notice.id"
                                        class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                        Notice type
                                    </label>
                                    <div class="mt-1 sm:col-span-2 sm:mt-0">
                                        <div
                                            class="rounded-md shadow-sm"
                                        >
                                            <select
                                                :id="'type-' + notice.id"
                                                v-model="notice.type"
                                                class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                            >
                                                <option value="3">
                                                    Default (No styling)
                                                </option>
                                                <option value="0"
                                                    >Info (Blue)</option
                                                >
                                                <option value="1"
                                                    >Success (Green)</option
                                                >
                                                <option value="2"
                                                    >Warning (Yellow)</option
                                                >
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div role="group" aria-labelledby="label-email">
                                    <div
                                        class="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:items-baseline sm:gap-4"
                                    >
                                        <div>
                                            <div
                                                class="text-base font-medium leading-6 text-gray-900 sm:text-sm sm:leading-5 sm:text-gray-700"
                                                id="label-email"
                                            >
                                                Show on:
                                            </div>
                                        </div>
                                        <div class="mt-4 sm:col-span-2 sm:mt-0">
                                            <div
                                                class="flex max-w-lg flex-wrap"
                                            >
                                                <div class="mt-4">
                                                    <base-checkbox
                                                        :id="
                                                            'showInMenu-' +
                                                            notice.id
                                                        "
                                                        v-model="
                                                            notice.showInMenu
                                                        "
                                                        label="Menu"
                                                    />
                                                </div>
                                                <div class="ml-4 mt-4">
                                                    <base-checkbox
                                                        :id="
                                                            'showInBasket-' +
                                                            notice.id
                                                        "
                                                        v-model="
                                                            notice.showInBasket
                                                        "
                                                        label="Basket"
                                                    />
                                                </div>
                                                <div class="ml-4 mt-4">
                                                    <base-checkbox
                                                        :id="
                                                            'OrderStatus-' +
                                                            notice.id
                                                        "
                                                        v-model="
                                                            notice.showInOrderStatus
                                                        "
                                                        label="Order Status"
                                                    />
                                                </div>
                                                <div class="mt-4">
                                                    <base-checkbox
                                                        :id="
                                                            'ModalBeforeCheckout-' +
                                                            notice.id
                                                        "
                                                        v-model="
                                                            notice.showInModalBeforeCheckout
                                                        "
                                                        label="Checkout Popup"
                                                        tooltip="Show this notice in a popup before customers can click the checkout button. They'll be prompted to accept the notice before they can proceed. Useful for allergen notices"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="flex justify-end border-t border-gray-200 bg-gray-100 px-4 py-2 sm:px-6"
                            >
                                <div>
                                    <span
                                        class="flex w-full rounded-md hover:shadow-sm sm:ml-3 sm:w-auto"
                                        @click="deleteNotice(notice)"
                                    >
                                        <button
                                            type="button"
                                            class="leading-2 focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-gray-100 px-4 py-2 text-sm font-medium text-gray-500 transition duration-150 ease-in-out hover:bg-red-500 hover:text-white hover:shadow-sm focus:border-red-700 focus:shadow-outline-red sm:text-sm sm:leading-5"
                                        >
                                            Delete
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </draggable>
                    <div
                        v-if="!isLoading && !notices.length"
                        class="col-span-6 mb-2 flex h-full flex-col items-center justify-center overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:overflow-hidden sm:rounded-md sm:p-5"
                    >
                        <div>
                            <p class="mb-4 font-semibold text-gray-700"
                                >No store notices yet</p
                            >
                        </div>
                        <div class="mr-4 mt-4 flex md:ml-4 md:mt-0">
                              <base-button @clicked="addVenueNotice"
                                           buttonText="Add store notice"
                                           button-style="action"
                                           :disabled="isLoading"
                                           size="sm"
                              />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <BaseSpacerWithRuler />

        <div
            class="my-6 ml-4 flex flex-shrink-0 justify-end"
            v-if="notices && notices.length"
        >
            <span class="inline-flex rounded-md shadow-sm" @click="saveNotices">
                <button
                    type="button"
                    class="focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                    :disabled="isLoading"
                >
                    Save
                </button>
            </span>
        </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable';
import { maxLength, requiredIf } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import { FeaturesEnum } from '@/enums';

export default {
    name: 'StoreNoticesSettings',
    components: { draggable },
    props: ['venueId'],
    metaInfo: {
        title: 'Store Notices'
    },
    data() {
        return {
            notices: [],
            isLoading: true,
            FeaturesEnum
        };
    },
    validations: {
        notices: {
            $each: {
                title: {
                    requiredIf: requiredIf(obj => !obj.body)
                },
                body: {
                    requiredIf: requiredIf(obj => !obj.title),
                    maxLength: maxLength(300)
                }
            }
        }
    },
    created() {
        this.fetchNotices();
    },
    methods: {
        async fetchNotices() {
            this.isLoading = true;

            try {
                const { data } = await this.$axios.get(
                    `/venues/${this.venueId}/notices`
                );

                this.notices = data.notices;
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        },
        updateNoticeOrder() {
            const indexedNotices = [];
            this.notices.forEach((notice, i) => {
                indexedNotices[i] = { ...notice, index: i };
            });

            this.notices = indexedNotices;
        },
        addVenueNotice() {
            this.notices.push({
                enabled: true,
                body: '',
                showInMenu: true,
                showInBasket: false,
                showInOrderStatus: false,
                showInOrderEmail: false,
                showInModalBeforeCheckout: false,
                index: 0,
                type: 3,
                title: ''
            });
        },
        async saveNotices() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return false;
            }

            this.isLoading = true;

            this.updateNoticeOrder();

            try {
                const notices = this.notices.map(
                    // eslint-disable-next-line no-unused-vars
                    ({ created_at, updated_at, accountId, ...notice }) => notice
                );

                const { data } = await this.$axios.put(
                    `/venues/${this.venueId}/notices/`,
                    {
                        venueNotices: notices
                    }
                );

                this.notices = data;
                this.$notify({
                    group: 'settings',
                    title: 'Store notices updated'
                });
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        },

        async deleteNotice(notice) {
            this.notices = this.notices.filter(val => val !== notice);

            if (!notice.id) {
                return;
            }

            this.isLoading = true;

            try {
                await this.$axios.delete(
                    `/venues/${this.venueId}/notices/${notice.id}`
                );

                await this.fetchNotices();
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        }
    },
    computed: {
        ...mapGetters({ isFeatureAvailable: 'user/isFeatureAvailable' })
    }
};
</script>
