<template>
    <modal
        name="disconnect-paypal-modal"
        :adaptive="true"
        height="auto"
        width="600px"
        @before-open="beforeOpen"
    >
        <div
            class="pb-4 pt-5 px-4 w-full bg-white rounded-lg shadow-xl overflow-hidden transform transition-all sm:p-6"
        >
            <div class="sm:flex sm:items-start">
                <div
                    class="flex flex-shrink-0 items-center justify-center mx-auto w-12 h-12 bg-red-100 rounded-full sm:mx-0 sm:w-10 sm:h-10"
                >
                    <svg
                        class="w-6 h-6 text-red-600"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                        ></path>
                    </svg>
                </div>
                <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <h3 class="text-gray-900 text-lg font-medium leading-6">
                        Deactivate PayPal
                    </h3>
                    <div class="mt-2">
                        <p class="text-gray-500 text-sm leading-5">
                            Disconnecting your PayPal account will prevent you
                            from offering PayPal payments on your store. Do you
                            want to continue?
                        </p>
                    </div>
                </div>
            </div>
            <div class="justify-end mt-5 sm:flex sm:mt-4 sm:pl-4">
                <span class="flex w-full rounded-md shadow-sm sm:w-auto">
                    <button
                        @click="deactivePaypal()"
                        type="button"
                        class="inline-flex justify-center px-4 py-2 w-full text-white text-base font-medium leading-6 hover:bg-red-500 bg-red-600 border focus:border-red-700 border-transparent rounded-md focus:outline-none focus:shadow-outline-red shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    >
                        Deactivate PayPal
                    </button>
                </span>
                <span
                    class="flex mt-3 w-full rounded-md shadow-sm sm:ml-3 sm:mt-0 sm:w-auto"
                >
                    <button
                        @click="hideModal()"
                        type="button"
                        class="inline-flex justify-center px-4 py-2 w-full hover:text-gray-500 text-gray-700 text-base font-medium leading-6 bg-white border focus:border-blue-300 border-gray-300 rounded-md focus:outline-none focus:shadow-outline shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    >
                        Cancel
                    </button>
                </span>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    name: 'disconnect-paypal-modal',

    data() {
        return { venueId: 0 };
    },
    methods: {
        beforeOpen(event) {
            const { venueId } = event.params;
            this.venueId = venueId;
        },
        hideModal() {
            this.$modal.hide('disconnect-paypal-modal');
        },
        async deactivePaypal() {
            try {
                await this.$axios.put('/paypal/toggle-active', {
                    active: false,
                    venueId: this.venueId
                });

                this.hideModal();

                this.$router.push({ name: 'editVenue' });
            } catch (error) {
                throw new Error(`API ${error}`);
            }
        }
    }
};
</script>
