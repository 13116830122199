<template>
    <date-range-picker
        ref="orderRangeSelector"
        class="relative sm:mr-3"
        :opens="'left'"
        :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY HH:mm' }"
        :maxDate="null"
        :min-date="null"
        :singleDatePicker="false"
        :showWeekNumbers="true"
        :showDropdowns="true"
        :autoApply="false"
        :timePicker="true"
        :time-picker-increment="1"
        :append-to-body="true"
        :ranges="ranges"
        v-model="dateRange"
        :linkedCalendars="false"
        @update="updateValues"
    >
        <template #input="picker">
            <div
                class="focus:outline-none inline-flex w-full cursor-pointer items-center rounded-md rounded-sm border border-gray-300 bg-white px-3 py-2 text-sm leading-5 transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
                :class="{
                    'border-gray-700': picker.startDate && picker.endDate
                }"
            >
                <svg
                    class="-ml-1 mr-3 h-5 w-5"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                    ></path>
                </svg>
                <span>
                    <template v-if="picker.startDate && picker.endDate">
                        {{ picker.startDate | date }}
                        - {{ picker.endDate | date }}
                    </template>
                    <template v-else>Select date range</template>
                </span>
            </div>
        </template>
        <template #footer="data">
            <div
                class="flex justify-between border-t border-gray-300 bg-gray-50 py-2 px-2"
            >
                <button
                    @click="clearDateRange"
                    class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-2 py-1 text-xs font-medium leading-5 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
                >
                    Clear date range selection
                </button>
                <button
                    @click="data.clickApply"
                    :disabled="data.in_selection"
                    class="focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-2 py-1 text-xs font-medium leading-5 text-white hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                    :class="{
                        'cursor-not-allowed opacity-50 ': data.in_selection
                    }"
                >
                    Apply selection
                </button>
            </div>
        </template>
    </date-range-picker>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import { mapGetters } from 'vuex';
import Vue from 'vue';

const CustomDateRangePicker = Vue.extend(DateRangePicker).extend({
    methods: {
        normalizeDatetime(value, oldValue) {
            let newDate = new Date(value);

            if (this.timePicker && oldValue) {
                newDate.setHours(oldValue.getHours());
                newDate.setMinutes(oldValue.getMinutes());
                newDate.setSeconds(oldValue.getSeconds());
                newDate.setMilliseconds(oldValue.getMilliseconds());
            } else {
                newDate.setHours(0);
                newDate.setMinutes(0);
                newDate.setSeconds(0);
                newDate.setMilliseconds(0);
            }

            return newDate;
        },
        normalizeEndDatetime(value, oldValue) {
            let newDate = new Date(value);

            if (this.timePicker && oldValue) {
                newDate.setHours(oldValue.getHours());
                newDate.setMinutes(oldValue.getMinutes());
                newDate.setSeconds(oldValue.getSeconds());
                newDate.setMilliseconds(oldValue.getMilliseconds());
            } else {
                newDate.setHours(23);
                newDate.setMinutes(59);
                newDate.setSeconds(0);
                newDate.setMilliseconds(0);
            }

            return newDate;
        },
        dateClick: function dateClick(value) {
            if (this.readonly) return false;

            if (this.in_selection) {
                this.in_selection = false;

                this.$emit('finish-selection', value);
                this.onSelect();
                if (this.autoApply) this.clickedApply();
            } else {
                this.start = this.normalizeDatetime(value, this.start);
                this.end = this.normalizeEndDatetime(value, this.end);

                if (
                    !this.singleDatePicker ||
                    this.singleDatePicker === 'range'
                ) {
                    this.in_selection = this.end;

                    this.$emit('start-selection', this.start);
                } else {
                    this.onSelect();
                    if (this.autoApply) this.clickedApply();
                }
            }
        }
    }
});

export default {
    name: 'OrderRangeSelector',
    props: {
        preSelected: {
            type: Object,
            required: false
        }
    },
    components: {
        DateRangePicker: CustomDateRangePicker
    },
    data() {
        return {
            isLoading: true,
            dateRange: {
                startDate: null,
                endDate: null
            }
        };
    },
    filters: {
        date(value) {
            if (!value) return '';

            return Intl.DateTimeFormat('en-GB', {
                year: '2-digit',
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric'
            }).format(value);
        }
    },
    async created() {
        this.dateRange = this.preSelected;
    },
    methods: {
        clearDateRange() {
            this.$refs.orderRangeSelector.clickRange({
                startDate: null,
                endDate: null
            });
        },
        updateValues({ startDate, endDate }) {
            this.$emit('date-updated', {
                startDate: startDate ? startDate.toISOString() : null,
                endDate: endDate ? endDate.toISOString() : null
            });
        }
    },
    computed: {
        ...mapGetters({
            currentUser: 'user/currentUser'
        }),
        todaysDate() {
            return new Date();
        },
        ranges() {
            const todayStart = new Date();
            todayStart.setHours(0, 0, 0, 0);

            const todayEnd = new Date();
            todayEnd.setHours(23, 59, 0, 0);

            const yesterdayStart = new Date();
            yesterdayStart.setDate(todayStart.getDate() - 1);
            yesterdayStart.setHours(0, 0, 0, 0);

            const yesterdayEnd = new Date();
            yesterdayEnd.setDate(todayStart.getDate() - 1);
            yesterdayEnd.setHours(23, 59, 0, 0);

            const thisMonthStart = new Date(
                todayStart.getFullYear(),
                todayStart.getMonth(),
                1
            );
            const thisMonthEnd = new Date(
                todayStart.getFullYear(),
                todayStart.getMonth() + 1,
                0,
                23,
                59
            );

            return {
                Today: [todayStart, todayEnd],
                Yesterday: [yesterdayStart, yesterdayEnd],
                'This month': [thisMonthStart, thisMonthEnd],
                'Last month': [
                    new Date(
                        todayStart.getFullYear(),
                        todayStart.getMonth() - 1,
                        1
                    ),
                    new Date(
                        todayStart.getFullYear(),
                        todayStart.getMonth(),
                        0,
                        23,
                        59
                    )
                ],
                'This year': [
                    new Date(todayStart.getFullYear(), 0, 1),
                    new Date(todayStart.getFullYear(), 11, 31, 23, 59)
                ],
                'All time': [new Date(2020, 3, 1, 0, 0), todayEnd]
            };
        }
    }
};
</script>

<style>
.reportrange-text {
    background: transparent !important;
    cursor: pointer !important;
    padding: 0 !important;
    border: none !important;
}
.calendars.row.no-gutters {
    display: flex;
}

.daterangepicker.dropdown-menu {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px -2px rgba(0, 0, 0, 0.05);
    top: 50px;
}
</style>
