<template>
    <div class="flex w-0 flex-1 flex-col overflow-hidden">
        <main class="focus:outline-none relative z-0 h-full flex-1">
            <div
                class="mx-auto flex max-w-7xl flex-row items-center justify-between px-4 sm:px-6 lg:px-8"
            >
                <div
                    class="h-4 w-4"
                    :class="{ 'text-gray-900 spinner': isLoading }"
                >
                    <span></span>
                </div>
            </div>
            <div
                class="mx-auto flex h-full max-w-7xl flex-col justify-center px-4 sm:px-6 md:px-8"
            >
                <base-page-title title="Import from a spreadsheet" />

                <div class="flex flex-col md:flex-row">
                    <div
                        class="mr-3 mt-6 flex w-full flex-row bg-white shadow sm:rounded-lg md:w-1/2"
                    >
                        <div class="w-full px-4 py-5 sm:p-6">
                            <div>
                                <p class="text-sm text-gray-600"
                                    >You'll need to prepare a CSV or Excel file
                                    to upload your menu, we've created some
                                    example files to help you get started.
                                    <br /><br />If you have any questions, use
                                    the live chat button to get help!</p
                                >

                                <div class="mt-3 flex items-center">
                                    <a
                                        href="https://ucarecdn.com/2752891c-b9c2-40d2-9c87-e77c575d9324/ExampleMenuImportFile.xlsx"
                                        target="_blank"
                                        class="flex cursor-pointer items-center rounded-md p-2 text-sm font-medium text-gray-600 hover:bg-gray-50"
                                    >
                                        <base-excel-icon class="mr-2" />
                                        Download Excel file
                                    </a>
                                    <a
                                        target="_blank"
                                        href="https://ucarecdn.com/d1f0e2df-1745-48e1-af43-a9fc96fa4188/ExampleMenuImportFile.csv"
                                        class="ml-4 flex cursor-pointer items-center rounded-md p-2 text-sm font-medium text-gray-600 hover:bg-gray-50"
                                    >
                                        <base-csv-file-icon class="mr-2" />
                                        Download CSV file
                                    </a>
                                </div>

                                <hr class="mt-3" />

                                <flat-file-launcher
                                    class="mt-4"
                                    managed
                                    title="Menu import"
                                    :fields="menuImportFields"
                                    :disableManualInput="true"
                                    type="Menu"
                                    :status="csvImportStatus"
                                    @csvImport="submitCsvMenu"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div
                        class="mt-10 flex flex-col pl-2 sm:flex-row sm:flex-wrap"
                    >
                        <button
                            @click="goBack"
                            type="button"
                            class="focus:outline-none group -ml-2 mt-2 flex cursor-pointer items-center rounded-md rounded-sm p-1.5 text-sm leading-5 text-gray-600 hover:bg-gray-200 hover:text-gray-700 sm:mr-6"
                        >
                            <base-back-icon />
                            Go back
                        </button>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, url } from 'vuelidate/lib/validators';
import FlatFileLauncher from '@/components/csv/FlatFileLauncher';

export default {
    name: 'CreateMenuImport',
    metaInfo: {
        title: 'Import Menu'
    },
    validations: {
        urlImport: {
            url: {
                required,
                url
            }
        }
    },
    components: {
        FlatFileLauncher
    },
    data() {
        return {
            isLoading: false,
            menuId: null,
            csvImportStatus: 'pending',
            menuImportFields: [
                {
                    label: 'Category Name',
                    key: 'categoryName',
                    validators: [
                        {
                            validate: 'required'
                        }
                    ],
                    description:
                        "Group your products into categories. We'll match categories by their name so keep them consistent."
                },
                {
                    label: 'Product Name',
                    key: 'itemName',
                    validators: [
                        {
                            validate: 'required'
                        }
                    ]
                },
                {
                    label: 'Product description',
                    key: 'itemDescription'
                },
                {
                    label: 'Product Price',
                    key: 'itemPrice',
                    validators: [
                        {
                            validate: 'regex_matches',
                            regex: '^(?=.)([+-]?([0-9]*)(.([0-9]+))?)$',
                            error: 'Must be a number.'
                        },
                        {
                            validate: 'required'
                        }
                    ],
                    description: 'Product base price.'
                },
                {
                    label: 'Product Inventory Count',
                    key: 'itemInventory',
                    validators: [
                        {
                            validate: 'regex_matches',
                            regex: '^\\d+$',
                            error: 'Must be a number.'
                        }
                    ],
                    description:
                        'Optionally set an inventory quantity for each product.'
                },
                {
                    label: 'Product sorting',
                    key: 'itemIndex',
                    validators: [
                        {
                            validate: 'regex_matches',
                            regex: '^\\d+$',
                            error: 'Must be a number.'
                        }
                    ],
                    description:
                        'Specify the order in which products should appear on your menu. Starting from 0 at the top.'
                },
                {
                    label: 'Product PLU / SKU',
                    key: 'itemPlu'
                },
                {
                    label: 'Is Popular?',
                    key: 'popular',
                    type: 'checkbox',
                    description:
                        'Tag items as popular to highlight them on your menu'
                },
                {
                    label: 'Product Image URL',
                    key: 'itemImageUrl'
                },
                {
                    label: 'Product POS ID',
                    key: 'itemPosId',
                    description:
                        'Optionally set a POS ID for each product, only needed for direct integrations.'
                }
            ]
        };
    },
    computed: {
        ...mapGetters({ user: 'user/currentUser' })
    },
    methods: {
        goBack() {
            this.$router.push({ name: 'createMenu' });
        },
        async submitCsvMenu(menus) {
            this.isLoading = true;
            this.csvImportStatus = 'pending';
            const venueId = this.$route.params.venueId;

            try {
                if (!this.menuId) {
                    const { data } = await this.$axios.post('/menus', {
                        venueId,
                        name: 'CSV Import Menu',
                        version: 0
                    });

                    if (!data || !data.id) {
                        return;
                    }

                    this.menuId = data.id;
                }

                await this.$axios.post(
                    `/menus/${this.menuId}/import-csv`,
                    menus
                );

                this.csvImportStatus = 'success';
                await this.$router.push({
                    name: 'menu',
                    params: {
                        menuId: this.menuId
                    }
                });
            } catch (e) {
                this.csvImportStatus = 'failed';

                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        }
    }
};
</script>

<style scoped></style>
