class EditVenueService {
    constructor() {
        this.START_OF_THE_DAY = '00:00';
        this.END_OF_THE_DAY = '23:59';
        this.DEFAULT_START_OF_THE_DAY = '09:00';
        this.DEFAULT_END_OF_THE_DAY = '17:00';
        this.SLOTS_TYPE = 'slots';
        this.HOURS_TYPE = 'hours';
    }

    addTimeInterval(array, day, type) {
        if (type === this.HOURS_TYPE) {
            array.push({
                day,
                openTime: '',
                closeTime: '',
                active: true
            });
        }

        if (type === this.SLOTS_TYPE) {
            array.push({
                day,
                openTime: '',
                closeTime: '',
                quantity: null,
                maxFutureDays: 7,
                prepTimeInDays: 0,
                slotInterval: null,
                active: true
            });
        }

        return array;
    }
    toggleAroundTheClock(array, day, type, closedArray) {
        const selectedDay = array.find(hours => hours.day === day);

        array = array.filter(hours => {
            return hours.day !== day;
        });

        if (type === this.HOURS_TYPE) {
            array.push({
                day,
                openTime:
                    selectedDay.openTime === this.START_OF_THE_DAY &&
                    selectedDay.closeTime === this.END_OF_THE_DAY
                        ? this.DEFAULT_START_OF_THE_DAY
                        : this.START_OF_THE_DAY,
                closeTime:
                    selectedDay.closeTime === this.END_OF_THE_DAY
                        ? this.DEFAULT_END_OF_THE_DAY
                        : this.END_OF_THE_DAY,
                active: true
            });
        }

        if (type === this.SLOTS_TYPE) {
            array.push({
                day,
                openTime:
                    selectedDay?.openTime === this.START_OF_THE_DAY &&
                    selectedDay?.closeTime === this.END_OF_THE_DAY
                        ? this.DEFAULT_START_OF_THE_DAY
                        : this.START_OF_THE_DAY,
                closeTime:
                    selectedDay?.closeTime === this.END_OF_THE_DAY
                        ? this.DEFAULT_END_OF_THE_DAY
                        : this.END_OF_THE_DAY,

                quantity: null,
                monetaryLimit: null,
                maxFutureDays: 7,
                prepTimeInDays: 0,
                slotInterval: null,
                active: true
            });
        }

        const isDayClosed = closedArray.find(
            (item, closedArrayIndex) => closedArrayIndex === day
        );

        if (isDayClosed) {
            closedArray.splice(day, 1, !isDayClosed);
        }

        return { resultArray: array, resultClosedArray: closedArray };
    }
    toggleCloseDay(array, day, closedArray) {
        closedArray[day] = !closedArray[day];

        array.forEach((hours, index) => {
            if (hours.day === day) {
                array[index].active = !array[index].active;
            }
        });

        return { resultArray: array, resultClosedArray: closedArray };
    }
    checkForDaysClosed(array, closedArray) {
        for (const slot of array) {
            closedArray[slot.day] = !slot.active;
        }
        return closedArray;
    }
    deleteTimeInterval(index, day, array) {
        if (this.oneDayIntervalsNumber(day, array) > 1) {
            array.splice(index, 1);
        }

        return array;
    }
    oneDayIntervalsNumber(day, array) {
        return array.filter(hours => hours.day === day).length;
    }
    isAroundTheClock(array, index) {
        const item = array.find(item => item.day === index);

        if (!item) {
            return false;
        }

        return (
            item.openTime === this.START_OF_THE_DAY &&
            item.closeTime === this.END_OF_THE_DAY
        );
    }
}

export default EditVenueService;
