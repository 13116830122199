var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4"},[_c('div',{staticClass:"relative",class:{ 'opacity-75': !_vm.hasOnboarded }},[(!_vm.isLoading)?_c('div',{staticClass:"mt-2 border border-gray-100 bg-white shadow sm:rounded-md"},[_c('div',{staticClass:"rounded-t-md border-b border-gray-100 overflow-hidden"},[_c('div',{staticClass:"relative"},[_c('ul',{staticClass:"flex flex-row items-center w-full px-2 py-2.5 mb-2 sm:mb-0 overflow-scroll"},_vm._l((_vm.periods),function(periodOption){return _c('li',{key:periodOption.value,staticClass:"mr-1 cursor-pointer rounded-md px-3 py-1 text-xs transition duration-150 ease-in-out hover:bg-gray-50",class:{
                                    'bg-gray-100':
                                        periodOption.value == _vm.period
                                },on:{"click":function($event){_vm.period = periodOption.value;
                              _vm.fetchOrderCount();}}},[_vm._v(" "+_vm._s(periodOption.label)+" ")])}),0)])]),_c('div',{staticClass:"grid grid-cols-1 md:grid-cols-3"},[_c('div',[_c('div',{staticClass:"px-4 py-3"},[_c('dl',[_c('dt',{staticClass:"flex items-center text-xs justify-between text-gray-700"},[_vm._v(" Total orders "),_c('div',{staticClass:"ml-3 inline-flex items-center rounded-md ring-1 ring-inset bg-yellow-50 ring-yellow-300 px-2 py-0.5 text-xs font-medium leading-5 leading-none text-yellow-700 md:mt-2 lg:mt-0",class:{
                                        ' bg-green-50 text-green-700 ring-green-300':
                                            _vm.orderCountIncrease > 0 ||
                                            isNaN(_vm.orderCountIncrease)
                                    }},[(_vm.orderCountIncrease >= 0 ||
                                            !isFinite(_vm.orderCountIncrease))?_c('base-trending-up-icon',{staticClass:"h-4 h-4 -ml-1 mr-0.5 flex-shrink-0 self-center"}):_c('base-trending-down-icon',{staticClass:"h-4 h-4 -ml-1 mr-0.5 flex-shrink-0 self-center"}),_c('span',{staticClass:"sr-only"},[_vm._v(" Increased by ")]),_c('span',[_c('animated-number',{staticClass:"font-heading",attrs:{"value":_vm.orderCountIncrease,"duration":800}}),_vm._v("%")],1)],1)]),_c('dd',{staticClass:"mt-1 flex items-baseline justify-between md:block lg:flex"},[_c('div',{staticClass:"flex flex-col items-baseline text-2xl font-semibold leading-8 text-black sm:text-xl"},[_c('span',[_c('animated-number',{staticClass:"font-heading",attrs:{"value":_vm.orderCount,"duration":1400}})],1),_c('span',{staticClass:"text-sm font-medium leading-5 text-gray-500"},[_vm._v(" from "),_c('span',[_c('animated-number',{staticClass:"font-heading",attrs:{"value":_vm.lastMonthOrderCount,"duration":800}})],1)])])])])])]),_c('div',{staticClass:"border-t border-gray-100 md:border-0 md:border-l"},[_c('div',{staticClass:"px-4 py-3"},[_c('dl',[_c('dt',{staticClass:"flex items-center text-xs justify-between text-gray-700"},[_vm._v(" Avg. order value "),_c('div',{staticClass:"ml-3 inline-flex flex-row items-center rounded-md ring-1 ring-inset bg-yellow-50 px-2 py-0.5 text-xs font-medium leading-none text-yellow-700 ring-yellow-300 md:mt-2 lg:mt-0",class:{
                                        'bg-green-50 text-green-700 ring-green-300':
                                            _vm.averageValueIncrease > 0 ||
                                            isNaN(_vm.averageValueIncrease)
                                    }},[(_vm.averageValueIncrease >= 0 ||
                                            !isFinite(_vm.averageValueIncrease))?_c('base-trending-up-icon',{staticClass:"h-4 h-4 -ml-1 mr-0.5 flex-shrink-0 self-center"}):_c('base-trending-down-icon',{staticClass:"h-4 h-4 -ml-1 mr-0.5 flex-shrink-0 self-center"}),_c('span',{staticClass:"sr-only"},[_vm._v(" Increased by ")]),_c('span',[_c('animated-number',{staticClass:"font-heading",attrs:{"value":_vm.averageValueIncrease,"duration":800,"round":"1"}}),_vm._v("% ")],1)],1)]),_c('dd',{staticClass:"mt-1 flex items-baseline justify-between md:block lg:flex"},[_c('div',{staticClass:"flex flex-col items-baseline text-2xl font-semibold leading-8 text-black sm:text-xl"},[_c('animated-number',{staticClass:"font-heading",attrs:{"value":_vm.avgOrder / 100,"duration":800,"price":""}}),_c('span',{staticClass:"text-sm font-medium leading-5 text-gray-500"},[_vm._v(" from "),_c('animated-number',{staticClass:"font-heading",attrs:{"value":_vm.lastMonthAvgOrder / 100,"duration":600,"price":""}})],1)],1)])])])]),_c('div',{staticClass:"border-t border-gray-100 md:border-0 md:border-l"},[_c('div',{staticClass:"px-4 py-3"},[_c('dl',[_c('dt',{staticClass:"flex items-center text-xs justify-between text-gray-700"},[_vm._v(" Gross revenue "),_c('div',{staticClass:"ml-3 inline-flex items-center rounded-md ring-1 ring-inset bg-yellow-50 px-2 py-0.5 text-xs font-medium leading-none text-yellow-700 ring-yellow-300 md:mt-2 lg:mt-0",class:{
                                        ' bg-green-50 text-green-700 ring-green-300':
                                            _vm.revenueIncrease > 0 ||
                                            isNaN(_vm.revenueIncrease)
                                    }},[(_vm.revenueIncrease >= 0 ||
                                            !isFinite(_vm.revenueIncrease))?_c('base-trending-up-icon',{staticClass:"h-4 h-4 -ml-1 mr-0.5 flex-shrink-0 self-center"}):_c('base-trending-down-icon',{staticClass:"h-4 h-4 -ml-1 mr-0.5 flex-shrink-0 self-center"}),_c('span',{staticClass:"sr-only"},[_vm._v(" Increased by ")]),_c('span',[_c('animated-number',{staticClass:"font-heading",attrs:{"value":_vm.revenueIncrease,"duration":600}}),_vm._v("%")],1)],1)]),_c('dd',{staticClass:"mt-1 flex items-baseline justify-between md:block lg:flex"},[_c('div',{staticClass:"flex flex-col items-baseline text-2xl font-semibold leading-8 text-black sm:text-xl"},[_c('animated-number',{staticClass:"font-heading",attrs:{"value":_vm.revenue,"duration":1800,"price":"","is-abbreviate":""}}),_c('span',{staticClass:"text-sm font-medium leading-5 text-gray-500"},[_vm._v(" from "),_c('animated-number',{staticClass:"font-heading",attrs:{"value":_vm.revenueLastMonth,"duration":1200,"price":"","is-abbreviate":""}})],1)],1)])])])])])]):_c('div',{staticClass:"mt-5 grid grid-cols-1 overflow-hidden border border-gray-100 bg-white p-4 shadow sm:rounded-md md:grid-cols-3"},[_c('div',[_c('VclList',{attrs:{"width":180,"height":100}})],1),_c('div',{staticClass:"border-t border-gray-200 pl-3 pt-3 sm:pt-0 md:border-0 md:border-l"},[_c('VclList',{attrs:{"width":180,"height":100}})],1),_c('div',{staticClass:"border-t border-gray-200 pl-3 pt-3 sm:pt-0 md:border-0 md:border-l"},[_c('VclList',{attrs:{"width":180,"height":100}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }