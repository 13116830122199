export default class StatusEnum {
    static get PENDING() {
        return 'Pending';
    }

    static get ACCEPTED() {
        return 'Accepted';
    }

    static get COMPLETE() {
        return 'Complete';
    }

    static get READY_FOR_PICKUP() {
        return 'Ready For Pickup';
    }

    static get OUT_FOR_DELIVERY() {
        return 'Out for Delivery';
    }

    static get PREPARING() {
        return 'Preparing';
    }

    static get DELIVERED() {
        return 'Delivered';
    }

    static get FAILED() {
        return 'Failed';
    }

    static get SYNCING() {
        return 'Syncing';
    }

    static get REJECTED() {
        return 'Rejected';
    }

    static get SUCCESS() {
        return 'Success';
    }

    static get EXPIRED() {
        return 'Expired';
    }

    static get ACTIVE() {
        return 'Active';
    }

    static get SCHEDULED() {
        return 'Scheduled';
    }

    static get RETRYING() { 
        return 'Retrying'
    }

    static get options() {
        return {
            PENDING: this.PENDING,
            ACCEPTED: this.ACCEPTED,
            COMPLETE: this.COMPLETE,
            READY_FOR_PICKUP: this.READY_FOR_PICKUP,
            OUT_FOR_DELIVERY: this.OUT_FOR_DELIVERY,
            PREPARING: this.PREPARING,
            DELIVERED: this.DELIVERED,
            FAILED: this.FAILED,
            SYNCING: this.SYNCING,
            REJECTED: this.REJECTED,
            SUCCESS: this.SUCCESS,
            EXPIRED: this.EXPIRED,
            ACTIVE: this.ACTIVE,
            SCHEDULED: this.SCHEDULED,
            RETRYING: this.RETRYING
        };
    }
}
