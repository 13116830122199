var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex w-0 flex-1 flex-col overflow-hidden"},[_c('ToggleSidebar'),_c('main',{staticClass:"focus:outline-none relative z-0 flex-1 overflow-y-auto",attrs:{"tabindex":"0"}},[_c('div',{staticClass:"pb-6 pt-2 md:py-6"},[_c('div',{staticClass:"mx-auto px-4 sm:px-6 lg:px-8"},[_c('div',{staticClass:"md:flex md:items-center md:justify-between"},[_c('div',{staticClass:"min-w-0 flex-1 flex items-center"},[_c('BasePageTitle',{attrs:{"title":"Banners"}})],1),_c('div',{staticClass:"flex justify-end sm:mr-4 md:ml-4"},[_c('base-button',{staticClass:"ml-3",attrs:{"size":"sm","button-text":"Create Banner","button-style":"action"},on:{"clicked":function($event){return _vm.$router.push({ name: 'newOffer' })}}})],1),_c('BaseSpinner',{attrs:{"isLoading":_vm.isLoading}})],1)]),_c('div',{staticClass:"mx-auto px-0 py-4 sm:px-6 md:px-8"},[_c('div',{staticClass:"rounded bg-white shadow-md sm:rounded-lg"},[_c('div',{staticClass:"flex flex-col justify-between px-2 py-2.5 sm:flex-row sm:px-6"},[_c('ul',{staticClass:"mb-2 flex flex-row items-center sm:mb-0 overflow-scroll"},_vm._l((_vm.offersFilters),function(filter){return _c('li',{key:filter.value,staticClass:"mr-1 cursor-pointer rounded-md px-3 py-1 text-xs transition duration-150 ease-in-out hover:bg-gray-50",class:{
                                    'bg-gray-100':
                                        filter.value === _vm.serverParams.status
                                },on:{"click":function($event){return _vm.updateParams({
                                        status: filter.value,
                                        page: 1
                                    })}}},[_vm._v(" "+_vm._s(filter.label)+" ")])}),0),_c('div',{staticClass:"flex"},[_c('search-input',{staticClass:"text-gray-700 sm:mr-3",attrs:{"min":3,"max":9,"size":"sm","placeholder":"Search by name"},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}}),_c('venue-selector',{key:_vm.venues ? _vm.venues.length : 0,attrs:{"venues":_vm.venues,"multiple-select":true,"allow-empty":true,"size":"sm"},on:{"selected":_vm.onVenueChange}})],1)]),_c('vue-good-table',{staticClass:"border-b border-gray-200",attrs:{"mode":"remote","columns":_vm.columns,"rows":_vm.offers,"styleClass":"tailwind-table","row-style-class":"mb-1 cursor-pointer","totalRows":_vm.totalRecords,"pagination-options":{
                            enabled: true,
                            perPage: _vm.serverParams.limit,
                            dropdownAllowAll: false,
                            rowsPerPageLabel: '',
                            perPageDropdown: [10, 20, 50, 100, 200],
                            setCurrentPage: _vm.serverParams.page
                        }},on:{"on-row-click":_vm.goToOfferDetails,"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'archived')?_c('status-badge',{attrs:{"status":_vm.offerStatus(props.row)}}):(
                                    props.column.field === 'mobileImage'
                                )?_c('span',{},[_c('img',{staticClass:"h-14 w-auto rounded-md shadow-sm",attrs:{"src":props.formattedRow[
                                            props.column.field
                                        ],"alt":""}})]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('template',{slot:"emptystate"},[_c('div',{staticClass:"flex h-40 w-full items-center justify-center bg-white"},[(!_vm.isLoading && _vm.totalRecords === 0)?_c('h3',{staticClass:"text-sm font-medium text-gray-700"},[_vm._v(" No banners found for the selected options ")]):_c('span',{staticClass:"spinner"})])]),_c('template',{slot:"loadingContent"},[_c('div',{staticClass:"flex h-40 w-full items-center justify-center bg-white"},[_c('span',{staticClass:"spinner"})])])],2)],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }