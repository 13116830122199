<template>
    <div class="relative mt-20">
        <div class="px-8 text-sm font-medium text-gray-700 mb-2 sm:px-12">
            Timeline
        </div>
        <comment-section
            :comments="order.comments"
            :order-id="order.id"
            @fetch-comments="fetchComments"
        />

        <div class="timeline w-full">
            <div v-auto-animate>
                <div
                    v-if="posReceiptId"
                    class="ml-3 flex flex-col items-start pl-16"
                >
                    <a
                        v-if="isTissl"
                        :href="`https://hub.tissl.com/report-centre/order-receipt-details/${posReceiptId}`"
                        target="_blank"
                        class="focus:outline-none mt-2 inline-flex items-center rounded-md border border-gray-300 bg-white px-2 py-1 text-xs font-semibold leading-5 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
                    >
                        <base-tissl-icon />
                        <span>View in POS</span>
                    </a>

                    <a
                        v-if="isIkentoo"
                        :href="`https://manager.lsk.lightspeed.app/receipt/list?accountId=${posReceiptId}`"
                        target="_blank"
                        class="focus:outline-none mt-2 inline-flex items-center rounded-md border border-gray-300 bg-white px-2 py-1 text-xs font-semibold leading-5 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
                    >
                        <base-lightspeed-icon />
                        <span>View in POS</span>
                    </a>

                    <view-in-deliverect-button
                        v-if="isDeliverect"
                        :deliverect-id="deliverectId"
                    />
                </div>
                <div
                    v-for="(event, index) in isExpanded
                        ? events
                        : collapsedEvents"
                    :key="event.title"
                >
                    <activity-item
                        :index="index"
                        :event="event"
                        :is-expanded="isExpanded"
                        :events-count="eventsCount"
                        :should-display-date-label="
                            shouldDisplayDateLabel(event, index)
                        "
                        @fetch-comments="fetchComments"
                        @toggle-collapse="toggleCollapse"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ActivityItem from '@/components/timeline/ActivityItem';
import CommentSection from '@/components/comments/CommentSection';
import { DeliveryProvidersEnum } from '@/enums';
import ViewInDeliverectButton from '@/components/widgets/ViewInDeliverectButton';

export default {
    name: 'OrderTimeline',
    data() {
        return {
            events: [],
            isExpanded: false,
            DeliveryProvidersEnum
        };
    },
    components: {
        ActivityItem,
        CommentSection,
        ViewInDeliverectButton
    },
    props: {
        order: {
            type: Object,
            required: true
        },
        lastDelivery: {
            type: Object,
            required: false
        }
    },
    mounted() {
        this.prepareEvents();
    },
    computed: {
        ...mapGetters({
            currency: 'user/currencySettings',
            accountId: 'user/accountId'
        }),
        eventsCount() {
            return this.events.length;
        },
        collapsedEvents() {
            if (this.eventsCount <= 8) {
                return this.events;
            } else {
                const newestEvents = this.events.slice(0, 2);
                const oldestEvent = this.events[this.eventsCount - 1];

                return [...newestEvents, oldestEvent];
            }
        },
        posReceiptId() {
            return this.order.posReceiptId;
        },
        isDeliverect() {
            return this.order.venue.posProvider === 'deliverect';
        },
        isIkentoo() {
            return this.order.venue.posProvider === 'ikentoo';
        },
        isTissl() {
            return this.order.venue.posProvider === 'tissl';
        },

        deliverectId() {
            return this.order.middlewareId
                ? this.order.middlewareId
                : this.posReceiptId;
        }
    },
    methods: {
        prepareEvents() {
            if (this.order.created_at) {
                this.addEvent(
                    `${this.order?.customer?.firstName || ''} ${this.order?.customer?.lastName || ''} placed this order.`,
                    this.order.created_at
                );
            }

            if (this.order.transactions?.length) {
              this.order.transactions.forEach(transaction => {
                if (transaction.kind === 'Sale') {
                  this.addEvent(
                      `A ${this.currency.symbol}${(transaction.amount / 100).toFixed(2)} ${
                          this.currency.ISO
                      } payment was successfully processed.`,
                      transaction.created_at
                  );
                }
              });
            }

            if (this.order.batchId) {
                this.addEvent(
                    `Order added to batch: ${this.order.batchId}`,
                    this.order.created_at
                );
            }

            if (this.order.acceptedAt) {
                this.addEvent(`Order was accepted`, this.order.acceptedAt);
            }

            if (this.order.billId) {
                this.addEvent(`Order was added to bill`, this.order.acceptedAt, {}, {
                  type: 'button',
                  text: `View bill`,
                  routerLink: {
                    path: '/tables',
                    query: { bill: this.order.billId, venueId: this.order.venueId }
                  }
                });
            }

            if (this.order.completedAt) {
                this.addEvent(`Order was completed`, this.order.completedAt);
            }

            if (this.order.externalServiceStatuses?.cloudprint?.length) {
              this.order.externalServiceStatuses.cloudprint.forEach(print => {
                this.addEvent(
                    `Print job ${print.copies ? 'x' + print.copies : '' }: ${print.status} ${print.status === 'success' ? '✅' : '❌'}`,
                    print.date
                );
              });
            }

            if (this.order.orderStatus === 'Cancelled') {
                this.addEvent(
                    'This order was cancelled. ',
                    this.order.updated_at
                );
            }

            if (this.order.archived) {
                this.addEvent(
                    'This order was archived.',
                    this.order.updated_at
                );
            }

            if (this.lastDelivery) {

                // Demo account
                if (this.accountId === 'f2b595dd-35b5-44ef-8c10-16a534dfab84' && this.lastDelivery.deliveryProvider === DeliveryProvidersEnum.ORKESTRO) {
                    this.lastDelivery.deliveryProvider = DeliveryProvidersEnum.ORKESTRO_DEMO;
                }

                this.addEvent(
                    `${this.lastDelivery.fleetName ? this.lastDelivery.fleetName.toUpperCase() : this.lastDelivery.deliveryProvider.toUpperCase()} delivery for order #${this.order.code} created.`,
                    this.lastDelivery.created_at,
                    {},
                    {
                        type: 'button',
                        text: `View in ${this.lastDelivery.deliveryProvider}`,
                        url: DeliveryProvidersEnum.providerDashboardLink(this.lastDelivery.deliveryProvider, this.lastDelivery.deliveryProviderId)
                    }
                );

                if (this.lastDelivery.proofOfDelivery?.length) {
                    this.addEvent(
                        `Proof of delivery uploaded.`,
                        this.lastDelivery.updated_at,
                        {},
                        {
                            type: 'button',
                            text: 'View image',
                            url: this.lastDelivery.proofOfDelivery[0].url
                        }
                    );
                }
            }



            this.order.comments.forEach(comment =>
                this.addEvent(comment.id, comment.created_at, comment)
            );

            this.events.sort(
                (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
            );
        },
        toggleCollapse() {
            this.isExpanded = !this.isExpanded;
        },
        addEvent(title, timestamp, comment = {}, action = null) {
            this.events.push({ title, timestamp, comment, action });
        },
        addTodayEvent(event) {
            if (!event) {
                return;
            }

            this.events.unshift({
                title: event.id,
                timestamp: event.created_at,
                comment: event
            });
        },
        removeCommentFromEvents(id) {
            this.events = this.events.filter(event => {
                return !(event.comment && event.comment.id === id);
            });
        },
        async fetchComments(id) {
            try {
                const { data } = await this.$axios.get(`/comments`, {
                    params: {
                        orderId: this.order.id
                    }
                });

                this.order.comments = data;

                if (id) {
                    this.removeCommentFromEvents(id);
                } else {
                    this.addTodayEvent(data[0]);
                }
            } catch (error) {
                throw new Error(`API ${error}`);
            }
        },
        shouldDisplayDateLabel(event, index) {
            return (
                index === 0 ||
                !this.$moment(event.timestamp).isSame(
                    this.events[index - 1].timestamp,
                    'day'
                )
            );
        }
    }
};
</script>

<style scoped>
.timeline:after {
    width: 2px;
    background: #e3e3e3;
    content: '';
    position: absolute;
    top: 122px;
    bottom: 0;
    z-index: -1;
    left: 60px;
}
</style>
