<template>
    <modal
        name="export-customers-modal"
        width="600px"
        height="auto"
        :clickToClose="true"
        :adaptive="true"
        @before-open="beforeOpen"
        @before-close="beforeClose"
    >
        <div
            class="pb-4 pt-5 px-4 bg-white rounded-lg shadow-xl overflow-hidden transform transition-all sm:p-6 sm:w-full"
        >
            <div>
                <div class="mt-1 text-left">
                    <h3 class="text-gray-900 text-lg font-heading font-medium leading-6">
                        Export customers
                    </h3>
                    <div v-if="currentUser" class="mt-2">
                        <p class="text-sm leading-5 text-gray-500">
                            Export a CSV file of your customers, we'll send an
                            email to {{ currentUser.email }} when it's ready. If you have a large number of customers this may take a while to arrive.
                        </p>
                    </div>
                </div>
            </div>
            <div
                class="mt-5 sm:flex sm:mt-6 sm:w-auto"
                :class="{ spinner: isLoading }"
            >
                <span class="flex w-full rounded-md shadow-sm sm:w-56">
                    <button
                        @click="exportCustomers"
                        :disabled="isLoading"
                        type="button"
                        class="inline-flex justify-center px-4 py-2 w-full text-white text-base font-medium leading-6 hover:bg-indigo-500 bg-indigo-600 border focus:border-indigo-700 border-transparent rounded-md focus:outline-none focus:shadow-outline-indigo shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    >
                        Export
                    </button>
                </span>
                <span
                    class="flex mt-3 w-full rounded-md shadow-sm sm:ml-3 sm:mt-0 sm:w-auto"
                >
                    <button
                        @click="closeModal"
                        type="button"
                        class="inline-flex justify-center px-4 py-2 w-full hover:text-gray-500 text-gray-700 text-base font-medium leading-6 bg-white border focus:border-blue-300 border-gray-300 rounded-md focus:outline-none focus:shadow-outline shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    >
                        Close
                    </button>
                </span>
            </div>
        </div>
    </modal>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'ExportCustomersModal',

    data() {
        return {
            isLoading: false,
            params: {}
        };
    },
    computed: {
        ...mapGetters({
            currentUser: 'user/currentUser'
        })
    },
    methods: {
        beforeOpen({ params }) {
            this.params = params.params;
        },

        beforeClose() {
            this.params = {};
        },

        async exportCustomers() {
            this.isLoading = true;
            try {
                await this.$axios.get('/customers/export?', {
                    params: this.params
                });

                this.$notify({
                    group: 'settings',
                    title: 'Export requested'
                });
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
                this.closeModal();
            }
        },
        closeModal() {
            this.$modal.hide('export-customers-modal');
        }
    }
};
</script>

<style
    src="@/assets/css/components/modals/export-customers-modal_scoped.css"
    scoped
/>
