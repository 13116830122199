<template>
    <div class="flex w-0 flex-1 flex-col overflow-hidden">
        <div
            v-if="!meta.hideNav"
            class="z-20 h-12 mt-4 mx-4 w-auto">
            <div
                v-if="venue"
                :class="{ 'max-w-7xl': meta.fullWidth }"
                class="mx-auto flex h-full glass bg-white max-w-5xl items-center justify-between px-4 sm:px-6 lg:px-8"
            >
                <div class="flex items-center">
                    <h1 class="text-base font-medium text-indigo-600">
                        {{ venue.adminName || venue.name }}
                    </h1>
                    <a :href="storeUrl" target="_blank" title="Visit store">
                        <svg
                            class="ml-2 h-4 w-4 flex-shrink-0 text-gray-600"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                            ></path>
                        </svg>
                    </a>
                </div>
                <div class="flex items-center">
                    <div class="hidden sm:block">
                        <a :href="storeUrl" target="_blank" title="Visit store">
                            <button
                                type="button"
                                class="focus:outline-none inline-flex items-center rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 text-gray-600 hover:bg-gray-100 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                <svg
                                    class="-ml-0.5 mr-2 h-4 w-4"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M10 12a2 2 0 100-4 2 2 0 000 4z"
                                    ></path>
                                    <path
                                        fill-rule="evenodd"
                                        d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                                        clip-rule="evenodd"
                                    ></path>
                                </svg>
                                View your store
                            </button>
                        </a>
                    </div>
                    <div
                        v-click-outside="onClickOutside"
                        class="relative mr-4 select-none text-left md:mr-6 print:hidden"
                    >
                        <div>
                            <span class="rounded-md">
                                <button
                                    @click="showMoreActions = !showMoreActions"
                                    type="button"
                                    class="focus:outline-none inline-flex w-full items-center rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-none text-gray-600 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
                                    id="options-menu"
                                    aria-haspopup="true"
                                    aria-expanded="true"
                                >
                                    More actions
                                    <svg
                                        class="-mr-1 ml-2 h-4 w-4"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                            clip-rule="evenodd"
                                        />
                                    </svg>
                                </button>
                            </span>
                        </div>
                        <transition
                            enter-active-class="transition ease-out duration-100"
                            enter-class="transform opacity-0 scale-95"
                            enter-to-class="transform opacity-100 scale-100"
                            leave-active-class="transition ease-in duration-75"
                            leave-class="transform opacity-100 scale-100"
                            leave-to-class="transform opacity-0 scale-95"
                        >
                            <div
                                v-if="showMoreActions"
                                class="absolute right-0 z-50 mt-2 w-56 origin-top-right rounded-md shadow-lg"
                            >
                                <div
                                    class="shadow-xs rounded-md bg-white"
                                    role="menu"
                                    aria-orientation="vertical"
                                    aria-labelledby="options-menu"
                                >
                                    <div class="py-1">
                                        <span
                                            @click="copyStoreUrl"
                                            class="focus:outline-none group flex cursor-pointer items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900"
                                            role="menuitem"
                                        >
                                            <svg
                                                class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500"
                                                fill="currentColor"
                                                viewBox="0 0 20 20"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M8 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"
                                                ></path>
                                                <path
                                                    d="M6 3a2 2 0 00-2 2v11a2 2 0 002 2h8a2 2 0 002-2V5a2 2 0 00-2-2 3 3 0 01-3 3H9a3 3 0 01-3-3z"
                                                ></path>
                                            </svg>
                                            Copy store link
                                        </span>
                                    </div>
                                    <div class="border-t border-gray-100"></div>
                                    <div class="py-1">
                                        <span
                                            @click="showArchiveVenueModal()"
                                            class="focus:outline-none group flex cursor-pointer items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900"
                                            role="menuitem"
                                        >
                                            <svg
                                                class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                            >
                                                <path
                                                    d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z"
                                                />
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"
                                                    clip-rule="evenodd"
                                                />
                                            </svg>
                                            {{
                                                venue.archived
                                                    ? 'Unarchive store'
                                                    : 'Archive store'
                                            }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
            </div>
        </div>
        <ToggleSidebarButton v-if="!meta.hideToggleSidebar" />
        <main
            class="focus:outline-none relative z-0 flex-1 overflow-y-auto pb-6 pt-2 md:py-6"
        >
            <div
                class="mx-auto mb-40 px-4 sm:px-6 lg:px-8"
                :class="{
                    'max-w-7xl': meta.fullWidth,
                    'max-w-5xl': !meta.fullWidth
                }"
                v-if="!isLoading && venue"
            >
                <router-view :venue-id="venue.id" :venue="venue" />
            </div>
            <div class="flex h-full w-full items-center justify-center" v-else>
                <span
                    class="h-4 w-4"
                    :class="{ 'text-gray-900 spinner': isLoading }"
                ></span>
            </div>
        </main>
        <ArchiveVenueModal @update="navigateToStoreList" />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ToggleSidebarButton from '../../helpers/ToggleSidebarButton';
import ArchiveVenueModal from '../../modals/ArchiveVenueModal';
import vClickOutside from 'v-click-outside';

export default {
    name: 'SettingsContainter',
    components: {
        ToggleSidebarButton,
        ArchiveVenueModal
    },
    metaInfo() {
        return {
            title: `Store settings (${this.venue.name})`
        };
    },
    data() {
        return {
            isLoading: true,
            showMoreActions: false
        };
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    async created() {
        await this.fetchVenue();
    },
    methods: {
        ...mapActions({
            fetchSingle: 'venues/fetchSingle'
        }),
        async fetchVenue() {
            this.isLoading = true;
            const payload = {
                slug: this.$route.params.id,
                params: {
                    allHours: true
                }
            };

            try {
                await this.fetchSingle(payload);
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        },
        onClickOutside() {
            this.showMoreActions = false;
        },
        showArchiveVenueModal() {
            this.$modal.show('archive-venue-modal', {
                venueId: this.venue.id,
                archived: this.venue.archived
            });
        },
        navigateToStoreList() {
            this.$router.push({ name: 'stores' });
        },
        async copyStoreUrl() {
            try {
                await navigator.clipboard.writeText(this.storeUrl);
                this.$notify({
                    group: 'settings',
                    title: 'Copied to clipboard'
                });
            } catch (err) {
                throw new Error('Failed to copy:', err);
            } finally {
                this.showMoreActions = false;
            }
        }
    },
    computed: {
        ...mapGetters({
            venue: 'venues/getVenue'
        }),
        meta() {
            return this.$route.meta;
        },
        storeUrl() {
            return `https://${this.$store.state?.user?.profile?.partner?.orderDomain}/${this.venue.slug}/menu`;
        }
    }
};
</script>
