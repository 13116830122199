<template>
    <div>
        <div class="md:flex md:items-center md:justify-between">
            <div class="min-w-0 flex-1">
                <router-link
                    :to="{ name: 'editVenue' }"
                    class="mb-2 flex items-center text-sm text-gray-500 inline-flex"
                >
                    <base-back-icon />
                    <span>Settings</span>
                </router-link>
                <base-page-title title="Printers" />
            </div>
            <div
                class="mr-4 mt-4 flex md:ml-4 md:mt-0"
                v-if="!isLoading && printers && printers.length"
            >
                <router-link
                    :to="{ name: 'printerSettings' }"
                    class="rounded-md shadow-sm md:ml-3"
                >
                  <base-button
                      size="sm"
                      buttonText="Connect new printer"
                  />
                </router-link>
            </div>

            <BaseSpinner :isLoading="isLoading" />
        </div>
        <div>
            <div class="mt-6 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                            >Integrated printers</h3
                        >
                        <p class="mt-2 text-sm text-gray-500">
                            Setup compatible receipt and ticket printers to
                            print your orders on-demand or automatically. You
                            can check compatibility and
                            <a
                                href="https://commandear.com/products/storekit-configures-star-tsp143iv-receipt-printer"
                                target="_blank"
                                class="underline"
                                rel="noopener"
                                >buy printers here</a
                            >.
                        </p>
                    </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0">
                    <div
                        v-for="printer in printers"
                        :key="printer.id"
                        class="mb-4 shadow sm:overflow-hidden sm:rounded-md"
                    >
                        <div
                            class="space-y-6 bg-white px-3 py-4 sm:p-2"
                            v-if="printer.venues && printer.venues.length"
                        >
                            <div class="flex items-center">
                                <div class="flex-shrink-0">
                                    <img
                                        class="h-16 p-2 w-auto"
                                        width="64"
                                        height="48"
                                        src="https://ucarecdn.com/0b951f42-eb08-4101-ad07-327a2143592e/-/format/auto/"
                                        alt=""
                                    />
                                </div>
                                <div
                                    v-if="printer.printerId"
                                    class="flex w-full items-center justify-between"
                                >
                                    <div>
                                        <span
                                            class="block text-sm font-semibold text-gray-700"
                                        >
                                            {{ printer.venues[0].printerName }}
                                        </span>
                                        <span
                                            class="font-mono text-xs font-semibold uppercase text-gray-700"
                                        >
                                            {{ printer.mac }}
                                        </span>
                                        <div class="flex items-center text-sm text-gray-600">
                                           <span
                                               aria-label="Online"
                                               class="flex h-3 w-3 animate items-center justify-center rounded-full mr-1"
                                               :class="{
                                                  'bg-red-100': !printer?.status?.Online,
                                                  'bg-green-100': printer?.status?.Online
                                              }"
                                           >
                                              <span
                                                  class="h-2 w-2 rounded-full"
                                                  :class="{
                                                      'bg-green-400': printer?.status?.Online,
                                                      'bg-red-400': !printer?.status?.Online
                                                  }"
                                              ></span>
                                          </span>
                                          <span>{{ printer?.status?.Online ? 'Online': 'Offline' }}</span>
                                          <span class="px-2">•</span>
                                            <span>
                                                {{
                                                    printer.venues[0].autoPrint
                                                        ? 'Auto printing'
                                                        : 'Manual printing'
                                                }}
                                            </span>
                                            <span class="px-2">•</span>
                                            <span>
                                                prints
                                                {{
                                                    printer.venues[0]
                                                        .defaultCopies > 1
                                                        ? printer.venues[0]
                                                              .defaultCopies +
                                                          ' receipts'
                                                        : '1 receipt'
                                                }}
                                            </span>
                                        </div>
                                    </div>

                                    <div class="flex items-center">
                                        <div @click="testPrinter(printer.id)">
                                            <base-printer-icon
                                                class="cursor-pointer text-gray-400 h-5 w-5"
                                                title="Print a test receipt"
                                                v-tooltip="
                                                    'Print a test receipt'
                                                "
                                                v-if="printer.id"
                                            />
                                        </div>
                                        <router-link
                                            :to="{
                                                name: 'printerSettings',
                                                query: {
                                                    mac: printer.mac,
                                                    operation: 'edit'
                                                }
                                            }"
                                            class="md:ml-3 mr-2"
                                        >
                                          <base-button buttonText="Edit Printer" size="sm" :disabled="isLoading" />
                                        </router-link>
                                    </div>
                                </div>
                                <div v-else class="flex w-full justify-between">
                                    <div>
                                        <span
                                            class="font-mono text-xs font-semibold uppercase text-gray-800"
                                            >{{ printer.mac }}</span
                                        >
                                        <span
                                            class="block text-sm text-red-600"
                                        >
                                            Pending confirmation
                                        </span>
                                    </div>

                                    <div class="flex items-center">
                                        <button
                                            @click="confirmPrinter(printer.mac)"
                                            :disabled="isLoading"
                                            type="button"
                                            class="focus:outline-none mr-4 inline-flex items-center rounded-md border border-gray-300 bg-white px-2 py-1 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:px-4 sm:py-2 sm:text-sm"
                                        >
                                            Connect printer
                                        </button>
                                        <div
                                            @click="
                                                showDeletePrinterModal(
                                                    printer.mac
                                                )
                                            "
                                            :disabled="isLoading"
                                            class="group mr-3 inline-block cursor-pointer text-gray-400"
                                        >
                                            <svg
                                                class="h-5 w-5 group-hover:text-red-400"
                                                fill="none"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                                ></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="!isLoading && printers && !printers.length"
                        class="mb-4 flex h-full flex-col items-center justify-center space-y-6 bg-white px-3 py-4 shadow sm:overflow-hidden sm:rounded-md sm:p-2"
                    >
                        <div class="mr-4 mt-4 flex md:ml-4 md:mt-0">
                            <base-button
                                href="https://commandear.com/products/storekit-configures-star-tsp143iv-receipt-printer"
                                button-text="Buy a printer"
                                size="sm"
                            />
                            <router-link
                                :to="{ name: 'printerSettings' }"
                                class="md:ml-3"
                            >
                              <base-button
                                  button-text="Connect Printer"
                                  size="sm"
                              />
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <BaseSpacerWithRuler />

        <DeletePrinterModal @fetch-printers="fetchPrinters" />
    </div>
</template>

<script>
import DeletePrinterModal from '@/components/modals/DeletePrinterModal';
import ably from '@/helpers/ably';

export default {
    name: 'printerIntegration',
    metaInfo: {
        title: 'Cloud Printing'
    },
    components: { DeletePrinterModal },
    props: {
        venueId: {
            type: Number,
            required: true,
            default: 0
        }
    },
    data() {
        return {
            printers: null,
            isLoading: true
        };
    },
    created() {
        this.fetchPrinters();

        this.watchPrinterStatus();
    },
    methods: {
        async fetchPrinters() {
            this.isLoading = true;
            const params = {
                venueId: this.venueId
            };

            try {
                const { data } = await this.$axios.get('/printers', {
                    params
                });

                this.printers = data;
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        },
        confirmPrinter(printerMac) {
            this.$router.push({
                name: 'printerSettings',
                query: { mac: printerMac }
            });
        },
        showDeletePrinterModal(mac) {
            this.$modal.show('delete-printer-modal', {
                mac,
                venueId: this.venueId
            });
        },
        async testPrinter(printerId) {
            this.isLoading = true;

            try {
                await this.$axios.post('/printers/test', {
                    printerId
                });

                this.$notify({
                    group: 'settings',
                    title: 'Test print job sent'
                });
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        }, 
        watchPrinterStatus() {
            const channel = ably.channels.get(`printer-status-${this.venueId}`);

            channel.subscribe(
                `printer-status-event-${this.venueId}`,
                ({ data }) => {
                    const index = this.printers.findIndex(
                        ({ id }) => id === data.printerId
                    );

                    if (~index && this.printers[index].status) {
                        this.printers[index].status.Online =
                            !!(data.status === 'online');
                    }
                }
            );
        }
    }
};
</script>

<style>
.animate {
  animation: pulse-green 2s infinite;
}

@keyframes pulse-green {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
  }
}
</style>