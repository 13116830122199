<template>
    <tbody class="bg-white overflow-auto">
        <slot />
    </tbody>
</template>

<script>
import { ContainerMixin } from 'vue-slicksort';

export default {
    name: 'AreaListContainer',
    mixins: [ContainerMixin]
};
</script>
