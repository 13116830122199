<template>
    <div>
        <ul>
            <li v-for="product in items" :key="product.id">
                <div href="#" class="block">
                    <div class="flex items-center px-4 py-2 sm:px-6">
                        <div class="flex flex-1 items-center min-w-0">
                            <div class="flex-shrink-0">
                                <img
                                    class="h-auto w-10 rounded-md"
                                    :src="
                                        product.image +
                                        '-/scale_crop/100x100/smart/-/format/auto/'
                                    "
                                    :alt="product.name"
                                    v-if="product.image"
                                />
                                <div
                                    class="w-10 h-10 bg-gray-100 rounded-md shadow-inner"
                                    v-else
                                ></div>
                            </div>
                            <div class="flex-1 px-4 min-w-0">
                                <div>
                                    <div
                                        class="text-gray-600 truncate text-sm font-medium leading-5"
                                        >{{ product.name }}</div
                                    >
                                </div>
                                <div>
                                    <div
                                        class="text-indigo-600 truncate font-medium leading-5"
                                        ><span
                                            class="text-gray-400 text-sm md:hidden"
                                            >Units sold: </span
                                        >{{ product.quantity }}</div
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'CustomerTopItems',
    props: {
        items: {
            type: Array,
            required: true,
            default: () => []
        }
    }
};
</script>
