import Vue from 'vue';

Vue.filter('pluralize', (amount, singular, plural) => {
    amount = parseInt(amount);

    if (amount === 1 || amount === -1) {
        return `${amount} ${singular}`;
    }

    return `${amount} ${plural}`;
});
