var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.key)?_c('div',{key:_vm.key,staticClass:"w-full"},[_c('div',{staticClass:"draggable-category rounded-t-lg border-b border-gray-200 bg-gray-100 px-4 py-3 sm:px-6",class:{
            'bg-red-300': _vm.element.deleted,
            'bg-green-300': !_vm.element.deleted && !_vm.element.existedBefore
        }},[_c('div',{key:_vm.key,staticClass:"-ml-4 -mt-4 flex cursor-move flex-wrap items-center justify-between sm:flex-nowrap"},[_c('div',{staticClass:"ml-4 mt-4 flex cursor-pointer"},[_c('div',{staticClass:"w-full overflow-hidden"},[_c('div',{staticClass:"flex"},[_c('toggle-switch',{on:{"input":function($event){return _vm.setUpdate(_vm.element)}},model:{value:(_vm.element.update),callback:function ($$v) {_vm.$set(_vm.element, "update", $$v)},expression:"element.update"}}),_c('div',{staticClass:"flex",on:{"click":function($event){return _vm.$emit('editElement', _vm.element)}}},[_c('h3',{staticClass:"text-md ml-3 font-medium leading-6 text-gray-700"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                                        _vm.element.oldName &&
                                        _vm.element.name !== _vm.element.oldName
                                    ),expression:"\n                                        element.oldName &&\n                                        element.name !== element.oldName\n                                    "}],staticClass:"line-through"},[_vm._v(" "+_vm._s(_vm.element.oldName)+" ")]),_vm._v(" "+_vm._s(_vm.element.name)+" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                                        _vm.element.oldPrice &&
                                        _vm.element.price !== _vm.element.oldPrice
                                    ),expression:"\n                                        element.oldPrice &&\n                                        element.price !== element.oldPrice\n                                    "}],staticClass:"line-through"},[_vm._v(_vm._s(_vm._f("currency")((_vm.element.oldPrice / 100))))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.element.price),expression:"element.price"}]},[_vm._v(_vm._s(_vm._f("currency")((_vm.element.price / 100))))])])])],1),_c('p',{staticClass:"mt-1 max-w-md truncate pr-10 text-sm leading-5 text-gray-500"},[_vm._v(" "+_vm._s(_vm.element.description)+" ")]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(
                            !_vm.element.deleted &&
                            !_vm.element.existedBefore &&
                            !_vm.element.update
                        ),expression:"\n                            !element.deleted &&\n                            !element.existedBefore &&\n                            !element.update\n                        "}],staticClass:"mt-1 max-w-md truncate pr-10 text-sm leading-5 text-gray-800"},[_vm._v(" Element won't be inserted ")]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.element.deleted && !_vm.element.update),expression:"element.deleted && !element.update"}],staticClass:"mt-1 max-w-md truncate pr-10 text-sm leading-5 text-gray-800"},[_vm._v(" Element won't be deleted ")]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.element.existedBefore && !_vm.element.update),expression:"element.existedBefore && !element.update"}],staticClass:"mt-1 max-w-md truncate pr-10 text-sm leading-5 text-gray-800"},[_vm._v(" Element will be deleted ")])])]),_c('div',{staticClass:"ml-4 mt-4 flex-shrink-0 cursor-default"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.showExpandButton),expression:"showExpandButton"}],staticClass:"rounded-md shadow-sm"},[_c('button',{staticClass:"focus:outline-none inline-flex w-full justify-center rounded-md bg-white px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800",class:{
                            'cursor-default opacity-25':
                                (!_vm.element.update && !_vm.element.deleted) ||
                                (_vm.element.deleted && _vm.element.update)
                        },attrs:{"type":"button","disabled":(!_vm.element.update && !_vm.element.deleted) ||
                            (_vm.element.deleted && _vm.element.update)},on:{"click":function($event){_vm.show = !_vm.show}}},[(_vm.show)?_c('span',[_vm._v("Collapse")]):_c('span',[_vm._v("Expand")]),_c('svg',{staticClass:"h-6 w-6 transition duration-300",attrs:{"fill":"currentColor","viewBox":"0 0 20 20","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z","clip-rule":"evenodd"}})])])])])])]),(_vm.show)?_c('div',[_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"-my-2 overflow-x-auto py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"},[_c('div',{staticClass:"inline-block min-w-full overflow-hidden border-b border-gray-200 pl-10 align-middle sm:rounded-lg"},[_vm._t("default")],2)])])]):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }