<template>
    <modal
        name="archive-venue-modal"
        :adaptive="true"
        height="auto"
        width="600px"
        @before-open="beforeOpen"
    >
        <div
            class="w-full transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 shadow-xl transition-all sm:p-6"
        >
            <div class="sm:flex sm:items-start">
                <div
                    class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
                >
                    <svg
                        class="h-6 w-6 text-red-600"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                        ></path>
                    </svg>
                </div>
                <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <h3
                        class="font-heading text-lg font-medium leading-6 text-gray-900"
                    >
                        {{ archived ? 'Unarchive Store' : 'Archive Store' }}
                    </h3>
                    <div class="mt-2">
                        <p class="text-sm leading-5 text-gray-500">
                            {{
                                archived
                                    ? 'Unarchive this store to show it on your stores list'
                                    : "Archive this store to hide it from your stores list and prevent guests accesing it, you'll be able to un-archive the store later."
                            }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="mt-5 justify-end sm:mt-4 sm:flex sm:pl-4">
                <span class="flex w-full rounded-md shadow-sm sm:w-auto">
                    <button
                        @click="hideModal()"
                        type="button"
                        class="focus:outline-none inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium leading-6 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline sm:text-sm sm:leading-5"
                    >
                        Cancel
                    </button>
                </span>
                <span
                    class="flex w-full rounded-md shadow-sm sm:ml-3 sm:mt-0 sm:w-auto"
                >
                    <button
                        v-if="archived"
                        @click="restore()"
                        type="button"
                        class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo sm:text-sm sm:leading-5"
                    >
                        Unarchive Store
                    </button>
                    <button
                        v-else
                        @click="archive()"
                        type="button"
                        class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo sm:text-sm sm:leading-5"
                    >
                        Archive Store
                    </button>
                </span>
            </div>
        </div>
    </modal>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: 'ArchiveOrderModal',
    data() {
        return {
            venueId: null,
            archived: false,
            isLoading: false
        };
    },
    methods: {
        ...mapActions({
            archiveVenue: 'venues/archive',
            restoreVenue: 'venues/restore'
        }),
        beforeOpen(event) {
            this.venueId = event.params.venueId;
            this.archived = event.params.archived;
        },
        hideModal() {
            this.$modal.hide('archive-venue-modal');
        },
        async archive() {
            try {
                this.isLoading = true;
                await this.archiveVenue(this.venueId);

                this.$emit('update');
                this.$notify({
                    group: 'settings',
                    title: 'Archived store'
                });
            } catch (error) {
                this.$notify({
                    group: 'settings',
                    title: 'Something went wrong'
                });
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
                this.hideModal();
            }
        },
        async restore() {
            try {
                this.isLoading = true;
                await this.restoreVenue(this.venueId);
                this.$emit('update');
                this.$notify({
                    group: 'settings',
                    title: 'Unarchived store'
                });
            } catch (error) {
                this.$notify({
                    group: 'settings',
                    title: 'Something went wrong'
                });
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
                this.hideModal();
            }
        }
    }
};
</script>
