var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('div',{staticClass:"md:flex md:items-center md:justify-between"},[_c('div',{staticClass:"min-w-0 flex-1"},[_c('router-link',{staticClass:"mb-2 flex items-center text-sm text-gray-500 inline-flex",attrs:{"to":{ name: 'paymentSettings' }}},[_c('base-back-icon'),_c('span',[_vm._v("Payment Settings")])],1),_c('h2',{staticClass:"text-2xl font-semibold text-gray-900"},[_vm._v(" PayPal Connection Status ")])],1),_c('div',{staticClass:"h-4 w-4",class:{ 'text-gray-900 spinner': _vm.isLoading }},[_c('span')])]),_vm._m(0),_vm._l((_vm.paypalInformations),function(paypalInfo){return _c('div',{key:paypalInfo.name,staticClass:"mb-5"},[_c('div',{staticClass:"rounded-md border border-blue-400 bg-blue-50 p-4"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex-shrink-0"},[_c('svg',{staticClass:"h-5 w-5 text-blue-400",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor","aria-hidden":"true"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z","clip-rule":"evenodd"}})])]),_c('div',{staticClass:"ml-3 flex-1 md:flex md:justify-between"},[_c('p',{directives:[{name:"linkified",rawName:"v-linkified:options",value:({
                                className: 'underline',
                                attributes: {
                                    rel: 'noreferrer noopener'
                                }
                            }),expression:"{\n                                className: 'underline',\n                                attributes: {\n                                    rel: 'noreferrer noopener'\n                                }\n                            }",arg:"options"}],staticClass:"text-sm text-gray-700"},[_vm._v(" "+_vm._s(paypalInfo.content)+" ")])])])])])}),_c('div',{staticClass:"flex justify-end"},[_c('router-link',{attrs:{"to":{ name: 'paymentSettings' }}},[_c('button',{staticClass:"focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",attrs:{"type":"button"}},[_vm._v(" Continue ")])])],1)],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-10"},[_c('h1',{staticClass:"text-xl font-semibold text-gray-700"},[_vm._v("Successfully connected to PayPal!")])])
}]

export { render, staticRenderFns }