<template>
    <div
        class="sm:pt-18 mx-auto flex min-h-screen w-full max-w-screen-lg flex-row justify-start overflow-scroll py-16 pt-20 sm:justify-center md:pt-20"
    >
        <div class="hidden w-1/2 flex-shrink-0 flex-col sm:flex">
            <div class="mr-10 px-4 pt-20 lg:px-0" v-if="isStoreKit">
                <div class="ml-0 mr-auto flex items-center">
                    <img
                        class="h-10 w-auto"
                        :src="whitelabelLogoUrl"
                        alt="Logo"
                        v-if="whitelabelLogoUrl"
                    />
                    <div
                        class="ml-4 flex items-center text-gray-600"
                        v-if="isZettleReferral || isOpentableReferral"
                    >
                        <svg
                            class="h-5 w-5"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                            ></path>
                        </svg>
                        <BaseZettleLogo v-if="isZettleReferral" />
                        <BaseOpentableLogo
                            class="ml-4 h-9"
                            v-if="isOpentableReferral"
                        />
                    </div>
                </div>
                <div>
                    <p class="mt-4 text-base text-gray-700 sm:mb-10">
                        Join over 500 hospitality brands who partner with
                        storekit to sell and connect with their customers
                        directly.
                    </p>
                    <ul class="mb-12 hidden sm:block">
                        <li class="mb-4 flex text-lg text-gray-800">
                            <svg
                                class="mr-3 h-6 w-6 flex-shrink-0 text-green-400"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M5 13l4 4L19 7"
                                ></path>
                            </svg>
                            <div>
                                <h5
                                    class="mb-0 text-sm font-medium leading-none text-gray-900"
                                    >Get started quickly</h5
                                >
                                <p class="mt-1 text-sm text-gray-600">
                                    Create your store in just a few minutes.
                                    Then easily input or import your menu.
                                </p>
                            </div>
                        </li>
                        <li class="mb-4 flex text-lg text-gray-800">
                            <svg
                                class="mr-3 h-6 w-6 flex-shrink-0 text-green-400"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M5 13l4 4L19 7"
                                ></path>
                            </svg>
                            <div>
                                <h5
                                    class="mb-0 text-sm font-medium leading-none text-gray-900"
                                    >Put away your credit card</h5
                                >
                                <p class="mt-1 text-sm text-gray-600">
                                    We don't charge anything for setup or per
                                    month for your store(s). Just pay the all
                                    inclusive card payments rate.
                                </p>
                            </div>
                        </li>
                        <li class="mb-4 flex text-lg text-gray-800">
                            <svg
                                class="mr-3 h-6 w-6 flex-shrink-0 text-green-400"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M5 13l4 4L19 7"
                                ></path>
                            </svg>
                            <div>
                                <h5
                                    class="mb-0 text-sm font-medium leading-none text-gray-900"
                                    >Help when you need it</h5
                                >
                                <p class="mt-1 text-sm text-gray-600">
                                    We're a partner in growing your online
                                    sales, and are always responsive and
                                    available for support.
                                </p>
                            </div>
                        </li>
                        <li>
                            <img
                                src="https://ucarecdn.com/11526658-01cb-4241-9cd0-9d71a97d27f5/-/format/auto/"
                                alt=""
                            />
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="flex w-full flex-col">
            <div
                v-if="!outOfGeographySuccesMessage"
                class="border border-gray-100 bg-white px-4 py-8 shadow-xl sm:rounded-md sm:px-10 sm:py-10 md:px-16 md:py-16"
            >
                <h2
                    v-if="country.enabled"
                    class="mb-6 text-left font-heading text-2xl font-bold leading-9 text-gray-700"
                >
                    Create your {{ isStoreKit ? 'storekit' : '' }} account
                </h2>
                <h2
                    v-else
                    class="mb-6 text-left text-2xl font-bold leading-9 text-gray-700"
                >
                    Sorry we're not available in {{ country.name }} yet, join
                    the waiting list and we'll be in touch when we can support
                    your business
                </h2>
                <div>
                    <div v-if="step === 0">
                        <div>
                            <label
                                for="name"
                                class="block text-sm font-medium leading-5 text-gray-700"
                            >
                                Your Name
                            </label>
                            <div class="mt-1 rounded-md shadow-sm">
                                <input
                                    v-model.trim="user.name"
                                    id="name"
                                    type="text"
                                    required
                                    class="focus:outline-none block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                                />
                            </div>
                        </div>
                        <div class="mt-6">
                            <label
                                for="businessName"
                                class="block text-sm font-medium leading-5 text-gray-700"
                            >
                                Business Name
                            </label>
                            <div class="mt-1 rounded-md shadow-sm">
                                <input
                                    v-model.trim="user.businessName"
                                    id="businessName"
                                    type="text"
                                    required
                                    class="focus:outline-none block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                                />
                            </div>
                        </div>
                        <div class="mt-6">
                            <label
                                for="email"
                                class="block text-sm font-medium leading-5 text-gray-700"
                            >
                                Email address
                            </label>
                            <div class="mt-1 rounded-md shadow-sm">
                                <input
                                    v-model.trim="user.email"
                                    @change="emailUnique = ''"
                                    id="email"
                                    type="email"
                                    autocomplete="username"
                                    required
                                    class="focus:outline-none block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                                />
                            </div>
                            <div class="mt-1" v-if="emailUnique">
                                <span class="py-2 text-xs text-red-600">{{
                                    emailUnique
                                }}</span>
                            </div>
                        </div>

                        <div class="mt-6">
                            <label
                                for="phone"
                                class="block text-sm font-medium leading-5 text-gray-700"
                            >
                                Phone Number
                            </label>
                            <div class="mt-1 rounded-md shadow-sm">
                                <input
                                    v-model.trim="user.phone"
                                    id="phone"
                                    type="tel"
                                    autocomplete="tel"
                                    class="focus:outline-none block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                                />
                            </div>
                        </div>

                        <div class="mt-6" v-if="country.enabled">
                            <BasePasswordInput
                                v-model="user.password"
                                autocomplete="new-password"
                                :error="$v.user.password.$error"
                                error-text="Your password is not strong enough. Your
                                password must be at least 6 characters long"
                            />
                        </div>

                        <div class="mt-6" v-if="isStoreKit">
                            <label
                                for="country"
                                class="block text-sm font-medium leading-5 text-gray-700"
                            >
                                Country
                            </label>
                            <div class="mt-1 rounded-md shadow-sm">
                                <multi-select
                                    v-if="countries"
                                    v-model="country"
                                    deselect-label="Can't remove this value"
                                    track-by="name"
                                    label="name"
                                    placeholder="Select one"
                                    :options="countries"
                                    :searchable="true"
                                    :allow-empty="false"
                                >
                                    <template
                                        slot="singleLabel"
                                        slot-scope="props"
                                        ><span class="option__desc">
                                            <span class="mr-1 text-base">
                                                {{ props.option.emoji }} </span
                                            ><span class="option__title">
                                                {{ props.option.name }}</span
                                            ></span
                                        >
                                    </template>

                                    <template slot="option" slot-scope="props">
                                        <div class="option__desc">
                                            <span class="mr-1 text-base">
                                                {{ props.option.emoji }} </span
                                            ><span class="option__title">
                                                {{ props.option.name }}</span
                                            ></div
                                        >
                                    </template>
                                </multi-select>
                            </div>
                        </div>
                    </div>

                    <div v-if="step === 1">
                        <div>
                            <label
                                for="name"
                                class="block text-sm font-medium leading-5 text-gray-700"
                            >
                                How many locations do you have?
                            </label>
                            <div class="mt-1 rounded-md shadow-sm">
                                <input
                                    v-model.trim="user.locations"
                                    id="locations"
                                    type="text"
                                    required
                                    class="focus:outline-none block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                                />
                            </div>
                        </div>
                      <div>
                        <label
                            for="name"
                            class="block text-sm font-medium leading-5 text-gray-700 mt-3"
                        >
                          How did you hear about us?
                        </label>
                        <div class="mt-1 rounded-md shadow-sm">
                          <input
                              v-model.trim="user.hearAboutUs"
                              id="hearAboutUs"
                              type="text"
                              required
                              class="focus:outline-none block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                          />
                        </div>
                      </div>
                        <div class="mt-6" v-if="isStoreKit">
                            <label
                                for="posSystem"
                                class="block text-sm font-medium leading-5 text-gray-700"
                            >
                                POS System
                            </label>
                            <div class="mt-1 rounded-md shadow-sm">
                                <multi-select
                                    v-if="posSystems"
                                    v-model="user.posSystem"
                                    deselect-label="Can't remove this value"
                                    track-by="name"
                                    label="name"
                                    placeholder="Select your POS"
                                    :options="posSystems"
                                    :searchable="true"
                                    :allow-empty="true"
                                >
                                    <template
                                        slot="singleLabel"
                                        slot-scope="props"
                                        ><span
                                            class="option__desc flex items-center"
                                        >
                                            <img
                                                class="mr-1 h-auto w-6 text-base"
                                                :src="props.option.img"
                                            />
                                            <span class="option__title">
                                                {{ props.option.name }}</span
                                            ></span
                                        >
                                    </template>

                                    <template slot="option" slot-scope="props">
                                        <div
                                            class="option__desc flex items-center"
                                        >
                                            <img
                                                class="mr-1 h-auto w-6 text-base"
                                                :src="props.option.img"
                                            /><span class="option__title">
                                                {{ props.option.name }}</span
                                            ></div
                                        >
                                    </template>
                                </multi-select>
                            </div>
                        </div>
                    </div>
                    <div
                        class="mt-6 flex items-center justify-between"
                        v-if="isOgav"
                    >
                        <base-checkbox
                            id="terms"
                            v-model="termsAgreed"
                            :error="termsError"
                        >
                            <template slot="label">
                                <p
                                    class="block text-sm leading-5 text-gray-900"
                                >
                                    I agree to the terms of the
                                    <a
                                        href="/Ogav-Online-T&C-Agreement.pdf"
                                        target="_blank"
                                        class="focus:outline-none font-medium text-indigo-600 transition duration-150 ease-in-out hover:text-indigo-500 focus:underline"
                                    >
                                        Ogav Agreement
                                    </a>
                                </p>
                            </template>
                        </base-checkbox>
                    </div>

                    <div class="mt-6" v-if="step === 0 && country.enabled">
                        <span
                            class="block w-full rounded-md shadow-sm"
                            :class="{ spinner: isLoading }"
                        >
                            <button
                                @click="progressStep"
                                :disabled="isLoading"
                                class="focus:outline-none flex w-full justify-center rounded-md border border-transparent bg-indigo-800 px-4 py-3 text-sm font-semibold text-white transition duration-150 ease-in-out hover:bg-indigo-900 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                            >
                                Next
                            </button>
                        </span>
                    </div>

                    <div v-if="step === 1 || !country.enabled" class="mt-6">
                        <span
                            class="block w-full rounded-md shadow-sm"
                            :class="{ spinner: isLoading }"
                        >
                            <button
                                @click="signup"
                                :disabled="isLoading"
                                class="focus:outline-none flex w-full justify-center rounded-md border border-transparent bg-indigo-800 px-4 py-3 text-sm font-semibold text-white transition duration-150 ease-in-out hover:bg-indigo-900 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                            >
                                {{
                                    country.enabled
                                        ? 'Create your account'
                                        : 'Join the waiting list'
                                }}
                            </button>
                        </span>
                    </div>
                </div>
            </div>

            <div
                v-else
                class="border border-gray-100 bg-white px-4 py-8 shadow-xl sm:rounded-md sm:px-10 sm:py-10 md:px-16 md:py-16"
            >
                <h1 class="text-xl text-gray-600">Thanks!</h1>
            </div>

            <div>
                <p
                    class="max-w mt-8 pl-3 text-left text-sm leading-5 text-gray-600"
                >
                    Already have an account?
                    <router-link
                        :to="{ name: 'login' }"
                        class="focus:outline-none font-medium text-indigo-600 transition duration-150 ease-in-out hover:text-indigo-500 focus:underline"
                    >
                        Log in
                    </router-link>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { minLength } from 'vuelidate/lib/validators';
import apiService from './../helpers/api';
import COUNTRIES_JSON from './../helpers/countries.json';
import POS_SYSTEMS_JSON from './../helpers/posSystems.json';

export default {
    name: 'SignupView',
    metaInfo: {
        title: 'Signup'
    },
    validations: {
        user: {
            password: {
                minLength: minLength(6)
            }
        }
    },
    data() {
        return {
            isLoading: false,
            emailUnique: null,
            termsAgreed: false,
            termsError: false,
            step: 0,
            country: {
                code: 'GB',
                name: 'United Kingdom',
                emoji: '🇬🇧',
                enabled: true
            },
            countries: COUNTRIES_JSON,
            posSystems: POS_SYSTEMS_JSON,
            outOfGeographySuccesMessage: false,
            referrerCode: null,
            user: {
                email: '',
                password: '',
                name: '',
                phone: '',
                businessName: '',
                posSystem: null,
                locations: null,
                hearAboutUs: null
            }
        };
    },
    async mounted() {
        await this.getOAuthInfo();
        if (this.$tracker) {
            this.$tracker.start();
        }
        if (this.$route.params.referrerCode) {
            this.referrerCode = this.$route.params.referrerCode;
        }
    },
    methods: {
        progressStep() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return false;
            }
            this.step = 1;
        },
        signup() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return false;
            }

            if (!this.country.enabled) {
                this.outOfGeography();

                return;
            }

            if (this.isOgav && !this.termsAgreed) {
                this.termsError = true;

                return;
            }

            this.isLoading = true;
            this.$store
                .dispatch('user/SIGNUP', {
                    email: this.user.email,
                    password: this.user.password,
                    name: this.user.name,
                    phone: this.user.phone,
                    partner: {
                        id: process.env.VUE_APP_PARTNER_ID || 1
                    },
                    referral: this.referrerCode,
                    businessName: this.user.businessName,
                    hubspotId: this.$route.query?.hubspotId || null,
                    posSystem: this.user.posSystem
                        ? this.user.posSystem.name
                        : null,
                    locations: this.user.locations,
                    hearAboutUs: this.user.hearAboutUs
                })
                .then(() => {
                    apiService.setHeader();

                    this.$router.push({
                        name: 'createVenue',
                        query: {
                            login_challenge:
                                this.$route.query.login_challenge || undefined
                        }
                    });

                    const user = this.$store.state.user.profile;

                    if (window.analytics) {
                        window.analytics.group(user.accounts[0].id, {
                          name: user.accounts[0].name,
                          domain: user.accounts[0].domain
                        });
                        window.analytics.identify(user.id, {
                            name: user.name,
                            phone: user.phone,
                            email: user.email,
                            companyName: user.accounts[0].name,
                            referrerCode: this.referrerCode,
                            createdAt: user.created_at,
                            product: 'Takeaway',
                            address: {
                                country: this.country.code
                            }
                        });
                        window.analytics.track('Account Created', {
                            accountName: user.accounts[0].name,
                            account_name: user.accounts[0].name,
                            accountId: user.accounts[0].id,
                            context: {
                                groupId: user.accounts[0].id
                            }
                        });
                        window.analytics.track('User Signed Up', {
                            accountId: user.accounts[0].id,
                            context: {
                                groupId: user.accounts[0].id
                            }
                        });

                        if (this.referrerCode === 'opentable') {
                          window.analytics.track('Opentable Signup', {
                            accountId: user.accounts[0].id,
                            context: {
                              groupId: user.accounts[0].id
                            }
                          });
                        }
                    }

                    if (this.$tracker) {
                        this.$tracker.setUserID(user.email);
                    }

                    function trackingListener() {
                        const capterra_vkey =
                            '5758888077f67e3910c4f1921e05e354';
                        const capterra_vid = '2145289';
                        var ct = document.createElement('img');
                        ct.src =
                            'https://ct.capterra.com/capterra_tracker.gif?vid=' +
                            capterra_vid +
                            '&vkey=' +
                            capterra_vkey;
                        document.body.appendChild(ct);
                    }

                    trackingListener();
                })
                .catch(e => {
                    if (e.data) {
                        this.emailUnique = e.data.message;
                    }

                    this.step = 0;

                    throw new Error(`API ${e}`);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        async outOfGeography() {
            this.isLoading = true;
            const ZAPIER_OOG_WEBHOOK = process.env.VUE_APP_ZAPIER_OOG_WEBHOOK;
            const payload = {
                ...this.user,
                country: this.country
            };
            try {
                await this.$axios.post(ZAPIER_OOG_WEBHOOK, payload, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    transformRequest: [
                        (data, headers) => {
                            delete headers.common['Authorization'];
                            delete headers.common['Timezone'];
                            return data;
                        },
                        ...this.$axios.defaults.transformRequest
                    ]
                });
                this.outOfGeographySuccesMessage = true;
                if (window.analytics) {
                    window.analytics.identify({
                        name: this.user.name,
                        email: this.user.email,
                        phone: this.user.phone,
                        address: {
                            country: this.country.code
                        }
                    });
                    window.analytics.track('Country Waiting List Joined', {
                        country: this.country.code,
                        accountId: this.user.accounts[0].id,
                        context: {
                            groupId: this.user.accounts[0].id
                        }
                    });
                }
            } catch (error) {
                throw new Error(`API Response ${error}`);
            } finally {
                this.isLoading = false;
            }
        },
        async getOAuthInfo() {
            if (!this.$route.query.login_challenge) {
                return;
            }

            try {
                const { data } = await this.$axios.get(
                    `/oauth/get-login-info`,
                    {
                        params: {
                            challenge: this.$route.query.login_challenge
                        }
                    }
                );

                if (data.name) {
                    this.user.businessName = data.name;
                }
            } catch (error) {
                throw new Error(`API ${error}`);
            }
        }
    },
    computed: {
        whitelabelLogoUrl() {
            return process.env.VUE_APP_WIHTELABEL_LOGO || null;
        },
        isOgav() {
            return (
                process.env.VUE_APP_WHITELABEL_PUBLIC_HOSTNAME ===
                'order.ogav.uk'
            );
        },
        isStoreKit() {
            return (
                process.env.VUE_APP_WHITELABEL_PUBLIC_HOSTNAME ===
                'order.storekit.com'
            );
        },
        isZettleReferral() {
            return this.$route.params.referrerCode === 'zettle';
        },
        isEposabilityClient() {
            return this.$route.params.referrerCode === 'eposability';
        },
        isOpentableReferral() {
            return this.$route.params.referrerCode === 'opentable';
        }
    }
};
</script>

<style scoped>
.spinner:after {
    left: auto;
    right: 1em;
    border-right-color: white;
}

.form-checkbox-error {
    -webkit-box-shadow: 0 0 0 3px rgba(254, 164, 164, 0.45);
    box-shadow: 0 0 0 3px rgba(254, 164, 164, 0.45);
    border-color: #fea4a4;
}

.primary-positive_svg__st0 {
    fill: #192550;
}
</style>
