<template>
    <div class="overflow-hidden rounded-md border bg-white py-12 sm:py-12">
        <div class="mx-auto max-w-7xl md:px-6 lg:px-8">
            <div
                class="grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-start"
            >
                <div class="px-6 lg:px-0 lg:pr-4 lg:pt-4">
                    <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
                        <div v-if="subtitle" class="flex text-indigo-800 sm:items-center">
                            <base-magic-icon class="mr-1 h-4 w-4" />
                            <h2 class="text-sm font-semibold leading-5">{{
                                subtitle
                            }}</h2>
                        </div>
                        <p
                            class="mt-2 font-heading text-2xl font-semibold leading-6 tracking-tight text-gray-900 sm:text-4xl"
                            >{{ title }}</p
                        >
                        <p class="mt-6 text-base leading-6 text-gray-600">{{
                            description
                        }}</p>
                        <dl
                            class="mt-8 max-w-xl space-y-5 text-sm leading-6 text-gray-600 lg:max-w-none"
                        >
                            <div
                                v-for="feature in features"
                                :key="feature.name"
                                class="relative pl-8"
                            >
                                <dt class="inline font-semibold text-gray-900">
                                    <base-tick-icon
                                        class="absolute left-1 top-1 h-5 w-5 text-indigo-800"
                                        aria-hidden="true"
                                    />
                                    {{ feature.name }}
                                </dt>
                                <dd class="inline">{{
                                    feature.description
                                }}</dd>
                            </div>
                        </dl>
                        <div
                            class="mt-8 flex flex-col sm:flex-row sm:items-center"
                        >
                            <slot name="cta"></slot>
                        </div>
                    </div>
                </div>
                <div class="self-center sm:px-6 lg:px-0">
                    <div
                        class="relative overflow-hidden rounded-md sm:shadow-md"
                    >
                        <img class="" :src="image" alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},

    props: {
        title: {
            type: String,
            required: true
        },
        subtitle: {
            type: String,
            required: true
        },
        description: {
            type: String,
            required: true
        },
        features: {
            type: Array,
            required: true
        },
        image: {
            type: String,
            required: false
        }
    }
};
// import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/vue/20/solid'
</script>
