<template>
    <div class="relative">
        <span class="text-sm text-gray-600">
            {{text}}
        </span>

        <div class="flex mb-2 items-center justify-between">
            <div>
                <span
                    class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-green-600 bg-green-200"
                >
                    {{ progress.currentStep }}
                </span>
            </div>
            <div class="text-right">
                <span class="text-xs font-semibold inline-block text-green-600">
                    {{ progress.percent }}%
                </span>
            </div>
        </div>
        <div class="overflow-hidden h-2 mb-4 text-xs flex rounded bg-green-200">
            <div
                class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"
                :style="`width: ${progress.percent}%`"
            ></div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ProgressBar',
    props: {
        text: {
            type: String,
            required: true
        },
        progress: {
            type: Object,
            required: true
        }
    }
};
</script>
