<template>
    <MglMap
        :accessToken="accessToken"
        :mapStyle="mapStyle"
        :center="coordinates"
        :zoom="15"
    >
        <MglMarker :coordinates="coordinates" color="blue" />
    </MglMap>
</template>

<script>
import Mapbox from 'mapbox-gl';
import { MglMap, MglMarker } from 'vue-mapbox';

export default {
    name: 'VenueMap',
    props: {
        coordinates: {
            type: Array,
            required: false,
            default: () => {
                return [-0.1381, 51.51];
            }
        }
    },
    components: {
        MglMap,
        MglMarker
    },
    data() {
        return {
            accessToken:
                'pk.eyJ1IjoiYmVubGFyY2V5c2siLCJhIjoiY2pycXZnOHp5MDRuMDQ0cHJ4ZWttMGxsYSJ9.MQY0Fnhodssbf7FMG0EiHw', // your access token. Needed if you using Mapbox maps
            mapStyle: 'mapbox://styles/benlarceysk/ck8enq76w032w1itcbgkkkrwc' // your map style
        };
    },

    created() {
        // We need to set mapbox-gl library here in order to use it in template
        this.mapbox = Mapbox;
    }
};
</script>

<style>
a.mapboxgl-ctrl-logo {
    display: none;
}
</style>
