<template>
    <div>
        <div
            v-if="!isLoading && customer"
            class="flex flex-col flex-wrap items-stretch"
        >
            <div class="p-3">
                <h5
                    class="break-anywhere mb-2 text-gray-700 text-sm font-bold leading-tight"
                >
                    {{ this.customer.firstname }} {{ this.customer.lastname || '' }}
                </h5>
                <span class="block mt-1.5 text-gray-500 text-sm">
                    {{ customer.orderCount }} orders
                </span>
                <a
                    :href="'mailto:' + customer.email"
                    target="_blank"
                    class="block mt-2 text-gray-600 hover:underline"
                    >{{ customer.email }}</a
                >
                <a
                    :href="'tel:' + customer.phone"
                    class="block mt-1 text-gray-600"
                    target="_blank"
                    >{{ customer.phone }}</a
                >
            </div>
            <div class="mt-3 p-3 border-t border-gray-200">
                <router-link
                    :to="{
                        name: 'customerDetail',
                        params: {
                            email: customerEmail
                        }
                    }"
                >
                    <button
                        type="button"
                        class="focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 inline-flex items-center justify-center px-3 py-1 text-gray-700 text-xs leading-4 hover:bg-gray-50 bg-white border border-gray-300 rounded-md focus:outline-none shadow-sm w-full text-center"
                    >
                        View customer
                    </button>
                </router-link>
            </div>
        </div>
        <div v-else>
            <div class="spinner p-3 h-20">
                <span class="text-gray-600"></span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CustomerDetailsTooltip',
    props: {
        customerEmail: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            isLoading: 0,
            customer: null
        };
    },
    async mounted() {
        this.fetchCustomer();
    },
    methods: {
        async fetchCustomer() {
            if (!this.customerEmail) {
                return;
            }

            try {
                this.isLoading++;
                const params = {
                    withLastOrderDate: true
                };
                const { data } = await this.$axios.get(
                    '/customers/' + this.customerEmail,
                    { params }
                );

                this.customer = data;
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading--;
            }
        }
    }
};
</script>

<style>
.v-popper__popper.customer-details-popover .v-popper__wrapper .v-popper__inner {
    width: 250px;
    background: #fff;
    padding: 0;
    color: black;
    border-radius: 5px;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
}

.v-popper__popper.customer-details-popover .v-popper__arrow-inner {
    border-color: #f9f9f9;
}
</style>
