<template>
    <div
        @click="copyToClipboard"
        style="background: #e8d1da"
        class="absolute top-0 z-30 flex w-full cursor-pointer flex-row items-center justify-center opacity-75 shadow-md transition-all sm:left-0 sm:mr-0 print:hidden"
        v-show="!hidden"
    >
        <div>
            <span class="text-xs font-medium uppercase text-gray-800"
                ><img
                    src="https://ucarecdn.com/e42f24cb-3823-4c01-88e1-c6e2ba7589c6/favicon.png"
                    alt=""
                    class="mr-2 inline w-5 py-1"
                />Super Admin:
            </span>
            <span class="mr-4 text-xs font-bold text-gray-800">
                {{ accountId }} ({{ accountName }})
            </span>

            <span
                class="mr-8 text-xs font-medium uppercase text-gray-800"
                @click="$store.commit('user/REMOVE_INTERNAL_FEATURE', true)"
                >view as normal use</span
            >
        </div>
        <div @click.stop="hidden = true">
            <base-small-close-icon class="cursor-pointer text-gray-800" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'SuperAdminNotification',
    props: {
        accountId: {
            type: String,
            required: false,
            default: 'null'
        },
        accountName: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {
            hidden: false
        };
    },
    methods: {
        async copyToClipboard() {
            await navigator.clipboard.writeText(this.accountId);
            this.$notify({
                group: 'settings',
                title: `Copied account ID: ${this.accountId} to clipboard 🙃`
            });
        }
    },
    created() {
        setTimeout(() => {
            this.hidden = true;
        }, 5000);
    }
};
</script>
