<template>
    <div>
        <div class="md:flex md:items-center md:justify-between">
            <div class="min-w-0 flex-1">
                <router-link
                    :to="{ name: 'editVenue' }"
                    class="mb-2 flex items-center text-sm text-gray-500 inline-flex"
                >
                    <base-back-icon />
                    <span>Settings</span>
                </router-link>
                <base-page-title title="Tables" />
            </div>

            <BaseSpinner :isLoading="isLoading" />
        </div>
        <tables :venue="venue" />

        <div class="my-6 ml-4 flex flex-shrink-0 justify-end">
            <span class="inline-flex rounded-md shadow-sm" @click="saveVenue">
                <button
                    type="button"
                    class="focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                    :disabled="isLoading"
                >
                    Save
                </button>
            </span>
        </div>
    </div>
</template>

<script>

import Tables from '../Tables';
export default {
    name: 'OrderAndPaySettings',
    props: ['venueId', 'venue'],
    metaInfo: {
        title: 'Order & Pay Settings'
    },
    data() {
        return {
            isLoading: false,
            primaryColour: '#161e2e',
            branding: { ...this.venue }
        };
    },
    components: { Tables },
    methods: {
        async saveVenue() {
            this.isLoading = true;

            try {
                const payload = {
                    orderInstructions: this.venue.orderInstructions,
                    tableDescriptor: this.venue.tableDescriptor,
                    tableScreenImage: this.venue.tableScreenImage
                };
                // eslint-disable-next-line
                const response = await this.$axios.put(
                    '/venues/' + this.venueId,
                    payload
                );
                this.$notify({
                    group: 'settings',
                    title: 'Settings saved'
                });
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        }
    }
};
</script>
