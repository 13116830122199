export const tags = [
    {
        label: 'Celery',
        value: 100
    },
    {
        label: 'Gluten',
        value: 101
    },
    {
        label: 'Crustaceans',
        value: 102
    },
    {
        label: 'Fish',
        value: 103
    },
    {
        label: 'Eggs',
        value: 104
    },
    {
        label: 'Lupin',
        value: 105
    },
    {
        label: 'Milk',
        value: 106
    },
    {
        label: 'Molluscs',
        value: 107
    },
    {
        label: 'Mustard',
        value: 108
    },
    {
        label: 'Nuts',
        value: 109
    },
    {
        label: 'Peanuts',
        value: 110
    },
    {
        label: 'Sesame',
        value: 111
    },
    {
        label: 'Soya',
        value: 112
    },
    {
        label: 'Sulphites',
        value: 113
    },
    {
        label: 'Almonds',
        value: 114
    },
    {
        label: 'Barley',
        value: 115
    },
    {
        label: 'Brazil Nuts',
        value: 116
    },
    {
        label: 'Cashew',
        value: 117
    },
    {
        label: 'Hazelnuts',
        value: 118
    },
    {
        label: 'Kamut',
        value: 119
    },
    {
        label: 'Macadamia',
        value: 120
    },
    {
        label: 'Oats',
        value: 121
    },
    {
        label: 'Pecan',
        value: 122
    },
    {
        label: 'Pistachio',
        value: 123
    },
    {
        label: 'Rye',
        value: 124
    },
    {
        label: 'Spelt',
        value: 125
    },
    {
        label: 'Walnuts',
        value: 126
    },
    {
        label: 'Wheat',
        value: 127
    },
    {
        label: 'No allergens',
        value: 1000
    },
    {
        label: 'Alcohol',
        value: 1
    },
    {
        label: 'Halal',
        value: 2
    },
    {
        label: 'Kosher',
        value: 3
    },
    {
        label: 'Vegan',
        value: 4
    },
    {
        label: 'Vegetarian',
        value: 5
    },
    {
        label: 'Gluten Free',
        value: 10
    }
];
