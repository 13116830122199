<template>
    <div>
        <div class="mt-1 flex" v-if="transaction.paymentDetails">
            <img
                class="h-6 rounded-md border border-gray-100"
                src="@/assets/visa.svg"
                alt="Visa"
                v-if="transaction.paymentDetails.paymentMethod === 'visa'"
            />
            <img
                class="h-6 rounded-md border border-gray-100"
                src="@/assets/mc.svg"
                alt="Mastercard"
                v-else-if="transaction.paymentDetails.paymentMethod === 'mc'"
            />
            <img
                class="h-6 rounded-md border border-gray-100"
                src="@/assets/amex.svg"
                alt="American Express"
                v-else-if="transaction.paymentDetails.paymentMethod === 'amex'"
            /><img
                class="h-6 rounded-md border border-gray-100"
                src="@/assets/applepay.svg"
                alt="Apple Pay"
                v-else-if="
                    transaction.paymentDetails.paymentMethod ===
                        'visa_applepay' ||
                    'amex_applepay' ||
                    'mc_applepay'
                "
            />

            <p
                class="mt-1 hidden pl-2 text-sm leading-5 text-gray-500 sm:block"
            >
                {{
                    transaction.paymentDetails.untokenisedCardSummary ||
                    transaction.paymentDetails.cardSummary ||
                    '••••'
                }}
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BillPaymentTransactions',
    props: {
        transaction: {
            type: Object,
            required: true
        }
    }
};
</script>
