<template>
    <div class="ml-2 py-12 text-sm font-medium leading-5 text-gray-700">
        Logging in to Featurebase, please wait...
    </div>
</template>

<script>
export default {
    name: 'featurebaseSSO',
    metaInfo: {
        title: 'Featurebase SSO'
    },
    mounted() {
        this.loginToFeaturebase();
    },
    watch: {
        '$store.state.user.profile.featureBaseToken': function () {
            this.loginToFeaturebase();
        }
    },
    methods: {
        loginToFeaturebase() {
            const token = this.$store.state.user?.profile?.featureBaseToken;
            const baseUrl = 'https://feedback.storekit.com';

            if (!token) {
                return;
            }

            window.open(
                `${baseUrl}/api/v1/auth/access/jwt?jwt=${token}&return_to=${baseUrl}`,
                '_self'
            );
        }
    }
};
</script>
