import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import user from './user';
import ui from './ui';
import allOrders from './allOrders';
import venues from './venues';
import report from './report';

export default new Vuex.Store({
    modules: {
        user,
        ui,
        allOrders,
        venues,
        report
    }
});
