var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{attrs:{"name":"sort-categories-modal","width":"600px","height":"auto","clickToClose":true,"adaptive":true},on:{"before-open":_vm.beforeOpen}},[_c('div',{staticClass:"flex h-12 items-center justify-between px-4 py-3 text-gray-600"},[_c('h1',{staticClass:"font-heading text-lg font-medium text-gray-900"},[_vm._v("Sort categories ")]),_c('button',{on:{"click":function($event){return _vm.$modal.hide('sort-categories-modal')}}},[_c('base-close-icon',{staticClass:"h-6 w-6"})],1)]),_c('div',{staticClass:"h-5/6 overflow-scroll bg-gray-100 p-4"},_vm._l((_vm.categories),function(category){return _c('div',{key:`${category.id}`,staticClass:"border-slate-300 flex h-10 items-center border-2 bg-white"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"mr-2 flex items-center"},[_c('base-ellipsis-vertical-icon'),_c('base-ellipsis-vertical-icon',{staticClass:"-ml-3"})],1),_c('span',{staticClass:"sm:text-md text-sm font-medium leading-6 text-gray-700"},[_vm._v(" "+_vm._s(category.name)+" ")])]),_c('div',{staticClass:"mr-4 flex flex-1 justify-end"},[_c('button',{attrs:{"disabled":category.index === _vm.categories.length - 1
                            ? true
                            : false},on:{"click":function($event){return _vm.down(category.index)}}},[_c('base-arrow-down-icon',{class:category.index === _vm.categories.length - 1
                                ? 'cursor-not-allowed opacity-25'
                                : ''})],1),_c('button',{staticClass:"mx-2",attrs:{"disabled":category.index === 0 ? true : false},on:{"click":function($event){return _vm.up(category.index)}}},[_c('base-arrow-up-icon',{class:category.index === 0
                                ? 'cursor-not-allowed opacity-25'
                                : ''})],1)])])}),0),_c('div',{staticClass:"flex justify-between px-2 py-3"},[_c('button',{staticClass:"focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm",on:{"click":function($event){return _vm.$modal.hide('sort-categories-modal')}}},[_vm._v(" Close ")]),_c('button',{staticClass:"focus:outline-none inline-flex items-center rounded-md border border-indigo-300 bg-indigo-600 px-4 py-2 text-xs font-medium text-white shadow-sm hover:bg-indigo-500 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm",on:{"click":_vm.save}},[_vm._v(" Save edits ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }