<template>
    <modal
        name="delete-printer-modal"
        width="600px"
        height="auto"
        :clickToClose="true"
        :adaptive="true"
        @before-open="beforeOpen"
    >
        <div
            class="bg-white rounded-lg shadow-xl overflow-hidden transform transition-all sm:w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
        >
            <div class="pb-4 pt-5 px-4 bg-white sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start">
                    <div
                        class="flex flex-shrink-0 items-center justify-center mx-auto w-12 h-12 bg-red-100 rounded-full sm:mx-0 sm:w-10 sm:h-10"
                    >
                        <svg
                            class="w-6 h-6 text-red-600"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                            />
                        </svg>
                    </div>
                    <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <h3
                            class="text-gray-900 text-lg font-medium leading-6"
                            id="modal-headline"
                        >
                            Delete Printer
                        </h3>
                        <div class="mt-2">
                            <p class="text-gray-500 text-sm leading-5">
                                Are you sure you want to delete this printer ?
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="px-4 py-3 bg-gray-50 sm:flex sm:flex-row-reverse sm:px-6"
            >
                <span
                    class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto"
                    @click="removePrinter(true)"
                >
                    <button
                        type="button"
                        class="inline-flex justify-center px-4 py-2 w-full text-white text-base font-medium leading-6 hover:bg-red-500 bg-red-600 border focus:border-red-700 border-transparent rounded-md focus:outline-none focus:shadow-outline-red shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    >
                        Delete Printer
                    </button>
                </span>
                <span
                    class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto"
                    @click="removePrinter(false)"
                >
                    <button
                        type="button"
                        class="inline-flex justify-center px-4 py-2 w-full text-white text-base font-medium leading-6 hover:bg-yellow-500 bg-yellow-600 border focus:border-yellow-700 border-transparent rounded-md focus:outline-none focus:shadow-outline-red shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    >
                        Disconnect Printer
                    </button>
                </span>
                <span
                    class="flex mt-3 w-full rounded-md shadow-sm sm:mt-0 sm:w-auto"
                    @click="hideModal"
                >
                    <button
                        type="button"
                        class="inline-flex justify-center px-4 py-2 w-full hover:text-gray-500 text-gray-700 text-base font-medium leading-6 bg-white border focus:border-blue-300 border-gray-300 rounded-md focus:outline-none focus:shadow-outline-blue shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    >
                        Cancel
                    </button>
                </span>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    name: 'DeletePrinterModal',
    data() {
        return {
            mac: '',
            venueId: ''
        };
    },
    methods: {
        beforeOpen(event) {
            const { mac, venueId } = event.params;
            this.mac = mac;
            this.venueId = venueId;
        },
        hideModal() {
            this.$modal.hide('delete-printer-modal');
        },
        async removePrinter(isHardDelete) {
            const data = { venueId: this.venueId, isHardDelete };

            try {
                await this.$axios.delete(`/printers/${this.mac}`, { data });

                this.$emit('fetch-printers');

                this.hideModal();
            } catch (e) {
                throw new Error(`API ${e}`);
            }
        }
    }
};
</script>
