<template>
    <div
        class="focus:outline-none relative z-0 flex-1 overflow-y-auto pb-6 pt-2 md:py-6"
    >
        <base-toggle-sidebar-button />

        <div
            class="mx-auto flex max-w-7xl flex-row items-center justify-between px-4 sm:px-6 lg:px-8"
        >
            <base-page-title title="Resources" />
            <BaseSpinner :isLoading="false" />
        </div>
        <div class="mx-auto max-w-7xl px-4 pb-24 pt-8 sm:px-6 md:px-8 lg:px-8">
            <div>
                <h2>Book a photo shoot</h2>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ResourcesScreen'
};
</script>

<style scoped></style>
