var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.embedded)?_c('div',{staticClass:"mb-4 print:hidden"},[_c('div',[_c('BasePageTitle',{attrs:{"title":"Feedback overview"}})],1),_c('div',{staticClass:"mt-1 flex flex-col sm:mt-2 sm:flex-row sm:flex-wrap"},[_c('PrintButton'),_c('ExportButton',{on:{"clicked":_vm.showExportCsvModal}})],1)]):_vm._e(),_c('div',{staticClass:"relative mb-4 mt-2 flex items-center print:hidden"},[_c('DateRangeSelector',{on:{"date-updated":_vm.updateReport}})],1),_c('div',{staticClass:"mb-4 flex justify-between gap-10 rounded-sm bg-white p-4 shadow"},[_c('div',{staticClass:"flex w-full max-w-2xl flex-col gap-8"},[_c('div',{staticClass:"flex flex-col gap-3"},[(_vm.average)?_c('div',[_c('span',{staticClass:"text-2xl font-extrabold"},[_vm._v(" Your overall rating: "+_vm._s(_vm.average)+" ")]),_c('span',{staticClass:"ml-1"},[_vm._v(" ( "+_vm._s(_vm.ratingTotalCount > 50 ? '50+' : _vm.ratingTotalCount)+" "+_vm._s(_vm.ratingTotalCount > 1 ? 'reviews' : 'review')+" ) ")])]):_vm._e(),_c('span',[_vm._v(" Here's how customers rated you. ")])]),_c('div',{staticClass:"flex w-full flex-col-reverse gap-2"},_vm._l((5),function(rating){return _c('div',{key:rating,staticClass:"flex w-full gap-2"},[_c('div',{staticClass:"flex w-9 items-center justify-between"},[_c('span',{staticClass:"text-green-600"},[_vm._v(_vm._s(rating))]),_c('star-icon',{attrs:{"size":"sm","color":"#059669"}})],1),_c('div',{staticClass:"flex h-full w-full items-center justify-center px-1"},[_c('div',{staticClass:"h-2 w-full overflow-hidden rounded-md bg-gray-200 shadow-inner"},[(_vm.distribution)?_c('div',{staticClass:"h-full bg-green-600",style:(`width: ${_vm.getRatingPercentageRatio(
                                    rating
                                )}%`)}):_vm._e()])]),_c('div',{staticClass:"w-10 text-right"},[(_vm.distribution)?_c('span',{staticClass:"text-gray-500"},[_vm._v(" "+_vm._s(_vm.getRatingPercentageRatio(rating))+"% ")]):_vm._e()])])}),0)]),_c('div',[_c('h3',{staticClass:"text-right text-3xl font-bold text-gray-700"},[_vm._v(" "+_vm._s(_vm.reviewedPercentage || 0)+"% ")]),_c('span',{staticClass:"text-sm text-gray-600"},[_vm._v("Of requests received feedback")])])]),_c('div',{staticClass:"mt-8 bg-white shadow"},[(!_vm.isLoading && _vm.reportData && _vm.reportData.length)?_c('vue-good-table',{staticClass:"border-b border-gray-200",attrs:{"isLoading":_vm.isLoading,"styleClass":"tailwind-table","pagination-options":{
                enabled: false,
                perPage: 100
            },"columns":_vm.columns,"rows":_vm.reportData},on:{"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'rating')?_c('span',[_c('div',{staticClass:"bg-orange-50 flex h-8 w-10 items-center justify-center rounded-lg text-yellow-300"},[_c('svg',{staticClass:"h-4 w-4",attrs:{"fill":"currentColor","viewBox":"0 0 20 20","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"}})]),_c('span',{staticClass:"block text-sm font-medium text-gray-600"},[_vm._v(_vm._s(props.formattedRow[props.column.field]))])])]):(props.column.field === 'venueId')?_c('span',[_c('div',[_c('span',{staticClass:"block"},[_vm._v(_vm._s(_vm.venueById( props.formattedRow[props.column.field] ).name))])])]):(
                        props.column.field === 'email' && props.row.email
                    )?_c('span',[_c('CustomerDetailsTableItem',{attrs:{"email":props.row.email}})],1):(props.column.field === 'comments')?_c('span',[(!props.row.comments)?_c('span',{staticClass:"px-4 text-gray-300"},[_vm._v("No comments")]):_c('div',[_c('v-dropdown',{attrs:{"distance":"16","triggers":['click'],"popperClass":"review-comments-popover"},scopedSlots:_vm._u([{key:"popper",fn:function(){return [_c('ReviewCommentsTooltip',{attrs:{"comments":props.row.comments}})]},proxy:true}],null,true)},[_c('div',{staticClass:"group flex cursor-pointer select-none items-center rounded-md px-4 py-2 hover:bg-gray-100"},[_c('span',{staticClass:"mr-2 block line-clamp-2"},[_vm._v(" "+_vm._s(props.formattedRow[ props.column.field ])+" ")]),_c('span',{staticClass:"m-auto block h-4 w-4 text-gray-600 opacity-0 group-hover:opacity-100"},[_c('svg',{attrs:{"viewBox":"0 0 20 20","fill":"currentColor","focusable":"false","aria-hidden":"true"}},[_c('path',{attrs:{"d":"M5 8l5 5 5-5H5z"}})])])])])],1)]):(
                        props.column.field === 'referenceId' &&
                        props.row.referenceId
                    )?_c('span',[_c('base-button',{attrs:{"button-text":props.formattedRow[props.column.field].length >
                            10
                                ? 'View Bill'
                                : 'View Order',"size":"sm"},nativeOn:{"click":function($event){return _vm.navigateToReference($event, props)}}})],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,false,332304990)}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }