var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-auto max-w-2xl px-6 sm:px-12",class:{ 'mt-10': _vm.$route.name !== 'manageAdyenAccountHolder' }},[_c('div',{staticClass:"md:flex md:items-center md:justify-between"},[_c('div',{staticClass:"min-w-0 flex-1"},[_c('router-link',{staticClass:"mb-2 flex items-center text-sm text-gray-500",attrs:{"to":{
                    name:
                        _vm.$route.name === 'manageAdyenAccountHolder'
                            ? 'paymentSettings'
                            : 'dashboard'
                }}},[_c('base-back-icon'),_c('span',[_vm._v(" "+_vm._s(_vm.$route.name === 'manageAdyenAccountHolder' ? 'Payment settings' : 'Dashboard')+" ")])],1)],1),_c('BaseSpinner',{attrs:{"isLoading":_vm.isLoading}})],1),_c('div',{staticClass:"mb-5 rounded-md border border-blue-200 bg-blue-50 p-4"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex-shrink-0"},[_c('svg',{staticClass:"h-5 w-5 text-blue-400",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor","aria-hidden":"true"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z","clip-rule":"evenodd"}})])]),(!_vm.adyenAccountHolder.active)?_c('div',{staticClass:"ml-3"},[_c('h3',{staticClass:"text-sm font-medium text-blue-800"},[_vm._v(" Information required ")]),_c('div',{staticClass:"mt-2 text-sm text-blue-700"},[_c('p',[_vm._v(" We need to collect some more information about your business before you can start taking payments ")]),_c('span',{staticClass:"mt-2 inline-flex rounded-md shadow-sm",on:{"click":_vm.fetchAdyenAccountHolderOnboardingUrl}},[_c('button',{staticClass:"focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700",class:{
                                spinner: _vm.onbardingUrlisLoading
                            },attrs:{"type":"button","disabled":_vm.onbardingUrlisLoading}},[_vm._v(" Complete account setup ")])])])]):_c('div',{staticClass:"ml-3"},[_c('h3',{staticClass:"text-sm font-medium text-blue-800"},[_vm._v(" Manage your account ")]),_c('div',{staticClass:"mt-2 text-sm text-blue-700"},[_c('p'),_c('span',{staticClass:"mt-2 inline-flex rounded-md shadow-sm",on:{"click":_vm.fetchAdyenAccountHolderOnboardingUrl}},[_c('button',{staticClass:"focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700",class:{
                                spinner: _vm.onbardingUrlisLoading
                            },attrs:{"type":"button","disabled":_vm.onbardingUrlisLoading}},[_vm._v(" Edit account setup ")])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }