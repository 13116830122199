<template>
    <div>
        <div class="md:flex md:items-center md:justify-between">
            <div class="min-w-0 flex-1">
                <router-link
                    :to="{ name: 'editVenue' }"
                    class="mb-2 flex items-center text-sm text-gray-500 inline-flex"
                >
                    <base-back-icon />
                    <span>Settings</span>
                </router-link>
                <base-page-title title="Instagram Integration" />
            </div>

            <BaseSpinner :isLoading="isLoading" />
        </div>
        <div>
            <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                            >Details</h3
                        >
                        <p class="mt-2 text-sm text-gray-500">
                            Enter your Instagram data. We'll embed your photos
                            to your store
                        </p>
                    </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0">
                    <div class="shadow sm:overflow-hidden sm:rounded-md">
                        <div
                            v-if="isInstagramAuthorized"
                            class="my-6 text-center"
                        >
                            <div v-if="photos && photos.length">
                                <div class="p-2"> Your Last Photos: </div>
                                <div
                                    class="mr-2 mt-5 grid grid-cols-2 items-center justify-center text-center"
                                >
                                    <div
                                        v-for="photo in photos"
                                        :key="photo.id"
                                    >
                                        <img
                                            :src="photo.media_url"
                                            alt="instagram photo"
                                            class="px-4 py-2"
                                        />
                                    </div>
                                </div>
                            </div>

                            <span v-else class="rounded-md text-green-800">
                                Authorized Successfully
                            </span>
                        </div>
                        <div
                            v-else
                            class="my-6 flex flex-shrink-0 justify-center"
                        >
                            <span
                                class="inline-flex rounded-md shadow-sm"
                                @click="getAuthorizationUrl"
                            >
                                <button
                                    type="button"
                                    class="focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-green-500 focus:border-green-700 focus:shadow-outline-indigo active:bg-green-700"
                                    :disabled="isLoading"
                                >
                                    Get Authorization
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <BaseSpacerWithRuler />
    </div>
</template>

<script>
export default {
    name: 'InstagramSettings',
    metaInfo: {
        title: 'Instagram Integration'
    },
    props: {
        venueId: {
            type: Number,
            required: true
        },
        venue: {
            type: Object,
            required: true
        }
    },
    computed: {
        isInstagramAuthorized() {
            return !!this.venue.integrations?.instagram?.accessToken;
        }
    },
    data() {
        return {
            isLoading: false,
            photos: []
        };
    },
    mounted() {
        if (this.isInstagramAuthorized) {
            this.getInstagramMedia();
        }
    },
    methods: {
        async getAuthorizationUrl() {
            try {
                this.isLoading = true;

                const params = {
                    venueId: this.venueId
                };

                const { data: url } = await this.$axios.get(
                    `/instagram/authorization-url`,
                    {
                        params
                    }
                );

                window.location.replace(url);
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        },
        async getInstagramMedia() {
            try {
                this.isLoading = true;

                const params = {
                    venueId: this.venueId
                };

                const { data } = await this.$axios.get(`/instagram/media`, {
                    params
                });

                this.photos = data;
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        }
    }
};
</script>
