<template>
  <div
      class="focus:outline-none relative z-0 flex-1 overflow-y-auto pb-6 pt-2 md:py-6"
  >
    <div class="mx-auto max-w-8xl pt-8">
      <FeaturePanelWithScreenshot
          title="Faster checkout with integrated guest WiFi"
          description="Integrate with your Captive WiFi account to help guests checkout faster when they connect through your guest WiFi."
          :features="captiveWifiFeatures"
          image="https://ucarecdn.com/39ad54c6-8a8b-4a5f-a153-0186e5654c4b/-/format/auto/"
      >
        <template #cta>
          <base-button
              class="mt-2 sm:ml-3 sm:mt-0"
              href="https://captivewifi.io"
              buttonText="Learn more"
          >
            <template #left-icon>
              <base-doc-icon class="mr-2 h-4 w-4" />
            </template>
          </base-button>
        </template>
      </FeaturePanelWithScreenshot>
    </div>
  </div>
</template>

<script>
import FeaturePanelWithScreenshot from "@/components/widgets/FeaturePanelWithScreenshot.vue";

export default {
  name: 'captiveWifiSettings',
  components: { FeaturePanelWithScreenshot },
  data() {
    return {
      captiveWifiFeatures: [
        {
          name: 'Pre-fill guest information.',
          description:
              'When a guest connects through your guest WiFi, we will pre-fill their information in the checkout flow.'
        }
      ]
    }
  }
}
</script>