<template>
    <div class="relative mb-3 rounded-md bg-sk-black" v-if="visible">
        <div class="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8">
            <div class="pr-16 sm:px-16 sm:text-center">
                <p class="text-sm font-medium text-white">
                    <span class="inline">
                        {{ text }}
                    </span>
                    <span class="block sm:ml-2 sm:inline-block">
                        <slot />
                    </span>
                </p>
            </div>
            <div
                v-if="dismissable"
                class="absolute inset-y-0 right-0 flex items-start pt-1 pr-1 sm:items-start sm:pt-1 sm:pr-2"
            >
                <button
                    @click="visible = false"
                    type="button"
                    class="focus:outline-none flex rounded-md p-2 hover:bg-indigo-500 focus:ring-2 focus:ring-white"
                >
                    <span class="sr-only">Dismiss</span>
                    <svg
                        class="h-6 w-6 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        aria-hidden="true"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BaseAnnouncementBanner',
    data() {
        return {
            visible: true
        };
    },
    props: {
        text: {
            type: String,
            required: true
        },
        dismissable: {
            type: Boolean,
            required: false,
            default: false
        }
    }
};
</script>
