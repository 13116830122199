<template>
    <tbody class="bg-white divide-y divide-gray-200">
        <slot />
    </tbody>
</template>

<script>
import { ContainerMixin } from 'vue-slicksort';

export default {
    name: 'ModifierGroupListContainer',
    mixins: [ContainerMixin]
};
</script>
