var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.areZonesLoading)?_c('div',{staticClass:"mb-0.5 mt-3 flex h-16 items-center justify-center"},[_c('BaseSpinner',{attrs:{"isLoading":_vm.areZonesLoading}})],1):[_vm._l((_vm.zoneTypes),function(zoneType){return _c('div',{key:zoneType.key},[(_vm.zones[zoneType.key]?.length > 0)?_c('div',{staticClass:"mb-2 mt-4"},[_c('div',{staticClass:"flex w-full cursor-pointer items-center bg-gray-200 px-4 py-2 text-sm",on:{"click":function($event){zoneType.show = !zoneType.show}}},[_c('h3',{staticClass:"flex flex-1 justify-center"},[_vm._v(" "+_vm._s(zoneType.title))]),_c('base-chevron-icon',{class:{ 'rotate-180': zoneType.show }})],1),_c('div',{directives:[{name:"auto-animate",rawName:"v-auto-animate"}]},[(zoneType.show)?_c('ul',{staticClass:"p-4"},_vm._l((_vm.zones[zoneType.key]),function(zone){return _c('li',{key:zone.id,staticClass:"flex cursor-pointer items-center border-b border-gray-200",on:{"mouseenter":function($event){return _vm.handleZoneOpacity(zone.id, 0.6)},"mouseleave":function($event){return _vm.handleZoneOpacity(zone.id, 0.25)},"click":function($event){return _vm.$emit(
                                'toggle-edit-mode',
                                zone,
                                zoneType.key
                            )}}},[_c('div',{staticClass:"flex flex-col"},[_c('span',{staticClass:"text-sm font-medium"},[_vm._v(" "+_vm._s(zone.name))]),(
                                        zoneType.key === 'drivingDistanceZones'
                                    )?_c('span',{staticClass:"text-xs"},[_vm._v(" Driving distance: "+_vm._s(zone.radius)+" mi ")]):_vm._e(),_c('span',{staticClass:"mb-2 text-xs"},[_vm._v(" Fee: "),_c('span',{staticClass:"font-mono"},[_vm._v(" "+_vm._s(_vm._f("currency")((zone.deliveryFee / 100)))+" ")]),(zone.minDeliveryAmount)?_c('span',[_vm._v(" Min: "),_c('span',{staticClass:"font-mono"},[_vm._v(" "+_vm._s(_vm._f("currency")((zone.minDeliveryAmount / 100)))+" ")])]):_vm._e()])]),_c('div',{staticClass:"flex flex-1 justify-end"},[_c('base-button',{attrs:{"buttonText":"Edit","size":"sm"}})],1)])}),0):_vm._e()])]):_vm._e()])}),_c('div',{staticClass:"mt-4 flex w-full justify-end p-4"},[_c('base-button',{attrs:{"buttonText":"Add new zone","size":"sm","button-style":"action"},on:{"clicked":function($event){return _vm.$emit('toggle-add-mode')}}})],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }