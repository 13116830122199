var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-selector"},[_c('div',{staticClass:"relative shadow-sm"},[_c('multi-select',{ref:"multiselect",staticClass:"cursor-pointer",class:{
                active: _vm.selectedVenues,
                'sm-selector': _vm.size === 'sm'
            },attrs:{"options":_vm.venuesOptions,"multiple":_vm.multipleSelect,"close-on-select":!_vm.multipleSelect,"searchable":false,"preselect-first":!_vm.allowEmpty,"deselectLabel":_vm.venues.length > 2 ? 'Remove' : ' ',"selectLabel":_vm.venues.length > 2 ? 'Select' : ' ',"allow-empty":_vm.allowEmpty,"maxHeight":400,"label":"name","track-by":"id"},on:{"input":function($event){!_vm.onClose ? _vm.updateSelection(_vm.selectedVenues) : null},"close":function($event){_vm.onClose ? _vm.updateSelection(_vm.selectedVenues) : null}},scopedSlots:_vm._u([{key:"selection",fn:function({ values }){return (_vm.multipleSelect)?[(values.length)?_c('span',{staticClass:"ml-1 font-medium text-gray-700",class:{ 'text-xs': _vm.size === 'sm' }},[_vm._v(" "+_vm._s(values.length)+" "+_vm._s(values.length > 1 ? 'stores' : 'store')+" selected ")]):_vm._e()]:undefined}},{key:"singleLabel",fn:function({ option }){return [_c('span',{staticClass:"truncate font-medium text-gray-700",class:{ 'text-xs': _vm.size === 'sm' }},[_vm._v(" "+_vm._s(_vm._f("truncateString")(option.adminName || option.name,_vm.truncateLength))+" ")])]}},{key:"option",fn:function(props){return [(!props.option.selectAllOption)?_c('div',{staticClass:"flex items-center justify-start"},[(!props.option.id && _vm.allowEmpty)?_c('svg',{staticClass:"mr-2 inline-block h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500",attrs:{"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M4 6h16M4 10h16M4 14h16M4 18h16"}})]):_c('span',{staticClass:"mr-2 inline-block h-2 w-2 flex-shrink-0 rounded-full",class:{
                            'bg-green-400': props.option.enabled,
                            'bg-gray-200': !props.option.enabled
                        },attrs:{"aria-label":props.option.enabled ? 'Online' : 'Offline'}}),_c('div',{staticClass:"leading-2 relative inline-block h-4 w-48 overflow-hidden whitespace-nowrap",class:{
                            'scroll-truncated':
                                props.option.name.length > 30
                        }},[_c('span',{staticClass:"absolute translate-y-0 transform whitespace-nowrap pr-3",class:{
                                'line-through': props.option.archived
                            }},[_vm._v(" "+_vm._s(props.option.adminName || props.option.name)+" ")])])]):_c('div',{staticClass:"flex items-center justify-center"},[_c('span',{staticStyle:{"font-weight":"500 !important"}},[_vm._v(" "+_vm._s(props.option.adminName || props.option.name)+" ")])])]}}],null,true),model:{value:(_vm.selectedVenues),callback:function ($$v) {_vm.selectedVenues=$$v},expression:"selectedVenues"}},[_c('template',{slot:"placeholder"},[_c('span',{staticClass:"ml-1 select-none font-medium font-medium text-gray-700",class:{ 'text-xs': _vm.size === 'sm' }},[_vm._v(" "+_vm._s(_vm.placeholder)+" ")])]),_c('template',{slot:"caret"},[_c('div',{staticClass:"absolute right-0 top-0 flex min-h-full items-center px-3"},[_c('svg',{staticClass:"h-5 w-5",class:{ 'h-4 w-4': _vm.size === 'sm' },attrs:{"fill":"currentColor","viewBox":"0 0 20 20"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z","clip-rule":"evenodd"}})])])])],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }