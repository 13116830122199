<template>
    <div>
        <div
            class="-mx-4 mt-2 bg-white ring-1 ring-gray-300 sm:-mx-6 md:mx-0 md:rounded-lg"
        >
            <table class="min-w-full divide-y divide-gray-300">
                <thead>
                    <tr>
                        <th
                            scope="col"
                            class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >Time</th
                        >
                        <th
                            scope="col"
                            class="table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >Code</th
                        >
                        <th
                            scope="col"
                            class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >Total</th
                        >
                        <th
                            scope="col"
                            class="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                            <span class="sr-only">View</span>
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="order in orders" :key="order.id">
                        <td
                            class="relative py-4 pl-4 pr-3 text-xs sm:pl-6 sm:text-sm"
                        >
                            <div class="font-medium text-gray-900">{{
                                order.created_at | moment('h:mm a')
                            }}</div>
                        </td>
                        <td class="px-3 py-3.5 text-sm text-gray-500">
                            <span class="font-mono font-bold" v-if="order.code">
                                {{ order.code }}
                            </span>
                        </td>
                        <td class="px-3 py-3.5 text-sm text-gray-500">
                            <div>{{ (order.total / 100) | currency }}</div>
                        </td>
                        <td class="px-3 py-3.5 text-sm text-gray-500">
                            <router-link
                                :to="{
                                    name: 'orderDetail',
                                    params: {
                                        id: order.id
                                    }
                                }"
                            >
                                <button
                                    type="button"
                                    class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                >
                                    View order
                                </button>
                            </router-link>
                        </td>
                    </tr>

                    <tr v-if="!orders.length">
                        <td
                            colspan="7"
                            class="relative w-full rounded-lg p-12 text-center"
                        >
                            <span
                                class="mt-2 block text-sm font-medium text-gray-900"
                            >
                                No orders yet
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BillPaymentOrders',
    props: {
        orders: {
            type: Array,
            required: true
        }
    }
};
</script>
