<template>
    <div class="mx-auto max-w-2xl px-6 sm:px-12">
        <div class="md:flex md:items-center md:justify-between">
            <div class="min-w-0 flex-1">
                <router-link
                    :to="{ name: 'paymentSettings' }"
                    class="mb-2 flex items-center text-sm text-gray-500 inline-flex"
                >
                    <base-back-icon />
                    <span>Payment settings</span>
                </router-link>
                <base-page-title title="storekit payments" />
            </div>

            <BaseSpinner :isLoading="isLoading" />
        </div>
        <div>
            <div class="mt-4">
                <div class="mt-5 md:mt-0" v-if="!isLoading">
                    <div class="shadow sm:overflow-hidden sm:rounded-md">
                        <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
                            <div class="">
                                <label
                                    for="businessType"
                                    class="block text-sm font-medium text-gray-700"
                                    >storekit payments account</label
                                >
                                <select
                                    id="businessType"
                                    name="businessType"
                                    v-model="selectedAccount"
                                    class="focus:outline-none form-input mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                >
                                    <option value="" disabled selected
                                        >Select an account</option
                                    >
                                    <option
                                        v-for="account in accounts"
                                        :key="account.id"
                                        :value="account"
                                    >
                                        {{ account.legalBusinessName }}
                                        <span
                                            v-if="
                                                isFeatureAvailable(
                                                    FeaturesEnum.INTERNAL
                                                )
                                            "
                                            >({{
                                                account.accountHolderCode
                                            }})</span
                                        >
                                    </option>
                                </select>
                            </div>
                            <div v-if="selectedAccount && !isValidAccount">
                                <div
                                    class="rounded-md border border-blue-200 bg-blue-50 p-4"
                                >
                                    <div class="flex">
                                        <div class="flex-shrink-0">
                                            <svg
                                                class="h-5 w-5 text-blue-400"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                                                    clip-rule="evenodd"
                                                />
                                            </svg>
                                        </div>
                                        <div class="ml-3">
                                            <h3
                                                class="text-sm font-medium text-blue-800"
                                            >
                                                Information required
                                            </h3>
                                            <div
                                                class="mt-2 text-sm text-blue-700"
                                            >
                                                <p>
                                                    We need to collect some more
                                                    information about your
                                                    business before you can
                                                    connect this account
                                                </p>
                                                <router-link
                                                    class="mt-2 inline-flex rounded-md shadow-sm"
                                                    :to="{
                                                        name:
                                                            'manageAdyenAccountHolder',
                                                        params: {
                                                            code:
                                                                selectedAccount.accountHolderCode
                                                        }
                                                    }"
                                                >
                                                    <button
                                                        type="button"
                                                        class="focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                                                    >
                                                        Complete account setup
                                                    </button>
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="my-6 ml-4 flex flex-shrink-0 justify-end">
            <span class="inline-flex rounded-md shadow-sm">
                <button
                    @click="connectAdyenAccountHolder"
                    type="button"
                    class="focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                    :class="{
                        'opacity-50': !selectedAccount || !isValidAccount
                    }"
                    :disabled="!isValidAccount || isLoading || !selectedAccount"
                >
                    Connect account
                </button>
            </span>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FeaturesEnum } from '@/enums';

export default {
    name: 'ConnectAdyenAccount',
    metaInfo: {
        title: 'storekit Payments Setup'
    },
    props: {
        venueId: {
            type: Number,
            required: true,
            default: 0
        },
        venue: {
            type: Object,
            required: false,
            default: () => {}
        }
    },
    data() {
        return {
            isLoading: false,
            selectedAccount: '',
            accounts: [],
            FeaturesEnum
        };
    },
    created() {
        this.fetchAdyenAccounts();
    },
    methods: {
        async fetchAdyenAccounts() {
            try {
                const { data } = await this.$axios.get(
                    '/adyen/account-holders'
                );
                this.accounts = data;
                if (data.length === 1) {
                    this.selectedAccount = data[0];
                }
            } catch (e) {
                throw new Error(e);
            }
            this.isLoading = false;
        },
        async connectAdyenAccountHolder() {
            if (!this.selectedAccount) {
                return false;
            }

            try {
                this.isLoading = true;

                const payload = {
                    accountHolderCode: this.selectedAccount.accountHolderCode,
                    venueId: this.venueId
                };

                await this.$axios.post('/adyen/account/', payload);

                this.$notify({
                    group: 'settings',
                    title:
                        'Connected successfully! View your store to try a payment.',
                    duration: 5000
                });

                this.$router.push({
                    name: 'paymentSettings'
                });
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        }
    },
    computed: {
        ...mapGetters({
            isFeatureAvailable: 'user/isFeatureAvailable'
        }),
        isValidAccount() {
            if (!this.selectedAccount.pciCompleted) {
                return false;
            }
            if (!this.selectedAccount.storeReference) {
                return false;
            }
            if (this.selectedAccount.adyenStatus !== 'Active') {
                return false;
            }
            if (!this.selectedAccount.active) {
                return false;
            }

            return true;
        }
    }
};
</script>
