module.exports = class DeliveryStatusEnum {
    static get CREATED() {
        return 'created';
    }

    static get NOT_ASSIGNED_DRIVER() {
        return 'not_assigned_driver';
    }

    static get ASSIGNED_DRIVER() {
        return 'assigned_driver';
    }

    static get STARTED() {
        return 'started';
    }

    static get PICKUP_ENROUTE() {
        return 'pickup_enroute';
    }

    static get PICKUP_ARRIVED() {
        return 'pickup_arrived';
    }

    static get PICKUP_COMPLETE() {
        return 'pickup_complete';
    }

    static get DROPOFF_ENROUTE() {
        return 'dropoff_enroute';
    }

    static get DROPOFF_ARRIVED() {
        return 'dropoff_arrived';
    }

    static get DROPOFF_COMPLETE() {
        return 'dropoff_complete';
    }

    static get CANCELED_BY_PROVIDER() {
        return 'canceled_by_provider';
    }

    static get CANCELED_BY_CUSTOMER() {
        return 'canceled_by_customer';
    }

    static get RETURN_IN_PROGRESS() {
        return 'return_in_progress';
    }

    static get RETURNED() {
        return 'returned';
    }

    static get SCHEDULED() {
        return 'scheduled';
    }

    static get EXPIRED() {
        return 'expired';
    }

    static get FAILED() {
        return 'failed';
    }

    static get options() {
        return {
            CREATED: this.CREATED,
            NOT_ASSIGNED_DRIVER: this.NOT_ASSIGNED_DRIVER,
            ASSIGNED_DRIVER: this.ASSIGNED_DRIVER,
            STARTED: this.STARTED,
            PICKUP_ENROUTE: this.PICKUP_ENROUTE,
            PICKUP_ARRIVED: this.PICKUP_ARRIVED,
            PICKUP_COMPLETE: this.PICKUP_COMPLETE,
            DROPOFF_ENROUTE: this.DROPOFF_ENROUTE,
            DROPOFF_ARRIVED: this.DROPOFF_ARRIVED,
            DROPOFF_COMPLETE: this.DROPOFF_COMPLETE,
            CANCELED_BY_PROVIDER: this.CANCELED_BY_PROVIDER,
            CANCELED_BY_CUSTOMER: this.CANCELED_BY_CUSTOMER,
            RETURN_IN_PROGRESS: this.RETURN_IN_PROGRESS,
            RETURNED: this.RETURNED,
            SCHEDULED: this.SCHEDULED,
            EXPIRED: this.EXPIRED,
            FAILED: this.FAILED,
        };
    }
};
