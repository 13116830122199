var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-5 overflow-hidden bg-white shadow print:shadow-none sm:rounded-lg"},[_c('div',{staticClass:"border-b border-gray-200"},[_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"flex w-full items-center justify-between px-3 py-2"},[_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"flex"},[_c('span',{staticClass:"inline-flex items-center rounded-md bg-green-50 px-2 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-300"},[_c('svg',{staticClass:"-ml-0.5 mr-1 h-4 w-4 text-green-400",attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M5 13l4 4L19 7"}})]),_vm._v(" Paid ")])])]),_c('div',[_c('span',{staticClass:"inline-flex rounded-md shadow-sm"},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                                !_vm.isTotalRefunded
                                    ? 'Open the refund order window'
                                    : 'Full amount already refunded'
                            ),expression:"\n                                !isTotalRefunded\n                                    ? 'Open the refund order window'\n                                    : 'Full amount already refunded'\n                            "}],staticClass:"focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-2 py-1.5 text-xs font-medium leading-4 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800",class:{
                                'cursor-not-allowed opacity-50': _vm.isTotalRefunded
                            },attrs:{"type":"button","disabled":_vm.isTotalRefunded},on:{"click":function($event){return _vm.showRefundModal(_vm.order, false)}}},[_vm._v(" Refund order ")])])])]),(_vm.saleTransaction)?_c('div',{staticClass:"flex w-full flex-row justify-between bg-gray-50 px-4 py-2"},[_c('div',{staticClass:"box-divider pr-3"},[_c('p',{staticClass:"mt-1 text-xs sm:text-sm leading-6 text-gray-500"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.saleTransaction.created_at,'MMM D, h:mm a'))+" ")])]),(_vm.saleTransaction.paymentDetails)?_c('div',{staticClass:"box-divider px-2"},[_c('div',{staticClass:"mt-1 flex"},[(
                                _vm.saleTransaction.paymentDetails
                                    .paymentMethod === 'visa'
                            )?_c('img',{staticClass:"h-5 rounded-md border border-gray-100",attrs:{"src":require("@/assets/visa.svg"),"alt":"Visa"}}):(
                                _vm.saleTransaction.paymentDetails
                                    .paymentMethod === 'mc'
                            )?_c('img',{staticClass:"h-5 rounded-md border border-gray-100",attrs:{"src":require("@/assets/mc.svg"),"alt":"Mastercard"}}):(
                                _vm.saleTransaction.paymentDetails
                                    .paymentMethod === 'amex'
                            )?_c('img',{staticClass:"h-5 rounded-md border border-gray-100",attrs:{"src":require("@/assets/amex.svg"),"alt":"American Express"}}):(
                                _vm.saleTransaction.paymentDetails
                                    .paymentMethod === 'klarna'
                            )?_c('img',{staticClass:"h-5 rounded-md border border-gray-100",attrs:{"src":"https://ucarecdn.com/3e6f845d-0f9b-4e99-b1c7-74d8cd817723/-/format/auto/","alt":"Klarna"}}):(
                                _vm.saleTransaction.paymentDetails
                                    .paymentMethod === 'ideal'
                            )?_c('img',{staticClass:"h-5 rounded-md border border-gray-100",attrs:{"src":require("@/assets/ideal-logo.svg"),"alt":"iDeal Logo"}}):(
                                _vm.saleTransaction.paymentDetails
                                    .paymentMethod === 'visa_applepay' ||
                                'amex_applepay' ||
                                'mc_applepay'
                            )?_c('img',{staticClass:"h-5 rounded-md border border-gray-100",attrs:{"src":require("@/assets/applepay.svg"),"alt":"Apple Pay"}}):_vm._e(),(
                                _vm.saleTransaction.paymentDetails
                                    .paymentMethod !== 'klarna'
                            )?_c('p',{staticClass:"pl-2 text-xs sm:text-sm leading-5 text-gray-500"},[_vm._v(" •••• "+_vm._s(_vm.saleTransaction.paymentDetails .untokenisedCardSummary || _vm.saleTransaction.paymentDetails .cardSummary || '••••')+" ")]):_vm._e()])]):_vm._e(),(
                        _vm.saleTransaction.paymentDetails &&
                        _vm.saleTransaction.paymentDetails.cardHolderName
                    )?_c('div',{staticClass:"px-2"},[_c('p',{staticClass:"mt-1 text-xs sm:text-sm leading-6 text-gray-500"},[_vm._v(" "+_vm._s(_vm.saleTransaction.paymentDetails.cardHolderName)+" ")])]):_vm._e(),(_vm.saleTransaction.trackingId)?_c('div',{staticClass:"hidden px-2 md:block"},[(_vm.isFeatureAvailable(_vm.FeaturesEnum.INTERNAL))?_c('a',{staticClass:"mt-1 text-xs leading-6 text-indigo-600 underline",attrs:{"target":"_blank","href":`https://ca-live.adyen.com/ca/ca/accounts/showTx.shtml?pspReference=${_vm.saleTransaction.trackingId}&txType=Payment`}},[_vm._v(_vm._s(_vm.saleTransaction.trackingId))]):_c('span',{staticClass:"mt-1 font-mono text-xs leading-6 text-gray-500"},[_vm._v(_vm._s(_vm.saleTransaction.trackingId))])]):_vm._e()]):_vm._e()])]),(_vm.order.refunds && _vm.order.refunds.length)?_c('div',{staticClass:"border-t border-gray-100 bg-gray-50 pt-2 shadow-inner"},[_c('div',{staticClass:"flex flex-col flex-wrap justify-between sm:flex-nowrap"},[_vm._m(0),_vm._l((_vm.order.refunds),function(refund){return _c('div',{key:refund.id,staticClass:"mx-4 mb-2 mt-2 w-auto rounded-md bg-white p-3"},[_c('p',{staticClass:"text-xs leading-5 text-gray-500"},[_vm._v(" Amount: "+_vm._s(_vm._f("currency")((refund.amount / 100)))+" ")]),(refund.reason)?_c('p',{staticClass:"mt-1 text-xs leading-5 text-gray-500"},[_vm._v(" Reason: "+_vm._s(_vm.RefundReasonsEnum.dictionary[refund.reason] || refund.reason)+" ")]):_vm._e(),(
                        refund.gatewayResponse &&
                        refund.gatewayResponse.response
                    )?_c('p',{staticClass:"mt-1 text-xs leading-5 text-gray-500"},[_vm._v(" Result: "+_vm._s(refund.gatewayResponse.response)+" ")]):_vm._e(),(_vm.isFeatureAvailable(_vm.FeaturesEnum.INTERNAL))?_c('a',{staticClass:"mt-1 font-mono text-xs leading-6 text-indigo-600 underline",attrs:{"target":"_blank","href":`https://ca-live.adyen.com/ca/ca/payments/modifySearch.shtml?uxEvent=PAYMENT_POWER_SEARCH&query=${_vm.saleTransaction.trackingId}`}},[_vm._v(_vm._s(refund.gatewayResponse.pspReference))]):_c('span',{staticClass:"mt-1 font-mono text-xs leading-5 text-gray-500"},[_vm._v("ID: "+_vm._s(refund.gatewayResponse ? refund.gatewayResponse.pspReference : refund.id))])])})],2)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ml-2 mt-1"},[_c('div',{staticClass:"flex items-center"},[_c('h3',{staticClass:"ml-4 text-base font-heading leading-6 text-gray-800"},[_vm._v(" Refunds ")])])])
}]

export { render, staticRenderFns }