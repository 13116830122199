<template>
  <div :id="`orkestro-chat-widget-wrapper-${deliveryProviderId}-${location}`"/>
</template>

<script>
import { mapGetters } from "vuex";

// Static variable to keep track of the script loading status
let scriptLoaderPromise = null;

export default {
  name: 'OrkestroChatWidget',
  props: {
    deliveryProviderId: {
      type: String,
      required: true
    },
    location: {
      type: String,
      required: false,
      default: 'order'
    }
  },
  data() {
    return {
      scriptId: 'orkestro-chat-widget',
      token: null
    };
  },
  async mounted() {
    this.token = await this.generateToken();

    if (!this.token) {
      return;
    }

    // Check if the script is already being loaded
    if (!scriptLoaderPromise) {
      scriptLoaderPromise = this.loadScript();
    }

    // Wait for the script to be loaded before rendering the widget
    scriptLoaderPromise.then(() => {
      this.renderWidget();
    });
  },
  beforeUnmount() {
    this.removeScript();
  },
  methods: {
    async generateToken() {
      try {
        const { data: { token } } = await this.$axios.get(
            `/orkestro/generate-token/${this.deliveryProviderId}`
        );
        return token;
      } catch (error) {
        return null;
      }
    },
    renderWidget() {
      /* eslint-disable */
      if (!this.token || typeof ChatWidget === 'undefined') {
        return;
      }

      this.chatWidget = new ChatWidget({
        selector: `#orkestro-chat-widget-wrapper-${this.deliveryProviderId}-${this.location}`,
        token: this.token,
        orderId: this.deliveryProviderId
      });

      this.chatWidget.render();
      /* eslint-enable */
    },
    loadScript() {
      return new Promise((resolve, reject) => {
        if (this.isAlreadyMounted()) {
          resolve(); // Script is already mounted, resolve immediately
          return;
        }

        const script = document.createElement('script');
        script.id = this.scriptId;
        script.type = 'text/javascript';
        script.src = `${this.orkestroWidgetUrl}/widgets/chat_widget@latest.js`;

        script.onload = () => {
          resolve(); // Resolve when the script is loaded
        };
        script.onerror = () => {
          reject(new Error('Failed to load Orkestro chat widget script'));
        };

        document.head.appendChild(script);
      });
    },
    removeScript() {
      // Optional: Only remove the script if necessary; depends on your use case
      const script = document.getElementById(this.scriptId);
      if (script) script.remove();
    },
    isAlreadyMounted() {
      return !!document.getElementById(this.scriptId);
    }
  },
  computed: {
    ...mapGetters({
      accountId: 'user/accountId'
    }),
    orkestroWidgetUrl() {
      const DEMO_ACCOUNT_ID = 'f2b595dd-35b5-44ef-8c10-16a534dfab84';

      if (this.accountId === DEMO_ACCOUNT_ID) {
        return 'https://widgets.sandbox.orkestro.io'
      } else {
        return process.env.NODE_ENV !== 'production'
            ? 'https://widgets.sandbox.orkestro.io'
            : 'https://widgets.orkestro.io/';
      }
    }
  }
};
</script>
