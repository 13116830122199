<template>
    <div>
        <div class="mt-4 md:grid md:gap-6 md:grid-cols-3">
            <div class="md:col-span-1">
                <div class="sm:pl-0 sm:pr-5">
                    <h3 class="text-gray-800 text-base font-semibold leading-6"
                        >3D Secure</h3
                    >
                    <p class="mt-2 text-gray-500 text-sm">
                        3D Secure enables you to authenticate card holders
                        through card issuers. It reduces the likelihood of fraud
                        when you use supported cards and improves transaction
                        performance. A successful 3D Secure authentication can
                        shift liability for chargebacks due to fraud from you to
                        the card issuer.
                    </p>
                </div>
            </div>
            <div class="mt-5 md:col-span-2 md:mt-0">
                <div class="shadow sm:rounded-md sm:overflow-hidden">
                    <div class="flex items-center mt-4 p-20">
                        <span
                            @click="toggle3DSecure"
                            role="checkbox"
                            tabindex="0"
                            aria-checked="false"
                            class="group relative inline-flex flex-shrink-0 items-center justify-center w-10 h-5 focus:outline-none cursor-pointer"
                        >
                            <span
                                aria-hidden="true"
                                :class="{
                                    'bg-gray-200': !is3DSecureActive,
                                    'bg-indigo-600': is3DSecureActive
                                }"
                                class="absolute mx-auto w-9 h-4 bg-gray-200 rounded-full transition-colors duration-200 ease-in-out"
                            ></span>
                            <span
                                aria-hidden="true"
                                :class="{
                                    'translate-x-0': !is3DSecureActive,
                                    'translate-x-5': is3DSecureActive
                                }"
                                class="absolute left-0 inline-block w-5 h-5 bg-white border group-focus:border-blue-300 border-gray-200 rounded-full shadow group-focus:shadow-outline transform translate-x-0 transition-transform duration-200 ease-in-out"
                            ></span>
                        </span>
                        <span
                            class="pl-3 text-gray-700 text-sm font-medium leading-5"
                        >
                            3D Secure
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        venueId: {
            type: Number,
            required: true,
            default: 0
        },
        is3DSecure: {
            type: Boolean,
            required: true,
            default: true
        }
    },

    data() {
        return {
            isLoading: false,
            is3DSecureActive: this.is3DSecure
        };
    },

    methods: {
        async toggle3DSecure() {
            this.is3DSecureActive = !this.is3DSecureActive;

            try {
                await this.$axios.put('/paypal/toggle-3d-secure', {
                    venueId: this.venueId,
                    is3DSecureEnabled: this.is3DSecureActive
                });

                this.$emit('toggle3DSecureActive', this.is3DSecureActive);
            } catch (error) {
                throw new Error(`API Response ${error}`);
            }
        }
    }
};
</script>
