<template>
    <div>
        <div class="md:flex md:items-center md:justify-between">
            <div class="min-w-0 flex-1">
                <router-link
                    :to="{ name: 'editVenue' }"
                    class="mb-2 flex items-center text-sm text-gray-500 inline-flex"
                >
                    <base-back-icon />
                    <span>Settings</span>
                </router-link>
                <base-page-title title="Deliverect Integration" />
            </div>

            <BaseSpinner :isLoading="isLoading" />
        </div>
        <div>
            <div class="mt-4 grid-cols-1 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                        >
                            Details
                        </h3>
                        <p class="mt-2 text-sm text-gray-500">
                            Connect storekit to Deliverect.
                        </p>
                    </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0" v-if="!isLoading">
                    <div class="shadow sm:overflow-hidden sm:rounded-md">
                        <div class="space-y-6 bg-white px-4 py-5 sm:p-12">
                            <div class="grid grid-cols-1 gap-6 md:grid-cols-3">
                                <div class="sm:col-span-6">
                                    <base-input-field
                                        type="text"
                                        label="Deliverect ID"
                                        :disabled="true"
                                        tooltip="The deliverect channel link ID. Contact us to remove or change this value."
                                        id="deliverect-channel-id"
                                        v-model="settings.deliverectId"
                                    >
                                    </base-input-field>
                                </div>

                                <div class="sm:col-span-6">
                                    <toggle-switch
                                        id="buffer-orders"
                                        label="Use Deliverect opening hours"
                                        tooltip="Toggle to use the opening hours set for this venue in Deliverect."
                                        v-model="
                                            deliverect.syncVenueAvailabilityHours
                                        "
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <BaseSpacerWithRuler />

        <div class="my-6 ml-4 flex flex-shrink-0 justify-end">
            <span
                class="inline-flex rounded-md shadow-sm"
                @click="updateSettings"
            >
                <button
                    type="button"
                    class="focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                    :disabled="isLoading"
                >
                    Save
                </button>
            </span>
        </div>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import ToggleSwitch from '@/components/formComponents/ToggleSwitch';

export default {
    name: 'DeliverectConnectedSettings',
    components: { ToggleSwitch },
    metaInfo: {
        title: 'Deliverect Integration'
    },
    props: ['venueId', 'venue'],
    data() {
        return {
            isLoading: false,
            settings: { ...this.venue },
            deliverect: {},
            defaultDeliverectSettings: {
                syncVenueAvailabilityHours: false
            }
        };
    },
    validations: {
        deliverect: {
            syncVenueAvailabilityHours: {
                required
            }
        }
    },
    mounted() {
        if (this.settings?.integrations?.deliverect) {
            this.deliverect = this.settings.integrations.deliverect;
        } else {
            this.deliverect = this.defaultDeliverectSettings;
        }
    },
    methods: {
        async toggleEnabled() {
            try {
                this.comtrex.enabled = !this.comtrex.enabled;
                await this.$axios.post('/comtrex/toggle', {
                    venueId: this.venueId,
                    enabled: this.comtrex.enabled
                });
            } catch (error) {
                this.comtrex.enabled = !this.comtrex.enabled;
                throw new Error(`API ${error}`);
            }
        },
        async updateSettings() {
            try {
                await this.$axios.post('/deliverect/update', {
                    venueId: this.venueId,
                    ...this.deliverect
                });
            } catch (error) {
                throw new Error(`API ${error}`);
            }
        }
    }
};
</script>
