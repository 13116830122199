<template>
    <div class="flex w-0 flex-1 flex-col overflow-hidden">
        <ToggleSidebarButton />
        <main
            class="focus:outline-none relative z-0 flex-1 overflow-y-auto pb-6 pt-2 md:py-6"
        >
            <div
                class="mx-auto flex max-w-7xl flex-col items-center justify-between px-4 sm:flex-row sm:px-6 lg:px-8"
            >
                <BasePageTitle title="Tables" />

                <div
                    class="h-4 w-4"
                    :class="{ 'text-gray-900 spinner': isLoading }"
                >
                    <span></span>
                </div>
                <div class="flex flex-row items-center">
                    <div>
                        <div class="relative rounded-md shadow-sm">
                            <div
                                class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                            >
                                <svg
                                    class="h-5 w-5 text-gray-400"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                        clip-rule="evenodd"
                                    ></path>
                                </svg>
                            </div>
                            <input
                                type="text"
                                name="search"
                                id="search"
                                v-model="tableSearchQuery"
                                class="block w-full rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                placeholder="search by table"
                            />
                        </div>
                    </div>

                    <span class="ml-4 rounded-sm">
                        <venue-selector
                            v-if="!isLoading"
                            :venues="venues"
                            :pre-selected="parseInt(venueFilter)"
                            :multiple-select="false"
                            :allow-empty="false"
                            @selected="setVenue"
                        />
                    </span>
                </div>
            </div>

            <div
                class="mx-auto max-w-7xl px-4 sm:px-6 md:px-8"
                v-if="isLoading"
            >
                <div class="py-4">
                    <div class="overflow-hidden bg-white shadow sm:rounded-md">
                        <div class="p-4">
                            <VclList :width="400" :height="200" />
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="mx-auto mt-10 max-w-7xl p-4 px-4 sm:mt-0 sm:px-6 md:px-8"
                v-else
            >
                <div class="mt-4 sm:mt-12">
                    <div
                        class="flex items-center justify-between sm:items-start"
                    >
                        <div class="flex flex-auto flex-row">
                            <h1
                                @click="
                                    closedBills = false;
                                    fetchBills(true);
                                "
                                :class="{ 'opacity-50': closedBills }"
                                class="cursor-pointer text-xl font-semibold text-gray-900"
                            >
                                Open bills
                            </h1>
                            <span>&nbsp;/&nbsp;</span>
                            <h1
                                @click="
                                    closedBills = true;
                                    fetchBills(true);
                                "
                                :class="{ 'opacity-50': !closedBills }"
                                class="cursor-pointer text-xl font-semibold text-gray-900"
                                >closed bills</h1
                            >

                            <date-range-selector
                                v-if="closedBills"
                                :time-picker="true"
                                :last-period-in-days="2"
                                :styles="pickerStyles"
                                @date-updated="updateDateRange"
                            />
                        </div>
                        <div>
                            <button
                                @click="fetchBills(false)"
                                :disabled="billsLoading"
                                type="button"
                                :class="{ 'cursor-wait': billsLoading }"
                                class="focus:outline-none inline-flex items-center rounded px-2.5 py-1.5 text-xs font-medium text-gray-700 hover:bg-gray-100 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                <base-refresh-icon :loading="billsLoading" />
                                Refresh
                            </button>
                        </div>
                    </div>
                    <div
                        class="-mx-4 mt-6 bg-white ring-1 ring-gray-300 sm:-mx-6 md:mx-0 md:rounded-lg"
                    >
                        <table class="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr>
                                    <th
                                        scope="col"
                                        class="py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6 sm:text-sm"
                                        >Table #</th
                                    >
                                    <th
                                        scope="col"
                                        class="px-3 py-3.5 text-left text-xs font-semibold text-gray-900 sm:text-sm lg:table-cell"
                                        >Opened</th
                                    >
                                    <th
                                        scope="col"
                                        class="hidden px-3 py-3.5 text-left text-xs font-semibold text-gray-900 sm:text-sm lg:table-cell"
                                        >Waiter</th
                                    >
                                    <th
                                        scope="col"
                                        class="hidden px-3 py-3.5 text-left text-xs font-semibold text-gray-900 sm:text-sm lg:table-cell"
                                        ><span class="pl-4">Items</span></th
                                    >
                                    <th
                                        scope="col"
                                        class="px-3 py-3.5 text-left text-xs font-semibold text-gray-900 sm:text-sm lg:table-cell"
                                        >Left to pay</th
                                    >
                                    <th
                                        scope="col"
                                        class="px-3 py-3.5 text-left text-xs font-semibold text-gray-900 sm:text-sm"
                                        >Total</th
                                    >
                                    <th
                                        scope="col"
                                        class="relative py-3.5 pl-3 pr-4 sm:pr-6"
                                    >
                                        <span class="sr-only">View</span>
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr
                                    v-for="bill in filteredBills"
                                    :key="bill.id"
                                >
                                    <td
                                        class="relative py-4 pl-4 pr-3 text-sm sm:pl-6"
                                    >
                                        <div
                                            class="font-medium text-gray-900"
                                            >{{ bill.table.posId }}</div
                                        >
                                    </td>
                                    <td
                                        class="table-cell px-3 py-3.5 text-sm text-gray-500"
                                        ><span v-if="closedBills">
                                            {{
                                                bill.providerOpenDate
                                                    | moment('D/M HH:mm a')
                                            }}
                                        </span>
                                        <span v-else>
                                            {{
                                                bill.providerOpenDate
                                                    | moment('HH:mm a d/M')
                                            }}
                                        </span></td
                                    >
                                    <td
                                        class="hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                                    >
                                        <div
                                            v-if="
                                                bill.providerDetails &&
                                                bill.providerDetails.staffName
                                            "
                                            class="flex flex-row items-start"
                                        >
                                            <avatar
                                                :username="
                                                    bill.providerDetails
                                                        .staffName
                                                "
                                                :size="24"
                                            />
                                            <span class="pl-2">{{
                                                bill.providerDetails.staffName
                                            }}</span>
                                        </div>
                                    </td>
                                    <td
                                        class="hidden flex-row items-center px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                                    >
                                        <v-dropdown
                                            distance="16"
                                            :triggers="['click']"
                                            popperClass="order-item-popover"
                                        >
                                            <div
                                                class="group flex select-none items-center rounded-md px-4 py-2 hover:bg-gray-100"
                                            >
                                                <span class="mr-2 block">
                                                    {{ bill.items.length }}
                                                    items
                                                </span>
                                                <span
                                                    class="m-auto block h-4 w-4 text-gray-600 opacity-0 group-hover:opacity-100"
                                                    ><svg
                                                        viewBox="0 0 20 20"
                                                        fill="currentColor"
                                                        focusable="false"
                                                        aria-hidden="true"
                                                    >
                                                        <path
                                                            d="M5 8l5 5 5-5H5z"
                                                        ></path></svg
                                                ></span>
                                            </div>

                                            <template #popper>
                                                <OrderItemsTooltip
                                                    :order-items="bill.items"
                                                />
                                            </template>
                                        </v-dropdown>
                                    </td>
                                    <td
                                        class="table-cell px-3 py-3.5 text-sm text-gray-500"
                                    >
                                        <div class="flex flex-row items-center">
                                            {{
                                                (bill.amountDue / 100)
                                                    | currency
                                            }}
                                            <div
                                                v-if="
                                                    bill.posStatus === 'error'
                                                "
                                                class="ml-1 text-red-600"
                                                v-tooltip="
                                                    'We weren\'t able to apply a payment - please check the bill payments and your POS connectivity'
                                                "
                                            >
                                                <base-warning-icon />
                                            </div>
                                        </div>
                                    </td>
                                    <td
                                        class="px-3 py-3.5 text-sm text-gray-500"
                                    >
                                        <div class="block">{{
                                            (bill.total / 100) | currency
                                        }}</div>
                                    </td>
                                    <td
                                        class="relative py-3.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"
                                    >
                                        <button
                                            @click="
                                                openTicketSidebar(
                                                    bill.id,
                                                    bill.venueId
                                                )
                                            "
                                            type="button"
                                            class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
                                        >
                                            <span class="hidden sm:inline-block"
                                                >View payments</span
                                            >
                                            <svg
                                                class="h-4 w-4 sm:hidden"
                                                fill="currentColor"
                                                viewBox="0 0 20 20"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                                    clip-rule="evenodd"
                                                ></path>
                                            </svg>
                                            <span></span>
                                        </button>
                                    </td>
                                </tr>
                                <tr
                                    v-if="
                                        !billsLoading &&
                                        !isLoading &&
                                        !filteredBills.length
                                    "
                                >
                                    <td
                                        colspan="7"
                                        class="relative w-full rounded-lg p-12 text-center"
                                    >
                                        <span
                                            class="mt-2 block text-sm font-medium text-gray-900"
                                        >
                                            No
                                            {{
                                                closedBills ? 'closed' : 'open'
                                            }}
                                            bills
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="mt-4 sm:mt-12">
                    <div
                        class="flex items-center items-center justify-between sm:items-start"
                    >
                        <div class="flex-auto">
                            <h1 class="text-xl font-semibold text-gray-900"
                                >Open tables</h1
                            >
                        </div>
                        <div>
                            <button
                                @click="fetchOpenTables"
                                type="button"
                                :disabled="tablesLoading"
                                :class="{ 'cursor-wait': tablesLoading }"
                                class="focus:outline-none inline-flex items-center rounded px-2.5 py-1.5 text-xs font-medium text-gray-700 hover:bg-gray-100 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                <base-refresh-icon :loading="tablesLoading" />
                                Refresh
                            </button>
                        </div>
                    </div>
                    <div
                        class="-mx-4 mt-6 bg-white ring-1 ring-gray-300 sm:-mx-6 md:mx-0 md:rounded-lg"
                    >
                        <table class="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr>
                                    <th
                                        scope="col"
                                        class="py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6 sm:text-sm"
                                        >Table #</th
                                    >
                                    <th
                                        scope="col"
                                        class="table-cell px-3 py-3.5 text-left text-xs font-semibold text-gray-900 sm:text-sm"
                                        >Opened</th
                                    >
                                    <th
                                        scope="col"
                                        class="hidden px-3 py-3.5 text-left text-xs font-semibold text-gray-900 sm:text-sm lg:table-cell"
                                        >Waiter</th
                                    >
                                    <th
                                        scope="col"
                                        class="hidden px-3 py-3.5 text-left text-xs font-semibold text-gray-900 sm:text-sm lg:table-cell"
                                        ><span class="pl-4">Items</span></th
                                    >
                                    <th
                                        scope="col"
                                        class="table-cell px-3 py-3.5 text-left text-xs font-semibold text-gray-900 sm:text-sm"
                                        >Left to pay</th
                                    >
                                    <th
                                        scope="col"
                                        class="px-3 py-3.5 text-left text-xs font-semibold text-gray-900 sm:text-sm"
                                        >Total</th
                                    >
                                    <th
                                        scope="col"
                                        class="relative py-3.5 pl-3 pr-4 sm:pr-6"
                                    >
                                        <span class="sr-only">View</span>
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr
                                    v-for="table in filteredOpenTables"
                                    :key="table.uuid || table.providerId"
                                >
                                    <td
                                        class="relative py-4 pl-4 pr-3 text-sm sm:pl-6"
                                    >
                                        <div
                                            class="font-medium text-gray-900"
                                            >{{ table.tableNumber }}</div
                                        >
                                    </td>
                                    <td
                                        class="table-cell px-3 py-3.5 text-sm text-gray-500"
                                        >{{
                                            table.openDate | moment('HH:mm a')
                                        }}</td
                                    >
                                    <td
                                        class="hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                                    >
                                        <div class="flex flex-row items-center">
                                            <avatar
                                                v-if="table.staffName"
                                                :username="table.staffName"
                                                :size="24"
                                            />
                                            <span class="pl-2">{{
                                                table.staffName
                                            }}</span>
                                        </div>
                                    </td>
                                    <td
                                        class="hidden flex-row items-center px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                                    >
                                        <v-dropdown
                                            v-if="table.salesEntries"
                                            distance="16"
                                            :triggers="['click']"
                                            popperClass="order-item-popover"
                                        >
                                            <div
                                                class="group flex select-none items-center rounded-md px-4 py-2 hover:bg-gray-100"
                                            >
                                                <span class="mr-2 block">
                                                    {{
                                                        table.salesEntries
                                                            .length
                                                    }}
                                                    items
                                                </span>
                                                <span
                                                    class="m-auto block h-4 w-4 text-gray-600 opacity-0 group-hover:opacity-100"
                                                    ><svg
                                                        viewBox="0 0 20 20"
                                                        fill="currentColor"
                                                        focusable="false"
                                                        aria-hidden="true"
                                                    >
                                                        <path
                                                            d="M5 8l5 5 5-5H5z"
                                                        ></path></svg
                                                ></span>
                                            </div>

                                            <template #popper>
                                                <!--                                                <OrderItemsTooltip-->
                                                <!--                                                    :order-items="items"-->
                                                <!--                                                />-->
                                            </template>
                                        </v-dropdown>
                                    </td>
                                    <td
                                        class="table-cell px-3 py-3.5 text-sm text-gray-500"
                                        >{{
                                            (table.currentAmount -
                                                table.paidAmount)
                                                | currency
                                        }}</td
                                    >
                                    <td
                                        class="px-3 py-3.5 text-sm text-gray-500"
                                    >
                                        <div class="block" v-if="table.total">{{
                                            table.total | currency
                                        }}</div>
                                    </td>
                                    <td
                                        class="relative py-3.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"
                                    >
                                    </td>
                                </tr>

                                <tr
                                    v-if="
                                        !isLoading && !filteredOpenTables.length
                                    "
                                >
                                    <td
                                        colspan="7"
                                        class="relative w-full rounded-lg p-12 text-center"
                                    >
                                        <span
                                            class="mt-2 block text-sm font-medium text-gray-900"
                                        >
                                            No open tables
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </main>
        <ticket-sidebar
            v-if="editedTicket"
            v-model="ticketSidebar"
            :product="editedTicket"
        />
    </div>
</template>

<script>
import Avatar from 'vue-avatar';
import { VclList } from 'vue-content-loading';
import TicketSidebar from '@/components/sidebars/TicketSidebar';
import VenueSelector from '@/components/selectors/VenueSelector';
import BaseWarningIcon from '@/components/base/icons/BaseWarningIcon';
import OrderItemsTooltip from '@/components/widgets/OrderItemsTooltip';
import DateRangeSelector from '@/components/widgets/DateRangeSelector';
import ToggleSidebarButton from '@/components/helpers/ToggleSidebarButton';

export default {
    name: 'PayAtTableList',
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Tables'
    },
    components: {
        BaseWarningIcon,
        VclList,
        VenueSelector,
        ToggleSidebarButton,
        TicketSidebar,
        Avatar,
        OrderItemsTooltip,
        DateRangeSelector
    },
    data() {
        const defaultDateRange = {
            startDate: null,
            endDate: null
        };

        return {
            tables: '',
            venues: [],
            venueFilter: null,
            isLoading: false,
            closedBills: false,
            billsLoading: false,
            tablesLoading: false,
            ticketSidebar: false,
            editedTicket: {},
            bills: [],
            openTables: [],
            tableSearchQuery: null,
            dateRange: {
                startDate: null,
                endDate: null
            },
            defaultDateRange,
            pickerStyles: 'md:h-7 ml-2'
        };
    },
    async created() {
        await this.fetchVenues();
        const { bill, venueId } = this.$route.query;

        if (bill && venueId) {
            this.ticketSidebar = true;
        }
    },
    methods: {
        async fetchVenues() {
            this.isLoading = true;

            try {
                const { data } = await this.$axios.get('/venues', {
                    params: { acceptsPat: true }
                });

                this.venues = data;
                this.venueFilter = [data[0].id];
                this.setVenue(data[0].id);
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        },
        async fetchBills(isLoading) {
            this.billsLoading = true;
            if (isLoading) {
                this.isLoading = true;
            }

            const params = {
                venueId: this.venueFilter,
                closed: this.closedBills,
                from: this.closedBills ? this.dateRange.startDate : null,
                to: this.closedBills ? this.dateRange.endDate : null
            };

            try {
                const { data } = await this.$axios.get('/bills/list', {
                    params
                });

                this.bills = data;
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
                this.billsLoading = false;
                this.dateRange = this.defaultDateRange;
            }
        },
        async fetchOpenTables() {
            this.tablesLoading = true;

            try {
                const { data } = await this.$axios.get('/bills/tables', {
                    params: {
                        venueId: this.venueFilter
                    }
                });

                this.openTables = data;
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.tablesLoading = false;
            }
        },
        setVenue(venueId) {
            this.venueFilter = this.venueById(venueId);
            this.fetchBills();
            this.fetchOpenTables();
        },
        venueById(id) {
            const venue = this.venues.find(venue => venue.id === parseInt(id));
            if (venue) {
                return venue.id;
            } else return '';
        },
        openTicketSidebar(billId, venueId) {
            this.$router.push({
                path: this.$route.path,
                query: { bill: billId, venueId: venueId }
            });

            this.ticketSidebar = true;
        },
        updateDateRange(val) {
            this.dateRange = val;

            this.fetchBills();
        }
    },
    computed: {
        filteredBills() {
            if (this.tableSearchQuery) {
                return this.bills.filter(
                    bill => bill.table.posId === this.tableSearchQuery
                );
            }
            return this.bills;
        },
        filteredOpenTables() {
            if (this.tableSearchQuery) {
                return this.openTables.filter(
                    openTable =>
                        openTable.tableNumber.toString() ===
                            this.tableSearchQuery &&
                        openTable.tableNumber.toString() !== '0'
                );
            }
            return this.openTables;
        }
    }
};
</script>

<style scoped></style>
