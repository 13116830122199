const state = {
    sidebarOpen: false,
    logoUrl: process.env.VUE_APP_WIHTELABEL_LOGO,
    logoWhiteUrl: process.env.VUE_APP_WIHTELABEL_LOGO_LIGHT,
    isWebExtension: false
};

const mutations = {
    UPDATE_SIDEBAR(state, payload) {
        state.sidebarOpen = payload;
    },
    SET_IS_WEB_EXTENSION(state, payload) {
        state.isWebExtension = payload;
    }
};

const getters = {
    isWebExtension(state) {
        return state.isWebExtension;
    }
};

export default {
    namespaced: true,
    getters,
    mutations,
    state
};
