<template>
    <base-button
        :href="'https://frontend.deliverect.com/orders/' + deliverectId"
        button-text="View in Deliverect"
        size="sm"
        class="mt-2"
    >
        <template #left-icon>
            <base-deliverect-icon class="mr-1 h-5 w-5" />
        </template>
    </base-button>
</template>

<script>
export default {
    name: 'ViewInDeliverectButton',
    props: {
        deliverectId: {
            type: String,
            required: true
        }
    }
};
</script>
