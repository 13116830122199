<template>
    <div>
        <div class="md:flex md:items-center md:justify-between">
            <div class="min-w-0 flex-1">
                <router-link
                    :to="{ name: 'editVenue' }"
                    class="mb-2 flex inline-flex items-center text-sm text-gray-500"
                >
                    <base-back-icon />
                    <span>Settings</span>
                </router-link>
                <base-page-title title="Kitchen CUT Integration" />
            </div>

            <BaseSpinner :isLoading="isLoading" />
        </div>
        <div>
            <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                        >
                            Details
                        </h3>
                        <p class="mt-2 text-sm text-gray-500">
                            Enter your Kitchen CUT API credentials and enable
                            the integration.
                            <br /><br />You'll need a Kitchen CUT account,
                            <a
                                class="text-indigo-600"
                                href="https://www.kitchencut.com/"
                                rel="noopener"
                                target="_blank"
                                >create one here</a
                            >.
                        </p>
                    </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0" v-if="!isLoading">
                    <div class="shadow sm:rounded-md">
                        <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
                            <div class="grid grid-cols-3 gap-6">
                                <div class="sm:col-span-6">
                                    <base-input-field
                                        v-model="kitchenCut.tenantUrl"
                                        type="url"
                                        prefix="https://"
                                        label="Tenant URL"
                                        id="tenant-url"
                                        placeholder="your-domain.kitchencut.com"
                                        :errors="$v.kitchenCut.tenantUrl.$error"
                                        errorMessage="This field is required"
                                    />
                                </div>

                                <div class="sm:col-span-6">
                                    <base-input-field
                                        v-model="kitchenCut.apiKey"
                                        type="text"
                                        label="API key"
                                        id="api-key"
                                        placeholder="e.g. 1V7e2IkiIK6iuXGvV8U8GzekBKW3OblQnHdKMBFP"
                                        :errors="$v.kitchenCut.apiKey.$error"
                                        errorMessage="This field is required"
                                    />
                                </div>

                              <div class="sm:col-span-6">
                                <base-input-field
                                    v-model="kitchenCut.outletEposId"
                                    type="text"
                                    label="Outlet ID"
                                    id="outlet-id"
                                    placeholder="e.g. 4"
                                    :errors="$v.kitchenCut.outletEposId.$error"
                                    errorMessage="This field is required"
                                />
                              </div>

                              <div class="sm:col-span-6">
                                <base-input-field
                                    v-model="kitchenCut.outletName"
                                    type="text"
                                    label="Outlet Name"
                                    id="outlet-name"
                                    placeholder="e.g. 'Bar'"
                                    :errors="$v.kitchenCut.outletName.$error"
                                    errorMessage="This field is required"
                                />
                              </div>

                                <div
                                    class="sm:col-span-6"
                                    :class="{
                                        'opacity-50': statusSwitchDisabled
                                    }"
                                >
                                    <label
                                        for="enabled"
                                        class="block text-sm font-medium leading-5 text-gray-700"
                                    >
                                        Integration Status
                                    </label>

                                    <div class="mt-2 flex items-start">
                                        <div class="w-full" v-auto-animate>
                                            <div
                                                class="flex flex-row items-center"
                                                @click="$v.$touch()"
                                            >
                                                <toggle-switch
                                                    v-model="kitchenCut.enabled"
                                                    :isDisabled="
                                                        statusSwitchDisabled
                                                    "
                                                    @toggle-enable="
                                                        toggleEnabled
                                                    "
                                                />
                                                <label
                                                    class="ml-2 text-sm text-gray-500"
                                                >
                                                    {{
                                                        kitchenCut.enabled
                                                            ? 'Enabled - sending orders to Kitchen CUT'
                                                            : 'Disabled - not sending orders to Kitchen CUT'
                                                    }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            <div class="sm:col-span-6 flex pt-4 border-t border-gray-200">
                              <DateRangeSelector
                                  @date-updated="date = $event.startDate"
                                  :single-date="true"
                                  :allow-future="false"
                                  class="mr-2"
                              />
                              <base-button button-text="Fetch Sales Data"  class="mr-2" @clicked="fetchSales" />
                              <base-button button-text="Sync Sales Data"   class="mr-2" @clicked="syncSales" />
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <BaseSpacerWithRuler />

        <div class="my-6 ml-4 flex flex-shrink-0 justify-end">
            <span
                class="inline-flex rounded-md shadow-sm"
                @click="saveSettings"
            >
                <button
                    type="button"
                    class="focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                    :disabled="isLoading"
                >
                    Save
                </button>
            </span>
        </div>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import ToggleSwitch from '@/components/formComponents/ToggleSwitch';
import { mapGetters } from 'vuex';
import DateRangeSelector from "@/components/widgets/DateRangeSelector";

export default {
    name: 'KitchenCutSettings',
    components: {DateRangeSelector, ToggleSwitch },
    metaInfo: {
        title: 'Kitchen CUT Integration'
    },
    props: {
        venueId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            isLoading: false,
            statusSwitchDisabled: false,
            date: '',
            kitchenCut: {
                tenantUrl: null,
                apiKey: null,
                enabled: false,
                outletEposId: null,
                outletName: null
            }
        };
    },
    validations: {
        kitchenCut: {
            tenantUrl: {
                required
            },
            apiKey: {
                required
            },
            outletEposId: {
              required
            },
            outletName: {
              required
            }
        }
    },
    async created() {
        await this.fetchSettings();

        this.kitchenCut.enabled =
            this.venue.integrations.kitchenCut?.enabled || false;
        this.kitchenCut.outletEposId = this.venue.integrations.kitchenCut?.outletEposId || null;
        this.kitchenCut.outletName = this.venue.integrations.kitchenCut?.outletName || null;

    },
    computed: {
        ...mapGetters({
            venue: 'venues/getVenue'
        })
    },
    methods: {
        async fetchSettings() {
            try {
                this.isLoading = true;

                const { data } = await this.$axios.get('/kitchen-cut/settings');

                this.kitchenCut = { ...this.kitchenCut, ...data };
                this.statusSwitchDisabled = !data.apiKey || !data.tenantUrl;
            } catch (e) {
                throw new Error(`API ${e} `);
            } finally {
                this.isLoading = false;
            }
        },

        async toggleEnabled() {
            try {
                this.kitchenCut.enabled = !this.kitchenCut.enabled;

                await this.$axios.post('/kitchen-cut/toggle', {
                    venueId: this.venueId,
                    enabled: this.kitchenCut.enabled
                });
            } catch (error) {
                this.kitchenCut.enabled = !this.kitchenCut.enabled;
                throw new Error(`API ${error}`);
            }
        },

        async saveSettings() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return false;
            }

            try {
                this.isLoading = true;

                await this.$axios.put(`/kitchen-cut/${this.venue.id}/settings`, {
                    outletEposId: this.kitchenCut.outletEposId,
                    outletName: this.kitchenCut.outletName
                });

                this.statusSwitchDisabled = false;

                this.$notify({
                    group: 'settings',
                    title: 'Settings saved'
                });
            } catch (error) {
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.message
                ) {
                    this.$modal.show('custom-error-modal', {
                        text:
                            'Sorry, there was an error while updating the Kitchen CUT integration, use the live chat button for help.'
                    });

                    this.kitchenCut.enabled = false;
                }

                throw new Error(`API ${error} `);
            } finally {
                this.isLoading = false;
            }
        },

      async fetchSales() {
        try {
          this.isLoading = true;
          const response = await this.$axios.get(`/kitchen-cut/sales-data`, {
            params: {
              venueId: this.venue.id,
              date: this.date
            }
          });

          // Create a Blob from the JSON response
          const blob = new Blob([JSON.stringify(response.data)], { type: 'application/json' });
          const url = window.URL.createObjectURL(blob);

          // Create a link to download the file
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `sales-data-${this.venue.id}-${this.date}.json`);

          // Append the link to the body
          document.body.appendChild(link);

          // Trigger the download
          link.click();

          // Clean up and remove the link
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);

          this.$notify({
            group: 'settings',
            title: 'Sales data fetched and downloaded'
          });
        } catch (error) {
          throw new Error(`API ${error}`);
        } finally {
          this.isLoading = false;
        }
      },

      async syncSales() {
        try {
          this.isLoading = true;
          await this.$axios.post(`/kitchen-cut/sync-data`, {
              venueId: this.venue.id,
              date: this.date
          });
          this.$notify({
            group: 'settings',
            title: 'Sales data synced'
          });
        } catch (error) {
          throw new Error(`API ${error}`);
        } finally {
          this.isLoading = false;
        }
      }
    }
};
</script>
