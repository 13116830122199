<template>
  <div>
    <div class="md:flex md:items-center md:justify-between">
      <div class="min-w-0 flex-1">
        <router-link
            :to="{ name: 'editVenue' }"
            class="mb-2 flex items-center text-sm text-gray-500 inline-flex"
        >
          <base-back-icon />
          <span>Settings</span>
        </router-link>
        <base-page-title title="Giftpro Integration" />
      </div>

      <BaseSpinner :isLoading="isLoading" />
    </div>
    <div v-if="!isLoading && !showSettings" class="py-8">
      <FeaturePanelWithScreenshot
          title="Giftpro"
          description="Allow guests to redeem gift vouchers"
          :features="acteolFeatures"
          image="https://ucarecdn.com/c1a34ba3-a1b2-472f-81d7-9aecb6a9cdd1/-/format/auto/"
      >
        <template #cta>
          <base-button
              v-if="
                  isFeatureAvailable(FeaturesEnum.SAAS) ||
                  isFeatureAvailable(FeaturesEnum.INTERNAL)
              "
              class="mt-2 sm:ml-3 sm:mt-0"
              @clicked="showSettings = true"
              buttonText="Enable Giftpro"
          />
          <base-upsell-button
              v-else
              upsell-item="giftpro"
              buttonText="Upgrade to enable Giftpro"
              :disabled="isLoading"
          ></base-upsell-button>
        </template>
      </FeaturePanelWithScreenshot>
    </div>
    <div v-if="!isLoading && showSettings">
      <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="sm:pl-0 sm:pr-5">
            <h3
                class="text-base font-semibold leading-6 text-gray-800"
            >Details</h3
            >
            <p class="mt-2 text-sm text-gray-500">
              Connect to Giftpro CRM
            </p>
          </div>
        </div>
        <div class="mt-5 md:col-span-2 md:mt-0">
          <div class="shadow sm:overflow-hidden sm:rounded-md">
            <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
              <div class="grid grid-cols-3 gap-6">
                <div class="col-span-3">
                  <base-password-input value="12345" input-id="apiKey" label="Giftpro API Key" :disabled="true" />
                </div>
                <div class="col-span-3">
                  <label
                      for="paymentType"
                      class="mb-1 flex items-center text-sm font-medium leading-5 text-gray-700"
                      v-tooltip="'The giftpro location is used to check if a voucher can be used at the venue.'"
                  >
                    Giftpro location
                    <base-question-icon
                        class="ml-1"
                    />
                  </label>
                  <div class="relative mt-1 rounded-md">
                    <select
                        id="locationId"
                        name="locationId"
                        v-model="giftpro.locationId"
                        class="focus:outline-none form-input mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    >
                      <option
                          v-for="location in this.locations"
                          :key="`location-${location.locationID}`"
                          :value="location.locationID"
                      >
                        {{ location.name }} ({{ location.locationID }})
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-span-3 flex flex-col">
                  <label
                      class="flex items-center text-sm font-medium leading-5 text-gray-700 pb-2"
                      v-tooltip="'Enable the integration to display a gift card code input box at checkout.'"
                  >
                    Integration enabled
                    <base-question-icon
                        class="ml-1"
                    />
                  </label>
                  <base-small-toggle-switch
                      v-model="giftpro.enabled"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="my-6 ml-4 flex flex-shrink-0 justify-end">
        <base-button @clicked="saveVenue" button-text="Save" size="sm" :disabled="isLoading" />
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import FeaturePanelWithScreenshot from "@/components/widgets/FeaturePanelWithScreenshot.vue";
import { FeaturesEnum } from "@/enums";
import { mapGetters } from "vuex";
import BasePasswordInput from "@/components/base/form/BasePasswordInput.vue";

export default {
  name: 'GiftproSettings',
  components: {BasePasswordInput, FeaturePanelWithScreenshot },
  props: ['venueId', 'venue'],
  metaInfo: {
    title: 'Giftpro Settings'
  },
  data() {
    return {
      isLoading: false,
      showSettings: false,
      settings: { ...this.venue },
      giftpro: {
        locationId: null,
        enabled: false
      },
      FeaturesEnum,
      locations: [],
      acteolFeatures: [
        {
          name: 'Redemption',
          description:
              'Guests can redeem gift vouchers in the checkout flow.'
        }
      ]
    };
  },
  validations: {
    giftpro: {
      locationId: {
        required
      }
    }
  },
  async mounted() {
    await this.checkGiftproAccount();
    if (this.settings?.integrations?.giftpro) {
      this.giftpro = this.settings.integrations.giftpro;
    }

    if (this.showSettings) {
      this.fetchLocations();
    }
  },
  computed: {
    ...mapGetters({
      isFeatureAvailable: 'user/isFeatureAvailable'
    })
  },
  methods: {
    async checkGiftproAccount() {
      try {
        this.isLoading = true;
        const { data } = await this.$axios.get(`/account/check`,{
          params: {
            columnName: 'giftpro'
          }
        })

        this.showSettings = !data ? false : true;
      }
      catch (e) {
        throw new Error(e)
      }
      finally {
        this.isLoading = false;
      }
    },
    async saveVenue() {
      try {
        this.isLoading = true;

        this.$v.giftpro.$touch();

        if (this.$v.giftpro.$invalid) {
          return false;
        }

        await this.$axios.post(`/giftpro/update/`,
            {...this.giftpro, venueId: this.venueId}
        );

        this.$notify({
          group: 'settings',
          title: 'Settings saved'
        });
      } catch (e) {
        throw new Error(`API ${e}`);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchLocations() {
      try {
        const { data } = await this.$axios.get(
            `/giftpro/locations`
        );
        this.locations = data;
      } catch (error) {
        this.$notify({
          group: 'settings',
          title: 'Something went wrong'
        });

        throw new Error(`API ${error}`);
      }
    }
  }
};
</script>
