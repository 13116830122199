var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tr',{staticClass:"mt-2 w-full bg-white"},[_c('td',{staticClass:"whitespace-nowrap"},[_c('div',{staticClass:"my-2 pl-3"},[_c('span',{staticClass:"inline-block cursor-pointer text-sm leading-5"},[_c('select',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.offerSlot.day.$model),expression:"offerSlot.day.$model",modifiers:{"trim":true}}],staticClass:"focus:outline-none w-26 inline-block rounded-md border border-gray-300 py-2 pl-3 pr-9 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5",attrs:{"id":"type","name":"type"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.offerSlot.day, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.days),function(day,index){return _c('option',{key:index,domProps:{"value":day.value,"selected":index === 0}},[_vm._v(" "+_vm._s(day.label)+" ")])}),0)])])]),_c('td',{staticClass:"whitespace-nowrap"},[_c('div',{staticClass:"relative flex flex-row items-center pl-3"},[_c('vue-timepicker',{class:{
                    'opacity-25': false,
                    error: _vm.offerSlot.startTime.$error
                },attrs:{"manual-input":"","minute-interval":15,"id":'hours-' + _vm.index,"disabled":false},model:{value:(_vm.offerSlot.startTime.$model),callback:function ($$v) {_vm.$set(_vm.offerSlot.startTime, "$model", $$v)},expression:"offerSlot.startTime.$model"}})],1)]),_c('td',{staticClass:"whitespace-nowrap"},[_c('div',{staticClass:"relative flex flex-row items-center pl-3"},[_c('vue-timepicker',{class:{
                    'opacity-25': false,
                    error: _vm.offerSlot.endTime.$error
                },attrs:{"manual-input":"","minute-interval":15,"id":'hours-' + _vm.index,"disabled":false},model:{value:(_vm.offerSlot.endTime.$model),callback:function ($$v) {_vm.$set(_vm.offerSlot.endTime, "$model", $$v)},expression:"offerSlot.endTime.$model"}})],1)]),_c('td',{staticClass:"whitespace-nowrap"},[_c('div',{staticClass:"flex whitespace-nowrap px-2 py-2"},[_c('div',{staticClass:"group my-1 cursor-pointer text-gray-400",on:{"click":function($event){return _vm.remove(_vm.offerSlot)}}},[_c('svg',{staticClass:"h-5 w-5 group-hover:text-red-400",attrs:{"fill":"none","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"d":"M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"}})])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }