var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"md:flex md:items-center md:justify-between"},[_c('div',{staticClass:"min-w-0 flex-1"},[_c('router-link',{staticClass:"mb-2 flex items-center text-sm text-gray-500 inline-flex",attrs:{"to":{ name: 'editVenue' }}},[_c('base-back-icon'),_c('span',[_vm._v("Settings")])],1),_c('base-page-title',{attrs:{"title":"Feedback Settings"}})],1),_c('BaseSpinner',{attrs:{"isLoading":_vm.isLoading}})],1),_c('div',[_c('div',{staticClass:"mt-4 md:grid md:grid-cols-3 md:gap-6"},[_vm._m(0),_c('div',{staticClass:"mt-5 md:col-span-2 md:mt-0"},[_c('div',{staticClass:"shadow sm:overflow-hidden sm:rounded-md"},[_c('div',{staticClass:"space-y-6 bg-white px-4 py-5 sm:p-6"},[_c('div',{staticClass:"grid grid-cols-3 gap-6"},[_c('div',{staticClass:"col-span-3 sm:col-span-2"},[_c('label',{staticClass:"mb-2 block text-sm font-medium leading-5 text-gray-700"},[_vm._v("Enable feedback")]),_c('toggle-switch',{model:{value:(
                                        _vm.feedbackSettings.orderReviewsEnabled
                                    ),callback:function ($$v) {_vm.$set(_vm.feedbackSettings, "orderReviewsEnabled", $$v)},expression:"\n                                        feedbackSettings.orderReviewsEnabled\n                                    "}})],1),_c('div',{staticClass:"col-span-6 sm:col-span-6 lg:col-span-3"},[_c('span',{staticClass:"text-sm leading-5 text-gray-500"},[_vm._v("Notification delay")]),_c('div',{staticClass:"relative mt-1 w-full rounded-md shadow-sm"},[_c('currency-input',{staticClass:"form-input block w-full pr-12 sm:text-sm sm:leading-5",attrs:{"id":"prepTime","placeholder":"60","currency":null,"precision":0,"locale":"en-GB","value-as-integer":true,"allow-negative":false},model:{value:(
                                            _vm.feedbackSettings.orderReviewDelay
                                        ),callback:function ($$v) {_vm.$set(_vm.feedbackSettings, "orderReviewDelay", $$v)},expression:"\n                                            feedbackSettings.orderReviewDelay\n                                        "}}),_vm._m(1)],1),_c('p',{staticClass:"mt-2 text-sm text-gray-500"},[_vm._v("Add a delay to when the feedback email is sent to your customer. We'll use the expected delivery time + the number in minutes you set here. ")])]),_c('div',{staticClass:"col-span-3 sm:col-span-2"},[_c('label',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Only send feedback requests to customers who have opted in to receive marketing communications.'),expression:"'Only send feedback requests to customers who have opted in to receive marketing communications.'"}],staticClass:"mb-2 flex items-center text-sm font-medium leading-5 text-gray-700"},[_vm._v("Opt-in only "),_c('base-question-icon',{staticClass:"ml-1 text-gray-700"})],1),_c('toggle-switch',{model:{value:(_vm.feedbackSettings.optInOnly),callback:function ($$v) {_vm.$set(_vm.feedbackSettings, "optInOnly", $$v)},expression:"feedbackSettings.optInOnly"}})],1)])])])])])]),_c('BaseSpacerWithRuler'),_c('div',{staticClass:"my-6 ml-4 flex flex-shrink-0 justify-end"},[_c('span',{staticClass:"inline-flex rounded-md shadow-sm",on:{"click":_vm.saveVenue}},[_c('button',{staticClass:"focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700",attrs:{"type":"button","disabled":_vm.isLoading}},[_vm._v(" Save ")])])]),(_vm.isFeatureAvailable(_vm.FeaturesEnum.INTERNAL) || _vm.isFeatureAvailable(_vm.FeaturesEnum.SAAS))?_c('div',[_c('SmartReviewSettings',{attrs:{"venue":_vm.venue}})],1):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md:col-span-1"},[_c('div',{staticClass:"sm:pl-0 sm:pr-5"},[_c('h3',{staticClass:"text-base font-semibold leading-6 text-gray-800"},[_vm._v("Details")]),_c('p',{staticClass:"mt-2 text-sm text-gray-500"},[_vm._v(" Enable to get feedback from customers on each order. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"},[_c('span',{staticClass:"text-gray-500 sm:text-sm sm:leading-5"},[_vm._v(" Minutes ")])])
}]

export { render, staticRenderFns }