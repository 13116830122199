var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"md:flex md:items-center md:justify-between"},[_c('div',{staticClass:"min-w-0 flex-1"},[_c('router-link',{staticClass:"mb-2 flex inline-flex items-center text-sm text-gray-500",attrs:{"to":{ name: 'editVenue' }}},[_c('base-back-icon'),_c('span',[_vm._v("Settings")])],1),_c('base-page-title',{attrs:{"title":"Tissl Integration"}})],1),_c('BaseSpinner',{attrs:{"isLoading":_vm.isLoading}})],1),_c('div',[_c('div',{staticClass:"mt-4 grid-cols-1 md:grid md:grid-cols-3 md:gap-6"},[_vm._m(0),(!_vm.isLoading)?_c('div',{staticClass:"mt-5 md:col-span-2 md:mt-0"},[_c('div',{staticClass:"shadow sm:overflow-hidden sm:rounded-md"},[_c('div',{staticClass:"space-y-6 bg-white px-4 py-5 sm:p-12"},[_c('div',{staticClass:"grid grid-cols-1 gap-6 md:grid-cols-3"},[_c('div',{staticClass:"sm:col-span-6"},[_c('base-button',{attrs:{"buttonText":"Fetch Items"},on:{"clicked":_vm.fetchItems}})],1),_c('div',{staticClass:"sm:col-span-6"},[_c('base-input-field',{attrs:{"type":"text","label":"Organisation ID","id":"organisationId","value":_vm.$store.state.user.profile
                                            ?.accounts[0]?.tissl
                                            ?.organisationId,"disabled":""}})],1),_c('div',{staticClass:"col-span-6"},[_c('label',{staticClass:"mb-1 flex items-center text-sm font-medium leading-5 text-gray-700",attrs:{"for":"site"}},[_vm._v(" Site "),_c('base-question-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                                            'The site ID provided by tissl'
                                        ),expression:"\n                                            'The site ID provided by tissl'\n                                        "}],staticClass:"ml-1"})],1),_c('div',{staticClass:"relative mt-1 rounded-md"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.tissl.siteId),expression:"tissl.siteId"}],staticClass:"focus:outline-none form-input mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm",attrs:{"id":"site","name":"site"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.tissl, "siteId", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((this
                                                .tisslOptions.sites),function(site,index){return _c('option',{key:`site-${index}`,domProps:{"value":site.id}},[_vm._v(" "+_vm._s(site.name)+" ")])}),0)])]),_c('div',{staticClass:"col-span-6"},[_c('label',{staticClass:"mb-1 flex items-center text-sm font-medium leading-5 text-gray-700",attrs:{"for":"paymentType"}},[_vm._v(" Payment Type "),_c('base-question-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                                            'Name of a payment type to map storekit payments to'
                                        ),expression:"\n                                            'Name of a payment type to map storekit payments to'\n                                        "}],staticClass:"ml-1"})],1),_c('div',{staticClass:"relative mt-1 rounded-md"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.tissl.paymentType),expression:"tissl.paymentType"}],staticClass:"focus:outline-none form-input mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm",attrs:{"id":"paymentType","name":"paymentType"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.tissl, "paymentType", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((this.tisslOptions
                                                .paymentTypes),function(paymentType,index){return _c('option',{key:`payment-type-${index}`,domProps:{"value":paymentType}},[_vm._v(" "+_vm._s(paymentType.name)+" ")])}),0)])]),_c('div',{staticClass:"col-span-6"},[_c('label',{staticClass:"mb-1 flex items-center text-sm font-medium leading-5 text-gray-700",attrs:{"for":"course"}},[_vm._v(" Course ")]),_c('div',{staticClass:"relative mt-1 rounded-md"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.tissl.course),expression:"tissl.course"}],staticClass:"focus:outline-none form-input mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm",attrs:{"id":"course","name":"course"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.tissl, "course", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((this
                                                .tisslOptions.courses),function(course,index){return _c('option',{key:`course-${index}`,domProps:{"value":course}},[_vm._v(" "+_vm._s(course.description)+" ")])}),0)])]),_c('div',{staticClass:"col-span-6"},[_c('label',{staticClass:"mb-1 flex items-center text-sm font-medium leading-5 text-gray-700",attrs:{"for":"orderType"}},[_vm._v(" Order Type ")]),_c('div',{staticClass:"relative mt-1 rounded-md"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.tissl.orderType),expression:"tissl.orderType"}],staticClass:"focus:outline-none form-input mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm",attrs:{"id":"orderType","name":"orderType"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.tissl, "orderType", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((this.tisslOptions
                                                .orderTypes),function(orderType,index){return _c('option',{key:`payment-type-${index}`,domProps:{"value":orderType}},[_vm._v(" "+_vm._s(orderType.description)+" ")])}),0)])]),_c('div',{staticClass:"sm:col-span-6"},[_c('toggle-switch',{attrs:{"id":"buffer-orders","label":"Buffer Orders","tooltip":"Orders are buffered in storekit and are only sent to the POS when they need to be prepared. This is calculated using the preparation time set for delivery and pickup orders"},model:{value:(_vm.tissl.bufferOrders),callback:function ($$v) {_vm.$set(_vm.tissl, "bufferOrders", $$v)},expression:"tissl.bufferOrders"}})],1),_c('div',{staticClass:"flex sm:col-span-6"},[_c('toggle-switch',{attrs:{"id":"integration-status","label":"Integration Status","extra-text-off":"Disabled - not sending orders to Tissl","extra-text-on":"Enabled - sending orders to tissl"},on:{"toggle-enable":_vm.toggleEnabled},model:{value:(_vm.tissl.enabled),callback:function ($$v) {_vm.$set(_vm.tissl, "enabled", $$v)},expression:"tissl.enabled"}})],1)])])])]):_vm._e()])]),_c('BaseSpacerWithRuler'),_c('div',{staticClass:"my-6 ml-4 flex flex-shrink-0 justify-end"},[_c('span',{staticClass:"inline-flex rounded-md shadow-sm",on:{"click":_vm.updateSettings}},[_c('button',{staticClass:"focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700",attrs:{"type":"button","disabled":_vm.isLoading}},[_vm._v(" Save ")])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md:col-span-1"},[_c('div',{staticClass:"sm:pl-0 sm:pr-5"},[_c('h3',{staticClass:"text-base font-semibold leading-6 text-gray-800"},[_vm._v(" Details ")]),_c('p',{staticClass:"mt-2 text-sm text-gray-500"},[_vm._v(" Connect storekit to Tissl EPOS. When enabled, your orders will be automatically imported into tissl. ")])])])
}]

export { render, staticRenderFns }