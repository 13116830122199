<template>
    <div>
        <div>
            <div>
                <p class="text-gray-500 text-sm leading-5">
                    Use an existing modifier group. Note that any changes made
                    to a shared modifier group will be applied against all
                    products
                </p>
            </div>

            <div
                class="flex flex-col mt-4"
                v-if="modifierGroups && modifierGroups.length > 0"
            >
                <div class="py-2">
                    <div
                        class="inline-block align-middle w-full border border-gray-200 rounded overflow-hidden"
                    >
                        <table class="table-fixed w-full">
                            <thead>
                                <tr>
                                    <th
                                        class="px-4 py-3 w-8/12 text-left text-gray-500 truncate text-xs font-medium tracking-wider leading-4 bg-gray-50 border-b border-gray-200 uppercase hover:"
                                    >
                                        Group
                                    </th>
                                    <th
                                        class="px-4 py-3 w-4/12 bg-gray-50 border-b border-gray-200"
                                    ></th>
                                </tr>
                            </thead>
                            <tbody class="bg-white">
                                <tr
                                    class="w-full bg-white"
                                    v-for="group in modifierGroups"
                                    :key="group.id"
                                >
                                    <td
                                        class="whitespace-nowrap pl-4 pr-4 py-4 w-8/12 text-gray-400 truncate text-sm font-medium leading-4 border-b border-gray-200"
                                    >
                                        <span
                                            v-tooltip="
                                                group.name.length > 50
                                                    ? group.name
                                                    : false
                                            "
                                            class="inline-block text-gray-500"
                                            title="group.name"
                                        >
                                            {{ group.name }}
                                        </span>
                                        <span
                                            v-if="group.plu"
                                            class="inline-block pl-3 text-gray-400 text-xs"
                                            >SKU: {{ group.plu }}</span
                                        >
                                        <span
                                            class="ml-2 inline-block cursor-pointer text-xs"
                                        >
                                            <v-dropdown
                                                distance="5"
                                                :triggers="['click']"
                                                popperClass="modifier-items-popover"
                                            >
                                                <div
                                                    v-if="group.products.length"
                                                    class="group flex select-none items-center rounded-md px-4 py-2 hover:bg-gray-100"
                                                >
                                                    <span>items</span>
                                                    <span
                                                        class="m-auto block h-4 w-4 text-gray-600 opacity-0 group-hover:opacity-100"
                                                    >
                                                        <svg
                                                            viewBox="0 0 20 20"
                                                            fill="currentColor"
                                                            focusable="false"
                                                            aria-hidden="true"
                                                        >
                                                            <path
                                                                d="M5 8l5 5 5-5H5z"
                                                            ></path>
                                                        </svg>
                                                    </span>
                                                </div>
                                                <template #popper>
                                                    <modifier-group-items-tooltip
                                                        :items="group.products"
                                                    />
                                                </template>
                                            </v-dropdown>
                                        </span>
                                    </td>
                                    <td
                                        class="whitespace-nowrap px-4 py-4 w-4/12 text-right text-sm font-medium leading-4 border-b border-gray-200"
                                    >
                                        <span
                                            v-if="isLoading && group.isLoading"
                                            class="spinner h-40"
                                        ></span>
                                        <div v-else>
                                            <span
                                                v-if="
                                                    !productHasModifier(
                                                        group.id
                                                    )
                                                "
                                                @click="
                                                    addGroupToProduct(group)
                                                "
                                                class="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                                            >
                                                Add group to product
                                            </span>
                                            <span v-else class="text-gray-600">
                                                Already added
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div v-else-if="isLoading" class="spinner h-40"></div>
            <div v-else class="flex items-center justify-center h-40">
                <span class="text-gray-600 font-medium">
                    No modifier groups available
                </span>
            </div>
            <div
                class="flex-row-reverse justify-between mt-5 select-none sm:flex sm:mt-12 sm:w-auto"
            >
                <div class="flex flex-col md:flex-row">
                    <span
                        class="flex mt-3 w-full rounded-md shadow-sm sm:mt-0 sm:w-auto"
                    >
                        <button
                            @click="$emit('stop-editing')"
                            type="button"
                            class="inline-flex justify-center px-4 py-2 w-full hover:text-gray-500 text-gray-700 text-base font-medium leading-6 bg-white border focus:border-blue-300 border-gray-300 rounded-md focus:outline-none focus:shadow-outline shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                        >
                            Go Back
                        </button>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ModifierGroupItemsTooltip from '@/components/widgets/ModifierGroupItemsTooltip';

export default {
    name: 'ExistingModiferGroups',
    components: { ModifierGroupItemsTooltip },
    props: {
        product: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            selectedModifierGroup: null,
            modifierGroups: null,
            isLoading: true
        };
    },
    async created() {
        await this.fetchGroups();
    },
    methods: {
        async fetchGroups() {
            this.isLoading = true;

            try {
                const { data } = await this.$axios.get(
                    `/menus/${this.$route.params.id}/modifiers`
                );

                this.modifierGroups = data;
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        },
        emitGroup(id) {
            this.$emit('edit-group', id);
        },
        async addGroupToProduct(group) {
            group.isLoading = true;
            this.isLoading = true;

            try {
                await this.$axios.put(
                    `/products/${this.product.id}/groups/${group.id}`
                );

                this.$emit('product-added');
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                setTimeout(() => {
                    group.isLoading = false;
                    this.isLoading = false;
                }, 1000);
            }
        },
        productHasModifier(groupId) {
            return this.product.modifierGroups.find(
                group => group.id === groupId
            );
        }
    }
};
</script>

<style>
.z-1000 {
    z-index: 1000;
}
</style>
