<template>
    <div class="mt-5 overflow-hidden bg-white shadow print:shadow-none sm:rounded-lg">
        <div class="border-b border-gray-200">
            <div class="flex flex-col">
                <div class="flex w-full items-center justify-between px-3 py-2">
                    <div class="flex items-center">
                        <div class="flex">
                            <span
                                class="inline-flex items-center rounded-md bg-green-50 px-2 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-300"
                            >
                                <svg
                                    class="-ml-0.5 mr-1 h-4 w-4 text-green-400"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M5 13l4 4L19 7"
                                    ></path>
                                </svg>
                                Paid
                            </span>
                        </div>
                    </div>
                    <div>
                        <span class="inline-flex rounded-md shadow-sm">
                            <button
                                type="button"
                                v-tooltip="
                                    !isTotalRefunded
                                        ? 'Open the refund order window'
                                        : 'Full amount already refunded'
                                "
                                :disabled="isTotalRefunded"
                                :class="{
                                    'cursor-not-allowed opacity-50': isTotalRefunded
                                }"
                                class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-2 py-1.5 text-xs font-medium leading-4 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
                                @click="showRefundModal(order, false)"
                            >
                                Refund order
                            </button>
                        </span>
                    </div>
                </div>
                <div
                    class="flex w-full flex-row justify-between bg-gray-50 px-4 py-2"
                    v-if="saleTransaction"
                >
                    <div class="box-divider pr-3">
                        <p class="mt-1 text-xs sm:text-sm leading-6 text-gray-500">
                            {{
                                saleTransaction.created_at
                                    | moment('MMM D, h:mm a')
                            }}
                        </p>
                    </div>
                    <div
                        class="box-divider px-2"
                        v-if="saleTransaction.paymentDetails"
                    >
                        <div class="mt-1 flex">
                            <img
                                class="h-5 rounded-md border border-gray-100"
                                src="@/assets/visa.svg"
                                alt="Visa"
                                v-if="
                                    saleTransaction.paymentDetails
                                        .paymentMethod === 'visa'
                                "
                            />
                            <img
                                class="h-5 rounded-md border border-gray-100"
                                src="@/assets/mc.svg"
                                alt="Mastercard"
                                v-else-if="
                                    saleTransaction.paymentDetails
                                        .paymentMethod === 'mc'
                                "
                            />
                            <img
                                class="h-5 rounded-md border border-gray-100"
                                src="@/assets/amex.svg"
                                alt="American Express"
                                v-else-if="
                                    saleTransaction.paymentDetails
                                        .paymentMethod === 'amex'
                                "
                            />
                            <img
                                class="h-5 rounded-md border border-gray-100"
                                src="https://ucarecdn.com/3e6f845d-0f9b-4e99-b1c7-74d8cd817723/-/format/auto/"
                                alt="Klarna"
                                v-else-if="
                                    saleTransaction.paymentDetails
                                        .paymentMethod === 'klarna'
                                "
                            />
                            <img
                                class="h-5 rounded-md border border-gray-100"
                                src="@/assets/ideal-logo.svg"
                                alt="iDeal Logo"
                                v-else-if="
                                    saleTransaction.paymentDetails
                                        .paymentMethod === 'ideal'
                                "
                            />
                            <img
                                class="h-5 rounded-md border border-gray-100"
                                src="@/assets/applepay.svg"
                                alt="Apple Pay"
                                v-else-if="
                                    saleTransaction.paymentDetails
                                        .paymentMethod === 'visa_applepay' ||
                                    'amex_applepay' ||
                                    'mc_applepay'
                                "
                            />

                            <p
                                v-if="
                                    saleTransaction.paymentDetails
                                        .paymentMethod !== 'klarna'
                                "
                                class="pl-2 text-xs sm:text-sm leading-5 text-gray-500"
                            >
                                ••••
                                {{
                                    saleTransaction.paymentDetails
                                        .untokenisedCardSummary ||
                                    saleTransaction.paymentDetails
                                        .cardSummary ||
                                    '••••'
                                }}
                            </p>
                        </div>
                    </div>
                    <div
                        v-if="
                            saleTransaction.paymentDetails &&
                            saleTransaction.paymentDetails.cardHolderName
                        "
                        class="px-2"
                    >
                        <p class="mt-1 text-xs sm:text-sm leading-6 text-gray-500">
                            {{ saleTransaction.paymentDetails.cardHolderName }}
                        </p>
                    </div>
                    <div
                        v-if="saleTransaction.trackingId"
                        class="hidden px-2 md:block"
                    >
                        <a
                            class="mt-1 text-xs leading-6 text-indigo-600 underline"
                            target="_blank"
                            :href="`https://ca-live.adyen.com/ca/ca/accounts/showTx.shtml?pspReference=${saleTransaction.trackingId}&txType=Payment`"
                            v-if="isFeatureAvailable(FeaturesEnum.INTERNAL)"
                            >{{ saleTransaction.trackingId }}</a
                        >
                        <span
                            v-else
                            class="mt-1 font-mono text-xs leading-6 text-gray-500"
                            >{{ saleTransaction.trackingId }}</span
                        >
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="order.refunds && order.refunds.length"
            class="border-t border-gray-100 bg-gray-50 pt-2 shadow-inner"
        >
            <div class="flex flex-col flex-wrap justify-between sm:flex-nowrap">
                <div class="ml-2 mt-1">
                    <div class="flex items-center">
                        <h3
                            class="ml-4 text-base font-heading leading-6 text-gray-800"
                        >
                            Refunds
                        </h3>
                    </div>
                </div>
                <div
                    class="mx-4 mb-2 mt-2 w-auto rounded-md bg-white p-3"
                    v-for="refund in order.refunds"
                    :key="refund.id"
                >
                    <p class="text-xs leading-5 text-gray-500">
                        Amount: {{ (refund.amount / 100) | currency }}
                    </p>
                    <p
                        class="mt-1 text-xs leading-5 text-gray-500"
                        v-if="refund.reason"
                    >
                        Reason:
                        {{
                            RefundReasonsEnum.dictionary[refund.reason] ||
                            refund.reason
                        }}
                    </p>
                    <p
                        class="mt-1 text-xs leading-5 text-gray-500"
                        v-if="
                            refund.gatewayResponse &&
                            refund.gatewayResponse.response
                        "
                    >
                        Result:
                        {{ refund.gatewayResponse.response }}
                    </p>
                    <a
                        class="mt-1 font-mono text-xs leading-6 text-indigo-600 underline"
                        target="_blank"
                        :href="`https://ca-live.adyen.com/ca/ca/payments/modifySearch.shtml?uxEvent=PAYMENT_POWER_SEARCH&query=${saleTransaction.trackingId}`"
                        v-if="isFeatureAvailable(FeaturesEnum.INTERNAL)"
                        >{{ refund.gatewayResponse.pspReference }}</a
                    >
                    <span
                        v-else
                        class="mt-1 font-mono text-xs leading-5 text-gray-500"
                        >ID:
                        {{
                            refund.gatewayResponse
                                ? refund.gatewayResponse.pspReference
                                : refund.id
                        }}</span
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { RefundReasonsEnum, FeaturesEnum } from '@/enums';
import { mapGetters } from 'vuex';

export default {
    name: 'OrderPayments',
    data() {
        return {
            RefundReasonsEnum,
            FeaturesEnum
        };
    },
    props: {
        transactions: {
            type: Array,
            required: true,
            default: () => []
        },
        order: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    computed: {
        ...mapGetters({
            isFeatureAvailable: 'user/isFeatureAvailable'
        }),
        saleTransaction() {
            const saleTransaction = this.transactions.find(
                transaction => transaction.kind === 'Sale'
            );

            return saleTransaction;
        },
        isTotalRefunded() {
            const refundedSum = this.order.refunds.reduce((sum, refund) => {
                return sum + refund.amount;
            }, 0);

            return refundedSum >= this.order.total;
        }
    },
    methods: {
        showRefundModal(order, askToShow) {
            this.$modal.show('refund-modal', { item: order, askToShow });
        }
    }
};
</script>
