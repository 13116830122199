<template>
    <date-range-picker
        v-if="currentUser"
        ref="picker"
        :opens="aligned"
        class="relative"
        :class="wrapperStyles"
        :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY' }"
        :maxDate="maxDate ? maxDate : allowFuture ? null : todaysDate"
        :min-date="minDate ? minDate : disallowPast ? todaysDate : null"
        :singleDatePicker="singleDate"
        :showWeekNumbers="true"
        :timePicker="timePicker"
        :time-picker-increment="1"
        :showDropdowns="true"
        :autoApply="true"
        :ranges="singleDate ? false : ranges"
        v-model="dateRange"
        :linkedCalendars="false"
        @update="updateValues"
    >
        <template v-slot:input="picker">
            <div
                class="focus:outline-none select-none inline-flex cursor-pointer items-center rounded-md shadow-sm border border-gray-300 bg-white px-3 py-2 text-sm leading-5 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
                :class="styles"
            >
                <svg
                    class="-ml-1 mr-3 h-5 w-5"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                    ></path>
                </svg>
                <span>
                    {{ picker.startDate | date }}
                    <span v-if="!singleDate">
                        - {{ picker.endDate | date }}
                    </span>
                </span>
            </div>
        </template>
    </date-range-picker>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

export default {
    name: 'DateRangeSelector',
    props: {
        singleDate: { type: Boolean, default: false },
        allowFuture: { type: Boolean, default: false },
        minDate: { type: String, default: null },
        maxDate: { type: String, default: null },
        disallowPast: { type: Boolean, default: false },
        lastPeriodInMonths: {
            type: Number,
            required: false,
            default: 1
        },
        aligned: {
            type: String,
            default: 'right'
        },
        styles: {
            type: String,
            default: 'text-gray-700 hover:text-gray-500'
        },
        wrapperStyles: {
            type: String,
            default: ''
        },
        lastPeriodInDays: {
            type: Number,
            required: false,
            default: 0
        },
        timePicker: {
            type: Boolean,
            default: false
        }
    },
    components: {
        DateRangePicker
    },
    data() {
        return {
            isLoading: true,
            dateRange: {
                startDate: null,
                endDate: null
            }
        };
    },
    async created() {
        const { number, unitTime } = this.date;

        if (
            this.lastPeriodInMonths !== 1 ||
            this.getDateRange === null ||
            this.singleDate ||
            this.timePicker
        ) {
            this.dateRange = {
                startDate: this.singleDate
                    ? this.$moment(this.todaysDate)
                    : this.$moment(this.todaysDate)
                          .subtract(number, unitTime)
                          .format('YYYY-MM-DD 00:00:00'),
                endDate: this.$moment(this.todaysDate).format(
                    'YYYY-MM-DD 23:59:00'
                )
            };
        } else {
            this.dateRange = this.getDateRange;
        }
    },
    filters: {
        date(value) {
            if (!value) return '';
            let options = { year: '2-digit', month: 'short', day: 'numeric' };
            return Intl.DateTimeFormat('en-GB', options).format(value);
        }
    },
    methods: {
        ...mapActions({
            setDateRange: 'report/setDateRange'
        }),
        updateValues(values) {
            const dateRange = {
                startDate: this.timePicker
                    ? this.$moment(values.startDate).format()
                    : this.$moment(values.startDate).format('YYYY-MM-DD'),
                endDate: this.timePicker
                    ? this.$moment(values.endDate).format()
                    : this.$moment(values.endDate).format('YYYY-MM-DD')
            };

            this.setDateRange(dateRange);
            this.$emit('date-updated', dateRange);
        }
    },
    computed: {
        ...mapGetters({
            currentUser: 'user/currentUser',
            getDateRange: 'report/getDateRange'
        }),
        date() {
            const isDays = !!this.lastPeriodInDays;

            const number = isDays
                ? this.lastPeriodInDays
                : this.lastPeriodInMonths;

            const unitTime = isDays ? 'days' : 'months';

            return { number, unitTime };
        },
        todaysDate() {
            return new Date();
        },
        ranges() {
            let today = new Date();
            today.setHours(0, 0, 0, 0);

            let todayEnd = new Date();
            todayEnd.setHours(23, 59, 0, 0);

            let yesterday = new Date();
            yesterday.setDate(today.getDate() - 1);
            yesterday.setHours(0, 0, 0, 0);

            let yesterdayEnd = new Date();
            yesterdayEnd.setDate(todayEnd.getDate() - 1);
            yesterdayEnd.setHours(23, 59, 0, 0);

            let thisMonthStart = new Date(
                today.getFullYear(),
                today.getMonth(),
                1
            );

            let thisMonthEnd = new Date(
                today.getFullYear(),
                today.getMonth() + 1,
                0,
                23,
                59
            );

            return {
                Today: [today, todayEnd],
                Yesterday: [yesterday, yesterdayEnd],
                'This month': [thisMonthStart, thisMonthEnd],
                'Last month': [
                    new Date(today.getFullYear(), today.getMonth() - 1, 1),
                    new Date(today.getFullYear(), today.getMonth(), 0, 23, 59)
                ],
                'This year': [
                    new Date(today.getFullYear(), 0, 1),
                    new Date(today.getFullYear(), 11, 31, 23, 59)
                ],
                'Last year': [
                    new Date(today.getFullYear() - 1, 0, 1),
                    new Date(today.getFullYear() - 1, 11, 31, 23, 59)
                ],
                'All time': [new Date('2020-04-01'), today]
            };
        }
    }
};
</script>

<style>
.reportrange-text {
    background: transparent !important;
    cursor: pointer !important;
    padding: 0 !important;
    border: none !important;
}
.calendars.row.no-gutters {
    display: flex;
}

.daterangepicker.dropdown-menu {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px -2px rgba(0, 0, 0, 0.05);
    top: 50px;
}

.daterangepicker td.in-range {
  background-color: #a8a2ff;
}

.daterangepicker .ranges li.active,
.daterangepicker td.active,
.daterangepicker td.active:hover {
  @apply bg-indigo-600;
}

</style>
