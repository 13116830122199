<template>
    <div class="mt-6 flex items-end overflow-y-auto sm:h-0 sm:flex-1">
        <nav class="w-full space-y-1 px-2">
            <feature-base-nav-item v-if="currentUser && !isWebExtension" :user="currentUser" />
            <span
                v-if="isStoreKit"
                @click="openIntercom"
                class="focus:outline-none group relative mt-0.5 flex cursor-pointer select-none rounded-md px-2 py-1 font-heading text-sm font-medium leading-5 text-gray-200 transition duration-150 ease-in-out hover:bg-gray-700 hover:text-white focus:bg-gray-700 focus:text-white"
            >
                <svg
                    class="mr-3 h-5 w-5 text-gray-400 transition duration-150 ease-in-out group-hover:text-gray-200 group-focus:text-gray-200"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                        clip-rule="evenodd"
                    ></path>
                </svg>
                Support
            </span>
            <router-link
                v-if="!isFeatureAvailable(FeaturesEnum.SAAS)"
                :to="{ name: 'upgrade' }"
                class="focus:outline-none group relative mt-0.5 flex cursor-pointer select-none rounded-md px-2 py-1 font-heading text-sm font-medium leading-5 text-gray-200 transition duration-150 ease-in-out hover:bg-gray-700 hover:text-white focus:bg-gray-700 focus:text-white"
            >
              <base-magic-icon class="mr-3 h-5 w-5 text-gray-400 transition duration-150 ease-in-out group-hover:text-gray-200 group-focus:text-gray-200" />
                storekit+
            </router-link>
            <router-link
                v-if="currentUser"
                exact
                :to="{ name: 'feedback' }"
                active-class=" bg-gray-900 text-white"
                class="focus:outline-none group mt-0.5 flex cursor-pointer select-none rounded-md px-2 py-1 font-heading text-sm font-medium leading-5 text-gray-200 transition duration-150 ease-in-out hover:bg-gray-700 hover:text-white focus:bg-gray-700 focus:text-white"
            >
                <base-conversation-icon class="text-gray-400 group-hover:text-gray-200 group-focus:text-gray-200" />
                Feature Requests
            </router-link>
        </nav>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import BaseConversationIcon from "@/components/base/icons/BaseConversationIcon.vue";
import {FeaturesEnum} from "@/enums";
import FeatureBaseNavItem from "@/components/widgets/FeatureBaseNavItem.vue";

export default {
    components: {
      FeatureBaseNavItem,
      BaseConversationIcon,
    },
   data() {
      return {
        FeaturesEnum
      }
   },
    computed: {
        ...mapGetters({
            currentUser: 'user/currentUser',
            isStoreKit: 'user/isStoreKit',
            isWebExtension: 'ui/isWebExtension',
            isFeatureAvailable: 'user/isFeatureAvailable'
        })
    },
    methods: {
        openIntercom() {
            try {
                window.Intercom('show');
            } catch (e) {
                window.alert(
                    'Please disable your AdBlocker to use live chat support.'
                );
            }
        }
    }
};
</script>
