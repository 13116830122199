<template>
    <div class="col-span-6 grid gap-6">
        <div>
            <label
                for="street"
                class="block text-gray-700 text-sm font-medium leading-5"
                >Home address</label
            >
            <input
                v-model.trim="address.street"
                placeholder="eg 101 High St"
                autocomplete="shipping street-address"
                id="street"
                class="form-input block mt-1 px-3 py-2 w-full border focus:border-blue-300 border-gray-300 rounded-md focus:outline-none focus:shadow-outline-blue shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                :class="{
                    'border-red-300': isIndividual
                        ? $v.address.street.$error
                        : $v[index].address.street.$error
                }"
            />
            <span
                v-if="
                    isIndividual
                        ? $v.address.street.$error
                        : $v[index].address.street.$error
                "
                class="text-red-600 text-xs"
            >
                Please enter the street name
            </span>
        </div>

        <div>
            <label
                for="building_number"
                class="block text-gray-700 text-sm font-medium leading-5"
                >Building name / number</label
            >
            <input
                v-model.trim="address.houseNumberOrName"
                placeholder="eg 15-17"
                id="building_number"
                class="form-input block mt-1 px-3 py-2 w-full border focus:border-blue-300 border-gray-300 rounded-md focus:outline-none focus:shadow-outline-blue shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                :class="{
                    'border-red-300': isIndividual
                        ? $v.address.houseNumberOrName.$error
                        : $v[index].address.houseNumberOrName.$error
                }"
            />
        </div>

        <div>
            <label
                for="city"
                class="block text-gray-700 text-sm font-medium leading-5"
                >City</label
            >
            <input
                id="city"
                v-model.trim="address.city"
                autocomplete="shipping locality"
                placeholder="eg London"
                class="form-input block mt-1 px-3 py-2 w-full border focus:border-blue-300 border-gray-300 rounded-md focus:outline-none focus:shadow-outline-blue shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                :class="{
                    'border-red-300': isIndividual
                        ? $v.address.city.$error
                        : $v[index].address.city.$error
                }"
            />
            <span
                v-if="
                    isIndividual
                        ? $v.address.city.$error
                        : $v[index].address.city.$error
                "
                class="text-red-600 text-xs"
            >
                Please enter a valid city
            </span>
        </div>

        <div>
            <label
                for="postal_code"
                class="block text-gray-700 text-sm font-medium leading-5"
                >Post code</label
            >
            <input
                id="postal_code"
                v-model.trim="address.postalCode"
                placeholder="eg SW1A 1AA"
                autocomplete="shipping postal-code"
                class="form-input block mt-1 px-3 py-2 w-full border focus:border-blue-300 border-gray-300 rounded-md focus:outline-none focus:shadow-outline-blue shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                :class="{
                    'border-red-300': isIndividual
                        ? $v.address.postalCode.$error
                        : $v[index].address.postalCode.$error
                }"
            />
            <span
                v-if="
                    isIndividual
                        ? $v.address.postalCode.$error
                        : $v[index].address.postalCode.$error
                "
                class="text-red-600 text-xs"
            >
                Please enter a valid postcode
            </span>
        </div>

        <div>
            <div>
                <label
                    for="location"
                    class="block text-gray-700 text-sm font-medium"
                    >Country</label
                >
                <select
                    v-model="address.country"
                    id="location"
                    name="location"
                    class="form-input focus:ring-indigo-500 block mt-1 pl-3 pr-10 py-2 w-full text-base border border-gray-300 focus:border-indigo-500 rounded-md focus:outline-none sm:text-sm"
                    :class="{
                        'border-red-300': isIndividual
                            ? $v.address.country.$error
                            : $v[index].address.country.$error
                    }"
                >
                    <option value="" disabled selected>Select a country</option>
                    <option
                        v-for="country in countries"
                        :key="country.code"
                        :value="country.code"
                        >{{ country.name }}</option
                    >
                </select>
                <span
                    v-if="
                        isIndividual
                            ? $v.address.country.$error
                            : $v[index].address.country.$error
                    "
                    class="text-red-600 text-xs"
                >
                    Please select a country
                </span>
            </div>
        </div>
    </div>
</template>
<script>
import COUNTRIES_JSON from '@/helpers/countries.json';

export default {
    name: 'AdyenAddress',
    props: {
        $v: {
            type: Object,
            required: true,
            default: () => ({})
        },
        address: {
            type: Object,
            required: true,
            default: () => ({})
        },
        index: {
            type: Number,
            required: false,
            default: 0
        },
        isIndividual: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    data() {
        return {
            countries: COUNTRIES_JSON
        };
    }
};
</script>
