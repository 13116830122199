<template>
    <div>
        <label
            :for="inputId"
            class="block text-sm font-medium leading-5 text-gray-700"
        >
          {{ label }}
        </label>
        <div class="relative mt-1 rounded-md shadow-sm">
            <input
                :value="value"
                @change="$emit('input', $event.target.value)"
                :id="inputId"
                :type="showPassword ? 'text' : 'password'"
                :autocomplete="autocomplete"
                :minlength="minLength"
                required
                :disabled="disabled"
                class="focus:outline-none block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                :class="{
                    'border-red-300': error
                }"
            />
            <div
                @click="showPassword = !showPassword"
                class="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-3"
            >
                <BaseEyeHiddenIcon
                    v-if="showPassword"
                    class="text-gray-500"
                    aria-hidden="true"
                />
                <BaseEyeIcon v-else class="text-gray-500" aria-hidden="true" />
            </div>
        </div>
        <div v-if="error && errorText" class="mt-1 flex py-2 text-red-500">
            <svg
                class="mr-1 h-4 w-4 flex-shrink-0"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                    clip-rule="evenodd"
                ></path>
            </svg>
            <p class="text-xs">
                {{ errorText }}
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BasePasswordInput',
    props: {
        inputId: {
            type: String,
            required: false,
            default: 'password'
        },
        label: {
              type: String,
              required: false,
              default: 'Password'
        },
        value: {
            type: String,
            required: false
        },
        autocomplete: {
            type: String,
            required: false
        },
        error: {
            type: [String, Boolean, Object],
            required: false,
            default: false
        },
        errorText: {
            type: String,
            required: false,
            default: null
        },
        minLength: {
            type: Number,
            required: false,
            default: 6
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            showPassword: false
        };
    },
    methods: {}
};
</script>
