<template>
    <div class="mt-5">
        <div class="shadow sm:rounded-md">
            <div class="bg-white px-4 py-5 sm:p-6">
                <h4 class="text-base font-medium text-gray-700">
                    Bank Account Details
                </h4>
                <div class="mt-4 grid gap-6 lg:grid-cols-6">
                    <div
                        v-if="isInGB"
                        class="grid gap-6 lg:col-span-6 lg:grid-cols-6"
                    >
                        <div class="lg:col-span-4">
                            <label
                                for="bankAccountNumber"
                                class="block text-sm font-medium leading-5 text-gray-700"
                            >
                                Account number
                            </label>

                            <input
                                v-model.trim="bankAccountDetails.accountNumber"
                                placeholder="eg 31926819"
                                autocomplete="bank-account-number"
                                id="bankAccountNumber"
                                type="text"
                                class="focus:outline-none form-input mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                                :class="{
                                    'border-red-300':
                                        $v.bankAccountDetails.accountNumber
                                            .$error
                                }"
                            />
                            <span
                                v-if="
                                    $v.bankAccountDetails.accountNumber.$error
                                "
                                class="text-xs text-red-600"
                            >
                                Please enter a valid Bank account number
                            </span>
                        </div>

                        <div class="lg:col-span-2">
                            <label
                                for="bankAccountSortCode"
                                class="block text-sm font-medium leading-5 text-gray-700"
                                >Sort code</label
                            >
                            <input
                                v-model.trim="bankAccountDetails.sortCode"
                                autocomplete="bank-account-sort-code"
                                placeholder="eg 60-16-13"
                                v-mask="'##-##-##'"
                                id="bankAccountSortCode"
                                type="text"
                                class="focus:outline-none form-input mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                                :class="{
                                    'border-red-300':
                                        $v.bankAccountDetails.sortCode.$error
                                }"
                            />
                            <span
                                v-if="$v.bankAccountDetails.sortCode.$error"
                                class="text-xs text-red-600"
                            >
                                Please enter a valid Sort code
                            </span>
                        </div>
                    </div>
                    <div v-else class="grid gap-6 lg:col-span-6 lg:grid-cols-6">
                        <div class="lg:col-span-4">
                            <label
                                for="bankAccountIBANNumber"
                                class="block text-sm font-medium leading-5 text-gray-700"
                            >
                                IBAN number
                            </label>

                            <input
                                v-model.trim="bankAccountDetails.iban"
                                placeholder="eg FR3330002005500000157841Z25"
                                autocomplete="account-iban-number"
                                id="bankAccountIBANNumber"
                                type="text"
                                class="focus:outline-none form-input mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                                :class="{
                                    'border-red-300':
                                        $v.bankAccountDetails.iban.$error
                                }"
                            />
                            <span
                                v-if="$v.bankAccountDetails.iban.$error"
                                class="text-xs text-red-600"
                            >
                                Please enter a valid IBAN number
                            </span>
                        </div>

                        <div class="lg:col-span-2">
                            <label
                                for="bankBicSwift"
                                class="block text-sm font-medium leading-5 text-gray-700"
                                >Bank Bic Swift</label
                            >
                            <input
                                v-model.trim="bankAccountDetails.bankBicSwift"
                                autocomplete="bank-bic-swift"
                                placeholder="eg CRLYFRPP"
                                id="bankBicSwift"
                                type="text"
                                class="focus:outline-none form-input mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                                :class="{
                                    'border-red-300':
                                        $v.bankAccountDetails.bankBicSwift
                                            .$error
                                }"
                            />
                            <span
                                v-if="$v.bankAccountDetails.bankBicSwift.$error"
                                class="text-xs text-red-600"
                            >
                                Please enter a valid Bank Bic Swift
                            </span>
                        </div>

                        <div class="lg:col-span-3">
                            <label
                                for="bankCity"
                                class="block text-sm font-medium leading-5 text-gray-700"
                            >
                                Bank City
                            </label>

                            <input
                                v-model.trim="bankAccountDetails.bankCity"
                                autocomplete="account-owner"
                                id="bankCity"
                                type="text"
                                placeholder="eg. Antony"
                                class="focus:outline-none form-input mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                            />

                            <span
                                v-if="$v.bankAccountDetails.bankCity.$error"
                                class="text-xs text-red-600"
                            >
                                Please enter a valid owner name
                            </span>
                        </div>
                        <div class="lg:col-span-3">
                            <label
                                for="bankName"
                                class="block text-sm font-medium leading-5 text-gray-700"
                            >
                                Bank Name
                            </label>

                            <input
                                v-model.trim="bankAccountDetails.bankName"
                                autocomplete="account-owner"
                                id="bankName"
                                type="text"
                                placeholder="eg. Credit Lyonnais"
                                class="focus:outline-none form-input mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                            />

                            <span
                                v-if="$v.bankAccountDetails.bankName.$error"
                                class="text-xs text-red-600"
                            >
                                Please enter a valid bank name
                            </span>
                        </div>
                    </div>
                    <div class="lg:col-span-3">
                        <label
                            for="countryCode"
                            class="block text-sm font-medium text-gray-700"
                        >
                            Country
                        </label>
                        <select
                            id="countryCode"
                            v-model.trim="bankAccountDetails.countryCode"
                            class="focus:outline-none form-input mt-1 block w-full rounded-md border border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            :class="{
                                'border-red-300':
                                    $v.bankAccountDetails.countryCode.$error
                            }"
                        >
                            <option value="" disabled selected>
                                Select a country
                            </option>
                            <option
                                v-for="country in countries"
                                :key="country.code"
                                :value="country.code"
                            >
                                {{ country.name }}
                            </option>
                        </select>
                        <span
                            v-if="$v.bankAccountDetails.countryCode.$error"
                            class="text-xs text-red-600"
                        >
                            Please select a country
                        </span>
                    </div>

                    <div class="lg:col-span-3">
                        <label
                            for="currency"
                            class="block text-sm font-medium text-gray-700"
                        >
                            Currency
                        </label>
                        <select
                            id="currency"
                            name="currency"
                            v-model.trim="bankAccountDetails.currencyCode"
                            class="focus:outline-none form-input mt-1 block w-full rounded-md border border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            :class="{
                                'border-red-300':
                                    $v.bankAccountDetails.currencyCode.$error
                            }"
                        >
                            <option value="" disabled selected>
                                Select a currency
                            </option>
                            <option
                                v-for="currency in currencies"
                                :key="currency"
                                :value="currency"
                            >
                                {{ currency }}
                            </option>
                        </select>
                        <span
                            v-if="$v.bankAccountDetails.currencyCode.$error"
                            class="text-xs text-red-600"
                        >
                            Please select a currency
                        </span>
                    </div>

                    <div :class="isInGB ? 'lg:col-span-6' : 'lg:col-span-4'">
                        <label
                            for="bankAccountOwnerName"
                            class="block text-sm font-medium leading-5 text-gray-700"
                        >
                            Owner Name
                        </label>

                        <input
                            v-model.trim="bankAccountDetails.ownerName"
                            autocomplete="account-owner-name"
                            id="bankAccountOwnerName"
                            type="bankAccountOwnerName"
                            class="focus:outline-none form-input mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                        />
                        <p
                            class="mt-2 text-xs text-gray-500"
                            id="bankAccountOwnerNameDescription"
                            >Enter the name of the person or company the bank
                            account is registered to.</p
                        >
                        <span
                            v-if="$v.bankAccountDetails.ownerName.$error"
                            class="text-xs text-red-600"
                        >
                            Please enter a valid owner name
                        </span>
                    </div>
                    <div v-if="!isInGB" class="lg:col-span-2">
                        <label
                            for="bankAccountOwnerCity"
                            class="block text-sm font-medium leading-5 text-gray-700"
                        >
                            Owner City
                        </label>

                        <input
                            v-model.trim="bankAccountDetails.ownerCity"
                            autocomplete="account-owner-city"
                            id="bankAccountOwnerCity"
                            type="bankAccountOwnerCity"
                            placeholder="eg. London"
                            class="focus:outline-none form-input mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                        />

                        <span
                            v-if="$v.bankAccountDetails.ownerCity.$error"
                            class="text-xs text-red-600"
                        >
                            Please enter a valid owner city
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import COUNTRIES_JSON from '@/helpers/countries.json';

export default {
    name: 'AdyenBankAccountDetailsForm',
    props: {
        $v: {
            type: Object,
            required: true
        },
        bankAccountDetails: {
            type: Object,
            required: true
        },
        isInGB: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            countries: COUNTRIES_JSON,
            currencies: ['GBP', 'EUR', 'SEK', 'NOK','CHF']
        };
    }
};
</script>
