<template>
    <div>
        <div class="md:flex md:items-center md:justify-between">
            <div class="min-w-0 flex-1">
                <router-link
                    :to="{ name: 'paymentSettings' }"
                    class="mb-2 flex items-center text-sm text-gray-500 inline-flex"
                >
                    <base-back-icon />
                    <span>Payment Settings</span>
                </router-link>
                <h2 class="text-2xl font-semibold text-gray-900"> PayPal </h2>
            </div>

            <BaseSpinner :isLoading="isLoading" />
        </div>

        <PayPalGeneral
            v-if="!isLoading"
            :venueId="venueId"
            :merchantId="mainPayPalData.merchantId"
            :active="mainPayPalData.active"
            :primaryEmail="onboardingStatus.primaryEmail"
            :paypalSignupLink="onboardingStatus.paypalSignupLink"
            :capabilities="onboardingStatus.capabilities"
            :paypalInformations="onboardingStatus.paypalInformations"
        />
        <PayPalCheckout v-if="!isLoading" :venueId="venueId" />
        <PayPalCustomCardFields
            v-if="!isLoading"
            :venueId="venueId"
            :areCustomCardFieldsAvailable="
                onboardingStatus.areCustomCardFieldsAvailable
            "
            :areCustomCardFields="areCustomCardFields"
            @toggleCustomCardFieldsActive="toggleCustomCardFieldsActive"
        />
        <PayPal3DSecure
            v-if="areCustomCardFields && !isLoading"
            :venueId="venueId"
            :is3DSecure="is3DSecure"
            @toggle3DSecureActive="toggle3DSecureActive"
        />

        <BaseSpacerWithRuler />
        <PayPalDisconnect v-if="!isLoading" :venueId="venueId" class="mt-2" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PayPalGeneral from '@/components/editVenue/PayPalGeneral';
import PayPalCheckout from '@/components/editVenue/PayPalCheckout';
import PayPalCustomCardFields from '@/components/editVenue/PayPalCustomCardFields';
import PayPal3DSecure from '@/components/editVenue/PayPal3DSecure';
import PayPalDisconnect from '@/components/editVenue/PayPalDisconnect';

export default {
    name: 'PayPalSettings',
    metaInfo: {
        title: 'PayPal Settings'
    },
    props: {
        venueId: {
            type: Number,
            required: true,
            default: 0
        }
    },
    components: {
        PayPalGeneral,
        PayPalCheckout,
        PayPalCustomCardFields,
        PayPal3DSecure,
        PayPalDisconnect
    },
    data() {
        return {
            isLoading: false,
            areCustomCardFields: false,
            is3DSecure: false
        };
    },
    created() {
        this.getPayPalData();
    },
    computed: {
        ...mapGetters({
            paypalSignupLink: 'paypal/signupLink',
            mainPayPalData: 'paypal/mainPayPalData',
            onboardingStatus: 'paypal/onboardingStatus'
        })
    },
    methods: {
        async getPayPalData() {
            this.isLoading = true;

            if (!this.mainPayPalData.active) {
                await this.$router.push({ name: 'paymentSettings' });

                return;
            }
            this.areCustomCardFields = await this.onboardingStatus
                .areCustomCardFieldsEnabled;
            this.is3DSecure = await this.onboardingStatus.is3DSecureEnabled;

            this.isLoading = false;
        },

        toggleCustomCardFieldsActive(bool) {
            this.areCustomCardFields = bool;
        },
        toggle3DSecureActive(bool) {
            this.is3DSecure = bool;
        }
    }
};
</script>
