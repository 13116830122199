<template>
    <div class="inline-block" v-tooltip="'Click to edit ' + label || ''">
        <input
            type="text"
            class="w-full px-1"
            v-if="edit"
            :value="valueLocal"
            @blur="
                valueLocal = $event.target.value;
                edit = false;
                $emit('input', valueLocal);
            "
            @keyup.enter="
                valueLocal = $event.target.value;
                edit = false;
                $emit('input', valueLocal);
            "
            v-focus=""
        />
        <span class="text-indigo-600" v-else @click="edit = true">
            {{ valueLocal }}
        </span>
    </div>
</template>

<script>
export default {
    props: ['value', 'label'],
    data() {
        return {
            edit: false,
            valueLocal: this.value
        };
    },
    watch: {
        value: function () {
            this.valueLocal = this.value;
        }
    },
    directives: {
        focus: {
            inserted(el) {
                el.focus();
            }
        }
    }
};
</script>
