var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.isLoading && _vm.customer)?_c('div',{staticClass:"flex flex-col flex-wrap items-stretch"},[_c('div',{staticClass:"p-3"},[_c('h5',{staticClass:"break-anywhere mb-2 text-gray-700 text-sm font-bold leading-tight"},[_vm._v(" "+_vm._s(this.customer.firstname)+" "+_vm._s(this.customer.lastname || '')+" ")]),_c('span',{staticClass:"block mt-1.5 text-gray-500 text-sm"},[_vm._v(" "+_vm._s(_vm.customer.orderCount)+" orders ")]),_c('a',{staticClass:"block mt-2 text-gray-600 hover:underline",attrs:{"href":'mailto:' + _vm.customer.email,"target":"_blank"}},[_vm._v(_vm._s(_vm.customer.email))]),_c('a',{staticClass:"block mt-1 text-gray-600",attrs:{"href":'tel:' + _vm.customer.phone,"target":"_blank"}},[_vm._v(_vm._s(_vm.customer.phone))])]),_c('div',{staticClass:"mt-3 p-3 border-t border-gray-200"},[_c('router-link',{attrs:{"to":{
                    name: 'customerDetail',
                    params: {
                        email: _vm.customerEmail
                    }
                }}},[_c('button',{staticClass:"focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 inline-flex items-center justify-center px-3 py-1 text-gray-700 text-xs leading-4 hover:bg-gray-50 bg-white border border-gray-300 rounded-md focus:outline-none shadow-sm w-full text-center",attrs:{"type":"button"}},[_vm._v(" View customer ")])])],1)]):_c('div',[_vm._m(0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"spinner p-3 h-20"},[_c('span',{staticClass:"text-gray-600"})])
}]

export { render, staticRenderFns }