<template>
    <div>
        <div class="md:flex md:items-center md:justify-between">
            <div class="min-w-0 flex-1">
                <router-link
                    :to="{ name: 'editVenue' }"
                    class="mb-2 flex items-center text-sm text-gray-500 inline-flex"
                >
                    <base-back-icon />
                    <span>Settings</span>
                </router-link>
                <base-page-title title="Feedback Settings" />
            </div>

            <BaseSpinner :isLoading="isLoading" />
        </div>
        <div>
            <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                            >Details</h3
                        >
                        <p class="mt-2 text-sm text-gray-500">
                            Enable to get feedback from customers on each order.
                        </p>
                    </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0">
                    <div class="shadow sm:overflow-hidden sm:rounded-md">
                        <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
                            <div class="grid grid-cols-3 gap-6">
                                <div class="col-span-3 sm:col-span-2">
                                    <label
                                        class="mb-2 block text-sm font-medium leading-5 text-gray-700"
                                        >Enable feedback</label
                                    >
                                    <toggle-switch
                                        v-model="
                                            feedbackSettings.orderReviewsEnabled
                                        "
                                    />
                                </div>
                                <div
                                    class="col-span-6 sm:col-span-6 lg:col-span-3"
                                >
                                    <span
                                        class="text-sm leading-5 text-gray-500"
                                        >Notification delay</span
                                    ><div
                                        class="relative mt-1 w-full rounded-md shadow-sm"
                                    >
                                        <currency-input
                                            id="prepTime"
                                            v-model="
                                                feedbackSettings.orderReviewDelay
                                            "
                                            class="form-input block w-full pr-12 sm:text-sm sm:leading-5"
                                            placeholder="60"
                                            :currency="null"
                                            :precision="0"
                                            locale="en-GB"
                                            :value-as-integer="true"
                                            :allow-negative="false"
                                        ></currency-input>
                                        <div
                                            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                                        >
                                            <span
                                                class="text-gray-500 sm:text-sm sm:leading-5"
                                            >
                                                Minutes
                                            </span>
                                        </div>
                                    </div>
                                    <p class="mt-2 text-sm text-gray-500"
                                        >Add a delay to when the feedback email
                                        is sent to your customer. We'll use the
                                        expected delivery time + the number in
                                        minutes you set here.
                                    </p>
                                </div>
                                <div class="col-span-3 sm:col-span-2">
                                    <label
                                        class="mb-2 flex items-center text-sm font-medium leading-5 text-gray-700"
                                        v-tooltip="'Only send feedback requests to customers who have opted in to receive marketing communications.'"
                                        >Opt-in only
                                      <base-question-icon class="ml-1 text-gray-700" /></label
                                    >
                                    <toggle-switch
                                        v-model="feedbackSettings.optInOnly"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <BaseSpacerWithRuler />

        <div class="my-6 ml-4 flex flex-shrink-0 justify-end">
            <span class="inline-flex rounded-md shadow-sm" @click="saveVenue">
                <button
                    type="button"
                    class="focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                    :disabled="isLoading"
                >
                    Save
                </button>
            </span>
        </div>

        <div v-if="isFeatureAvailable(FeaturesEnum.INTERNAL) || isFeatureAvailable(FeaturesEnum.SAAS)">
            <SmartReviewSettings :venue="venue" />
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { FeaturesEnum } from '@/enums';
import ToggleSwitch from '../../formComponents/ToggleSwitch';
import SmartReviewSettings from '@/components/venue/settings/smartReviews/SmartReviewSettings';

export default {
    name: 'FeedbackSettings',
    metaInfo: {
        title: 'Feedback Settings'
    },
    props: ['venueId', 'venue'],
    components: {
        ToggleSwitch,
        SmartReviewSettings
    },
    data() {
        let opts = {};
        const defaultSettings = {
            orderReviewsEnabled: false
        };
        Object.assign(opts, defaultSettings, this.venue.feedbackSettings);
        return {
            isLoading: false,
            FeaturesEnum,
            feedbackSettings: opts
        };
    },
    validations: {
        feedbackSettings: {
            orderReviewsEnabled: {
                required
            }
        }
    },
    methods: {
        ...mapActions({
            updateVenueSettings: 'venues/update'
        }),
        async saveVenue() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.errors = 'ERROR';
                return false;
            }

            try {
                this.isLoading = true;

                const payload = {
                    id: this.venueId,
                    feedbackSettings: this.feedbackSettings
                };

                await this.updateVenueSettings(payload);

                this.$notify({
                    group: 'settings',
                    title: 'Settings saved'
                });
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        }
    },
    computed: {
        ...mapGetters({
            isFeatureAvailable: 'user/isFeatureAvailable'
        })
    }
};
</script>
