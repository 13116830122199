<template>
    <div
        class="relative flex items-center"
        :class="{
            'opacity-50': disabled
        }"
    >
        <div class="absolute flex h-5 items-center">
            <input
                v-bind="{
                    id,
                    checked,
                    value,
                    disabled
                }"
                @change="update"
                type="checkbox"
                class="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                :class="{
                    'cursor-not-allowed': disabled,
                    'border-red-300 ring-2 ring-red-200 ring-offset-2': error
                }"
            />
        </div>
        <div class="pl-6 text-sm leading-5">
            <label
                :for="id"
                v-tooltip="tooltip"
                :class="{
                    'cursor-not-allowed ': disabled
                }"
            >
                <slot name="label">
                    <p
                        v-if="label"
                        class="flex h-5 items-center font-medium text-gray-700"
                    >
                        {{ label }}
                        <base-question-icon
                            v-if="tooltip"
                            class="ml-1 inline"
                        />
                    </p>
                </slot>

                <slot name="caption">
                    <p v-if="caption" class="text-gray-500">
                        {{ caption }}
                    </p>
                </slot>
            </label>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BaseCheckbox',
    model: {
        prop: 'modelValue',
        event: 'change'
    },
    props: {
        id: [String, Number],
        value: [String, Boolean, Array],
        tooltip: String,
        label: String,
        caption: String,
        disabled: Boolean,
        error: [String, Boolean, Object],
        modelValue: [String, Boolean, Array]
    },
    computed: {
        checked() {
            return Array.isArray(this.modelValue)
                ? this.modelValue.includes(this.value)
                : !!this.modelValue;
        }
    },
    methods: {
        update(event) {
            const isChecked = event.target.checked;
            let newValue;

            if (Array.isArray(this.modelValue)) {
                newValue = isChecked
                    ? [...this.modelValue, this.value]
                    : this.modelValue.filter(item => item !== this.value);
            } else {
                newValue = !!isChecked;
            }

            this.$emit('change', newValue);
        }
    }
};
</script>
