import axios from 'axios';
import auth from '@/helpers/auth';
import * as Sentry from '@sentry/vue';
import ApiService from '@/helpers/api';
import router from '@/router';
import { FeaturesEnum } from '@/enums';
import currencyMap from '../helpers/currencies';

const state = {
    isStoreKit: null,
    isLoading: true,
    errors: false,
    profile: null,
    isAuthenticated: !!auth.getToken(),
    useAdminIndex: false,
    currency: null,
    isRemovedInternalFeature: false
};

const mutations = {
    SAVE_USER(state, payload) {
        state.user = payload;
    },
    PURGE_AUTH(state) {
        state.isAuthenticated = false;
        state.profile = {};
        auth.destroyToken();

        Sentry.setUser(null);
        router.push({ name: 'login' });
    },
    SET_ERROR(state, error) {
        state.errors = error;
    },
    SET_AUTH(state, user) {
        state.isAuthenticated = true;
        state.profile = user;
        state.errors = {};
        state.isStoreKit = user ? user.partnerId === 1 : false;
        auth.saveToken(state.profile.token);

        state.currency = currencyMap(user?.accounts?.[0]?.displayCurrency);

        if (state.currency.ISO !== 'GBP') {
            this._vm.$currency.setConfig(state.currency);
        }

        if (window.analytics) {
            window.analytics.identify(user.id, {
                name: user.name,
                email: user.email,
                phone: user.phone,
                user_hash: user.hash
            });
        }

        Sentry.setUser({
            name: user.name,
            email: user.email,
            id: user.id
        });
    },
    SET_ADMIN_INDEX_USE(state, value) {
        state.useAdminIndex = value;
    },
    REMOVE_INTERNAL_FEATURE(state, value) {
        state.isRemovedInternalFeature = value;
    }
};

const getters = {
    currentUser(state) {
        return state.profile;
    },
    isAuthenticated(state) {
        return state.isAuthenticated;
    },
    isStoreKit(state) {
        return state.isStoreKit;
    },
    isFeatureAvailable: (state, getters, rootState) => featureKey => {
        if (featureKey === FeaturesEnum.INTERNAL) {
            const email = state.profile?.email;

            if (state.isRemovedInternalFeature) {
                return false;
            }

            if (process.env.NODE_ENV === 'development') {
                return true;
            }

            if (!email) {
                return false;
            }

            if (rootState.ui.isWebExtension) {
                return false;
            }

            const internalDomains = process.env.VUE_APP_INTERNAL_DOMAINS.replace(
                /\s/g,
                ''
            ).split(',');

            const domain = email.split('@')[1];

            return !!internalDomains.includes(domain);
        }

        const isAccount =
            state.profile &&
            state.profile.accounts &&
            state.profile.accounts.length;

        const availableFeatures = isAccount
            ? state.profile.accounts[0].features
            : [];

        const isAvailable = availableFeatures.some(
            feature => feature === FeaturesEnum[featureKey]
        );

        return isAvailable;
    },
    accountId(state) {
        return state.profile?.accounts?.[0]?.id;
    },
    accountName(state) {
        return state.profile?.accounts?.[0]?.name;
    },
    useAdminIndex(state) {
        return state.useAdminIndex;
    },
    currencySettings(state) {
        return state.currency;
    },
    userId(state) {
        return state.profile?.id;
    }
};

const actions = {
    LOGIN(context, credentials) {
        return new Promise((resolve, reject) => {
            const uninterceptedAxiosInstance = axios.create();
            uninterceptedAxiosInstance
                .post('/users/login', {
                    user: credentials
                })
                .then(({ data }) => {
                    context.commit('SET_AUTH', data.user);
                    if (window.analytics) {
                        window.analytics.track('User Signed In');
                    }
                    resolve(data);
                })
                .catch(error => {
                    context.commit('SET_ERROR', true);
                    reject(error);
                })
                .finally(() => {
                    resolve;
                });
        });
    },
    RESET(context, credentials) {
        return new Promise((resolve, reject) => {
            this.$axios
                .post('/users/forgot', {
                    user: credentials
                })
                .then(({ data }) => {
                    // context.commit('SET_AUTH', data.user);
                    resolve(data);
                })
                .catch(error => {
                    context.commit('SET_ERROR', true);
                    reject(error);
                })
                .finally(() => {
                    resolve;
                });
        });
    },
    CHANGE_PASSWORD(context, credentials) {
        return new Promise((resolve, reject) => {
            this.$axios
                .post('/users/reset', {
                    user: credentials
                })
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(error => {
                    context.commit('SET_ERROR', true);
                    reject({ ...error });
                })
                .finally(() => {
                    resolve;
                });
        });
    },
    SIGNUP(context, credentials) {
        return new Promise((resolve, reject) => {
            const uninterceptedAxiosInstance = axios.create();
            uninterceptedAxiosInstance
                .post('/users', {
                    user: credentials
                })
                .then(({ data }) => {
                    context.commit('SET_AUTH', data.user);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit('SET_ERROR', response.data);
                    reject(response);
                });
        });
    },
    async CHECK_AUTH(context) {
        if (auth.getToken()) {
            ApiService.setHeader();
            try {
                const { data } = await this.$axios.get('/user');
                context.commit('SET_AUTH', data.user);
            } catch (error) {
                context.commit('SET_ERROR', error);
                if (error.response && error.response.status !== 401) {
                    throw new Error(`API ${error}`);
                } else {
                    context.commit('PURGE_AUTH');
                }
            }
        } else {
            context.commit('PURGE_AUTH');
        }
    },
    LOGOUT(context) {
        context.commit('PURGE_AUTH');

        if (window.analytics) {
            window.analytics.track('User Signed Out');
            window.analytics.reset();
        }
    },
    setAdminIndexUse({ commit }, value) {
        commit('SET_ADMIN_INDEX_USE', value);
    }
};

export default {
    namespaced: true,
    getters,
    mutations,
    state,
    actions
};
