var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fullscreen-container mx-auto flex w-full flex-grow"},[_c('div',{staticClass:"orders-list flex min-w-0 flex-1 bg-white print:hidden"},[_c('div',{staticClass:"min-w-0 flex-1 bg-white"},[_c('div',[_c('div',{staticClass:"border-b border-t border-gray-200 p-3",class:{ 'animate-pulse bg-red-50': _vm.connectionError }},[_c('div',{staticClass:"flex items-start"},[_c('div',{staticClass:"flex items-center space-x-3"},[_c('span',{staticClass:"flex h-4 w-4 items-center justify-center rounded-full",class:{
                                    animate: _vm.isPolling,
                                    'bg-red-100': _vm.connectionError,
                                    'bg-green-100': !_vm.connectionError
                                },attrs:{"aria-label":"Running"}},[_c('span',{staticClass:"h-2 w-2 rounded-full",class:{
                                        'bg-green-400': !_vm.connectionError,
                                        'bg-red-400': _vm.connectionError
                                    }})]),(_vm.connectionError)?_c('div',{staticClass:"flex flex-col"},[_c('h1',{staticClass:"text-sm font-medium"},[_vm._v(" Connection Error ")]),_c('span',{staticClass:"text-xs text-gray-700"},[_vm._v(" Please check your internet connection ")])]):_vm._e()]),(!_vm.connectionError)?_c('div',{staticClass:"relative flex w-full flex-row justify-end"},[_c('button',{staticClass:"focus:outline-none inline-flex items-center items-center rounded-md border bg-white px-4 py-2 text-sm font-medium shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",class:_vm.selectedFilters
                                        ? 'border-indigo-600 text-indigo-600'
                                        : 'border-gray-300 text-gray-700',attrs:{"type":"button"},on:{"click":_vm.openFilterSidebar}},[_vm._v(" Filters "),(_vm.selectedFilters)?_c('span',{staticClass:"ml-2 h-4 w-4 rounded-full bg-indigo-600 text-xs font-bold text-white"},[_vm._v(" "+_vm._s(_vm.selectedFilters)+" ")]):_vm._e()])]):_vm._e()])])]),_c('div',{directives:[{name:"auto-animate",rawName:"v-auto-animate"}],staticClass:"h-full flex-1 overflow-y-auto pb-36",class:{ spinner: _vm.isLoading }},[_vm._l((_vm.orders),function(order){return _c('div',{key:order.id,staticClass:"relative z-0 cursor-pointer divide-y divide-gray-200 border-b border-gray-200",on:{"click":function($event){return _vm.selectOrder(order)}}},[_c('LiveOrderListItem',{class:{
                            'bg-gray-100': _vm.selectedOrder.id === order.id
                        },attrs:{"order":order,"table-id":_vm.selectedTableId,"selected-venues-count":Array.isArray(_vm.venueFilter)
                                ? _vm.venueFilter.length
                                : 0,"venues-count":Array.isArray(_vm.venues) ? _vm.venues.length : 0,"is-absolute-time":_vm.isAbsoluteTime},on:{"toggle-absolute-time":function($event){_vm.isAbsoluteTime = !_vm.isAbsoluteTime}}})],1)}),(!_vm.isLoading && !_vm.orders.length)?_c('div',{staticClass:"flex h-full items-center justify-center"},[_c('h5',{staticClass:"font-bold text-gray-700"},[_vm._v(" No orders for today yet ")])]):_vm._e()],2)])]),_c('div',{staticClass:"flex w-0 flex-1 flex-col bg-sk-silver-grey lg:flex-shrink-0 lg:border-l lg:border-gray-200 xl:pr-0 print:w-full"},[(!_vm.isWebExtension)?_c('div',{staticClass:"hidden justify-end border-b border-t border-gray-200 bg-white pb-4 pl-4 pr-6 pt-4 sm:flex sm:pl-6 lg:pl-8 xl:border-t-0 xl:pl-6 xl:pt-6 print:hidden",staticStyle:{"height":"64px"}},[_c('button',{staticClass:"focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",attrs:{"type":"button"},on:{"click":_vm.toggleFullScreen}},[_c('svg',{staticClass:"-ml-0.5 mr-2 h-4 w-4",attrs:{"fill":"currentColor","viewBox":"0 0 20 20","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M3 4a1 1 0 011-1h4a1 1 0 010 2H6.414l2.293 2.293a1 1 0 11-1.414 1.414L5 6.414V8a1 1 0 01-2 0V4zm9 1a1 1 0 010-2h4a1 1 0 011 1v4a1 1 0 01-2 0V6.414l-2.293 2.293a1 1 0 11-1.414-1.414L13.586 5H12zm-9 7a1 1 0 012 0v1.586l2.293-2.293a1 1 0 111.414 1.414L6.414 15H8a1 1 0 010 2H4a1 1 0 01-1-1v-4zm13-1a1 1 0 011 1v4a1 1 0 01-1 1h-4a1 1 0 010-2h1.586l-2.293-2.293a1 1 0 111.414-1.414L15 13.586V12a1 1 0 011-1z","clip-rule":"evenodd"}})]),_vm._v(" Fullscreen ")]),(
                    _vm.$store.state.user &&
                    _vm.$store.state.user.profile &&
                    _vm.$store.state.user.profile.id !== 1828
                )?_c('router-link',{staticClass:"focus:outline-none ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",attrs:{"to":{ name: 'dashboard' }}},[_c('svg',{staticClass:"-ml-0.5 mr-2 h-4 w-4",attrs:{"fill":"currentColor","viewBox":"0 0 20 20","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z","clip-rule":"evenodd"}})]),_vm._v(" Exit ")]):_vm._e()],1):_vm._e(),_c('Order',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedOrder && _vm.selectedOrder.id),expression:"selectedOrder && selectedOrder.id"}],key:_vm.selectedOrder?.id,staticClass:"w-full",on:{"select":order => (this.selectedOrder = order)}})],1),_c('live-orders-filter-sidebar',{attrs:{"venues":_vm.venues,"venueFilter":_vm.venueFilter,"venueTables":_vm.venueTables,"showCompleted":_vm.showCompleted,"showFailed":_vm.showFailed},on:{"setVenue":_vm.setVenue,"setTable":_vm.setTable,"setFulfillmentMethods":_vm.setFulfillmentMethods,"toggleCompleted":_vm.toggleCompleted,"toggleFailed":_vm.toggleFailed,"clearFilters":_vm.clearFilters},model:{value:(_vm.isFilterSidebarOpen),callback:function ($$v) {_vm.isFilterSidebarOpen=$$v},expression:"isFilterSidebarOpen"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }