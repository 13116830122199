<template>
   <router-link
       :to="{ name: 'releaseNotes' }"
       active-class="bg-gray-700 text-white"
       class="focus:outline-none group relative mt-1 flex cursor-pointer select-none rounded-md px-2 py-1 font-heading text-sm font-medium leading-5 text-gray-200 transition duration-150 ease-in-out hover:bg-gray-700 hover:text-white focus:bg-gray-700 focus:text-white"
   >
      <svg
          viewBox="0 0 20 20"
          fill="currentColor"
          class="mr-3 h-5 w-5 text-gray-400 transition duration-150 ease-in-out group-hover:text-gray-200 group-focus:text-gray-200"
      >
          <path
              fill-rule="evenodd"
              d="M12 7a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0V8.414l-4.293 4.293a1 1 0 01-1.414 0L8 10.414l-4.293 4.293a1 1 0 01-1.414-1.414l5-5a1 1 0 011.414 0L11 10.586 14.586 7H12z"
              clip-rule="evenodd"
          ></path>
      </svg>
      What's new
     <span id="fb-update-badge"></span>
  </router-link>
</template>
<script>
export default {
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  beforeMount() {
    // const script = document.createElement("script");
    // script.src = "https://do.featurebase.app/js/sdk.js";
    // script.id = "featurebase-sdk";
    // document.head.appendChild(script);
  },
  mounted() {
    // const win = window;
    //
    // if (typeof win.Featurebase !== "function") {
    //   win.Featurebase = function () {
    //     (win.Featurebase.q = win.Featurebase.q || []).push(arguments);
    //   };
    // }
    // win.Featurebase("initialize_changelog_widget", {
    //   organization: "storekit", // Replace this with your featurebase organization name
    //   theme: "light", // Choose between dark or light theme
    //   fullscreenPopup: true, // Optional - Open a fullscreen announcement of the new feature to the user
    //   usersName: this.user.name, // Optional - Show the users name in the welcome message for the fullscreen popup
    //   // jwtToken: this.user.featureBaseToken
    // });
  },
  methods: {
    openPanel() {

      // window.Featurebase("initialize_changelog_widget", {
      //   organization: "storekit", // Replace this with your featurebase organization name
      //   theme: "light", // Choose between dark or light theme
      //   fullscreenPopup: true, // Optional - Open a fullscreen announcement of the new feature to the user
      //   usersName: this.user.name, // Optional - Show the users name in the welcome message for the fullscreen popup
      //   alwaysShow: true,
      //   jwtToken: this.user.featureBaseToken
      // });

      // window.Featurebase("initialize_portal_widget", {
      //   organization: 'storekit', // required
      //   placement: 'right', // optional
      //   fullScreen: true, // optional
      //   initialPage: 'ChangelogView', // optional (MainView, RoadmapView, CreatePost, PostsView, ChangelogView)
      //   metadata: null // Attach session-specific metadata to feedback. Refer to the advanced section for the details: https://help.featurebase.app/en/articles/3774671-advanced#7k8iriyap66
      // });
      //
      // window.postMessage({
      //   target: 'FeaturebaseWidget',
      //   data: {
      //     action: 'changePage',
      //     payload: 'ChangelogView',  // MainView, RoadmapView, CreatePost, PostsView, ChangelogView
      //     openWidget: true
      //   },
      // })

      // window.Featurebase('manually_open_changelog_popup')
    }
  }
};
</script>