<template>
    <div>
        <div v-if="!isLoading">
            <fieldset
                class="space-y-5"
                v-if="venuePrinters && venuePrinters.length > 1"
            >
                <legend class="sr-only">Printers</legend>
                <div v-if="categoryPrinters && !categoryPrinters.length">
                    <span class="text-sm text-gray-700"
                        >Menu items within
                        <span class="text-indigo-600">{{ category.name }}</span>
                        currently print on all ({{ venuePrinters.length }})
                        enabled printers. Tick a printer to print on selected
                        printers only.
                    </span>
                </div>
                <div
                    class="relative flex select-none items-start"
                    v-for="printer in venuePrinters"
                    :key="printer.id"
                >
                    <base-checkbox
                        :id="printer.id"
                        v-model="categoryPrinters"
                        :value="printer.id"
                        :label="printer.venues[0].printerName"
                        :caption="printer.mac"
                    />
                </div>
            </fieldset>
            <div v-else-if="venuePrinters && venuePrinters.length === 1">
                <div class="my-8 text-center">
                    <svg
                        class="mx-auto h-12 w-12 text-gray-400"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
                        ></path>
                    </svg>
                    <h3 class="mt-2 text-sm font-medium text-gray-900">
                        Only 1 printer available
                    </h3>
                    <p class="mt-1 text-sm text-gray-500">
                        You'll need to connect more than one printer to use
                        category printing.
                    </p>
                </div>
            </div>
            <div v-else>
                <div class="my-8 text-center">
                    <svg
                        class="mx-auto h-12 w-12 text-gray-400"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
                        ></path>
                    </svg>
                    <h3 class="mt-2 text-sm font-medium text-gray-900">
                        No printers
                    </h3>
                    <p class="mt-1 text-sm text-gray-500">
                        You'll need to connect a printer first.
                    </p>
                </div>
            </div>
            <div
                v-if="venuePrinters && venuePrinters.length > 1"
                class="mt-5 justify-between sm:mt-6 sm:flex sm:w-auto"
                :class="{ spinner: isLoading }"
            >
                <span class="flex rounded-md shadow-sm">
                    <button
                        @click="updateCategoryPrinters"
                        :disabled="isLoading"
                        type="button"
                        class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo sm:text-sm sm:leading-5"
                    >
                        Save changes
                    </button>
                </span>
                <span
                    class="mt-3 flex w-full rounded-md shadow-sm sm:ml-3 sm:mt-0 sm:w-auto"
                >
                    <button
                        @click="close"
                        type="button"
                        class="focus:outline-none inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium leading-6 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline sm:text-sm sm:leading-5"
                    >
                        Close
                    </button>
                </span>
            </div>
        </div>
        <div
            v-else-if="isLoading && !venuePrinters.length"
            class="mt-20 spinner"
        >
        </div>
    </div>
</template>

<script>
export default {
    name: 'CategoryPrintingSettings',
    props: {
        venueId: {
            type: Number,
            required: true
        },
        category: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isLoading: false,
            venuePrinters: [],
            categoryPrinters: []
        };
    },
    mounted() {
        this.fetchPrinters();
        this.categoryPrinters = this.category.printers.map(
            printer => printer.id
        );
    },
    methods: {
        async fetchPrinters() {
            this.isLoading = true;
            const params = {
                venueId: this.venueId
            };

            try {
                const { data } = await this.$axios.get('/printers', {
                    params
                });

                this.venuePrinters = data.filter(printer => {
                    if (printer.printerId && printer.venues.length) {
                        return printer;
                    }
                });
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        },
        async updateCategoryPrinters() {
            this.isLoading = true;
            const payload = [];

            this.categoryPrinters.forEach(printerId =>
                payload.push({ printerId })
            );

            try {
                await this.$axios.post(
                    `/categories/${this.category.id}/printers`,
                    payload
                );

                this.close();
                this.$emit('update');
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        },
        close() {
            this.$emit('close');
        }
    }
};
</script>
