<template>
    <div>
        <div
            v-if="shouldDisplayDateLabel"
            class="pb-4 ml-10 pt-4 px-8 text-xs font-medium text-gray-500 sm:px-10"
            >{{ getDateLabel(event) }}
        </div>
        <div v-if="Object.keys(event.comment).length" class="z-50 w-full px-6">
            <comment-component
                :comment="event.comment"
                @fetch-comments="$emit('fetch-comments', event.comment.id)"
            />
        </div>

        <div class="relative mb-4 w-full" v-else>
            <div
                class="marker top-1/2 flex h-2 w-2 -translate-y-1/2 transform rounded-lg bg-gray-100 shadow ring-1 ring-gray-400"
            ></div>
            <div
                class="ml-14 flex flex-col px-6 text-xs leading-4 font-medium text-gray-700"
            >
              <div class="flex w-full justify-between">
                <span>{{ event.title }}</span>
                <span class="text-gray-500">{{ formatTime(event.timestamp) }}</span>
              </div>
              <component
                  :is="event.action && event.action.type === 'button' && event.action.routerLink ? 'router-link' : 'div'"
                  v-if="event.action && event.action.type === 'button'"
                  :to="event.action.routerLink"
                  class="mt-1.5">
                <base-button :button-text="event.action.text" :href="event.action.url" size="xs" />
              </component>

            </div>

        </div>
        <div
            v-if="!isExpanded && eventsCount > 8 && index === 1"
            class="mb-7 ml-10 mt-10 flex w-full px-8 text-xs font-medium text-gray-700 sm:px-12"
        >
            <button
                class="text-sm text-gray-500"
                @click="$emit('toggle-collapse')"
                >Show {{ eventsCount - 5 }} more events</button
            >
        </div>
    </div>
</template>

<script>
import CommentComponent from '@/components/comments/CommentComponent';

export default {
    name: 'ActivityItem',
    props: {
        event: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        },
        isExpanded: {
            type: Boolean,
            default: false
        },
        eventsCount: {
            type: Number,
            required: false,
            default: 0
        },
        shouldDisplayDateLabel: {
            type: Boolean,
            required: true
        }
    },
    components: {
        CommentComponent
    },
    methods: {
        formatTime(date) {
            return this.$moment(date).format('HH:mm');
        },
        getDateLabel(event) {
            const today = this.$moment().startOf('day');
            const yesterday = this.$moment().subtract(1, 'day').startOf('day');
            const eventDate = this.$moment(event.timestamp).startOf('day');

            if (eventDate.isSame(today)) {
                return 'Today';
            }

            if (eventDate.isSame(yesterday)) {
                return 'Yesterday';
            }

            return this.$moment(event.timestamp).format('LL');
        }
    }
};
</script>

<style scoped>
.marker {
    position: absolute;
    left: 57px;
}
</style>
