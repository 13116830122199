<template>
    <svg
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 50 50"
        class="flex-shrink-0"
        :class="[color, sizeClass]"
        stroke="currentColor"
    >
        <title>Pickup Order</title>
        <path
            d="M 11.537109 3 C 9.459297 3 7.590082 4.291721 6.8574219 6.2363281 L 4.0644531 13.646484 A 1.0001 1.0001 0 0 0 5.4472656 14.894531 L 10.802734 12.216797 L 13.292969 14.707031 A 1.0001 1.0001 0 0 0 14.447266 14.894531 L 19.802734 12.216797 L 22.292969 14.707031 A 1.0001 1.0001 0 0 0 23.447266 14.894531 L 28.802734 12.216797 L 31.292969 14.707031 A 1.0001 1.0001 0 0 0 32.935547 14.353516 L 36.091797 5.9785156 A 1.0001 1.0001 0 0 0 36.09375 5.9746094 C 36.30574 5.4077876 36.847006 5 37.5 5 C 38.340812 5 39 5.6591883 39 6.5 L 39 10.798828 L 34.076172 22.615234 C 34.025172 22.737234 34 22.868 34 23 L 34 45 L 6 45 L 6 23.201172 L 9.3652344 15.171875 L 6.6210938 16.542969 L 4.078125 22.613281 C 4.026125 22.735281 4 22.867 4 23 L 4 46 C 4 46.553 4.448 47 5 47 L 35 47 L 45 47 A 1.0001 1.0001 0 0 0 46 46 L 46 23 A 1.0001 1.0001 0 0 0 45.923828 22.615234 L 41 10.798828 L 41 6.5 C 41 4.6350258 39.508559 3.1062394 37.664062 3.0175781 A 1.0001 1.0001 0 0 0 37.613281 3.0078125 A 1.0001 1.0001 0 0 0 37.513672 3 A 1.0001 1.0001 0 0 0 37.5 3 L 11.537109 3 z M 11.537109 5 L 34.433594 5 C 34.380864 5.1061604 34.262592 5.1634123 34.220703 5.2753906 L 34.220703 5.2714844 L 31.611328 12.197266 L 29.707031 10.292969 A 1.0001 1.0001 0 0 0 28.552734 10.105469 L 23.197266 12.783203 L 20.707031 10.292969 A 1.0001 1.0001 0 0 0 19.552734 10.105469 L 14.197266 12.783203 L 11.707031 10.292969 A 1.0001 1.0001 0 0 0 10.552734 10.105469 L 6.8359375 11.962891 L 8.7304688 6.9414062 C 9.1718086 5.7700134 10.284922 5 11.537109 5 z M 40 13.601562 L 44 23.199219 L 44 45 L 36 45 L 36 23.199219 L 40 13.601562 z M 13 28 A 1.0001 1.0001 0 0 0 12 29 L 12 39 A 1.0001 1.0001 0 0 0 13 40 L 27 40 A 1.0001 1.0001 0 0 0 28 39 L 28 29 A 1.0001 1.0001 0 0 0 27 28 L 13 28 z M 14 30 L 26 30 L 26 38 L 14 38 L 14 30 z"
        />
    </svg>
</template>

<script>
export default {
    name: 'PickupIcon',
    props: ['color', 'size'],
    computed: {
        sizeClass() {
            if (this.size === 'lg') {
                return 'w-7 h-7';
            } else if (this.size === 'sm') {
                return 'w-5 h-5';
            }
            return 'w-6 h-6';
        }
    }
};
</script>
