<template>
    <div class="mt-4">
        <div class="relative" :class="{ 'opacity-75': !hasOnboarded }">
            <div
                class="mt-2 border border-gray-100 bg-white shadow sm:rounded-md"
                v-if="!isLoading"
            >
                <div class="rounded-t-md border-b border-gray-100 overflow-hidden">
                    <div
                        class="relative"
                    >
                        <ul class="flex flex-row items-center w-full px-2 py-2.5 mb-2 sm:mb-0 overflow-scroll">
                          <li
                              @click="
                                  period = periodOption.value;
                                  fetchOrderCount();
                              "
                              class="mr-1 cursor-pointer rounded-md px-3 py-1 text-xs transition duration-150 ease-in-out hover:bg-gray-50"
                              :class="{
                                        'bg-gray-100':
                                            periodOption.value == period
                                    }"
                              v-for="periodOption in periods"
                              :key="periodOption.value"
                          >
                            {{ periodOption.label }}
                          </li>
                        </ul>
                    </div>
                </div>
                <div class="grid grid-cols-1 md:grid-cols-3">
                    <div>
                        <div class="px-4 py-3">
                            <dl>
                                <dt
                                    class="flex items-center text-xs justify-between text-gray-700"
                                >
                                    Total orders
                                    <div
                                        class="ml-3 inline-flex items-center rounded-md ring-1 ring-inset bg-yellow-50 ring-yellow-300 px-2 py-0.5 text-xs font-medium leading-5 leading-none text-yellow-700 md:mt-2 lg:mt-0"
                                        :class="{
                                            ' bg-green-50 text-green-700 ring-green-300':
                                                orderCountIncrease > 0 ||
                                                isNaN(orderCountIncrease)
                                        }"
                                    >
                                      <base-trending-up-icon v-if="orderCountIncrease >= 0 ||
                                                !isFinite(orderCountIncrease)" class="h-4 h-4 -ml-1 mr-0.5 flex-shrink-0 self-center" />
                                      <base-trending-down-icon v-else class="h-4 h-4 -ml-1 mr-0.5 flex-shrink-0 self-center" />
                                        <span class="sr-only">
                                            Increased by
                                        </span>
                                        <span>
                                            <animated-number
                                                class="font-heading"
                                                :value="orderCountIncrease"
                                                :duration="800"
                                            />%</span
                                        >
                                    </div>
                                </dt>
                                <dd
                                    class="mt-1 flex items-baseline justify-between md:block lg:flex"
                                >
                                    <div
                                        class="flex flex-col items-baseline text-2xl font-semibold leading-8 text-black sm:text-xl"
                                    >
                                        <span>
                                            <animated-number
                                                class="font-heading"
                                                :value="orderCount"
                                                :duration="1400"
                                        /></span>
                                        <span
                                            class="text-sm font-medium leading-5 text-gray-500"
                                        >
                                            from
                                            <span>
                                                <animated-number
                                                    class="font-heading"
                                                    :value="lastMonthOrderCount"
                                                    :duration="800"
                                            /></span>
                                        </span>
                                    </div>
                                </dd>
                            </dl>
                        </div>
                    </div>
                    <div
                        class="border-t border-gray-100 md:border-0 md:border-l"
                    >
                        <div class="px-4 py-3">
                            <dl>
                                <dt
                                    class="flex items-center text-xs justify-between text-gray-700"
                                >
                                    Avg. order value
                                    <div
                                        class="ml-3 inline-flex flex-row items-center rounded-md ring-1 ring-inset bg-yellow-50 px-2 py-0.5 text-xs font-medium leading-none text-yellow-700 ring-yellow-300 md:mt-2 lg:mt-0"
                                        :class="{
                                            'bg-green-50 text-green-700 ring-green-300':
                                                averageValueIncrease > 0 ||
                                                isNaN(averageValueIncrease)
                                        }"
                                    >
                                      <base-trending-up-icon v-if="averageValueIncrease >= 0 ||
                                                !isFinite(averageValueIncrease)" class="h-4 h-4 -ml-1 mr-0.5 flex-shrink-0 self-center" />
                                      <base-trending-down-icon v-else class="h-4 h-4 -ml-1 mr-0.5 flex-shrink-0 self-center" />
                                        <span class="sr-only">
                                            Increased by
                                        </span>
                                        <span>
                                            <animated-number
                                                class="font-heading"
                                                :value="averageValueIncrease"
                                                :duration="800"
                                                round="1"
                                            />%
                                        </span>
                                    </div>
                                </dt>
                                <dd
                                    class="mt-1 flex items-baseline justify-between md:block lg:flex"
                                >
                                    <div
                                        class="flex flex-col items-baseline text-2xl font-semibold leading-8 text-black sm:text-xl"
                                    >
                                        <animated-number
                                            class="font-heading"
                                            :value="avgOrder / 100"
                                            :duration="800"
                                            price
                                        />
                                        <span
                                            class="text-sm font-medium leading-5 text-gray-500"
                                        >
                                            from
                                            <animated-number
                                                class="font-heading"
                                                :value="lastMonthAvgOrder / 100"
                                                :duration="600"
                                                price
                                            />
                                        </span>
                                    </div>
                                </dd>
                            </dl>
                        </div>
                    </div>
                    <div
                        class="border-t border-gray-100 md:border-0 md:border-l"
                    >
                        <div class="px-4 py-3">
                            <dl>
                                <dt
                                    class="flex items-center text-xs justify-between text-gray-700"
                                >
                                    Gross revenue
                                    <div
                                        class="ml-3 inline-flex items-center rounded-md ring-1 ring-inset bg-yellow-50 px-2 py-0.5 text-xs font-medium leading-none text-yellow-700 ring-yellow-300 md:mt-2 lg:mt-0"
                                        :class="{
                                            ' bg-green-50 text-green-700 ring-green-300':
                                                revenueIncrease > 0 ||
                                                isNaN(revenueIncrease)
                                        }"
                                    >
                                      <base-trending-up-icon v-if="revenueIncrease >= 0 ||
                                                !isFinite(revenueIncrease)" class="h-4 h-4 -ml-1 mr-0.5 flex-shrink-0 self-center" />
                                      <base-trending-down-icon v-else class="h-4 h-4 -ml-1 mr-0.5 flex-shrink-0 self-center" />
                                        <span class="sr-only">
                                            Increased by
                                        </span>
                                        <span>
                                            <animated-number
                                                class="font-heading"
                                                :value="revenueIncrease"
                                                :duration="600"
                                            />%</span
                                        >
                                    </div>
                                </dt>
                                <dd
                                    class="mt-1 flex items-baseline justify-between md:block lg:flex"
                                >
                                    <div
                                        class="flex flex-col items-baseline text-2xl font-semibold leading-8 text-black sm:text-xl"
                                    >
                                        <animated-number
                                            class="font-heading"
                                            :value="revenue"
                                            :duration="1800"
                                            price
                                            is-abbreviate
                                        />
                                        <span
                                            class="text-sm font-medium leading-5 text-gray-500"
                                        >
                                            from
                                            <animated-number
                                                class="font-heading"
                                                :value="revenueLastMonth"
                                                :duration="1200"
                                                price
                                                is-abbreviate
                                            />
                                        </span>
                                    </div>
                                </dd>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="mt-5 grid grid-cols-1 overflow-hidden border border-gray-100 bg-white p-4 shadow sm:rounded-md md:grid-cols-3"
                v-else
            >
                <div>
                    <VclList :width="180" :height="100" />
                </div>
                <div
                    class="border-t border-gray-200 pl-3 pt-3 sm:pt-0 md:border-0 md:border-l"
                >
                    <VclList :width="180" :height="100" />
                </div>
                <div
                    class="border-t border-gray-200 pl-3 pt-3 sm:pt-0 md:border-0 md:border-l"
                >
                    <VclList :width="180" :height="100" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { VclList } from 'vue-content-loading';
import AnimatedNumber from '../helpers/AnimatedNumber';
import BaseTrendingUpIcon from "@/components/base/icons/BaseTrendingUpIcon.vue";

export default {
    name: 'HomepageStats',
    props: {
        hasOnboarded: {
            type: Boolean
        }
    },
    components: {
      BaseTrendingUpIcon,
        VclList,
        AnimatedNumber
    },
    data() {
        return {
            isLoading: true,
            orderCount: 0,
            lastMonthOrderCount: 0,
            avgOrder: 0,
            revenue: 0,
            revenueLastMonth: 0,
            period: 30,
            fetchTimeout: null,
            periods: [{
                label: 'Today',
                value: 1
            },
              {
                label: 'This Week',
                value: new Date().getDay() + 1
              },
              {
                label: 'Last 7 Days',
                value: 7
            }, {
                label: 'Last 30 Days',
                value: 30
            },
              {
                label: '1 Year',
                value: 365
            }]
        };
    },
    async created() {
        this.isLoading = true;
        if (localStorage.getItem('dashboardStatsPeriod')) {
          this.period = localStorage.getItem('dashboardStatsPeriod');
        } else {
          this.period = 30;
        }
        this.fetchOrderCount();
    },
    beforeDestroy() {
        clearTimeout(this.fetchTimeout);
    },
    methods: {
        fetchOrderCount() {
            clearTimeout(this.fetchTimeout);

            let period = this.period || 30;
            this.$axios
                .get('/reports/stats?period=' + period)
                .then(res => {
                  [this.orderCount, this.lastMonthOrderCount, this.avgOrder, this.lastMonthAvgOrder, this.revenue, this.revenueLastMonth] = res.data;
                })
                .catch(e => {
                    throw new Error(`API ${e}`);
                })
                .finally(() => {
                    this.isLoading = false;
                    const THIRTY_SECONDS = 30000;
                    this.fetchTimeout = setTimeout(() => {
                        if (!document.hidden) {
                            this.fetchOrderCount();
                        }
                    }, THIRTY_SECONDS);
                });
        },
        onClickOutside() {
            this.dropdownOpen = false;
        },
        calculateIncrease(current, previous) {
          const increase = ((current - previous) / Math.abs(previous)) * 100;
          return isNaN(increase) ? '-' : !isFinite(increase) ? '∞' : increase.toFixed(2);
        },
    },
    computed: {
      orderCountIncrease() {
        return this.calculateIncrease(this.orderCount, this.lastMonthOrderCount);
      },
      averageValueIncrease() {
        return this.calculateIncrease(this.avgOrder, this.lastMonthAvgOrder);
      },
      revenueIncrease() {
        return this.calculateIncrease(this.revenue, this.revenueLastMonth);
      },
    },
    watch: {
        period(newPeriod) {
            localStorage.setItem('dashboardStatsPeriod', newPeriod);
        }
    }
};
</script>
