<template>
    <div>
        <div v-if="!isLoading" class="shadow sm:overflow-hidden sm:rounded-md">
            <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
                <div v-if="!editMode">
                    <label
                        for="model"
                        class="block text-sm font-medium text-gray-700"
                        >Model</label
                    >
                    <select
                        id="model"
                        name="model"
                        v-model="model"
                        :disabled="editMode"
                        class="focus:outline-none form-input mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    >
                        <option
                            v-for="(model, index) in models"
                            :key="`model-${index}`"
                        >
                            {{ model }}
                        </option>
                        <option disabled>Star SP742 (Coming soon)</option>
                    </select>
                </div>

                <fieldset>
                    <label class="block text-sm font-medium text-gray-700">
                        Name
                    </label>
                    <input
                        v-model="printerName"
                        class="form-input mt-1 block w-full sm:text-sm sm:leading-5"
                        :class="{
                            'border-red-300': $v.printerName.$error
                        }"
                        type="text"
                    />

                    <span v-if="$v.printerName.$error" class="text-red-400">
                        {{ printerNameError }}
                    </span>
                </fieldset>
                <fieldset>
                    <label
                        for="model"
                        class="block text-sm font-medium text-gray-700"
                        >Text size</label
                    >
                    <select
                        id="textSize"
                        name="textSize"
                        v-model="textSize"
                        class="focus:outline-none form-input mt-1 block w-full rounded-md border-gray-300 pb-2 pl-3 pr-10 text-base capitalize focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    >
                        <option
                            v-for="(textSize, index) in textSizes"
                            :key="`textSize-${index}`"
                        >
                            {{ textSize }}
                        </option>
                    </select>
                </fieldset>
                <fieldset>
                    <label
                        class="mb-2 block text-sm font-medium leading-5 text-gray-700"
                        >Print item prices
                    </label>
                    <toggle-switch v-model="showPrices" />
                </fieldset>

                <fieldset>
                    <legend class="sr-only"> Auto printing settings </legend>

                    <div class="-space-y-px rounded-md bg-white">
                        <div
                            @click="autoPrint = true"
                            class="relative flex cursor-pointer rounded-tl-md rounded-tr-md border p-4"
                            :class="{
                                'z-10 border-indigo-200 bg-indigo-50': autoPrint,
                                'border-gray-200': !autoPrint
                            }"
                        >
                            <div class="flex h-5 items-center self-center">
                                <input
                                    id="settings-option-0"
                                    name="auto_settings"
                                    type="radio"
                                    class="h-4 w-4 cursor-pointer border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                    :checked="autoPrint"
                                />
                            </div>
                            <label
                                for="settings-option-0"
                                class="ml-3 flex cursor-pointer flex-col"
                            >
                                <span
                                    :class="{
                                        'text-indigo-900': autoPrint,
                                        'text-gray-900': !autoPrint
                                    }"
                                    class="block text-sm font-medium"
                                >
                                    Auto print
                                </span>
                                <span
                                    :class="{
                                        'text-indigo-700': autoPrint,
                                        'text-gray-500': !autoPrint
                                    }"
                                    class="block text-xs"
                                >
                                    Print every order as soon as it's accepted
                                </span>
                            </label>
                        </div>

                        <div
                            @click="autoPrint = false"
                            class="relative flex cursor-pointer rounded-bl-md rounded-br-md border p-4"
                            :class="{
                                'z-10 border-indigo-200 bg-indigo-50': !autoPrint,
                                'border-gray-200': autoPrint
                            }"
                        >
                            <div class="flex h-5 items-center self-center">
                                <input
                                    id="settings-option-1"
                                    name="auto_settings"
                                    type="radio"
                                    class="h-4 w-4 cursor-pointer border-gray-300 text-indigo-600 focus:ring-indigo-100"
                                    :checked="!autoPrint"
                                />
                            </div>
                            <label
                                for="settings-option-1"
                                class="ml-3 flex cursor-pointer flex-col"
                            >
                                <span
                                    :class="{
                                        'text-indigo-900': !autoPrint,
                                        'text-gray-900': autoPrint
                                    }"
                                    class="block text-sm font-medium"
                                >
                                    Manual print
                                </span>
                                <span
                                    :class="{
                                        'text-indigo-700': !autoPrint,
                                        'text-gray-500': autoPrint
                                    }"
                                    class="block text-xs"
                                >
                                    Only print orders when clicking print in the
                                    dashboard
                                </span>
                            </label>
                        </div>
                    </div>
                </fieldset>

                <fieldset>
                    <legend class="sr-only"> Number of copies </legend>

                    <div class="-space-y-px rounded-md bg-white">
                        <div
                            @click="defaultCopies = 1"
                            class="relative flex cursor-pointer rounded-tl-md rounded-tr-md border p-4"
                            :class="{
                                'z-10 border-indigo-200 bg-indigo-50':
                                    defaultCopies === 1,
                                'border-gray-200': defaultCopies !== 1
                            }"
                        >
                            <div class="flex h-5 items-center">
                                <input
                                    id="defaultCopies1"
                                    name="privacy_setting"
                                    type="radio"
                                    class="h-4 w-4 cursor-pointer border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                    :checked="defaultCopies === 1"
                                />
                            </div>
                            <label
                                for="defaultCopies1"
                                class="ml-3 flex cursor-pointer flex-col"
                            >
                                <span
                                    :class="{
                                        'text-indigo-900': defaultCopies === 1,
                                        'text-gray-900': defaultCopies !== 1
                                    }"
                                    class="block text-sm font-medium"
                                >
                                    1 copy
                                </span>
                            </label>
                        </div>

                        <div
                            @click="defaultCopies = 2"
                            class="relative flex cursor-pointer border p-4"
                            :class="{
                                'z-10 border-indigo-200 bg-indigo-50':
                                    defaultCopies === 2,
                                'border-gray-200': defaultCopies !== 2
                            }"
                        >
                            <div class="flex h-5 items-center">
                                <input
                                    id="defaultCopies2"
                                    name="privacy_setting"
                                    type="radio"
                                    class="h-4 w-4 cursor-pointer border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                    :checked="defaultCopies === 2"
                                />
                            </div>
                            <label
                                for="defaultCopies2"
                                class="ml-3 flex cursor-pointer flex-col"
                            >
                                <span
                                    :class="{
                                        'text-indigo-900': defaultCopies === 2,
                                        'text-gray-900': defaultCopies !== 2
                                    }"
                                    class="block text-sm font-medium"
                                >
                                    2 copies
                                </span>
                            </label>
                        </div>

                        <div
                            @click="defaultCopies = 3"
                            class="relative flex cursor-pointer border p-4"
                            :class="{
                                'z-10 border-indigo-200 bg-indigo-50':
                                    defaultCopies === 3,
                                'border-gray-200': defaultCopies !== 3
                            }"
                        >
                            <div class="flex h-5 items-center">
                                <input
                                    id="defaultCopies3"
                                    name="privacy_setting"
                                    type="radio"
                                    class="h-4 w-4 cursor-pointer border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                    :checked="defaultCopies === 3"
                                />
                            </div>
                            <label
                                for="defaultCopies3"
                                class="ml-3 flex cursor-pointer flex-col"
                            >
                                <span
                                    :class="{
                                        'text-indigo-900': defaultCopies === 3,
                                        'text-gray-900': defaultCopies !== 3
                                    }"
                                    class="block text-sm font-medium"
                                >
                                    3 copies
                                </span>
                            </label>
                        </div>

                        <div
                            @click="defaultCopies = 4"
                            class="relative flex cursor-pointer rounded-bl-md rounded-br-md border p-4"
                            :class="{
                                'z-10 border-indigo-200 bg-indigo-50':
                                    defaultCopies === 4,
                                'border-gray-200': defaultCopies !== 4
                            }"
                        >
                            <div class="flex h-5 items-center">
                                <input
                                    id="defaultCopies4"
                                    name="privacy_setting"
                                    type="radio"
                                    class="h-4 w-4 cursor-pointer border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                    :checked="defaultCopies === 4"
                                />
                            </div>
                            <label
                                for="defaultCopies3"
                                class="ml-3 flex cursor-pointer flex-col"
                            >
                                <span
                                    :class="{
                                        'text-indigo-900': defaultCopies === 4,
                                        'text-gray-900': defaultCopies !== 4
                                    }"
                                    class="block text-sm font-medium"
                                >
                                    4 copies
                                </span>
                            </label>
                        </div>
                    </div>
                </fieldset>

                <fieldset v-if="autoPrint">
                    <label class="block text-sm font-medium text-gray-700">
                        Buffer (in minutes)
                    </label>
                    <input
                        v-model.number="buffer"
                        class="form-input mt-1 block w-full sm:text-sm sm:leading-5"
                        :class="{
                            'border-red-300': $v.buffer.$error
                        }"
                        placeholder="eg 30"
                        type="number"
                        min="0"
                        max="720"
                    />

                    <span v-if="$v.buffer.$error" class="text-red-400">
                        {{ bufferError }}
                    </span>
                </fieldset>

                <div v-if="!editMode">
                    <label
                        for="mac"
                        class="block text-sm font-medium text-gray-700"
                    >
                        MAC Address
                    </label>
                    <div class="mt-1">
                        <input
                            v-model="mac"
                            type="tel"
                            v-mask="'XX:XX:XX:XX:XX:XX'"
                            id="mac"
                            name="mac"
                            class="form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            :class="{
                                'border-red-300': $v.mac.$error
                            }"
                            placeholder="00:11:62:a1:b2:c3"
                            :disabled="editMode"
                            required
                        />
                    </div>

                    <span v-if="$v.mac.$error" class="text-red-400">
                        {{ macError }}
                    </span>

                    <p class="mt-2 text-sm text-gray-500">
                        Perform a self-print to get access to your printer's MAC
                        address. Follow
                        <a
                            href="https://www.star-m.jp/products/s_print/mcprint3/manual/en/basicOperations/testPrint.htm"
                            target="_blank"
                            class="text-indigo-600"
                            >these instructions</a
                        >
                        or contact us if you get stuck
                    </p>
                </div>
            </div>
            <div
                class="flex items-center justify-between bg-gray-50 px-4 py-3 text-right sm:px-6"
            >
                <div
                    v-if="editMode"
                    @click="showDeletePrinterModal()"
                    :disabled="isLoading"
                    class="group mr-3 inline-block cursor-pointer text-gray-400"
                >
                    <svg
                        class="h-5 w-5 group-hover:text-red-400"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                        ></path>
                    </svg>
                </div>
                <button
                    @click="editPrinter"
                    v-if="editMode"
                    type="submit"
                    class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                    Save settings
                </button>

                <button
                    v-else
                    class="focus:outline-none inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    @click="createPrinter"
                >
                    Add printer
                </button>
            </div>
        </div>
        <div
            v-else
            class="sm:p-6w-4 h-4 space-y-6 px-4 py-5 text-gray-900 spinner"
        >
            <span></span>
        </div>

        <DeletePrinterModal @fetch-printers="navigateToPrinterList" />
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import {
    required,
    minLength,
    minValue,
    maxValue,
    numeric
} from 'vuelidate/lib/validators';
import DeletePrinterModal from '@/components/modals/DeletePrinterModal';
import ToggleSwitch from '@/components/formComponents/ToggleSwitch';

export default {
    validations: {
        mac: { required, minLength: minLength(17) },
        buffer: { minValue: minValue(0), maxValue: maxValue(720), numeric },
        printerName: { required }
    },
    components: {
        DeletePrinterModal,
        ToggleSwitch
    },
    props: {
        venueId: {
            type: Number,
            required: true,
            default: 0
        },
        selectedMac: {
            type: String,
            required: true,
            default: ''
        },
        editMode: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    data() {
        return {
            autoPrint: true,
            defaultCopies: 1,
            mac: this.selectedMac,
            isLoading: false,
            macError: 'Incorrect MAC Address',
            buffer: 0,
            model: 'StarMCPrint3',
            bufferError: 'Buffer must be value between 0 - 720',
            printerName: 'Printer',
            textSize: 'medium',
            showPrices: false,
            textSizes: ['medium', 'large'],
            printerNameError: 'Printer name is required',
            models: ['StarMCPrint3', 'StarMCPrint2', 'StarTSP100IV']
        };
    },
    created() {
        if (this.editMode) {
            this.getPrinter();
        }
    },
    computed: {
        ...mapGetters({ user: 'user/currentUser' })
    },
    methods: {
        async getPrinter() {
            try {
                this.isLoading = true;

                const params = {
                    venueId: this.venueId
                };

                const { data } = await this.$axios.get(
                    `/printers/${this.mac}`,
                    { params }
                );

                this.autoPrint = data.autoPrint;
                this.defaultCopies = data.defaultCopies;
                this.buffer = data.buffer;
                this.printerName = data.printerName;
                this.textSize = data.textSize;
                this.showPrices = data.showPrices;
            } catch (error) {
                this.$router.push({ name: 'printerIntegration' });

                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        },
        async createPrinter() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.isLoading = true;

            const payload = {
                venueId: this.venueId,
                autoPrint: this.autoPrint,
                defaultCopies: this.defaultCopies,
                mac: this.mac,
                printerName: this.printerName,
                model: this.model,
                buffer: this.buffer,
                textSize: this.textSize,
                showPrices: this.showPrices
            };

            try {
                await this.$axios.post('/printers', payload);

                this.$router.push({ name: 'printerIntegration' });
                if (window.analytics) {
                    window.analytics.track('Printer Created', {
                        venueId: this.venueId,
                        autoPrint: this.autoPrint,
                        defaultCopies: this.defaultCopies,
                        mac: this.mac,
                        buffer: this.buffer,
                        accountId: this.user.accounts[0].id,
                        context: {
                            groupId: this.user.accounts[0].id
                        }
                    });
                }
            } catch (error) {
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.message &&
                    error.response.data.message.includes('printer_exists')
                ) {
                    this.$modal.show('custom-error-modal', {
                        title: 'Printer already exists',
                        text:
                            'The printer you are trying to add already exists for this venue'
                    });
                }

                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        },

        async editPrinter() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.isLoading = true;

            const payload = {
                venueId: this.venueId,
                autoPrint: this.autoPrint,
                defaultCopies: this.defaultCopies,
                mac: this.mac,
                buffer: this.buffer,
                printerName: this.printerName,
                textSize: this.textSize,
                showPrices: this.showPrices
            };

            try {
                await this.$axios.put('/printers', payload);

                this.isLoading = false;

                this.$notify({
                    group: 'settings',
                    title: 'Settings saved'
                });
                this.$router.push({ name: 'printerIntegration' });
            } catch (error) {
                this.isLoading = false;

                throw new Error(`API ${error}`);
            }
        },

        showDeletePrinterModal() {
            this.$modal.show('delete-printer-modal', {
                mac: this.mac,
                venueId: this.venueId
            });
        },

        navigateToPrinterList() {
            this.$router.push({ name: 'printerIntegration' });
        }
    }
};
</script>
