<template>
    <div class="relative">
        <mobile-device-warning v-if="isMobile" />

        <div v-else class="fixed inset-0 flex flex-col">
            <designer-header v-model="selectedDevice" >
              <template #right>
                <base-button
                  :disabled="!hasChanged || isLoading"
                  button-text="Publish changes"
                  button-style="action"
                  size="sm"
                  @clicked="saveVenue"
                  v-tooltip="'Save and publish changes to your store'"
              >
                  <template #left-icon>
                    <base-doc-icon class="mr-2 h-4 w-4" />
                  </template>
                </base-button>
              </template>
            </designer-header>

            <div class="flex h-full overflow-hidden">
                <div
                    class="h-full w-72 overflow-auto bg-white pb-10 pl-1 shadow-md"
                    style="scrollbar-width: thin; scrollbar-gutter: stable"
                >
                    <category-accordion
                        v-model="isOpen.images"
                        title="Images"
                    />
                    <div
                        v-show="isOpen.images"
                        class="flex w-full flex-col items-center border-b border-gray-200 justify-center gap-2 px-2 py-4"
                    >
                        <image-category
                            title="FAVICON"
                            key-name="faviconImage"
                            :ctx-name="`${ctxName}favImage`"
                            :is-edit-mode="isEdit.faviconImage"
                            :image-url="formData.faviconImage || ''"
                            :has-init-value="!!initValues.faviconImage"
                            @success="onSuccess"
                            @edit="isEdit.faviconImage = true"
                            @cancel-edit="onCancelEdit"
                            @image-removed="restoreInitValue"
                        />
                        <image-category
                            title="LOGO"
                            key-name="logo"
                            :ctx-name="`${ctxName}logo`"
                            :is-edit-mode="isEdit.logo"
                            :image-url="formData.logo || ''"
                            :has-init-value="!!initValues.logo"
                            @success="onSuccess"
                            @edit="isEdit.logo = true"
                            @cancel-edit="onCancelEdit"
                            @image-removed="restoreInitValue"
                        />
                        <image-category
                            title="LOGO NAV"
                            key-name="logoNav"
                            :ctx-name="`${ctxName}logoNav`"
                            :is-edit-mode="isEdit.logoNav"
                            :image-url="formData.logoNav || ''"
                            :has-init-value="!!initValues.logoNav"
                            @success="onSuccess"
                            @edit="isEdit.logoNav = true"
                            @cancel-edit="onCancelEdit"
                            @image-removed="restoreInitValue"
                        />
                        <image-category
                            title="HEADER IMAGE"
                            key-name="headerImage"
                            :ctx-name="`${ctxName}headerImage`"
                            :is-edit-mode="isEdit.headerImage"
                            :image-url="formData.headerImage || ''"
                            :has-init-value="!!initValues.headerImage"
                            @success="onSuccess"
                            @edit="isEdit.headerImage = true"
                            @cancel-edit="onCancelEdit"
                            @image-removed="restoreInitValue"
                        />
                    </div>

                    <category-accordion
                        v-model="isOpen.menuItems"
                        title="Menu items"
                    />
                    <div
                        v-show="isOpen.menuItems"
                        class="flex w-full flex-col items-center border-b border-gray-200 justify-center gap-4 px-2 py-4"
                    >
                        <toggle-category
                            v-model="formData.showCurrencySymbol"
                            key-name="showCurrencySymbol"
                            title="Show a currency symbol"
                            @change="onSettingChange"
                        />
                        <toggle-category
                            v-model="formData.largeImages"
                            key-name="largeImages"
                            title="Large images"
                            @change="onSettingChange"
                        />
                        <toggle-category
                            v-model="formData.ageGated"
                            key-name="ageGated"
                            title="Age gated"
                            @change="onSettingChange"
                        />
                    </div>

                    <category-accordion
                        v-model="isOpen.typography"
                        title="Typography"
                    />
                    <div
                        v-show="isOpen.typography"
                        class="flex w-full flex-col items-center justify-center gap-4 border-b border-gray-200 px-2 py-4"
                    >
                        <font-category
                            title="BODY"
                            key-name="bodyFont"
                            :font="formData.bodyFont || {}"
                            :ctx-name="`${ctxName}bodyFont`"
                            :is-edit-mode="isEdit.bodyFont"
                            :has-init-value="!!initValues.bodyFont"
                            @success="onSuccess"
                            @edit="isEdit.bodyFont = true"
                            @cancel-edit="onCancelEdit"
                            @font-removed="onRemoved"
                        />
                        <font-category
                            title="HEADING"
                            key-name="headingFont"
                            :font="formData.headingFont || {}"
                            :ctx-name="`${ctxName}headingFont`"
                            :is-edit-mode="isEdit.headingFont"
                            :has-init-value="!!initValues.headingFont"
                            @success="onSuccess"
                            @edit="isEdit.headingFont = true"
                            @cancel-edit="onCancelEdit"
                            @font-removed="onRemoved"
                        />
                    </div>

                    <category-accordion
                        v-model="isOpen.description"
                        title="Description"
                    />
                    <div
                        v-show="isOpen.description"
                        class="flex w-full flex-col items-center border-b border-gray-200 justify-center gap-4 py-2 px-2"
                    >
                        <div class="w-full">
                            <label
                                for="about"
                                class="pl-1 text-xs font-medium leading-5 tracking-wide text-gray-700"
                            >
                                About
                            </label>
                            <textarea
                                id="about"
                                v-model.trim="formData.description"
                                rows="6"
                                class="form-input mt-1 block w-full rounded-md shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                @input="
                                    onSettingChange(
                                        'description',
                                        formData.description
                                    )
                                "
                            />
                        </div>
                    </div>

                    <category-accordion
                        v-model="isOpen.branding"
                        title="Colours"
                    />
                    <div
                        v-show="isOpen.branding"
                        class="flex w-full flex-col items-center justify-center gap-4 border-b border-gray-200 px-2 py-4"
                    >
                        <div
                            v-if="isFeatureAvailable(FeaturesEnum.INTERNAL)"
                            class="flex w-full items-start justify-between pb-2"
                        >
                            <div class="flex flex-col items-start gap-2">
                                <button
                                    class="text-right text-xs text-blue-700 hover:text-blue-500"
                                    @click="copyBranding"
                                >
                                    Copy Branding
                                </button>
                                <base-checkbox
                                    v-model="modifiedOnly"
                                    id="modifiedOnly"
                                    tooltip="If checked, only the modified settings will be copied."
                                >
                                    <template #label>
                                        <span class="text-xs text-gray-600">
                                            Modified only
                                        </span>
                                    </template>
                                </base-checkbox>
                            </div>
                            <button
                                class="text-right text-xs text-blue-700 hover:text-blue-500"
                                @click="pasteBranding"
                            >
                                Paste Branding
                            </button>
                        </div>
                        <colour-category
                            v-model="formData.primaryColour"
                            key-name="primaryColour"
                            title="Primary colour"
                            default-colour="#292a31"
                            @change="onSettingChange"
                        />
                        <div class="flex w-full flex-col gap-1">
                            <span
                                class="mt-1 text-sm font-medium leading-4 tracking-wide"
                            >
                                Text
                            </span>
                            <colour-category
                                v-for="item in textBranding"
                                :key="item.keyName"
                                v-model="formData[item.keyName]"
                                :key-name="item.keyName"
                                :title="item.title"
                                :default-colour="item.default"
                                @change="onSettingChange"
                            />
                        </div>
                        <div class="flex w-full flex-col gap-1">
                            <span
                                class="mt-1 text-sm font-medium leading-4 tracking-wide"
                            >
                                Buttons
                            </span>
                            <colour-category
                                v-for="item in buttonBranding"
                                :key="item.keyName"
                                v-model="formData[item.keyName]"
                                :key-name="item.keyName"
                                :title="item.title"
                                :default-colour="item.default ?? undefined"
                                @change="onSettingChange"
                            />
                        </div>
                        <div class="flex w-full flex-col gap-1">
                            <span
                                class="mt-1 text-sm font-medium leading-4 tracking-wide"
                            >
                                Menu Items
                            </span>
                            <colour-category
                                v-for="item in menuItemBranding"
                                :key="item.keyName"
                                v-model="formData[item.keyName]"
                                :key-name="item.keyName"
                                :title="item.title"
                                :default-colour="item.default"
                                @change="onSettingChange"
                            />
                        </div>
                    </div>

                    <template v-if="hasTableSection">
                        <category-accordion
                            v-model="isOpen.tables"
                            title="Tables"
                        />
                        <div
                            v-show="isOpen.tables"
                            class="flex w-full flex-col items-center justify-center gap-4 border-b border-gray-200 px-2 pb-4 pt-2"
                        >
                            <button
                                class="ml-auto text-right text-xs text-blue-700 hover:text-blue-500"
                                @click="toggleSelectionModal"
                            >
                                Toggle Modal
                            </button>
                            <toggle-category
                                v-model="formData.showTableSelectionModal"
                                keyName="showTableSelectionModal"
                                title="Show Table Selection Modal"
                                @change="onSettingChange"
                            />
                            <div class="w-full">
                                <label
                                    for="orderInstructions"
                                    class="pl-1 text-xs font-medium leading-5 tracking-wide text-gray-700"
                                >
                                    Order instructions
                                </label>
                                <textarea
                                    id="orderInstructions"
                                    v-model.trim="formData.orderInstructions"
                                    rows="6"
                                    class="form-input mt-1 block w-full rounded-md shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                    @input="
                                        onSettingChange(
                                            'orderInstructions',
                                            formData.orderInstructions
                                        )
                                    "
                                />
                            </div>
                            <image-category
                                title="Splash screen image"
                                key-name="tableScreenImage"
                                :ctx-name="`${ctxName}tableScreenImage`"
                                :is-edit-mode="isEdit.tableScreenImage"
                                :image-url="formData.tableScreenImage || ''"
                                :has-init-value="!!initValues.tableScreenImage"
                                @success="onSuccess"
                                @edit="isEdit.tableScreenImage = true"
                                @cancel-edit="onCancelEdit"
                                @image-removed="restoreInitValue"
                            />
                        </div>
                    </template>

                    <category-accordion
                        v-model="isOpen.menuNotice"
                        title="Menu notice"
                    />
                    <div
                        v-show="isOpen.menuNotice"
                        class="flex w-full flex-col items-center justify-center gap-4 border-b border-gray-200 px-2 pb-4 pt-2"
                    >
                        <label
                            for="menuNotice"
                            class="text-xs text-gray-700"
                        >
                          Display a message at the bottom of your menu. Typically used to display a calorie or allergen notice, or your service charge policy.
                        </label>
                        <textarea
                            id="menuNotice"
                            v-model.trim="formData.menuNotice"
                            rows="6"
                            class="form-input mt-1 block w-full rounded-md shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            @input="
                                onSettingChange(
                                    'menuNotice',
                                    formData.menuNotice
                                )
                            "
                            @focus="scrollToMenuNotice"
                        />
                    </div>

                    <category-accordion
                        v-model="isOpen.busyMessage"
                        title="Busy Message"
                        @input="toggleBusyMessageModal"
                    />
                    <div
                        v-show="isOpen.busyMessage"
                        class="flex w-full flex-col items-center justify-center gap-4 border-b border-gray-200 px-2 pb-4 pt-2"
                    >
                        <label
                            for="busyMessage"
                            class="text-xs text-gray-700"
                        >
                          Customise the message displayed when your store is closed or snoozed.
                        </label>
                        <textarea
                            id="busyMessage"
                            v-model.trim="formData.busyMessage"
                            rows="6"
                            class="form-input mt-1 block w-full rounded-md shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            :class="{
                                'border-red-300': $v.formData.busyMessage.$error
                            }"
                            @input="
                                onSettingChange(
                                    'busyMessage',
                                    formData.busyMessage
                                )
                            "
                        />
                    </div>
                </div>
                <div
                    class="flex flex-1 items-start justify-center overflow-hidden p-2 px-4"
                >
                    <div
                        class="flex h-full w-full flex-col overflow-hidden rounded-md shadow-md"
                        :class="{ 'rounded-2xl': selectedDevice === 'mobile' }"
                        :style="deviceWidth"
                    >
                        <div
                            class="flex h-12 w-full items-center justify-start border-b border-gray-200 bg-gray-100 px-6"
                            :class="{
                                'bg-gray-100 border-b border-gray-200': selectedDevice !== 'mobile',
                                'border-none': selectedDevice === 'mobile',
                            }"
                            :style="selectedDevice !== 'mobile' ? {} : { backgroundColor: formData.primaryColour }"

                        >
                            <div class="flex w-1/3 flex-none flex-shrink gap-3">
                                <div
                                    v-for="index in 3"
                                    :key="`button-${index}`"
                                    :class="{
                                        'bg-red-500': index === 1,
                                        'bg-yellow-500': index === 2,
                                        'bg-green-500': index === 3
                                    }"
                                    class="h-2.5 w-2.5 rounded-full"
                                />
                            </div>
                            <div
                                class="h-full w-full self-center justify-self-center p-2 sm:w-1/3"
                            >
                                <div
                                    class="flex h-full w-full items-center justify-center gap-1.5 overflow-hidden rounded-md bg-gray-50 px-3 shadow"
                                >
                                    <img
                                        v-if="formData.faviconImage"
                                        :src="`${formData.faviconImage}-/resize/48x48/`"
                                        class="rounded-sm"
                                        alt="FavIcon"
                                    />
                                    <span class="truncate text-sm">
                                        {{ source.text }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <iframe
                            ref="iframe"
                            :src="source.value"
                            scale="0.75"
                            class="flex h-full w-full"
                        />
                    </div>
                </div>
                <div class="w-64 h-full overflow-auto bg-white">
                    <div
                        class="flex w-full flex-col justify-center p-2"
                    >
                        <div class="flex justify-evenly px-1 py-3">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { FeaturesEnum } from '@/enums';
import { mapActions, mapGetters } from 'vuex';
import { isMobile } from 'mobile-device-detect';
import { required } from 'vuelidate/lib/validators';
import FontCategory from '@/components/storeDesigner/FontCategory';
import ImageCategory from '@/components/storeDesigner/ImageCategory';
import DesignerHeader from '@/components/storeDesigner/DesignerHeader';
import ColourCategory from '@/components/storeDesigner/ColourCategory';
import ToggleCategory from '@/components/storeDesigner/ToggleCategory';
import CategoryAccordion from '@/components/storeDesigner/CategoryAccordion';
import MobileDeviceWarning from '@/components/storeDesigner/MobileDeviceWarning';

export default {
    name: 'StoreDesigner',
    metaInfo: {
        title: 'Store Designer'
    },
    props: {
        venueId: {
            type: Number,
            required: true
        },
        venue: {
            type: Object,
            required: true
        }
    },
    components: {
        FontCategory,
        ImageCategory,
        ColourCategory,
        ToggleCategory,
        DesignerHeader,
        CategoryAccordion,
        MobileDeviceWarning
    },
    data() {
        const defaultDevice =
            this.venue.acceptsPat || this.venue.acceptsInStore
                ? 'mobile'
                : 'desktop';

        const defaultValues = {
            colorBgPage: null,
            colorBgButton: null,
            colorBgPrimary: null,
            colorTextPrice: null,
            colorTextMuted: null,
            borderMenuItem: null,
            colorTextAccent: null,
            colorTextButton: null,
            colorBgSecondary: null,
            colorBgBrandPage: null,
            colorTextHeading: null,
            colorTextPrimary: null,
            colorTextItemBody: null,
            colorBgPageDarker: null,
            borderSelectedItem: null,
            colorTextSecondary: null,
            colorBgBrandBanner: null,
            colorTextSubheading: null,
            borderButtonPrimary: null,
            colorHighlightBorder: null,
            borderBottomMenuItem: null,
            colorTextBrandBanner: null,
            colorSecondaryActions: null,

            showTableSelectionModal: false,
            showCurrencySymbol: false,
            orderInstructions: null,
            tableScreenImage: null,
            primaryColour: null,
            faviconImage: null,
            largeImages: false,
            busyMessage: null,
            headerImage: null,
            headingFont: null,
            ageGated: false,
            bodyFont: null,
            logoNav: null,
            logo: null
        };

        const buttonBranding = [
            {
                keyName: 'colorTextButton',
                title: 'Text colour',
                default: '#ffffff'
            },
            {
                keyName: 'colorBgButton',
                title: 'Background colour',
                default: '#292a31'
            },
            {
                keyName: 'borderButtonPrimary',
                title: 'Border button primary',
                default: ''
            }
        ];

        const menuItemBranding = [
            {
                keyName: 'colorBgPage',
                title: 'Background page colour',
                default: '#fafafa'
            },
            {
                keyName: 'colorBgPrimary',
                title: 'Background primary colour',
                default: '#ffffff'
            },
            {
                keyName: 'colorBgSecondary',
                title: 'Background secondary colour',
                default: '#ffffff'
            },
            {
                keyName: 'colorBgBrandPage',
                title: 'Background brand page colour',
                default: '#fafafa'
            },
            {
                keyName: 'colorBgPageDarker',
                title: 'Background brand page darker colour',
                default: '#f4f5f5'
            },
            {
                keyName: 'colorBgBrandBanner',
                title: 'Background brand banner colour',
                default: '#ffffff'
            },
            {
                keyName: 'colorHighlightBorder',
                title: 'Highlight border colour',
                default: '#172d48'
            },
            {
                keyName: 'colorSecondaryActions',
                title: 'Secondary actions colour',
                default: '#292a31'
            },
            {
                keyName: 'borderBottomMenuItem',
                title: 'Border bottom menu item colour',
                default: ''
            },
            {
                keyName: 'borderMenuItem',
                title: 'Border menu item colour',
                default: ''
            },
            {
                keyName: 'borderSelectedItem',
                title: 'Border selected item colour',
                default: '#252f3f'
            }
        ];

        const textBranding = [
            {
                keyName: 'colorTextPrice',
                title: 'Price colour',
                default: '#4b5563'
            },
            {
                keyName: 'colorTextMuted',
                title: 'Muted colour',
                default: '#6b7280'
            },

            {
                keyName: 'colorTextAccent',
                title: 'Accent colour',
                default: '#233876'
            },
            {
                keyName: 'colorTextHeading',
                title: 'Headings colour',
                default: '#161e2e'
            },
            {
                keyName: 'colorTextPrimary',
                title: 'Primary colour',
                default: '#2d3748'
            },
            {
                keyName: 'colorTextItemBody',
                title: 'Item body colour',
                default: '#2d3748'
            },
            {
                keyName: 'colorTextSecondary',
                title: 'Secondary colour',
                default: '#4a5568'
            },
            {
                keyName: 'colorTextSubheading',
                title: 'Subheading colour',
                default: '#252f3f'
            },
            {
                keyName: 'colorTextBrandBanner',
                title: 'Brand banner colour',
                default: '#252f3f'
            }
        ];

        return {
            isLoading: false,
            ctxName: this.venue.slug,
            selectedDevice: defaultDevice,
            initValues: { ...defaultValues },
            formData: { ...defaultValues },
            otherBrandingProps: null,
            modifiedOnly: true,
            menuItemBranding,
            buttonBranding,
            textBranding,
            FeaturesEnum,
            isOpen: {
                images: false,
                tables: false,
                branding: false,
                menuItems: false,
                typography: false,
                menuNotice: false,
                description: false,
                busyMessage: false
            },
            isEdit: {
                tableScreenImage: false,
                faviconImage: false,
                headerImage: false,
                headingFont: false,
                bodyFont: false,
                logoNav: false,
                logo: false
            }
        };
    },
    validations: {
        formData: {
            busyMessage: { required }
        }
    },
    computed: {
        ...mapGetters({
            isFeatureAvailable: 'user/isFeatureAvailable'
        }),
        hasChanged() {
            return (
                JSON.stringify(this.formData) !==
                JSON.stringify(this.initValues)
            );
        },
        publicHostname() {
            return process.env.VUE_APP_WHITELABEL_PUBLIC_HOSTNAME;
        },
        source() {
            const isLocal = this.publicHostname === 'localhost:8081';
            const { slug } = this.venue;

            const base = `http${isLocal ? '' : 's'}://${this.publicHostname}`;

            return {
                base,
                text: `${base}/${slug}/menu`,
                value: `${base}/${slug}/menu?storeDesigner=true`
            };
        },
        deviceWidth() {
            switch (this.selectedDevice) {
                case 'desktop':
                    return 'width: 1366px; min-width: 773px';
                case 'tablet':
                    return 'width: 772px; min-width: 640px';
                case 'mobile':
                    return 'width: 390px; max-height: 980px';
                default:
                    return 'width: 100%';
            }
        },
        hasTableSection() {
            return this.venue.acceptsInStore;
        },
        isMobile() {
            return isMobile;
        },
        brandingKeys() {
            return [
                ...this.textBranding,
                ...this.buttonBranding,
                ...this.menuItemBranding
            ].map(({ keyName }) => keyName);
        }
    },
    mounted() {
        const {
            fonts: { body: bodyFont, heading: headingFont },
            branding: {
                colorBgPage,
                colorBgButton,
                colorBgPrimary,
                colorTextPrice,
                colorTextMuted,
                borderMenuItem,
                colorTextAccent,
                colorTextButton,
                colorBgSecondary,
                colorBgBrandPage,
                colorTextHeading,
                colorTextPrimary,
                colorTextItemBody,
                colorBgPageDarker,
                borderSelectedItem,
                colorTextSecondary,
                colorBgBrandBanner,
                colorTextSubheading,
                borderButtonPrimary,
                colorHighlightBorder,
                borderBottomMenuItem,
                colorTextBrandBanner,
                colorSecondaryActions,
                ...otherBrandingProps
            },
            showTableSelectionModal,
            showCurrencySymbol,
            orderInstructions,
            tableScreenImage,
            primaryColour,
            faviconImage,
            description,
            headerImage,
            largeImages,
            busyMessage,
            menuNotice,
            ageGated,
            logoNav,
            logo
        } = {
            ...this.venue,
            branding: {
                ...this.venue.branding,
                ...this.getBrandingColours(
                    this.brandingKeys,
                    this.venue.branding
                )
            },
            fonts: {
                body: this.venue.fonts?.body ?? null,
                heading: this.venue.fonts?.heading ?? null
            }
        };

        this.otherBrandingProps = otherBrandingProps;
        this.initValues = {
            showTableSelectionModal,
            showCurrencySymbol,
            orderInstructions,
            tableScreenImage,
            primaryColour,
            faviconImage,
            description,
            headerImage,
            largeImages,
            headingFont,
            busyMessage,
            menuNotice,
            bodyFont,
            ageGated,
            logoNav,
            logo,

            colorBgPage,
            colorBgButton,
            colorBgPrimary,
            colorTextPrice,
            colorTextMuted,
            borderMenuItem,
            colorTextAccent,
            colorTextButton,
            colorBgSecondary,
            colorBgBrandPage,
            colorTextHeading,
            colorTextPrimary,
            colorTextItemBody,
            colorBgPageDarker,
            borderSelectedItem,
            colorTextSecondary,
            colorBgBrandBanner,
            colorTextSubheading,
            borderButtonPrimary,
            colorHighlightBorder,
            borderBottomMenuItem,
            colorTextBrandBanner,
            colorSecondaryActions
        };

        this.formData = { ...this.initValues };
    },

    methods: {
        ...mapActions({
            updateVenueSettings: 'venues/update'
        }),
        async copyBranding() {
            const brandingColours = {
                primaryColour: this.formData.primaryColour,
                ...this.getBrandingColours(
                    this.brandingKeys,
                    this.formData,
                    this.modifiedOnly
                )
            };

            try {
                await navigator.clipboard.writeText(
                    JSON.stringify(brandingColours, null, 2)
                );
                this.$notify({
                    group: 'settings',
                    title: 'Copied to clipboard'
                });
            } catch (err) {
                throw new Error('Failed to copy:', err);
            }
        },
        async pasteBranding() {
            try {
                const brandingColours = await navigator.clipboard.readText();

                if (!brandingColours) {
                    this.$notify({
                        group: 'settings',
                        title: 'No branding colours found'
                    });

                    return;
                }

                const parsedColours = JSON.parse(brandingColours);

                this.formData = { ...this.formData, ...parsedColours };

                for (const key in parsedColours) {
                    this.onSettingChange(key, parsedColours[key]);
                }
            } catch (err) {
                throw new Error('Failed to paste:', err);
            }
        },
        async saveVenue() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.isLoading = true;

            try {
                const brandingColoursPayload = this.getBrandingColours(
                    this.brandingKeys,
                    this.formData,
                    true
                );

                const payload = {
                    id: this.venueId,
                    logo: this.formData.logo,
                    logoNav: this.formData.logoNav,
                    ageGated: this.formData.ageGated,
                    menuNotice: this.formData.menuNotice,
                    busyMessage: this.formData.busyMessage,
                    description: this.formData.description,
                    largeImages: this.formData.largeImages,
                    headerImage: this.formData.headerImage,
                    faviconImage: this.formData.faviconImage,
                    primaryColour: this.formData.primaryColour || '',
                    tableScreenImage: this.formData.tableScreenImage,
                    orderInstructions: this.formData.orderInstructions,
                    showCurrencySymbol: this.formData.showCurrencySymbol,
                    showTableSelectionModal: this.formData.showTableSelectionModal,
                    fonts: {
                        ...(this.formData.headingFont
                            ? { heading: this.formData.headingFont }
                            : null),
                        ...(this.formData.bodyFont
                            ? { body: this.formData.bodyFont }
                            : null)
                    },
                    branding: {
                        ...this.otherBrandingProps,
                        ...brandingColoursPayload
                    }
                };

                await this.updateVenueSettings(payload);

                this.$notify({
                    group: 'settings',
                    title: 'Settings saved'
                });

                this.initValues = { ...this.formData };

                Object.keys(this.isEdit).forEach(key => {
                    this.isEdit[key] = false;
                });
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        },

        scrollToMenuNotice() {
            const message = {
                type: 'scroll',
                value: 'menuNotice'
            };

            this.postMessage(message);
        },

        toggleBusyMessageModal(value) {
            this.onSettingChange('enabled', !value);
        },

        toggleSelectionModal() {
            const message = {
                type: 'toggleModal',
                value: 'table-selection-modal'
            };

            this.postMessage(message);
        },

        onSettingChange(key, value) {
            const message = { key, value, type: 'settingChange' };

            this.postMessage(message);
        },

        postMessage(message) {
            this.$refs.iframe.contentWindow.postMessage(
                message,
                this.source.base
            );
        },

        onSuccess(key, event) {
            const fontKeys = ['bodyFont', 'headingFont'];

            if (fontKeys.includes(key)) {
                const parts =
                    event.name.indexOf('.') > 0
                        ? event.name.toLowerCase().split('.')
                        : [];

                this.formData[key] = {
                    extension: parts.pop() || 'undefined',
                    url: event.cdnUrl,
                    name:
                        parts
                            .shift()
                            .trim()
                            .replace(/[^\w\s-]/g, '')
                            .replace(/[\s_-]+/g, '-')
                            .replace(/^-+|-+$/g, '') || 'undefined'
                };
            } else {
                this.formData[key] = event.cdnUrl;
            }

            this.onSettingChange(key, this.formData[key]);
        },

        onCancelEdit(key) {
            this.isEdit[key] = false;

            this.restoreInitValue(key);
        },

        onRemoved(key) {
            if (this.formData[key] !== null) {
                this.formData[key] = null;

                this.isEdit[key] = true;

                this.onSettingChange(key, this.formData[key]);
            }
        },

        restoreInitValue(key) {
            if (this.formData[key] !== this.initValues[key]) {
                this.formData[key] = this.initValues[key];

                this.onSettingChange(key, this.formData[key]);
            }
        },

        getBrandingColours(keys, source, modifiedOnly = false) {
            const brandingColours = {
                ...Object.fromEntries(
                    keys.map(key => [key, source?.[key] ?? null])
                )
            };

            if (modifiedOnly) {
                for (const key in brandingColours) {
                    if (brandingColours[key] === null) {
                        delete brandingColours[key];
                    }
                }
            }

            return brandingColours;
        }
    }
};
</script>
