import moment from 'moment';
import { Parser } from '@json2csv/plainjs';
import { BlobWriter, TextReader, ZipWriter } from '@zip.js/zip.js';

export const exportToCsv = (
    csvData,
    isPlain,
    fields,
    isMultiple,
    title = 'export'
) => {
    try {
        const opts = {
            excelString: !isPlain,
            withBOM: true
        };

        if (!csvData.length) {
            opts.fields = fields;
        }

        const parser = new Parser(opts);
        const csvTitle = `${title}_${moment().format('YYYY-MM-DD_HH-mm')}.csv`;

        if (isMultiple) {
            zipFile(csvData, csvTitle, parser, opts).then(blob =>
                downloadZip(blob, title)
            );

            return;
        }

        const csv = parser.parse(csvData);
        const data = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
        const link = document.createElement('a');

        link.setAttribute('href', data);
        link.setAttribute('download', csvTitle);
        link.click();
    } catch (err) {
        throw new Error(err);
    }
};

function zipFile(files, title, parser, opts) {
    const zipWriter = new ZipWriter(new BlobWriter('application/zip'));

    for (const { name, transactionsPerDay, fields } of files) {
        if (fields && fields.length) {
            const updatedOpts = { ...opts, fields };

            parser = new Parser(updatedOpts);
        }

        const csv = parser.parse(transactionsPerDay);

        zipWriter.add(`${name}_${title}`, new TextReader(csv));
    }

    return zipWriter.close();
}

function downloadZip(blob, title) {
    document.body
        .appendChild(
            Object.assign(document.createElement('a'), {
                download: `${title}.zip`,
                href: URL.createObjectURL(blob)
            })
        )
        .click();
}

export default { exportToCsv };
