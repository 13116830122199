<template>
    <fieldset>
        <legend class="sr-only"> </legend>
        <div class="bg-white rounded-md -space-y-px">
            <div
                v-for="(option, index) in options"
                :key="index"
                class="relative flex p-4 border"
                :class="{
                    'rounded-tl-md rounded-tr-md': index === 0,
                    'rounded-bl-md rounded-br-md': index === options.length - 1,
                    'bg-indigo-50 border-indigo-200 z-10':
                        option.value === value,
                    'border-gray-200': option.value !== value
                }"
            >
                <div class="flex items-center h-5">
                    <input
                        :id="option.value + '-' + index"
                        :name="option.value + '-' + index"
                        :value="option.value"
                        type="radio"
                        class="focus:ring-indigo-500 w-4 h-4 text-indigo-600 border border-gray-300 cursor-pointer"
                        @change="updateValue(option.value)"
                        :checked="option.value === value"
                    />
                </div>
                <label
                    :for="option.value + '-' + index"
                    class="flex flex-col ml-3 cursor-pointer"
                >
                    <span
                        class="block text-sm font-medium"
                        :class="{
                            'text-indigo-900': option.value === value,
                            'text-gray-900': option.value !== value
                        }"
                    >
                        {{ option.label }}
                    </span>
                    <span
                        class="block text-sm"
                        v-if="option.labelDescription"
                        :class="{
                            'text-indigo-700': option.value === value,
                            'text-gray-500': option.value !== value
                        }"
                    >
                        {{ option.labelDescription }}
                    </span>
                </label>
            </div>
        </div>
    </fieldset>
</template>

<script>
export default {
    name: 'LargeRadioButton',
    props: {
        options: {
            type: Array,
            required: true,
            default: () => []
        },
        value: {
            type: [String, Number, Boolean, Object],
            required: true
        }
    },
    computed: {},
    methods: {
        updateValue(value) {
            this.$emit('input', value);
        }
    }
};
</script>
