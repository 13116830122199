<template>
    <div class="group col-span-6 rounded-sm ">
        <router-link :to="{ name: routeName }" class="hover:bg-gray-50 py-3 border-b transition-all duration-300 ease-in-out flex justify-between items-center px-5 ">
            <div class="">
                <h4
                    class="text-sm text-gray-700 font-semibold group-hover:text-indigo-700"
                    >{{ title }}</h4
                >
                <p class="text-sm text-gray-600 group-hover:text-gray-700"
                    >{{ description }}
                </p>
            </div>
          <div>
            <base-arrow-right-icon class="opacity-0 transition-opacity duration-300 ease-in-out group-hover:opacity-100 w-4 h-4" />
          </div>
        </router-link>
    </div>
</template>

<script>
export default {
    name: 'SettingsListItem',
    props: {
        routeName: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        description: {
            type: String,
            required: false,
            default: null
        }
    }
};
</script>

<style scoped></style>
