<template>
    <div>
        <div class="md:flex md:items-center md:justify-between">
            <div class="min-w-0 flex-1">
                <router-link
                    :to="{ name: 'editVenue' }"
                    class="mb-2 flex items-center text-sm text-gray-500 inline-flex"
                >
                    <base-back-icon />
                    <span>Settings</span>
                </router-link>
                <base-page-title title="Orkestro Integration" />
            </div>

            <BaseSpinner :isLoading="isLoading" />
        </div>
        <div>
            <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                            >Details</h3
                        >
                        <p class="mt-2 text-sm text-gray-500">
                            Enter your API Key to connect to Orkestro. When
                            enabled, your delivery orders will be automatically
                            imported into Orkestro and dispatched according to
                            the settings you chose when creating your Orkestro
                            account.
                            <br /><br />If you don't have an Orkestro account
                            yet, you can learn how to
                            <a
                                class="text-indigo-600"
                                href="https://dash.orkestro.com/#/account-creation/sign-up?referrer=STOREKIT"
                                target="_blank"
                                >create one here</a
                            >
                        </p>
                    </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0" v-if="!isLoading">
                    <div class="shadow sm:overflow-hidden sm:rounded-md">
                        <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
                            <div class="grid grid-cols-3 gap-6">
                                <div class="col-span-3 sm:col-span-2">
                                    <base-input-field
                                        label="Orkestro API Key"
                                        id="orkestroApiKey"
                                        type="text"
                                        v-model.trim="orkestro.orkestroApiKey"
                                        :errors="
                                            $v.orkestro.orkestroApiKey.$error
                                        "
                                        placeholder="e.g. AJDWNAUD1231D9021="
                                    />
                                </div>
                                <div class="sm:col-span-6">
                                    <label
                                        for="enabled"
                                        class="block text-sm font-medium leading-5 text-gray-700"
                                        >Integration Status</label
                                    >

                                    <div class="mt-2 flex items-start">
                                        <div>
                                            <toggle-switch
                                                v-model="
                                                    orkestro.orkestroEnabled
                                                "
                                            />
                                        </div>

                                        <p class="pl-4 text-sm text-gray-500"
                                            >{{
                                                orkestro.orkestroEnabled
                                                    ? 'Enabled - sending orders to Orkestro'
                                                    : 'Disabled - not sending orders to Orkestro'
                                            }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <BaseSpacerWithRuler />

        <div class="my-6 ml-4 flex flex-shrink-0 justify-end">
            <span class="inline-flex rounded-md shadow-sm" @click="saveVenue">
                <button
                    type="button"
                    class="focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                    :disabled="isLoading"
                >
                    Save
                </button>
            </span>
        </div>

    </div>
</template>

<script>
// import FooterHelp from '../../helpers/FooterHelp';
import { required } from 'vuelidate/lib/validators';
import ToggleSwitch from '@/components/formComponents/ToggleSwitch';

export default {
    name: 'OrkestroSettings',
    metaInfo: {
        title: 'Orkestro Integration'
    },
    props: ['venueId', 'venue'],
    components: {
        ToggleSwitch
    },
    data() {
        return {
            isLoading: true,
            settings: { ...this.venue },
            orkestro: {
                orkestroApiKey: null,
                orkestroEnabled: false
            }
        };
    },
    validations: {
        orkestro: {
            orkestroApiKey: {
                required(value) {
                    return this.orkestro.orkestroEnabled
                        ? !!value.length
                        : true;
                },
                minLength(value) {
                    return this.orkestro.orkestroEnabled
                        ? value.length >= 10
                        : true;
                }
            },
            orkestroEnabled: {
                required
            }
        }
    },
    async created() {
        this.fetchOrkestroSettings();
    },
    methods: {
        async fetchOrkestroSettings() {
            try {
                this.isLoading = true;

                const { data } = await this.$axios.get(
                    '/venues/' + this.venueId + '/settings/orkestro'
                );

                if (data.orkestro) {
                    this.orkestro.orkestroEnabled =
                        data.orkestro[0].orkestroEnabled;
                    this.orkestro.orkestroApiKey =
                        data.orkestro[0].orkestroApiKey;
                }
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        },
        async saveVenue() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.errors = 'ERROR';
                return false;
            }

            try {
                this.isLoading = true;

                const payload = {
                    orkestroApiKey: this.orkestro.orkestroApiKey,
                    orkestroEnabled: this.orkestro.orkestroEnabled
                };
                // eslint-disable-next-line
                const response = await this.$axios.put(
                    '/venues/' + this.venueId,
                    payload
                );

                this.$notify({
                    group: 'settings',
                    title: 'Settings saved'
                });
            } catch (error) {
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.message &&
                    error.response.data.message.includes('key_invalid')
                ) {
                    this.$modal.show('custom-error-modal', {
                        text:
                            "Sorry, that Orkestro API key doesn't seem to work. Please double check and try again."
                    });
                    this.orkestro.orkestroEnabled = false;
                }

                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        }
    }
};
</script>
