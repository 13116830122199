var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mt-4 md:grid md:gap-6 md:grid-cols-3"},[_vm._m(0),_c('div',{staticClass:"mt-5 md:col-span-2 md:mt-0"},[_c('div',{staticClass:"shadow bg-white sm:rounded-md sm:overflow-hidden"},[_c('div',{staticClass:"flex items-center mt-4 p-8"},[_c('span',{staticClass:"group relative inline-flex flex-shrink-0 items-center justify-center w-10 h-5 focus:outline-none cursor-pointer",attrs:{"role":"checkbox","tabindex":"0","aria-checked":"false"},on:{"click":_vm.toggleAutoAccept}},[_c('span',{staticClass:"absolute mx-auto w-9 h-4 bg-gray-200 rounded-full transition-colors duration-200 ease-in-out",class:{
                                'bg-gray-200': !_vm.isAutoAccept,
                                'bg-indigo-600': _vm.isAutoAccept
                            },attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"absolute left-0 inline-block w-5 h-5 bg-white border group-focus:border-blue-300 border-gray-200 rounded-full shadow group-focus:shadow-outline transform translate-x-0 transition-transform duration-200 ease-in-out",class:{
                                'translate-x-0': !_vm.isAutoAccept,
                                'translate-x-5': _vm.isAutoAccept
                            },attrs:{"aria-hidden":"true"}})]),_c('span',{staticClass:"pl-3 text-gray-700 text-sm font-medium leading-5"},[_vm._v(" Auto Accept ")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md:col-span-1"},[_c('div',{staticClass:"sm:pl-0 sm:pr-5"},[_c('h3',{staticClass:"text-gray-800 text-base font-semibold leading-6"},[_vm._v(" Auto Accept ")]),_c('p',{staticClass:"mt-2 text-gray-500 text-sm"},[_vm._v(" Enable or disable auto accept orders ")])])])
}]

export { render, staticRenderFns }