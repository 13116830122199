<template>
    <div>
        <div class="md:flex md:items-center md:justify-between">
            <div class="min-w-0 flex-1">
                <router-link
                    :to="{ name: 'editVenue' }"
                    class="mb-2 flex items-center text-sm text-gray-500 inline-flex"
                >
                    <base-back-icon />
                    <span>Settings</span>
                </router-link>
                <base-page-title title="EPOS Integration" />
            </div>

            <BaseSpinner :isLoading="isLoading" />
        </div>
        <div>
            <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                            >Details</h3
                        >
                        <p class="mt-2 text-sm text-gray-500">
                            Syncronise your operations by connecting your store
                            to your EPOS system. Sync full product information
                            to populate your menu and dispatch orders directly
                            into your POS.
                        </p>
                    </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0">
                    <div class="shadow sm:overflow-hidden sm:rounded-md">
                        <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
                            <div class="grid grid-cols-3 gap-6">
                                <div class="col-span-3">
                                    <div
                                        v-if="
                                            !settings.deliverectId &&
                                            !settings.posId
                                        "
                                    >
                                        <div class="rounded-md bg-blue-50 p-4">
                                            <div class="flex">
                                                <div class="flex-shrink-0">
                                                    <svg
                                                        class="h-5 w-5 text-blue-400"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 20 20"
                                                        fill="currentColor"
                                                        aria-hidden="true"
                                                    >
                                                        <path
                                                            fill-rule="evenodd"
                                                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                                            clip-rule="evenodd"
                                                        />
                                                    </svg>
                                                </div>
                                                <div
                                                    class="ml-3 flex-1 md:flex md:justify-between"
                                                >
                                                    <h3
                                                        class="text-black-800 text-sm font-medium"
                                                    >
                                                        No EPOS system connected
                                                        yet.
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-6 flex">
                                            <div>
                                                <select
                                                    id="epos-system"
                                                    name="epos-system"
                                                    v-model="selectedSystem"
                                                    class="focus:outline-none block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                >
                                                    <option
                                                        v-for="(
                                                            system, index
                                                        ) in systems"
                                                        :key="index"
                                                        :value="system.name"
                                                    >
                                                        {{ system.name }}
                                                    </option>
                                                </select>
                                            </div>
                                            <button
                                                type="button"
                                                class="focus:outline-none ml-4 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                @click="openIntercom"
                                            >
                                                Integrate your EPOS
                                            </button>
                                        </div>
                                    </div>
                                    <div
                                        class="rounded-md bg-green-50 p-4"
                                        v-else
                                    >
                                        <div class="flex">
                                            <div class="flex-shrink-0">
                                                <base-tick-icon
                                                    class="text-green-400"
                                                />
                                            </div>
                                            <div class="ml-3">
                                                <h3
                                                    class="text-sm font-medium text-green-800"
                                                >
                                                    Connected to Deliverect
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pt-4">
                        <h4 class="font-medium">Integrated systems:</h4>
                    </div>
                    <div class="mt-6 grid grid-cols-2 gap-4 sm:grid-cols-3">
                        <div
                            v-for="system in systems"
                            :key="system.img"
                            class="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-4 py-3 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
                        >
                            <div class="flex-shrink-0">
                                <img class="w-12" :src="system.img" alt="" />
                            </div>
                            <div class="min-w-0 flex-1">
                                <div class="focus:outline-none">
                                    <span
                                        class="absolute inset-0"
                                        aria-hidden="true"
                                    ></span>
                                    <p
                                        class="text-sm font-medium text-gray-900"
                                    >
                                        {{ system.name }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <BaseSpacerWithRuler />

        <!--        <div class="ml-4 my-6 flex-shrink-0 flex justify-end">-->
        <!--            <span class="inline-flex rounded-md shadow-sm" @click="saveVenue">-->
        <!--                <button-->
        <!--                    type="button"-->
        <!--                    class="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700"-->
        <!--                    :disabled="isLoading"-->
        <!--                >-->
        <!--                    Save-->
        <!--                </button>-->
        <!--            </span>-->
        <!--        </div>-->

        <!--        <FooterHelp label="notices" link="https://storekit.com" />-->
    </div>
</template>

<script>
// import FooterHelp from '../../helpers/FooterHelp';
import { minLength, required } from 'vuelidate/lib/validators';

export default {
    name: 'DeliverectSettings',
    components: {},

    metaInfo: {
        title: 'EPOS Integrations'
    },
    props: ['venueId', 'venue'],
    data() {
        return {
            isLoading: false,
            settings: { ...this.venue },
            selectedSystem: 'NCR Aloha',
            systems: [
                {
                    name: 'NCR Aloha',
                    img:
                        'https://www.deliverect.com/hubfs/Deliverect_New/Integrations%20Page/NCR-Aloha-Logo-800x800-01.svg'
                },
                {
                    name: 'Presto Express',
                    img:
                        'https://www.deliverect.com/hubfs/Deliverect_New/Integrations%20Page/Presto%20Express.svg'
                },
                {
                    name: 'Square',
                    img:
                        'https://www.deliverect.com/hubfs/Delivery_Square%20POS%20logo.svg'
                },
                {
                    name: 'Lightspeed',
                    img:
                        'https://ucarecdn.com/8ab028ea-619e-4a38-8f3a-f490c7224967/-/format/auto/'
                },
                {
                    name: 'Micros Simphony',
                    img:
                        'https://www.deliverect.com/hubfs/Deliverect_New/Integrations%20Page/Micros%20Simphony-02.svg'
                },
                {
                    name: 'TCPOS',
                    img: 'https://www.deliverect.com/hubfs/pos_TCPOS.svg'
                },
                {
                    name: 'ICR Touch',
                    img:
                        'https://www.deliverect.com/hubfs/Deliverect_New/Integrations%20Page/ICR%20Touch-01.svg'
                },
                {
                    name: 'Trivec',
                    img:
                        'https://www.deliverect.com/hubfs/Delivery_Trivec%20POS%20logo.svg'
                },
                {
                    name: 'TouchBistro',
                    img:
                        'https://ucarecdn.com/0da74e3d-5463-49d1-86d5-ffa463be1c24/-/format/auto/'
                },
                {
                    name: 'IKentoo',
                    img:
                        'https://ucarecdn.com/9257423e-921b-4447-a32a-01dc3dfaa619/-/format/auto/'
                },
                {
                    name: 'unTill',
                    img:
                        'https://www.deliverect.com/hubfs/Deliverect_New/Integrations%20Page/Untill%20logo.svg'
                },
                {
                    name: 'Cluster',
                    img:
                        'https://www.deliverect.com/hubfs/Deliverect_New/Integrations%20Page/Cluster-.svg'
                },
                {
                    name: 'Tevalis',
                    img:
                        'https://www.deliverect.com/hubfs/Deliverect_New/Integrations%20Page/Tevalis-01.svg'
                },
                {
                    name: "Maitre'D",
                    img: 'https://www.deliverect.com/hubfs/MaitreD%20.svg'
                },
                {
                    name: 'Tiller',
                    img:
                        'https://www.deliverect.com/hubfs/Deliverect_New/Integrations%20Page/Tiller-Logo-07.svg'
                },
                {
                    name: 'Revo',
                    img:
                        'https://www.deliverect.com/hubfs/Delivery_Revo%20XEF%20POS%20logo.svg'
                },
                {
                    name: 'Countr',
                    img:
                        'https://www.deliverect.com/hubfs/Deliverect_New/Integrations%20Page/Countr_new-01.svg'
                },
                {
                    name: "L'Addition",
                    img:
                        'https://www.deliverect.com/hs-fs/hubfs/laddition.png?width=120&height=120&name=laddition.png'
                },
                {
                    name: 'MyOrderBox',
                    img:
                        'https://www.deliverect.com/hs-fs/hubfs/Deliverect_New/Integrations%20Page/MyOrderBox.png?width=120&height=120&name=MyOrderBox.png'
                },
                {
                    name: 'Kounta',
                    img:
                        'https://ucarecdn.com/b6c691e0-a907-44ed-8baa-d09d45fec76b/-/format/auto/'
                },
                {
                    name: 'Revel',
                    img:
                        'https://ucarecdn.com/b54189ff-3bc7-4986-bd79-1ffe3b7c036a/-/format/auto/'
                },
                {
                    name: 'Guestline',
                    img: 'https://www.deliverect.com/hubfs/Guestline-Logo.svg'
                },
                {
                    name: 'Gastrofix',
                    img:
                        'https://www.deliverect.com/hubfs/Delivery_Gastrofix%20POS%20logo.svg'
                },
                {
                    name: 'CLYO Systems',
                    img:
                        'https://www.deliverect.com/hubfs/Delivery_Clyosystems%20POS%20logo%20copy.svg'
                },
                {
                    name: 'Storyous',
                    img:
                        'https://www.deliverect.com/hubfs/Delivery_Storyous%20POS%20logo.svg'
                },
                {
                    name: 'Hypersoft',
                    img:
                        'https://www.deliverect.com/hubfs/Deliverect_New/Integrations%20Page/Hypersoft%20POS%20system.svg'
                },
                {
                    name: 'Micros Res 3700',
                    img:
                        'https://www.deliverect.com/hubfs/Deliverect_New/Integrations%20Page/MICROS_3700_logo-01.svg'
                },
                {
                    name: 'POSist',
                    img: 'https://www.deliverect.com/hubfs/POSist-01.svg'
                },
                {
                    name: 'Izettle',
                    img:
                        'https://ucarecdn.com/2d2439a7-0572-4986-acba-a838628796fa/-/format/auto/'
                },
                {
                    name: 'Tissl',
                    img:
                        'https://ucarecdn.com/50481204-ecd7-4f3c-859b-4fd89e8457c6/-/format/auto/'
                },
                {
                    name: 'Omega POS',
                    img:
                        'https://ucarecdn.com/96b18c1c-5b51-4565-a657-534963091911/-/format/auto/'
                },
                {
                    name: 'Miss Tipsi',
                    img:
                        'https://ucarecdn.com/ab18f4b9-3339-4061-b9c6-a398837b9f8e/-/format/auto/'
                },
                {
                    name: 'Kobas',
                    img:
                        'https://ucarecdn.com/43d41db6-0c0e-484d-8a4c-f3996de4d94a/-/format/auto/'
                },
                {
                    name: 'CompuCash',
                    img: 'https://www.deliverect.com/hubfs/CC_s_RGB.svg'
                },
                {
                    name: 'Carré POS',
                    img:
                        'https://ucarecdn.com/7bbdfde1-98da-4ccb-bd96-b4e4e35d4cb5/-/format/auto/'
                },
                {
                    name: 'Zonal',
                    img:
                        'https://www.deliverect.com/hubfs/zonallogo%20coming%20soon-01.svg'
                },
                {
                    name: 'Menlog',
                    img:
                        'https://ucarecdn.com/6188ab99-8093-4412-900f-a07b8c2cdf7e/-/format/auto/'
                },
                {
                    name: 'Cashpad',
                    img:
                        'https://ucarecdn.com/7b76e8f6-6f49-4a88-b2f2-b44a0bd7a955/-/format/auto/'
                },
                {
                    name: 'Symbioz',
                    img:
                        'https://ucarecdn.com/6fb3d8aa-cbc9-4885-a152-63d99ebfaa7d/-/format/auto/'
                }
            ]
        };
    },
    validations: {
        settings: {
            facebookPixelId: {
                required,
                minLength: minLength(10)
            }
        }
    },
    methods: {
        async saveVenue() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.errors = 'ERROR';
                return false;
            }

            try {
                this.isLoading = true;

                const payload = {
                    facebookPixelId: this.settings.facebookPixelId
                };
                // eslint-disable-next-line
                const response = await this.$axios.put(
                    '/venues/' + this.venueId,
                    payload
                );

                this.$notify({
                    group: 'settings',
                    title: 'Settings saved'
                });
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        },
        openIntercom() {
            if (window.Intercom) {
                window.Intercom(
                    'showNewMessage',
                    `Hi! I'd like to integrate my store ${this.settings.name} (https://order.storekit.com/${this.venue.slug}) with my EPOS system: ${this.selectedSystem}`
                );
            } else {
                window.alert(
                    'Please disable your AdBlocker to use live chat support.'
                );
            }
            if (window.analytics) {
                window.analytics.track('POS Integration Requested', {
                    venueId: this.settings.id,
                    accountId: this.venue.accountId,
                    context: {
                        groupId: this.venue.accountId
                    }
                });
            }
        }
    }
};
</script>
