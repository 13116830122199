<template>
    <div
        class="relative z-50 inline-block pb-8 text-left sm:float-right sm:pb-0"
    >
        <div>
                <button
                    @click="showDropdown = !showDropdown"
                    type="button"
                    class="inline-flex justify-center px-4 py-2 w-full rounded-md shadow-sm hover:text-gray-500 text-gray-700 active:text-gray-800 text-sm leading-5 active:bg-gray-50 bg-white border focus:border-blue-300 border-gray-300 focus:outline-none focus:shadow-outline-blue transition duration-150 ease-in-out"
                >
                    <span
                        class="pl-1 truncate font-medium capitalize"
                        v-if="value"
                        >{{ value }}</span
                    ><span class="pl-2" v-else> None Selected</span>
                    <svg
                        class="-mr-1 ml-2 w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                    >
                        <path
                            fill-rule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clip-rule="evenodd"
                        />
                    </svg>
                </button>
        </div>
        <transition
            enter-active-class="transition ease-out duration-100"
            enter-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
        >
            <div
                v-if="showDropdown"
                class="absolute right-0 mt-2 w-56 rounded-md shadow-lg origin-top-right"
            >
                <div
                    class="max-h-72 bg-white rounded-md shadow-xs cursor-pointer overflow-scroll"
                >
                    <div class="py-1">
                        <div v-for="(option, index) in options" :key="index">
                            <span
                                @click="
                                    updateSelection(option);
                                    showDropdown = false;
                                "
                                class="group flex items-center px-4 py-2 text-gray-700 hover:text-gray-900 focus:text-gray-900 truncate text-sm leading-5 hover:bg-gray-100 focus:bg-gray-100 focus:outline-none capitalize"
                            >
                                {{ option }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'ReportIntervalSelection',
    data() {
        return {
            showDropdown: false,
            options: ['day', 'week', 'month']
        };
    },
    props: {
        multipleSelect: {
            type: Boolean,
            required: false,
            default: false
        },
        value: {
            type: String
        }
    },
    methods: {
        updateSelection(value) {
            this.$emit('input', value);
        }
    }
};
</script>
