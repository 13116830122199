<template>
    <div class="mt-6 pb-4 text-left">
        <span class="-mt-1 block text-sm font-medium leading-5 text-gray-700"
            >Tell your story better with product videos
        </span>
        <div class="mt-3 rounded-md bg-blue-50 p-3">
            <div class="flex">
                <div class="flex-shrink-0">
                    <!-- Heroicon name: information-circle -->
                    <svg
                        class="h-5 w-5 text-blue-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                    >
                        <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                            clip-rule="evenodd"
                        />
                    </svg>
                </div>
                <div class="ml-3 flex-1 md:flex md:justify-between">
                    <p class="text-xs text-blue-700">
                        You can upload almost any video file and we'll optimise
                        it for all of the possible devices it could be viewed
                        on, but we do recommend keeping to a time limit of
                        around 30 seconds.
                    </p>
                </div>
            </div>
        </div>

        <div class="mt-5">
            <div class="flex flex-row items-center">
                <label
                    for="video"
                    class="block text-sm font-medium leading-5 text-gray-700"
                >
                    Video
                    <span class="text-gray-400">(optional)</span>
                </label>
                <span
                    @click="removeVideo"
                    class="transition-duration-100 cursor-pointer pl-6 text-red-400 transition hover:text-red-700"
                    v-if="product.video"
                >
                    <svg
                        class="h-5 w-5"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                        ></path>
                    </svg>
                </span>
            </div>
            <div class="w-full">
                <div>
                    <uploadcare-inline
                        :ctxName="`${ctxName}product_settings_video`"
                        inputAcceptTypes="video/*"
                        tabs="local, instagram, dropbox, url, gdrive"
                        @success="onImageSuccess"
                    >
                        <div
                            :class="{
                                'p-6': !product.video,
                                'p-3': product.video
                            }"
                            class="mt-2 flex justify-center rounded-md border-2 border-dashed border-gray-300"
                        >
                            <div class="text-center" v-if="!product.video">
                                <p class="mt-1 text-xs text-gray-500">
                                    Video files up to 100MB
                                </p>
                            </div>
                            <div v-else-if="!isVideoReady">
                                <div class="h-4 w-4 text-gray-900 spinner">
                                    <span></span>
                                </div>
                            </div>
                            <div v-else class="w-auto">
                                <div
                                    class="relative h-36 w-36 max-w-md rounded-sm bg-cover bg-center"
                                    :style="`background-image: url(https://image.mux.com/${product.video}/thumbnail.jpg?time=0);`"
                                >
                                </div>
                            </div>
                        </div>
                    </uploadcare-inline>
                </div>
            </div>
            <div
                class="mt-5 justify-between sm:mt-6 sm:flex sm:w-auto"
                :class="{ spinner: isLoading }"
            >
                <span class="flex rounded-md shadow-sm">
                    <button
                        @click="update"
                        :disabled="isLoading"
                        type="button"
                        class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo sm:text-sm sm:leading-5"
                    >
                        Save changes
                    </button>
                </span>
                <span
                    class="mt-3 flex w-full rounded-md shadow-sm sm:ml-3 sm:mt-0 sm:w-auto"
                >
                    <button
                        @click="close"
                        type="button"
                        class="focus:outline-none inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium leading-6 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline sm:text-sm sm:leading-5"
                    >
                        Close
                    </button>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import UploadcareInline from '@/components/widgets/UploadcareInline';

export default {
    name: 'ProductVideoSettings',
    components: { UploadcareInline },
    props: {
        product: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isLoading: false,
            isVideoReady: true,
            ctxName: this.product.id
        };
    },
    methods: {
        close() {
            this.$emit('close');
        },
        update() {
            this.$emit('update');
        },
        updateCategories() {
            this.$emit('update-categories');
        },
        onImageSuccess(event) {
            const regexp = /^(video)\//gi;

            if (regexp.test(event.mimeType)) {
                this.createMuxAsset(event.cdnUrl, event.name);
            } else {
                this.product.image = event.cdnUrl;
            }
        },
        onImageError(event) {
            throw new Error('Error: ', event);
        },
        videoProgress(uploadInfo) {
            if (uploadInfo.state === 'uploading') {
                this.isVideoReady = false;
            }

            if (uploadInfo.state === 'ready') {
                this.isVideoReady = true;
            }
        },
        async createMuxAsset(cdnUrl, name) {
            const url = cdnUrl + name;

            try {
                const { data } = await this.$axios.post('/videos', {
                    url
                });

                this.product.video = data.playbackId;
                this.product.muxAssetId = data.id;
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        },
        removeVideo() {
            this.product.video = '';
            this.product.muxAssetId = null;
        }
    }
};
</script>
