var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative flex items-center",class:{
        'opacity-50': _vm.disabled
    }},[_c('div',{staticClass:"absolute flex h-5 items-center"},[_c('input',_vm._b({staticClass:"form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out",class:{
                'cursor-not-allowed': _vm.disabled,
                'border-red-300 ring-2 ring-red-200 ring-offset-2': _vm.error
            },attrs:{"type":"checkbox"},on:{"change":_vm.update}},'input',{
                id: _vm.id,
                checked: _vm.checked,
                value: _vm.value,
                disabled: _vm.disabled
            },false))]),_c('div',{staticClass:"pl-6 text-sm leading-5"},[_c('label',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.tooltip),expression:"tooltip"}],class:{
                'cursor-not-allowed ': _vm.disabled
            },attrs:{"for":_vm.id}},[_vm._t("label",function(){return [(_vm.label)?_c('p',{staticClass:"flex h-5 items-center font-medium text-gray-700"},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.tooltip)?_c('base-question-icon',{staticClass:"ml-1 inline"}):_vm._e()],1):_vm._e()]}),_vm._t("caption",function(){return [(_vm.caption)?_c('p',{staticClass:"text-gray-500"},[_vm._v(" "+_vm._s(_vm.caption)+" ")]):_vm._e()]})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }