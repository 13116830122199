<template>
    <modal
        name="duplicate-store-modal"
        width="600px"
        height="600px"
        :clickToClose="true"
        :adaptive="true"
    >
        <div
            class="transform rounded-lg bg-white px-4 pb-4 pt-5 transition-all sm:w-full sm:p-6"
        >
            <div class="pb-16">
                <div class="mt-3 text-left sm:mt-5">
                    <h3
                        class="text-heading font-heading text-lg font-medium leading-6 text-gray-900"
                    >
                        Duplicate a store
                    </h3>
                    <div class="mt-3">
                        <base-input-field
                            label="Name"
                            id="name"
                            v-model="name"
                            :errors="$v.name.$error"
                            placeholder="store name"
                        />
                    </div>
                    <div class="mt-3">
                        <span class="overflow-visible rounded-sm shadow-sm">
                            <venue-selector
                                v-if="venues && venues.length"
                                :venues="venues"
                                :multiple-select="false"
                                :allow-empty="false"
                                @selected="setVenue"
                            />
                        </span>
                    </div>
                    <div class="mt-3">
                        <label
                            class="block text-sm font-medium leading-5 text-gray-700"
                        >
                            Duplicate tables
                        </label>
                        <base-small-toggle-switch v-model="copyTables" />
                    </div>
                    <div class="mt-3">
                        <label
                            for="sku"
                            class="block text-sm font-medium leading-5 text-gray-700"
                        >
                            Duplicate store notices
                        </label>
                        <base-small-toggle-switch v-model="copyNotices" />
                    </div>
                </div>
            </div>
            <div
                class="mt-5 sm:mt-6 sm:flex sm:w-auto"
                :class="{ spinner: isLoading }"
            >
                <span class="flex w-full rounded-md shadow-sm sm:w-56">
                    <button
                        @click="duplicateStore()"
                        :disabled="isLoading"
                        type="button"
                        class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo sm:text-sm sm:leading-5"
                    >
                        Duplicate store
                    </button>
                </span>
                <span
                    class="mt-3 flex w-full rounded-md shadow-sm sm:ml-3 sm:mt-0 sm:w-auto"
                >
                    <button
                        @click="$modal.hide('duplicate-store-modal')"
                        type="button"
                        class="focus:outline-none inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium leading-6 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline sm:text-sm sm:leading-5"
                    >
                        Close
                    </button>
                </span>
            </div>
        </div>
    </modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import VenueSelector from '@/components/selectors/VenueSelector';

export default {
    name: 'DuplicateStoreModal',
    components: {
        VenueSelector
    },
    props: {
        venues: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    validations: {
        name: {
            required
        },
        venueId: {
            required
        },
        copyTables: {
            required
        },
        copyNotices: {
            required
        }
    },
    data() {
        return {
            name: null,
            isLoading: false,
            venueId: null,
            copyTables: true,
            copyNotices: true
        };
    },
    methods: {
        setVenue(venueId) {
            this.venueId = venueId;
        },
        async duplicateStore() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return false;
            }
            this.isLoading = true;

            try {
                await this.$axios.post(`/venues/${this.venueId}/duplicate`, {
                    name: this.name,
                    copyTables: this.copyTables,
                    copyAreas: this.copyTables,
                    copyNotices: this.copyNotices
                });

                this.$emit('venue-duplicated');
                this.$modal.hide('duplicate-store-modal');
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        }
    }
};
</script>

<style scoped>
.spinner:after {
    left: auto;
    right: 1em;
    border-right-color: indigo;
}
</style>
