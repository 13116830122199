export default class VenueSnoozeReasonEnum {
    static get CLOSING_EARLY() {
        return 'CLOSING_EARLY';
    }

    static get BETWEEN_SERVICE() {
        return 'BETWEEN_SERVICE';
    }

    static get KITCHEN_TOO_BUSY() {
        return 'KITCHEN_TOO_BUSY';
    }

    static get BAR_TOO_BUSY() {
        return 'BAR_TOO_BUSY';
    }

    static get TECH_ISSUE() {
        return 'TECH_ISSUE';
    }

    static get WAIT_TIME() {
        return 'WAIT_TIME';
    }

    static get OTHER() {
        return 'OTHER';
    }

    static get options() {
        return {
            CLOSING_EARLY: this.CLOSING_EARLY,
            BETWEEN_SERVICE: this.BETWEEN_SERVICE,
            KITCHEN_TOO_BUSY: this.KITCHEN_TOO_BUSY,
            BAR_TOO_BUSY: this.BAR_TOO_BUSY,
            TECH_ISSUE: this.TECH_ISSUE,
            WAIT_TIME: this.WAIT_TIME,
            OTHER: this.OTHER
        };
    }

    static get dictionary() {
        return {
            CLOSING_EARLY: 'Closing venue early',
            BETWEEN_SERVICE: 'Between service periods',
            KITCHEN_TOO_BUSY: 'Kitchen too busy',
            BAR_TOO_BUSY: 'Bar too busy',
            TECH_ISSUE: 'Tech issue',
            OTHER: 'Other'
        };
    }
}
