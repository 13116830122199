<template>
    <div>
        <div class="md:flex md:items-center md:justify-between">
            <div class="min-w-0 flex-1">
                <router-link
                    :to="{ name: 'editVenue' }"
                    class="mb-2 flex items-center text-sm text-gray-500 inline-flex"
                >
                    <base-back-icon />
                    <span>Settings</span>
                </router-link>
                <base-page-title title="Toggle Integration" />
            </div>

            <BaseSpinner :isLoading="isLoading" />
        </div>
        <div>
            <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                            >Details</h3
                        >
                        <p class="mt-2 text-sm text-gray-500">
                            Enter your Toggle API credentials and enable the
                            gift card payments for your store..
                            <br /><br />You'll need a Toggle account,
                            <a
                                class="text-indigo-600"
                                href="https://www.usetoggle.com/"
                                rel="noopener"
                                target="_blank"
                                >create one here</a
                            >.
                        </p>
                    </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0" v-if="!isLoading">
                    <div class="shadow sm:overflow-hidden sm:rounded-md">
                        <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
                            <div class="grid grid-cols-3 gap-6">
                                <div class="sm:col-span-6">
                                    <base-input-field
                                        disabled
                                        type="url"
                                        prefix="https://"
                                        label="Toggle
                                        API URL"
                                        tooltip="The static IP address your Toggle server is available to connect to."
                                        id="toggle-url"
                                        placeholder="api.mytoggle.io/graphql"
                                    >
                                    </base-input-field>
                                </div>

                                <div class="sm:col-span-6">
                                    <label
                                        for="toggleUsername"
                                        class="block text-sm font-medium leading-5 text-gray-700"
                                        >Toggle username</label
                                    >
                                    <input
                                        v-model="toggle.username"
                                        id="toggleUsername"
                                        name="toggleUsername"
                                        type="text"
                                        placeholder="username"
                                        class="focus:outline-none form-input mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                                    />
                                </div>

                                <div class="sm:col-span-6">
                                    <BasePasswordInput
                                        v-model="toggle.password"
                                        autocomplete="new-password"
                                    />
                                </div>

                                <div class="mt-6">
                                    <label
                                        for="accountId"
                                        class="block text-sm font-medium leading-5 text-gray-700"
                                        >Toggle account ID</label
                                    >
                                    <input
                                        v-model="toggle.accountId"
                                        id="accountId"
                                        name="accountId"
                                        type="text"
                                        placeholder="accountId"
                                        class="focus:outline-none form-input mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                                    />
                                </div>

                                <div class="sm:col-span-6">
                                    <label
                                        for="enabled"
                                        class="block text-sm font-medium leading-5 text-gray-700"
                                    >
                                        Integration Status
                                    </label>

                                    <div class="mt-2 flex items-start">
                                        <div>
                                            <span
                                                role="checkbox"
                                                tabindex="0"
                                                :class="{
                                                    'bg-gray-200': !toggle.enabled,
                                                    'bg-indigo-600':
                                                        toggle.enabled
                                                }"
                                                @click="
                                                    toggle.enabled = !toggle.enabled
                                                "
                                                @keydown.space.prevent="
                                                    toggle.enabled = !toggle.enabled
                                                "
                                                :aria-checked="toggle.enabled"
                                                class="focus:outline-none relative block h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:shadow-outline"
                                            >
                                                <span
                                                    aria-hidden="true"
                                                    :class="{
                                                        'translate-x-5':
                                                            toggle.enabled,
                                                        'translate-x-0': !toggle.enabled
                                                    }"
                                                    class="relative inline-block h-5 w-5 transform rounded-full bg-white shadow transition duration-200 ease-in-out"
                                                >
                                                    <span
                                                        :class="{
                                                            'opacity-0 duration-100 ease-out':
                                                                toggle.enabled,
                                                            'opacity-100 duration-200 ease-in': !toggle.enabled
                                                        }"
                                                        class="absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
                                                    >
                                                        <svg
                                                            class="h-3 w-3 text-gray-400"
                                                            fill="none"
                                                            viewBox="0 0 12 12"
                                                        >
                                                            <path
                                                                d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                                                stroke="currentColor"
                                                                stroke-width="2"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                            />
                                                        </svg>
                                                    </span>
                                                    <span
                                                        :class="{
                                                            'opacity-100 duration-200 ease-in':
                                                                toggle.enabled,
                                                            'opacity-0 duration-100 ease-out': !toggle.enabled
                                                        }"
                                                        class="absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
                                                    >
                                                        <svg
                                                            class="h-3 w-3 text-indigo-600"
                                                            fill="currentColor"
                                                            viewBox="0 0 12 12"
                                                        >
                                                            <path
                                                                d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"
                                                            />
                                                        </svg>
                                                    </span>
                                                </span>
                                            </span>
                                        </div>

                                        <p class="pl-4 text-sm text-gray-500"
                                            >{{
                                                toggle.enabled
                                                    ? 'Enabled - sending orders to Toggle'
                                                    : 'Disabled - not sending orders to Toggle'
                                            }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <BaseSpacerWithRuler />

        <div class="my-6 ml-4 flex flex-shrink-0 justify-end">
            <span class="inline-flex rounded-md shadow-sm" @click="saveVenue">
                <button
                    type="button"
                    class="focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                    :disabled="isLoading"
                >
                    Save
                </button>
            </span>
        </div>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
    name: 'ToggleSettings',
    metaInfo: {
        title: 'Toggle Integration'
    },
    props: {
        venueId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            isLoading: false,
            toggle: {
                enabled: false,
                username: null,
                password: null,
                accountId: null
            }
        };
    },
    validations: {
        toggle: {
            enabled: {
                required
            },
            username: {
                required
            },
            password: {
                required
            },
            accountId: {
                required
            }
        }
    },
    async created() {
        this.fetchToggleSettings();
    },
    methods: {
        async fetchToggleSettings() {
            try {
                this.isLoading = true;

                const { data } = await this.$axios.get(
                    '/venues/' + this.venueId + '/settings/toggle'
                );

                if (data[0].toggle) {
                    this.toggle = data[0].toggle;
                }
            } catch (e) {
                throw new Error(`API ${e} `);
            } finally {
                this.isLoading = false;
            }
        },
        async saveVenue() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                this.errors = 'ERROR';
                return false;
            }

            try {
                this.isLoading = true;

                const payload = {
                    enabled: this.toggle.enabled,
                    username: this.toggle.username,
                    password: this.toggle.password,
                    accountId: this.toggle.accountId
                };

                await this.$axios.put(
                    '/venues/' + this.venueId + '/settings/toggle',
                    payload
                );

                this.$notify({
                    group: 'settings',
                    title: 'Settings saved'
                });
            } catch (error) {
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.message
                ) {
                    this.$modal.show('custom-error-modal', {
                        text:
                            'Sorry, there was an error while updating the Toggle integration, use the live chat button for help.'
                    });
                    this.toggle.enabled = false;
                }

                throw new Error(`API ${error} `);
            } finally {
                this.isLoading = false;
            }
        }
    }
};
</script>
