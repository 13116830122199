const state = {
    dateRange: null
};

const getters = {
    getDateRange(state) {
        return state.dateRange;
    }
};

const mutations = {
    SET_DATE_RANGE(state, value) {
        state.dateRange = value;
    }
};

const actions = {
    setDateRange({ commit }, value) {
        commit('SET_DATE_RANGE', value);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
