<template>
    <div class="mx-auto max-w-2xl px-6 sm:px-12">
        <div class="md:flex md:items-center md:justify-between">
            <div class="min-w-0 flex-1">
                <router-link
                    :to="{ name: 'paymentSettings' }"
                    class="mb-2 flex items-center text-sm text-gray-500 inline-flex"
                >
                    <base-back-icon />
                    <span>Payment settings</span>
                </router-link>
                <base-page-title title="storekit payments" />
            </div>

            <BaseSpinner :isLoading="isLoading" />
        </div>
        <div>
            <div class="mt-4">
                <div class="mt-5 md:mt-0" v-if="!isLoading">
                    <div class="shadow sm:overflow-hidden sm:rounded-md">
                        <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
                            <div class="">
                                <label
                                    for="businessType"
                                    class="block text-sm font-medium text-gray-700"
                                    >storekit payments account</label
                                >
                                <select
                                    id="businessType"
                                    name="businessType"
                                    v-model="selectedAccount"
                                    class="focus:outline-none form-input mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                >
                                    <option value="" disabled hidden
                                        >Select an account</option
                                    >
                                    <option
                                        v-for="account in accounts"
                                        :key="account.id"
                                        :value="account.accountHolderCode"
                                    >
                                        {{ account.legalBusinessName }} ({{
                                            account.accountHolderCode
                                        }})
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="my-6 ml-4 flex flex-shrink-0 justify-end">
            <span
                class="inline-flex rounded-md shadow-sm"
                @click="connectAdyenAccountHolder"
            >
                <button
                    type="button"
                    class="focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                    :disabled="isLoading || !selectedAccount"
                >
                    Connect account
                </button>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ManageAdyenAccount',
    metaInfo: {
        title: 'storekit payments account'
    },
    data() {
        return {
            isLoading: false,
            selectedAccount: null,
            accounts: []
        };
    },
    created() {
        this.fetchAdyenAccounts();
    },
    methods: {
        async fetchAdyenAccounts() {
            try {
                const { data } = await this.$axios.get(
                    '/adyen/account-holders'
                );
                this.accounts = data;
            } catch (e) {
                throw new Error(e);
            } finally {
                this.isLoading = false;
            }
        },
        async connectAdyenAccountHolder() {
            if (!this.selectedAccount) {
                return false;
            }

            try {
                this.isLoading = true;

                const payload = {
                    accountHolderCode: this.selectedAccount.accountHolderCode,
                    venueId: this.venueId
                };

                const { data } = await this.$axios.post(
                    '/adyen/account/',
                    payload
                );

                if (data.id) {
                    this.$notify({
                        group: 'settings',
                        title: 'Connected successfully!'
                    });
                    setTimeout(
                        () =>
                            this.$router.push({
                                name: 'paymentSettings'
                            }),
                        3000
                    );
                }
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        }
    }
};
</script>
