<template>
  <div>

  </div>
</template>

<script>
export default {
  name: 'BaseFullScreenModal',
  props: {
  }
}
</script>