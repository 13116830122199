<template>
    <div>
        <div class="mt-6" v-if="venue.acceptsInStore">
            <div>
                <div class="block">
                    <div class="overflow-x-scroll border-b border-gray-200">
                        <nav class="-mb-px flex">
                            <span
                                @click="tab = 0"
                                :class="{
                                    'border-indigo-500 text-indigo-600 focus:border-indigo-700 focus:text-indigo-800':
                                        tab === 0
                                }"
                                class="focus:outline-none cursor-pointer whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-sm font-medium leading-5 text-gray-500 hover:border-gray-300 hover:text-gray-700 focus:border-gray-300 focus:text-gray-700"
                            >
                                {{ venue.tableDescriptor }} Plan
                            </span>
                            <span
                                @click="tab = 1"
                                :class="{
                                    'border-indigo-500 text-indigo-600 focus:border-indigo-700 focus:text-indigo-800':
                                        tab === 1
                                }"
                                class="focus:outline-none ml-8 cursor-pointer whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-sm font-medium leading-5 text-gray-500 hover:border-gray-300 hover:text-gray-700 focus:border-gray-300 focus:text-gray-700"
                            >
                                Settings
                            </span>
                        </nav>
                    </div>
                </div>
            </div>
            <!-- Tables -->

            <div class="" v-if="tab === 0">
                <div>
                    <Tables
                        v-on:
                        :tables="tables"
                        :venueId="venue.id"
                        :venue-slug="venue.slug"
                        :tableDescriptor="venue.tableDescriptor"
                        :order-type="venue.acceptsPat ? 'pat' : 'opat'"
                        :pos-provider="venue.posProvider"
                        @sortedTables="sortedTables"
                        v-on:table-area-added="fetchTables"
                    />
                </div>
            </div>

            <!-- Settings -->

            <div class="px-4 py-5 sm:p-6" v-if="tab === 1">
                <div
                    class="mt-3 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6"
                >
                    <div class="sm:col-span-4">
                        <label
                            for="tableDescriptor"
                            class="block text-sm font-medium leading-5 text-gray-700"
                        >
                            Location names
                        </label>
                        <p class="mt-2 text-sm text-gray-500">
                            By default we use "Table" to refer to where your
                            customers are within your store, but you can change
                            this to anything you like. For example, Room, Hole
                            or Booth </p
                        ><div class="mt-1 flex rounded-md shadow-sm">
                            <input
                                v-model="venue.tableDescriptor"
                                v-on:input="
                                    $emit('venue-updated', {
                                        tableDescriptor: $event.target.value
                                    })
                                "
                                id="tableDescriptor"
                                class="form-input block w-full min-w-0 flex-1 rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            />
                        </div>
                    </div>

                    <div class="sm:col-span-4">
                        <label
                            for="about"
                            class="block text-sm font-medium leading-5 text-gray-700"
                        >
                            Order instructions
                        </label>
                        <p class="mt-2 text-sm text-gray-500"
                            >We'll show this message to your customers when they
                            first visit your ordering site. Leave it as the
                            default or write your own.
                        </p>

                        <div class="mt-1 rounded-md shadow-sm">
                            <textarea
                                v-model="venue.orderInstructions"
                                v-on:input="
                                    $emit('venue-updated', {
                                        orderInstructions: $event.target.value
                                    })
                                "
                                id="orderInstructions"
                                rows="3"
                                class="form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            ></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <AddTableAreaModal v-on:table-area-added="fetchTables" />
        <AddTableModal v-on:table-area-added="fetchTables" />
        <DeleteTableModal v-on:table-area-added="fetchTables" />
        <DeleteTableAreaModal v-on:table-area-added="fetchTables" />
    </div>
</template>

<script>
import Tables from '../tableBuilder/Tables';
import AddTableAreaModal from '../modals/AddTableAreaModal';
import AddTableModal from '../modals/AddTableModal';
import DeleteTableModal from '../modals/DeleteTableModal';
import DeleteTableAreaModal from '../modals/DeleteTableAreaModal';

export default {
    name: 'EditTables',
    components: {
        Tables,
        AddTableAreaModal,
        AddTableModal,
        DeleteTableModal,
        DeleteTableAreaModal
    },
    props: ['venue'],
    data() {
        return {
            tab: 0,
            isLoading: false,
            tables: []
        };
    },
    async mounted() {
        await this.fetchTables();
    },
    methods: {
        async fetchTables() {
            try {
                const { data } = await this.$axios.get(
                    '/venues/' + this.venue.id + '/tables?cache=false'
                );
                this.tables = data;
                this.$modal.hide('api-error-modal');
            } catch (e) {
                throw Error(e);
            } finally {
                this.isLoading = false;
            }
        },
        sortedTables(tables) {
            tables.forEach((table, index) => {
                table.index = String(index);
                delete table.created_at;
                delete table.updated_at;
            });

            this.tables = tables;

            this.updateAreasOrder();
        },
        async updateAreasOrder() {
            try {
                await this.$axios.put(`/areas/sort`, this.tables);
            } catch (e) {
                throw new Error(`API ${e}`);
            }
        }
    }
};
</script>
