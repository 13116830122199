var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.isLoading)?_c('div',{staticClass:"shadow sm:overflow-hidden sm:rounded-md"},[_c('div',{staticClass:"space-y-6 bg-white px-4 py-5 sm:p-6"},[(!_vm.editMode)?_c('div',[_c('label',{staticClass:"block text-sm font-medium text-gray-700",attrs:{"for":"model"}},[_vm._v("Model")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.model),expression:"model"}],staticClass:"focus:outline-none form-input mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm",attrs:{"id":"model","name":"model","disabled":_vm.editMode},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.model=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_vm._l((_vm.models),function(model,index){return _c('option',{key:`model-${index}`},[_vm._v(" "+_vm._s(model)+" ")])}),_c('option',{attrs:{"disabled":""}},[_vm._v("Star SP742 (Coming soon)")])],2)]):_vm._e(),_c('fieldset',[_c('label',{staticClass:"block text-sm font-medium text-gray-700"},[_vm._v(" Name ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.printerName),expression:"printerName"}],staticClass:"form-input mt-1 block w-full sm:text-sm sm:leading-5",class:{
                        'border-red-300': _vm.$v.printerName.$error
                    },attrs:{"type":"text"},domProps:{"value":(_vm.printerName)},on:{"input":function($event){if($event.target.composing)return;_vm.printerName=$event.target.value}}}),(_vm.$v.printerName.$error)?_c('span',{staticClass:"text-red-400"},[_vm._v(" "+_vm._s(_vm.printerNameError)+" ")]):_vm._e()]),_c('fieldset',[_c('label',{staticClass:"block text-sm font-medium text-gray-700",attrs:{"for":"model"}},[_vm._v("Text size")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.textSize),expression:"textSize"}],staticClass:"focus:outline-none form-input mt-1 block w-full rounded-md border-gray-300 pb-2 pl-3 pr-10 text-base capitalize focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm",attrs:{"id":"textSize","name":"textSize"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.textSize=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.textSizes),function(textSize,index){return _c('option',{key:`textSize-${index}`},[_vm._v(" "+_vm._s(textSize)+" ")])}),0)]),_c('fieldset',[_c('label',{staticClass:"mb-2 block text-sm font-medium leading-5 text-gray-700"},[_vm._v("Print item prices ")]),_c('toggle-switch',{model:{value:(_vm.showPrices),callback:function ($$v) {_vm.showPrices=$$v},expression:"showPrices"}})],1),_c('fieldset',[_c('legend',{staticClass:"sr-only"},[_vm._v(" Auto printing settings ")]),_c('div',{staticClass:"-space-y-px rounded-md bg-white"},[_c('div',{staticClass:"relative flex cursor-pointer rounded-tl-md rounded-tr-md border p-4",class:{
                            'z-10 border-indigo-200 bg-indigo-50': _vm.autoPrint,
                            'border-gray-200': !_vm.autoPrint
                        },on:{"click":function($event){_vm.autoPrint = true}}},[_c('div',{staticClass:"flex h-5 items-center self-center"},[_c('input',{staticClass:"h-4 w-4 cursor-pointer border-gray-300 text-indigo-600 focus:ring-indigo-500",attrs:{"id":"settings-option-0","name":"auto_settings","type":"radio"},domProps:{"checked":_vm.autoPrint}})]),_c('label',{staticClass:"ml-3 flex cursor-pointer flex-col",attrs:{"for":"settings-option-0"}},[_c('span',{staticClass:"block text-sm font-medium",class:{
                                    'text-indigo-900': _vm.autoPrint,
                                    'text-gray-900': !_vm.autoPrint
                                }},[_vm._v(" Auto print ")]),_c('span',{staticClass:"block text-xs",class:{
                                    'text-indigo-700': _vm.autoPrint,
                                    'text-gray-500': !_vm.autoPrint
                                }},[_vm._v(" Print every order as soon as it's accepted ")])])]),_c('div',{staticClass:"relative flex cursor-pointer rounded-bl-md rounded-br-md border p-4",class:{
                            'z-10 border-indigo-200 bg-indigo-50': !_vm.autoPrint,
                            'border-gray-200': _vm.autoPrint
                        },on:{"click":function($event){_vm.autoPrint = false}}},[_c('div',{staticClass:"flex h-5 items-center self-center"},[_c('input',{staticClass:"h-4 w-4 cursor-pointer border-gray-300 text-indigo-600 focus:ring-indigo-100",attrs:{"id":"settings-option-1","name":"auto_settings","type":"radio"},domProps:{"checked":!_vm.autoPrint}})]),_c('label',{staticClass:"ml-3 flex cursor-pointer flex-col",attrs:{"for":"settings-option-1"}},[_c('span',{staticClass:"block text-sm font-medium",class:{
                                    'text-indigo-900': !_vm.autoPrint,
                                    'text-gray-900': _vm.autoPrint
                                }},[_vm._v(" Manual print ")]),_c('span',{staticClass:"block text-xs",class:{
                                    'text-indigo-700': !_vm.autoPrint,
                                    'text-gray-500': _vm.autoPrint
                                }},[_vm._v(" Only print orders when clicking print in the dashboard ")])])])])]),_c('fieldset',[_c('legend',{staticClass:"sr-only"},[_vm._v(" Number of copies ")]),_c('div',{staticClass:"-space-y-px rounded-md bg-white"},[_c('div',{staticClass:"relative flex cursor-pointer rounded-tl-md rounded-tr-md border p-4",class:{
                            'z-10 border-indigo-200 bg-indigo-50':
                                _vm.defaultCopies === 1,
                            'border-gray-200': _vm.defaultCopies !== 1
                        },on:{"click":function($event){_vm.defaultCopies = 1}}},[_c('div',{staticClass:"flex h-5 items-center"},[_c('input',{staticClass:"h-4 w-4 cursor-pointer border-gray-300 text-indigo-600 focus:ring-indigo-500",attrs:{"id":"defaultCopies1","name":"privacy_setting","type":"radio"},domProps:{"checked":_vm.defaultCopies === 1}})]),_c('label',{staticClass:"ml-3 flex cursor-pointer flex-col",attrs:{"for":"defaultCopies1"}},[_c('span',{staticClass:"block text-sm font-medium",class:{
                                    'text-indigo-900': _vm.defaultCopies === 1,
                                    'text-gray-900': _vm.defaultCopies !== 1
                                }},[_vm._v(" 1 copy ")])])]),_c('div',{staticClass:"relative flex cursor-pointer border p-4",class:{
                            'z-10 border-indigo-200 bg-indigo-50':
                                _vm.defaultCopies === 2,
                            'border-gray-200': _vm.defaultCopies !== 2
                        },on:{"click":function($event){_vm.defaultCopies = 2}}},[_c('div',{staticClass:"flex h-5 items-center"},[_c('input',{staticClass:"h-4 w-4 cursor-pointer border-gray-300 text-indigo-600 focus:ring-indigo-500",attrs:{"id":"defaultCopies2","name":"privacy_setting","type":"radio"},domProps:{"checked":_vm.defaultCopies === 2}})]),_c('label',{staticClass:"ml-3 flex cursor-pointer flex-col",attrs:{"for":"defaultCopies2"}},[_c('span',{staticClass:"block text-sm font-medium",class:{
                                    'text-indigo-900': _vm.defaultCopies === 2,
                                    'text-gray-900': _vm.defaultCopies !== 2
                                }},[_vm._v(" 2 copies ")])])]),_c('div',{staticClass:"relative flex cursor-pointer border p-4",class:{
                            'z-10 border-indigo-200 bg-indigo-50':
                                _vm.defaultCopies === 3,
                            'border-gray-200': _vm.defaultCopies !== 3
                        },on:{"click":function($event){_vm.defaultCopies = 3}}},[_c('div',{staticClass:"flex h-5 items-center"},[_c('input',{staticClass:"h-4 w-4 cursor-pointer border-gray-300 text-indigo-600 focus:ring-indigo-500",attrs:{"id":"defaultCopies3","name":"privacy_setting","type":"radio"},domProps:{"checked":_vm.defaultCopies === 3}})]),_c('label',{staticClass:"ml-3 flex cursor-pointer flex-col",attrs:{"for":"defaultCopies3"}},[_c('span',{staticClass:"block text-sm font-medium",class:{
                                    'text-indigo-900': _vm.defaultCopies === 3,
                                    'text-gray-900': _vm.defaultCopies !== 3
                                }},[_vm._v(" 3 copies ")])])]),_c('div',{staticClass:"relative flex cursor-pointer rounded-bl-md rounded-br-md border p-4",class:{
                            'z-10 border-indigo-200 bg-indigo-50':
                                _vm.defaultCopies === 4,
                            'border-gray-200': _vm.defaultCopies !== 4
                        },on:{"click":function($event){_vm.defaultCopies = 4}}},[_c('div',{staticClass:"flex h-5 items-center"},[_c('input',{staticClass:"h-4 w-4 cursor-pointer border-gray-300 text-indigo-600 focus:ring-indigo-500",attrs:{"id":"defaultCopies4","name":"privacy_setting","type":"radio"},domProps:{"checked":_vm.defaultCopies === 4}})]),_c('label',{staticClass:"ml-3 flex cursor-pointer flex-col",attrs:{"for":"defaultCopies3"}},[_c('span',{staticClass:"block text-sm font-medium",class:{
                                    'text-indigo-900': _vm.defaultCopies === 4,
                                    'text-gray-900': _vm.defaultCopies !== 4
                                }},[_vm._v(" 4 copies ")])])])])]),(_vm.autoPrint)?_c('fieldset',[_c('label',{staticClass:"block text-sm font-medium text-gray-700"},[_vm._v(" Buffer (in minutes) ")]),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.buffer),expression:"buffer",modifiers:{"number":true}}],staticClass:"form-input mt-1 block w-full sm:text-sm sm:leading-5",class:{
                        'border-red-300': _vm.$v.buffer.$error
                    },attrs:{"placeholder":"eg 30","type":"number","min":"0","max":"720"},domProps:{"value":(_vm.buffer)},on:{"input":function($event){if($event.target.composing)return;_vm.buffer=_vm._n($event.target.value)},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.$v.buffer.$error)?_c('span',{staticClass:"text-red-400"},[_vm._v(" "+_vm._s(_vm.bufferError)+" ")]):_vm._e()]):_vm._e(),(!_vm.editMode)?_c('div',[_c('label',{staticClass:"block text-sm font-medium text-gray-700",attrs:{"for":"mac"}},[_vm._v(" MAC Address ")]),_c('div',{staticClass:"mt-1"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.mac),expression:"mac"},{name:"mask",rawName:"v-mask",value:('XX:XX:XX:XX:XX:XX'),expression:"'XX:XX:XX:XX:XX:XX'"}],staticClass:"form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm",class:{
                            'border-red-300': _vm.$v.mac.$error
                        },attrs:{"type":"tel","id":"mac","name":"mac","placeholder":"00:11:62:a1:b2:c3","disabled":_vm.editMode,"required":""},domProps:{"value":(_vm.mac)},on:{"input":function($event){if($event.target.composing)return;_vm.mac=$event.target.value}}})]),(_vm.$v.mac.$error)?_c('span',{staticClass:"text-red-400"},[_vm._v(" "+_vm._s(_vm.macError)+" ")]):_vm._e(),_vm._m(0)]):_vm._e()]),_c('div',{staticClass:"flex items-center justify-between bg-gray-50 px-4 py-3 text-right sm:px-6"},[(_vm.editMode)?_c('div',{staticClass:"group mr-3 inline-block cursor-pointer text-gray-400",attrs:{"disabled":_vm.isLoading},on:{"click":function($event){return _vm.showDeletePrinterModal()}}},[_c('svg',{staticClass:"h-5 w-5 group-hover:text-red-400",attrs:{"fill":"none","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"d":"M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"}})])]):_vm._e(),(_vm.editMode)?_c('button',{staticClass:"focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",attrs:{"type":"submit"},on:{"click":_vm.editPrinter}},[_vm._v(" Save settings ")]):_c('button',{staticClass:"focus:outline-none inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",on:{"click":_vm.createPrinter}},[_vm._v(" Add printer ")])])]):_c('div',{staticClass:"sm:p-6w-4 h-4 space-y-6 px-4 py-5 text-gray-900 spinner"},[_c('span')]),_c('DeletePrinterModal',{on:{"fetch-printers":_vm.navigateToPrinterList}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"mt-2 text-sm text-gray-500"},[_vm._v(" Perform a self-print to get access to your printer's MAC address. Follow "),_c('a',{staticClass:"text-indigo-600",attrs:{"href":"https://www.star-m.jp/products/s_print/mcprint3/manual/en/basicOperations/testPrint.htm","target":"_blank"}},[_vm._v("these instructions")]),_vm._v(" or contact us if you get stuck ")])
}]

export { render, staticRenderFns }