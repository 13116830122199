<template>
    <button
        type="button"
        class="focus:outline-none group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        role="switch"
        @click="onChange()"
        :aria-checked="value.toString()"
    >
        <span class="sr-only">Use setting</span>
        <span
            aria-hidden="true"
            class="pointer-events-none absolute h-full w-full rounded-md bg-transparent"
        ></span>
        <span
            aria-hidden="true"
            :class="{ 'bg-indigo-600': value, 'bg-gray-200': !value }"
            class="pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out"
        ></span>
        <span
            aria-hidden="true"
            :class="{ 'translate-x-5': value, 'translate-x-0': !value }"
            class="pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out"
        ></span>
        <toggle-confirm-modal
            @confirm="emitChange()"
            :options="{ title: confirmTitle, description: confirmDescription }"
        />
    </button>
</template>

<script>
import ToggleConfirmModal from '@/components/modals/ToggleConfirmModal';

export default {
    name: 'SmallToggleSwitch',
    components: {
        ToggleConfirmModal
    },
    props: {
        value: {
            type: Boolean,
            default: false,
            required: true
        },
        confirm: {
            type: Boolean,
            default: false
        },
        confirmTitle: {
            type: String,
            default: 'Confirm changes'
        },
        confirmDescription: {
            type: String,
            default:
                'To confirm changes, please click the confirm button shown below.'
        }
    },
    methods: {
        onChange() {
            this.confirm ? this.displayConfirmModal() : this.emitChange();
        },
        displayConfirmModal() {
            this.$modal.show('toggle-confirm-modal');
        },
        emitChange() {
            this.$modal.hide('toggle-confirm-modal');
            this.$emit('input', !this.value);
        }
    }
};
</script>
