<template>
    <div>
        <div class="mb-4 print:hidden">
            <div>
                <BasePageTitle title="Feedback average over time" />
            </div>
            <div class="mt-1 flex flex-col sm:mt-2 sm:flex-row sm:flex-wrap">
                <PrintButton />
                <ExportButton @clicked="showExportCsvModal" />
            </div>
        </div>
        <div class="relative mb-4 mt-2 flex items-center print:hidden">
            <DateRangeSelector v-on:date-updated="updateReport" />
            <label class="pl-3 pr-2 text-sm text-gray-800"> Group by:</label>
            <ReportIntervalSelection v-model="interval" />
        </div>

        <div class="mb-4 overflow-hidden rounded-sm bg-white shadow">
            <div class="py-2 pl-1 pr-2">
                <apex-chart
                    v-if="!isLoading"
                    width="100%"
                    height="350px"
                    type="area"
                    :options="chartOptions"
                    :series="series"
                    class="font-sans"
                ></apex-chart>
            </div>
        </div>

        <div class="mt-8 bg-white shadow">
            <vue-good-table
                v-if="!isLoading && reportData && reportData.length"
                :isLoading.sync="isLoading"
                class="border-b border-gray-200"
                styleClass="tailwind-table"
                :pagination-options="{
                    enabled: false,
                    perPage: 100
                }"
                :columns="columns"
                :rows="reportData"
            >
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field === 'avg'">
                        <div
                            class="bg-orange-50 flex h-8 w-10 items-center justify-center rounded-lg text-yellow-300"
                        >
                            <span
                                class="block text-sm font-medium text-gray-600"
                                >{{
                                    props.formattedRow[props.column.field]
                                }}</span
                            >
                        </div>
                    </span>

                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
            </vue-good-table>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PrintButton from '../helpers/PrintButton';
import ExportButton from '@/components/helpers/ExportButton';
import DateRangeSelector from '../widgets/DateRangeSelector';
import ReportIntervalSelection from '../selectors/ReportIntervalSelection';

export default {
    name: 'FeedbackAverageReport',
    props: {
        selectedVenues: {
            type: Array,
            required: true
        },
        venues: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    components: {
        PrintButton,
        ExportButton,
        DateRangeSelector,
        ReportIntervalSelection
    },
    data() {
        return {
            isLoading: true,
            dateRange: {
                startDate: null,
                endDate: null
            },
            interval: 'day',
            chartOptions: {
                theme: {
                    palette: 'palette10'
                },
                chart: {
                    id: 'dow-report',
                    toolbar: false
                },
                title: {
                    text: 'Average rating over time',
                    align: 'left',
                    margin: 10,
                    offsetX: 0,
                    offsetY: 0,
                    floating: false,
                    style: {
                        fontSize: '15px',
                        fontWeight: 'bold',
                        color: '#263238'
                    }
                },
                colors: ['rgba(78, 205, 196, 1)'],
                stroke: {
                    width: 4
                },
                xaxis: {
                    lines: {
                        show: false
                    }
                },
                yaxis: [
                    {
                        lines: {
                            show: false
                        }
                    }
                ],
                dataLabels: {
                    enabled: false
                },
                tooltip: {
                    enabled: true
                },
                dropShadow: {
                    enabled: true,
                    top: 0,
                    left: 0,
                    blur: 3,
                    opacity: 0.1
                }
            },
            series: [
                {
                    name: 'Average Rating',
                    type: 'area',
                    data: []
                }
            ],
            columns: [
                {
                    label: 'Date',
                    field: 'day',
                    sortable: true,
                    firstSortType: 'desc',
                    type: 'date',
                    dateInputFormat: 'yyyy-MM-dd',
                    dateOutputFormat: 'MMM do yy',
                    tdClass:
                        'px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500',
                    thClass:
                        'px-6 bg-gray-100 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider'
                },
                {
                    label: 'Average',
                    field: 'avg',
                    type: 'number',
                    sortable: true,
                    firstSortType: 'desc',
                    tdClass:
                        'px-6 py-3 whitespace-nowrap text-sm leading-5 text-gray-500',
                    thClass:
                        'px-6 bg-gray-100 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider'
                },
                {
                    label: 'Total Reviews',
                    field: 'total',
                    type: 'number',
                    sortable: true,
                    firstSortType: 'desc',
                    tdClass:
                        'px-6 py-3 whitespace-nowrap text-sm leading-5 text-gray-500',
                    thClass:
                        'px-6 bg-gray-100 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider'
                }
            ],
            reportData: null,
            csvTitle: 'Reviews',
            fields: [
                { label: 'Date', value: 'day' },
                { label: 'Average Rating', value: 'avg' },
                { label: 'Total', value: 'total' }
            ]
        };
    },
    created() {
        this.dateRange = this.getDateRange || this.dateRange;

        this.fetchReport();
    },
    methods: {
        updateReport(val) {
            this.dateRange = val;
            this.fetchReport();
        },
        async fetchReport() {
            this.isLoading = true;

            const params = {
                from: this.dateRange.startDate,
                to: this.dateRange.endDate,
                interval: this.interval,
                venueIds: this.selectedVenues
            };

            try {
                const response = await this.$axios.get(
                    '/reports/feedback/over-time-period',
                    { params }
                );

                this.reportData = response.data;

                const data = [];
                for (const val of response.data) {
                    const x = val.day || val.week || val.month;
                    const y = val.avg;
                    data.push({ x, y });
                }

                this.series[0].data = data;
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        },
        print() {
            window.print();
        },
        venueById(id) {
            return this.venues.find(venue => venue.id === id);
        },
        showExportCsvModal() {
            this.$modal.show('export-csv-modal', {
                header: this.csvTitle,
                csvData: this.reportData,
                fields: this.fields
            });
        }
    },
    computed: {
        ...mapGetters({
            getDateRange: 'report/getDateRange'
        }),
        dateInputFormat() {
            if (this.interval === 'day') {
                return 'yyyy-MM-dd';
            } else if (this.interval === 'week') {
                return 'yyyy-ww';
            } else {
                return 'yyyy-MM';
            }
        }
    },
    watch: {
        selectedVenues() {
            this.fetchReport();
        },
        interval() {
            this.fetchReport();
        }
    }
};
</script>
