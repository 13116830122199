<template>
    <div>
        <div class="md:flex md:items-center md:justify-between">
            <div class="min-w-0 flex-1">
                <router-link
                    :to="{ name: 'editVenue' }"
                    class="mb-2 flex items-center text-sm text-gray-500 inline-flex"
                >
                    <base-back-icon />
                    <span>Settings</span>
                </router-link>
                <base-page-title title="Meta Integration" />
            </div>

            <BaseSpinner :isLoading="isLoading" />
        </div>
        <div>
            <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                            >Details</h3
                        >
                        <p class="mt-2 text-sm text-gray-500">
                            Enter your Meta pixel ID. We'll embed this on all
                            pages on your store, and send relevant conversion
                            information to Meta.
                            <br /><br />If you don't have a pixel ID yet, you
                            can learn how to
                            <a
                                class="text-indigo-600"
                                href="https://en-gb.facebook.com/business/help/314143995668266?id=1205376682832142"
                                target="_blank"
                                >create one here</a
                            >.
                        </p>
                    </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0">
                    <div class="shadow sm:overflow-hidden sm:rounded-md">
                        <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
                            <div class="grid grid-cols-3 gap-6">
                                <div class="col-span-3 sm:col-span-2">
                                    <base-input-field
                                        label="Meta Pixel ID"
                                        id="pixelId"
                                        v-model="settings.facebookPixelId"
                                        :errors="
                                            $v.settings.facebookPixelId.$error
                                        "
                                        placeholder="e.g. 12345678901234"
                                    />
                                    <p
                                        v-if="
                                            $v.settings.facebookPixelId.$error
                                        "
                                        class="text-xs text-red-600"
                                    >
                                        Meta pixel identifier is too short
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <BaseSpacerWithRuler />

        <div class="my-6 ml-4 flex flex-shrink-0 justify-end">
            <span class="inline-flex rounded-md shadow-sm" @click="saveVenue">
                <button
                    type="button"
                    class="focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                    :disabled="isLoading"
                >
                    Save
                </button>
            </span>
        </div>

        <!--        <FooterHelp label="notices" link="https://storekit.com" />-->
    </div>
</template>

<script>
import { minLength } from 'vuelidate/lib/validators';

export default {
    name: 'FacebookSettings',
    metaInfo: {
        title: 'Meta Ad Integration'
    },
    props: ['venueId', 'venue'],
    data() {
        return {
            isLoading: false,
            settings: { ...this.venue }
        };
    },
    validations: {
        settings: {
            facebookPixelId: {
                minLength: minLength(10)
            }
        }
    },
    methods: {
        async saveVenue() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.errors = 'ERROR';
                return false;
            }

            try {
                this.isLoading = true;

                const payload = {
                    facebookPixelId: this.settings.facebookPixelId || null
                };
                // eslint-disable-next-line
                const response = await this.$axios.put(
                    '/venues/' + this.venueId,
                    payload
                );

                this.$notify({
                    group: 'settings',
                    title: 'Settings saved'
                });
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        }
    }
};
</script>
