<template>
    <div>
        <div>
            <button
                @click="showDisconnectPayPalModal()"
                type="button"
                class="focus:ring-2 focus:ring-offset-2 focus:ring-red-500 inline-flex items-center px-4 py-2 text-red-700 text-sm font-medium bg-red-100 hover:bg-red-200 border border-transparent rounded-md focus:outline-none"
            >
                Deactivate PayPal
            </button>
        </div>
        <DisconnectPayPalModal />
    </div>
</template>
<script>
import DisconnectPayPalModal from '@/components/modals/DisconnectPaypalModal';

export default {
    props: {
        venueId: {
            type: Number,
            required: true,
            default: 0
        }
    },
    components: {
        DisconnectPayPalModal
    },
    methods: {
        showDisconnectPayPalModal() {
            this.$modal.show('disconnect-paypal-modal', {
                venueId: this.venueId
            });
        }
    }
};
</script>
