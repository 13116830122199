<template>
    <div class="flex w-0 flex-1 flex-col overflow-hidden">
        <main class="focus:outline-none relative z-0 h-full flex-1">
            <div
                class="mx-auto flex max-w-7xl flex-row items-center justify-between px-4 sm:px-6 lg:px-8"
            >
                <div
                    class="h-4 w-4"
                    :class="{ 'text-gray-900 spinner': isLoading }"
                >
                    <span></span>
                </div>
            </div>
            <div
                class="mx-auto flex h-full max-w-7xl flex-col justify-center px-4 sm:px-6 md:px-8"
            >
                <transition name="fade" v-if="!importSuccess">
                    <base-page-title :title="pageTitle" />
                </transition>

                <transition name="fade" v-if="importSuccess">
                    <div
                        v-if="importSuccess"
                        class="mr-3 mt-6 flex w-full flex-row bg-white shadow sm:rounded-lg md:w-1/2"
                    >
                        <div class="w-full px-4 py-5 sm:p-6">
                            <h3
                                class="flex items-center font-heading text-lg font-semibold leading-6 text-gray-900"
                            >
                                Success
                                <base-check-circle-icon
                                    class="ml-2 h-5 w-5 text-green-600"
                                />
                            </h3>
                            <div class="mt-2 text-sm leading-5 text-gray-500">
                                <p
                                    >Thanks! We've got your menu and will start
                                    working on it asap. We'll send you an email
                                    ({{ user ? user.email : '' }}) when it's
                                    ready.
                                </p>
                                <p class="mt-1">Redirecting to the menu...</p>
                            </div>
                        </div>
                    </div>
                </transition>

                <div class="flex flex-col md:flex-row" v-if="!importSuccess">
                    <div
                        v-if="activeImportOption === 'website'"
                        class="mr-3 mt-6 flex w-full flex-row bg-white shadow sm:rounded-lg md:w-1/2"
                    >
                        <div class="w-full px-4 py-5 sm:p-6">
                            <div>
                                <base-input-field
                                    label="Your website link"
                                    caption="If you have multiple menus, enter the menu listing page and describe below which menus should be imported."
                                    type="url"
                                    v-model="urlImport.url"
                                    :error-message="
                                        $v.urlImport.$error
                                            ? 'Please enter a valid URL'
                                            : ''
                                    "
                                    :errors="$v.urlImport.$error"
                                    placeholder="e.g. https://example.com/menu"
                                />
                                <base-input-field
                                    class="mt-3"
                                    label="Comments"
                                    labelPostfix=" (optional)"
                                    caption="Anything else we should know when importing your menu?"
                                    type="text"
                                    v-model="urlImport.comments"
                                />
                                <base-button
                                    @clicked="importMenu"
                                    class="mt-3"
                                    buttonText="Submit"
                                    buttonStyle="primary"
                                    :disabled="
                                        this.$v.urlImport.$invalid || isLoading
                                    "
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="activeImportOption === 'pdf'"
                        class="mr-3 mt-6 flex w-full flex-row bg-white shadow sm:rounded-lg md:w-1/2"
                    >
                        <div class="w-full px-4 py-5 sm:p-6">
                            <div>
                                <uploadcare-inline
                                    ctxName="menu-pdf2"
                                    publicKey="6f16cc10b9a381e43be2"
                                    @success="handleUploadSuccess"
                                    :multipleMax="3"
                                    :multiple="true"
                                />

                                <base-input-field
                                    class="mt-3"
                                    label="Comments"
                                    labelPostfix=" (optional)"
                                    caption="Anything else we should know when importing your menu?"
                                    type="text"
                                    v-model="pdfImport.comments"
                                />
                                <base-button
                                    @clicked="importMenu"
                                    class="mt-3"
                                    buttonText="Submit"
                                    buttonStyle="primary"
                                    :disabled="
                                        this.$v.pdfImport.$invalid || isLoading
                                    "
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="!activeImportOption"
                        class="flex flex-col md:flex-row"
                    >
                        <div
                            v-for="option in importOptions"
                            :key="option.title"
                            class="mr-3 mt-6 flex w-full flex-row bg-white shadow sm:rounded-lg md:w-1/3"
                        >
                            <div class="flex w-full flex-col px-4 py-5 sm:p-6">
                                <h3
                                    class="font-heading text-lg font-semibold leading-6 text-gray-900"
                                >
                                    {{ option.title }}
                                </h3>
                                <div
                                    class="mt-2 text-sm leading-5 text-gray-500"
                                >
                                    <p>
                                        {{ option.description }}
                                    </p>
                                </div>
                                <div class="mt-auto justify-end">
                                    <span
                                        class="mt-3 inline-flex rounded-md shadow-sm sm:w-auto"
                                        :class="{
                                            'button-spinner spinner': isLoading
                                        }"
                                    >
                                        <button
                                            @click="option.action"
                                            :disabled="isLoading"
                                            type="button"
                                            class="focus:outline-none inline-flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 font-medium text-white transition duration-150 ease-in-out hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 sm:w-auto sm:text-sm sm:leading-5"
                                        >
                                            {{ option.actionText }}
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="!importSuccess">
                    <div
                        class="mt-10 flex flex-col pl-2 sm:flex-row sm:flex-wrap"
                    >
                        <button
                            @click="goBack"
                            type="button"
                            class="focus:outline-none group -ml-2 mt-2 flex cursor-pointer items-center rounded-md rounded-sm p-1.5 text-sm leading-5 text-gray-600 hover:bg-gray-200 hover:text-gray-700 sm:mr-6"
                        >
                            <base-back-icon />
                            Go back
                        </button>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, url } from 'vuelidate/lib/validators';
import uploadcareInline from '@/components/widgets/UploadcareInline';

export default {
    name: 'CreateMenuImport',
    metaInfo: {
        title: 'Import Menu'
    },
    components: { uploadcareInline },
    validations: {
        urlImport: {
            url: {
                required,
                url
            }
        },
        pdfImport: {
            sources: {
                required
            }
        }
    },
    data() {
        return {
            isLoading: false,
            menuId: null,
            newCategory: {},
            activeImportOption: null,
            importSuccess: false,
            urlImport: {
                url: null,
                comments: null
            },
            pdfImport: {
                sources: [],
                comments: null
            },
            importOptions: [
                {
                    title: 'Import from a website',
                    subtitle: 'Import your menu from a file',
                    description:
                        'If you have your menu available online, either on your own website, or an aggregator like Deliveroo or Uber Eats. ',
                    speed: '10-30 mins',
                    cost: 'Free',
                    action: () => {
                        this.activeImportOption = 'website';
                    },
                    actionText: 'Enter a website'
                },
                {
                    title: 'Import from a PDF file',
                    subtitle: 'Import your menu from a file',
                    description:
                        'If you have your menu available in a PDF file you can upload. ',
                    speed: '10-30 mins',
                    cost: 'Free',
                    action: () => {
                        this.activeImportOption = 'pdf';
                    },
                    actionText: 'Upload PDF(s)'
                }
            ]
        };
    },
    computed: {
        ...mapGetters({ user: 'user/currentUser' }),
        pageTitle() {
            switch (this.activeImportOption) {
                case 'website':
                    return 'Import from a website';
                case 'pdf':
                    return 'Import from a PDF file';
                default:
                    return "Let's import your menu";
            }
        }
    },
    methods: {
        async createMenu() {
            const { venueId } = this.$route.params;
            this.isLoading = true;

            try {
                const { data } = await this.$axios.post('/menus', {
                    venueId,
                    name: 'Default Menu',
                    version: 0,
                    importStatus: 'inProgress'
                });

                if (!data || !data.id) {
                    return;
                }

                this.menuId = data.id;

                // await this.createCategory();

                if (window.analytics) {
                    window.analytics.track('Menu Created', {
                        menuId: data.id,
                        venueId,
                        accountId: this.user.accounts[0].id,
                        context: {
                            groupId: this.user.accounts[0].id
                        }
                    });
                }
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }
        },
        async importMenu() {
            const { venueId } = this.$route.params;
            this.$v.$touch();
            const type = this.activeImportOption;
            if (
                (type === 'pdf' && this.$v.pdfImport.$invalid) ||
                (type === 'website' && this.$v.urlImport.$invalid)
            ) {
                return false;
            }

            try {
                this.isLoading = true;

                await this.createMenu();

                await this.$axios.post(`/menus/${venueId}/import`, {
                    type,
                    url: this.urlImport.url,
                    comments:
                        type === 'website'
                            ? this.urlImport.comments
                            : this.pdfImport.comments,
                    sources: this.pdfImport.sources,
                    menuId: this.menuId
                });

                this.importSuccess = true;

                // redirect to menu after 10 seconds
                setTimeout(() => {
                    this.$router.push({
                        name: 'editMenu',
                        params: { id: this.menuId }
                    });
                }, 10000);
            } catch (e) {
                throw new Error(`API ${e}`);
            } finally {
                this.isLoading = false;
            }

            return false;
        },
        goBack() {
            if (this.activeImportOption) {
                this.activeImportOption = null;
            } else {
                this.$router.go(-1);
            }
        },
        handleUploadSuccess(files) {
          if (Array.isArray(files)) {
            this.pdfImport.sources = files.map(file => file.cdnUrl);
          }
          else {
            this.pdfImport.sources = [files.cdnUrl];
          }
        }
    }
};
</script>

<style scoped>
.spinner.button-spinner:after {
    left: auto;
    right: 1em;
    border-right-color: white;
}

.spinner.slug-spinner {
    color: black;
    pointer-events: all;
}

.spinner.slug-spinner:after {
    left: auto;
    right: 1em;
}
</style>
