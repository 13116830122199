<template>
    <modal
        name="piggy-connect-modal"
        :adaptive="true"
        height="auto"
        width="600px"
        @before-open="beforeOpen"
    >
        <div
            class="w-full transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 shadow-xl transition-all sm:p-6"
        >
            <div class="sm:flex sm:items-start">
                <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <h3 class="text-lg font-medium leading-6 text-gray-900">
                        Connect to Piggy
                    </h3>
                    <div class="mt-2">
                        <p class="text-sm leading-5 text-gray-500">
                            Provide your credentials and connect with Piggy
                        </p>
                    </div>
                </div>
            </div>

            <div class="m-auto mt-2 w-full p-6 pb-0">
                <base-input-field
                    id="piggyClientId"
                    v-model="piggySettings.clientId"
                    label="Piggy Client Id"
                    placeholder="eg 1001"
                    type="number"
                    :errors="$v.piggySettings.clientId.$error"
                    error-message="This field is required"
                />
            </div>

            <div class="m-auto w-full p-6">
                <base-input-field
                    id="piggySecret"
                    v-model="piggySettings.secret"
                    label="Piggy Client Secret"
                    placeholder="eg AJDWNAUD1231D9031401"
                    :errors="$v.piggySettings.secret.$error"
                    error-message="This field is required"
                />
            </div>
            <div class="mt-5 justify-end sm:mt-4 sm:flex sm:pl-4">
                <span
                    class="mt-3 flex w-full rounded-md shadow-sm sm:ml-3 sm:mt-0 sm:w-auto"
                    @click="connectPiggy"
                >
                    <button
                        type="button"
                        class="focus:outline-none inline-flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                        :disabled="isLoading"
                    >
                        Save
                    </button>
                </span>

                <span
                    class="mt-3 flex w-full rounded-md shadow-sm sm:ml-3 sm:mt-0 sm:w-auto"
                >
                    <button
                        @click="hideModal"
                        type="button"
                        class="focus:outline-none inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium leading-6 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline sm:text-sm sm:leading-5"
                    >
                        Cancel
                    </button>
                </span>
            </div>
        </div>
    </modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
    name: 'piggy-connect-modal',
    validations: {
        piggySettings: {
            secret: {
                required
            },
            clientId: {
                required
            }
        }
    },
    data() {
        return {
            isLoading: false,
            piggySettings: { secret: '', clientId: null },
            callback: null
        };
    },
    methods: {
        beforeOpen({ params }) {
            this.callback = params.callback;
        },
        hideModal() {
            this.$emit('close');
            this.$modal.hide('piggy-connect-modal');
        },
        async connectPiggy() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                this.errors = 'ERROR';

                return false;
            }

            try {
                this.isLoading = true;

                const payload = {
                    clientId: this.piggySettings.clientId,
                    secret: this.piggySettings.secret
                };

                await this.$axios.post(`/piggy/auth`, payload);

                this.$notify({
                    group: 'settings',
                    title: 'Settings saved'
                });

                this.callback(true);
                this.hideModal();
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        }
    }
};
</script>
