<template>
    <button
        @click="print"
        type="button"
        class="focus:outline-none group flex cursor-pointer items-center rounded-md px-2 py-1 text-xs leading-5 font-medium text-gray-600 hover:text-gray-700 hover:bg-gray-200 focus:bg-gray-300 sm:mr-2"
    >
        <svg
            class="flex-shrink-0 mr-1.5 w-5 h-5 text-gray-600 group-hover:text-gray-700"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                d="M5 4v3H4a2 2 0 00-2 2v3a2 2 0 002 2h1v2a2 2 0 002 2h6a2 2 0 002-2v-2h1a2 2 0 002-2V9a2 2 0 00-2-2h-1V4a2 2 0 00-2-2H7a2 2 0 00-2 2zm8 0H7v3h6V4zm0 8H7v4h6v-4z"
                clip-rule="evenodd"
            ></path>
        </svg>
        Print
    </button>
</template>

<script>
export default {
    name: 'PrintButton',
    methods: {
        print() {
            window.print();
        }
    }
};
</script>
