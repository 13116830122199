<template>
    <modal
        name="link-input-modal"
        width="600px"
        height="auto"
        :clickToClose="true"
        @before-open="beforeOpen"
        :adaptive="true"
    >
        <div
            class="transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 shadow-xl transition-all sm:w-full sm:p-6"
        >
            <div>
                <div class="mt-3 text-left sm:mt-5">
                    <h3 class="text-lg font-medium leading-6 text-gray-900">
                        Add link
                    </h3>
                    <div class="mt-2"> </div>
                    <div class="mt-3">
                        <base-input-field
                            label="Text"
                            id="text"
                            v-model="text"
                        />
                    </div>

                    <div class="mt-3">
                        <base-input-field
                            label="Link"
                            id="link"
                            v-model="link"
                            :errors="$v.link.$error"
                        />
                    </div>
                </div>
            </div>
            <div class="mt-5 sm:mt-6 sm:flex sm:w-auto">
                <span class="flex w-full rounded-md shadow-sm sm:w-56">
                    <button
                        @click="createLink"
                        type="button"
                        class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo sm:text-sm sm:leading-5"
                    >
                        Create
                    </button>
                </span>
                <span
                    class="mt-3 flex w-full rounded-md shadow-sm sm:ml-3 sm:mt-0 sm:w-auto"
                >
                    <button
                        @click="$modal.hide('link-input-modal')"
                        type="button"
                        class="focus:outline-none inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium leading-6 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline sm:text-sm sm:leading-5"
                    >
                        Close
                    </button>
                </span>
            </div>
        </div>
    </modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
    name: 'LinkInputModal',
    components: {},
    validations: {
        link: { required }
    },
    data() {
        return {
            link: '',
            text: ''
        };
    },
    methods: {
        beforeOpen(event) {
            if (event.params.previousUrl) {
                this.link = event.params.previousUrl;
            }
        },
        createLink() {
            this.$v.link.$touch();

            if (this.$v.link.$invalid) {
                return;
            }

            this.$v.$reset();

            this.$emit('create-link', { text: this.text, link: this.link });
            this.link = '';
            this.text = '';

            this.$modal.hide('link-input-modal');
        }
    }
};
</script>
