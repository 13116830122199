<template>
    <VueTelInput
        styleClasses="mt-1 border focus:border-blue-300 border-gray-300 rounded-md focus:outline-none focus:shadow-outline-blue shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5"
        :class="{ 'border-red-300': error }"
        :value="value"
        @input="updateValue"
        @validate="updateValidate"
        :autoFormat="true"
        mode="international"
        :validCharactersOnly="true"
        :dropdownOptions="dropdownOptions"
        :preferredCountries="preferredCountries"
    />
</template>

<script>
import { VueTelInput } from 'vue-tel-input';

export default {
    name: 'InternationalTelInput',
    components: {
        VueTelInput
    },
    props: {
        value: {
            type: String,
            required: false,
            default: ''
        },
        error: {
            type: Boolean,
            required: false,
            default: null
        }
    },
    data() {
        return {
            dropdownOptions: {
                showDialCodeInList: true,
                showFlags: true,
                showSearchBox: true
            },
            valididation: null,
            width: '380px',
            preferredCountries: ['GB', 'FR', 'IE', 'ES', 'NL']
        };
    },
    methods: {
        updateValue(value) {
            const stripeWhitespace = value.replace(/[\s/]/g, '');
            this.$emit('input', stripeWhitespace);
        },
        updateValidate(value) {
            this.valididation = value;
        }
    }
};
</script>

<style src="vue-tel-input/dist/vue-tel-input.css"></style>

<style>
.vue-tel-input > input {
    @apply rounded-r-md text-sm;
}
.vue-tel-input > div {
    @apply rounded-l-md;
}
</style>
