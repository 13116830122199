<template>
    <div>
        <div class="mt-4 md:grid md:gap-6 md:grid-cols-3">
            <div class="md:col-span-1">
                <div class="sm:pl-0 sm:pr-5">
                    <h3 class="text-gray-800 text-base font-semibold leading-6"
                        >Details</h3
                    >
                    <p class="mt-2 text-gray-500 text-sm">
                        Connect to and manage PayPal
                    </p>
                </div>
            </div>
            <div class="mt-5 md:col-span-2 md:mt-0">
                <div class="shadow sm:rounded-md sm:overflow-hidden">
                    <div class="px-4 py-5 bg-white sm:p-6">
                        <div class="mb-4">
                            <img
                                src="@/assets/paypal-logo.svg"
                                alt="Connect with PayPal"
                                class="mb-2 w-24"
                            />
                        </div>
                        <a
                            v-if="paypalSignupLink"
                            data-paypal-button="true"
                            :href="`${paypalSignupLink}&displayMode=minibrowser`"
                            target="PPFrame"
                            type="button"
                            class="items-center px-4 py-2 text-white text-sm font-medium leading-5 hover:bg-orange-500 bg-orange-600 active:bg-orange-700 border focus:border-orange-700 border-transparent rounded-md focus:outline-none focus:shadow-outline-orange"
                            :disabled="isLoading"
                        >
                            Sign up for PayPal
                        </a>

                        <div
                            v-for="paypalInfo in paypalInformations"
                            :key="paypalInfo.name"
                            class="mb-5"
                        >
                            <div
                                class="p-4 bg-blue-50 border border-blue-400 rounded-md"
                            >
                                <div class="flex">
                                    <div class="flex-shrink-0">
                                        <svg
                                            class="w-5 h-5 text-blue-400"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                                clip-rule="evenodd"
                                            />
                                        </svg>
                                    </div>
                                    <div
                                        class="flex-1 ml-3 md:flex md:justify-between"
                                    >
                                        <p
                                            v-linkified:options="{
                                                className: 'underline',
                                                attributes: {
                                                    rel: 'noreferrer noopener'
                                                }
                                            }"
                                            class="text-gray-700 text-sm"
                                        >
                                            {{ paypalInfo.content }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="merchantId && primaryEmail && active">
                            <div class="mb-5">
                                <div
                                    class="p-4 bg-green-50 border border-green-400 rounded-md"
                                >
                                    <div class="flex">
                                        <div class="flex-shrink-0">
                                            <svg
                                                class="w-5 h-5 text-green-400"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                                                    clip-rule="evenodd"
                                                />
                                            </svg>
                                        </div>
                                        <div
                                            class="flex-1 ml-3 md:flex md:justify-between"
                                        >
                                            <p class="text-gray-700 text-sm">
                                                Successfully connected to
                                                PayPal. Merchant ID:
                                                <span class="font-mono">{{
                                                    merchantId
                                                }}</span>
                                                Email:
                                                <span class="font-mono">{{
                                                    primaryEmail
                                                }}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="flex justify-end">
                                <button
                                    @click="toggleShowCapabilities"
                                    type="button"
                                    class="focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 inline-flex items-center px-4 py-2 text-gray-700 text-sm font-medium hover:bg-gray-50 bg-white border border-gray-300 rounded-md focus:outline-none shadow-sm"
                                >
                                    Show connection details
                                </button>
                            </div>
                            <div class="mt-2" v-show="showCapabilities">
                                <table class="table-auto text-gray-400 text-sm">
                                    <thead>
                                        <tr>
                                            <th class="text-left text-gray-600"
                                                >Name</th
                                            >
                                            <th
                                                class="text-center text-gray-600"
                                                >Status</th
                                            >
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="capability in capabilities"
                                            :key="capability.name"
                                        >
                                            <td class="w-3/4 text-left">{{
                                                capability.name
                                            }}</td>
                                            <td class="w-1/4 text-center">{{
                                                capability.status
                                            }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        venueId: {
            type: Number,
            required: true,
            default: 0
        },
        merchantId: {
            type: String,
            required: true,
            default: ''
        },
        active: {
            type: Boolean,
            required: true,
            default: false
        },
        paypalSignupLink: {
            type: String,
            required: true,
            default: ''
        },
        primaryEmail: {
            type: String,
            required: true,
            default: ''
        },
        capabilities: {
            type: Array,
            required: true,
            default: () => []
        },
        paypalInformations: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    data() {
        return {
            showCapabilities: false
        };
    },
    methods: {
        toggleShowCapabilities() {
            this.showCapabilities = !this.showCapabilities;
        }
    }
};
</script>
