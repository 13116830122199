<template>
    <tr class="w-full bg-white">
        <td
            v-tooltip="group.name.length > 25 ? group.name : false"
            class="whitespace-nowrap pl-2 pr-4 py-4 w-2/5 text-gray-400 overflow-hidden text-sm font-medium leading-4 border-b border-gray-200 cursor-move"
        >
            <svg
                class="inline-block w-4 h-4"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                    d="M7 16V4m0 0L3 8m4-4l4 4m6 0v12m0 0l4-4m-4 4l-4-4"
                ></path>
            </svg>
            <span class="inline-block pl-3 text-gray-500 w-full truncate">
                {{ group.name }}
            </span>
        </td>
        <td
            class="whitespace-nowrap px-4 py-4 w-2/5 text-gray-500 truncate text-sm leading-4 border-b border-gray-200"
        >
            <template v-if="group.products && group.products.length > 0">
                <span
                    v-for="product in group.products.slice(0, 3)"
                    :key="product.id"
                >
                    {{ product.name }},
                </span>
                ...
            </template>
        </td>
        <td
            class="whitespace-nowrap px-4 py-4 w-1/5 text-right text-sm font-medium leading-4 border-b border-gray-200"
        >
            <span
                @click="emitGroup(group.id)"
                class="text-indigo-600 hover:text-indigo-900 cursor-pointer"
            >
                Edit
            </span>
        </td>
    </tr>
</template>

<script>
import { ElementMixin } from 'vue-slicksort';

export default {
    name: 'ModifierGroupListItem',
    mixins: [ElementMixin],
    props: ['group'],
    methods: {
        emitGroup(id) {
            this.$parent['$parent'].$emit('edit-group', id);
        }
    }
};
</script>
