var render = function render(){var _vm=this,_c=_vm._self._c;return _c('sliding-sidebar',{attrs:{"title":"Edit product","id":_vm.product.id,"size":"2xl"},on:{"on-escape-key-up":_vm.closeSidebar},model:{value:(_vm.isOpened),callback:function ($$v) {_vm.isOpened=$$v},expression:"isOpened"}},[_c('div',[_c('div',{staticClass:"sm:hidden"},[_c('label',{staticClass:"sr-only",attrs:{"for":"tabs"}},[_vm._v("Select a tab")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentTab),expression:"currentTab"}],staticClass:"focus:outline-none block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-sm focus:border-indigo-500 focus:ring-indigo-500",attrs:{"id":"tabs","name":"tabs"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.currentTab=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.tabs),function(tab){return _c('option',{key:tab.key,domProps:{"value":tab,"selected":tab}},[_vm._v(" "+_vm._s(tab.name)+" "),(
                            tab.key === _vm.TabsEnum.TAGS_N_ALLERGENS.key &&
                            !_vm.isSomeAllergenSelected
                        )?_c('span',[_vm._v(" (*no allergens selected) ")]):_vm._e()])}),0)]),_c('div',{staticClass:"hidden sm:block"},[_c('div',{staticClass:"border-b border-gray-200"},[_c('nav',{staticClass:"-mb-px flex space-x-10",attrs:{"aria-label":"Tabs"}},_vm._l((_vm.tabs),function(tab){return _c('span',{key:tab.name,staticClass:"relative cursor-pointer",class:[
                            tab.key === _vm.currentTab.key
                                ? 'border-indigo-500 text-indigo-600'
                                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                            tab.key === _vm.TabsEnum.DELETE.key
                                ? 'text-red-600'
                                : '',
                            'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium'
                        ],attrs:{"aria-current":tab.key === _vm.currentTab.key ? 'page' : undefined},on:{"click":function($event){return _vm.changeTab(tab)}}},[(
                                tab.key === _vm.TabsEnum.TAGS_N_ALLERGENS.key &&
                                !_vm.isSomeAllergenSelected
                            )?_c('div',{staticClass:"absolute top-0 -right-2 mb-1 inline-flex items-center rounded-full bg-red-100 px-0.5 py-0.5 text-xs font-medium text-red-800"},[_c('svg',{staticClass:"h-3 w-3",attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 24 24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"}})])]):_vm._e(),_vm._v(" "+_vm._s(tab.name)+" ")])}),0)])])]),_c('transition',{attrs:{"name":"fade","mode":"out-in","appear":""}},[(_vm.currentTab.key === _vm.TabsEnum.DETAILS.key)?_c('product-detail-settings',{attrs:{"product":_vm.product,"venue":_vm.venue},on:{"close":_vm.closeSidebar,"update":_vm.saveProduct,"update-categories":_vm.updateCategories}}):(_vm.currentTab.key === _vm.TabsEnum.TAGS_N_ALLERGENS.key)?_c('product-tags-settings',{attrs:{"product":_vm.product},on:{"close":_vm.closeSidebar,"update":_vm.saveProduct}}):(_vm.currentTab.key === _vm.TabsEnum.MODIFIERS.key)?_c('product-modifiers-settings',{attrs:{"product":_vm.product,"venue":_vm.venue},on:{"close":_vm.closeSidebar,"update":_vm.saveProduct,"update-categories":_vm.updateCategories}}):(_vm.currentTab.key === _vm.TabsEnum.VIDEO.key)?_c('product-video-settings',{attrs:{"product":_vm.product},on:{"close":_vm.closeSidebar,"update":_vm.saveProduct,"update-categories":_vm.updateCategories}}):(_vm.currentTab.key === _vm.TabsEnum.DELETE.key)?_c('product-deleting-settings',{attrs:{"product":_vm.product},on:{"close":_vm.closeSidebar,"update":_vm.updateCategories}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }