var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.embedded)?_c('div',{staticClass:"mb-4 print:hidden"},[_c('div',[_c('BasePageTitle',{attrs:{"title":"Sales over time"}})],1),_c('div',{staticClass:"mt-1 flex flex-col sm:mt-2 sm:flex-row sm:flex-wrap"},[_c('PrintButton'),_c('ExportButton',{on:{"clicked":_vm.showExportCsvModal}})],1)]):_vm._e(),(!_vm.embedded)?_c('DateSettings',{attrs:{"byPreOrderDate":_vm.byPreOrderDate},on:{"update-report":_vm.updateReport,"fetch-report-by-pre-order-date":_vm.fetchReportByPreOrderDate}}):_vm._e(),_c('div',{staticClass:"mb-4 overflow-hidden rounded-sm bg-white shadow",class:{ 'overflow-visible rounded-md': _vm.embedded }},[_c('div',{class:{ 'py-2 pl-1 pr-2' : !_vm.embedded }},[(!_vm.isLoading)?_c('apex-chart',{staticClass:"font-sans",class:{ embedded: _vm.embedded },attrs:{"width":"100%","height":_vm.embedded ? '260px' : '350px',"type":"bar","options":_vm.chartOptions,"series":_vm.series}}):_c('div',{staticClass:"spinner",staticStyle:{"height":"275px"}})],1)]),(!_vm.embedded)?_c('div',{staticClass:"mt-8 bg-white shadow"},[(!_vm.isLoading && _vm.reportData && _vm.reportData.length)?_c('vue-good-table',{staticClass:"border-b border-gray-200",attrs:{"isLoading":_vm.isLoading,"styleClass":"tailwind-table gray-subheaders","pagination-options":{
                enabled: true,
                perPage: 100
            },"group-options":{
                enabled: true,
                headerPosition: 'bottom'
            },"columns":_vm.columns,"rows":[
                {
                    day: null,
                    totalOrders: null,
                    grossRevenue: null,
                    grossDiscount: null,
                    children: _vm.reportData || []
                }
            ]},on:{"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},scopedSlots:_vm._u([{key:"table-header-row",fn:function(props){return [(props.column.field === 'day')?_c('span',{staticClass:"text-sm"},[_vm._v(" Summary ")]):_c('span',{staticClass:"text-sm"},[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,false,442786)}):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }