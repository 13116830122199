<template>
    <tr class="mt-2 w-full bg-white">
        <td class="whitespace-nowrap">
            <div class="my-2 pl-3">
                <span class="inline-block cursor-pointer text-sm leading-5">
                    <select
                        v-model.trim="offerSlot.day.$model"
                        id="type"
                        name="type"
                        class="focus:outline-none w-26 inline-block rounded-md border border-gray-300 py-2 pl-3 pr-9 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                    >
                        <option
                            v-for="(day, index) of days"
                            :key="index"
                            :value="day.value"
                            :selected="index === 0"
                        >
                            {{ day.label }}
                        </option>
                    </select>
                </span>
            </div>
        </td>
        <td class="whitespace-nowrap">
            <div class="relative flex flex-row items-center pl-3">
                <vue-timepicker
                    manual-input
                    :minute-interval="15"
                    :id="'hours-' + index"
                    :disabled="false"
                    v-model="offerSlot.startTime.$model"
                    :class="{
                        'opacity-25': false,
                        error: offerSlot.startTime.$error
                    }"
                />
            </div>
        </td>
        <td class="whitespace-nowrap">
            <div class="relative flex flex-row items-center pl-3">
                <vue-timepicker
                    manual-input
                    :minute-interval="15"
                    :id="'hours-' + index"
                    :disabled="false"
                    v-model="offerSlot.endTime.$model"
                    :class="{
                        'opacity-25': false,
                        error: offerSlot.endTime.$error
                    }"
                />
            </div>
        </td>
        <td class="whitespace-nowrap">
            <div class="flex whitespace-nowrap px-2 py-2">
                <div
                    @click="remove(offerSlot)"
                    class="group my-1 cursor-pointer text-gray-400"
                >
                    <svg
                        class="h-5 w-5 group-hover:text-red-400"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                        ></path>
                    </svg>
                </div>
            </div>
        </td>
    </tr>
</template>
<script>
import { FeaturesEnum } from '@/enums';
import VueTimepicker from 'vue2-timepicker';

export default {
    name: 'OfferSlotGroupListOption',
    components: {
        VueTimepicker
    },
    data() {
        return {
            FeaturesEnum,
            days: [
                { value: 0, label: 'Monday' },
                { value: 1, label: 'Tuesday' },
                { value: 2, label: 'Wednesday' },
                { value: 3, label: 'Thursday' },
                { value: 4, label: 'Friday' },
                { value: 5, label: 'Saturday' },
                { value: 6, label: 'Sunday' }
            ]
        };
    },
    props: {
        offerSlot: {
            type: Object,
            default: () => ({}),
            required: true
        },
        index: {
            type: String,
            default: '',
            required: true
        }
    },
    methods: {
        remove(offerSlot) {
            this.$emit('remove', offerSlot.$model);
        }
    }
};
</script>
