var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"md:flex md:items-center md:justify-between"},[_c('div',{staticClass:"min-w-0 flex-1"},[_c('router-link',{staticClass:"mb-2 flex items-center text-sm text-gray-500 inline-flex",attrs:{"to":{ name: 'editVenue' }}},[_c('base-back-icon'),_c('span',[_vm._v("Settings")])],1),_c('base-page-title',{attrs:{"title":"Printers"}})],1),(!_vm.isLoading && _vm.printers && _vm.printers.length)?_c('div',{staticClass:"mr-4 mt-4 flex md:ml-4 md:mt-0"},[_c('router-link',{staticClass:"rounded-md shadow-sm md:ml-3",attrs:{"to":{ name: 'printerSettings' }}},[_c('base-button',{attrs:{"size":"sm","buttonText":"Connect new printer"}})],1)],1):_vm._e(),_c('BaseSpinner',{attrs:{"isLoading":_vm.isLoading}})],1),_c('div',[_c('div',{staticClass:"mt-6 md:grid md:grid-cols-3 md:gap-6"},[_vm._m(0),_c('div',{staticClass:"mt-5 md:col-span-2 md:mt-0"},[_vm._l((_vm.printers),function(printer){return _c('div',{key:printer.id,staticClass:"mb-4 shadow sm:overflow-hidden sm:rounded-md"},[(printer.venues && printer.venues.length)?_c('div',{staticClass:"space-y-6 bg-white px-3 py-4 sm:p-2"},[_c('div',{staticClass:"flex items-center"},[_vm._m(1,true),(printer.printerId)?_c('div',{staticClass:"flex w-full items-center justify-between"},[_c('div',[_c('span',{staticClass:"block text-sm font-semibold text-gray-700"},[_vm._v(" "+_vm._s(printer.venues[0].printerName)+" ")]),_c('span',{staticClass:"font-mono text-xs font-semibold uppercase text-gray-700"},[_vm._v(" "+_vm._s(printer.mac)+" ")]),_c('div',{staticClass:"flex items-center text-sm text-gray-600"},[_c('span',{staticClass:"flex h-3 w-3 animate items-center justify-center rounded-full mr-1",class:{
                                              'bg-red-100': !printer?.status?.Online,
                                              'bg-green-100': printer?.status?.Online
                                          },attrs:{"aria-label":"Online"}},[_c('span',{staticClass:"h-2 w-2 rounded-full",class:{
                                                  'bg-green-400': printer?.status?.Online,
                                                  'bg-red-400': !printer?.status?.Online
                                              }})]),_c('span',[_vm._v(_vm._s(printer?.status?.Online ? 'Online': 'Offline'))]),_c('span',{staticClass:"px-2"},[_vm._v("•")]),_c('span',[_vm._v(" "+_vm._s(printer.venues[0].autoPrint ? 'Auto printing' : 'Manual printing')+" ")]),_c('span',{staticClass:"px-2"},[_vm._v("•")]),_c('span',[_vm._v(" prints "+_vm._s(printer.venues[0] .defaultCopies > 1 ? printer.venues[0] .defaultCopies + ' receipts' : '1 receipt')+" ")])])]),_c('div',{staticClass:"flex items-center"},[_c('div',{on:{"click":function($event){return _vm.testPrinter(printer.id)}}},[(printer.id)?_c('base-printer-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                                                'Print a test receipt'
                                            ),expression:"\n                                                'Print a test receipt'\n                                            "}],staticClass:"cursor-pointer text-gray-400 h-5 w-5",attrs:{"title":"Print a test receipt"}}):_vm._e()],1),_c('router-link',{staticClass:"md:ml-3 mr-2",attrs:{"to":{
                                            name: 'printerSettings',
                                            query: {
                                                mac: printer.mac,
                                                operation: 'edit'
                                            }
                                        }}},[_c('base-button',{attrs:{"buttonText":"Edit Printer","size":"sm","disabled":_vm.isLoading}})],1)],1)]):_c('div',{staticClass:"flex w-full justify-between"},[_c('div',[_c('span',{staticClass:"font-mono text-xs font-semibold uppercase text-gray-800"},[_vm._v(_vm._s(printer.mac))]),_c('span',{staticClass:"block text-sm text-red-600"},[_vm._v(" Pending confirmation ")])]),_c('div',{staticClass:"flex items-center"},[_c('button',{staticClass:"focus:outline-none mr-4 inline-flex items-center rounded-md border border-gray-300 bg-white px-2 py-1 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:px-4 sm:py-2 sm:text-sm",attrs:{"disabled":_vm.isLoading,"type":"button"},on:{"click":function($event){return _vm.confirmPrinter(printer.mac)}}},[_vm._v(" Connect printer ")]),_c('div',{staticClass:"group mr-3 inline-block cursor-pointer text-gray-400",attrs:{"disabled":_vm.isLoading},on:{"click":function($event){return _vm.showDeletePrinterModal(
                                                printer.mac
                                            )}}},[_c('svg',{staticClass:"h-5 w-5 group-hover:text-red-400",attrs:{"fill":"none","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"d":"M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"}})])])])])])]):_vm._e()])}),(!_vm.isLoading && _vm.printers && !_vm.printers.length)?_c('div',{staticClass:"mb-4 flex h-full flex-col items-center justify-center space-y-6 bg-white px-3 py-4 shadow sm:overflow-hidden sm:rounded-md sm:p-2"},[_c('div',{staticClass:"mr-4 mt-4 flex md:ml-4 md:mt-0"},[_c('base-button',{attrs:{"href":"https://commandear.com/products/storekit-configures-star-tsp143iv-receipt-printer","button-text":"Buy a printer","size":"sm"}}),_c('router-link',{staticClass:"md:ml-3",attrs:{"to":{ name: 'printerSettings' }}},[_c('base-button',{attrs:{"button-text":"Connect Printer","size":"sm"}})],1)],1)]):_vm._e()],2)])]),_c('BaseSpacerWithRuler'),_c('DeletePrinterModal',{on:{"fetch-printers":_vm.fetchPrinters}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md:col-span-1"},[_c('div',{staticClass:"sm:pl-0 sm:pr-5"},[_c('h3',{staticClass:"text-base font-semibold leading-6 text-gray-800"},[_vm._v("Integrated printers")]),_c('p',{staticClass:"mt-2 text-sm text-gray-500"},[_vm._v(" Setup compatible receipt and ticket printers to print your orders on-demand or automatically. You can check compatibility and "),_c('a',{staticClass:"underline",attrs:{"href":"https://commandear.com/products/storekit-configures-star-tsp143iv-receipt-printer","target":"_blank","rel":"noopener"}},[_vm._v("buy printers here")]),_vm._v(". ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-shrink-0"},[_c('img',{staticClass:"h-16 p-2 w-auto",attrs:{"width":"64","height":"48","src":"https://ucarecdn.com/0b951f42-eb08-4101-ad07-327a2143592e/-/format/auto/","alt":""}})])
}]

export { render, staticRenderFns }