<template>
    <div class="mt-6">
        <transition name="fade" mode="out-in" appear>
            <ModifierGroupList
                v-if="!isGroupEdited && !isModifierGroupShowed"
                @edit-group="editGroup"
                @existing-group="showModifierGroup"
                @refresh-modifiers="fetchProduct"
                @update="update"
                @close="close"
                :product.sync="product"
            />

            <ExistingModifierGroups
                v-if="isModifierGroupShowed"
                :product.sync="product"
                @stop-editing="isModifierGroupShowed = false"
                @product-added="existingGroupAdded"
            />

            <CreateEditModifierGroup
                v-if="isGroupEdited && !isModifierGroupShowed"
                @stop-editing="isGroupEdited = false"
                @product-updated="productUpdated"
                @group-removed="groupRemoved"
                :modifier-group="selectedModifierGroupId"
                :product.sync="product"
                :venue="venue"
            />
        </transition>
    </div>
</template>

<script>
import ModifierGroupList from '@/components/menuBuilder/ModifierGroupList';
import CreateEditModifierGroup from '@/components/menuBuilder/CreateEditModifierGroup';
import ExistingModifierGroups from '@/components/menuBuilder/ExistingModifierGroups';

export default {
    name: 'ProductModifiersSettings',
    props: {
        product: {
            type: Object,
            required: true
        },
        venue: {
            type: Object,
            required: true
        }
    },
    components: {
        ModifierGroupList,
        CreateEditModifierGroup,
        ExistingModifierGroups
    },
    data() {
        return {
            isLoading: false,
            selectedModifierGroupId: null,
            isModifierGroupShowed: false,
            isGroupEdited: false
        };
    },
    methods: {
        close() {
            this.$emit('close');
        },
        update() {
            this.isGroupEdited = false;
            this.$emit('update');
        },
        groupRemoved() {
            this.fetchProduct();
            this.$emit('update-categories');

            this.isModifierGroupShowed = false;
            this.isGroupEdited = false;
        },
        productUpdated() {
            this.fetchProduct();
            this.$emit('update-categories');
        },
        existingGroupAdded() {
            this.isModifierGroupShowed = false;
            this.fetchProduct();
            this.$emit('update-categories');
        },
        editGroup(id) {
            this.selectedModifierGroupId = id;
            this.isGroupEdited = true;
        },
        showModifierGroup() {
            this.isModifierGroupShowed = true;
        },
        async fetchProduct() {
            this.isLoading = true;

            try {
                const { data } = await this.$axios.get(
                    `/products/${this.product.id}`
                );

                this.$set(this.product, 'modifierGroups', data.modifierGroups);
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        }
    }
};
</script>
