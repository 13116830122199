<template>
  <div>
    <div class="md:flex md:items-center md:justify-between">
      <div class="min-w-0 flex-1">
        <router-link
            :to="{ name: 'editVenue' }"
            class="mb-2 flex items-center text-sm text-gray-500 inline-flex"
        >
          <base-back-icon />
          <span>Settings</span>
        </router-link>
        <base-page-title title="OpenTable Integration" />
      </div>
    </div>
    <div class="mx-auto max-w-7xl pt-8">
      <FeaturePanelWithScreenshot
          v-if="!venue.openTableWebhookEnabled"
          title="Connect with OpenTable"
          subtitle=""
          description="Keep your menus on opentable up to date automatically."
          :features="opentableFeatures"
          image="https://ucarecdn.com/1fe90d5a-7e33-4b25-8234-77361aa75490/-/format/auto/-/resize/1500x/"
      >
        <template #cta>
          <base-button
              class="mt-2 sm:mt-0"
              href="https://storekit.com/our-products/features/smart-reviews"
              buttonText="Connect to opentable"
          >
            <template #left-icon>
              <base-doc-icon class="mr-2 h-4 w-4" />
            </template>
          </base-button>
        </template>
      </FeaturePanelWithScreenshot>
      <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="sm:pl-0 sm:pr-5">
            <h3
                class="text-base font-semibold leading-6 text-gray-800"
            >Details</h3
            >
            <p class="mt-2 text-sm text-gray-500">
              Your store is connected to OpenTable and we'll send any updates to the connected menus to OpenTable automatically.
              <br>
            </p>
          </div>
        </div>
        <div class="mt-5 md:col-span-2 md:mt-0" v-if="!isLoading">
          <div class="shadow sm:overflow-hidden sm:rounded-md">
            <div class="bg-white px-4 py-3 border-b border-gray-100">
              <span class="text-sm font-heading font-medium">Available menus:</span>
            </div>
            <div class="space-y-6 bg-white px-4 py-4">
              <div class="grid grid-cols-3 gap-6">
                <div
                    v-for="menu in venue.menu"
                    :key="menu.id"
                    class="col-span-3 flex items-center justify-between"
                >
                  <span class="text-sm">
                    {{ menu.name }}
                  </span>
                  <div v-if="menu.syndication.includes('openTable')">
                    <span class="flex items-center text-sm font-medium">
                      <span class="mr-2 text-green-600">
                          <base-tick-icon />
                      </span>
                      <span class="hidden sm:inline-block">
                        Connected
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import FeaturePanelWithScreenshot from '@/components/widgets/FeaturePanelWithScreenshot';

export default {
  name: 'OpentableSettings',
  metaInfo: {
    title: 'OpenTable Integration'
  },
  components: {
    FeaturePanelWithScreenshot
  },
  props: ['venueId', 'venue'],
  data() {
    return {
      isLoading: false,
      opentableFeatures: [{
        name: 'Synced menus',
        description: 'Drive more bookings with synced menus. Upload structured menu data into your OpenTable account. '
      },
      {
        name: 'Automatic updates',
        description: 'Whenever a change is made to your menu(s) in storekit, we\'ll send the updates to opentable so they\'re always up to date.'
      }
      ]
    }
  },
  created() {
    this.fetchMenus();
  },
  methods: {
    async updateMenus() {
      try {
        this.isLoading = true;
        await this.$axios.post(`/venues`, {

        })

      }
      catch (e) {
        this.$notify({
          group: 'settings',
          title: 'Sorry, something went wrong.',
          duration: 2000
        });
        throw new Error(e);
      }
      finally {
        this.isLoading = false;
      }
    },
    async fetchMenus() {
      try {
        this.isLoading = true;
        const { data } = await this.$axios.get(`/venues/`)

        this.menus = data;
      }
      catch (e) {
        throw new Error(e)
      }
      finally {
        this.isLoading = false;
      }
    }
  }
};
</script>
