<template>
    <div
        class="flex min-h-screen w-full flex-col justify-start overflow-scroll bg-sk-silver-grey sm:justify-center"
    >
      <div class="mx-auto max-w-7xl px-4 pt-8 sm:px-6 md:px-8 lg:px-8">
        <FeaturePanelWithScreenshot
            title="Do more with storekit plus"
            subtitle="Unlock smart reviews with a subscription"
            :features="features"
            description="The fastest way to increase your star ratings on google, tripadvisor, yelp and more."
            image="https://ucarecdn.com/1fe90d5a-7e33-4b25-8234-77361aa75490/-/format/auto/-/resize/1500x/"
        >
          <template #cta>
            <base-upsell-button
                upsell-item="storekit plus"
                buttonText="Upgrade to storekit plus"
            ></base-upsell-button>
          </template>
        </FeaturePanelWithScreenshot>
      </div>
    </div>
</template>

<script>
import FeaturePanelWithScreenshot from "@/components/widgets/FeaturePanelWithScreenshot.vue";

export default {
    name: 'UpsellScreen',
    components: { FeaturePanelWithScreenshot },
    data() {
      return {
        features: [
          {
            name: 'Custom branding',
            description: ''
          },
          {
            name: 'Lower transaction rates',
            description: ''
          },
          {
            name: 'Smart reviews',
            description: ''
          }
        ]
      }
    }
};
</script>