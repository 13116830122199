<template>
    <modal
        name="menu-sync-modal"
        height="auto"
        width="800px"
        @before-open="beforeOpen"
        @before-close="beforeClose"
        :scrollable="true"
        :reset="true"
    >
        <div
            class="w-full transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 shadow-xl transition-all sm:p-6"
        >
            <div class="sm:flex sm:items-start">
                <div
                    class="mt-3 min-w-full text-center sm:ml-4 sm:mt-0 sm:text-left"
                >
                    <h3 class="text-lg font-medium leading-6 text-gray-900">
                        {{ title }}
                    </h3>
                    <div v-if="isLoading" class="spinner" />
                    <div v-else class="mt-2">
                        <p class="text-sm leading-5 text-gray-500">
                            <br />
                            <draggable
                                v-model="newCategories"
                                handle=".draggable-category"
                            >
                                <div
                                    v-for="category in newCategories"
                                    :key="`${category.id}-category-${key}`"
                                >
                                    <menu-sync-element :element="category">
                                        <draggable
                                            v-model="category.products"
                                            handle=".draggable-category"
                                        >
                                            <div
                                                v-for="product in category.products"
                                                :key="`${product.id}-product-${key}`"
                                            >
                                                <menu-sync-element
                                                    :element="product"
                                                >
                                                    <draggable
                                                        v-model="
                                                            product.modifierGroups
                                                        "
                                                        handle=".draggable-category"
                                                    >
                                                        <div
                                                            v-for="group in product.modifierGroups"
                                                            :key="`${group.id}-group-${key}`"
                                                        >
                                                            <menu-sync-element
                                                                :element="group"
                                                            >
                                                                <draggable
                                                                    v-model="
                                                                        group.products
                                                                    "
                                                                    handle=".draggable-category"
                                                                >
                                                                    <div
                                                                        v-for="modifier in group.products"
                                                                        :key="`${modifier.id}-modifier-${key}`"
                                                                    >
                                                                        <menu-sync-element
                                                                            :element="
                                                                                modifier
                                                                            "
                                                                        >
                                                                        </menu-sync-element>
                                                                    </div>
                                                                </draggable>
                                                            </menu-sync-element>
                                                        </div>
                                                    </draggable>
                                                </menu-sync-element>
                                            </div>
                                        </draggable>
                                    </menu-sync-element>
                                </div>
                            </draggable>
                        </p>
                    </div>
                </div>
            </div>
            <div
                class="mt-5 min-w-full justify-between sm:mt-4 sm:flex sm:pl-4"
            >
                <span class="flex w-full rounded-md shadow-sm sm:w-auto">
                    <button
                        @click="hideModal()"
                        type="button"
                        class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo sm:text-sm sm:leading-5"
                    >
                        Close
                    </button>
                </span>
                <span class="flex w-full rounded-md shadow-sm sm:w-auto">
                    <button
                        @click="syncMenu()"
                        type="button"
                        :disabled="isLoading"
                        class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo sm:text-sm sm:leading-5"
                        :class="{ 'opacity-50': isLoading }"
                    >
                        Confirm
                    </button>
                </span>
            </div>
        </div>
    </modal>
</template>

<script>
import draggable from 'vuedraggable';
import MenuSyncElement from '@/components/MenuSyncElement.vue';
export default {
    name: 'MenuSyncModal',
    components: {
        draggable,
        MenuSyncElement
    },
    data() {
        return {
            title: 'Menu sync',
            oldCategories: null,
            newCategories: null,
            show: true,
            isLoading: true,
            key: 0
        };
    },
    methods: {
        async beforeOpen({ params }) {
            if (params.title) {
                this.title = params.title;
            }
            this.isLoading = true;
            this.oldCategories = params.oldCategories;
            this.newCategories = params.newCategories;
            if (params.provider === 'lightspeed') {
                await this.getLightspeedMenu(params.menuId, params.venueId);
            } else return;

            this.compareCategories();
        },
        async getLightspeedMenu(menuId, venueId) {
            try {
                const { data } = await this.$axios.get(`/ikentoo/fetch-menu`, {
                    params: {
                        venueId,
                        menuId
                    }
                });
                this.newCategories = data;
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        },
        compareCategories() {
            const usedOldCategories = [];
            for (const newCategory of this.newCategories) {
                const oldCategory = this.oldCategories.find(
                    ({ id }) => id === newCategory.id
                );
                newCategory.update = true;
                if (oldCategory) {
                    newCategory.oldIndex = oldCategory.index;
                    newCategory.existedBefore = true;
                    newCategory.oldName = oldCategory.name;
                    const usedOldProducts = [];

                    for (const newProduct of newCategory.products) {
                        const oldProduct = oldCategory.products.find(
                            ({ id }) => id === newProduct.id
                        );

                        newProduct.update = true;

                        if (oldProduct) {
                            newProduct.oldIndex = oldProduct.index;
                            newProduct.existedBefore = true;
                            newProduct.oldName = oldProduct.name;
                            newProduct.oldPrice = oldProduct.price;
                            const usedOldGroups = [];

                            for (const newGroup of newProduct.modifierGroups) {
                                const oldGroup = oldProduct.modifierGroups.find(
                                    ({ id }) => id === newGroup.id
                                );

                                newGroup.update = true;

                                if (oldGroup) {
                                    newGroup.oldIndex = oldGroup.index;
                                    newGroup.existedBefore = true;
                                    newGroup.oldName = oldGroup.name;
                                    const usedOldModifiers = [];

                                    for (const newModifier of newGroup.products) {
                                        const oldModifier = oldGroup.products.find(
                                            ({ id }) => id === newModifier.id
                                        );

                                        newModifier.update = true;

                                        if (oldModifier) {
                                            newModifier.oldIndex =
                                                oldModifier.index;
                                            newModifier.existedBefore = true;
                                            newModifier.oldName =
                                                oldModifier.name;
                                            newModifier.oldPrice =
                                                oldModifier.price;
                                            usedOldModifiers.push(oldModifier);
                                        } else {
                                            newModifier.existedBefore = false;
                                        }
                                    }

                                    for (const oldModifier of oldGroup.products) {
                                        const usedOldModifier = usedOldModifiers.find(
                                            ({ id }) => id === oldModifier.id
                                        );

                                        if (!usedOldModifier) {
                                            const oldModifierExists = newGroup.products.findIndex(
                                                ({ id }) =>
                                                    id === oldModifier.id
                                            );

                                            if (!~oldModifierExists) {
                                                newGroup.products.push({
                                                    ...oldModifier,
                                                    deleted: true,
                                                    update: true
                                                });
                                            }
                                        }
                                    }

                                    usedOldGroups.push(oldGroup);
                                } else {
                                    newGroup.existedBefore = false;
                                    this.setAllSubitemsProperties(
                                        newGroup.products,
                                        { update: true }
                                    );
                                }
                            }

                            for (const oldGroup of oldProduct.modifierGroups) {
                                const usedOldGroup = usedOldGroups.find(
                                    ({ id }) => id === oldGroup.id
                                );

                                if (!usedOldGroup) {
                                    const oldGroupExists = newProduct.modifierGroups.findIndex(
                                        ({ id }) => id === oldGroup.id
                                    );

                                    if (!~oldGroupExists) {
                                        newProduct.modifierGroups.push({
                                            ...oldGroup,
                                            deleted: true,
                                            update: true,
                                            checkGroup: true
                                        });
                                    }
                                }
                            }

                            usedOldProducts.push(oldProduct);
                        } else {
                            newProduct.existedBefore = false;
                            this.setAllSubitemsProperties(
                                newProduct.modifierGroups,
                                { update: true }
                            );
                        }
                    }

                    for (const oldProduct of oldCategory.products) {
                        const usedOldProduct = usedOldProducts.find(
                            ({ id }) => id === oldProduct.id
                        );

                        if (!usedOldProduct) {
                            oldProduct.modifierGroups = this.setAllSubitemsProperties(
                                oldProduct.modifierGroups,
                                { deleted: true, update: true }
                            );
                            const oldProductExists = newCategory.products.findIndex(
                                ({ id }) => id === oldProduct.id
                            );

                            if (!~oldProductExists) {
                                newCategory.products.push({
                                    ...oldProduct,
                                    deleted: true,
                                    update: true
                                });
                            }
                        }
                    }

                    usedOldCategories.push(oldCategory);
                } else {
                    newCategory.existedBefore = false;
                    this.setAllSubitemsProperties(newCategory.products, {
                        update: true
                    });
                }
            }

            for (const oldCategory of this.oldCategories) {
                const usedOldCategory = usedOldCategories.find(
                    ({ id }) => id === oldCategory.id
                );

                if (!usedOldCategory) {
                    oldCategory.products = this.setAllSubitemsProperties(
                        oldCategory.products,
                        { deleted: true, update: true }
                    );

                    const oldCategoryExists = this.newCategories.findIndex(
                        ({ id }) => id === oldCategory.id
                    );

                    if (!~oldCategoryExists) {
                        this.newCategories.push({
                            ...oldCategory,
                            deleted: true,
                            update: true
                        });
                    }
                }
            }
            this.key++;
        },
        setAllSubitemsProperties(array, values) {
            for (const object of array) {
                for (const value in values) {
                    object[value] = values[value];
                }

                if (object.products) {
                    this.setAllSubitemsProperties(object.products, values);
                }

                if (object.modifierGroups) {
                    this.setAllSubitemsProperties(
                        object.modifierGroups,
                        values
                    );
                }
            }

            return array;
        },
        filterMenuToSync(array) {
            array = array.filter(
                ({ deleted, update }) =>
                    (!deleted && update) || (deleted && !update)
            );

            for (const object of array) {
                delete object.update;
                delete object.deleted;
                delete object.printers;
                delete object.archived;
                delete object.oldIndex;
                delete object.oldName;
                delete object.existedBefore;

                if (object.products) {
                    object.products = this.filterMenuToSync(object.products);
                }

                if (object.modifierGroups) {
                    object.modifierGroups = this.filterMenuToSync(
                        object.modifierGroups
                    );
                }
            }
            return array;
        },
        async syncMenu() {
            this.isLoading = true;
            const categoriesToUpdate = this.filterMenuToSync(
                this.newCategories
            );

            try {
                await this.$axios.post(`/menu/sync`, {
                    menuId: this.$route.params.id,
                    menuCategories: categoriesToUpdate
                });

                this.$notify({
                    group: 'settings',
                    duration: 5000,
                    text: 'Menu Synced Successfully'
                });
                this.$emit('fetch-menu');
            } catch (error) {
                this.$notify({
                    group: 'settings',
                    duration: 5000,
                    text: 'Menu Sync Failed - Please Select a Lightspeed Menu'
                });

                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }

            this.hideModal();
        },
        beforeClose() {
            this.title = 'Menu sync';
            this.text = '';
            this.oldCategories = null;
            this.newCategories = null;
        },
        hideModal() {
            this.$emit('fetch-menu');
            this.$modal.hide('menu-sync-modal');
        }
    }
};
</script>
