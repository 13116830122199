<template>
    <div>
        <div class="md:flex md:items-center md:justify-between">
            <div class="min-w-0 flex-1">
                <router-link
                    :to="{ name: 'editVenue' }"
                    class="mb-2 flex items-center text-sm text-gray-500 inline-flex"
                >
                    <base-back-icon />
                    <span>Settings</span>
                </router-link>
                <base-page-title title="Airship Settings" />
            </div>

            <BaseSpinner :isLoading="isLoading" />
        </div>
        <div>
            <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                        >
                            Details
                        </h3>
                        <p class="mt-2 text-sm text-gray-500">
                            Connect to Airship and we'll send customer details,
                            including product level data, into your CRM.
                        </p>
                    </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0">
                    <div
                        v-if="!isLoading"
                        class="bg-white text-left shadow sm:rounded-md"
                    >
                        <div class="p-4 text-left">
                            <span
                                v-if="isAirshipConnected"
                                class="font-medium text-green-600"
                            >
                                Connected to Airship
                            </span>

                            <p v-if="rows && rows.length" class="text-sm">
                                Now you can match Airship units to your stores
                            </p>
                        </div>

                        <div class="space-y-6 bg-white">
                            <div class="flex justify-center">
                                <div
                                    v-if="!isAirshipConnected"
                                    class="col-span-3 pb-3 sm:col-span-2"
                                >
                                    <button
                                        type="button"
                                        class="focus:outline-none inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                                        @click="openAirshipModal"
                                    >
                                        Connect to Airship
                                    </button>
                                </div>
                                <div v-else class="w-full">
                                    <div class="col-span-3 sm:col-span-2">
                                        <vue-good-table
                                            v-if="rows && rows.length"
                                            style-class="tailwind-table"
                                            :isLoading.sync="isLoading"
                                            :columns="columns"
                                            :rows="rows"
                                            class="tailwind-table airship-table bg-white text-sm"
                                        >
                                            <template
                                                slot="table-row"
                                                slot-scope="props"
                                            >
                                                <div
                                                    v-if="
                                                        props.column.field ===
                                                        'venueName'
                                                    "
                                                >
                                                    <multi-select
                                                        v-model="
                                                            rows[props.index]
                                                                .venues
                                                        "
                                                        :options="venues"
                                                        :searchable="false"
                                                        :allow-empty="false"
                                                        :multiple="true"
                                                        :close-on-select="false"
                                                        track-by="id"
                                                        label="name"
                                                        selectLabel="Select"
                                                        deselectLabel="Selected"
                                                        class="airship-multiselect cursor-pointer"
                                                    >
                                                        <template
                                                            slot="selection"
                                                            slot-scope="{
                                                                values
                                                            }"
                                                        >
                                                            <span
                                                                v-if="
                                                                    values.length
                                                                "
                                                                class="ml-1 font-medium text-gray-700"
                                                            >
                                                                {{
                                                                    values.length
                                                                }}
                                                                {{
                                                                    values.length >
                                                                    1
                                                                        ? 'stores'
                                                                        : 'store'
                                                                }}
                                                                selected
                                                            </span>
                                                        </template>
                                                    </multi-select>
                                                </div>
                                            </template>
                                        </vue-good-table>

                                        <div class="p-6 pt-0">
                                            <div
                                                v-if="errorMessage"
                                                class="mt-5 text-right text-xs text-red-600"
                                            >
                                                {{ errorMessage }}
                                            </div>

                                            <div
                                                v-if="rows && rows.length"
                                                class="text-right"
                                                :class="
                                                    errorMessage ? 'mt-5' : ''
                                                "
                                            >
                                                <button
                                                    type="button"
                                                    class="focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                                                    :disabled="isLoading"
                                                    @click="saveMatch"
                                                >
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <airship-connect-modal @close="checkIsAirshipConnected" />

        <div class="hidden sm:block" aria-hidden="true">
            <div class="mt-10 py-5">
                <div class="border-t border-gray-200"></div>
            </div>
        </div>
    </div>
</template>

<script>
import AirshipConnectModal from '@/components/modals/AirshipConnectModal';

export default {
    name: 'AirshipSettings',
    metaInfo: {
        title: 'Airship Settings'
    },
    props: {
        venueId: {
            type: Number,
            required: true
        },
        venue: {
            type: Object,
            required: true
        }
    },
    components: {
        AirshipConnectModal
    },
    data() {
        return {
            isLoading: false,
            columns: [
                {
                    label: 'Airship Unit',
                    field: 'unitId',
                    sortable: false,
                    type: 'string',
                    thClass: 'tailwind-th',
                    tdClass: 'tailwind-td w-1/6 h-20'
                },
                {
                    label: 'Unit Name',
                    field: 'unitName',
                    sortable: false,
                    type: 'string',
                    thClass: 'tailwind-th',
                    tdClass: 'tailwind-td whitespace-normal w-3/6 h-20'
                },
                {
                    label: 'Connected Stores',
                    field: 'venueName',
                    sortable: false,
                    type: 'string',
                    thClass: 'tailwind-th',
                    tdClass: 'tailwind-td w-2/6 h-20'
                }
            ],
            rows: [],
            venues: [],
            errorMessage: '',
            isAirshipConnected: false
        };
    },
    mounted() {
        this.checkIsAirshipConnected();
        this.fetchData();
    },
    methods: {
        openAirshipModal() {
            this.$modal.show('airship-connect-modal', {
                callback: success => {
                    if (success) {
                        this.fetchData();
                    }
                }
            });
        },
        async fetchData() {
            this.isLoading = true;

            await this.fetchUnits();
            await this.fetchVenues();

            this.isLoading = false;
        },
        async fetchUnits() {
            try {
                const {
                    data: { data: units }
                } = await this.$axios.get('/airship/units');

                if (units && units.length) {
                    units.forEach(unit => {
                        if (!unit.is_root) {
                            this.rows.push({
                                unitId: unit.id,
                                unitName: unit.name,
                                venues: []
                            });
                        }
                    });
                }
            } catch (error) {
                throw new Error(`API ${error}`);
            }
        },
        async fetchVenues() {
            try {
                const { data: venues } = await this.$axios.get('/venues');

                venues.forEach(({ id, name, integrations }) => {
                    if (
                        integrations &&
                        integrations.airship &&
                        integrations.airship.unitId
                    ) {
                        const row = this.rows.find(
                            row => row.unitId === integrations.airship.unitId
                        );

                        if (row) {
                            row.venues.push({ id, name });
                        }
                    }
                });

                this.venues = venues.map(venue => {
                    return { id: venue.id, name: venue.name };
                });
            } catch (error) {
                throw new Error(`API ${error}`);
            }
        },
        async saveMatch() {
            this.errorMessage = '';

            const venuesIds = [];
            const payload = [];

            this.rows
                .filter(row => !!row.venues.length)
                .forEach(row => {
                    const rowVenuesIds = row.venues.map(venue => venue.id);

                    const payloadItem = {
                        unitId: row.unitId,
                        unitName: row.unitName,
                        venuesIds: rowVenuesIds
                    };

                    payload.push(payloadItem);

                    venuesIds.push(...rowVenuesIds);
                });

            const duplicates = venuesIds.filter(
                (venueId, index) => venuesIds.indexOf(venueId) !== index
            );

            if (duplicates.length) {
                this.errorMessage =
                    'Venues can be associated with only one unit id';

                return;
            }

            try {
                this.isLoading = true;

                await this.$axios.put('/airship/units', payload);

                this.$notify({
                    group: 'settings',
                    title: 'Settings saved'
                });
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        },
        async checkIsAirshipConnected() {
            const { data } = await this.$axios.get('/account/check', {
                params: {
                    columnName: 'airship'
                }
            });

            this.isAirshipConnected = !data ? false : true;
        }
    }
};
</script>
<style
    src="@/assets/css/components/venue/settings/airship-settings.css"
></style>
