<template>
    <div
        class="flex w-full items-center justify-between pl-1 text-xs font-medium leading-5 tracking-wide text-gray-700"
    >
        <label :for="keyName"> {{ title }} </label>
        <base-small-toggle-switch
            :id="keyName"
            v-model="computedValue"
            @input="onToggleChange"
        />
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        keyName: {
            type: String,
            required: true
        }
    },
    computed: {
        computedValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },

    methods: {
        onToggleChange(value) {
            this.$emit('change', this.keyName, value);
        }
    }
};
</script>
