<template>
    <div class="flex w-full flex-col">
        <label
            class="pl-1 text-xs font-medium leading-5 tracking-wide text-gray-700"
        >
            {{ title }}
        </label>
        <div
            v-if="hasInitValue && !isEditMode"
            class="relative mt-1 flex flex-col w-full justify-center items-center rounded-md border border-dashed border-gray-300 px-6 pb-2 pt-4"
        >
            <div
                class="relative h-20 w-full max-w-md rounded-sm bg-contain bg-center bg-no-repeat"
                :style="`background-image: url('${imageUrl}');`"
            >
            </div>
            <base-button class="mt-2" size="xs" button-text="Change" @clicked="editImage" />
        </div>
        <template v-else>
            <button
                v-if="hasInitValue"
                class="px-2 py-1 text-right text-sm text-blue-700 hover:text-blue-500"
                @click="onCancelImageEdit"
            >
                Cancel
            </button>

            <uploadcare-inline
                class="w-full"
                style="min-height: 142px"
                :ctxName="ctxName"
                inputAcceptTypes="image/*"
                @success="onImageSuccess"
                @files-removed="onImageRemoved"
            />
        </template>
    </div>
</template>

<script>
import UploadcareInline from '@/components/widgets/UploadcareInline';

export default {
    props: {
        title: {
            type: String,
            required: true
        },
        isEditMode: {
            type: Boolean,
            required: true
        },
        imageUrl: {
            type: String,
            required: true
        },
        ctxName: {
            type: String,
            required: true
        },
        keyName: {
            type: String,
            required: true
        },
        hasInitValue: {
            type: Boolean,
            required: true
        }
    },

    components: {
        UploadcareInline
    },

    methods: {
        editImage() {
            this.$emit('edit');
        },

        onCancelImageEdit() {
            this.$emit('cancel-edit', this.keyName);
        },

        onImageSuccess(event) {
            this.$emit('success', this.keyName, event);
        },

        onImageRemoved() {
            this.$emit('image-removed', this.keyName);
        }
    }
};
</script>
