<template>
    <modal
        name="export-csv-modal"
        :adaptive="true"
        :reset="true"
        height="auto"
        width="600px"
        @before-open="beforeOpen"
    >
        <div
            class="transform overflow-hidden rounded-lg bg-white shadow-xl transition-all sm:w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
        >
            <div class="bg-white">
                <div class="px-3 pb-3 pt-4 sm:p-5 sm:pb-3">
                    <div class="flex items-center justify-between">
                        <h3
                            class="text-lg font-medium font-heading text-gray-900"
                        >
                            Export
                            <span v-if="header">
                                {{ header }}
                            </span>
                            <span v-else>your report</span>
                        </h3>
                        <button @click="hideModal">
                            <base-close-icon class="h-6 w-6" />
                        </button>
                    </div>
                </div>
                <div class="border-b border-gray-200" />
                <div class="px-3 pb-3 pt-4 sm:p-5">
                    <div>
                        <LargeRadioButton
                            :options="csvOptions"
                            v-model="isPlainCSV"
                        />
                    </div>
                </div>
            </div>
            <div
                class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6"
            >
                <span class="ml-4 inline-flex rounded-md shadow-sm">
                    <button
                        type="button"
                        @click="exportToCsv"
                        class="focus:outline-none inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                    >
                        Export {{ header }}
                    </button>
                </span>
                <span
                    class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto"
                >
                    <button
                        @click="hideModal"
                        type="button"
                        class="focus:outline-none inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium leading-6 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                    >
                        Cancel
                    </button>
                </span>
            </div>
        </div>
    </modal>
</template>

<script>
import { exportToCsv } from '@/helpers/exportToCsv';
import LargeRadioButton from '../formComponents/LargeRadioButton.vue';

export default {
    name: 'ExportCsvModal',
    components: {
        LargeRadioButton
    },
    data() {
        return {
            header: '',
            csvTitle: '',
            csvData: [],
            isPlainCSV: false,
            fields: [],
            csvOptions: [
                {
                    label:
                        'CSV for Excel, Number, or other spreadsheet programs',
                    value: false
                },
                {
                    label: 'Plain CSV file',
                    value: true
                }
            ],
            multiCsvData: []
        };
    },

    methods: {
        beforeOpen({ params }) {
            this.header = params.header;
            this.csvTitle = params.header;
            this.csvData = params.csvData;
            this.fields = params.fields;
            this.multiCsvData = params.multiCsvData;
        },
        hideModal() {
            this.$modal.hide('export-csv-modal');
        },
        exportToCsv() {
            let csv, isMultiple;

            if (this.multiCsvData?.length >= 1) {
                this.multiCsvData.push({
                    fields: this.fields,
                    name: `_${this.csvTitle}`,
                    transactionsPerDay: this.csvData
                });
                csv = this.multiCsvData;
                isMultiple = true;
            } else {
                csv = this.csvData;
                isMultiple = false;
            }

            exportToCsv(
                csv,
                this.isPlainCSV,
                this.fields,
                isMultiple,
                this.csvTitle
            );

            this.hideModal();
        }
    }
};
</script>
