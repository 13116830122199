<template>
    <sliding-sidebar
        v-model="isOpened"
        title="Edit category"
        :id="category.id"
        size="2xl"
        @on-escape-key-up="closeSidebar"
    >
        <div>
            <div class="sm:hidden">
                <label for="tabs" class="sr-only">Select a tab</label>
                <select
                    id="tabs"
                    name="tabs"
                    v-model="currentTab"
                    class="focus:outline-none block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-sm focus:border-indigo-500 focus:ring-indigo-500"
                >
                    <option
                        v-for="tab in tabs"
                        :key="tab.key"
                        :value="tab"
                        :selected="tab"
                    >
                        {{ tab.name }}
                    </option>
                </select>
            </div>
            <div class="hidden sm:block">
                <div class="border-b border-gray-200">
                    <nav class="-mb-px flex space-x-8" aria-label="Tabs">
                        <span
                            v-for="tab in tabs"
                            :key="tab.name"
                            @click="changeTab(tab)"
                            class="cursor-pointer"
                            :class="[
                                tab.key === currentTab.key
                                    ? 'border-indigo-500 text-indigo-600'
                                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                tab.key === TabsEnum.DELETE.key
                                    ? 'text-red-600'
                                    : '',
                                'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium'
                            ]"
                            :aria-current="
                                tab.key === currentTab.key ? 'page' : undefined
                            "
                        >
                            {{ tab.name }}
                        </span>
                    </nav>
                </div>
            </div>
        </div>
        <transition name="fade" mode="out-in" appear>
            <category-detail-settings
                v-if="currentTab.key === TabsEnum.DETAILS.key"
                :category="category"
                @close="closeSidebar"
                @update="updateCategories"
            />

            <category-printing-settings
                v-else-if="currentTab.key === TabsEnum.PRINTING.key"
                :venue-id="venueId"
                :category="category"
                @close="closeSidebar"
                @update="updateCategories"
            />

            <category-availability-settings
                v-else-if="currentTab.key === TabsEnum.AVAILABILITY.key"
                :category="category"
                @close="closeSidebar"
                @update="updateCategories"
            />

            <category-deleting-settings
                v-else-if="currentTab.key === TabsEnum.DELETE.key"
                :category="category"
                @close="closeSidebar"
                @update="updateCategories"
            />
        </transition>
    </sliding-sidebar>
</template>

<script>
import SlidingSidebar from '../SlidingSidebar';
import CategoryPrintingSettings from './modules/CategoryPrintingSettings';
import CategoryAvailabilitySettings from './modules/CategoryAvailabilitySettings';
import CategoryDeletingSettings from './modules/CategoryDeletingSettings';
import CategoryDetailSettings from './modules/CategoryDetailSettings';

const TabsEnum = Object.freeze({
    DETAILS: { name: 'Details', key: 'details', visible: true },
    PRINTING: { name: 'Printing', key: 'printing', visible: true },
    AVAILABILITY: {
        name: 'Availability',
        key: 'availability',
        visible: true
    },
    DELETE: { name: 'Delete', key: 'delete', visible: true }
});

export default {
    name: 'EditCategorySidebar',
    components: {
        SlidingSidebar,
        CategoryPrintingSettings,
        CategoryAvailabilitySettings,
        CategoryDeletingSettings,
        CategoryDetailSettings
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        category: {
            type: Object,
            required: true
        },
        venueId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            TabsEnum,
            currentTab: TabsEnum.DETAILS,
            tabs: Object.values(TabsEnum),
            isLoading: false
        };
    },
    computed: {
        isOpened: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },
    methods: {
        resetCurrentTab() {
            this.currentTab = TabsEnum.DETAILS;
        },
        changeTab(tab) {
            this.currentTab = tab;
        },
        updateCategories() {
            this.$emit('update');
        },
        closeSidebar() {
            this.resetCurrentTab();
            this.isOpened = false;
        }
    }
};
</script>

<style src="@/assets/css/animations.css" />
