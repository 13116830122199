<template>
    <div class="flex flex-col flex-wrap items-stretch p-1">
        <ul class="max-h-40 list-none overflow-y-scroll">
            <li
                class="basket-item flex flex-auto flex-row items-center justify-between text-gray-700"
                v-for="(item, index) in orderItems"
                :key="'item-' + item.id + '-' + index"
            >
                <div
                    class="mr-2 relative inline-flex select-none flex-row items-center justify-between"
                >
                    <div class="w-6 text-center text-gray-600">
                        {{ item.quantity }}x
                    </div>
                </div>
                <span
                    class="item mr-3 flex flex-auto flex-wrap justify-start text-left"
                >
                    <span class="name flex-auto self-center text-sm">
                        {{ item.name }}
                    </span>
                    <span
                        v-if="item.modifiers"
                        class="variants flex-shrink-0 flex-grow text-xs text-gray-500"
                        style="flex: 1 0 100%"
                    >
                        <span
                            v-for="(modifier, index) in item.modifiers"
                            :key="'modifier-' + item.id + modifier.id + index"
                            class="text-gray-400"
                        >
                            {{ modifier.name
                            }}{{
                                index !== item.modifiers.length - 1 ? ',' : ''
                            }}
                        </span>
                    </span>
                </span>
            </li>
        </ul>
        <div
            v-if="orderNotes"
            class="mt-3 whitespace-pre-line border-t border-dashed border-gray-300 pt-2 text-gray-600"
        >
            <span class="font-medium">Notes:</span>
            <span class="text-gray-500">
                {{ orderNotes }}
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OrderItemsTooltip',
    props: {
        orderItems: {
            type: Array,
            required: true
        },
        orderNotes: {
            type: String,
            required: false,
            default: ''
        }
    }
};
</script>

<style>
.v-popper__popper.order-item-popover .v-popper__wrapper .v-popper__inner {
    width: 250px;
    background: #fff;
    padding: 0.5rem;
    color: black;
    border-radius: 5px;
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
}

.v-popper__popper.order-item-popover .v-popper__arrow-inner {
    border-color: #f9f9f9;
}
</style>
