var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isLoading)?_c('div',{staticClass:"mb-4 overflow-hidden rounded-lg bg-white shadow"},[_c('div',{staticClass:"grid grid-cols-6 gap-3 px-4 py-5 sm:p-6"},[_c('div',{staticClass:"group col-span-6 rounded-sm"},[_c('div',{},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"flex flex-row"},[_c('div',{staticClass:"w-1/2"},[_c('p',{staticClass:"text-sm font-medium text-gray-700"},[_vm._v(" Credit / debit card rate ")]),_c('span',{staticClass:"block py-2 text-sm text-gray-500"},[(_vm.paymentFee)?_c('div',[(Array.isArray(_vm.paymentFee))?_vm._l((_vm.paymentFee),function(fee){return _c('div',{key:fee.fulfillmentMethod},[_vm._v(" "+_vm._s(fee.percent + '%')+" "),(fee.rate)?[_vm._v(" + "+_vm._s(_vm._f("currency")(fee.rate, _vm.venue.currencyCode ))+" "+_vm._s('(' + fee.fulfillmentMethod + ')')+" ")]:_vm._e()],2)}):[_vm._v(" "+_vm._s(_vm.paymentFee.percent + '%')+" "),(_vm.paymentFee.rate)?[_vm._v(" + "+_vm._s(_vm._f("currency")(_vm.paymentFee.rate, _vm.venue.currencyCode ))+" ")]:_vm._e()]],2):_c('div',[_vm._v(" "+_vm._s(_vm.venue.acceptsInStore ? '1.9% + £0.15' : '3.9% + £0.20')+" ")])])])]),_vm._m(2),_c('div',{staticClass:"mt-3 flex justify-end"},[(_vm.account && _vm.account.id && _vm.account.adyenHolder)?_c('div',[_c('router-link',{staticClass:"ml-2",attrs:{"to":{
                                name: 'manageAdyenAccountHolder',
                                params: {
                                    code:
                                        _vm.account.adyenHolder
                                            .accountHolderCode
                                }
                            }}},[_c('button',{staticClass:"focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",attrs:{"type":"button"}},[_vm._v(" Manage account ")])]),(_vm.isFeatureAvailable(_vm.FeaturesEnum.INTERNAL))?_c('div',{staticClass:"mt-2"},[_c('router-link',{attrs:{"to":{
                                    name: 'createAdyenAccount',
                                    query: { newAccount: 'true' }
                                }}},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                                        'Only create a new account if this store belongs to a different company / legal entity'
                                    ),expression:"\n                                        'Only create a new account if this store belongs to a different company / legal entity'\n                                    "}],staticClass:"focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",attrs:{"type":"button"}},[_vm._v(" Create a new account ")])])],1):_vm._e()],1):_vm._e(),(
                            _vm.account &&
                            _vm.account.id &&
                            _vm.account.adyenPlatformHolder
                        )?_c('div',[_c('router-link',{staticClass:"ml-2",attrs:{"to":{
                                name: 'manageAdyenPlatformAccountHolder',
                                params: {
                                    accountHolderId:
                                        _vm.account.adyenPlatformHolder.id
                                }
                            }}},[_c('button',{staticClass:"focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",attrs:{"type":"button"}},[_vm._v(" Manage account ")])]),(_vm.isFeatureAvailable(_vm.FeaturesEnum.INTERNAL))?_c('div',{staticClass:"mt-2"},[_c('router-link',{attrs:{"to":{
                                    name: 'createAdyenAccount',
                                    query: { newAccount: 'true' }
                                }}},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                                        'Only create a new account if this store belongs to a different company / legal entity'
                                    ),expression:"\n                                        'Only create a new account if this store belongs to a different company / legal entity'\n                                    "}],staticClass:"focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",attrs:{"type":"button"}},[_vm._v(" Create a new account ")])])],1):_vm._e(),(_vm.isFeatureAvailable(_vm.FeaturesEnum.INTERNAL))?_c('div',{staticClass:"mt-2"},[_c('router-link',{attrs:{"to":{
                                    name: 'createAdyenPlatformAccount',
                                    query: { newAccount: 'true' }
                                }}},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                                        'Only create a new account if this store belongs to a different company / legal entity'
                                    ),expression:"\n                                        'Only create a new account if this store belongs to a different company / legal entity'\n                                    "}],staticClass:"focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",attrs:{"type":"button"}},[_vm._v(" Create a new platform account ")])])],1):_vm._e()],1):(_vm.accountHolders.length)?_c('div',[_c('router-link',{attrs:{"to":{
                                name: 'createAdyenAccount',
                                query: { newAccount: 'true' }
                            }}},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                                    'Only create a new account if this store belongs to a different company / legal entity'
                                ),expression:"\n                                    'Only create a new account if this store belongs to a different company / legal entity'\n                                "}],staticClass:"focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",attrs:{"type":"button"}},[_vm._v(" Create a new account ")])]),_c('router-link',{attrs:{"to":{
                                name: 'createAdyenPlatformAccount',
                                query: { newAccount: 'true' }
                            }}},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                                    'Only create a new account if this store belongs to a different company / legal entity'
                                ),expression:"\n                                    'Only create a new account if this store belongs to a different company / legal entity'\n                                "}],staticClass:"focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",attrs:{"type":"button"}},[_vm._v(" Create a new platform account ")])]),_c('router-link',{staticClass:"ml-2",attrs:{"to":{
                                name: 'connectAdyenAccount'
                            }}},[_c('button',{staticClass:"focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",attrs:{"type":"button"}},[_vm._v(" Connect an existing account ")])]),_c('router-link',{staticClass:"ml-2",attrs:{"to":{
                                name: 'connectAdyenPlatformAccount'
                            }}},[_c('button',{staticClass:"focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",attrs:{"type":"button"}},[_vm._v(" Connect an existing platform account ")])])],1):_c('div',[_c('router-link',{attrs:{"to":{
                                name: 'createAdyenAccount'
                            }}},[_c('button',{staticClass:"focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",attrs:{"type":"button"}},[_vm._v(" Activate Payments ")])]),(_vm.isFeatureAvailable(_vm.FeaturesEnum.INTERNAL))?_c('div',{staticClass:"mt-2"},[_c('router-link',{attrs:{"to":{
                                  name: 'createAdyenPlatformAccount'
                              }}},[_c('button',{staticClass:"focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",attrs:{"type":"button"}},[_vm._v(" Activate Payments (Balance) ")])])],1):_vm._e()],1)])])])])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3"},[_c('img',{staticClass:"-ml-1 mb-2 w-24",attrs:{"src":"https://ucarecdn.com/27460630-e85a-489c-a724-872f203c0f13/-/format/auto/","alt":"Connect with StoreKit Payments"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"mb-4 text-sm text-gray-800"},[_vm._v(" Connect your store to storekit payments to accept credit/debit card and Apple/Google Pay payments. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"-ml-2 h-12 w-auto",attrs:{"src":"https://ucarecdn.com/d00b3002-e1c8-4eb0-8de4-e7106d74a68e/-/format/auto/","alt":"Accepted payment methods"}})])
}]

export { render, staticRenderFns }