<template>
    <div>
        <div class="md:flex md:items-center md:justify-between">
            <div class="min-w-0 flex-1">
                <router-link
                    :to="{ name: 'editVenue' }"
                    class="mb-2 flex items-center text-sm text-gray-500 inline-flex"
                >
                    <base-back-icon />
                    <span>Settings</span>
                </router-link>
                <base-page-title title="Comtrex Integration" />
            </div>

            <BaseSpinner :isLoading="isLoading" />
        </div>
        <div>
            <div class="mt-4 grid-cols-1 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                        >
                            Details
                        </h3>
                        <p class="mt-2 text-sm text-gray-500">
                            Connect storekit to Comtrex EPOS. When enabled, your
                            orders will be automatically imported into comtrex.
                        </p>
                    </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0" v-if="!isLoading">
                    <div class="shadow sm:overflow-hidden sm:rounded-md">
                        <div class="space-y-6 bg-white px-4 py-5 sm:p-12">
                            <div class="grid grid-cols-1 gap-6 md:grid-cols-3">
                                <div class="sm:col-span-6">
                                    <base-button
                                        @clicked="fetchItems"
                                        buttonText="Fetch Items"
                                    ></base-button>
                                </div>
                                <div class="sm:col-span-6">
                                    <base-input-field
                                        type="url"
                                        prefix="http://"
                                        label="Server IP Address"
                                        tooltip="The static IP address your comtrex server is availalbe to connect to."
                                        id="comtrex-url"
                                        placeholder="1.2.3.4.5:16700"
                                        v-model="comtrex.url"
                                    >
                                    </base-input-field>
                                </div>

                                <div class="sm:col-span-6">
                                    <base-input-field
                                        type="text"
                                        tooltip="The vendor ID provided by Comtrex, this will usually be iNetVendor."
                                        label="Vendor ID"
                                        id="vendor-id"
                                        placeholder="iNetVendor"
                                        v-model="comtrex.vendorId"
                                    >
                                    </base-input-field>
                                </div>

                                <div class="sm:col-span-6">
                                    <base-input-field
                                        type="text"
                                        label="Discount ID"
                                        tooltip="ID of a media type to map order discounts to, must be an open amount discount"
                                        id="discount-id"
                                        v-model="comtrex.discountId"
                                    >
                                    </base-input-field>
                                </div>

                                <div class="sm:col-span-6">
                                    <base-input-field
                                        type="text"
                                        label="Tip ID"
                                        tooltip="ID of a media type to map order tips to"
                                        id="tip-id"
                                        v-model="comtrex.tipId"
                                    >
                                    </base-input-field>
                                </div>

                                <div class="sm:col-span-6">
                                    <base-input-field
                                        type="text"
                                        label="Payment Type ID"
                                        tooltip="ID of a payment type to map storekit payments to"
                                        id="payment-type"
                                        v-model="comtrex.PaymentType"
                                    >
                                    </base-input-field>
                                </div>

                                <div class="sm:col-span-6">
                                    <base-input-field
                                        type="text"
                                        label="Sales Type Number"
                                        tooltip="Sales Type Number - to control how orders are processed on the till"
                                        id="sale-type-number"
                                        v-model="comtrex.SalesTypeNumber"
                                    >
                                    </base-input-field>
                                </div>

                                <div class="sm:col-span-6">
                                    <toggle-switch
                                        id="buffer-orders"
                                        label="Buffer Orders"
                                        tooltip="Orders are buffered in storekit and are only sent to the POS when they need to be prepared. This is calculated using the preparation time set for delivery and pickup orders"
                                        is-disabled
                                        v-model="comtrex.bufferOrders"
                                    />
                                </div>

                                <div class="flex sm:col-span-6">
                                    <toggle-switch
                                        id="integration-status"
                                        label="Integration Status"
                                        extra-text-on="Enabled - sending orders to Comtrex"
                                        extra-text-off="Disabled - not sending orders to Comtrex"
                                        v-model="comtrex.enabled"
                                        @toggle-enable="toggleEnabled"
                                    />
                                </div>

                                <div class="sm:col-span-6">
                                    <toggle-switch
                                        id="send-tip"
                                        label="Send Gratuity Amount"
                                        v-model="comtrex.sendTip"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <BaseSpacerWithRuler />

        <div class="my-6 ml-4 flex flex-shrink-0 justify-end">
            <span
                class="inline-flex rounded-md shadow-sm"
                @click="updateSettings"
            >
                <button
                    type="button"
                    class="focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                    :disabled="isLoading"
                >
                    Save
                </button>
            </span>
        </div>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import ToggleSwitch from '@/components/formComponents/ToggleSwitch';

export default {
    name: 'ComtrexSettings',
    components: { ToggleSwitch },
    metaInfo: {
        title: 'Comtrex Integration'
    },
    props: ['venueId', 'venue'],
    data() {
        return {
            isLoading: false,
            settings: { ...this.venue },
            comtrex: {},
            defaultComtrexSettings: {
                url: '',
                enabled: false,
                SalesTypeNumber: '',
                PaymentType: '',
                tipId: '',
                discountId: '',
                bufferOrders: false,
                vendorId: 'iNetVendor',
                sendTip: false
            },
            lightspeed: {}
        };
    },
    validations: {
        comtrex: {
            url: {
                required
            },
            vendorId: {
                required
            },
            PaymentType: {
                required
            },
            tipId: {
                required
            },
            discountId: {
                required
            },
            enabled: {
                required
            }
        }
    },
    mounted() {
        if (this.settings?.integrations?.comtrex) {
            this.comtrex = this.settings.integrations.comtrex;
        } else {
            this.comtrex = this.defaultComtrexSettings;
        }
    },
    methods: {
        async toggleEnabled() {
            try {
                this.comtrex.enabled = !this.comtrex.enabled;
                await this.$axios.post('/comtrex/toggle', {
                    venueId: this.venueId,
                    enabled: this.comtrex.enabled
                });
            } catch (error) {
                this.comtrex.enabled = !this.comtrex.enabled;
                throw new Error(`API ${error}`);
            }
        },
        async updateSettings() {
            try {
                await this.$axios.put('/comtrex', {
                    venueId: this.venueId,
                    ...this.comtrex
                });
            } catch (error) {
                throw new Error(`API ${error}`);
            }
        },
        async fetchItems() {
            try {
                const { data } = await this.$axios.get(
                    `/comtrex/${this.venueId}/items`,
                    {
                        responseType: 'arraybuffer'
                    }
                );

                const url = window.URL.createObjectURL(
                    new Blob([data], { type: 'application/json' })
                );
                const link = document.createElement('a');
                link.href = url;
                const fileName = `comtrex-sync.json`; // whatever your file name .
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
            } catch (error) {
                throw new Error(`API ${error}`);
            }
        }
    }
};
</script>
