<template>
    <modal
        ref="refundModal"
        name="refund-modal"
        :adaptive="!isLightspeedIOS"
        :scrollable="isLightspeedIOS"
        @before-open="beforeOpen"
        :clickToClose="true"
        height="auto"
        width="600px"
    >
        <div
            v-if="askToShow"
            class="w-full transform overflow-hidden rounded-lg bg-white px-2 py-4 text-center shadow-xl transition-all"
        >
            <h3 class="text-lg font-medium leading-6 text-gray-900">
                Do you want to refund the payment also?
            </h3>

            <div class="mt-2 justify-center p-2 sm:flex sm:flex-row">
                <span
                    class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto"
                    @click="showFullModal"
                >
                    <button
                        type="button"
                        class="focus:outline-none m-2 inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-8 py-2 text-base font-medium leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-green-500 focus:border-green-700 focus:shadow-outline-red sm:text-sm sm:leading-5"
                    >
                        Yes
                    </button>
                </span>
                <span
                    class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto"
                    @click="hideModal"
                >
                    <button
                        type="button"
                        class="focus:outline-none m-2 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-8 py-2 text-base font-medium leading-6 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                    >
                        No
                    </button>
                </span>
            </div>
        </div>
        <div v-else>
            <div
                v-if="isManagerPinRequired && !isManagerPinValid"
                class="px-10 py-6"
            >
                <label
                    for="managerPin"
                    class="flex flex-row font-heading items-center text-base font-semibold text-gray-700"
                >
                    <base-lock-icon />
                    Manager PIN
                </label>
                <p class="mt-1 text-sm text-gray-600"
                    >Enter the 4-digit manager PIN to refund this payment.
                </p>
                <div
                    class="mb-8 mt-8 flex flex-col items-center justify-center"
                    :class="{ 'opacity-50 spinner': isLoading }"
                >
                    <PincodeInput
                        v-model="managerPin"
                        :characterPreview="false"
                        :secure="true"
                    />
                    <div
                        v-if="$v.managerPin.$error || pinError"
                        class="mt-1 text-xs text-red-500"
                    >
                        <span v-if="!$v.managerPin.required">
                            Please enter the manager pin code
                        </span>

                        <span v-else-if="pinError">
                            Sorry, that pin code isn't correct
                        </span>
                    </div>
                </div>
            </div>
            <div
                v-else
                class="w-full transform overflow-hidden rounded-lg bg-white shadow-xl transition-all"
            >
                <div class="px-4 pb-4 pt-5 sm:flex sm:items-start sm:p-6">
                    <div
                        class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
                    >
                        <svg
                            class="h-6 w-6 text-red-600"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                            ></path>
                        </svg>
                    </div>
                    <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <h3 class="text-lg font-medium font-heading leading-6 text-gray-900">
                            Refund {{ isBillPayment ? 'Payment' : 'Order' }}:
                            #{{ refundItem.code ? refundItem.code : refundItem.id }}
                        </h3>
                        <div class="mt-2">
                            <p class="text-sm leading-5 text-gray-500">
                                Refund the full or partial amount paid by card
                                for this
                                {{ isBillPayment ? 'payment' : 'order' }}. This
                                action cannot be reversed. Refunds take 3-5
                                business days to clear back into the customers
                                account.
                            </p>
                            <div class="mt-4 block">
                                <span class="block text-sm text-gray-500">
                                    Full refund
                                </span>
                                <div class="mt-2 flex items-center">
                                    <span
                                        role="checkbox"
                                        tabindex="0"
                                        aria-checked="false"
                                        @click="toggleFullRefund"
                                        :class="{
                                            'bg-gray-200': !fullRefund,
                                            'bg-indigo-600': fullRefund
                                        }"
                                        class="focus:outline-none relative inline-block h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:shadow-outline"
                                    >
                                        <span
                                            aria-hidden="true"
                                            :class="{
                                                'translate-x-5': fullRefund,
                                                'translate-x-0': !fullRefund
                                            }"
                                            class="inline-block h-5 w-5 translate-x-0 transform rounded-full bg-white shadow transition duration-200 ease-in-out"
                                        ></span>
                                    </span>
                                </div>
                            </div>

                            <div class="flex w-full flex-col">
                                <label
                                    for="refundInput"
                                    class="mt-4 block text-sm font-medium leading-5 text-gray-700"
                                >
                                    Refund Amount
                                </label>

                                <div
                                    class="relative mt-1 rounded-md shadow-sm"
                                    v-if="currency"
                                >
                                    <div
                                        class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                                    >
                                        <span
                                            class="text-gray-500 sm:text-sm sm:leading-5"
                                        >
                                            {{ currency.symbol }}
                                        </span>
                                    </div>
                                    <currency-input
                                        id="refundInput"
                                        v-model="refundAmount"
                                        class="form-input block w-full pl-7 pr-12 sm:text-sm sm:leading-5"
                                        placeholder="0.00"
                                        :currency="null"
                                        locale="en-GB"
                                        :value-as-integer="true"
                                        :allow-negative="false"
                                        :class="{
                                            'border-red-300':
                                                $v.refundAmount.$error
                                        }"
                                    />
                                    <div
                                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
                                    >
                                        <span
                                            class="text-gray-500 sm:text-sm sm:leading-5"
                                        >
                                            {{ currency.ISO }}
                                        </span>
                                    </div>
                                </div>
                                <p
                                    v-if="$v.refundAmount.$error"
                                    class="mt-1 text-xs text-red-400"
                                >
                                    <span>
                                        Maximum refund amount:
                                        {{ (maxRefundAmount / 100) | currency }}
                                    </span>
                                </p>
                                <div class="mt-4">
                                    <label
                                        for="reason"
                                        class="block text-sm font-medium leading-5 text-gray-700"
                                    >
                                        Select reason for refund
                                    </label>
                                    <div class="rounded-md shadow-sm">
                                        <select
                                            v-model.trim="refundOption"
                                            id="refundReason"
                                            class="focus:outline-none mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                                        >
                                            <option :value="null">
                                                No reason
                                            </option>
                                            <option
                                                v-for="(
                                                    value, key
                                                ) of RefundReasonsEnum.dictionary"
                                                :key="key"
                                                :value="key"
                                            >
                                                {{ value }}
                                            </option>
                                        </select>

                                        <div
                                            v-if="
                                                refundOption ===
                                                RefundReasonsEnum.OTHER
                                            "
                                        >
                                            <label
                                                for="otherReason"
                                                class="mt-4 block text-sm font-medium leading-5 text-gray-700"
                                            >
                                                Add reason for refund
                                            </label>

                                            <textarea
                                                v-model="refundReason"
                                                id="otherReason"
                                                rows="1"
                                                class="form-input mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                                                placeholder=""
                                            ></textarea>
                                        </div>
                                    </div>
                                    <p class="mt-2 text-sm text-gray-500">
                                        Only you and other staff can see this
                                        reason.
                                    </p>
                                </div>
                                <base-checkbox
                                    v-if="
                                        refundItem.customer?.piggy?.credits > 0
                                    "
                                    class="mt-2"
                                    v-model="revokeLoyaltyPointsCheckbox"
                                    id="revoke-loyalty-points"
                                    label="Loyalty"
                                    caption="Revoke loyalty points for this transaction"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6"
                >
                    <span
                        class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto"
                        @click="refund"
                    >
                        <button
                            type="button"
                            :disabled="
                                refundAmount === 0 ||
                                maxRefundAmount === 0 ||
                                isLoading
                            "
                            :class="{
                                'spinner-white spinner': isLoading,
                                'cursor-not-allowed bg-gray-100 opacity-50':
                                    refundAmount === 0 || maxRefundAmount === 0
                            }"
                            class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-red-500 focus:border-red-700 focus:shadow-outline-red sm:text-sm sm:leading-5"
                        >
                            Refund {{ (refundAmount / 100) | currency }}
                        </button>
                    </span>
                    <span
                        class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto"
                        @click="hideModal"
                    >
                        <button
                            type="button"
                            class="focus:outline-none inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium leading-6 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                        >
                            Cancel
                        </button>
                    </span>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import PincodeInput from 'vue-pincode-input';
import { RefundReasonsEnum } from '@/enums';
import { mapGetters } from 'vuex';
import { requiredIf, maxValue } from 'vuelidate/lib/validators';
import { browserName, isMobileSafari } from 'mobile-device-detect';

export default {
    name: 'RefundModal',
    components: {
        PincodeInput
    },
    created() {
        if (this.isLightspeedIOS) {
            window.addEventListener("resize", this.onWindowResizeHandler);
        }
    },
    computed: {
        ...mapGetters({ currency: 'user/currencySettings' }),
        isLightspeedIOS() {
            return isMobileSafari && browserName === 'WebKit';
        },
        refundAmountRange() {
            const max = this.refundItem.total / 100;

            return {
                min: 0,
                max
            };
        },
        isManagerPinRequired() {
            return (
                this.refundItem &&
                this.refundItem.venue &&
                this.refundItem.venue.managerPin
            );
        },
        refundValidation() {
            if (this.refundAmount > this.maxRefundAmount) {
                return this.maxRefundAmount;
            }

            return this.refundAmount;
        }
    },
    props: {
        isBillPayment: {
            type: Boolean,
            default: false
        }
    },
    validations() {
        return {
            managerPin: {
                required: requiredIf(function () {
                    return this.isManagerPinRequired;
                })
            },
            refundAmount: {
                maxValue: maxValue(this.refundValidation)
            }
        };
    },
    data() {
        return {
            refundItem: {},
            refundAmount: 0,
            isLoading: false,
            fullRefund: false,
            refundOption: null,
            refundReason: '',
            askToShow: false,
            managerPin: '',
            pinError: false,
            isManagerPinValid: false,
            RefundReasonsEnum,
            maxRefundAmount: 0,
            revokeLoyaltyPointsCheckbox: true
        };
    },
    watch: {
        refundAmount(value) {
            this.fullRefund = value === this.refundItem.total;
        },
        managerPin(value, oldValue) {
            if (value.length === 4 && oldValue.length === 3) {
                this.checkPin();
            }
        }
    },
    methods: {
        onWindowResizeHandler() {
            setTimeout(() => {
                this.$refs.refundModal.onWindowResize();
            },50);
        },
        beforeOpen({ params }) {
            Object.assign(this.$data, this.$options.data.apply(this));
            this.refundItem = params.item;
            this.askToShow = params.askToShow;

            this.checkMaxRefundAmount();
        },
        hideModal() {
            this.$modal.hide('refund-modal');
        },
        toggleFullRefund() {
            this.fullRefund = !this.fullRefund;
            this.refundAmount = this.refundItem.total;
        },
        async checkPin() {
            try {
                this.isLoading = true;
                const { data } = await this.$axios.post(
                    `/venues/${this.refundItem.venueId}/manager-pin-check`,
                    {
                        pinCode: this.managerPin
                    }
                );

                this.pinError = true;
                this.isManagerPinValid = data;
            } catch (error) {
                throw new Error(error);
            } finally {
                this.isLoading = false;
            }
        },
        async refund() {
            this.pinError = false;
            this.$v.$touch();

            if (this.$v.$invalid) {
                return false;
            }

            if (!this.refundAmount) {
                return;
            }

            this.isLoading = true;
            const payload = {
                amount: this.refundAmount,
                reason:
                    this.refundOption &&
                    this.refundOption !== RefundReasonsEnum.OTHER
                        ? this.refundOption
                        : this.refundReason,
                managerPin: this.managerPin
            };

            try {
                const collection = this.isBillPayment ? 'bills' : 'orders';

                await this.$axios.put(
                    `/${collection}/${this.refundItem.id}/refund`,
                    payload
                );

                if (
                    this.revokeLoyaltyPointsCheckbox &&
                    this.refundItem.customer?.piggy?.credits > 0
                ) {
                    await this.reversePiggyTransaction();
                }

                this.$notify({
                    group: 'settings',
                    title: 'Refund queued successfully'
                });

                this.$emit('refund');

                this.hideModal();
            } catch (error) {
                if (
                    error.response?.data?.message?.includes('wrong_manager_pin')
                ) {
                    this.isManagerPinValid = false;
                    this.pinError = true;
                    this.$notify({
                        group: 'settings',
                        title: 'Sorry, the manager PIN is incorrect'
                    });
                } else {
                    this.$notify({
                        group: 'settings',
                        title: 'An error occurred'
                    });
                }

                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
                this.$v.$reset();
            }
        },
        showFullModal() {
            this.askToShow = false;
        },
        async checkMaxRefundAmount() {
            const column = this.isBillPayment ? 'billPaymentId' : 'orderId';

            try {
                const { data } = await this.$axios.post(
                    `/refund/max-refund-amount`,
                    {
                        id: this.refundItem.id,
                        column,
                        total: this.refundItem.total
                    }
                );

                this.maxRefundAmount = data;
            } catch (error) {
                throw new Error(`API ${error}`);
            }
        },
        async reversePiggyTransaction() {
            const type = this.isBillPayment ? 'billId' : 'id';

            try {
                await this.$axios.post(`/piggy/reverse-transaction`, {
                    id: this.refundItem.id,
                    type,
                    credits: Math.floor(this.refundAmount / 100)
                });
            } catch (error) {
                throw new Error(`API ${error}`);
            }
        }
    }
};
</script>

<style>
input.vue-pincode-input {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 5px;
    box-shadow: none;
    border: 3px solid rgb(53 53 53 / 14%);
    background-color: rgba(243, 243, 240);
    max-width: 60px;
    font-size: 1.8rem;
    border-radius: 5px;
}
input.vue-pincode-input:focus {
    box-shadow: none;
    border-color: rgba(17, 24, 39);
}
</style>

<style scoped>
.spinner-white::after {
    border: 2px solid #fff;
    border-right-color: transparent;
    border-top-color: transparent;
}
</style>
