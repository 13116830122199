var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fieldset',[_c('legend',{staticClass:"sr-only"}),_c('div',{staticClass:"bg-white rounded-md -space-y-px"},_vm._l((_vm.options),function(option,index){return _c('div',{key:index,staticClass:"relative flex p-4 border",class:{
                'rounded-tl-md rounded-tr-md': index === 0,
                'rounded-bl-md rounded-br-md': index === _vm.options.length - 1,
                'bg-indigo-50 border-indigo-200 z-10':
                    option.value === _vm.value,
                'border-gray-200': option.value !== _vm.value
            }},[_c('div',{staticClass:"flex items-center h-5"},[_c('input',{staticClass:"focus:ring-indigo-500 w-4 h-4 text-indigo-600 border border-gray-300 cursor-pointer",attrs:{"id":option.value + '-' + index,"name":option.value + '-' + index,"type":"radio"},domProps:{"value":option.value,"checked":option.value === _vm.value},on:{"change":function($event){return _vm.updateValue(option.value)}}})]),_c('label',{staticClass:"flex flex-col ml-3 cursor-pointer",attrs:{"for":option.value + '-' + index}},[_c('span',{staticClass:"block text-sm font-medium",class:{
                        'text-indigo-900': option.value === _vm.value,
                        'text-gray-900': option.value !== _vm.value
                    }},[_vm._v(" "+_vm._s(option.label)+" ")]),(option.labelDescription)?_c('span',{staticClass:"block text-sm",class:{
                        'text-indigo-700': option.value === _vm.value,
                        'text-gray-500': option.value !== _vm.value
                    }},[_vm._v(" "+_vm._s(option.labelDescription)+" ")]):_vm._e()])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }