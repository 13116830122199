<template>
    <base-button
       @clicked="launch"
      :button-text="buttonText"
      size="sm"
    />
</template>

<script>
import { mapGetters } from 'vuex';
import FlatfileImporter from '@flatfile/adapter';
import dateValidator from '@/helpers/dateValidator';

export default {
    props: {
        buttonText: {
            type: String,
            required: false,
            default: 'Import'
        },
        buttonStyle: {
            type: String,
            required: false,
            default:
                'inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-xs sm:text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
        },
        status: {
            type: String,
            required: false,
            default: 'pending'
        },
        type: {
            type: String,
            required: true,
            default: ''
        },
        fields: {
            type: Array,
            required: true,
            default: () => [{}]
        },
        title: {
            type: String,
            required: false,
            default: 'Bulk add'
        },
        allowInvalidSubmit: {
            type: Boolean,
            required: false,
            default: false
        },
        managed: {
            type: Boolean,
            required: false,
            default: false
        },
        disableManualInput: {
            type: Boolean,
            reuired: false,
            default: false
        },
        forceManualInput: {
            type: Boolean,
            required: false,
            default: false
        },
        allowCustom: {
            type: Boolean,
            required: false,
            default: false
        },
        maxRecords: {
            type: Number,
            required: false,
            default: null
        },
        displayEncoding: {
            type: Boolean,
            required: false,
            default: false
        },
        i18nOverrides: {
            type: Number,
            required: false,
            default: null
        },
        theme: {
            type: Object,
            required: false,
            default: () => ({})
        },
        checkDate: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            importer: new FlatfileImporter(
                process.env.VUE_APP_FLAT_FILE_LICENSE_KEY,
                {
                    type: this.type,
                    fields: this.fields,
                    title: this.title,
                    allowInvalidSubmit: this.allowInvalidSubmit,
                    managed: this.managed,
                    disableManualInput: this.disableManualInput,
                    forceManualInput: this.forceManualInput,
                    allowCustom: this.allowCustom,
                    // maxRecords: null,
                    devMode: process.env.NODE_ENV !== 'production',
                    displayEncoding: this.displayEncoding,
                    // i18nOverrides: this.i18nOverrides,
                    theme: this.theme
                }
            ),
            results: null
        };
    },
    computed: {
        ...mapGetters({ currentUser: 'user/currentUser' })
    },

    beforeDestroy() {
        this.importer.destroy();
    },

    watch: {
        status: function (value) {
            if (value === 'success') {
                this.importer.displaySuccess('Success!');
            } else if (value === 'failed') {
                this.importer.displaySuccess('Error, please try again later.');
            }
        }
    },

    methods: {
        launch() {
            const $v = this;
            this.importer.setCustomer({
                userId: this.currentUser.id,
                email: this.currentUser.email,
                name: this.currentUser.name
            });

            if (this.checkDate) {
                this.dateValidation();
            }

            this.importer
                .requestDataFromUser()
                .then(function (results) {
                    $v.results = results.validData;

                    $v.submit();
                })
                .catch(function (error) {
                    if (error) {
                        throw new Error(`${error}`);
                    }
                });
        },
        submit() {
            this.importer.displayLoader();

            this.$emit('csvImport', this.results);
        },
        dateValidation() {
            this.importer.registerRecordHook(record => {
                let { startDate, endDate } = record;
                let out = {};

                if (startDate.length === 0 && endDate.length === 0) {
                    return out;
                }

                out = {
                    startDate: {
                        info: []
                    },
                    endDate: {
                        info: []
                    }
                };

                startDate = this.$moment(startDate).format('YYYY-MM-DD HH:mm');
                endDate = this.$moment(endDate).format('YYYY-MM-DD HH:mm');

                const formatDate = dateValidator.checkDateFormat(
                    startDate,
                    endDate
                );

                const dateRange = dateValidator.checkDateRange(
                    startDate,
                    endDate
                );

                const selectedStartDate = dateValidator.checkSelectedStartDate(
                    startDate
                );

                out.startDate.info.push(
                    formatDate.startDate,
                    dateRange.startDate,
                    selectedStartDate.startDate
                );
                out.endDate.info.push(formatDate.endDate, dateRange.endDate);

                return out;
            });
        }
    }
};
</script>
