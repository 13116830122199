<template>
  <div class="flex flex-row bg-blue-50 border border-blue-200 rounded-md shadow-sm p-3">
    <div>
      <img :src="image">
    </div>
    <div class="flex flex-col">
      <span class="text-gray-700 text-sm font-medium">{{ title }}</span>
      <span class="text-gary-700 text-xs">{{ description }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseEmbeddedTip',
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    video: {
      type: String,
      required: false
    },
    image: {
      type: String,
      required: false
    }
  }
};
</script>

<style scoped></style>
