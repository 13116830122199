<template>
    <div>
        <div class="md:flex md:items-center md:justify-between">
            <div class="min-w-0 flex-1">
                <router-link
                    :to="{ name: 'editVenue' }"
                    class="mb-2 flex inline-flex items-center text-sm text-gray-500"
                >
                    <base-back-icon />
                    <span>Settings</span>
                </router-link>
                <base-page-title title="Tissl Integration" />
            </div>

            <BaseSpinner :isLoading="isLoading" />
        </div>
        <div>
            <div class="mt-4 grid-cols-1 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                        >
                            Details
                        </h3>
                        <p class="mt-2 text-sm text-gray-500">
                            Connect storekit to Tissl EPOS. When enabled, your
                            orders will be automatically imported into tissl.
                        </p>
                    </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0" v-if="!isLoading">
                    <div class="shadow sm:overflow-hidden sm:rounded-md">
                        <div class="space-y-6 bg-white px-4 py-5 sm:p-12">
                            <div class="grid grid-cols-1 gap-6 md:grid-cols-3">
                                <div class="sm:col-span-6">
                                    <base-button
                                        @clicked="fetchItems"
                                        buttonText="Fetch Items"
                                    ></base-button>
                                </div>

                                <div class="sm:col-span-6">
                                    <base-input-field
                                        type="text"
                                        label="Organisation ID"
                                        id="organisationId"
                                        :value="
                                            $store.state.user.profile
                                                ?.accounts[0]?.tissl
                                                ?.organisationId
                                        "
                                        disabled
                                    >
                                    </base-input-field>
                                </div>

                                <div class="col-span-6">
                                    <label
                                        for="site"
                                        class="mb-1 flex items-center text-sm font-medium leading-5 text-gray-700"
                                    >
                                        Site
                                        <base-question-icon
                                            class="ml-1"
                                            v-tooltip="
                                                'The site ID provided by tissl'
                                            "
                                        />
                                    </label>
                                    <div class="relative mt-1 rounded-md">
                                        <select
                                            id="site"
                                            name="site"
                                            v-model="tissl.siteId"
                                            class="focus:outline-none form-input mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        >
                                            <option
                                                v-for="(site, index) in this
                                                    .tisslOptions.sites"
                                                :key="`site-${index}`"
                                                :value="site.id"
                                            >
                                                {{ site.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-span-6">
                                    <label
                                        for="paymentType"
                                        class="mb-1 flex items-center text-sm font-medium leading-5 text-gray-700"
                                    >
                                        Payment Type
                                        <base-question-icon
                                            class="ml-1"
                                            v-tooltip="
                                                'Name of a payment type to map storekit payments to'
                                            "
                                        />
                                    </label>
                                    <div class="relative mt-1 rounded-md">
                                        <select
                                            id="paymentType"
                                            name="paymentType"
                                            v-model="tissl.paymentType"
                                            class="focus:outline-none form-input mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        >
                                            <option
                                                v-for="(
                                                    paymentType, index
                                                ) in this.tisslOptions
                                                    .paymentTypes"
                                                :key="`payment-type-${index}`"
                                                :value="paymentType"
                                            >
                                                {{ paymentType.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-span-6">
                                    <label
                                        for="course"
                                        class="mb-1 flex items-center text-sm font-medium leading-5 text-gray-700"
                                    >
                                        Course
                                    </label>
                                    <div class="relative mt-1 rounded-md">
                                        <select
                                            id="course"
                                            name="course"
                                            v-model="tissl.course"
                                            class="focus:outline-none form-input mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        >
                                            <option
                                                v-for="(course, index) in this
                                                    .tisslOptions.courses"
                                                :key="`course-${index}`"
                                                :value="course"
                                            >
                                                {{ course.description }}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div class="col-span-6">
                                    <label
                                        for="orderType"
                                        class="mb-1 flex items-center text-sm font-medium leading-5 text-gray-700"
                                    >
                                        Order Type
                                    </label>
                                    <div class="relative mt-1 rounded-md">
                                        <select
                                            id="orderType"
                                            name="orderType"
                                            v-model="tissl.orderType"
                                            class="focus:outline-none form-input mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        >
                                            <option
                                                v-for="(
                                                    orderType, index
                                                ) in this.tisslOptions
                                                    .orderTypes"
                                                :key="`payment-type-${index}`"
                                                :value="orderType"
                                            >
                                                {{ orderType.description }}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div class="sm:col-span-6">
                                    <toggle-switch
                                        id="buffer-orders"
                                        label="Buffer Orders"
                                        tooltip="Orders are buffered in storekit and are only sent to the POS when they need to be prepared. This is calculated using the preparation time set for delivery and pickup orders"
                                        v-model="tissl.bufferOrders"
                                    />
                                </div>

                                <div class="flex sm:col-span-6">
                                    <toggle-switch
                                        id="integration-status"
                                        label="Integration Status"
                                        extra-text-off="Disabled - not sending orders to Tissl"
                                        extra-text-on="Enabled - sending orders to tissl"
                                        v-model="tissl.enabled"
                                        @toggle-enable="toggleEnabled"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <BaseSpacerWithRuler />

        <div class="my-6 ml-4 flex flex-shrink-0 justify-end">
            <span
                class="inline-flex rounded-md shadow-sm"
                @click="updateSettings"
            >
                <button
                    type="button"
                    class="focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                    :disabled="isLoading"
                >
                    Save
                </button>
            </span>
        </div>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import ToggleSwitch from '@/components/formComponents/ToggleSwitch';
import { mapActions } from 'vuex';

export default {
    name: 'TisslSettings',
    components: { ToggleSwitch },
    metaInfo: {
        title: 'Tissl Integration'
    },
    props: ['venueId', 'venue'],
    data() {
        return {
            isLoading: false,
            tisslOptions: {},
            settings: { ...this.venue },
            tissl: {},
            defaultTisslSettings: {
                enabled: false,
                bufferOrders: false,
                removeEmail: false,
                deliveryFee: {
                    name: '',
                    productId: ''
                },
                discount: {
                    name: '',
                    productId: ''
                },
                paymentType: {
                    name: '',
                    paymentId: ''
                },
                orderType: {
                    description: '',
                    orderTypeId: ''
                },
                course: {
                    courseId: '',
                    description: ''
                },
                siteId: ''
            }
        };
    },
    validations: {
        tissl: {
            enabled: {
                required
            }
        }
    },
    beforeRouteLeave(to, from, next) {
        this.fetchVenues();

        return next();
    },
    mounted() {
        if (this.settings?.integrations?.tissl) {
            this.tissl = this.settings.integrations.tissl;
        } else {
            this.tissl = this.defaultTisslSettings;
        }

        if (this.venue?.posId) {
            this.tissl.siteId = this.venue.posId;
        }

        this.fetchSettings();
    },
    methods: {
        ...mapActions({
            fetchVenues: 'venues/fetch'
        }),
        async toggleEnabled() {
            try {
                this.tissl.enabled = !this.tissl.enabled;
                await this.$axios.post('/tissl/toggle', {
                    venueId: this.venueId,
                    enabled: this.tissl.enabled
                });

                this.$notify({
                    group: 'settings',
                    title: 'Integration status updated'
                });
            } catch (error) {
                this.$notify({
                    group: 'settings',
                    title: 'Something went wrong'
                });

                this.tissl.enabled = !this.tissl.enabled;
                throw new Error(`API ${error}`);
            }
        },
        async updateSettings() {
            try {
                await this.$axios.put('/tissl/update', {
                    venueId: this.venueId,
                    ...this.tissl
                });

                this.$notify({
                    group: 'settings',
                    title: 'Settings saved'
                });
            } catch (error) {
                this.$notify({
                    group: 'settings',
                    title: 'Something went wrong'
                });

                throw new Error(`API ${error}`);
            }
        },
        async fetchItems() {
            try {
                const { data } = await this.$axios.get(
                    `/tissl/${this.venueId}/items`,
                    {
                        responseType: 'arraybuffer'
                    }
                );

                const url = window.URL.createObjectURL(
                    new Blob([data], { type: 'application/json' })
                );
                const link = document.createElement('a');
                link.href = url;
                const fileName = `tissl-sync.json`; // whatever your file name .
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
            } catch (error) {
                throw new Error(`API ${error}`);
            }
        },
        async fetchSettings() {
            try {
                const { data } = await this.$axios.get(
                    `/tissl/${this.venueId}/settings`
                );
                this.tisslOptions = data;
            } catch (error) {
                this.$notify({
                    group: 'settings',
                    title: 'Something went wrong'
                });

                throw new Error(`API ${error}`);
            }
        }
    }
};
</script>
