<template>
    <v-dropdown
        @show="opened()"
        distance="16"
        :delay="300"
        :triggers="['click']"
        popperClass="customer-details-popover"
    >
        <div
            class="group flex items-center px-4 py-1 hover:bg-gray-100 rounded-md cursor-pointer select-none"
        >
            <div class="flex flex-col">
                <span class="block mr-2" v-if="name">
                    {{ name }}
                </span>
                <span class="block mr-2" v-if="email && showEmail">
                    {{ email }}
                </span>
                <span class="block mr-2" v-if="phone && showPhone">
                    {{ phone }}
                </span>
            </div>
            <span
                class="block m-auto w-4 h-4 text-gray-600 opacity-0 group-hover:opacity-100"
                ><svg
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    focusable="false"
                    aria-hidden="true"
                >
                    <path d="M5 8l5 5 5-5H5z"></path></svg
            ></span>
        </div>

        <template #popper>
            <CustomerDetailsTooltip v-if="open && email" :customer-email="email" />
        </template>
    </v-dropdown>
</template>

<script>
import CustomerDetailsTooltip from './CustomerDetailsTooltip';
export default {
    name: 'CustomerDetailsTableItem',
    props: {
        email: {
            type: String,
            required: false
        },
        name: {
            type: String,
            required: false
        },
        phone: {
            type: String,
            required: false
        },
        showEmail: {
            type: Boolean,
            required: false,
            default: true
        },
        showPhone: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    components: {
        CustomerDetailsTooltip
    },
    data() {
        return {
            open: false
        };
    },
    methods: {
        opened() {
          if(this.email) {
            this.open = true;
          }
        }
    }
};
</script>
