<template>
    <sliding-sidebar
        v-model="isOpened"
        title="All orders filters"
        @on-escape-key-up="close"
    >
        <div>
            <div class="bg-white">
                <div class="relative mt-3 text-center sm:mt-0 sm:text-left">
                    <div
                        class="relative mb-3 inline-block w-full text-left md:mb-0"
                    >
                        <div class="mb-2">
                            <span class="rounded-md shadow-sm">
                                <venue-selector
                                    ref="venueSelector"
                                    class="w-full"
                                    v-if="venues.length"
                                    :venues="venues"
                                    :multiple-select="true"
                                    :allow-empty="true"
                                    :pre-selected="
                                        Array.isArray(userSettings.venueFilter)
                                            ? userSettings.venueFilter
                                            : parseInt(userSettings.venueFilter)
                                    "
                                    @selected="emitVenue"
                                />
                            </span>
                        </div>

                        <div
                            class="flex w-full flex-col justify-between sm:flex-row"
                        >
                            <order-range-selector
                                @date-updated="dateRangeChange"
                                :preSelected="{
                                    startDate: userSettings.from,
                                    endDate: userSettings.to
                                }"
                                class="transition-color mb-3 min-w-max flex-1 rounded-md font-medium text-gray-700 shadow-sm sm:mr-3 md:mb-0"
                                aligned="center"
                                :allowFuture="true"
                            />
                            <div
                                class="relative mb-3 inline-block text-left md:mb-0"
                            >
                                <div
                                    v-click-outside="clickOutside"
                                    class="relative"
                                >
                                    <span
                                        class="inline-block w-auto w-full rounded-sm shadow-sm"
                                    >
                                        <button
                                            @click="
                                                showOrderFilter = !showOrderFilter
                                            "
                                            type="button"
                                            :class="{
                                                'border-gray-600':
                                                    userSettings.orderFilter !=
                                                    null
                                            }"
                                            class="focus:outline-none inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
                                        >
                                            {{
                                                userSettings.orderFilter
                                                    ? userSettings.orderFilter
                                                    : 'All'
                                            }}
                                            orders
                                            <svg
                                                class="-mr-1 ml-2 h-5 w-5"
                                                fill="currentColor"
                                                viewBox="0 0 20 20"
                                            >
                                                <path
                                                    fill-rule="evenodd"
                                                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                    clip-rule="evenodd"
                                                />
                                            </svg>
                                        </button>
                                    </span>
                                </div>
                                <transition
                                    enter-active-class="transition ease-out duration-100"
                                    enter-class="transform opacity-0 scale-95"
                                    enter-to-class="transform opacity-100 scale-100"
                                    leave-active-class="transition ease-in duration-75"
                                    leave-class="transform opacity-100 scale-100"
                                    leave-to-class="transform opacity-0 scale-95"
                                >
                                    <div
                                        v-if="showOrderFilter"
                                        class="absolute right-0 z-50 mt-2 w-full origin-top-right rounded-md shadow-lg sm:w-56"
                                    >
                                        <div
                                            class="shadow-xs cursor-pointer rounded-md bg-white"
                                        >
                                            <div class="py-1">
                                                <span
                                                    @click="
                                                        orderFilterChange(null)
                                                    "
                                                    class="focus:outline-none group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900"
                                                >
                                                    <svg
                                                        fill="none"
                                                        stroke="currentColor"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="2"
                                                        viewBox="0 0 24 24"
                                                        class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500"
                                                    >
                                                        <path
                                                            d="M4 6h16M4 10h16M4 14h16M4 18h16"
                                                        ></path>
                                                    </svg>
                                                    All Orders
                                                </span>
                                            </div>
                                            <div
                                                class="border-t border-gray-100"
                                            ></div>
                                            <div class="py-1">
                                                <span
                                                    @click="
                                                        orderFilterChange(
                                                            'InStore'
                                                        )
                                                    "
                                                    class="focus:outline-none group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900"
                                                >
                                                    <InstoreIcon
                                                        color="text-gray-500"
                                                        size="sm"
                                                        class="mr-2"
                                                    />
                                                    In Store
                                                </span>
                                                <span
                                                    @click="
                                                        orderFilterChange(
                                                            'Delivery'
                                                        )
                                                    "
                                                    class="focus:outline-none group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900"
                                                >
                                                    <DeliveryIcon
                                                        color="text-gray-500"
                                                        size="sm"
                                                        class="mr-2"
                                                    />
                                                    Delivery
                                                </span>
                                                <span
                                                    @click="
                                                        orderFilterChange(
                                                            'Pickup'
                                                        )
                                                    "
                                                    class="focus:outline-none group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900"
                                                >
                                                    <PickupIcon
                                                        color="text-gray-500"
                                                        size="sm"
                                                        class="mr-2"
                                                    />
                                                    Pickup
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </transition>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="mt-2 flex flex-row items-center sm:mt-6">
                <toggle-switch v-model="showArchivedOrders" />
                <span class="ml-4 text-sm font-medium text-gray-500">
                    Show archived orders
                </span>
            </div>

            <div class="mb-4 mt-4 flex flex-row items-center">
                <toggle-switch v-model="showRejectedOrders" />
                <span class="ml-4 text-sm font-medium text-gray-500">
                    Show rejected orders
                </span>
            </div>
        </div>
        <div class="justify-between py-3 sm:flex sm:flex-row">
            <span
                class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto"
                :class="{ 'opacity-50': isLoading }"
            >
                <button
                    @click="close"
                    :disabled="isLoading"
                    type="button"
                    class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo sm:text-sm sm:leading-5"
                >
                    Close
                </button>
            </span>
            <span
                class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto"
                :class="{ 'opacity-50': isLoading }"
            >
                <button
                    @click="clearFilters"
                    :disabled="isLoading"
                    type="button"
                    class="focus:outline-none inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium leading-6 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                >
                    Clear filters
                </button>
            </span>
        </div>
    </sliding-sidebar>
</template>

<script>
import vClickOutside from 'v-click-outside';
import SlidingSidebar from '../SlidingSidebar';
import PickupIcon from '@/components/common/PickupIcon';
import InstoreIcon from '@/components/common/InstoreIcon';
import DeliveryIcon from '@/components/common/DeliveryIcon';
import VenueSelector from '@/components/selectors/VenueSelector';
import ToggleSwitch from '@/components/formComponents/ToggleSwitch';
import OrderRangeSelector from '@/components/widgets/OrderRangeSelector';

export default {
    name: 'AllOrdersFilterSidebar',
    components: {
        SlidingSidebar,
        VenueSelector,
        DeliveryIcon,
        PickupIcon,
        InstoreIcon,
        OrderRangeSelector,
        ToggleSwitch
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    props: {
        userSettings: {
            type: Object,
            required: true
        },
        value: {
            type: Boolean,
            default: false
        },
        venues: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            isLoading: false,
            showOrderFilter: false
        };
    },
    computed: {
        isOpened: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        showArchivedOrders: {
            get() {
                return this.userSettings.archived;
            },
            set(value) {
                this.$emit('toggleArchived', value);
            }
        },
        showRejectedOrders: {
            get() {
                return this.userSettings.showRejected;
            },
            set(value) {
                this.$emit('toggleRejected', value);
            }
        }
    },

    methods: {
        emitVenue(val) {
            this.$emit('setVenue', val);
        },
        close() {
            this.isOpened = false;
        },
        clearFilters() {
            this.$emit('clearFilters');
            this.$refs.venueSelector.updateSelection([]);
        },
        clickOutside() {
            this.showOrderFilter = false;
        },
        orderFilterChange(val) {
            this.$emit('orderFilterChange', val);
            this.showOrderFilter = false;
        },
        dateRangeChange(val) {
            this.$emit('dateRangeChange', val);
        }
    }
};
</script>
