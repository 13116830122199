<template>
    <modal
        name="external-import-menu-modal"
        width="700px"
        height="auto"
        :clickToClose="true"
        :adaptive="true"
        :pivotY="0.35"
        @before-open="beforeOpen"
    >
        <div class="px-4 pb-6">
            <div
                class="transform overflow-hidden rounded-lg transition-all sm:w-full"
            >
                <div v-if="!selectedActor" class="mx-auto p-5 sm:max-w-md">
                    <div
                        v-for="(value, key) in actors"
                        :key="key"
                        class="p-5 text-center"
                    >
                        <div
                            class="flex cursor-pointer items-center justify-center rounded-md rounded-sm border border border-gray-300 border-gray-300 bg-white bg-white p-2 px-4 py-2 text-sm font-medium text-gray-700 text-gray-700 shadow-sm hover:bg-gray-50 hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            @click="selectActor(key)"
                        >
                            <!--                            <img-->
                            <!--                                :src="value.logo"-->
                            <!--                                alt=""-->
                            <!--                                class="mr-3 h-8 w-8 rounded-md"-->
                            <!--                            />-->
                            <span class="text-base font-semibold text-gray-700">
                                {{ value.name }}
                            </span>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="overflow-hidden bg-white">
                        <div
                            class="flex items-center justify-between border-b border-gray-200 px-4 py-5 sm:px-6"
                        >
                            <div class="">
                                <h3
                                    class="text-lg font-medium font-medium leading-6 text-gray-900"
                                >
                                    Import a menu from {{ selectedActor.name }}
                                </h3>
                                <div>
                                    <p class="text-sm leading-4 text-gray-500">
                                        Copy and paste the web url for your
                                        {{ selectedActor.name }} menu.
                                    </p>
                                </div>
                            </div>
                            <div
                                class="-mt-8 h-4 w-4"
                                :class="{ spinner: isLoading }"
                            >
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <div class="w-full px-4 py-5 sm:p-6">
                        <div>
                            <div class="w-full flex-col">
                                <div class="flex w-full flex-1">
                                    <base-input-field
                                        :label="`Your ${selectedActor.name} link`"
                                        id="url"
                                        v-model.trim="importUrl"
                                        class="w-full"
                                        type="url"
                                        :placeholder="selectedActor.placeholder"
                                        :errors="$v.importUrl.$error"
                                    />
                                </div>

                                <div
                                    v-if="$v.importUrl.$error"
                                    class="m-1 text-sm text-red-500"
                                >
                                    Incorrect Url
                                    {{
                                        selectedActor.example
                                            ? ', must begins with'
                                            : ''
                                    }}

                                    <i>{{ selectedActor.example }}</i>
                                </div>

                                <div class="mt-3">
                                    <label
                                        for="imageImport"
                                        class="block text-sm font-medium leading-5 text-gray-700"
                                    >
                                        Import images
                                    </label>

                                    <div
                                        class="mt-1 flex h-9 flex-row items-center"
                                    >
                                        <div class="">
                                            <toggle-switch
                                                v-model="importImages"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-2 flex w-full justify-between">
                                    <span>
                                        <button
                                            type="button"
                                            :disabled="isLoading"
                                            class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
                                            @click="selectActor(null)"
                                        >
                                            Back
                                        </button>
                                    </span>

                                    <span>
                                        <button
                                            type="button"
                                            :disabled="isLoading"
                                            class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
                                            @click="createMenu"
                                        >
                                            Import
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mb-2 text-center">
                    <button
                        type="button"
                        :disabled="isLoading"
                        class="focus:outline-none mb-2 rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
                        @click="closeModal"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import { required, url } from 'vuelidate/lib/validators';
import ToggleSwitch from '@/components/formComponents/ToggleSwitch';

export default {
    name: 'ExternalImportMenuModal',
    components: { ToggleSwitch },
    data() {
        return {
            isLoading: false,
            importUrl: null,
            venueId: null,
            importImages: true,
            actors: {
                DELIVEROO: {
                    name: 'Deliveroo',
                    placeholder:
                        'e.g. https://deliveroo.co.uk/menu/london/leicester-square/five-guys-piccadilly?day=today&geohash=gcpvhc5e3890&time=ASAP"',
                    example:
                        'https://deliveroo.co.uk/menu/london/leicester-square/five-guys-piccadilly',
                    beginUrl: 'https://deliveroo.co.uk/menu/',
                    actorName: 'deliveroo',
                    logo: '@/assets/deliveroo.png'
                },
                UBER_EATS: {
                    name: 'Uber Eats',
                    placeholder: 'e.g. https://ubereats.com/',
                    example: 'https://www.ubereats.com/',
                    beginUrl: 'https://www.ubereats.com/',
                    actorName: 'uber',
                    logo: ''
                },
                GLORIA_FOOD: {
                    name: 'Gloria Food',
                    placeholder: 'e.g. https://myrestaurant.com',
                    example: '',
                    beginUrl: '',
                    actorName: 'gloriaFood',
                    logo: ''
                },
                // JUST_EAT: {
                //     name: 'Just Eat',
                //     placeholder:
                //         'e.g. https://www.just-eat.co.uk/restaurants-china-house-new-willesden/menu',
                //     example: 'https://www.just-eat.co.uk/',
                //     beginUrl: 'https://www.just-eat.co.uk/',
                //     actorName: 'justEat',
                //     logo: ''
                // },
                FLIPDISH: {
                    name: 'Flipdish',
                    placeholder: 'e.g. https://myrestaurant.com',
                    example: '',
                    beginUrl: '',
                    actorName: 'flipdish',
                    logo: ''
                }
            },
            selectedActor: null
        };
    },
    validations: {
        importUrl: {
            required,
            url,
            beginningOfTheUrl: (value, { selectedActor }) => {
                if (!value) {
                    return false;
                }

                const { beginUrl } = selectedActor;

                return value.slice(0, beginUrl.length) === beginUrl;
            }
        }
    },
    computed: {
        publicHostname() {
            return process.env.VUE_APP_WHITELABEL_PUBLIC_HOSTNAME;
        }
    },
    methods: {
        beforeOpen({ params }) {
            this.venueId = params.venueId;
        },
        selectActor(key) {
            this.selectedActor = this.actors[key] || null;
        },
        async createMenu() {
            this.$v.$touch();

            if (this.$v.$invalid || this.isLoading) {
                return false;
            }

            this.isLoading = true;

            try {
                await this.$axios.post(`/scrape/${this.venueId}`, {
                    actor: this.selectedActor.actorName,
                    url: this.importUrl,
                    importImages: this.importImages
                });

                this.$notify({
                    group: 'settings',
                    title:
                        "Started import, you'll receive an email once complete!"
                });

                this.closeModal();
            } catch (error) {
                this.$notify({
                    group: 'settings',
                    title: 'Something went wrong, make sure your URL is correct'
                });

                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        },
        closeModal() {
            this.selectActor(null);

            this.$modal.hide('external-import-menu-modal');
        }
    }
};
</script>
