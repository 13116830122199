<template>
    <modal
        name="check-address-zone-modal"
        height="auto"
        width="500px"
        :clickToClose="true"
        :adaptive="true"
        @before-close="beforeClose"
    >
        <div
            class="w-full transform overflow-hidden rounded-lg px-4 pb-4 pt-5 shadow-xl transition-all sm:p-6"
        >
            <div class="w-full">
                <base-input-field
                    label="Street"
                    id="street"
                    v-model="address.street"
                    placeholder="Street"
                    :errors="$v.address.street.$error"
                />
            </div>
            <div class="mt-3 flex">
                <div class="relative mb-4 w-full">
                    <base-input-field
                        label="City"
                        id="city"
                        v-model="address.city"
                        placeholder="City"
                        :errors="$v.address.city.$error"
                    />
                </div>
                <div class="relative mb-4 ml-2 w-full lg:w-2/5">
                    <base-input-field
                        label="Postcode"
                        id="postcode"
                        v-model="address.postcode"
                        placeholder="Postcode"
                        :errors="$v.address.postcode.$error"
                /></div>
            </div>
            <div class="flex w-full justify-end">
                <base-button
                    @clicked="checkZone"
                    buttonText="Check"
                    size="sm"
                />
            </div>
        </div>
    </modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';

export default {
    name: 'CheckAddressZoneModal',
    data() {
        return {
            address: {
                city: null,
                street: null,
                postcode: null,
                country: null
            },
            coords: {}
        };
    },
    validations: {
        address: {
            city: { required },
            street: { required },
            postcode: { required }
        }
    },
    computed: {
        ...mapGetters({
            venue: 'venues/getVenue'
        })
    },
    methods: {
        async checkZone() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return false;
            }

            const params = {
                city: this.address.city,
                street: this.address.street,
                postcode: this.address.postcode,
                country: this.venue.address.country
            };

            try {
                const { data } = await this.$axios.get(
                    `/venues/${this.venue.id}/check-address`,
                    {
                        params
                    }
                );

                if (!data.zone) {
                    this.coords = data.coords;
                    this.$notify({
                        group: 'settings',
                        title:
                            'No delivery zone found for the provided address.'
                    });

                    return;
                }

                this.coords = data.coords;
                this.$notify({
                    group: 'settings',
                    title: `Delivery zone found for the provided address: ${data.zone.name}`
                });
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.$emit('place-marker', this.coords);
                this.$modal.hide('check-address-zone-modal');
            }
        },
        beforeClose() {
            this.address = {
                city: null,
                street: null,
                postcode: null,
                country: null
            };
            this.coords = {};
            this.$v.$reset();
        }
    }
};
</script>
