var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-5 bg-white shadow print:shadow-none sm:rounded-md"},[_c('div',{staticClass:"px-4 sm:col-span-2 sm:px-6 lg:px-8"},[_c('div',{staticClass:"mt-3 flex flex-col print:block"},[_c('div',{staticClass:"-mx-4 -my-2 overflow-x-auto py-2 sm:-mx-6 lg:-mx-8"},[_c('div',{staticClass:"inline-block min-w-full overflow-hidden align-middle sm:rounded-sm print:overflow-visible"},[_c('table',{staticClass:"w-full min-w-full px-3 sm:px-4 lg:px-6"},[_c('thead',[_c('tr',[_c('th',{staticClass:"border-b border-gray-200 bg-gray-100 px-3 py-3 text-left text-xs font-medium text-gray-500 sm:rounded-tl-md sm:px-4"},[_vm._v(" Items "),_c('span',{staticClass:"font-mono"},[_vm._v("("+_vm._s(_vm.countOrderItems)+")")])]),_c('th',{staticClass:"border-b border-gray-200 bg-gray-100 px-3 py-3 text-right text-xs font-medium text-gray-500 sm:px-4"},[_vm._v(" Quantity ")]),_c('th',{staticClass:"border-b border-gray-200 bg-gray-100 px-3 py-3 text-right text-xs font-medium text-gray-500 sm:rounded-tr-md sm:px-4"},[_vm._v(" Price ")])])]),(
                                _vm.order && _vm.order.items && _vm.order.items.length
                            )?_c('tbody',{staticClass:"bg-white"},_vm._l((_vm.order.items),function(item,index){return _c('order-menu-item',{key:item.id + '-' + index,attrs:{"item":item}})}),1):_vm._e(),(
                                _vm.order && _vm.order.items && _vm.order.items.length
                            )?_c('tbody',{staticClass:"bg-white"}):_vm._e()]),_c('ul',{staticClass:"w-full px-3 pb-4 sm:px-4"},[(_vm.order.serviceCharge)?_c('li',{staticClass:"mt-3 flex flex-auto flex-row justify-between text-sm text-gray-600"},[_c('span',{staticClass:"total"},[_vm._v(" "+_vm._s(_vm.order.venue.serviceChargeLabel || 'Service charge')+" ")]),_c('span',{staticClass:"font-mono text-gray-900"},[_vm._v(" "+_vm._s(_vm._f("currency")((_vm.order.serviceCharge / 100)))+" ")])]):_vm._e(),(
                                _vm.order.fulfillmentMethod === 'Delivery' &&
                                _vm.order.deliveryFee
                            )?_c('li',{staticClass:"mt-1 flex flex-auto flex-row justify-between text-sm text-gray-600 sm:mt-2"},[_c('span',{staticClass:"total"},[_vm._v(" Delivery Fee ")]),_c('span',{staticClass:"font-mono text-gray-900"},[_vm._v(" "+_vm._s(_vm._f("currency")((_vm.order.deliveryFee / 100)))+" ")])]):_vm._e(),(_vm.order.tip > 0)?_c('li',{staticClass:"mt-1 flex flex-auto flex-row justify-between text-sm text-gray-600 sm:mt-2"},[_c('span',{staticClass:"total"},[_vm._v(" "+_vm._s(_vm.order.venue.tipLabel || 'Gratuity')+" ")]),_c('span',{staticClass:"font-mono text-gray-900"},[_vm._v(_vm._s(_vm._f("currency")((_vm.order.tip / 100))))])]):_vm._e(),(
                                _vm.order.orderDiscountCode &&
                                _vm.order.orderDiscountCode.length
                            )?_c('li',{staticClass:"mt-1 flex flex-auto flex-row justify-between text-sm text-gray-600 sm:mt-2"},[_c('span',{staticClass:"total"},[_vm._v(" Discount "),_c('router-link',{staticClass:"rounded-sm font-mono hover:bg-gray-100",attrs:{"to":{
                                        name: 'editDiscount',
                                        params: {
                                            id: _vm.order.discountCodeId
                                        }
                                    }}},[_vm._v(" ["+_vm._s(_vm.order.orderDiscountCode[0].code)+"] ")])],1),_c('span',{staticClass:"font-mono text-red-800"},[_vm._v(" ("+_vm._s(_vm._f("currency")((_vm.order.discountAmount / 100)))+") ")])]):_vm._e(),(_vm.order?.customer?.piggy?.piggyProductDiscount > 0)?_c('li',{staticClass:"mt-1 flex flex-auto flex-row justify-between text-sm text-gray-600 sm:mt-2"},[_c('span',{staticClass:"total"},[_vm._v(" Loyalty discount ")]),_c('span',{staticClass:"font-mono text-red-800"},[_vm._v(" ("+_vm._s(_vm._f("currency")((_vm.order.customer.piggy.piggyProductDiscount / 100 )))+") ")])]):_vm._e(),(_vm.order.tip || _vm.order.deliveryFee || _vm.order.serviceCharge || _vm.order.discountAmount)?_c('li',{staticClass:"mt-2 h-px border-t border-dashed border-gray-200"}):_vm._e(),_c('li',{staticClass:"mt-2 flex flex-auto flex-row justify-between py-2 font-heading text-sm font-bold text-gray-900"},[_c('span',{staticClass:"total"},[_vm._v(" Total ")]),_c('span',{staticClass:"price"},[_vm._v(" "+_vm._s(_vm._f("currency")((_vm.order.total / 100)))+" ")])])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }