<template>
    <modal
        name="airship-connect-modal"
        :adaptive="true"
        height="auto"
        width="600px"
        @before-open="beforeOpen"
    >
        <div
            class="w-full transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 shadow-xl transition-all sm:p-6"
        >
            <div class="sm:flex sm:items-start">
                <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <h3 class="text-lg font-medium leading-6 text-gray-900">
                        Connect to Airship
                    </h3>
                    <div class="mt-2">
                        <p class="text-sm leading-5 text-gray-500">
                            Provide your credentials and connect with Airship
                        </p>
                    </div>
                </div>
            </div>

            <div class="m-auto mt-2 w-full p-6 pb-0">
                <label
                    for="airshipAuthToken"
                    class="block text-sm font-medium leading-5 text-gray-700"
                >
                    Airship API Key
                </label>
                <input
                    v-model.trim="airshipSettings.authToken"
                    id="airshipAuthToken"
                    type="text"
                    placeholder="eg AJDWNAUD1231D9021="
                    class="focus:outline-none form-input mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                    :class="{
                        'border-red-300': $v.airshipSettings.authToken.$error
                    }"
                />
                <div
                    v-if="$v.airshipSettings.authToken.$error"
                    class="mt-1 text-xs text-red-400"
                >
                    Invalid Api Key
                </div>
            </div>

            <div class="m-auto w-full p-6">
                <label
                    for="airshipAccountId"
                    class="block text-sm font-medium leading-5 text-gray-700"
                >
                    Airship Account Id
                </label>
                <input
                    v-model.trim="airshipSettings.accountId"
                    id="airshipAccountId"
                    type="number"
                    placeholder="eg 456"
                    min="1"
                    class="focus:outline-none form-input mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                    :class="{
                        'border-red-300': $v.airshipSettings.accountId.$error
                    }"
                />

                <div
                    v-if="$v.airshipSettings.accountId.$error"
                    class="mt-1 text-xs text-red-400"
                >
                    Account Id must be a positive number
                </div>
            </div>

            <div class="mt-5 justify-end sm:mt-4 sm:flex sm:pl-4">
                <span
                    class="mt-3 flex w-full rounded-md shadow-sm sm:ml-3 sm:mt-0 sm:w-auto"
                    @click="connectAirship"
                >
                    <button
                        type="button"
                        class="focus:outline-none inline-flex w-full items-center justify-center rounded-md rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                        :disabled="isLoading"
                    >
                        Save
                    </button>
                </span>

                <span
                    class="mt-3 flex w-full rounded-md shadow-sm sm:ml-3 sm:mt-0 sm:w-auto"
                >
                    <button
                        @click="hideModal()"
                        type="button"
                        class="focus:outline-none inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium leading-6 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline sm:text-sm sm:leading-5"
                    >
                        Cancel
                    </button>
                </span>
            </div>
        </div>
    </modal>
</template>

<script>
import { required, minLength, minValue } from 'vuelidate/lib/validators';

export default {
    name: 'airship-connect-modal',

    validations: {
        airshipSettings: {
            authToken: {
                required,
                minLength: minLength(10)
            },
            accountId: {
                required,
                minValue: minValue(1)
            }
        }
    },
    data() {
        return {
            isLoading: false,
            airshipSettings: { authToken: '', accountId: 0 },
            callback: null
        };
    },
    methods: {
        beforeOpen({ params }) {
            this.callback = params.callback;
        },
        hideModal() {
            this.$emit('close');
            this.$modal.hide('airship-connect-modal');
        },
        async connectAirship() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                this.errors = 'ERROR';

                return false;
            }

            try {
                this.isLoading = true;

                const payload = {
                    airshipSettings: this.airshipSettings
                };

                await this.$axios.post(`/airship/connect`, payload);

                this.$notify({
                    group: 'settings',
                    title: 'Settings saved'
                });

                this.callback(true);

                this.hideModal();
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        }
    }
};
</script>
