<template>
    <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div
                class="inline-block align-middle py-2 min-w-full sm:px-6 lg:px-8"
            >
                <div
                    class="border-b border-gray-200 shadow overflow-hidden sm:rounded-lg"
                >
                    <table class="min-w-full divide-gray-200 divide-y">
                        <thead>
                            <tr>
                                <th
                                    class="px-6 py-3 text-left text-gray-500 text-xs font-medium tracking-wider leading-4 bg-gray-50 uppercase"
                                >
                                    Name
                                </th>
                                <th
                                    class="px-6 py-3 text-left text-gray-500 text-xs font-medium tracking-wider leading-4 bg-gray-50 uppercase"
                                >
                                    Role
                                </th>
                                <th class="px-6 py-3 bg-gray-50"></th>
                            </tr>
                        </thead>
                        <tbody class="bg-white divide-gray-200 divide-y">
                            <tr v-for="user in users" :key="user.id">
                                <td class="whitespace-nowrap px-6 py-4">
                                    <div class="flex items-center">
                                        <div class="flex-shrink-0 w-10 h-10">
                                            <avatar
                                                :username="user.name"
                                                :size="36"
                                            />
                                        </div>
                                        <div class="ml-4">
                                            <div
                                                class="text-gray-900 text-sm font-medium leading-5"
                                            >
                                                {{ user.name }}
                                            </div>
                                            <div
                                                class="text-gray-500 text-sm leading-5"
                                            >
                                                {{ user.email }}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="whitespace-nowrap px-6 py-4">
                                    <div
                                        class="text-gray-900 text-sm leading-5"
                                    >
                                    </div>
                                    <div class="text-gray-500 text-sm leading-5"
                                        >Admin</div
                                    >
                                </td>
                                <td class="whitespace-nowrap px-6 py-4">
                                    <span
                                        class="inline-flex px-2 text-green-800 text-xs font-semibold leading-5 bg-green-100 rounded-full"
                                    >
                                        Active
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr
                                class="bg-white cursor-pointer"
                                @click="showAddUserModal()"
                            >
                                <td
                                    class="whitespace-nowrap px-6 py-2 bg-gray-100"
                                    colspan="5"
                                >
                                    <div
                                        class="flex items-center justify-center w-full"
                                    >
                                        <div class="mt-2 w-full sm:mt-0">
                                            <div
                                                class="flex justify-center pb-3 pt-2 px-6 bg-white border-2 border-dashed border-gray-300 rounded-md"
                                            >
                                                <div class="text-center">
                                                    <p
                                                        class="mt-1 text-gray-600 text-sm"
                                                    >
                                                        <button
                                                            type="button"
                                                            class="hover:text-indigo-500 text-indigo-600 focus:underline font-medium focus:outline-none transition duration-150 ease-in-out"
                                                        >
                                                            Add a user
                                                        </button>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Avatar from 'vue-avatar';

export default {
    name: 'UserList',
    props: {
        users: Array
    },
    data() {
        return {};
    },
    components: { Avatar },
    methods: {
        showAddUserModal() {
            this.$modal.show('add-user-modal');
        }
    }
};
</script>
